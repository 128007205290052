<template>
    <v-card v-if="isReply">
         <v-row class="px-2 btns" flex :style="{width: '100%', margin: 0}">
            <v-col lg="6" class="px-0 py-0" align="left" >
             <v-tooltip top v-if="amIAdmin">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  color="primary"
                  class="btnRed mr-2"
                  @click="seeUserMessages(state.showCompSendMsg.uuid)"
                >
                  <font-awesome-icon :icon="['fal', 'eye']" :style="{ fontSize: '20px' }" />
                </v-btn>
              </template>
              <span>{{$t('components.sendMessageModal.seeUserMessages')}}</span>
            </v-tooltip>
            </v-col>
            <v-col lg="6" class="px-0 py-0" align="right">
             <v-tooltip top >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  color="primary"
                  class="btnRed mr-2"
                  @click="toggleIsReply"
                >
                  <!-- @click="closeModalMsg(); resettmpMessagetext('')" -->
                  <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
                </v-btn>
              </template>
              <span>{{ $t('generics.cancel') }}</span>
            </v-tooltip>
            </v-col>
          </v-row>
      <v-divider></v-divider>
      <v-form ref="messageForm" v-model="valid" lazy-validation :autocomplete="false">
        
        <v-row :style="{width: '100%', margin: 0}" class="px-5">
            <v-textarea
              counter
              contenteditable="true"
              :label="$t('components.sendMessageModal.message')"
              :rules="rules"
              v-model="state.tmpMessagetext"
              :no-resize="true"
              maxlength="250"
              autofocus
            ></v-textarea>
        </v-row>
      </v-form>
    </v-card>
</template>
<script>
import store from "../../store";
import { sendOfflineMsg, sendMsg } from "../../lib/wsMsg.js";
// import { replyMessageEvent } from '../../effector/message';

export default {
  components: {
  },
  props: ['toggleIsReply', 'isReply', 'setIsReply'],
  data: () => ({
    //dialog: false,
    state: store.state,
    ownUUID: store.state.ownUUID,
    valid: true,
    setCurrentContentVisile: store.setCurrentContentVisile,
    closeOnClick: true,
    closeOnContent: false,
    offset: true,
    shown: true,
    linkText:' Go Here ',
    linkUrl:'https://www.ibm.com',
    content: `
      <h1>Yay Headlines!</h1>
      <p>All these <strong>cool tags</strong> are working now.</p>
    `,
    rules: [v => !!v || "", v => !!v.trim() || "Message can not be blank"],
    contentWidth: 0,
    contentHeight: 0,
    previousHeight: 0
  }),
  mounted: function() {
   
  },
  methods: {
    foo: function(){
     // console.log('Foo from parent')
    },
    seeUserMessages(user){
      store.state.user.subordinateActiveUser = user;
      this.state.showCompSendMsg = undefined;
      // console.log('see messages of ' , store.state.group[user].user.name);
      this.setCurrentContentVisile("messages", false, this.$router);
    },
    linkifyme(){
     let mssgbody = store.state.tmpMessagetext;
     let link =' <a href="'+this.linkUrl +'" target="_blank">'+this.linkText+ '</a>'; 
     store.state.tmpMessagetext += link; 
     this.closeMenu(); 
    },
    closeMenu() {
       this.setIsReply(false);
    },

    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    //  preparedataToSendMsgEffector(id, text){
    //   const userToSendMsg= {};
    //   userToSendMsg[id] = {
    //     userUUID: id,
    //     read: false,
    //     replied: false,
    //     deleted: false
    //   }

    //   const dataMsg = {
    //     body: text,
    //     header: 'individual message',
    //     date: Date.now(),
    //     users: userToSendMsg,
    //     isReply: true,
    //     creatorUUID: this.ownUUID,
    //     masterMessageUUID: null,
    //   }
    //   replyMessageEvent(dataMsg);
    // },
    preparedataToSendMsg(id, text) {
      const userToSend = [];
      const separate = this.separatedPeople();
      if (this.state.showCompSendMsg.options !== '') {
       //  console.log(this.state.showCompSendMsg);
        const option = this.state.showCompSendMsg.options;
        const recipients = this.state.showCompSendMsg.recipients;
       //  console.log('Options ', option)
        switch (option) {
          case "teams":
            const teamsSelected = id;
            for (const index in separate.teams[teamsSelected]) {
              const uuid = separate.teams[teamsSelected][index];
              const isConnected = this.userIsConnected(uuid);
              if (
                isConnected &&
                uuid !== this.state.ownUUID &&
                userToSend.indexOf(uuid) == -1
              ) {
                userToSend.push(uuid);
              }
            }
            break;
          case "sections":
            const sectionsSelected = id;
            for (const index in separate.sections[sectionsSelected]) {
              const uuid = separate.sections[sectionsSelected][index];
              const isConnected = this.userIsConnected(uuid);
              if (
                isConnected &&
                uuid !== this.state.ownUUID &&
                userToSend.indexOf(uuid) == -1
              ) {
                userToSend.push(uuid);
              }
            }
            break;
          case "departments":
            const departmentsSelected = id;
            for (const index in separate.departments[departmentsSelected]) {
              const uuid = separate.departments[departmentsSelected][index];
              const isConnected = this.userIsConnected(uuid);
              if (
                isConnected &&
                uuid !== this.state.ownUUID &&
                userToSend.indexOf(uuid) == -1
              ) {
                userToSend.push(uuid);
              }
            }
            break;
          case "multUsers":
            if(recipients.length){
              recipients.forEach(recipient => {
                userToSend.push(recipient);
              });
            }
            break;


          default:
            break;
        }
       // console.log(userToSend, "usertosend");
        for (const key in userToSend) {
          const uuid = userToSend[key];
          const header = {
            sendTo: {
              name: id,
              category: option
            }
          };
         // console.log(header, "header");
         if (this.userIsConnected(uuid)) {
          this.sendMsg(uuid, this.state.tmpMessagetext, header);
         } else {
          this.sendOfflineMsg(uuid, this.state.tmpMessagetext, header);
         }
        }
      } else {
         if (this.userIsConnected(id)) {
          this.sendMsg(id, text.trim());
         } else {
          this.sendOfflineMsg(id, text.trim());
         }
      }
      this.closeModalMsg();
     },
    userIsConnected(uuid) {
      const users = this.state.group || {};
      if (users[uuid].connected) {
        return true;
      } else {
        return false;
      }
    },
    sendOfflineMsg(...arg) {
      return sendOfflineMsg(...arg);
    },
    sendMsg(...arg) {
      return sendMsg(...arg);
    },
    setshowCompSendMsg(v) {
      return store.setshowCompSendMsg(v);
    },
    closeModalMsg() {
     this.resettmpMessagetext(''); 
     this.setIsReply(false);
    },
    resettmpMessagetext(v) {
      return store.resettmpMessagetext(v);
    },
    separatedPeople: function() {
      const result = { sections: {}, departments: {}, teams: {} };

      if (!store.state.namespaceSettings.processedOrganisation) {
        return result;
      }
      const sections =
        store.state.namespaceSettings.processedOrganisation.sections || {};
      const departments =
        store.state.namespaceSettings.processedOrganisation.departments || {};
      const teams =
        store.state.namespaceSettings.processedOrganisation.teams || {};

      if (Object.keys(sections).length > 0) {
        Object.keys(sections).forEach(orgKey => {
          if (!result.sections[orgKey]) result.sections[orgKey] = [];
          for (var i = 0; i < sections[orgKey].supervisors.length; i++) {
            if (
              this.ownUUID !== sections[orgKey].supervisors[i].uuid &&
              !result.sections[orgKey].includes(
                sections[orgKey].supervisors[i].uuid
              )
            ) {
              result.sections[orgKey].push(
                sections[orgKey].supervisors[i].uuid
              );
            }
          }

          for (var i = 0; i < sections[orgKey].departments.length; i++) {
            const departKey = sections[orgKey].departments[i];
            const department = departments[departKey];
            if (!result.departments[departKey])
              result.departments[departKey] = [];
            for (var j = 0; j < department.supervisors.length; j++) {
              if (
                this.ownUUID !== department.supervisors[j].uuid &&
                !result.sections[orgKey].includes(
                  department.supervisors[j].uuid
                )
              ) {
                result.sections[orgKey].push(department.supervisors[j].uuid);
              }
              if (
                this.ownUUID !== department.supervisors[j].uuid &&
                !result.departments[departKey].includes(
                  department.supervisors[j].uuid
                )
              ) {
                result.departments[departKey].push(
                  department.supervisors[j].uuid
                );
              }
            }

            for (
              var teamKey = 0;
              teamKey < department.teams.length;
              teamKey++
            ) {
              const team_key = department.teams[teamKey];
              const team = teams[team_key];
              if (!result.teams[team_key]) result.teams[team_key] = [];
              for (var j = 0; j < team.supervisors.length; j++) {
                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.sections[orgKey].includes(team.supervisors[j].uuid)
                ) {
                  result.sections[orgKey].push(team.supervisors[j].uuid);
                }

                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.departments[departKey].includes(
                    team.supervisors[j].uuid
                  )
                ) {
                  result.departments[departKey].push(team.supervisors[j].uuid);
                }

                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.teams[team_key].includes(team.supervisors[j].uuid)
                ) {
                  result.teams[team_key].push(team.supervisors[j].uuid);
                }
              }

              for (var j = 0; j < team.members.length; j++) {
                if (
                  this.ownUUID !== team.members[j] &&
                  !result.sections[orgKey].includes(team.members[j])
                ) {
                  result.sections[orgKey].push(team.members[j]);
                }

                if (
                  this.ownUUID !== team.members[j] &&
                  !result.departments[departKey].includes(team.members[j])
                ) {
                  result.departments[departKey].push(team.members[j]);
                }

                if (
                  this.ownUUID !== team.members[j] &&
                  !result.teams[team_key].includes(team.members[j])
                ) {
                  result.teams[team_key].push(team.members[j]);
                }
              }
            }
          }
        });
      }
      function sortObject(o) {
        return Object.keys(o)
          .sort((a, b) => {
            const str1 = a.toLowerCase();
            const str2 = b.toLowerCase();
            return (str1 > str2) - (str1 < str2);
          })
          .reduce((r, k) => ((r[k] = o[k]), r), {});
      }
      result.sections = sortObject(result.sections);
      result.departments = sortObject(result.departments);
      result.teams = sortObject(result.teams);
      return result;
    }
  },
  computed: {
    test(){
      return this.store.state.tmpMessagetext;
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.breakpoint;
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : xl ? [4, 8] : [12, 12];
    },
    modalTitle () {
      let translateKey = 'components.sendMessageModal.sendMessageTo';
      switch (this.state.showCompSendMsg.options) {
        case 'multUsers':
          if (!this.state.showCompSendMsg.isAllMembers && this.state.showCompSendMsg.recipients) {
            translateKey = 'components.sendMessageModal.sendMessageToTeamN';
            return this.$t(translateKey, [this.state.showCompSendMsg.recipients.length, (this.state.showCompSendMsg.team || this.getNameForUuid(this.state.showCompSendMsg.uuid))]);
          }
          // fallthru intentional
        case 'teams':
          translateKey = 'components.sendMessageModal.sendMessageToTeam';
          break;
        case 'sections':
          translateKey = 'components.sendMessageModal.sendMessageToSection';
          break;
        case 'departments':
          translateKey = 'components.sendMessageModal.sendMessageToDepartment';
          break;
      }
     // console.log(this.state.showCompSendMsg);
      return this.$t(translateKey, [this.state.showCompSendMsg.team || this.getNameForUuid(this.state.showCompSendMsg.uuid)]);
    },    
  }
};
</script>

<style scoped lang="scss">
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
</style>