var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.canInvite && !_vm.isMobile && !_vm.isIpad)?_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":false,"position-x":260,"position-y":120,"absolute":"","offset-y":"","max-height":"100%","max-width":"100%","content-class":"inviteVisitorMenu","top":!_vm.isNavBar,"bottom":_vm.isNavBar},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{class:{
            btnSendGuestInvitation:
              (_vm.typeInvite == 'guest' || _vm.typeInvite == 'user') && !_vm.isNavBar,
            buttonNavbar: _vm.isNavBar,
          },attrs:{"small":_vm.isNavBar,"icon":""}},[(!_vm.isSidebar)?_c('font-awesome-icon',{staticClass:"primary--text",style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'user-plus']}}):_vm._e(),_vm._v(" "),(_vm.isSidebar)?_c('font-awesome-icon',{staticClass:"primary--text",style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'plus-circle']}}):_vm._e(),_vm._v(" "),(_vm.showCustomTT)?_c('CustomTT',{attrs:{"text":((_vm.$t('components.hotkeys.controlKey')) + " + U"),"position":"top","extraclass":'ctru'}}):_vm._e()],1)],1)]}}],null,false,1347934867),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_vm._v(" "),_c('div',[_c('InviteGuestPanel',{attrs:{"closeMenu":_vm.closeMenu,"typeInvite":_vm.typeInvite,"fromRoot":_vm.fromRoot}})],1)]):_vm._e(),_vm._v(" "),(_vm.isIpad)?_c('v-btn',{class:{
      btnSendGuestInvitation:
        (_vm.typeInvite == 'guest' || _vm.typeInvite == 'user') && !_vm.isNavBar,
      buttonNavbar: _vm.isNavBar,
    },attrs:{"small":_vm.isNavBar,"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.dialogInvite = true}}},[(!_vm.isSidebar)?_c('font-awesome-icon',{style:({ fontSize: !_vm.isNavBar ? '20px' : '14px' }),attrs:{"icon":['fal', 'user-plus']}}):_vm._e(),_vm._v(" "),(_vm.isSidebar)?_c('font-awesome-icon',{style:({ fontSize: !_vm.isNavBar ? '20px' : '14px' }),attrs:{"icon":['fal', 'plus-circle']}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.dialogInvite)?_c('InviteGuestPanel',{attrs:{"typeInvite":_vm.typeInvite,"fromRoot":_vm.fromRoot}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }