<template>
  <v-dialog persistent v-model="showUploadUserPictureModal" max-width="570">
    <v-card>
      <HeaderModal
        :titleModal="$t('components.uploadUserPictureModal.providePhoto')"
        :closeModalFunction="closeUserProfileAndCamera"
      >
        <v-spacer></v-spacer>
      </HeaderModal>
      <div v-show="showCroppie">
        <v-row class="d-flex mx-auto pt-5 pb-2">
          <v-col cols="12" class="py-0">
            <vue-croppie
              ref="croppieRef"
              class="sliderSize"
              :enableResize="false"
              :enforceBoundary="true"
              :enableExif="true"
              :boundary="{ width: 256, height: 256 }"
              :viewport="{ width: 246, height: 246, type: 'square' }"
            ></vue-croppie>
          </v-col>
          <div class="rotateIcons">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="grey" @click="rotate(-90)">
                  <font-awesome-icon
                    :icon="['fal', 'undo']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.navbar.rotateLeft") }}</span>
            </v-tooltip>
            <br />
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="grey" @click="rotate(90)">
                  <font-awesome-icon
                    :icon="['fal', 'undo']"
                    :style="{ fontSize: '20px' }"
                    class="fa-flip-horizontal"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.navbar.rotateRight") }}</span>
            </v-tooltip>
          </div>
        </v-row>
        <v-card color="primary" class="py-2 pl-4 footerModal">
          <v-row class="px-2 btns" flex :style="{ width: '100%', margin: 0 }">
            <v-col class="px-0 py-0" align="right">
              <v-btn
                class="buttonPrimaryFooter"
                depressed
                color="white"
                @click="crop"
                >{{ $t("generics.save") }}</v-btn
              >
              <v-btn
                class="ml-3 mr-2"
                color="white"
                style="border-radius: 4px; min-width: 45px"
                icon
                tile
                outlined
                @click="cancelCrop"
              >
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '20px' }"
              /></v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-spacer></v-spacer>
      </div>
      <v-row flex :style="{ width: '100%', margin: 0 }" v-if="!showCroppie">
        <input
          type="file"
          ref="fileInput"
          class="d-none"
          @change="showCroppieModal($event)"
        />
        <v-col>
          <div align="center">
            <v-list-item-avatar tile size="150" class="borderRadius10">
              <template>
                <DefaultAvatar
                  :size="150"
                  :userData="state.user"
                  v-if="
                    !state.user.avatar ||
                    state.user.avatar === 'img/default_profile_picture.png'
                  "
                ></DefaultAvatar>
                <v-img
                  v-if="
                    state.user.avatar &&
                    state.user.avatar !== 'img/default_profile_picture.png'
                  "
                  class="borderRadius10"
                  max-height="150"
                  max-width="150"
                  contain
                  :src="state.user.avatar"
                  lazy-src="assets/default_profile_picture.png"
                ></v-img>
              </template>
              <div
                flex
                class="uploadAvatarButtons p-4 mx-auto"
                v-if="state.ownModalUser"
                :class="{
                  hasImage:
                    state.user.avatar != 'img/default_profile_picture.png',
                  hasNoImage:
                    state.user.avatar == 'img/default_profile_picture.png',
                }"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      depressed
                      v-on="on"
                      color="primary"
                      class="mb-2"
                      isSmall="true"
                      v-on:click="selectAvatar"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'upload']"
                        :style="{ fontSize: '20px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.navbar.uploadPicture") }}</span>
                </v-tooltip>
              </div>
            </v-list-item-avatar>
          </div>
        </v-col>
        <v-row :style="{ width: '100%', margin: 0 }">
          <v-col>
            <v-checkbox
              @change="updateDontShowAgain()"
              v-model="dontShowModal"
              :label="$t('generics.dontShowAgain')"
              hide-details
              class="mt-0"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-row>
      <FooterModal
        v-if="!showCroppie"
        :closeModalFunction="closeUserProfileAndCamera"
        :hideDefaultButtons="true"
      >
        <v-btn
          style="border-radius: 4px; min-width: 45px"
          class="ml-3 mr-2"
          tile
          @click="closeUserProfileAndCamera"
        >
          {{ $t("components.newVersionAvailable.later") }}
        </v-btn>
      </FooterModal>
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import DefaultAvatar from "../image/defaultAvatar.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  props: ["showUploadUserPictureModal", "toggleUploadUserPictureModal"],
  components: { DefaultAvatar, HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
    showCroppie: false,
    ownUUID: store.state.ownUUID,
    dontShowModal: store.state.user.dontShowAvatarMessage,
  }),
  methods: {
    updateDontShowAgain() {
      this.$set(this.state.user, "dontShowAvatarMessage", this.dontShowModal);
    },
    closeModal() {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (this.toggleModalSettings) {
        this.toggleModalSettings();
      }
    },
    closeUserProfileAndCamera() {
      this.toggleUploadUserPictureModal();
    },
    crop() {
      const options = {
        type: "base64",
        size: { width: 256, height: 256 },
        format: "jpg",
      };
      this.$refs.croppieRef.result(options, (output) => {
        store.setAvatar(output, this.state.user.uuid);
        this.showCroppie = false;
      });
    },
    cancelCrop() {
      this.showCroppie = false;
      this.resetInputAvatar();
    },
    result(output) {
      this.cropped = output;
    },
    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle);
    },
    selectAvatar() {
      this.$refs.fileInput.click();
    },
    resetInputAvatar() {
      this.$refs.fileInput.value = "";
    },
    showCroppieModal(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.croppieRef.bind({
          url: e.target.result,
        });
        this.showCroppie = true;
      };
      reader.readAsDataURL(files[0]);
      this.resetInputAvatar();
    },
  },
  computed: {
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.breakpoint;
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : xl ? [4, 8] : [12, 12];
    },
  },
};
</script>

<style scoped lang="scss">
.rotateIcons {
  position: absolute;
  right: 16%;
  top: 40%;
}
video {
  height: 256;
  width: 256;
  min-width: 256;
  border: 2px solid #f2f2f2;
  background: black;
}

.btns {
  justify-content: flex-end;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.uploadAvatarButtons {
  position: absolute;
  left: 0px;
  height: 150px;
  width: 150px;
  top: 0px;
  border-radius: 10px;
  text-align: center;
  padding-top: 100px;
  button {
    height: 33px !important;
    min-width: 45px !important;
    padding: 0 10px !important;
  }
  &.hasImage {
    background: #00000030;
    display: none;
    &:hover {
      display: block;
    }
  }
  &.hasNoImage {
    display: none;
    &:hover {
      display: block;
    }
  }
}
.borderRadius10 {
  &:hover > .hasImage {
    display: block;
  }
  &:hover > .hasNoImage {
    display: block;
  }
}
/*.croppie-container {
  height: unset;
  margin: 40px auto;
}*/
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: 2px solid #02889d !important;
}
</style>
<style lang="scss">
.sliderSize .cr-slider-wrap {
  width: 100%;
  margin: 15px auto;
}
.sliderSize .cr-slider {
  width: 256px;
}
</style>