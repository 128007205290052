import { render, staticRenderFns } from "./2faSendSmsModal.vue?vue&type=template&id=211de57a&scoped=true"
import script from "./2faSendSmsModal.vue?vue&type=script&lang=js"
export * from "./2faSendSmsModal.vue?vue&type=script&lang=js"
import style0 from "./2faSendSmsModal.vue?vue&type=style&index=0&id=211de57a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "211de57a",
  null
  
)

export default component.exports