var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"InviteFormContainer"},[_c('v-card',{staticClass:"pt-6 pl-10 pr-10"},[_c('v-tabs',{staticClass:"tabInvite pb-5",attrs:{"vertical":_vm.isMobile ? true : false},on:{"change":function($event){return _vm.tabChanged($event)}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"disabled":_vm.disabledConferenceTab}},[_vm._v(_vm._s(_vm.$t("components.groups.konferenzen"))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('font-awesome-icon',_vm._g({staticClass:"ml-1",style:({
                fontSize: '17px',
                paddingBottom: 2
              }),attrs:{"color":"#02889d","icon":['fal', 'info-circle']}},on))]}}])},[_vm._v(" "),_c('span',[_vm._v("\n             "+_vm._s(_vm.$t('components.conferences.tabTT.conference'))+"\n          ")])])],1),_vm._v(" "),(_vm.showConferenceHallTab)?_c('v-tab',{attrs:{"disabled":_vm.disabledConferenceHallTab}},[_vm._v(_vm._s(_vm.$t('components.conferenceForm.conferenceHalls'))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('font-awesome-icon',_vm._g({staticClass:"ml-1",style:({
                fontSize: '17px',
                paddingBottom: 2
              }),attrs:{"color":"#02889d","icon":['fal', 'info-circle']}},on))]}}],null,false,749669056)},[_vm._v(" "),_c('span',[_vm._v("\n             "+_vm._s(_vm.$t('components.conferences.tabTT.event'))+"\n          ")])])],1):_vm._e()],1),_vm._v(" "),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":0}},[[_c('ConferenceGrid',{ref:"confGrid",attrs:{"existingConference":_vm.existingConference,"updateGroup":_vm.updateGroup,"groupFromConference":_vm.groupFromConference,"sendMailToAll":_vm.sendMailToAll,"justSendMailToAll":_vm.justSendMailToAll,"callGroup":_vm.callGroup,"saveGroup":_vm.saveGroup,"sendUserMessageParent":_vm.sendUserMessage,"currentTab":_vm.tab == 0 ? 'normalConference' : 'hallConference'}})]],2),_vm._v(" "),(_vm.showConferenceHallTab)?_c('v-tab-item',{attrs:{"value":1}},[[_c('ConferenceHallForm',{ref:"confGrid",attrs:{"existingConference":_vm.existingConference,"updateGroup":_vm.updateGroup,"groupFromConference":_vm.groupFromConference,"sendMailToAll":_vm.sendMailToAll,"justSendMailToAll":_vm.justSendMailToAll,"callGroup":_vm.callGroup,"saveGroup":_vm.saveGroup,"sendUserMessageParent":_vm.sendUserMessage,"currentTab":_vm.tab == 0 ? 'normalConference' : 'hallConference'}})]],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }