<template>
  <v-dialog v-model="showVideoTestModal" persistent @keydown.esc="closeModal">
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('components.userProfile.videoTest')"
          :closeModalFunction="closeModal"
        />
        <v-card-text>
          <div class="d-flex justify-center align-center black heigth70">
            <CameraPreview :height="'100%'" :width="'100%'"></CameraPreview>
          </div>
        </v-card-text>
        <FooterModal :closeModalFunction="closeModal" />
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import {
  resetVideoTestModalEvent,
  videoTestModalStore,
} from "../../effector/modals";
import CameraPreview from "../camera/cameraPreview.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  components: {
    CameraPreview,
    HeaderModal,
    FooterModal,
  },
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
  }),
  effector: {
    showVideoTestModal: videoTestModalStore,
  },
  methods: {
    closeModal() {
      resetVideoTestModalEvent();
    },
  },
};
</script>

<style scoped lang="scss">
.heigth70 {
  height: 70%;
}
</style>