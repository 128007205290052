<template>
  <v-dialog v-if="visiblePrompt" v-model="showModalAudioPolicy" max-width="560">
    <template>
      <v-card class="mx-auto">
        <v-card-title class="headline" primary-title>
          <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
          {{ $t('components.audioPolicy.title') }}
        </v-card-title>
        <v-card-text>
          {{ $t('components.audioPolicy.text') }}
          <div class="mt-8 d-flex justify-center">
            <div class="mx-3 pointer">
              <v-btn class="primary" v-on:click="closeModal()">{{ $t('generics.ok') }}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";

export default {
  data() {
    return {
      state: store.state,
    }
  },
  mounted() {
    document.addEventListener("click", this.closeModal);
  },
  destroyed() {
    document.removeEventListener("click", this.closeModal);
  },
  methods: {
    closeModal() {
      const callback = (typeof this.showModalAudioPolicy === 'function') && this.showModalAudioPolicy;
      if (callback) callback();
      store.setShowModalAudioPolicy(false);
    },
  },
  computed: {
    showModalAudioPolicy() {
      return store.state.showModalAudioPolicy;
    },
    visiblePrompt() {
      return store.state.nerd || (typeof this.showModalAudioPolicy === 'function');
    }
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>