<template>
  <v-clappr
    class="playerClass"
    :class="{'preventClick': !isMobile}"
    el="mycustomid"
    ref="clappr" 
    :options="playerOptions"
    v-if="showPlayer && getSrc"
    :source="getSrc"
    @init="onInit"
    @error="onError"
  />
</template>
<script>
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api';
import MD5 from '../../../lib/md5';
import { jvbOptions } from "../../../lib/rtcSettings";
import store from "../../../store";
import { VClappr } from 'v-clappr';
import { isMobile } from "../../../lib/mobileUtil";

Vue.use(VueCompositionApi);

export default {
  props: ['setVideoClappr'],
  components: { VClappr },
  data() {
    return {
      state: store.state,
      clapprPlayer: null,
      showPlayer: true,
      isMobile: isMobile(),
      reloadTimeout: null,
      playerOptions:{
        width: '100%',
        height: '100%',
        autoPlay: true,
        mute: false,
        poster: 'assets/icon.png'
      }
    };
  },
  destroyed() {
    if (this.reloadTimeout) {
      clearTimeout(this.reloadTimeout);
      this.reloadTimeout = null;
    }
  },
  methods: {
    onInit(clappr){
      this.clapprPlayer = clappr
      this.clapprPlayer.setVolume(100)
      store.setCanAutoplayWithSound(true);
      this.setVideoClappr(clappr)
    },
    onError(error){
      if (this.reloadTimeout) clearTimeout(this.reloadTimeout);
      if (error && error.code === 'hls:networkError_manifestLoadError') {
        try {
          document.getElementsByClassName("player-error-screen__title")[0].innerText = this.$t('components.callsContent.pleaseWait');
          document.getElementsByClassName("player-error-screen__message")[0].innerText = this.$t('components.callsContent.eventStartingShortly');
          document.getElementsByClassName("player-error-screen__code")[0].innerText = '';
          document.getElementsByClassName("player-error-screen__reload")[0].innerHTML = '';
        } catch (e) {
          console.warn('Oops, failed to override the error message', e);
        }
      }
      this.reloadTimeout = setTimeout(() => {
        this.showPlayer = false;
        this.$nextTick(() => {
          this.showPlayer = true;
        });
      }, 3000);
    }
  },
  computed: {
    getSrc() {
      const callUUID = store.state.user.inBridgeCallListener
      if (callUUID) {
        const videoSrc = jvbOptions().recorder+'hls/'+window.location.host.replace(':', '_')+'.'+MD5(callUUID.replace(/-/g, ''))+'.m3u8';
        return videoSrc
      }
    }
  }
};
</script>
<style scoped lang="scss">
.playerClass{
  height: 100%;
  width: 100%;
}
.preventClick {
  pointer-events: none;
}
</style>
<style lang="scss">
.media-control.live{
  display: none!important;
}
</style>
