<template>
  <v-dialog
    v-model="showErrorInCallModal"
    persistent
    max-width="460"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal :titleModal="'Error'" :closeModalFunction="closeModal" />
      <v-card-text
        >{{$t("components.errorInCall.text")}}
        </v-card-text>
      <FooterModal :closeModalFunction="closeModal" />
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import {
  resetErrorInCallModalEvent,
  errorInCallModalStore,
} from "../../effector/modals";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
export default {
  props: ["errorInCall", "closeModalError"],
  components:{
     FooterModal,
    HeaderModal
  },
  data() {
    return {
      state: store.state,
    };
  },
  effector: {
    showErrorInCallModal: errorInCallModalStore,
  },
  methods: {
    closeModal() {
      resetErrorInCallModalEvent();
    },
  },
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
