<template>
<!-- UNSUED MODAL -->
  <v-dialog v-model="showModal" persistent max-width="480" @keydown.esc="closeFtpSetting">
    <v-card>
      <v-card-title class="headline">
        <v-img
          class="mr-1"
          src="assets/icon.png"
          max-height="30"
          max-width="30"
          contain
        ></v-img>
        {{$t('components.adminSettingsModal.remoteStorageftp')}}
      </v-card-title>
      <v-card-text
        >{{ $t('components.adminSettingsModal.confirmFtp') }}</v-card-text
      >
      <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns mx-0" flex>
          <v-col cols="8" class="px-0 py-0" align="right">
            <v-btn color="primary" @click="acceptFtp()">{{
              $t("generics.agree")
            }}</v-btn>
            <v-btn color="primary" @click="closeFtpSetting()">{{
              $t("generics.cancel")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import {
  callUUID /*, rtcHangup*/,
  // getWebrtcConnections
} from "../../lib/rtcConn";
import { setNamespaceSetting } from "../../lib/wsMsg";
export default {
  props: ["showModal", "closeModal", "cancelFtp"],
  data() {
    return {
      state: store.state,
    };
  },
  mounted() {},
  methods: {
    closeFtpSetting() {
      this.cancelFtp();
      this.closeModal();
    },
    acceptFtp() {
      setNamespaceSetting("activateFtp", true);
      this.closeModal();
    },
  },
  computed: {},
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
