 <template>
  <div class="dayTimeOverlay">
    <span class="mx-2">{{$t('components.conferenceForm.startDate')}}:&nbsp;{{ formatDate }}</span>
    <span class="mx-2">{{$t('components.conferenceForm.startTime')}}:&nbsp;{{ formatTime }}</span>
  </div>
</template>
<script>
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { getTimezone } from "../../utils/basicFunctions";
export default {
  props: ["conference"],
  data() {
    return {
      state: store.state,
    };
  },
  computed: {
    conferenceDateTime() {
      const conferenceData = this.conference;
      const conferenceISODate = (conferenceData.confStartDate + 'T' + conferenceData.confStartTime.split(':').map(e => e.padStart(2, '0')).join(':') + ':00' + (conferenceData.confTimezone || getTimezone(conferenceData.confStartDate, conferenceData.confStartTime)));
      return new Date(conferenceISODate);
    },
    formatDate(){
      return moment(this.conferenceDateTime).format('DD.MM.YYYY');
    },
    formatTime(){
      return moment(this.conferenceDateTime).format('HH:mm') + ' ' +  this.$t('components.meetingLineModal.hours');
    }
  },
  mounted: function () {},
};
</script>
<style scoped lang="scss">
.dayTimeOverlay {
  background-color: rgba(0, 0, 0, 0.50);
  position: absolute;
  top: 0px;
  display: grid;
  font-size: 14px;
  width: 100%;
}
.keepLineaBreak{
  white-space:pre-line
}
</style>