import store from '../store';

export function isGuestOrVisitor () {
  return (store.state.user.guest || store.state.user.visitor);
}

const guestVisitorPages = ['/', '/home'];

function routerAcl (router) {
  router.beforeEach((to, from, next) => {
    if (isGuestOrVisitor() && guestVisitorPages.indexOf(to.path) === -1) {
      return next('/home');
    }

    return next();
  });
  return router;
}

export default routerAcl;
