<template>
  <v-dialog v-model="showVotingModal" persistent max-width="560">
    <template>
      <v-card class="mx-auto">
        <HeaderModal :titleModal="'VideoCall Qualitätsbewertung'" />
        <v-card-text>
          Bewerten Sie die Qualität der Verbindung
          <div class="mt-8 d-flex justify-center">
            <v-rating
              v-model="rating"
              color="yellow darken-3"
              background-color="grey darken-1"
              empty-icon="$ratingFull"
              hover
              @input="rate()"
            ></v-rating>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import {
  resetQualityVotingModalEvent,
  qualityVotingModalStore,
  setRateMessageModalEvent,
} from "../../effector/modals";
import { voteQualityCall } from "../../lib/wsMsg";
export default {
  components: { HeaderModal },
  data: () => ({
    state: store.state,
    goodQuality: true,
    badQuality: false,
    closeTimeout: null,
    rating: 0,
    test: true,
  }),
  mounted() {
    document.addEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    this.closeTimeout = setTimeout(() => {
      this.rate();
    }, 5000);
  },
  destroyed() {
    document.removeEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
  },
  methods: {
    onKeyUp(event) {
      if (event.type === "keyup" && event.keyCode === 32) {
        this.closeModal();
      }
    },
    rate() {
      const finalResult = this.rating === 0 ? 5 : this.rating;
      voteQualityCall(finalResult);
      setRateMessageModalEvent(finalResult);
      this.closeModal();
    },
    closeModal() {
      store.state.user.activity = store.state.user.originalActivity;
      clearTimeout(this.closeTimeout);
      resetQualityVotingModalEvent();
    },
  },

  effector: {
    showVotingModal: qualityVotingModalStore,
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.thumbs-down {
  color: red;
}
.thumbs-up {
  color: green;
}
</style>