<template>
  <v-list max-width="320" @click.stop.prevent>
    <v-list-group
      v-bind:disabled="state.searchTerm !== '' ? true : false"
      v-if="!isGuest && !mini"
      active-class="listColor"
      :appendIcon="
        sortedUsers.length === 0 || state.showSearch ? null : '$expand'
      "
      @click="handlerClickUsers($event)"
      :value="state.searchTerm.length > 2 ? false : isActive ? true : undefined"
    >
      <template v-slot:activator class="reserveSpace">
        <v-list-item-icon :class="{ iconWithPL: mini }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <font-awesome-icon
                v-on="on"
                @click.stop.prevent="handlerClickUserIcon"
                class="primary--text cursorPointer"
                :icon="
                  currentPage('/users') ? ['fas', 'user'] : ['fal', 'user']
                "
                :class="`${getRoute === '/users' ? 'primary--text' : ''}`"
              />
            </template>
            <span>{{ $t("components.sidebar.userMiniTT") }}</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title
          v-if="!mini"
          class=" cursorPointer"
          @click.stop.prevent="showHideSearch"
        >
          <span v-if="!state.showSearch">
            {{ $t("generics.searchUser") }} <br />
            <hr class="hrUSers"
          /></span>
          <DrawerContentSearch v-if="!amIGuest && state.showSearch" />
          <v-tooltip
            top
            v-if="!isMobile && amIAdmin && !state.showSearch && !mini"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                class="btnAddGroup"
                v-bind:disabled="false"
                @click="openInviteUser"
              >
                <font-awesome-icon
                  class="primary--text"
                  :icon="['fal', 'plus-circle']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.sidebar.userTT") }}</span>
          </v-tooltip>
        </v-list-item-title>
        <CustomTT
          v-if="showCustomTT && !mini"
          :text="`${$t('components.hotkeys.controlKey')} + S`"
          position="bottom"
          :extraclass="'ctrs'"
        />
      </template>
      <UserList :persons="sortedUsers" :section="'Favorites'" />
    </v-list-group>
    <v-tooltip right v-if="!isGuest && mini">
      <template v-slot:activator="{ on }">
        <v-list-item v-on="on" class="cursorPointer">
          <v-list-item-icon
            :class="{ iconWithPL: mini }"
            @click.stop.prevent="handlerClickUserIcon"
          >
            <font-awesome-icon
              class="primary--text cursorPointer"
              :icon="currentPage('/users') ? ['fas', 'user'] : ['fal', 'user']"
              :class="`${getRoute === '/users' ? 'primary--text' : ''}`"
            />
          </v-list-item-icon>
        </v-list-item>
      </template>
      <span>{{ $t("components.sidebar.userMiniTT") }}</span>
    </v-tooltip>
  </v-list>
</template>

<script>
import store, { EventBus } from "../../store";
import UserList from "./userList.vue";
import InviteGuest from "../navbar/inviteGuest.vue";
import DrawerContentSearch from "./drawerContentSearch.vue";
import { isMobile, isIpad } from "../../lib/mobileUtil";

import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";
import { isConferenceCall } from "../../utils/calls";
import { checkListSort } from "../../utils/basicFunctions";
import { hasPrivilege } from "../../utils/privileges";

export default {
  props: ["mini", "openSidebar", "setList", "isGuest", "active"],
  components: {
    UserList,
    DrawerContentSearch,
    InviteGuest,
    CustomTT,
  },
  data: () => ({
    state: store.state,
    isIpad: isIpad(),
    isMobile: isMobile(),
    opennedList: false,
  }),
  effector: {
    showCustomTT: hotkeysInfoStore,
  },
  mounted() {
    EventBus.$on("focusSearchFieldEvent", this.showHideSearch);
  },
  destroyed(){
    EventBus.$off("focusSearchFieldEvent", this.showHideSearch);
  },
  methods: {
    handlerClickUsers(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.setList("users");
    },
    openInviteUser() {
      this.setCurrentContentVisile("invitationformuser", true, this.$router);
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    handlerClickUserIcon() {
      if (!this.mini) {
        if (this.canGoToUserPage) this.gotoPage("users");
      } else {
        this.openSidebar();
      }
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    gotoPage(route) {
      this.setCurrentContentVisile(route, true, this.$router);
    },
    showHideSearch() {
      if (!this.state.showSearch) {
        this.state.showSearch = !this.state.showSearch;
      }
    },
  },
  computed: {
    isActive() {
      return this.active == "users";
    },
    sortedUsers() {
      let persons = [];
      for (let uuid in store.state.group) {
        if (uuid) {
          const person = store.state.group[uuid];
          if (!person || !person.user || !hasPrivilege(uuid)) {
            continue;
          } else {
            persons.push({ ...person, uuid });
          }
        }
      }
      return checkListSort(persons, this.state.user.userSettings.usersSort);
    },
    isConference() {
      if (Object.keys(store.state.remoteBridgeStreams)[0])
        return isConferenceCall(Object.keys(store.state.remoteBridgeStreams));
    },
    canGoToUserPage() {
      if (this.amIAdmin || this.state.namespaceSettings.displayAllUsers) {
        return true;
      } else {
        return false;
      }
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    amIGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    getRoute() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
.reserveSpace {
  display: contents;
}
.hrUSers {
  // border-color: #8ec1cb;
  width: 80%;
}
.cursorPointer {
  cursor: pointer;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.theme--dark .listColor {
  color: white !important;
}
.theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>

<style lang="scss">
.usersListItemNav {
  .v-list-item__icon .v-list-group__header__append-icon {
    min-width: 35px !important;
  }
}
.ctrs {
  top: 65px !important;
  left: 15px;
}
</style>