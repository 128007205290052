<template>
  <div>
    <div class="d-flex align-center">
      <template v-if="!editingPhone">
        <div class="tex0t-truncate body-1 containerWrapper">
          <span :style="phoneNumberValid ? {} : { color: 'red' }">{{
            $t("components.2faSms.recoveryPhoneNumber")
          }}</span>
          {{ phoneNumber }}
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="toggle2faActivationModal()">
                <font-awesome-icon
                  :icon="['fal', 'pen']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span v-if="phoneNumberValid">{{
              $t("components.2faSms.editPhoneNumber")
            }}</span>
            <span v-else>{{
              $t("components.2faSms.phoneNumberRequired")
            }}</span>
          </v-tooltip>
          <div>
            <span>{{
              $t("components.2faSms.warningMsg")
            }}</span>

          </div>
          <div class="d-flex" v-if="!phoneNumberValid">
            <span slot="label">{{
              $t("components.2faSms.userPhoneNumberLabel")
            }}</span>
            <v-checkbox @change="toggle2faActivationModal()" class="mt-0 py-0 px-1" v-model="dontUsePhone">
            </v-checkbox>
          </div>
        </div>
      </template>
      <template v-if="editingPhone">
        <vue-tel-input
          v-model="phoneNumberInput"
          required
          defaultCountry="DE"
          enabledCountryCode
          @onInput="onTelInput"
          name="number"
          id="numDisplay"
          class="form-control text-center input-sm inputPhone2FA"
          value
          placeholder
          autocomplete="off"
          :class="{ borderError: !phoneInputValid }"
        ></vue-tel-input>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              class="mt-3"
              @click="savePhone()"
              :disabled="!phoneInputValid"
              color="primary"
            >
              <font-awesome-icon
                :icon="['fal', 'check']"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("generics.save") }}</span>
        </v-tooltip>
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon class="my-3" @click="cancelPhone()">
              <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
            </v-btn>
          </template>
          <span>{{ $t('generics.cancel') }}</span>
        </v-tooltip> -->
      </template>
    </div>
    <TwoFactorActivationModal
      v-if="showActivation"
      :showActivation="showActivation"
      :toggle2faActivationModal="toggle2faActivationModal"
      :saveEntryNoPhone="saveEntryNoPhone"
    ></TwoFactorActivationModal>
  </div>
</template>

<script>
import store from "../../../store";
import TwoFactorActivationModal from "../../2fa/modals/steps/2faActivationModal.vue";
export default {
  components: { TwoFactorActivationModal },
  data() {
    return {
      state: store.state,
      showActivation: false,
      editingPhone: false,
      phoneNumberInput: "",
      phoneNumberParsed: "",
      phoneInputValid: true,
    };
  },
  computed: {
    dontUsePhone: {
      get: function () {
        return this.state.persisted.ignoreRecoveryPhone || false;
      },
      set: function (newValue) {
        this.$set(this.state.persisted, "ignoreRecoveryPhone", newValue);
      },
    },
    phoneNumber: {
      get: function () {
        return this.state.user.recoveryPhone;
      },
      set: function (newValue) {
        this.$set(this.state.user, "recoveryPhone", newValue);
      },
    },
    phoneNumberValid() {
      return (
        this.phoneNumber &&
        typeof this.phoneNumber === "string" &&
        this.phoneNumber.startsWith("+") &&
        /^\+\d+$/.test(this.phoneNumber)
      );
    },
  },
  methods: {
    toggle2faActivationModal() {
      this.showActivation = !this.showActivation;
    },
    saveEntryNoPhone() {
      this.showActivation = false;
    },
    editPhone() {
      this.phoneNumberInput = this.phoneNumber;
      this.phoneNumberParsed = this.phoneNumber;
      this.phoneInputValid = this.phoneNumberValid;
      this.editingPhone = true;
    },
    onTelInput({ number, isValid, country }) {
      if (isValid) {
        this.phoneNumberParsed = number.international.replace(/[-\s]/g, "");
      }
      this.phoneInputValid = isValid;
    },
    savePhone() {
      this.phoneNumber = this.phoneNumberParsed;
      this.editingPhone = false;
    },
    cancelPhone() {
      this.editingPhone = false;
      this.phoneNumberInput = this.phoneNumber;
    },
  },
};
</script>

<style scoped lang="scss">
.containerWrapper {
  text-align: left;
}
</style>