import Handsontable from 'handsontable';
import { includes, toLower, startsWith } from 'lodash';

// === START https://github.com/sabbiu/Handsontable-Multiselect
const separator = ',';
const height_of_multiselect = 300;
const CustomEditor = Handsontable.editors.BaseEditor.prototype.extend();
let query = '';

function isCharacter (event) {
  return startsWith(event.code, 'Key') ||
    startsWith(event.code, 'Digit') ||
    includes(['Slash', 'Period'], event.code) ||
    includes([192, 222, 186, 219], event.keyCode);
}

// ...rest of the editor code
CustomEditor.prototype.init = function () {
  // Create detached node, add CSS class and make sure its not visible
  this.select = document.createElement('SELECT');
  this.select.setAttribute('multiple', true);
  this.select.style = 'position: absolute;';
  Handsontable.dom.addClass(this.select, 'htCustomEditor');
  this.select.style.display = 'none';

  // Attach node to DOM, by appending it to the container holding the table
  this.instance.rootElement.appendChild(this.select);

  this.select.addEventListener('keydown', event => {
    if (event.code === 'Backspace') {
      query = query.slice(0, -1);
    } else if (event.code === 'Escape') {
      if (query) {
        query = '';
      } else {
        this.close();
        this.hot.selectCell(this.row, this.col + 1);
      }
    } else if (isCharacter(event)) {
      query = query + event.key;
      event.preventDefault();
    }
    this.select.children.forEach(option => {
      option.style.display = includes(toLower(option.value), query) ? 'block' : 'none';
    });
  });
};

CustomEditor.prototype.focus = function () {
  // console.log('Lol focus');
  this.select.focus();
};

// Create options in prepare() method
CustomEditor.prototype.prepare = function () {
  // Remember to invoke parent's method
  Handsontable.editors.BaseEditor.prototype.prepare.apply(this, arguments);

  var selectOptions = this.cellProperties.selectOptions;
  var options;

  if (typeof selectOptions === 'function') {
    options = this.prepareOptions(selectOptions(this.row, this.col, this.prop));
  } else {
    options = this.prepareOptions(selectOptions);
  }
  Handsontable.dom.empty(this.select);

  for (var option in options) {
    if (options.hasOwnProperty(option)) {
      var optionElement = document.createElement('OPTION');
      optionElement.value = option;
      optionElement.addEventListener('mousedown', (event) => {
        event.preventDefault();
        if (this.prop === 'supervisor') {
          event.srcElement.parentElement.selectedIndex = -1;
          event.srcElement.selected = !event.srcElement.selected;
          this.close();
          this.hot.selectCell(this.row, this.col + 1);
        } else {
          var offset = event.srcElement.parentElement.scrollTop;
          event.srcElement.selected = !event.srcElement.selected;
          setTimeout(function () { event.srcElement.parentElement.scrollTop = offset; }, 0);
        }
      });
      Handsontable.dom.fastInnerHTML(optionElement, options[option]);
      this.select.appendChild(optionElement);
    }
  }
};

CustomEditor.prototype.prepareOptions = function (optionsToPrepare) {
  var preparedOptions = {};

  if (Array.isArray(optionsToPrepare)) {
    for (var i = 0, len = optionsToPrepare.length; i < len; i++) {
      preparedOptions[optionsToPrepare[i]] = optionsToPrepare[i];
    }
  } else if (typeof optionsToPrepare === 'object') {
    preparedOptions = optionsToPrepare;
  }

  // console.log('prepare options', preparedOptions, optionsToPrepare);

  return preparedOptions;
};

CustomEditor.prototype.getValue = function () {
  // return this.select.value;
  var result = [];
  var options = this.select && this.select.options;
  var opt;

  for (var i = 0, iLen = options.length; i < iLen; i++) {
    opt = options[i];

    if (opt.selected) {
      result.push(opt.value || opt.text);
    }
  }

  return result.join(separator);
};

CustomEditor.prototype.setValue = function (value) {
  // console.log(value)
  var self = this;
  var array = value.split(separator);
  // console.log(array)
  // array.forEach(function(item) {

  //   self.select.value = item;
  // });
  // self.select.value = array;

  // console.log(self.select.length)
  for (var i = 0; i < self.select.length; i++) {
    self.select.options[i].selected = false;

    array.forEach(function (arrayItem) {
      if (self.select.options[i].value === arrayItem) {
        self.select.options[i].selected = true;
      }
    });
  }
};

const onBeforeKeyDown = function (event) {
  var instance = this; // context of listener function is always set to Handsontable.Core instance
  var editor = instance.getActiveEditor();
  var selectedIndex = editor.select.selectedIndex;

  Handsontable.dom.enableImmediatePropagation(event);

  switch (event.keyCode) {
    case Handsontable.helper.keyCode.ARROW_UP:
      var previousOption = editor.select.options[selectedIndex - 1];

      if (previousOption) { // if previous option exists
        editor.select.value = previousOption.value; // mark it as selected
      }

      event.stopImmediatePropagation(); // prevent EditorManager from processing this event
      event.preventDefault(); // prevent browser from scrolling the page up
      break;

    case Handsontable.helper.keyCode.ARROW_DOWN:
      var nextOption = editor.select.options[selectedIndex + 1];

      if (nextOption) { // if previous option exists
        editor.select.value = nextOption.value; // mark it as selected
      }
      event.stopImmediatePropagation(); // prevent EditorManager from processing this event
      event.preventDefault(); // prevent browser from scrolling the page up
      break;
  }
};

CustomEditor.prototype.close = function () {
  this.select.style.display = 'none';
  this.instance.removeHook('beforeKeyDown', onBeforeKeyDown);
};

CustomEditor.prototype.open = function ($event) {
  query = '';
  var width = Handsontable.dom.outerWidth(this.TD);
  // important - group layout reads together for better performance
  var height = Handsontable.dom.outerHeight(this.TD);
  var rootOffset = Handsontable.dom.offset(this.instance.rootElement);
  var tdOffset = Handsontable.dom.offset(this.TD);
  var editorSection = this.checkEditorSection();
  var cssTransformOffset;

  const scrollTop = this.instance.table.parentNode.parentNode.parentNode.scrollTop || 0;
  const scrollLeft = this.instance.table.parentNode.parentNode.parentNode.scrollLeft || 0;

  if (this.instance.view.wt.wtScrollbars) {
    switch (editorSection) {
      case 'top':
        cssTransformOffset = Handsontable.dom.getCssTransform(this.instance.view.wt.wtScrollbars.vertical.clone.wtTable.holder.parentNode);
        break;
      case 'left':
        cssTransformOffset = Handsontable.dom.getCssTransform(this.instance.view.wt.wtScrollbars.horizontal.clone.wtTable.holder.parentNode);
        break;
      case 'corner':
        cssTransformOffset = Handsontable.dom.getCssTransform(this.instance.view.wt.wtScrollbars.corner.clone.wtTable.holder.parentNode);
        break;
    }
  }
  var selectStyle = this.select.style;

  if (cssTransformOffset && cssTransformOffset !== -1) {
    selectStyle[cssTransformOffset[0]] = cssTransformOffset[1];
  } else {
    Handsontable.dom.resetCssTransform(this.select);
  }

  selectStyle.height = height + height_of_multiselect + 'px';
  selectStyle.minWidth = width + 'px';
  selectStyle.top = tdOffset.top - rootOffset.top - scrollTop + 'px';
  selectStyle.left = tdOffset.left - rootOffset.left - scrollLeft + 'px';
  selectStyle.margin = '0px';
  selectStyle.display = '';

  this.instance.addHook('beforeKeyDown', onBeforeKeyDown);
};

CustomEditor.prototype.checkEditorSection = function () {
  if (this.row < this.instance.getSettings().fixedRowsTop) {
    if (this.col < this.instance.getSettings().fixedColumnsLeft) {
      return 'corner';
    } else {
      return 'top';
    }
  } else {
    if (this.col < this.instance.getSettings().fixedColumnsLeft) {
      return 'left';
    }
  }
};

// === END https://github.com/sabbiu/Handsontable-Multiselect

export default CustomEditor;

// class CustomEditor extends Handsontable.editors.TextEditor {
//   constructor(props) {
//     super(props);
//   }

//   createElements() {
//     super.createElements();

//     this.TEXTAREA = document.createElement('input');
//     this.TEXTAREA.setAttribute('placeholder', 'Custom placeholder');
//     this.TEXTAREA.className = 'handsontableInput';
//     this.textareaStyle = this.TEXTAREA.style;
//     Handsontable.dom.empty(this.TEXTAREA_PARENT);
//     this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
//   }
// }
