<template >
  <div style="overflow-y: auto">
    <div id="inviteVisitorMenu">
      <v-list class="invitationMenuList" dense @click.stop.prevent>
        <v-img
          class="mr-2 officeIcon"
          src="assets/icon.png"
          max-height="30"
          max-width="30"
          contain
        ></v-img>
        <div class="inviteHeader py-2" @click.stop.prevent>
          <font-awesome-icon
            :icon="['fal', 'user-friends']"
            :style="{ fontSize: '20px' }"
            class="cursorPointer mr-1 logoModal"
            color="white"
          />
          <!-- <v-img
            class="mr-1 logoModal"
            src="assets/icon.png"
            max-height="30"
            max-width="30"
            contain
          ></v-img> -->
          <span flat class="ml-auto inviteTextTitle">{{
            $t("components.inviteVisitor.title")
          }}</span>
          <v-tooltip top max-width="300" v-if="isAdvanced">
            <template v-slot:activator="{ on }" class="mt-17">
              <div class="invitationList" v-on="on">
                <v-select
                  :items="getvisitors"
                  hide-details
                  item-text="name"
                  :label="`(${getvisitors.length}) ${$t(
                    'components.inviteVisitor.inviteList'
                  )}`"
                  dense
                  class="mr-4 mt-1 headselect"
                  :selectable="false"
                >
                  <template slot="selection"> </template>
                  <template slot="item" slot-scope="data">
                    <v-btn
                      :loading="isdeleting"
                      icon
                      color="primary"
                      style="cursor: pointer; z-index: 99999"
                      class="btnRed mx-0"
                    >
                      <DeleteBasket :indexData="data" :delFunction="removeInvitation" extraClasses="" fontSize="20" prevent= true /> 
                    </v-btn>
                    <span @click.stop.prevent>
                      {{ $t("components.inviteVisitor.date") }}:&nbsp;
                      {{ data.item.date }} &nbsp;{{
                        $t("components.inviteVisitor.name")
                      }}:&nbsp;{{ data.item.name }} &nbsp;{{
                        $t("components.inviteVisitor.email")
                      }}:&nbsp;{{ data.item.email }}
                    </span>
                  </template>
                </v-select>
              </div>
            </template>
            <span>{{ $t("generics.myVisitorsToolTip") }}</span>
          </v-tooltip>
        </div>
        <v-divider></v-divider>
        <div class="generalDiv">
          <v-row>
            <v-row class="mx-0" style="height: 65px; margin-top: 5px">
              <v-col class="col-12 px-1 pb-0 pt-2" style="margin-bottom: 13px">
                <v-row>
                  <v-col cols="8">
                    <p
                      style="color: white; line-height: 2.5em"
                      class="mb-0 font-weight-bold labelTitle bannerTextLeft"
                    >
                      {{ $t("components.inviteVisitor.titleInvitationMenu") }}
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p
                      style="color: white; line-height: 2.5em"
                      class="mb-0 font-weight-bold labelTitle bannerTextLeft"
                    >
                      {{ $t("components.inviteVisitor.videoCallTo") }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="8">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }" class="mt-17">
                        <v-combobox
                          v-on="on"
                          v-model="userSelected"
                          :items="getAssistsList"
                          select
                          :search-input.sync="searchUsers"
                          item-text="name"
                          class="mt-5 ml-0 mx-1 label600"
                          dense
                          auto-select-first
                          @change="searchUsers = ''"
                          hide-details="auto"
                        ></v-combobox>
                      </template>
                      <span>{{
                        $t("components.inviteVisitor.oneUserSelected")
                      }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="inviteeType"
                      @change="inviteTypeChange($event)"
                      col
                      class="rbgwaiting mt-2"
                      row
                    >
                      <v-radio
                        :style="{
                          fontZise: 14,
                          fontWeight: 500,
                          marginRight: 5,
                        }"
                        :disabled="isSupportCall || multiUser"
                        class="mt-0"
                        hide-details="true"
                        :label="getTitleWaitingROOM"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        :style="{
                          marginRight: 5,
                          marginLeft: 0,
                          fontZise: 14,
                          fontWeight: 500,
                        }"
                        :disabled="isSupportCall || multiUser"
                        :label="$t('generics.user')"
                        class="mt-0"
                        hide-details="true"
                        value="0"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row style="margin-top: 10px">
              <!-- /////// Name ///////// -->
              <!-- </v-col> -->
              <v-col cols="8" class="mt-12">
                <p
                  style="color: white; line-height: 2.5em"
                  class="mb-0 font-weight-bold labelTitle bannerTextLeft"
                >
                  {{ getTextForInvites }}
                </p>
              </v-col>
              <v-col
                cols="4"
                class="mt-11 mb-5"
                v-if="isWhatsAppInviteAvailable"
              >
                <v-radio-group
                  @change="getText()"
                  :disabled="disableRadioEmailWhatsApp"
                  v-model="invitationSend"
                  row
                  class="rbgwaiting"
                >
                  <v-radio value="email" class="mt-0" hide-details="true">
                    <template v-slot:label>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <p v-on="on" class="mb-0 font-weight-bold labelTitle">
                            {{ $t("components.inviteVisitor.invitationEmail") }}
                          </p>
                        </template>
                        <span>
                          {{
                            $t("components.inviteVisitor.invitationEmail")
                          }}</span
                        >
                      </v-tooltip>
                    </template>
                  </v-radio>
                  <v-radio class="mt-0" hide-details="true" value="phone">
                    <template v-slot:label>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <p v-on="on" class="mb-0 font-weight-bold labelTitle">
                            {{ $t("components.inviteVisitor.whatsappPhone") }}
                          </p>
                        </template>
                        <span>{{
                          $t("components.inviteVisitor.whatsappPhone")
                        }}</span>
                      </v-tooltip>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="col-4 py-1 pt-0" style="margin-top: 10px">
                <v-text-field
                  @blur="applyRules = true"
                  v-model="name"
                  required
                  :rules="applyRules ? userNameRules : noRules"
                  :label="$t('components.inviteVisitor.name')"
                  dense
                ></v-text-field>
              </v-col>
              <!-- /////// Email ///////// -->
              <v-col
                v-if="invitationSend === 'email'"
                class="col-4 px-1 py-1 pt-0"
                style="margin-top: 10px"
              >
                <div class="d-flex">
                  <v-text-field
                    v-model="email"
                    required
                    :rules="applyRules && !phoneNumber ? emailRules : noRules"
                    ref="comboEmail"
                    :disabled="phoneNumber.length > 0"
                    @blur="applyRules = true"
                    :label="$t('components.inviteVisitor.invitationEmail')"
                    dense
                  ></v-text-field>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="cursorPointer"
                        color="black"
                        v-on="on"
                        icon
                        @click.stop.prevent="addUserToList()"
                        :disabled="( !name || name.length == 0) || isEmailInvalid"
                      >
                        <!-- :disabled=" emailRules ? isValidSearch(email) : null" -->
                        <!-- :disabled="email.length > 0" -->
                        <font-awesome-icon
                          v-on="on"
                          :icon="['fal', 'save']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      $t("components.inviteVisitor.ttSaveDiskette")
                    }}</span>
                  </v-tooltip>
                </div>

              </v-col>
              <v-col
                v-if="invitationSend === 'phone'"
                class="col-4 px-1 py-1 pt-0 positionRelative"
                style="margin-top: 2px"
              >
                <div class="d-flex">
                  <vue-tel-input
                    v-model="phoneNumberInput"
                    defaultCountry="DE"
                    enabledCountryCode
                    @onInput="onTelInput"
                    name="number"
                    id="numDisplay"
                    class="form-control text-center input-sm inputPhone2FA"
                    value
                    placeholder
                    autocomplete="off"
                    :class="{ borderError: !phoneInputValid }"
                  ></vue-tel-input>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="cursorPointer mt-2"
                        color="black"
                        v-on="on"
                        icon
                        @click.stop.prevent="addUserToList()"
                        :disabled="(!name || name.length == 0) ||
                          (!phoneNumberInput || phoneNumberInput.length == 0)
                        "
                      >
                        <font-awesome-icon
                          v-on="on"
                          :icon="['fal', 'save']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      $t("components.inviteVisitor.ttSaveDiskette")
                    }}</span>
                  </v-tooltip>
                </div>
              </v-col>

              <v-col class="col-4 px-1 py-1 pt-0" style="margin-top: 10px">
                <v-combobox
                  v-model="contactSelected"
                  :items="filteredInvitations"
                  item-text="name"
                  hide-details="auto"
                  :label="$t('components.inviteVisitor.contacts')"
                  select
                  dense
                  :menu-props="{ closeOnContentClick: true }"
                >
                  <template slot="item" slot-scope="data">
                    <template>
                      <v-list-item
                        @click="handleSelectEmail(data.item)"
                        style="position: relative"
                      >
                        <v-list-item-content class="pt-0">
                          <v-list-item-title
                            v-text="data.item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle 
                            v-text="invitationSend === 'email' ? data.item.email : data.item.phone"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                        <DeleteBasket :indexData="data.item" :delFunction="removeUserFromList" extraClasses="cursorPointer removeUserBasket" fontSize="15" prevent= true />  
                      </v-list-item>
                    </template>
                  </template>
                </v-combobox>
              </v-col>

              <!-- participants -->
              <v-col
                class="col-12 px-1 py-1 pt-0"
                v-if="inviteList.length > 0"
                style="border-bottom: 1px solid #00000066"
              >
                <div
                  style="display: flex"
                  v-for="(user, idx) in inviteList"
                  :key="idx"
                >
                  <v-col class="col-4 px-1 py-1 pt-0 participantsList">
                    <span>
                      {{ $t("components.inviteVisitor.name") }}:
                      {{ user.name }}</span
                    >
                  </v-col>
                  <v-col class="col-4 px-1 py-1 pt-0 participantsList">
                    <span v-if="user.email">
                      {{ $t("components.inviteVisitor.invitationEmail") }}:
                      {{ user.email }}</span
                    >
                    <span v-if="user.mobilePhone">
                      {{ $t("generics.whatsapp") }}:
                      {{ user.mobilePhone }}</span
                    >
                  </v-col>
                  <v-col
                    style="text-align: end"
                    class="col-4 px-1 py-1 pt-0 participantsList"
                  >
                    <v-btn
                      style="margin-top: -5px"
                      icon
                    >
                    <DeleteBasket :indexData="user.email" :delFunction="removeInviteeFromList" extraClasses="" fontSize="20" prevent= false />  
                    </v-btn>
                  </v-col>
                </div>
                <v-spacer></v-spacer>
              </v-col>
            </v-row>

            <!-- New position checkbox -->
            <v-row class="mx-0 pb-0">
              <!-- <v-col class="col-12 pl-0 py-0">
              <p>{{$t('components.inviteVisitor.function')}}</p>
            </v-col> -->
              <v-col class="col-6 pl-0 py-0">
                <v-text-field
                  v-model="visFunction"
                  required
                  :label="$t('components.inviteVisitor.function')"
                  dense
                ></v-text-field>
              </v-col>
              <!-- paylink -->
              <v-col
                v-if="isProVersion && invitationSend === 'email'"
                class="col-6 px-1 py-1 pt-0"
              >
                <v-text-field
                  v-model="paylinkAmount"
                  :label="'Paylink'"
                  dense
                  type="number"
                >
                  <template v-slot:append>
                    <font-awesome-icon :icon="['fal', 'euro-sign']" :style="{ fontSize: '15px' }"/> <!-- v-if="state.namespaceSettings.currency === 'EUR'" -->
                    <!-- <font-awesome-icon :icon="['far', 'pound-sign']" :style="{ fontSize: '15px' }" v-if="state.namespaceSettings.currency === 'GBP'"/>
                    <v-img
                      contain
                      height="20"
                      v-if="state.namespaceSettings.currency === 'CHF'"
                      src="img/paymenticons/swiss-franc-black.png"
                      lazy-src="img/paymenticons/swiss-franc.png"
                    ></v-img> -->
                  </template>
                </v-text-field>
              </v-col>
              <!-- end paylink -->
            </v-row>

          </v-row>
          <v-row>
            <v-col :cols="isAdvanced ? 6 : 12" class="px-0 py-0">
              <v-list dense class="py-0 px-0">
                <v-list-item class="px-0">
                  <v-list-item-title @click.stop.prevent>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <!-- MIMI 1 -->
                      <v-row class="mx-0 pb-0">
                        <!-- <v-col class="pl-0 py-0"><p
                            style="margin-left: 6px; margin-bottom: 0px !important;"
                          >{{$t('components.inviteVisitor.videoCallTo')}}</p></v-col> -->
                        <v-col class="ml-1 px-1 py-0" v-if="isAdvanced">
                          <p
                            style="
                              margin-left: -8px;
                              margin-bottom: 0px !important;
                            "
                          >
                            {{ $t("components.2faModal.pinLabel") }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-row class="mx-0 pb-0">
                        <!-- <v-col class="py-0">
                          <v-row>
                            <v-radio-group
                              v-model="inviteeType"
                              @change="inviteTypeChange($event)"
                              col
                              class="rbgwaiting"
                            >
                            
                              <v-radio
                                :style="{marginRight:5, marginLeft:0, fontZise:14,fontWeight: 500}"
                                :disabled="isSupportCall || multiUser"
                                :label="$t('generics.user')"
                                class="mt-0"
                                hide-details="true"
                                value="0"
                              ></v-radio>
                      


                      
                              <v-radio
                                :style="{fontZise:14,fontWeight: 500, marginRight:5 }"
                                :disabled="isSupportCall || multiUser"
                                class="mt-0"
                                hide-details="true"
                                :label="getTitleWaitingROOM"
                                :value="1"
                              ></v-radio>
                    
                            </v-radio-group>
                          </v-row>
                        </v-col> -->

                        <v-col
                          class="px-1 py-0 mb-5 mimiTest"
                          v-if="isAdvanced"
                        >
                          <!-- v-model="pinActive" @change="pinActive ? complexPinActive = false : complexPinActive = complexPinActive; pinCode = null"-->
                          <v-radio-group
                            v-model="pinType"
                            @change="invitePinChange($event)"
                            row
                            class="rbgwaiting"
                          >
                            <v-radio
                              value="0"
                              class="w100 mt-0"
                              hide-details="true"
                            >
                              <template v-slot:label>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <p
                                      v-on="on"
                                      class="mb-0 font-weight-bold labelTitle"
                                    >
                                      {{
                                        $t("components.inviteGuestPanel.noPin")
                                      }}
                                    </p>
                                  </template>
                                  <span>
                                    {{
                                      $t("components.inviteGuestPanel.noPin")
                                    }}</span
                                  >
                                </v-tooltip>
                              </template>
                            </v-radio>

                            <v-radio
                              class="w100 mt-0"
                              hide-details="true"
                              value="1"
                            >
                              <template v-slot:label>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <p
                                      v-on="on"
                                      class="mb-0 font-weight-bold labelTitle"
                                    >
                                      {{
                                        $t("components.2faModal.pinProtection")
                                      }}
                                    </p>
                                  </template>
                                  <span>{{
                                    $t(
                                      "components.inviteGuestPanel.pinProtectionTT"
                                    )
                                  }}</span>
                                </v-tooltip>
                              </template>
                            </v-radio>

                            <v-radio
                              value="2"
                              class="w100 mt-0"
                              hide-details="true"
                            >
                              <template v-slot:label>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <p
                                      v-on="on"
                                      class="mb-0 font-weight-bold labelTitle"
                                    >
                                      {{ $t("components.2faModal.complexPin") }}
                                    </p>
                                  </template>
                                  <span>{{
                                    $t(
                                      "components.inviteGuestPanel.complexPinTT"
                                    )
                                  }}</span>
                                </v-tooltip>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row
                        class="mx-0 pb-0"
                        style="margin-top: 40px"
                        v-if="pinActive || complexPinActive"
                      >
                        <v-col
                          style="margin-top: 10px"
                          class="col-11 px-1 py-0"
                        >
                          <v-text-field
                            autocomplete="off"
                            v-model="pinCode"
                            :label="
                              pinActive
                                ? $t(
                                    'components.inviteGuestPanel.enterInvitationPinCode'
                                  )
                                : $t(
                                    'components.inviteGuestPanel.enterComplexInvitationPinCode'
                                  )
                            "
                            dense
                            @keydown.space.prevent
                            @blur="checkPin"
                            :maxlength="complexPinActive ? 12 : 6"
                            :rules="
                              complexPinActive
                                ? [
                                    (v) => !!v || '',
                                    (v) => (v && v.length >= 12) || '',
                                    (v) =>
                                      complexPinRegex.test(v) ||
                                      this.$t(
                                        'components.inviteGuestPanel.ttComplexPinRequirements'
                                      ),
                                  ]
                                : [
                                    (v) => !!v || '',
                                    (v) => (v && v.length === 6) || '',
                                    (v) =>
                                      /^\d{6}$/.test(v) ||
                                      this.$t(
                                        'components.2faPin.sixDigitsRequired'
                                      ),
                                  ]
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col class="col-1 px-1 py-0">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" icon class="my-3 btnPinDet">
                                <font-awesome-icon
                                  :icon="['fal', 'question-circle']"
                                  :style="{ fontSize: '15px' }"
                                />
                              </v-btn>
                            </template>
                            <span v-if="complexPinActive">{{
                              $t(
                                "components.inviteGuestPanel.ttComplexPinRequirements"
                              )
                            }}</span>
                            <span v-else>{{
                              $t(
                                "components.inviteGuestPanel.ttPinRequirements"
                              )
                            }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>

                      <!-- new position checkbox end -->
                      <v-row style="width: 100%; margin: 0" class="mx-0">
                        <!-- /////// Subject ///////// -->
                        <v-col
                          class="col-12 px-0 py-0"
                          :class="{ textAreaSubjectAdvanced: isAdvanced }"
                        >
                          <v-list class="py-0">
                            <!-- <v-col cols="12" class="col-8 px-1 py-0">
                              <p
                                style="color: white; line-height: 2.5em"
                                class="mb-0 font-weight-bold labelTitle bannerText"
                              >
                                {{ $t("components.inviteVisitor.subject") }}
                              </p>
                            </v-col> -->
                            <v-col
                              cols="12"
                              class="col-8 py-0"
                              :class="{
                                'px-1': isAdvanced,
                                'px-0': !isAdvanced,
                              }"
                            >
                              <v-textarea
                                v-model="subject"
                                name="subjectinput"
                                :no-resize="true"
                                class="py-0 my-0 bolder textAreaSubject"
                                :rows="isAdvanced ? 1 : 4"
                                counter
                                maxlength="80"
                                auto-grow
                                style="font-size: 14px"
                                :label="$t('components.inviteVisitor.subject')"
                              />
                            </v-col>
                          </v-list>
                        </v-col>
                        <v-col
                          class="col-12 px-0 py-0"
                          style="height: 79px"
                          v-if="isAdvanced"
                        >
                          <v-list class="py-0">
                            <v-col
                              cols="12"
                              style="margin-top: 6px"
                              class="col-8 px-1 py-0"
                            >
                              <p
                                style="color: white; line-height: 2.5em"
                                class="mb-0 font-weight-bold labelTitle bannerText"
                              >
                                {{ $t("components.inviteVisitor.notiz") }}
                              </p>
                            </v-col>
                            <v-col cols="12" class="col-8 px-1 py-0">
                              <v-textarea
                                v-model="internalnote"
                                name="noteinput"
                                :no-resize="true"
                                class="py-0 my-0"
                                rows="1"
                                counter
                                maxlength="80"
                                @click.stop.prevent
                              />
                            </v-col>
                          </v-list>
                        </v-col>
                      </v-row>

                      <!-- TIME -->
                      <!-- <v-radio-group class="mt-0" v-model="radioGroup" style="height: 176px"> invitePinChange -->
                      <v-row class="mx-0" v-if="isAdvanced">
                        <v-col class="col-8 px-1 py-0">
                          <v-checkbox
                            v-model="callPeriod"
                            class="checkCallPeriod"
                            @change="toggleCallPeriod()"
                          >
                            <template v-slot:label>
                              <p class="mb-0 font-weight-bold labelTitle">
                                {{
                                  $t("components.inviteVisitor.videoCallTime")
                                }}
                              </p>
                            </template>
                          </v-checkbox>
                          <v-combobox
                            class="mt-2"
                            v-model="appmtOptionselect"
                            :items="combinedAppointmentOptions"
                            @change="getText()"
                            :disabled="callPeriod === false"
                            hide-details="auto"
                          ></v-combobox>
                        </v-col>
                        <v-col class="col-1 px-0 py-0"></v-col>
                        <v-col class="col-3 px-1 py-0">
                          <div class="divTitle">
                            <p class="mb-0 font-weight-bold labelTitle">
                              {{
                                $t("components.inviteVisitor.linkValidUntil")
                              }}
                            </p>
                          </div>
                          <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            v-model="startLinkDateFrom"
                            class="mt-2"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="linkDateFrom"
                                v-on="on"
                                readonly
                              >
                                <font-awesome-icon
                                  :icon="['fal', 'calendar-alt']"
                                  class="calendarIcon"
                                  v-on="on"
                                  slot="prepend"
                                />
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date"
                              @input="tixdate = false"
                              :pickTime="true"
                              :locale="this.$locale.current()"
                              hide-details="auto"
                              first-day-of-week="1"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <!-- Fixtermin -->
                        <v-col class="col-8 px-1 py-0">
                          <v-checkbox
                            v-model="fixedDate"
                            class="checkFixedDate"
                            @change="toggleFixDate()"
                          >
                            <template v-slot:label>
                              <p class="mb-0 font-weight-bold labelTitle">
                                {{ $t("components.inviteVisitor.fixtermin") }}
                              </p>
                            </template>
                          </v-checkbox>
                          <v-row class="mx-0">
                            <v-col cols="6" sm="6" class="px-1 py-0">
                              <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                v-model="startLinkDateTo"
                                :disabled="fixedDate === false"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="linkDateTo"
                                    v-on="on"
                                    readonly
                                    :disabled="fixedDate === false"
                                  >
                                    <font-awesome-icon
                                      :icon="['fal', 'calendar-alt']"
                                      class="calendarIcon"
                                      v-on="on"
                                      slot="prepend"
                                    />
                                  </v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="dateTo"
                                  @input="tixdate = false"
                                  :pickTime="true"
                                  :locale="this.$locale.current()"
                                  :disabled="fixedDate === false"
                                  first-day-of-week="1"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="6" sm="6" class="px-1 py-0">
                              <v-menu
                                ref="menutTo"
                                v-model="menutTo"
                                :close-on-content-click="false"
                                :nudge-left="150"
                                :return-value.sync="time"
                                transition="scale-transition"
                                offset-y
                                :disabled="fixedDate === false"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="linkTimeTo"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="fixedDate === false"
                                  >
                                    <font-awesome-icon
                                      :icon="['far', 'clock']"
                                      class="calendarIcon"
                                      v-on="on"
                                      slot="prepend"
                                    />
                                  </v-text-field>
                                </template>
                                <v-time-picker
                                  format="24hr"
                                  v-if="menutTo"
                                  v-model="linkTimeTo"
                                  @click:minute="$refs.menutTo.save(linkTimeTo)"
                                  :disabled="fixedDate === false"
                                ></v-time-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col class="col-1 px-0 py-0"></v-col>
                        <v-col class="col-3 px-1 py-0">
                          <div class="divTitle">
                            <p class="mb-0 font-weight-bold labelTitle">
                              {{ $t("components.inviteVisitor.duration") }}
                            </p>
                          </div>
                          <v-text-field
                            max-width="50"
                            v-model="durationMin"
                            hide-details
                            single-line
                            type="number"
                            min="1"
                            max="120"
                            @keyup="checkValue"
                          />
                        </v-col>
                      </v-row>
                      <!-- </v-radio-group> -->
                      <!-- END TIME -->
                    </v-form>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>

            <!-- /////// Invite text ///////// -->
            <v-col
              cols="6"
              class="px-2 py-2 pt-2"
              @click.stop.prevent
              v-if="isAdvanced"
            >
              <v-textarea
                :readonly="1 === 1"
                spellcheck="false"
                v-model="fixedDetailText"
                name="subjectinput"
                :no-resize="true"
                class="py-0 my-0 pre inviteTextClass textAreaInvite"
                rows="3"
                @click.stop.prevent
              />
              <div class="">
                <p
                  style="color: white; line-height: 2.5em"
                  class="mb-0 font-weight-bold labelTitle bannerText"
                >
                  {{ $t("components.inviteVisitor.invitationTextHeadline") }}
                </p>
              </div>
              <!-- <p
                style="line-height: 2.5em"
                class="mb-0 font-weight-bold labelTitle"
              >
                {{ $t("components.inviteVisitor.invitationText") }}
              </p> -->
              <v-textarea
                spellcheck="false"
                v-model="inviteText"
                name="noteinput"
                :no-resize="true"
                class="py-0 my-0 pre inviteTextClass textAreaInviteText"
                counter
                rows="10"
                @click.stop.prevent
              />
              <div class="mt-12" style="display: none">
                Value: {{ inviteText }}
              </div>
              <v-col v-if="copiedLInk" cols="12" class="col-8 px-1 py-1">
                <span>{{ $t("components.inviteVisitor.copiedLInk") }}</span>
              </v-col>
            </v-col>
          </v-row>
          <!-- /////// Error text ///////// -->
          <v-row style="width: 100%; margin: 0" v-if="errText">
            <div class="mx-auto">
              <span style="color: red">{{ errText }}</span>
            </div>
          </v-row>

          <v-row class="mx-auto footerRow">
            <v-col
              v-if="successText && successText !== ''"
              cols="12"
              class="text-center minHeight36 pt-0"
            >
              <span>{{ successText }}</span>
            </v-col>

            <v-col v-if="!isAdvanced">
              <v-btn @click.stop.prevent="isAdvanced = true" color="primary">{{
                $t("components.inviteGuestPanel.advanced")
              }}</v-btn>
            </v-col>

            <v-col v-if="isAdvanced">
              <v-btn
                :disabled="disableSendButton || multiUser"
                @click.stop.prevent="copyInvitationLink()"
                color="primary"
                >{{ $t("components.inviteGuestPanel.copyLink") }}</v-btn
              >
            </v-col>

            <v-col class="text-center" v-if="amIAdmin && isAdvanced">
              <v-btn
                v-if="!textHasChanged"
                @click.stop.prevent="saveCustomMessage"
                color="primary"
                >{{ $t("components.inviteGuestPanel.saveText") }}</v-btn
              >
            </v-col>

            <v-col class="text-center cccheckbox" v-if="isAdvanced">
              <v-tooltip top>
                <template v-slot:activator="{ on }" class="mt-17">
                  <v-checkbox
                    v-on="on"
                    v-model="carboncopy"
                    class="checkCallPeriod"
                  >
                    <template v-slot:label>
                      <p class="mb-0 font-weight-bold labelTitle">CC</p>
                    </template>
                  </v-checkbox>
                </template>
                <span>{{ $t("components.inviteGuestPanel.ccTT") }}</span>
              </v-tooltip>
            </v-col>

            <!-- <v-col >
              <v-btn
                :disabled="disableSendButton"
                color="primary"
                @click.stop.prevent="precheckNewVisitor"
              >{{$t('components.inviteGuestPanel.sendInvitation')}}</v-btn>
            </v-col> -->
            <v-col class="text-right">
              <v-btn
                class="mr-2"
                :disabled="disableSendButton"
                color="primary"
                @click.stop.prevent="precheckNewVisitor"
                >{{ $t("components.inviteGuestPanel.sendInvitation") }}</v-btn
              >
              <v-btn @click.stop.prevent="closeVMenu()" color="primary">{{
                $t("generics.cancel")
              }}</v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- </vue-scroll> -->
      </v-list>
    </div>
    <InfoModalInviteVisitorsMenu
      :closeInfoModal="showHideInfoModal"
      :showInfoModal="showInfoModal"
      :userSaveLimit="userSaveLimit"
    />
  </div>
</template>
<script>
import Vue from "vue";
import store from "../../store";
import { isWaitingRoomUser } from "../../utils/privileges";
import DeleteBasket from "../../components/ui/deleteBasket.vue";
import { v4 as uuidv4 } from "uuid";
import { createVisitor } from "../../lib/inviteHelpers";
import moment from "../../../sharedsrc/moment";
import { deleteVisitor } from "../../utils/waitingRoom";
import {
  setNamespaceSetting,
  isEmailRegistered,
  sendInviteMailCustom,
  transferVisitor,
} from "../../lib/wsMsg";
import { dispatchSuccessAlert, dispatchErrorAlert } from "../../effector/alerts";
import {
  newTimelineItemEvent,
  createTimelineEntryEffect,
} from "../../effector/timeline";
import { newConferenceEvent } from "../../effector/conference";
import {
  foundExistingEmailState,
  findUserEmailEvent,
  foundExistingUUIDByEmailState,
  findUserByEmailEvent,
} from "../../effector/users";
import InfoModalInviteVisitorsMenu from "../modal/infoModalInviteVisitorsMenu.vue";
import DirectLinkInvitationMultiUser from "../modal/directLinkInviteMultiUser.vue";
export default {
  props: ["closeMenu"],
  components: { InfoModalInviteVisitorsMenu, DirectLinkInvitationMultiUser, DeleteBasket },
  data() {
    return {
      userSaveLimit: false,
      inviteeType: 1,
      searchInvitations: "",
      showInfoModal: false,
      // radioGroup: 2,
      searchUsers: null,
      createVisitorBusy: false,
      state: store.state,
      // radios: null,
      ownUUID: store.state.ownUUID,
      name: "",
      email: null,
      inviteText: "",
      isAppointment: false,
      appmtOptionselect: null,
      contactSelected: null,
      items: {},
      subject: "",
      instalink: "",
      created: "",
      addToPlanner: false,
      internalnote: "",
      linkreadonly: true,
      linkDateFrom: "",
      date: "",
      linkTimeFrom: null,
      menutfrom: false,
      linkDateTo: "",
      dateTo: "",
      linkTimeTo: null,
      menutTo: false,
      time: null,
      valid: false,
      durationMin: "",
      errText: "",
      searchTariffs: null,
      successText: "",
      sendingInvitation: false,
      userNameRules: [
        (v) => (!!v && !!v.trim().length) || this.$t("generics.nameRequired"),
      ],
      emailRules: [
        (v) => !!v || "",
        (v) => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) || this.$t("generics.emailMustBeValid"),
      ],
      phoneRules: [
        (v) => !!v || "",
        (v) => /^\+\d+$/.test(v) || this.$t("generics.phoneMustBeValid"),
      ],
      tariffRules: [(v) => !v || /^\d+(,\d{2}|)$/.test(v) || "format"],
      noRules: [],
      userSelected: "",
      userList: [],
      inviteList: [],
      sendMail: true,
      invitateLink: undefined,
      copiedLInk: false,
      startLinkDateFrom: false,
      startLinkDateTo: false,
      price: "",
      fixedPrice: "",
      lastEmail: "",
      lastUuid: "",
      typeOneCall: "user",
      pinActive: false,
      complexPinActive: false,
      noPin: true,
      pinCode: "",
      pinType: "0",
      isWaitingRoom: true,
      isNotWaitingRoom: false,
      isSupportCall: false,
      isOneTime: false,
      isTrue: true,
      isforMail: false,
      // isConfidential: false,
      callPeriod: false,
      fixedDate: false,
      carboncopy: false,
      complexPinRegex: /^(?=.*[0-9])(?=.*[a-zñöäü])(?=.*[A-ZÖÄÜÑ])(?=.*[*.!@$%^&(){}[\]\/:;<>,.?~_\+\-\=|]).{12,}$/,
      multiUser: false,
      showModalDirectLink: false,
      forceSingleUse: false,
      conferenceTitle: "",
      conferenceId: null,
      row: null,
      moment: moment,
      deleting: false,
      isAdvanced: false,
      applyRules: true,
      paylinkAmount: 0,
      visFunction: "",
      phoneNumber: "",
      phoneNumberInput: "",
      phoneInputValid: false,
      invitationSend: "email",
    };
  },
  effector: {
    foundExistingEmail: foundExistingEmailState,
    foundExistingUUID: foundExistingUUIDByEmailState,
  },

  watch: {
    inviteList() {
      this.inviteList.length > 0
        ? (this.multiUser = true)
        : (this.multiUser = false);
    },
    inviteText(newText, oldText) {
      // console.log("inviteText new", newText, "old", oldText);
      if (this.inviteText === "") {
        setTimeout(this.getText, 100);
      }
    },
    dateTo(val) {
      // console.log("dateTo ", val);
      this.linkDateTo = this.formatDate(this.dateTo);
      this.startLinkDateTo = false;
      // console.log("linkDateTo ", this.linkDateTo);
    },
    date(val) {
      // console.log("date ", val);
      this.linkDateFrom = this.formatDate(this.date);
      this.startLinkDateFrom = false;
      // console.log("date from ", this.linkDateFrom);
    },
    "state.showInviteInstaLink": function (value) {
      // console.log("resetting all");
      this.callPeriod = false;
      this.fixedDate = false;
      this.isWaitingRoom = true;
      this.isNotWaitingRoom = false;
      this.carboncopy = false;
      this.inviteText = "";
      this.pinActive = false;
      this.complexPinActive = false;
      this.pinCode = null;
      this.multiUser = false;
      this.conferenceTitle = "";
      this.userList = [];
      if (value === true) {
        this.appmtOptionselect = null;
        this.getText();
      }
    },
    name: function (value) {},
  },
  mounted() {
    // document.getElementById('drawerContentCard').classList.add('hideElement');
    this.getText();
    // if (store.state.user.titel && store.state.user.titel !== "unknown" ) {
    this.userSelected = {
      name: this.getUserTitel(this.ownUUID) + " " + this.state.user.name,
      value: this.ownUUID,
    };
    // } else {
    //   this.userSelected = {
    //     name: store.state.user.name,
    //     value: store.state.ownUUID,
    //   };
    // }
  },
  destroyed(){
    // document.getElementById('drawerContentCard').classList.remove('hideElement');
  },
  methods: {
    doNothing() {
      return false;
    },
    onTelInput( data ) {
      if (data) {
        // new number
        this.dialCode = data.country ? '+' + data.country.dialCode : '';
        this.phoneNumber = (data.number.international || "").replace(/[-\s]/g, "");
        this.phoneInputValid = data.isValid;
      } else {
        // number from the saved list
        this.dialCode = '';
        this.phoneNumber = (this.phoneNumberInput || "").replace(/[-\s]/g, "");
        this.phoneInputValid = true;
      }
    },
    async removeInvitation(data) {
      if (this.isdeleting) return;
      this.deleting = true;
      const result = await deleteVisitor(data.item.uuid, true);
      this.deleting = false;
      // dispatchSuccessAlert(
      //   Vue.prototype.$t("components.eventEntryForm.updatedSuccess")
      // );
    },
    inviteTypeChange(evt) {
      // 1 wr // 2 guest
      if (evt == 0) {
        this.isWaitingRoom = false;
        this.isNotWaitingRoom = true;
      } else if (evt == 1) {
        this.isWaitingRoom = true;
        this.isNotWaitingRoom = false;
      }
    },
    invitePinChange(evt) {
      // 0 no pin  // 1 reg  // 2 complex
      if (evt) {
        switch (evt) {
          case "0":
            this.pinActive = false;
            this.complexPinActive = false;
            this.noPin = true;
            break;
          case "1":
            this.pinActive = true;
            this.complexPinActive = false;
            this.noPin = false;
            break;
          case "2":
            this.pinActive = false;
            this.complexPinActive = true;
            this.noPin = false;
            break;
          default:
            break;
        }
      }
    },
    cancelMultiSelect() {
      // console.log("cleanUpOnCancel");
      this.inviteList = [];
      this.forceSingleUse = true;
      this.multiUser = false;
      this.conferenceTitle = "";
      this.openCloseModalDirectLink();
    },
    openCloseModalDirectLink(confTitle = null) {
      if (this.inviteList.length > 0 && !this.showModalDirectLink) {
        // Entering edit mode
        // console.log(
        //   "Entering edit mode ",
        //   this.conferenceTitle,
        //   this.inviteList
        // );
        this.showModalDirectLink = !this.showModalDirectLink;
      } else {
        if (!this.showModalDirectLink && !this.forceSingleUse) {
          this.multiUser = true;
          this.isWaitingRoom = true;
          this.isNotWaitingRoom = false;
        } else {
          if (
            (this.showModalDirectLink && this.inviteList.length == 0) ||
            this.forceSingleUse
          ) {
            this.multiUser = false;
          }
        }
        this.forceSingleUse = false;
        this.conferenceTitle = confTitle;
        // console.log("title ", this.conferenceTitle);
        this.showModalDirectLink = !this.showModalDirectLink;
      }

      // console.log("invite  list", this.inviteList);
    },
    amIGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    prepMultiUserInvite() {
      this.inviteeType = 1;
      this.isWaitingRoom = true;
      this.isNotWaitingRoom = false;
      // check email and name for validity and
      if (
        (!this.phoneInputValid && !/^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(this.email || "")) ||
        this.name === null ||
        (this.name || "").trim().length < 1
      ) {
        // show validation error
      } else {
        const newItem = {
          name: this.name,
          email: this.email || "",
          mobilePhone: this.phoneNumber || "",
        };
        // console.log('New Invite Data ', newItem);
        var found = this.inviteList.findIndex(
          (element) =>
            (newItem.email && element.email === newItem.email) ||
            (newItem.mobilePhone && element.mobilePhone === newItem.mobilePhone)
        );
        // console.log('Found  ', found)
        if (found == -1) {
          this.inviteList.push(newItem);
          this.applyRules = false;
          this.email = "";
          this.phoneNumber = "";
          this.phoneNumberInput = "";
          this.phoneInputValid = false;
          this.name = "";
        }
        // this.applyRules = true;
      }
    },
    getUserTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
    checkPin() {
      if (this.complexPinActive) {
        return this.complexPinRegex.test(this.pinCode);
      } else if (this.pinActive) {
        return /^\d{6}$/.test(this.pinCode);
      } else {
        return true;
      }
    },
    toggleFixDate() {
      if (this.fixedDate) {
        this.appmtOptionselect = null;
        this.callPeriod = false;
      } else {
      }
    },
    toggleCallPeriod() {
      if (this.callPeriod) {
        this.fixedDate = false;
        this.linkTimeTo = null;
        this.dateTo = "";
      } else {
      }
    },
    // setPinActive(evt) {
    //   this.isConfidential = evt;
    // },
    setConfidential(evt) {
      this.pinActive = evt;
    },
    isValidSearch(term) {
      if (term !== "") {
        const email = term.trim().toLowerCase();
        const re = /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/;
        return re.test(term);
      } else {
        return false;
      }
    },
    handleSelectEmail(item) {
      if (item) {
        if(this.invitationSend  === 'email') {
          this.contactSelected = item.name;
          this.name = item.name;
          this.email = item.email;
          this.$refs.comboEmail.blur();
          
        } else if (this.invitationSend === 'phone'){
          this.contactSelected = item.name;
          this.name = item.name;
          this.phoneNumberInput = item.phone.includes(item.dialCode) ? item.phone : item.dialCode + item.phone;
          this.onTelInput();
        }
      }
    },
    removeUserFromList(item) {
      if(this.invitationSend === 'email'){
        const existingEmail = store.state.user.videoCallInvitations.filter(
          (e) =>  e.email !== item.email 
        );
         store.setVideoCallInvitations(existingEmail);
      }else if (this.invitationSend === 'phone') {
        const existingPhone = store.state.user.videoCallInvitations.filter(
          (e) =>  e.phone !== item.phone 
        );
         store.setVideoCallInvitations(existingPhone);
      }
    },
    async addUserToList() {
      // console.log(this.$refs.comboEmail, this.email, "comboemail");
      const existingVideoCallInvitations =
        store.state.user.videoCallInvitations;
      if (existingVideoCallInvitations.length < 20) {
        if (this.invitationSend === "email") {
          if (this.isValidSearch(this.email)) {
            await findUserEmailEvent(this.email);
            if (this.foundExistingEmail.length === 0) {
              const emailAlreadyExists = existingVideoCallInvitations.find(
                (e) => e.email === this.email
              );
              if (emailAlreadyExists) {
                return;
              }
              const newItem = {
                name: this.name,
                email: this.email,
              };
              existingVideoCallInvitations.push(newItem);
              store.setVideoCallInvitations(existingVideoCallInvitations);
              this.$refs.comboEmail.blur();
              dispatchSuccessAlert(this.$t("components.inviteVisitor.saveSuccessText1") + ' ' + this.name + ' ' + this.$t("components.inviteVisitor.saveSuccessText2"));
            } else {
              this.userSaveLimit = false;
              this.showHideInfoModal();
            }
          }
        } else if (this.invitationSend === "phone") {
          const phoneAlreadyExists = this.filteredInvitations.find(
            (e) => e.phone === this.phoneNumberInput
          );
          if (phoneAlreadyExists) {
            return;
          }
          const newItem = {
            name: this.name,
            phone: this.phoneNumberInput,
            dialCode: this.dialCode
          };
          existingVideoCallInvitations.push(newItem);
          // console.log('existingVideoCallInvitations', existingVideoCallInvitations)
          store.setVideoCallInvitations(existingVideoCallInvitations);
          dispatchSuccessAlert(this.$t("components.inviteVisitor.saveSuccessText1") + ' ' + this.name + ' ' + this.$t("components.inviteVisitor.saveSuccessText2"));
        }
      } else {
        this.userSaveLimit = true;
        this.showHideInfoModal();
      }
    },
    showHideInfoModal() {
      // clear name and email fields
      this.name = "";
      this.email = "";
      this.phoneNumber = "";
      this.phoneNumberInput = "";
      this.phoneInputValid = false;
      this.showInfoModal = !this.showInfoModal;
    },
    checkValue() {
      if (this.durationMin > 120) {
        this.durationMin = 120;
      }
    },

    addItem(val) {
      if (val !== null && /^\d+(,\d{2}|)$/.test(val)) {
        store.setUserOneCallTariff(val);
      }
    },
    checkInput(e) {
      if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 44) {
        // good input
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    copyInvitationLink() {
      this.sendMail = false;
      this.precheckNewVisitor();
    },
    buildAppointment(visitorId) {
      const dateTimeStart = new Date(this.dateTo + " " + this.linkTimeTo);
      const dateTimeEnd =
        new Date(this.dateTo + " " + this.linkTimeTo).getTime() +
        this.durationMin * 60000;
      // console.log('Dates', this.dateTo, this.linkTimeTo, this.durationMin, dateTimeStart, dateTimeEnd);
      let selectedUsers = [];
      if (this.userSelected.value !== store.state.ownUUID) {
        selectedUsers.push(this.userSelected.value);
      }
      selectedUsers.push(visitorId);

      const payload = {
        name: this.name,
        details: this.subject || "",
        users: selectedUsers,
        // img: this.imgAppointment,
        start: dateTimeStart,
        end: dateTimeEnd,
        color: "orange",
        type: "oneCall",
        oneCall: {
          visitorUUID: visitorId,
        },
      };
      // console.log('Payload OneCall ', payload);
      newTimelineItemEvent(payload);
    },
    closeVMenu() {
      this.$refs.form.resetValidation();
      this.invitateLink = undefined;
      this.sendMail = true;
      // this.userSelected = {
      //   name: store.state.user.name,
      //   value: store.state.ownUUID
      // };
      this.copiedLInk = false;
      this.errText = "";
      this.successText = "";
      this.appmtOptionselect = null;
      this.linkDateTo = "";
      this.linkTimeTo = null;
      this.isSupportCall = false;
      this.isOneTime = false;
      (this.durationMin = ""), (this.email = null);
      this.name = "";
      this.subject = "";
      this.sideBarSubject = "";
      this.internalnote = "";
      this.linkDateFrom = null;
      this.isNotWaitingRoom = false;
      this.isAdvanced = false;
      this.getText();
      this.closeMenu();
    },
    async saveCustomMessage() {
      // Tino this is for all so save to namespace
      if (this.invitationSend === "email") {
        this.state.namespaceSettings.customVisitorInviteText = this.inviteText;
        await setNamespaceSetting("customVisitorInviteText", this.inviteText);
      } else {
        this.state.namespaceSettings.customVisitorWhatsAppInviteText = this.inviteText;
        await setNamespaceSetting(
          "customVisitorWhatsAppInviteText",
          this.inviteText
        );
      }
    },
    copyLink() {
      const selBox = document.createElement("textarea");
      selBox.style.position = "fixed";
      selBox.value = this.invitateLink;
      document.body.appendChild(selBox);
      selBox.select();
      try {
        var successful = document.execCommand("copy");
        document.body.removeChild(selBox);
        this.copiedLInk = true;
      } catch (err) {
        this.copiedLInk = false;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    getText() {
      let setting;
      if (this.invitationSend === "email") {
        setting = this.state.namespaceSettings.customVisitorInviteText;
      } else {
        setting = this.state.namespaceSettings.customVisitorWhatsAppInviteText;
      }
      if ((setting || "").length > 5)
        // TINO do we need this? if so we should grab it from namespace as it is company global
        this.inviteText = setting;
      else {
        this.inviteText = this.loadDefaultInviteText();
      }
    },
    precheckNewVisitor() {
      if (this.multiUser) {
        this.handleMultiUserInvite();
        return;
      }
      if (
        this.phoneNumber &&
        this.phoneNumber.startsWith("+") &&
        /^\+\d+$/.test(this.phoneNumber)
      ) {
        // Skip email validation if a valid phone number is provided
      } else if (this.email.length < 5) {
        this.valid = false;
        return;
      }
      if (this.valid) {
        findUserEmailEvent(this.email);
        if (this.foundExistingEmail.length === 0) {
          this.handleNewVisitor();
          // this.handleMultiUserInvite();
        } else {
          this.userSaveLimit = false;
          this.showHideInfoModal();
        }
      }
    },

    async handleMultiUserInvite() {
      let myUUIDS = [];
      let visitoruuid = null;
      for (let index = 0; index < this.inviteList.length; index++) {
        const element = this.inviteList[index];
        if (this.valid) {
          if (element.email) await findUserEmailEvent(element.email);
          if (!element.email || this.foundExistingEmail.length === 0) {
            this.email = element.email || "";
            this.phoneNumber = element.mobilePhone || "";
            this.name = element.name;
            //  console.log("calling handle ", this.email, this.name);
            visitoruuid = await this.handleNewVisitor(
              element.name,
              element.email,
              element.mobilePhone
            );
            //  console.log('visitor id........', visitoruuid);
            if (visitoruuid && visitoruuid !== null);
            if (myUUIDS.indexOf(visitoruuid) === -1) {
              myUUIDS.push(visitoruuid);
            }
            if (index === this.inviteList.length - 1) {
              dispatchSuccessAlert(
                Vue.prototype.$t("components.inviteVisitor.successMultiSent")
              );
            }
          } else {
            //  console.log("crapped");
            this.userSaveLimit = false;
            this.showHideInfoModal();
          }
        }
      }
      //Mimi
      this.conferenceId = uuidv4();
      const confInfo = {
        conferenceId: this.conferenceId,
        conferenceName: this.subject,
        confUsers: myUUIDS,
        timelineConference: "",
      };

      // console.log('confInfo ', confInfo);
      await this.createConference(confInfo);
      await this.createGroup(confInfo);
      // create event if dates
      if (this.canAddEvent) {
        const timelineConference = await this.saveData(confInfo);
        // console.log('timelineConference', timelineConference)
        // update group information
        let extant = store.state.user.userGroups.find(
          (e) => e.id == confInfo.conferenceId
        );
        if (extant && extant.id) {
          store.state.user.userGroups.find(
            (e) => e.id == confInfo.conferenceId
          ).timelineConference = timelineConference;
        }
      }

      this.sendMail = true;
      this.closeVMenu();

      if (this.standalone && this.sendMail) window.close();
    },

    async saveData(confInfo) {
      let meetingId = null;
      if (!this.canAddEvent) throw new Error("Not allowed");
      const dateTimeStart = new Date(this.dateTo + " " + this.linkTimeTo);
      const dateTimeEnd =
        new Date(this.dateTo + " " + this.linkTimeTo).getTime() +
        parseInt(this.durationMin) * 60000;

      const tmpData = {
        name: confInfo.conferenceName || this.$t("components.conferenceForm.newConference"),
        details: "",
        users: confInfo.confUsers,
        start: new Date(dateTimeStart),
        end: new Date(dateTimeEnd),
        color: "orange",
        type: "conference call",
        groupId: confInfo.conferenceId,
        host: store.state.user.uuid,
      };

      tmpData.rsvp = false;
      const meeting = await createTimelineEntryEffect(tmpData);
      meetingId = meeting.uuid;
      return meetingId;
    },

    async createGroup(confInfo) {
      let newGroup = {};
      let tempArr = [];
      const dateTimeStart = new Date(this.dateTo + " " + this.linkTimeTo);
      const dateTimeEnd =
        new Date(this.dateTo + " " + this.linkTimeTo).getTime() +
        parseInt(this.durationMin) * 60000;

      newGroup.id = confInfo.conferenceId;
      newGroup.newGroupName =
        confInfo.conferenceName.trim() || this.$t("components.conferenceForm.newConference");
      newGroup.title = confInfo.conferenceName.trim() || this.$t("components.conferenceForm.newConference");
      confInfo.confUsers.forEach((element) => {
        let data = {
          name:
            ((store.state.group[element] || {}).user || {}).name || "Anonymous",
          uuid: element,
        };

        tempArr.push(data);
      });
      newGroup.members = tempArr;
      newGroup.owner = (store.state.user || {}).name || "Anonymous";
      newGroup.ownerUUID = store.state.ownUUID;
      newGroup.isTemplate = false;
      newGroup.isConferenceWaitingRoom = true;
      newGroup.timelineConference = "";
      newGroup.pinProtected = false;

      if (this.durationMin) {
        newGroup.durationMin = this.durationMin;
      }
      if (dateTimeStart && dateTimeStart.length > 5) {
        newGroup.dateTimeStart = dateTimeStart;
      }

      //  console.log('pusing to user groups !!!!!!!! ', newGroup);
      store.state.user.userGroups.push(newGroup);
    },
    async createConference(confInfo) {
      // console.log('calling Event create ',confInfo);
      newConferenceEvent(confInfo);
    },

    async handleNewVisitor(name = null, email = null, mobilePhone = null) {
      const myUUIDS = [];
      if (this.multiUser) {
        this.email = email || "";
        this.phoneNumber = mobilePhone || "";
        this.name = name;
      }
      this.sideBarSubject = this.subject;
      // this.subject = this.$t("components.inviteVisitor.line1", [
      //   store.state.namespaceSettings.companyInfo.company_name || window.location.hostname,
      //   this.userSelected.name
      // ]);
      this.isforMail = true;

      // if (this.createVisitorBusy) return;
      // this.createVisitorBusy = true;
      try {
        const { key, found } = this.email
          ? await isEmailRegistered(this.email)
          : { key: uuidv4(), found: false };
        if (found && !((this.state.group[key] || {}).user || {}).guest) {
          // this.errText = this.$t("components.inviteGuestPanel.errAlreadyUser");
          await sendInviteMailCustom(key, {
            name: this.state.group[key].user.email,
            email: this.email,
            guest: this.state.group[key].user.guest,
            // customMessage: visitorData.inviteText,
            // variables: visitorData.variables
          });
          this.lastUuid = this.state.group[key].user.uuid;
          // return;
        } else {
          if (this.lastEmail !== this.email) {
            this.lastUuid = uuidv4();
          }
          //  console.log(this.email, this.name, this.createVisitorBusy);
          const odata = {
            visitorId: this.lastUuid, // key,
            name: this.name,
            email: this.email || "",
            mobilePhone: this.phoneNumber || "",
            function: this.visFunction,
            inviteText:
              !this.email && this.phoneNumber // Override invite text if WhatsApp invitation
                ? this.state.user.name +
                  " " +
                  this.virtualOfficePrefix +
                  "\n" +
                  this.inviteText
                : //this.getFirstLine +
                  this.fixedDetailText +
                  this.inviteText +
                  "\n" +
                  this.$t("components.inviteVisitor.yourLink") +
                  ":\n" +
                  "%INVITE_LINK%" +
                  "\n",
            subject: this.subject,
            sideBarSubject: this.sideBarSubject,
            subjectDisplay: this.subject,
            created: Date.now(),
            linkDateFrom: this.linkDateTo, //this.linkDateFrom,
            linkTimeFrom: this.linkTimeTo, //this.linkTimeFrom,
            linkDateTo: this.linkDateTo,
            linkTimeTo: moment(this.linkTimeTo, "HH:mm").add(
              this.durationMin,
              "minutes"
            ), //this.linkTimeTo,
            internalnote: this.internalnote,
            instalink: this.instalink,
            duration: this.durationMin,
            tariff: this.price,
            isSupportCall: this.isSupportCall,
            isWaitingRoom: this.isWaitingRoom,
            isOneTime: this.isOneTime,
            inviters: {
              [this.userSelected.value]: true,
            },
            userInviter: this.userSelected.value,
          };
          odata.conferenceId = null;
          if (this.multiUser) {
            odata.conferenceId = this.conferenceId;
            odata.conferenceTitle = this.conferenceTitle;
          }
          //  console.log(odata, "odata");
          odata.variables = {
            "%APPOINTMENT_FROM%": this.linkDateFrom
              ? this.$t("components.inviteVisitor.appointment") +
                " " +
                this.linkDateFrom +
                " " +
                this.$t("components.inviteVisitor.at") +
                " " +
                this.linkTimeFrom
              : "",
            "%APPOINTMENT_EXPIRES%": this.linkDateTo
              ? this.$t("components.inviteVisitor.expires") +
                " " +
                this.linkDateTo +
                " " +
                this.$t("components.inviteVisitor.at") +
                " " +
                this.linkTimeTo
              : "",
          };
          const options =
            (this.pinActive || this.complexPinActive) && this.pinCode
              ? { "2fa": { pin: { code: this.pinCode } } }
              : {};
          if (this.paylinkAmount) {
            options.paylink = true;
            options.amount = `${this.paylinkAmount}`.replace(",", ".");
          }
          const inviteLink = await createVisitor(odata, {
            sendMail: this.sendMail,
            carbonCopy: this.carboncopy,
            ...options,
          });

          if (myUUIDS.indexOf(odata.visitorId) === -1) {
            myUUIDS.push(odata.visitorId);
          }

          odata.instalink = this.instalink = inviteLink;
          this.isforMail = false;
          if (this.ownUUID !== this.userSelected.value) {
            await this.doTransferVisitor(
              this.userSelected.value,
              odata.visitorId,
              this.isWaitingRoom ? "isWaitingRoom" : ""
            );
          } else {
            store.setUserVisitor(odata.visitorId, odata);
          }

          if (this.isAppointable && !this.multiUser) {
            // this.buildAppointment(odata.visitorId);
          }
          // update user tariffs if needed
          if (this.price != "") {
            store.setUserOneCallTariff(this.price);
          }
          //update persisted options if any
          if (!this.multiUser) {
            this.updateAppntOptions();
            if (this.sendMail) {
              //toaster
              dispatchSuccessAlert(
                Vue.prototype.$t("components.inviteVisitor.successSent")
              );
              // this.successText = this.$t("components.inviteVisitor.successSent");
            } else {
              this.invitateLink = inviteLink;
              this.copyLink();
            }
          }
        }

        if (!this.multiUser) {
          // mimi 2
          this.conferenceId = uuidv4();
          const confInfo = {
            conferenceId: this.conferenceId,
            conferenceName: this.subject,
            confUsers: myUUIDS,
            timelineConference: "",
          };

          await this.createConference(confInfo);
          await this.createGroup(confInfo);
          // create event if dates
          if (this.canAddEvent) {
            const timelineConference = await this.saveData(confInfo);
            // update group information
            let extant = store.state.user.userGroups.find(
              (e) => e.id == confInfo.conferenceId
            );
            if (extant && extant.id) {
              store.state.user.userGroups.find(
                (e) => e.id == confInfo.conferenceId
              ).timelineConference = timelineConference;
            }
          }
          this.lastEmail = this.email;
          if (this.sendMail) {
            this.$refs.form.reset();
            this.userSelected = {
              name: this.state.user.name,
              value: this.state.ownUUID,
            };
            this.clearForm();
            this.closeVMenu();
            this.lastUuid = "";
          } else {
            // reset sendMail
            this.closeVMenu();
            this.sendMail = true;
          }
        }
        // reset User select
      } catch (err) {
        console.warn("handeNewVisitor 2", err, err.code);
        //  if (err && err.message && err.message.indexOf('No such recipient here') != -1) {
        //   dispatchErrorAlert(this.$t("components.inviteVisitor.badEmail"))
        // }
      }
      this.createVisitorBusy = false;

      if (!this.multiUser) {
        if (this.standalone && this.sendMail) window.close();
      } else {
        return this.lastUuid;
      }
    },
    async doTransferVisitor(transferTo, transferUUID, visitorType) {
      const result = await transferVisitor(transferTo, transferUUID);
      if (result) {
        const visitor = store.state.group[transferUUID].user && JSON.parse(JSON.stringify(store.state.group[transferUUID].user));
        if (!visitor) return;
        const extantWaitingRoomUser = await isWaitingRoomUser(transferUUID);
        if (extantWaitingRoomUser && visitorType !== "isWaitingRoom") {
          visitor.visitorData.isWaitingRoom = false;
        }
        delete visitor.visitorData.inviters[store.state.ownUUID];
        visitor.visitorData.inviters[transferTo] = true;
        store.setUserVisitor(transferUUID, visitor.visitorData);
        delete store.state.persisted.userVisitors[transferUUID];
      }
    },
    updateAppntOptions() {
      let allOptions = this.myCombinedAppointmentOptions();
      if (
        this.appmtOptionselect &&
        allOptions.indexOf(this.appmtOptionselect) === -1
      ) {
        store.state.persisted.usersCustomAppointmentEntries.push(
          this.appmtOptionselect
        );
        store.setusersCustomAppointmentEntries(
          store.state.persisted.usersCustomAppointmentEntries
        );
      }
    },
    clearForm() {
      // console.log('clearing form....');
      this.visitorId = null;
      this.title = "";
      this.name = "";
      this.company = "";
      this.email = "";
      this.phoneNumber = "";
      this.phoneNumberInput = "";
      this.phoneInputValid = false;
      this.addToPlanner = false;
      this.subject = "";
      this.sideBarSubject = "";
      this.created = "";
      this.linkDateFrom = "";
      this.linkTimeFrom = "";
      this.linkDateTo = "";
      this.linkTimeTo = "";
      this.internalnote = "";
      this.instalink = "";
      this.successText = "";
      this.durationMin = "";
      this.userSelected = {
        name: this.getUserTitel(this.ownUUID) + " " + this.state.user.name,
        value: this.state.ownUUID,
      };
      this.sendMail = true;
      this.invitateLink = undefined;
      this.price = "";
      this.isSupportCall = false;
      // this.isWaitingRoom = true;
      this.isOneTime = false;
      // this.radios = 2;
      // this.copiedLInk = false;
    },
    removeInviteeFromList(email) {
      const myFilter = this.inviteList.filter((e) => e.email !== email);
      this.inviteList = myFilter;
      // console.log('deleting ', email, this.inviteList)
    },
    buildName() {
      // if (store.state.user && store.state.user.titel) {
      return this.getUserTitel(this.ownUUID) + " " + this.state.user.name;
      // } else if (store.state.user) {
      //   return store.state.user.name;
      // }
    },
    textWithLink() {
      return this.$t("components.inviteVisitor.line2") + "\n";
    },
    loadDefaultInviteText() {
      if (this.invitationSend === "phone") {
        return this.$t("components.inviteVisitor.whatsappText").join("\n");
      }

      let linkDevice = "";
      // if (store.state.namespaceSettings.sendIosLink) {
      //   linkDevice = this.$t("components.inviteVisitor.line2ios");
      // } else {
      linkDevice = this.$t("components.inviteVisitor.line2");
      // }

      return (
        // this.$t("components.inviteVisitor.header") +
        // "\n" +
        // this.$t("components.inviteVisitor.line1",[window.location.hostname, ((store.state.user || {}).titel || '') + ' ' + store.state.user.name]) +
        "\n" +
        linkDevice +
        "\n" +
        // this.$t("components.inviteVisitor.line2_1") +

        this.$t("components.inviteVisitor.line4_1") +
        "\n" +
        "\n" +
        // this.$t("components.inviteVisitor.line4_2") +
        // "\n\n" +
        // (this.appmtOptionselect
        //   ? this.$t("components.inviteVisitor.line4_0") +
        //     " " +
        //     this.appmtOptionselect +
        //     " \n"
        //   : "") +
        // "\n" +
        // this.$t("components.inviteVisitor.line5_0") +
        // " " +
        // this.$t("components.inviteVisitor.line5_1") +
        // " https://www.voffice.pro/de/hinweise.html/" +
        // "\n" +
        this.$t("components.inviteVisitor.line3").join("\n") +
        "\n"
        // this.$t("components.inviteVisitor.line6") +
        // "\n" //+
        // this.$t("components.inviteVisitor.line7") +
        // "\n" //+
        // this.$t("components.inviteVisitor.instaLinkInvite") +
        // "\n"
      );
    },
    myCombinedAppointmentOptions() {
      const baseOptions = [
        // this.$t("components.inviteVisitor.plsSelect"),
        this.$t("components.inviteVisitor.shortly"),
        this.$t("components.inviteVisitor.immediately"),
        this.$t("components.inviteVisitor.tillsixpm"),
        this.$t("components.inviteVisitor.asap"),
        this.$t("components.inviteVisitor.morning"),
        this.$t("components.inviteVisitor.afterTomorrow"),
      ];
      const additionalOptions = (
        store.state.persisted.usersCustomAppointmentEntries || []
      ).filter((v) => !!v);
      const options = additionalOptions.length
        ? baseOptions.concat(additionalOptions)
        : baseOptions;
      return options;
    },
   getDurationPhrase(num) {
    let hours = (num / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

      if (rhours === 1 && rminutes === 1) return this.$t("components.invitePreview.inviteAllLineDurationHourMinute", [rhours,rminutes]) + ".";
      if (rhours === 1 && rminutes > 1) return this.$t("components.invitePreview.inviteAllLineDurationHourMinutes", [rhours,rminutes]) + ".";
      if (rhours > 1 && rminutes === 1) return this.$t("components.invitePreview.inviteAllLineDurationHoursMinute", [rhours,rminutes]) + ".";
      if (rhours > 1 && rminutes > 1) return this.$t("components.invitePreview.inviteAllLineDurationHoursMinutes", [rhours,rminutes]) + ".";
      if (rhours === 0 && rminutes === 1) return this.$t("components.invitePreview.inviteAllLineDurationMinute", [rminutes]) + ".";
      if (rhours === 0 && rminutes > 1) return this.$t("components.invitePreview.inviteAllLineDuration", [rminutes]) + ".";
      if (rhours === 1 && rminutes === 0) return this.$t("components.invitePreview.inviteAllLineDurationHour", [rhours]) + ".";
      if (rhours > 1 && rminutes === 0) return this.$t("components.invitePreview.inviteAllLineDurationHours", [rhours]) + ".";

    }
  },
  computed: {
    isProVersion() {
      return !store.state.namespaceSettings.basicVersion;
    },
    isEmailInvalid() {
      if (this.email && this.email.length > 0) {
        const email = this.email.trim().toLowerCase();
        const re = /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/;
        return !re.test(email);
      } else {
        return true;
      }
    },
    disableRadioEmailWhatsApp() {
      return (
        (this.inviteList && !!this.inviteList.length) ||
        (this.email && !!this.email.length) ||
        (this.phoneNumber && !!this.phoneNumber.length) ||
        false
      );
    },
    isWhatsAppInviteAvailable() {
      // See also isWhatsAppInviteAvailable @ websrc/components/timeline/conferencesDirectCallMask.vue
      // https://gitlab.ra-micro.de/devcups/voffice/-/issues/401
      return false;
      /*
      if (process.env.PKG) {
        return false;
      } else {
        return true;
      }
      */
    },
    isMaxedOut() {
      // console.log('maxed ', this.inviteList.length > 2)
      return this.inviteList.length > 2;
    },
    isdeleting() {
      return this.deleting || this.state.isConfDeleting;
    },
    virtualOfficePrefix() {
      return (
        this.state.namespaceSettings.companyInfo.virtualOfficePrefix ||
        store.getVirtualOfficePrefix()
      );
    },
    getTitleWaitingROOM() {
      if (this.state.namespaceSettings.waitingRoomAlias) {
        let alias = this.state.namespaceSettings.waitingRoomAlias;
        if (alias && alias.trim().length > 30) {
          alias = alias.substring(0, 27) + "...";
        }
        return alias;
      } else {
        let msg = this.$t("components.waitingRoom.titleHeader");
        if (msg && msg.trim().length > 30) {
          msg = msg.substring(0, 27) + "...";
        }
        return msg;
      }
    },
    canAddEvent() {
      return (
        this.dateTo !== "" && this.linkTimeTo !== "" && this.durationMin !== ""
      );
    },
    getTextForInvites() {
      let members = this.inviteList.length;
      if (members === 0) {
        return this.$t("components.inviteVisitor.invitationTo");
      } else {
        let trailText = " ";
        this.inviteList.forEach((element) => {
          trailText = trailText + element.name + ", ";
        });
        // return (
        //   this.$t("components.inviteVisitor.invitationMultiUser") + trailText
        // );
        return trailText;
      }
    },
    getvisitors() {
      const visitors = Object.keys(store.state.persisted.userVisitors)
        .filter((uuid) => !!store.state.persisted.userVisitors[uuid])
        .map((uuid) => ({
          date: moment(
            new Date(store.state.persisted.userVisitors[uuid].created),
            "DD.MM.YYYY HH:mm"
          ).format("DD.MM.YYYY HH:mm"),
          name: store.state.persisted.userVisitors[uuid].name,
          email: store.state.persisted.userVisitors[uuid].email,
          uuid: uuid,
          disabled: false,
        }));
      // console.log(visitors)
      // add unconfirmed users invites
      //   for (let uuid in store.state.group) {
      //     const person = store.state.group[uuid];
      //     const isGuest = (person.permissions === 10 || !person.user || person.user.guest || person.user.visitor);
      //       if (!person || !person.user || person.user.name == "" || isGuest || !(person.user.inviters || {})[store.state.ownUUID] || person.user.rtcCallStart !== undefined)
      //         continue;
      //     console.log("aquiiiii")
      //      visitors.push(person.user.name+ ': '+ person.user.email);
      // }
      // console.log('returning visitors from drawerContetnt', visitors)
      return visitors;
    },
    // getUserFullName() {
    //   const person = store.state.user;
    //   if (person.user && person.user.titel) {
    //     return {
    //       name: person.user.titel + " " + person.user.name,
    //       value: uuid,
    //     };
    //   } else if (person.user) {
    //     return { name: person.user.name, value: uuid };
    //   }
    // },
    filteredInvitations() {
      const myInvitations = store.state.user.videoCallInvitations || [];
      const myNewList = myInvitations.filter((t) => {
        if (this.invitationSend === "email" && t.email) {
          return t;
        } else if (this.invitationSend === "phone" && t.phone) {
          return t;
        }
      });
      return myNewList;
    },
    textHasChanged() {
      // text could come from two sources
      let custText;
      if (this.invitationSend === "email") {
        custText = this.state.namespaceSettings.customVisitorInviteText || "";
      } else {
        custText =
          this.state.namespaceSettings.customVisitorWhatsAppInviteText || "";
      }
      const newText = this.inviteText.trim();
      const stdText = this.loadDefaultInviteText().trim();
      if (custText.length > 0) {
        return newText === custText.trim();
      } else {
        return newText === stdText;
      }
    },
    getFirstLine() {
      return (
        this.userSelected.name +
        " " +
        this.$t("components.inviteVisitor.hasInvitedYou") +
        "\n"
      );
    },
    fixedDetailText() {
      let lineSubject = "\n" + (this.computedSubject || "") + "\n";
      // let fullName = this.getUserFullName().name;
      // let head = "\n" + this.$t("components.inviteVisitor.line1", [window.location.hostname, fullName]) + "\n";
      let line1 =
        this.userSelected.name +
        " " +
        this.$t("components.inviteVisitor.hasInvitedYou") +
        "\n";
      let line2 =
        this.$t("components.inviteVisitor.conferenceStartsAt") +
        " " +
        this.linkDateTo +
        " " +
        this.$t("components.inviteVisitor.at") +
        " " +
        this.linkTimeTo +
        " " +
        this.$t("components.meetingLineModal.hours") +
        "\n";
       let line2_1 = "";
       if (this.getDurationMin){
        line2_1 = line2_1 + "\n" +  this.getDurationPhrase(this.getDurationMin) + 
        " " +
        "\n"; 
       }
      let line3 =
        this.$t("components.inviteVisitor.callRequestedOn") +
        ": " +
        this.appmtOptionselect +
        "\n";
      let line4 =
        this.$t("components.inviteVisitor.linkGoodUntil") +
        " " +
        this.linkDateFrom +
        "\n";
      // let line5 = 'For technical problems, please contact RA-MICRO Technical Support: support-voffice@ra-micro.de, Phone: 030-435 98 888';

      if (!this.linkDateTo || !this.linkTimeTo) {
        line2 = "";
      }
      if (!this.appmtOptionselect) {
        line3 = "";
      }
      if (!this.linkDateFrom) {
        line4 = "";
      }
      if (!this.durationMin) {
        line2_1 = "";
      }
      //  console.log(lineSubject + line1 + line2 + line2_1 + line3 + line4)
      if (!this.isforMail) {
        return line2 + line2_1 + line3 + line4;
      } else {
        if (this.paylinkAmount && this.paylinkAmount > 0){
          const linkText = "\n" + this.$t("components.inviteVisitor.inviteLinkText",[this.paylinkAmount + '€']) + "\n" 
          return line2 + line2_1 + line3 + line4 + linkText;
        } else {
           return line2 + line2_1 + line3 + line4; 
        }
        
      }
    },
    getDurationMin() {
      if (
        !this.durationMin ||
        this.durationMin.length <= 0 ||
        this.durationMin <= 0
      ) {
        return null;
      } else {
        return this.durationMin;
      }
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    getUserTariffs() {
      return store.state.persisted.userOneCallTariffs || [];
    },

    disableSendButton() {
      if (this.fixedDate) {
        if (
          this.linkDateTo.length < 3 ||
          !this.linkTimeTo ||
          !this.durationMin
        ) {
          return true;
        }
      }
      if (this.multiUser) {
        if (
          (this.conferenceTitle || "").length < 0 ||
          this.inviteList.length === 0 ||
          !this.checkPin() ||
          !this.isWaitingRoom
        ) {
          return true;
        } else {
          return false;
        }
      } else if (
        this.name == "" ||
        /* Test email */ ((this.email == "" ||
          !this.email ||
          this.email.length < 5 ||
          this.isEmailInvalid) &&
          /* Test phone */ !(this.phoneNumber && this.phoneInputValid)) ||
        !this.checkPin()
        // (this.isConfidential && /^\d{6}$/.test(this.pinCode) == false)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAppointable() {
      // must have start date , start time and minutes
      let canBePlotted = false;
      if (this.linkDateTo === null || this.linkTimeTo === null) {
        canBePlotted = false;
      } else {
        if (this.durationMin > 0) {
          canBePlotted = true;
        }
      }
      return canBePlotted;
    },
    getAssistantsList() {
      const assistants = (store.state.user.assistants || [])
        .filter((uuid) => {
          const person = store.state.group[uuid];
          return (
            person &&
            person.user &&
            person.user.name &&
            person.permissions !== 10 &&
            !person.user.guest &&
            !person.user.visitor
          );
        })
        .map((uuid) => {
          const person = store.state.group[uuid];
          // if (person.user.titel && person.user.titel !== "unknown") {
          return {
            name: this.getUserTitel(uuid) + " " + person.user.name,
            value: uuid,
          };
          // } else {
          //   return { name: person.user.name, value: uuid };
          // }
        });
      return assistants.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
      });
    },
    getAssistsList() {
      const assists = (store.state.user.assists || [])
        .filter((uuid) => {
          const person = store.state.group[uuid];
          return (
            person &&
            person.user &&
            person.user.name &&
            person.permissions !== 10 &&
            !person.user.guest &&
            !person.user.visitor
          );
        })
        .map((uuid) => {
          const person = store.state.group[uuid];
          // if (person.user.titel && person.user.titel !== "unknown") {
          return {
            name: this.getUserTitel(uuid) + " " + person.user.name,
            value: uuid,
          };
          // } else {
          //   return { name: person.user.name, value: uuid };
          // }
        });
      return assists.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
      });
    },
    getUserList() {
      const persons = Object.keys(store.state.group)
        .filter((uuid) => {
          const person = store.state.group[uuid];
          return (
            person &&
            person.user &&
            person.user.name &&
            person.permissions !== 10 &&
            !person.user.guest &&
            !person.user.visitor
          );
        })
        .map((uuid) => {
          const person = store.state.group[uuid];
          // if (person.user.titel && person.user.titel !== "unknown") {
          return {
            name: this.getUserTitel(uuid) + " " + person.user.name,
            value: uuid,
          };
          // } else {
          //   return { name: person.user.name, value: uuid };
          // }
        });
      return persons.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
      });
    },
    // computedDateFormattedFrom() {
    //   return this.formatDate(this.date);
    // },
    // computedDateFormattedTo() {
    //   return this.formatDate(this.dateTo);
    // },
    combinedAppointmentOptions() {
      const options = this.myCombinedAppointmentOptions();
      return options;
    },
    computedSubject() {
      return this.subject;
    },
    // soonPaylink() {
    //   return "Paylink " + this.$t("generics.comingSoon");
    // },
    // soonAddPlaner() {
    //   return "Add to vOffice planner " + this.$t("generics.comingSoon");
    // },
    // titleItems() {
    //   return [
    //     { id: 0, text: this.$t("components.inviteVisitor.without") },
    //     { id: 1, text: this.$t("components.inviteVisitor.mr") },
    //     { id: 2, text: this.$t("components.inviteVisitor.ms") },
    //     { id: 3, text: this.$t("components.inviteVisitor.company") }
    //   ];
    // }
  },
};
</script>
<style scoped lang="scss">
.removeUserBasket {
  position: 'absolute';
  top: 8;
  right: 5;
}
.positionRelative {
  position: relative;
}
.phoneSaveIcon {
  position: absolute;
  top: 9px;
  right: 20px;
}
.generalDiv {
  width: 50%;
  margin: 0 auto;
}
.officeIcon {
  position: absolute;
  top: 5px;
}
.w100 {
  width: 100%;
}
.v-application .mt-5 {
  margin-top: 5px !important;
}
.rbgwaiting {
  margin-top: 0px;
  height: 30px;
}
.logoModal {
  display: inline-block;
  vertical-align: middle;
}
.footerRow {
  // border-top: 1px solid var(--v-primary-base);
  margin-top: 10px;
  padding-top: 10px;
}
.labelTitle {
  font-size: 13px !important;
}
.bannerTextLeft {
  // margin-left: 10%;
  background-color: rgb(2, 136, 157);
  height: 30px;
  max-height: 30px;
  text-align: center;
  width: 157px;
}

.bannertext {
  background-color: rgb(2, 136, 157);
  height: 30px;
  max-height: 30px;
  margin: 0px auto;
  text-align: center;
  width: 157px;
}
.btnPinDet {
  width: 20px !important;
  height: 20px !important;
}
.theme--dark .footerRow {
  background-color: #1f1f1f;
}
.theme--light .footerRow {
  background-color: #ffffff;
}

.theme--dark .labelTitle {
  color: #ffffff;
}
.theme--light .labelTitle {
  color: #212121;
}
.calendarIcon {
  font-size: 20px;
  cursor: pointer;
  margin-top: 10px;
}
.divTitle {
  font-size: 14px;
  height: 24px;
  margin-bottom: 8px;
}
.invitationMenuList {
  width: calc(100vw - 0px);
  padding-top: 0px;
  max-width: 1024px;
  max-height: 820px;
  // max-height: 768px;
}
.minHeight36 {
  min-height: 36px;
}
// .vueScrollinviteOneCallMenu {
//   height: 600px!important;
// }
.pre {
  white-space: pre;
}
.bolder {
  font-weight: 400;
}
.tightDialog {
  margin-top: 0px;
  padding-top: 0px;
}
.v-text-field {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.v-time-picker-title__time .v-picker__title__btn,
.v-time-picker-title__time span {
  height: 40px !important;
  font-size: 30px !important;
}
.autoscroll {
  overflow-y: auto;
}
.inviteTextClass {
  font-size: 13px;
  font-weight: normal;
  max-height: 306px;
}
.cursorPointer {
  cursor: pointer;
}

.v-menu__content {
  min-width: unset !important;
}
.positionClose {
  position: absolute;
  top: 0;
  right: 0;
}
.textAreaSubjectAdvanced {
  height: 59px;
  margin-top: 30px;
}
</style>

<style lang="scss">
.textAreaSubject {
  & textarea {
    overflow: scroll;
    max-height: 32px;
    border-bottom: 1px #f2f2f2;
  }
}
.textAreaInvite {
  // & .v-input__slot {
  //   height: 239px;
  // }
}
.textAreaInviteText {
  & .v-input__slot {
    height: 305px;
  }
}

.__view {
  width: unset !important;
}
.theme--light.v-input textarea {
  &::placeholder {
    color: black !important;
  }
}

.theme--dark.v-input textarea {
  &::placeholder {
    color: white !important;
  }
}

.cccheckbox {
  max-width: 50px !important;
  padding: 0 !important;
  padding-top: 21px !important;
  max-height: 46px !important;
}

.checkCallPeriod {
  & .v-input__control {
    max-height: 35px;
  }
}

.invitecheckb {
  margin-top: 0 !important;
  padding-top: 0px !important;
}
#inviteVisitorMenu {
  height: 100% !important;
  & .v-label {
    font-weight: 500 !important;
    font-size: 14px;
  }
  & .invitationMenuList {
    width: 100% !important;
    padding-top: 0px;
    max-width: 100% !important;
    max-height: 100% !important;
    min-height: 100%;
  }
}

.checkCallPeriod {
  margin-top: 0px !important;
  padding-top: 0px !important;
  max-height: 25px;
}

.checkFixedDate {
  margin-top: 0px !important;
  padding-top: 0px !important;
  max-height: 31px;
}

.inviteHeader {
  background-color: #02889d;
  height: 40px;
  margin: 0px;
  text-align: center;
}

.inviteTextTitle {
  background-color: transparent;
  color: white;
  margin: 0 auto;
  font-size: 17px;
  font-weight: bold;
  top: 2px;
  position: relative;
}
.invitationList {
  position: absolute;
  top: 0;
  right: 0;
}
.mt-17 {
  margin-top: 17px !important;
}
.mt-10 {
  margin-top: 10px !important;
}

.headselect label {
  color: white !important;
}
.headselect .v-icon {
  color: white !important;
}

.headselect .theme--light.v-text-field > .v-input__control > .v-input__slot {
  border-color: white !important;
}
.headselect > .v-input__control > .v-input__slot:before {
  border-color: rgb(255, 255, 255) !important;
}

.participantsList {
  margin: 0px;
  padding: 0px !important;
  max-height: 25px;
}
</style>
