<template>
  <v-list
    two-line
    v-if="protocolCallArray.length !== 0"
    :min-height="310"
    style="overflow: hidden"
    id="vlistProtokol"
  >
    <vue-scroll class="vueScrollProtocol">
      <div style="height: 310px">
        <v-list-item-group active-class="primary--text">
          <div v-for="(protocol, index) in protocolCallArray" :key="index">
            <template>
              <v-list-item @click="copyCallId(protocol.callUuid)">
                <template v-slot:default="{}">
                  <v-list-item-avatar
                    tile
                    size="60"
                    class="borderRadius10 cursorPointer"
                    :style="{
                      border: `2px solid ${setBorderByStatus(
                        state.group[protocol.uuid]
                      )}`,
                    }"
                  >
                    <DefaultAvatarGrid
                      :size="120"
                      :userData="state.group[protocol.uuid]"
                      v-if="
                        getAvatarForUuid(protocol.uuid) ==
                        'img/default_profile_picture.png'
                      "
                    ></DefaultAvatarGrid>
                    <v-img
                      v-if="
                        getAvatarForUuid(protocol.uuid) !=
                        'img/default_profile_picture.png'
                      "
                      class="mx-2 borderRadius10"
                      max-height="60"
                      max-width="60"
                      contain
                      :src="getAvatarForUuid(protocol.uuid)"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      style="line-height: 1.8 !important"
                      v-text="getNameForUuid(protocol.uuid)"
                    ></v-list-item-title>
                    <v-list-item-subtitle class="text--primary">
                      {{ protocol.callUuid }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text--primary">
                      {{
                        protocol.type === "outgoing"
                          ? $t("components.notificationsModal.outgoingCall")
                          : protocol.type === "incoming"
                          ? $t("components.notificationsModal.incomingCall")
                          : $t("components.notificationsModal.missedCall")
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text
                      v-text="
                        new Date(protocol.ts).toLocaleDateString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                      "
                    ></v-list-item-action-text>
                  </v-list-item-action>
                </template>
              </v-list-item>
              <v-divider
                v-if="index + 1 < protocolCallArray.length"
                :key="index"
              ></v-divider>
            </template>
          </div>
        </v-list-item-group>
      </div>
    </vue-scroll>
  </v-list>
  <v-list v-else>
    <v-list-item>
      <v-list-item-title>
        {{ getEmptyText }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import store from "../../store";
import moment from "../../../sharedsrc/moment";
import DefaultAvatarGrid from "../image/defaultAvatarGrid.vue";
import {
  dispatchSuccessAlert,
  dispatchErrorAlert,
} from "../../effector/alerts";

const hasOwn = Object.prototype.hasOwnProperty;

export default {
  props: ["notificationType"],
  components: { DefaultAvatarGrid },
  data() {
    return {
      state: store.state,
      persisted: store.state.persisted,
      moment,
      user: store.state.user,
    };
  },
  methods: {
    copyCallId(callUUID) {
      if (callUUID != "") {
        const selBox = document.createElement("textarea");
        selBox.className = "copyTextBox";
        selBox.style.position = "fixed";
        selBox.value = callUUID;
        document.getElementById("vlistProtokol").appendChild(selBox);
        selBox.select();
        try {
          document.execCommand("copy");
          dispatchSuccessAlert(
            this.$t("components.drawerContentCard.callIdCopied")
          );
        } catch (err) {
          dispatchErrorAlert(
            this.$t("components.drawerContentCard.callIdCopyError")
          );
        } finally {
          document.getElementById("vlistProtokol").removeChild(selBox);
        }
      }
    },
    setBorderByStatus(person) {
      try {
        return store.getStatuscolorByUser(person.user, person.connected);
      } catch {}
    },
    getNameForUuid: function (...args) {
      return store.getNameForUuid(...args);
    },
    getAvatarForUuid: function (...args) {
      return store.getAvatarForUuid(...args);
    },
    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      );
    },
  },
  computed: {
    getEmptyText: function () {
      if (this.notificationType == "missedCalls") {
        return this.$t("components.notificationDropDown.noMissedCalls");
      } else if (this.notificationType == "incomingTickets") {
        return this.$t("components.notificationDropDown.noNewTasks");
      } else if (this.notificationType === "messages") {
        return this.$t("components.notificationDropDown.noNewMessages");
      } else if (this.notificationType === "uploads") {
        return this.$t("components.notificationDropDown.noNewFiles");
      } else {
        return this.$t("components.notificationDropDown.noNewNotifications");
      }
    },
    protocolCallArray: function () {
      const history = [];
      const outgoing = store.state.persisted.callHistory.outgoing;
      const incoming = store.state.persisted.callHistory.incoming;
      for (const key in outgoing) {
        if (hasOwn.call(outgoing, key)) {
          const element = outgoing[key];
          const date = new Date(element.ts);
          if (this.isToday(date)) {
            const data = {
              ts: element.ts,
              type: "outgoing",
              uuid: element.uuid,
              callUuid: element.callUuid || "",
            };
            history.push(data);
          }
        }
      }
      for (const key in incoming) {
        if (hasOwn.call(incoming, key)) {
          const element = incoming[key];
          const date = new Date(element.ts);
          if (this.isToday(date)) {
            const data = {
              ts: element.ts,
              type: "incoming",
              uuid: element.uuid,
              callUuid: element.callUuid || "",
            };
            history.push(data);
          }
        }
      }
      for (let userUUID in store.state.persisted.userMessages.messages) {
        const userMessages =
          store.state.persisted.userMessages.messages[userUUID];
        if (!userMessages) continue;
        for (let msgUUID in userMessages) {
          const date = new Date(userMessages[msgUUID].date);
          const notificationType = userMessages[msgUUID].info.notificationType;
          if (notificationType === "call") {
            if (this.isToday(date)) {
              const data = {
                ts: date,
                type: "missed",
                uuid: userUUID,
              };

              history.push(data);
            }
          }
        }
      }
      history.sort((a, b) => {
        const aDate = new Date(a.ts);
        const bDate = new Date(b.ts);
        return aDate > bDate
          ? -1
          : aDate < bDate
          ? 1
          : (a.uuid || "").localeCompare(b.uuid || "");
      });
      return history;
    },
  },
};
</script>

<style scoped lang="scss">
.vueScrollProtocol {
  height: calc(100% - 506px) !important;
}
</style>

<style lang="scss">
.__view {
  width: unset !important;
}
</style>