<template>
  <v-dialog
    v-model="showBeaconDomainWhitelistModal"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="
            $t('components.adminSettingsModal.beaconDomainWhitelist')
          "
          :closeModalFunction="closeModal"
        >
        </HeaderModal>
        <v-card-text class="py-0 pt-4">
          <v-list-item>
            <v-list-item-content>
              <v-checkbox
                class="py-2 px-2"
                hide-details
                v-model="enabledBeaconDomainWhitelist"
                :label="
                  $t('components.adminSettingsModal.beaconActiveWhitelist')
                "
                @change="changeEnabledBeaconDomainWhitelist"
              ></v-checkbox>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <div class="d-flex py-2 px-2 align-center justify-space-between">
                <v-text-field
                  :disabled="!enabledBeaconDomainWhitelist"
                  :label="$t('generics.domain')"
                  v-model="domain"
                ></v-text-field>
                <v-btn
                  :disabled="!enabledBeaconDomainWhitelist"
                  class="ml-2"
                  @click="addDomainWhitelist()"
                  color="primary"
                  >{{
                    $t("components.adminSettingsModal.beaconAddDomain")
                  }}</v-btn
                >
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="enabledBeaconDomainWhitelist">
            <v-list-item-content class="contentDomainList">
              <div
                class="d-flex py-2 px-2 align-center justify-space-between"
                v-for="domain in domainsWhitelist"
                :key="domain"
              >
                <span>{{ domain }}</span>
                <DeleteBasket
                  :indexData="domain"
                  :delFunction="deleteDomainWhitelist"
                  fontSize="20"
                  prevent="false"
                />
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <FooterModal :closeModalFunction="closeModal" />
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import {
  beaconDomainWhitelistStore,
  resetBeaconDomainWhitelistEvent,
} from "../../effector/modals";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import DeleteBasket from "../ui/deleteBasket.vue";
import { validURL } from "../../utils/basicFunctions";
import {
  setNamespaceSetting,
} from "../../lib/wsMsg";

export default {
  components: { HeaderModal, FooterModal, DeleteBasket },
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
    enabledBeaconDomainWhitelist: store.state.namespaceSettings.beaconDomainWhitelist.enabled || false,
    domainsWhitelist: store.state.namespaceSettings.beaconDomainWhitelist.domains || [],
    domain: "",
  }),
  mounted() {},
  destroyed() {},
  effector: {
    showBeaconDomainWhitelistModal: beaconDomainWhitelistStore,
  },
  watch: {},
  methods: {
    deleteDomainWhitelist(domain) {
      if (!domain) return;
      const foundIndex = this.domainsWhitelist.indexOf(domain);
      if (foundIndex != -1) {
        this.domainsWhitelist.splice(foundIndex, 1);
        this.saveNamespaceSetting()
      }
    },
    addDomainWhitelist() {
      const url = validURL(this.domain);
      if (url && this.domainsWhitelist.indexOf(this.domain) == -1) {
        this.domainsWhitelist.push(this.domain);
        this.saveNamespaceSetting()
      }
      this.domain = "";
    },
    changeEnabledBeaconDomainWhitelist() {
      this.saveNamespaceSetting(9)
    },
    saveNamespaceSetting(){
       const data = {
        enabled:this.enabledBeaconDomainWhitelist,
        domains: this.domainsWhitelist
      }
      setNamespaceSetting("beaconDomainWhitelist", data);
    },
    closeModal() {
      resetBeaconDomainWhitelistEvent();
    },
  },
};
</script>
<style scoped lang="scss">
.contentDomainList {
  max-height: 270px;
  overflow: auto;
}
</style>