<template>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      max-width="700"
      @keydown.esc="closeModal"
    >
      <v-card>
        <HeaderModal :titleModal="titleModal" :closeModalFunction="closeModal" />
        <v-row class="w-100 mx-0 my-0 px-3 pt-3" :class="isMobile? 'containerModal': ''">
          <v-col
            v-for="n in 2"
            :key="n"
            :lg="cols[n - 1]"
            :md="cols[n - 1]"
            :sm="cols[n - 1]"
            :xl="cols[n - 1]"
            :class="{ 'text-center avatarContainerModal': isMobile && n == 1 }"
            class="my-0 py-0 px-0"
          >
            <div class="pl-3" v-if="n == 1">
              <v-list-item-avatar
                v-if="!multiUserCallQueueStore"
                tile
                size="150"
                class="borderRadius4"
                :style="getBorderByStatusFull(
                    state.group[state.showModalMeetingTime]
                  )"
                @dblclick="callWithoutTime(state.showModalMeetingTime)"
              >
                <v-img
                  class="borderRadius4"
                  max-height="150"
                  max-width="150"
                  contain
                  :src="getAvatarForUuid(state.showModalMeetingTime)"
                  lazy-src="img/default_profile_picture.png"
                  v-if="
                    getAvatarForUuid(state.showModalMeetingTime) !==
                    'img/default_profile_picture.png'
                  "
                ></v-img>
                <DefaultAvatar
                  :size="120"
                  :userData="state.group[state.showModalMeetingTime].user"
                  v-if="
                    getAvatarForUuid(state.showModalMeetingTime) ===
                    'img/default_profile_picture.png'
                  "
                ></DefaultAvatar>
              </v-list-item-avatar>
              <!-- // multiuser selections -->
              <div v-else>
                <v-list class="userQueueStyle pt-0">
                  <v-list-item
                    dense
                    v-for="uuid in multiUserCallQueueStore"
                    :key="uuid"
                  >
                    <v-list-item-avatar min-width="25px" min-height="25px" width="25px" height="25px" max-width="25px" max-height="25px">
                      <v-img  :src="getAvatarForUuid(uuid)"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content class="queueContent">
                      <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                      <v-list-item-title v-html="$sanitize(getNameForUuid(uuid))"></v-list-item-title>
                      <v-list-item-subtitle class="trashQueue">
                       
                         <DeleteBasket
                          :indexData="uuid"
                          :delFunction="removeUserFromMultiUserCalQueue"
                          :extraClasses="'pointer mb-1'"
                          fontSize="16"
                          v-if="uuid != state.showModalMeetingTime"
                        />
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div class="pt-3 pr-3" v-if="n == 2">
              <div v-if="getTimelineSelection" class="mb-0">
                <v-checkbox
                  class="mt-0"
                  v-model="showTimeLine"
                  label="Timeline"
                  hide-details
                ></v-checkbox>
              </div>
              <div v-if="showDurationCall">
                <p class="pb-0 mt-4 mb-1">
                  {{ $t("components.meetingLineModal.plannedDuration") }}
                </p>
              </div>
              <div class="d-flex" v-if="showDurationCall">
                <v-radio-group
                  class="my-0"
                  v-model="select"
                  @change="changeCallDuration"
                  row
                  dense
                >
                  <v-row class="my-0 mx-0">
                    <v-col
                      :cols="!isMobile ? 3 : 6"
                      :class="{ 'px-0 py-1': !isMobile, 'pt-0 pb-4': isMobile }"
                    >
                      <v-radio label="5 min" value="300000"></v-radio>
                    </v-col>
                    <v-col
                      :cols="!isMobile ? 3 : 6"
                      :class="{ 'px-0 py-1': !isMobile, 'pt-0 pb-4': isMobile }"
                    >
                      <v-radio label="10 min" value="600000"></v-radio>
                    </v-col>
                    <v-col
                      :cols="!isMobile ? 3 : 6"
                      :class="{ 'px-0 py-1': !isMobile, 'pt-0 pb-4': isMobile }"
                    >
                      <v-radio label="20 min" value="1200000"></v-radio>
                    </v-col>
                    <v-col
                      :cols="!isMobile ? 3 : 6"
                      :class="{ 'px-0 py-1': !isMobile, 'pt-0 pb-4': isMobile }"
                    >
                      <v-radio label="30 min" value="1800000"></v-radio>
                    </v-col>
                    <v-col
                      :cols="!isMobile ? 3 : 6"
                      :class="{ 'px-0 py-1': !isMobile, 'pt-0 pb-4': isMobile }"
                    >
                      <v-radio label="40 min" value="2400000"></v-radio>
                    </v-col>
                    <v-col
                      :cols="!isMobile ? 3 : 6"
                      :class="{ 'px-0 py-1': !isMobile, 'pt-0 pb-4': isMobile }"
                    >
                      <v-radio label="50 min" value="3000000"></v-radio>
                    </v-col>
                    <v-col
                      :cols="!isMobile ? 3 : 6"
                      :class="{ 'px-0 py-1': !isMobile, 'pt-0 pb-4': isMobile }"
                    >
                      <v-radio label="60 min" value="3600000"></v-radio>
                    </v-col>
                    <v-col
                      :cols="!isMobile ? 3 : 6"
                      :class="{ 'px-0 py-1': !isMobile, 'pt-0 pb-4': isMobile }"
                    >
                      <v-radio :label="$t('components.meetingTime.unlimited')" value="86400000"></v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </div>
            </div>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            v-if="
              state.persisted.tmpMessages.showMessageInMeetingTimeModal &&
              !isMobile
            "
          >
            <v-divider></v-divider>
            <p class="pt-5">{{ $t("components.meetingTime.spaceBarTip") }}</p>
            <v-checkbox
              v-model="checkedMessage"
              :label="$t('components.meetingTime.dontShowAgain')"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="pb-5 pt-0 px-2">
            <CallModalCameraPreview
              typeCall="meeting"
              :height="'100%'"
              :width="'100%'"
            ></CallModalCameraPreview>
          </v-col>
        </v-row>
        <FooterModal :closeModalFunction="closeModal">
          <template>
            <v-menu top offset-y :close-on-click="true" v-if="amIAdmin">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  class="ml-2 btnAbsolute"
                  color="white"
                >
                  <font-awesome-icon
                    :icon="['fal', 'ellipsis-v']"
                    :style="{ fontSize: '30px' }"
                  />
                </v-btn>
              </template>
              <v-list>
                <v-list-item class="pointer" v-if="amIAdmin">
                  <v-list-item-title
                    @click="toggleMeetingTimeGlobalDuration()"
                    >{{
                      $t("components.meetingTime.timeSet")
                    }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="ml-3 buttonIconPrimaryFooter callButton px-2"
                  v-on="on"
                  icon
                  tile
                  color="primary"
                  @click="startCall"
                >
                  {{ $t("generics.startCall") }}
                </v-btn>
              </template>
              <span>{{ $t("generics.startCall") }}</span>
            </v-tooltip>
          </template>
        </FooterModal>
      </v-card>
    </v-dialog>
    <MeetingTimeGlobalDuration
      :toggleMeetingTimeGlobalDuration="toggleMeetingTimeGlobalDuration"
      :showModal="showMeetingTimeGlobalDuration"
    />
    <MeetingTimeTitle
      :toggleMeetingTimeTitle="toggleMeetingTimeTitle"
      :showModal="showMeetingTimeTitle"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import store from "../../store";
import {multiUserCallQueueStore, resetMultiUserCallQueueEvent, setMultiUserCallQueueEvent} from "../../effector/multiUserCallQueue"
import { isMobile, isIpad } from "../../lib/mobileUtil";
import { callUUID } from "../../lib/rtcConn";
import { wsCallStartBridgeCall, callPerson, amInABridgeCall } from "../../utils/calls";
import MeetingTimeGlobalDuration from "./meetingTimeGlobalDuration.vue";
import MeetingTimeTitle from "./meetingTimeTitle.vue";
import DefaultAvatar from "../image/defaultAvatar.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { hasPrivilege } from "../../utils/privileges.js";
import { markAsCallWaitingRoomUserEvent, markAsCallWaitingRoomForUserEvent } from "../../effector/users/waitingInfo";
import { getBorderByStatusFull } from "../../utils/basicFunctions";
import CallModalCameraPreview from "../camera/callModalCameraPreview.vue";
import DeleteBasket from "../ui/deleteBasket.vue"
export default {
  components: {
    MeetingTimeGlobalDuration,
    MeetingTimeTitle,
    DefaultAvatar,
    HeaderModal,
    FooterModal,
    CallModalCameraPreview,
    DeleteBasket
  },
  data() {
    return {
      state: store.state,
      isMobile: isMobile(),
      isIpad: isIpad(),
      select:
        store.state.namespaceSettings.defaultMeetingDuration.value.toString(),
      ownUUID: store.state.ownUUID,
      checkedMessage: false,
      presentationView: store.state.user.startCallInPresentation,
      showMeetingTimeGlobalDuration: false,
      showMeetingTimeTitle: false,
      typeVideoCall: "bridgeCall",
      showTimeLine: true,
      waitingForUuid: null
    };
  },
  watch:{
     amInABridgeCall: {
      handler: function (inCall) {
        if(inCall){
          this.closeModal()
        }
      },
    },
  },
  effector:{
    multiUserCallQueueStore: multiUserCallQueueStore
  },
  mounted() {
    this.waitingForUuid = this.$route.params.uuid
    document.addEventListener("keyup", this.onKeyUp, {
      capture: false,
      passive: true,
    });
    if (this.userSettingsCallDuration) {
      this.select = this.userSettingsCallDuration.toString();
    }
    
    if(this.multiUserCallQueueStore){
      let userQueue = this.multiUserCallQueueStore
      const userUuid = this.state.showModalMeetingTime
       const indexUser = userQueue.indexOf(userUuid)
       if(indexUser==-1){
        userQueue.push(userUuid)
        setMultiUserCallQueueEvent(userQueue)
      }
    }
  },
  methods: {
    removeUserFromMultiUserCalQueue(uuid){
    if(this.multiUserCallQueueStore){
      let userQueue = this.multiUserCallQueueStore
      const userUuid = uuid
       const indexUser = userQueue.indexOf(userUuid)
       if(indexUser!=-1){
        userQueue.splice(indexUser, 1);
        setMultiUserCallQueueEvent(userQueue)
      }
    }
    },
    changeCallDuration() {
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.callDuration = this.select;
      store.setUserSettings(temporalUserSettings);
    },
    startCall() {
      const typeCall = this.state.user.userSettings.videoCameraCallOn
        ? "video"
        : "isAudioOnly";
      this.presentationView = false;
      this.personCall(this.state.showModalMeetingTime, {
        typeCall,
      });
    },
    getBorderByStatusFull(person) {
      return getBorderByStatusFull(person);
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    toggleMeetingTimeGlobalDuration() {
      this.showMeetingTimeGlobalDuration = !this.showMeetingTimeGlobalDuration;
    },
    toggleMeetingTimeTitle() {
      this.showMeetingTimeTitle = !this.showMeetingTimeTitle;
    },
    onKeyUp(event) {
      if (
        event.type === "keyup" &&
        event.keyCode === 32 &&
        this.state.showModalMeetingTime
      ) {
        this.personCall(this.state.showModalMeetingTime);
      }
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    callWithoutTime(uuid) {
      this.setCurrentContentVisile("", false, this.$router);
      callUUID(uuid);
      // if person is a waitingroom user i add to effector called inside waitingInfo and decrease the badge os waitingroomusers #3024
      if (!hasPrivilege(uuid)) {
        markAsCallWaitingRoomUserEvent(uuid);
      }
      this.closeModal();
    },
    personCall(uuid, options = {}) {
      if (this.typeVideoCall === "directCall") {
        this.setdurationMeeting();
        this.setPresentationView();
        this.setStartCallInPresentation(); // remember in store if user star call in presentation for the next time
        this.setCurrentContentVisile("", false, this.$router);
        callUUID(uuid, options);
        if (this.checkedMessage) {
          store.setShowMessageInMeetingTimeModal(false);
        }
        this.closeModal();
      } else if (this.typeVideoCall === "bridgeCall") {
        const id = uuidv4();
        const info = {
          callUUID: id,
          calling: [uuid],
          isNormalMode: true,
          initiator:
            this.checkInitiator === ""
              ? this.state.ownUUID
              : this.checkInitiator,
          callStartTs: Date.now(),
          callDurationMs: parseInt(this.select) || 60000 * 20,
          infiniteCall: this.showDurationCall ? false : true,
          isAudioOnly: options.typeCall === "isAudioOnly" ? true : false,
        };
        const infoStartBridgeCall = {
          callUUID: id,
          isNormalMode: true,
          initiator: this.state.ownUUID,
          callStartTs: Date.now(),
          callDurationMs: parseInt(this.select) || 60000 * 20,
          infiniteCall: this.showDurationCall ? false : true,
          isAudioOnly: options.typeCall === "isAudioOnly" ? true : false,
        };
        wsCallStartBridgeCall(uuid, this.state.ownUUID, infoStartBridgeCall);
        store.addRemoteBridgeStream(id, info);
        this.setCurrentContentVisile("", false, this.$router);
        if (this.checkedMessage) {
          store.setShowMessageInMeetingTimeModal(false);
        }
        if (!hasPrivilege(uuid)) {
        if (this.waitingForUuid) {
            markAsCallWaitingRoomForUserEvent(uuid);
          }
          else {
            markAsCallWaitingRoomUserEvent(uuid);
          }
        }
         // code for multiuser queue checkbox
        const StartCallUuid = this.state.showModalMeetingTime
        const userQueue = this.multiUserCallQueueStore
        this.$nextTick(() => {
          // console.log("nextTick", userQueue, StartCallUuid)
          if(userQueue){
            userQueue.forEach(uuid => {
              // console.log(uuid, StartCallUuid)
              if(uuid!== this.ownUUID && StartCallUuid!==uuid){
                callPerson(uuid, this.$router)
              }
            });
          }
        });
        this.closeModal();
      }
    },
    setStartCallInPresentation() {
      return store.setStartCallInPresentation(this.presentationView);
    },
    setPresentationView() {
      return store.setPresentationView(
        this.presentationView,
        this.state.ownUUID
      );
    },
    setdurationMeeting() {
      if (this.showDurationCall) {
        return store.setdurationMeeting(parseInt(this.select));
      } else {
        return store.setdurationMeeting(undefined);
      }
    },
    closeModal() {
      if(this.multiUserCallQueueStore) resetMultiUserCallQueueEvent()
      return store.setShowModalMeetingTime(undefined);
    },
  },
  computed: {
    userSettingsCallDuration() {
      return store.state.user.userSettings.callDuration;
    },
    showDurationCall() {
      const useFeatureTimeline =
        this.state.namespaceSettings.useFeatureTimeline;
      const timelineSelection = this.state.namespaceSettings.timelineSelection;
      if (useFeatureTimeline) {
        if (timelineSelection === "timelineObligatory") {
          return true;
        } else if (
          timelineSelection === "timelineUserSelection" &&
          this.showTimeLine
        ) {
          return true;
        }
      }
      return false;
    },
    getTimelineSelection() {
      const useFeatureTimeline =
        this.state.namespaceSettings.useFeatureTimeline;
      if (useFeatureTimeline) {
        if (
          this.state.namespaceSettings.timelineSelection ===
          "timelineUserSelection"
        ) {
          return true;
        }
      }
      return false;
    },
    checkInitiator() {
      const callUUID = this.getCallUUID;
      if (callUUID !== "") {
        return this.state.remoteBridgeStreams[callUUID].initiator;
      } else {
        return "";
      }
    },
    getCallUUID() {
      let remoteStreamsKey;
      if (Object.keys(this.state.remoteBridgeStreams).length > 0) {
        remoteStreamsKey = Object.keys(this.state.remoteBridgeStreams)[0];
      } else {
        remoteStreamsKey = "";
      }
      return remoteStreamsKey;
    },
    titleModal() {
      if(this.multiUserCallQueueStore) return this.$t("components.meetingTime.vofficeCall")
      const typePerson = this.hasPrivilege(this.state.showModalMeetingTime)
        ? this.$t("generics.userLowercase")
        : this.$t("generics.guestLowercase");
      if (
        this.state.showModalMeetingTime &&
        !store.state.group[this.state.showModalMeetingTime].beaconCallInvite
      ) {
        return (
          this.$t("components.meetingTime.titleModal") +
          " " +
          typePerson +
          " " +
          this.getNameForUuid(this.state.showModalMeetingTime)
        );
      } else {
        return this.$t("components.meetingTime.titleModal") + " " + typePerson;
      }
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    showModal() {
      return store.state.showModalMeetingTime !== undefined;
    },
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.breakpoint;
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : xl ? [4, 8] : [12, 12];
    },
    amInABridgeCall(){
      return amInABridgeCall();
    }
  },
};
</script>
<style scoped lang="scss">
.avatarContainerModal{
  min-width: 100%;
  width: 100%;
}
.containerModal{
  overflow: auto;
  max-height: 50vh;
  min-height: 50vh;
  height: 50vh;
}
.trashQueueBtn:focus:before{
  background: transparent!important;
  opacity: 0!important;
}
.trashQueueBtn:hover::before{
  background: transparent!important;
  opacity: 0!important;
}
.trashQueueBtn:hover{
  background: transparent!important;
}
.userQueueStyle{
  max-height: 160px;
  overflow-y: auto;
  margin-top: 19px;
  margin-bottom: 5px;
  margin-right: 10px;
}
.trashQueue{
  max-width: 10%;
  width: 10%;
}
.queueContent{
  display: contents;
}
.userQueueStyle::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .userQueueStyle::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ababab;
    border-radius: 10px;
  }
  /* Handle */
  .userQueueStyle::-webkit-scrollbar-thumb {
    background: #06a5df;
    border-radius: 10px;
  }

.callButton {
  width: auto !important;
}
.pointer {
  cursor: pointer;
}
.btnAbsolute {
  position: absolute;
  left: 0;
}
.w100 {
  width: 100%;
}
.w-100 {
  width: 100%;
}
.v-avatar .v-image {
  border-radius: 10px !important;
}
.borderRadius4,
.v-avatar .v-image {
  border-radius: 4px !important;
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: unset !important;
}
.v-application .v-text-field .v-label {
  font-size: 20px !important;
}
</style>
