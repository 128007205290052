<template>
  <!-- <v-badge
    bordered
    color="error"
    :content="totalNotification"
    :value="totalNotification"
    overlap
  > -->
    <!-- <vue-scroll> -->
      <v-menu offset-y :close-on-click="true" :max-height="370" :max-width="350">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon :color="iconsBlack ? 'black' : 'white'" v-on="on" id="v-tour-sentMessages">
                  <font-awesome-icon
                    :icon="['fal', 'comment-alt-lines']"
                    :style="{ fontSize: '16px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t('components.drawerContentCard.sentMessages') }}</span>
            </v-tooltip>
          </div>
        </template>

        <!-- <NotificationDropDown :notificationType="'messages'" /> -->
        <SentMessagesDropDownEffector />
      </v-menu>
    <!-- </vue-scroll> -->
  <!-- </v-badge> -->
</template>

<script>
import store from '../../../store';
// import NotificationDropDown from "../notificationDropDown.vue";
import SentMessagesDropDownEffector from "../sentMessagesDropDownEffector.vue";

export default {
  props: ['iconsBlack'],
  components: { SentMessagesDropDownEffector },
  data: () => ({
    state: store.state,
  }),
  methods:{
    // setNotificationEffector(){
    //   if(this.messagesToMe.length > 0){
    //     dispatchViewedMessageEvent();
    //   }
    // },
  },
  computed:{
    // totalNotification(){
    //   return this.unseenMessagesToMe
    // }
  }
}
</script>
<style scoped lang="scss">
.lockMessageIcon{
  position: absolute;
  right: -6px;
  top: -10px;
}
</style>