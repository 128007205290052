<template>
  <v-list max-width="320">
    <UserList :persons="sortedPersons" :section="'Users'" />
  </v-list>
</template>

<script>
import store from "../../store";
import UserList from "./userList.vue";

export default {
  props: [
    "persons",
    "setCurrentContentVisile",
    "sortedPersons",
    "active",
    "isMobile"
  ],
  components: {
    UserList
  },
  data: () => ({
    state: store.state
  }),
};
</script>

<style scoped lang="scss">
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.theme--dark .listColor {
  color: white !important;
}
.theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>

