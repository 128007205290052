var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{attrs:{"max-width":"320"}},[_c('v-list-group',{attrs:{"value":_vm.searchFoundSomethingTeam || _vm.isActive,"color":_vm.isDarkMode ? 'white' : 'black'},on:{"click":function($event){return _vm.setList('teams')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"primary--text",attrs:{"icon":['fal', 'sitemap']}})],1),_vm._v(" "),_c('v-list-item-title',{class:("" + (_vm.getRoute === '/organisation' &&
          _vm.state.activeOrganisationSection == 'teams'
            ? 'font-weight-bold'
            : '')),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.handleOrganisation();
          _vm.searchFoundSomethingTeam;}}},[_c('span',{staticClass:"pr-4"},[_vm._v(_vm._s(_vm.totalTeams || 0))]),_vm._v("\n        "+_vm._s(_vm.state.namespaceSettings.teamAlias
            ? _vm.state.namespaceSettings.teamAlias
            : _vm.$t("components.teams.teams"))+"\n      ")])]},proxy:true}])},[_vm._v(" "),_c('div',[_c('v-list',{staticClass:"listHeight",attrs:{"dense":""}},_vm._l((_vm.teams),function(team){return _c('v-list-item',{key:team.team,staticClass:"ml-9",attrs:{"link":""}},[_c('div',{staticClass:"text-truncate w80 cursorAuto",class:_vm.selectedTeamName === team.team ? 'font-weight-bold primary--text': '',on:{"click":function($event){return _vm.openUserTeam(team.team)}}},[_vm._v("\n            "+_vm._s(team.team)+"\n          ")]),_vm._v(" "),_c('v-list-item-icon',{staticClass:"alingIcon",on:{"click":function($event){return _vm.openMenu($event, team.team)}}},[_c('font-awesome-icon',{staticClass:"primary--text",attrs:{"icon":['fal', 'video']}})],1)],1)}),1),_vm._v(" "),_c('v-menu',{attrs:{"close-on-click":_vm.closeOnClick,"close-on-content-click":_vm.closeOnContent,"position-x":_vm.positionX,"position-y":_vm.positionY},model:{value:(_vm.showMenuTeam),callback:function ($$v) {_vm.showMenuTeam=$$v},expression:"showMenuTeam"}},[(_vm.selectedTeam)?_c('v-list',[_c('div',{staticClass:"userListTeams"},_vm._l((_vm.membersOfTeams(_vm.selectedTeam)),function(item,y){return _c('v-list-item',{key:y,staticClass:"mencheckbox section",attrs:{"item-text":"name"}},[_c('v-checkbox',{staticClass:"mencheckbox chkList sel-check-item",attrs:{"label":item.name,"value":item.id,"disabled":_vm.disableCallBtnForConference(item.id)},on:{"change":function($event){return _vm.groupVideoCall($event, item.id)}},model:{value:(_vm.actionCallUserList),callback:function ($$v) {_vm.actionCallUserList=$$v},expression:"actionCallUserList"}})],1)}),1),_vm._v(" "),_c('v-list-item',{staticClass:"select-box justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3 buttonIconPrimaryFooter primary--text  px-2",attrs:{"tile":"","color":"white","disabled":_vm.actionCallUserList.length === 0 || _vm.amIInCall},on:{"click":_vm.callPeople}},on),[_vm._v("\n                "+_vm._s(_vm.$t("generics.group"))+" \n              ")])]}}],null,false,828257715)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.userListItem.videoCall")))])])],1)],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }