<template>
  <v-dialog
    v-model="modalData.show"
    persistent
    max-width="550"
    @keydown.esc="cancelChangeFunction"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.changeFunctionModal.editFunction')"
        :closeModalFunction="cancelChangeFunction"
      />
      <v-card-text>
        <v-text-field
          v-model="newFunction"
          class="body-2"
          label
          type="text"
          :maxlength="20"
        ></v-text-field>
      </v-card-text>
      <FooterModal
        :closeModalFunction="cancelChangeFunction"
        :showCancel="true"
      >
        <v-btn color="primary" @click="setNewFunction(modalData.person)">{{
          $t("generics.save")
        }}</v-btn>
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { setRemoteUser } from "../../lib/wsMsg.js";
export default {
  props: ["modalData", "cancelChangeFunction"],
  components: { HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
    newFunction: "",
  }),
  methods: {
    setNewFunction(person) {
      person.user.position = this.newFunction;
      setRemoteUser(person.uuid, { position: this.newFunction });
      this.cancelChangeFunction();
    },
  },
  mounted: function () {
    if (
      this.modalData &&
      this.modalData.person &&
      this.modalData.person.user.position
    ) {
      this.newFunction = this.modalData.person.user.position;
    }
  },
};
</script>