<template>
  <v-dialog v-model="showModal" persistent max-width="570">
    <v-card>
      <HeaderModal :titleModal="$t('generics.info')" />
      <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
        <v-card-text>
          <v-text-field
            v-model="visitorName"
            :label="$t('components.inviteVisitor.typeYourName') "
            outlined
            dense
            counter
            :maxlength="120"
          ></v-text-field>
           <!-- <v-text-field
           v-if="isConferenceCall"
            v-model="visitorLocation"
            :label="$t('components.inviteVisitor.typeYourLocation') "
            outlined
            dense
            counter
            :maxlength="120"
          ></v-text-field> -->
          <v-text-field
          v-if="isConferenceCall"
            v-model="visitorDescription"
            :label="$t('components.inviteVisitor.typeYourDescription') "
            outlined
            dense
            counter
            :maxlength="120"
          ></v-text-field>
        </v-card-text>
      </v-row>
      <FooterModal :hideDefaultButtons="true">
        <v-btn
          class="buttonIconPrimaryFooter saveButton px-2"
          icon
          tile
          color="primary"
          :disabled="disableSaveVisitorInfo"
          @click="saveVisitorInfo"
        >
          {{ $t("generics.save") }}
        </v-btn>
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import {
  visitorNameModalStore,
  resetVisitorNameModalEvent,
} from "../../effector/modals";
export default {
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
      visitorName: "",
      visitorLocation: "",
      visitorDescription: ""
    };
  },
  effector: {
    showModal: visitorNameModalStore,
  },
  methods: {
    closeModal() {
      resetVisitorNameModalEvent();
    },
    setName(name){
      return store.setUserName(name)
    },
    setLocation(location){
      return store.setUserLocation(location)
    },
    setDescription(description){
      return store.setUserDescription(description)
    },
    saveVisitorInfo() {
      this.setName(this.visitorName)
      if(this.isConferenceCall){
        // if(this.visitorLocation) {
        //   this.setLocation(this.visitorLocation)
        // }
        if(this.visitorDescription){
          this.setDescription(this.visitorDescription)
        }
      }
      this.closeModal();
    },
  },
  computed: {
    isConferenceCall() {
      // when we have the links we have to check if that user is from the conference hall
      return true
    },
    disableSaveVisitorInfo(){
      return !this.visitorName
      // if(this.isConferenceCall){
      //   return !this.visitorName || !this.visitorLocation
      // }else {
      //   return !this.visitorName
      // }
    }
  },
};
</script>
<style scoped lang="scss">
.saveButton {
  width: auto !important;
}

</style>
