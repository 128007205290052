<template>
  <div class="pRelative">
    <v-col class="conferenceTitleWrap">
      <span>
        {{
          $t("components.callsContent.conferenceTotalUsers", [
            getListenerList.length,
          ])
        }}
      </span>
    </v-col>
    <v-col class="listenerContainer" ref="listenerContainer">
      <v-col
        class="listenerColumn"
        v-for="(uuid, index) in getSortedListenerList"
        :key="index"
      >
        <div
          class="pRelative"
          @mousemove="showBar(uuid)"
          @mouseleave="hidebar(uuid)"
        >
          <!-- Top bar -->
          <div class="topbar">
            <div class="userIconInfo">
              <span class="userIconTile font-weight-light d-flex align-center">
                <font-awesome-icon
                  class="mr-2"
                  :icon="['fal', 'user']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </div>
            <v-card-subtitle
              style="color: #fff !important"
              class="callerName font-weight-light pt-0 pb-0 px-0 text-truncate"
            >
              {{ getNameForUuid(uuid) }}
            </v-card-subtitle>
            <div class="rightSideBar">
              <font-awesome-icon
                v-if="
                  listenerRaisedHandStore[uuid] &&
                  listenerRaisedHandStore[uuid].raisedHand
                "
                color="red"
                :icon="['fas', 'hand-point-up']"
                class="iconHand ml-2"
                :style="{ fontSize: '20px' }"
              />
            </div>
          </div>
          <!-- END Top bar -->
          <!-- user action bar -->
          <div
            class="icons iconsRemote"
            :id="`remoteBar_${uuid}`"
            v-if="visibleBar == `remoteBar_${uuid}`"
          >
            <div class="buttosContainer">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="getAmIModerator"
                    class="buttonsCall"
                    icon
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    @click.prevent.stop="toggleHangUpListener(uuid)"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'phone']"
                      class="faPhoneRotate"
                      :style="{ fontSize: '20px' }"
                    />
                  </v-btn>
                </template>
                <span>{{ $t("generics.hangUp") }}</span>
              </v-tooltip>
              <v-menu
                class="customMenuLocal"
                content-class="customDotsMenuLocal"
                right
                :offset-x="true"
                :close-on-content-click="true"
                z-index="999999"
                :key="index"
                v-model="menuOpen"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dense
                    class="buttonsCall darkGreyIcon"
                    color="white"
                    icon
                    v-bind="attrs"
                    @click.stop.prevent
                    v-on="on"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'ellipsis-v']"
                      :style="{ fontSize: '20px' }"
                    />
                  </v-btn>
                </template>
                <v-list class="text-left" dark>
                  <v-list-item @click="setUserInModal(uuid)">
                    <v-list-item-icon>
                      <font-awesome-icon
                        :icon="['fal', 'info-circle']"
                        :style="{ fontSize: '20px' }"
                      />
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ $t("components.adminSettingsModal.userInfo") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="getAmIModerator"
                    @click="toggleGiveWord(uuid)"
                    :disabled="getTotalUserInPodium>=12"
                  >
                    <v-list-item-icon>
                      <font-awesome-icon
                        :icon="['fal', 'comment']"
                        :style="{ fontSize: '20px' }"
                      />
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ $t("components.callsContent.moveToStage") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <!-- End user action bar -->
          <!-- Timer -->
          <template v-if="listenerRaisedHandStore[uuid] && listenerRaisedHandStore[uuid].raisedHand && callInitiator == state.ownUUID && raisedHandTimerValues[uuid]">
            <div :key="`timer-${uuid}`" class="timerPosition red--text">{{raisedHandTimerValues[uuid] ? new Date(raisedHandTimerValues[uuid].seconds * 1000).toISOString().substring(14, 19) : ''}}</div>
          </template>
          <!-- End Timer -->
          <!-- User image -->
          <div class="imgContent">
            <div class="imgUserContainer">
              <v-img
                contain
                :class="`imageCallListeners`"
                max-height="75px"
                max-width="75px"
                :src="
                  getAvatarForUuid(uuid) || 'img/default_profile_picture.png'
                "
                lazy-src="img/default_profile_picture.png"
              ></v-img>
            </div>
            <!-- End User image -->
          </div>
        </div>
      </v-col>
    </v-col>
  </div>
</template>
<script>
import debounce from "lodash.debounce";
import store, { syncedUserState } from "../../../store";
import { setUserProfileEvent } from "../../../effector/modals";
import { bridgeInfoStore } from "../../../effector/users/bridgeInfo";
import { bridgeCallGetAllListeners } from "../../../lib/wsMsg";
import { wsCall } from "../../../lib/wsConnect";
import { setListenerRaisedHandEvent, listenerRaisedHandStore } from "../../../effector/listenerRaisedHand";

export default {
  props: ["getAmIModerator", "isListenerView", "chatOpen"],
  data() {
    return {
      state: store.state,
      menuOpen: false,
      visibleBar: false,
      raisedHandIntervalHolders: {},
      raisedHandTimerValues: {}
    };
  },
  destroyed() {
    Object.keys(this.raisedHandIntervalHolders).forEach(userUUID => this.cleanTimer(userUUID));
    document.removeEventListener("wheel", this.onMouseWheel);
  },
  mounted() {
    document.addEventListener("wheel", this.onMouseWheel);
    const getAllListener = async () => {
      await syncedUserState();
      const listeners = await bridgeCallGetAllListeners();
      setListenerRaisedHandEvent(listeners);
    };
    getAllListener();
  },
  effector: {
    bridgeInfoStore: bridgeInfoStore,
    listenerRaisedHandStore: listenerRaisedHandStore,
  },
  watch: {
    menuOpen: {
      handler: function (open) {
        if (!open && this.visibleBar) {
          this.visibleBar = false;
        }
      },
    },
    getPodiumList: {
      handler: debounce(function (podiumList, oldPodiumList) {
        if (
          podiumList.length == 0 &&
          oldPodiumList.length > 0 &&
          this.isListenerView
        ) {
          console.log("kick listener")
          const callUUID = this.state.user.inBridgeCallListener;
          store.removeRemoteBridgeStreams(callUUID);
        }
      }, 100),
    },
    listenerRaisedHandStore: {
      deep: true,
      handler: function (raisedHand) {
        if (this.callInitiator !== this.state.ownUUID) return;
        for (const userUUID in raisedHand) {
          if (Object.hasOwnProperty.call(raisedHand, userUUID)) {
            const element = raisedHand[userUUID];
            if (element.raisedHand) {
              if (!this.raisedHandIntervalHolders[userUUID]) {
                this.raisedHandIntervalHolders[userUUID] = setInterval(this.timer, 1000, userUUID)
              }
            } else if (this.raisedHandIntervalHolders[userUUID]) {
              this.cleanTimer(userUUID)
            }
            if (element.inPodium && this.raisedHandIntervalHolders[userUUID]) {
              this.cleanTimer(userUUID)
            }
          }
        }
        Object.keys(this.raisedHandIntervalHolders)
          .forEach(userUUID => !Object.hasOwnProperty.call(raisedHand, userUUID) && this.cleanTimer(userUUID));
      }
    },
  },
  methods: {
    cleanTimer(userUUID) {
      clearInterval(this.raisedHandIntervalHolders[userUUID]);
      delete this.raisedHandIntervalHolders[userUUID];
      delete this.raisedHandTimerValues[userUUID];
    },
    timer (userUUID) {
      if (!this.raisedHandTimerValues[userUUID]) {
        this.$set(this.raisedHandTimerValues, userUUID, { seconds: 0 })
      } else {
        this.raisedHandTimerValues[userUUID].seconds++
      }
    },
    toggleHangUpListener(user) {
      const callUUID = this.state.user.inBridgeCall;
      if (!callUUID) return false
      wsCall("sendToUUID", user, {
        type: "bridge-signal",
        action: "hangup_listener",
        sender: this.state.ownUUID,
        callUUID: callUUID,
      });
    },
    toggleGiveWord(user) {
      const callUUID = this.state.user.inBridgeCall;
       if(!callUUID) return false
      const infoCall = this.state.remoteBridgeStreams[callUUID]
      wsCall("sendToUUID", user, {
        type: "bridge-signal",
        action: "podium_listener",
        sender: this.state.ownUUID,
        callUUID: callUUID,
        infoCall: infoCall
      });
    },
    showBar(user) {
      if (!this.menuOpen) {
        this.visibleBar = "remoteBar_" + user;
      }
    },
    hidebar() {
      if (!this.menuOpen) {
        this.visibleBar = false;
      }
    },
    setUserInModal(uuid) {
      //open modal if im not guest
      if (!uuid) return;
      const person = this.state.group[uuid];
      store.state.ownModalUser = uuid === this.state.ownUUID;
      store.state.userOnModal = { ...person.user };
      store.state.userOnModal.connected = person.user.connected;
      store.state.userOnModal.uuid = uuid;
      store.state.userOnModal.userTS = person.userTS;
      setUserProfileEvent(true);
    },
    onMouseWheel(e) {
      if (!this.chatOpen && this.$refs.listenerContainer) {
        this.$refs.listenerContainer.scrollLeft += e.deltaY;
      }
    },
    getNameForUuid(userUUID) {
      return store.getNameForUuid(userUUID);
    },
    getAvatarForUuid(userUUID) {
      return store.getAvatarForUuid(userUUID);
    },
  },
  computed: {
    callInitiator() {
      const callUUID = this.isListenerView
        ? this.state.user.inBridgeCallListener
        : this.state.user.inBridgeCall;
      if (!callUUID) return;
      return (this.state.remoteBridgeStreams[callUUID] || {}).initiator;
    },
    getListenerList() {
      if (!this.bridgeInfoStore || !this.bridgeInfoStore.listeners) return [];
      let callUUID = this.isListenerView
        ? this.state.user.inBridgeCallListener
        : this.state.user.inBridgeCall;
      if (!callUUID) return [];
      let listenerList = [];
      for (const key in this.bridgeInfoStore.listeners) {
        if (Object.hasOwnProperty.call(this.bridgeInfoStore.listeners, key)) {
          const element = this.bridgeInfoStore.listeners[key];
          if (element == callUUID) {
            listenerList.push(key);
          }
        }
      }
      return listenerList || [];
    },
    getSortedListenerList() {
      const raisedHandList = this.listenerRaisedHandStore;
      const listenerList = this.getListenerList;
      let sortList = [];
      let tmpArray = [];
      for (const key in raisedHandList) {
        if (Object.hasOwnProperty.call(raisedHandList, key)) {
          const element = raisedHandList[key];
          if (element.raisedHand) {
            const tmp = {
              raisedHand: element.raisedHand,
              tsRaiseHand: element.tsRaiseHand,
              uuid: key,
            };
            tmpArray.push(tmp);
          }
        }
      }
      tmpArray.sort((a, b) => {
        const aDate = new Date(a.tsRaiseHand);
        const bDate = new Date(b.tsRaiseHand);
        return aDate < bDate
          ? -1
          : aDate > bDate
          ? 1
          : (a.uuid || "").localeCompare(b.uuid || "");
      });
      tmpArray = tmpArray.map((e) => e.uuid);
      sortList = tmpArray.concat(listenerList);
      sortList = sortList.filter((item, index) => {
        return sortList.indexOf(item) == index;
      });
      return sortList || [];
    },
    getTotalUserInPodium(){
      return this.getPodiumList.length
    },
    getPodiumList() {
      if (!this.bridgeInfoStore || !this.bridgeInfoStore.podium) return [];
      let callUUID = this.isListenerView
        ? this.state.user.inBridgeCallListener
        : this.state.user.inBridgeCall;
      if (!callUUID) return [];
      let podiumList = [];
         for (const key in this.bridgeInfoStore.podium) {
        if (Object.hasOwnProperty.call(this.bridgeInfoStore.podium, key)) {
          const element = this.bridgeInfoStore.podium[key];
          if (element == callUUID) {
            podiumList.push(key);
          }
        }
      }
      return podiumList || [];
    },
  },
};
</script>
<style scoped lang="scss">
.timerPosition {
  position: absolute;
  right: 0px;
  z-index: 1;
  top: 30px;
  font-weight: bold;
}
.iconHand {
  animation: blinker 1.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.imgContent {
  top: 67%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
}
.imgUserContainer {
  position: relative;
  height: 100%;
  width: 100%;
}
.listenerColumn {
  width: 10%;
  min-width: 10%;
  max-width: 10%;
}
.listenerContainer::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.listenerContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #000000;
  border-radius: 10px;
}
/* Handle */
.listenerContainer::-webkit-scrollbar-thumb {
  background: #06a5df;
  border-radius: 10px;
}
.listenerContainer {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: auto;
  background: black;
  padding: 0;
  //just for firefox
  scrollbar-color: #06a5df #000000;
  scrollbar-width: thin;
  width: 100%;
  // height: 100%;
  height: 85%;
}
.pRelative {
  width: 100%;
  height: 100%;
  position: relative;
}
.topbar {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  height: 30px;
  font-size: 15px;
  top: 0px;
  padding: 4px;
  .userIconInfo {
    position: absolute;
    font-size: 15px;
  }
  .rightSideBar {
    position: absolute;
    right: 13px;
    top: 4px;
    font-size: 15px;
  }
  & .callerName {
    max-width: 65%;
    margin: 0 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.icons {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  bottom: 0;
  top: 30px !important;
  margin: 0 !important;
  height: 36px;
  width: 100%;
  text-align: left;
  display: flex;
  left: 0;
  justify-content: center;
  cursor: default !important;

  .buttosContainer {
    background-color: rgb(0, 0, 0);
    box-shadow: 2px 2px 24px #636363;
    opacity: 0.6;
    padding: 6px;
    height: 50px;
    border-radius: 15px;
    &:hover {
      opacity: 0.8;
    }
  }
}
.conferenceTitleWrap {
  background: #808080;
  padding: 0;
  height: 25px;
  // height: 15%;
  text-align: center;
  color: white;
}
@media (max-width: 1400px) {
  .listenerColumn {
    width: 12% ;
    min-width: 12%;
    max-width: 12%;
  }
}
@media (max-width: 1200px) {
  .listenerColumn {
    width: 14%;
    min-width: 14%;
    max-width: 14%;
 }
}
@media (max-width: 1100px) {
  .listenerColumn {
    width: 22%;
    min-width: 22%;
    max-width: 22%;
  }
}
@media (max-width: 900px) {
  .listenerColumn {
    width: 30%;
    min-width: 30%;
    max-width: 30%;
  }
}
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>