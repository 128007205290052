var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',{staticClass:"px-0 py-0"},[_c('v-row',[_c('v-col',{staticClass:"mx-2 mr-6"},[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('font-awesome-icon',{attrs:{"slot":"append","icon":['fal', 'search']},slot:"append"})],1)],1),_vm._v(" "),[_c('v-data-table',{staticClass:"guestTableList elevation-0",attrs:{"headers":_vm.headers,"items":_vm.payments,"items-per-page":10,"page":_vm.page,"fixed-header":"","dense":"","search":_vm.search,"height":"calc(100vh - 265px)"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.transactionDate",fn:function(ref){
var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.moment(item.transactionDate).format("DD.MM.YYYY HH:mm"))+"\n            ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v("\n              "+_vm._s(item.amount)+" "+_vm._s(item.currency)+"\n            ")]}},{key:"item.transactionStatus",fn:function(ref){
var item = ref.item;
return [_vm._v("\n              "+_vm._s(item.transactionStatus === "approved"
                  ? _vm.$t("components.payment.approved")
                  : item.transactionStatus)+"\n            ")]}},{key:"item.isFacePay",fn:function(ref){
                  var item = ref.item;
return [_vm._v("\n              "+_vm._s(item.isFacePay == 1 ? _vm.$t("generics.yes") : _vm.$t("generics.no"))+"\n            ")]}},{key:"item.isPayLink",fn:function(ref){
                  var item = ref.item;
return [_vm._v("\n              "+_vm._s(item.isPayLink == 1 ? _vm.$t("generics.yes") : _vm.$t("generics.no"))+"\n            ")]}}])})]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }