<template>
  <v-dialog
    v-model="showInviteInfo"
    persistent
    max-width="460"
    @keydown.esc="closeShowInviteInfo"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.userListItem.invitationSent')"
        :closeModalFunction="closeShowInviteInfo"
      />
      <v-card-text class="pt-6 pb-7">
        {{ $t("components.userListItem.yourInvitationTo") }}
        <b
          >{{ state.sendInvitationGuestModal.invitationData.name }} ({{
            state.sendInvitationGuestModal.invitationData.email
          }})</b
        >
        {{ $t("components.userListItem.hasBeenSuccessfullySent") }}
      </v-card-text>
      <FooterModal :closeModalFunction="closeShowInviteInfo" />
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  props: ["showInviteInfo", "closeShowInviteInfo"],
  data: () => ({
    state: store.state,
    save: false,
  }),
};
</script>
<style scoped lang="scss">
.btns {
  justify-content: flex-end;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.logo {
  left: 0;
  position: absolute;
}
</style>