<template>
  <v-dialog v-model="showActivation" persistent max-width="595" @keydown.esc="closeModal">
    <v-card>
      <v-card-title class="headline">
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        {{ $t('components.2faActivation.title') }}
      </v-card-title>
      <v-card-subtitle class="pt-2">
        <div class="py-2">
          <span>{{ $t('components.2faActivation.sentence1') }}</span>
        </div>
        <div class="py-2">
          <div class="py-1 px-2 redBordered">
            <span>{{ $t('components.2faActivation.sentence2') }}</span>
          </div>
        </div>
        <div class="py-2">
          <v-row class="mx-0 my-3">
            <template>
              <v-col cols="7" class="py-0 positionRelative m-0a">
                <vue-tel-input
                  v-model="telInputModel"
                  required
                  defaultCountry="DE"
                  enabledCountryCode
                  @onInput="onTelInput"
                  name="number"
                  id="numDisplay"
                  class="form-control text-center input-sm inputPhone2FA"
                  value
                  placeholder
                  autocomplete="off"
                ></vue-tel-input>
                <span class="whatsAppIcon">
                  <font-awesome-icon :icon="['fab', 'whatsapp']" :style="{ fontSize: '20px' }" />
                </span>
              </v-col>
            </template>
          </v-row>
        </div>
        <div class="py-2 greenBordered">
          <div class="py-1" style="max-height:50px;">
            <span slot="label" class="spanCheckBox">{{$t('components.2faActivation.checkBoxText')}}</span>
            <v-checkbox
              :disabled="disabledoptOutCheck"
              v-model="optOut"
              class="optOutCheckbox"
              :value="optOut"
              @change="optOutChanged($event)"
            ></v-checkbox>
          </div>
        </div>
      </v-card-subtitle>
      <v-card color="primary" class="py-2 pl-4 mt-0 pr-2 footerModal">
        <v-row class="px-2 btns mx-0">
          <v-col cols="12" class="px-0 py-0" align="right">
            <!-- <v-btn
              color="primary"             
            >{{ $t('generics.cancel') }}
            </v-btn>-->
              <!-- @click="checkPhoneAndSave()" -->
            <v-btn
            @click="saveEntryNoTel()"
              color="primary"
              :disabled="saveDisabled"
            >{{ $t('generics.save') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
    <TwoFactorLastChancePhone
      v-if="showLastChance"
      :toggle2faLastChangeModal="toggle2faLastChangeModal"
      :showLastChance="showLastChance"
      :saveEntryNoTel="saveEntryNoTel"
    ></TwoFactorLastChancePhone>
  </v-dialog>
</template>

<script>
import store from "../../../../store";
import TwoFactorLastChancePhone from "./2faLastChancePhone.vue";
export default {
  props: [
    "toggle2faActivationModal",
    "showActivation",
    "saveEntryNoPhone"
  ],
  components: {
    TwoFactorLastChancePhone
  },
  data() {
    return {
      state: store.state,
      telInputModel: '',
      telInputValid: false,
      showLastChance: false
    };
  },
  computed: {
    optOut: {
      get: function () {
        return this.state.persisted.ignoreRecoveryPhone || false;
      },
      set: function (newValue) {
        this.$set(this.state.persisted, "ignoreRecoveryPhone", newValue);
      },
    },
    phoneNumber: {
      get: function() {
        return this.state.user.recoveryPhone;
      },
      set: function(newValue) {
        this.$set(this.state.user, "recoveryPhone", newValue);
      }
    },
    saveDisabled() {
      return !this.telInputValid && !this.optOut;
    },
    phoneNumberValid() {
      return (
        !!this.phoneNumber &&
        typeof this.phoneNumber === "string" &&
        this.phoneNumber.startsWith("+") &&
        /^\+\d+$/.test(this.phoneNumber)
      );
    },
    disabledoptOutCheck() {
      return this.telInputValid;
    }
  },
  methods: {
    optOutChanged(event){
      if( event ) {
       this.telInputModel = '';
      }
    },
    saveEntryNoTel() {
      this.showLastChance = false;
      // close popups and call save on entry without phone
      if (this.telInputModel === '' && this.optOut) {
        this.$set(this.state.user, "recoveryPhone", this.telInputModel);
      }
      this.saveEntryNoPhone();
    },
    toggle2faLastChangeModal() {
      this.showLastChance = !this.showLastChance;
    },
    onTelInput({ number, isValid, country }) {
      if (isValid) { // Autosave valid numbers in user state
        this.phoneNumber = number.international.replace(/[-\s]/g, "");
        this.optOut = false;
      }
      this.telInputValid = isValid;
    },
    checkPhoneAndSave() {
      // if phone save and close
      if (this.telInputValid) {
        this.saveEntryNoPhone();
      } else {
        this.showLastChance = true;
      }
    },
    changeoptOut() {
      this.optOut = !this.optOut;
    },
    closeModal() {
      this.toggle2faActivationModal();
    }
  },
  watch: {
    showActivation: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.telInputModel = this.phoneNumber;
          this.telInputValid = this.phoneNumberValid;
        } else {
          this.telInputModel = '';
          this.telInputValid = false;
        }
      }
    }
  },
};
</script>
<style scoped lang="scss">
.optOutCheckbox {
  width: 5%;
  display: inline-block;
  margin-top: 0px;
  padding-top: 0px;
  position: relative;
  top: -4px;
  text-align: right;
}
.spanCheckBox {
  width: 90%;
  display: inline-block;
}
.m-0a {
  margin: 0 auto !important;
}
.positionRelative {
  position: relative;
}
.whatsAppIcon {
  position: absolute;
  top: 9px;
  right: 20px;
}
.inputPhone2FA {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  border-bottom: none !important;
  height: 38px;
}
.redBordered {
  border: 1px solid red;
}
.greenBordered {
  border-top: 1px solid green;
  border-bottom: 1px solid green;
}
</style>