<template>
  <v-dialog
    v-model="protocol"
    persistent
    max-width="650"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.drawerContentCard.protocol')"
        :closeModalFunction="closeModal"
      />
      <ProtocolCallDropDown />
      <FooterModal :closeModalFunction="closeModal" />
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import ProtocolCallDropDown from "../sidebar/protocolCallDropDown.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { ProtocolCallDropDown, HeaderModal, FooterModal },
  props: ["protocol", "showProtocol"],
  data: () => ({
    state: store.state,
    setColorSettings: store.setColorSettings,
    isMobile: isMobile(),
  }),
  methods: {
    closeModal() {
      this.showProtocol();
    },
  },
  mounted() {
    if (typeof document.store.state.user.colorsettings === "undefined") {
      this.setColorSettings(true, false);
    }
  },
};
</script>