 <template>
  <v-dialog v-model="showModal" persistent max-width="580" @keydown.esc="closeModal">
    <v-card>
      <v-row flex class="w-100 mx-0 my-0 justify-space-between">
        <v-col class="px-0 py-0" align="left">
          <v-card-title class="headline">
            <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
            {{$t('components.representative.representative')}}
          </v-card-title>
        </v-col>
        <div class="pa-4">
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="black" @click="closeModal">
                  <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '25px' }" />
                </v-btn>
              </template>
              <span>{{ $t('generics.close') }}</span>
            </v-tooltip>
          </template>
        </div>
      </v-row>
      <v-row class="mx-0 px-3">
        <v-col class="col-12 px-1 py-1 pt-2">
          <v-switch
            hide-details
            class="my-0 py-2"
            v-model="activeRepresentative"
            :label="$t('components.representative.activate')"
          ></v-switch>
        </v-col>
        <v-col class="col-12 px-1 py-1 pt-2">
          <v-select
            :disabled="!activeRepresentative"
            v-model="userSelected"
            :items="getUserList"
            class="mt-3"
            :label="$t('generics.users')"
            hide-details
            outlined
            dense
            item-text="name"
            item-value="value"
            return-object
          ></v-select>
        </v-col>
      </v-row>
      <!-- <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns mx-0" flex>
          <v-col cols="8" class="px-0 py-0" align="right">
            <v-btn color="primary" @click="saveRepresentatives">{{ $t('generics.save') }}</v-btn>
          </v-col>
          <v-col cols="8" class="px-0 py-0" align="right">
            <v-btn color="primary" @click="toggleModalRepresentative">{{ $t('generics.close') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>-->
      <!-- /////// Footer area ///////// -->
      <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns w-100 mx-0 my-0">
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="white" @click="saveRepresentatives" class="mr-3">
                  <font-awesome-icon :icon="['fal', 'check']" :style="{ fontSize: '20px' }" />
                </v-btn>
              </template>
              <span>{{ $t('generics.accept') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  color="white"
                  @click.stop.prevent="closeModal"
                  class="ml-3"
                >
                  <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
                </v-btn>
              </template>
              <span>{{ $t('generics.close') }}</span>
            </v-tooltip>
          </template>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import { hasRepresentative } from "../../utils/representatives";
import { setRepresentedUsers } from '../../lib/wsMsg';

export default {
  props: ["showModal", "closeModal"],
  data() {
    return {
      state: store.state,
      activeRepresentative: false,
      userSelected: {},
    };
  },
  mounted: function () {
    this.loadDataModal();
  },
  methods: {
    hasRepresentative(uuid) {
      return hasRepresentative(uuid);
    },
    loadDataModal() {
      if (this.hasRepresentative(this.state.ownUUID)) {
        const representativesUuid = this.hasRepresentative(this.state.ownUUID);
        this.activeRepresentative = true;
        this.userSelected = {
          name: this.getNameForUuid(representativesUuid),
          value: representativesUuid,
        };
      }
    },
    saveRepresentatives() {
      let userObject = {};
      let arrayUser = [];
      if (this.activeRepresentative) {
        userObject = { uuid: this.userSelected.value };
        arrayUser.push(userObject);
      }
      store.setRepresentatives(this.activeRepresentative, arrayUser);
      setRepresentedUsers(this.userSelected.value, this.state.ownUUID, this.activeRepresentative);
      this.closeModal();
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
  },

  computed: {
    getUserList() {
      const persons = Object.keys(store.state.group)
        .filter((uuid) => {
          const person = store.state.group[uuid];
          return (
            person &&
            person.user &&
            person.user.name &&
            person.permissions !== 10 &&
            !person.user.guest &&
            !person.user.visitor
          );
        })
        .map((uuid) => {
          const person = store.state.group[uuid];
          return { name: person.user.name, value: uuid };
        });
      return persons.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
</style>