var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.protocolCallArray.length !== 0)?_c('v-list',{staticStyle:{"overflow":"hidden"},attrs:{"two-line":"","min-height":310,"id":"vlistProtokol"}},[_c('vue-scroll',{staticClass:"vueScrollProtocol"},[_c('div',{staticStyle:{"height":"310px"}},[_c('v-list-item-group',{attrs:{"active-class":"primary--text"}},_vm._l((_vm.protocolCallArray),function(protocol,index){return _c('div',{key:index},[[_c('v-list-item',{on:{"click":function($event){return _vm.copyCallId(protocol.callUuid)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-list-item-avatar',{staticClass:"borderRadius10 cursorPointer",style:({
                    border: ("2px solid " + (_vm.setBorderByStatus(
                      _vm.state.group[protocol.uuid]
                    ))),
                  }),attrs:{"tile":"","size":"60"}},[(
                      _vm.getAvatarForUuid(protocol.uuid) ==
                      'img/default_profile_picture.png'
                    )?_c('DefaultAvatarGrid',{attrs:{"size":120,"userData":_vm.state.group[protocol.uuid]}}):_vm._e(),_vm._v(" "),(
                      _vm.getAvatarForUuid(protocol.uuid) !=
                      'img/default_profile_picture.png'
                    )?_c('v-img',{staticClass:"mx-2 borderRadius10",attrs:{"max-height":"60","max-width":"60","contain":"","src":_vm.getAvatarForUuid(protocol.uuid)}}):_vm._e()],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"line-height":"1.8 !important"},domProps:{"textContent":_vm._s(_vm.getNameForUuid(protocol.uuid))}}),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"text--primary"},[_vm._v("\n                    "+_vm._s(protocol.callUuid)+"\n                  ")]),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"text--primary"},[_vm._v("\n                    "+_vm._s(protocol.type === "outgoing"
                        ? _vm.$t("components.notificationsModal.outgoingCall")
                        : protocol.type === "incoming"
                        ? _vm.$t("components.notificationsModal.incomingCall")
                        : _vm.$t("components.notificationsModal.missedCall"))+"\n                  ")])],1),_vm._v(" "),_c('v-list-item-action',[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(
                      new Date(protocol.ts).toLocaleDateString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                    )}})],1)]}}],null,true)}),_vm._v(" "),(index + 1 < _vm.protocolCallArray.length)?_c('v-divider',{key:index}):_vm._e()]],2)}),0)],1)])],1):_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v("\n      "+_vm._s(_vm.getEmptyText)+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }