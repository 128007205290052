<template>
  <div>
    <v-content
      :class="`px-0 py-0 ${removeGeneralWrapper ? null : 'contentGridWrapper'} ${isMobile ? 'contentGridWrapperMobile' : 'contentGridWrapper'}`"
      v-on:scroll.native="handleScroll">
      <template v-if="state.user.readGridMessage">
        <UserProfle v-if="showUserProfile" :showUserProfile="showUserProfile" :toggleUserProfile="toggleUserProfile"
          :enableCam="true" />
        <v-row class="mx-auto"
          v-if="(canISeeUser && !isMobile) || (canISeeUser && isMobile && (typeData !== 'favorites' || state.user.userSettings.favoritesViewMobile === 'grid') && typeData !== 'coffee-break')">
          <v-col class="custom6cols pb-1 px-2" cols="6"
            v-if="($route.path === '/guest' || $route.path === '/invites') && !isMobile">
            <v-card class="cardWidth cursorPointer" :style="{ border: `1px solid #efefef` }" @click="clickOnAddCard">
              <div style="height: 180px; display: table; width: 100%">
                <div class="divAdd">
                  <font-awesome-icon :icon="['fal', 'plus']" class="buttonAdd" />
                  <p class="mb-0 mt-2">{{ $t("generics.addUserCard") }}</p>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col class="custom6cols pb-1 px-2" cols="6"
            v-if="$route.path === '/users' && amIAdmin && !showSidebar && !isMobile">
            <v-card class="cardWidth cursorPointer" :style="{ border: `1px solid #efefef` }" @click="openUserInvite">
              <div style="height: 180px; display: table; width: 100%">
                <div class="divAdd">
                  <font-awesome-icon :icon="['fal', 'plus-circle']" class="buttonAdd" />
                  <p class="mb-0 mt-2">
                    {{ $t("components.footernav.inviteUser") }}
                  </p>
                  <v-btn class="mt-3" color="primary" outlined dense @click.prevent.stop="gotoInviteUserList()">
                    {{ $t("components.inviteGuestPanel.inviteList") }}
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col v-for="(person, index) in slicedPersons" :key="index" class="custom6cols pb-1 px-2" cols="6">
            <v-card class="cardWidth" @mouseover="selectIndex(index)" @mouseout="ItemIndex = null"
              @contextmenu="openMenu($event, person.uuid)" :style="getBorderByStatusFull(person)"
              @click="handleClicks($event, person, `flipCard-${index}`)">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-img :src="getAvatarByType(person.uuid)" class="white--text align-end" height="180px">
                      <div class="gradientBg">
                        <v-card-subtitle style="color: #fff !important"
                          class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                          {{ titelPerson(person) }} {{ namePerson(person) }}
                        </v-card-subtitle>
                        <v-card-subtitle v-if="person.user && hasRepresentative(person.uuid) && person.user.representative && state.group && person.user.representative.value in state.group"
                          :style="getBorderByStatusFull(getPersonByUuid(person.user.representative.value))"
                          class="d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between"
                          @click.stop.prevent>
                          <div class="representative-subtitle text-truncate">
                            <CallIcon :person="getPersonByUuid(person.user.representative.value)" :isRegularGrid="true"
                              class="mr-1"></CallIcon>
                            <span class="colorWhite">
                              {{ getRepresentativeName(person) }}
                            </span>
                          </div>
                          <ButtonsFrontCard :person="person" :uuid="person.uuid" sectionName="members" class="pt-1"
                            :flipCard="flipCard" :index="index" :typeData="typeData"
                            style="position: inherit !important" />
                        </v-card-subtitle>
                        <ButtonsFrontCard v-if="!hasRepresentative(person.uuid) || !(person.user.representative && state.group && person.user.representative.value in state.group)" :person="person" :uuid="person.uuid"
                          sectionName="members" :flipCard="flipCard" :index="index" :typeData="typeData" />
                      </div>
                      <ButtonsCardWrapper :person="person" :isRegularGrid="true" :showMultiUserCallIcon="true" />
                    </v-img>
                  </div>
                </template>
                <UserInfoTooltip :person="person"></UserInfoTooltip>
              </v-tooltip>
            </v-card>
          </v-col>
          <infinite-loading :distance="5" @infinite="infiniteHandler">
            <div slot="no-results"></div>
            <div slot="no-more"></div>
          </infinite-loading>
        </v-row>
        <div
          v-if="canISeeUser && isMobile && typeData == 'favorites' && (state.user.userSettings.favoritesViewMobile === 'swipe' || !state.user.userSettings.favoritesViewMobile)">
          <swiper ref="swiperComponent" :options="swiperOptions">
            <swiper-slide v-for="(person, index) in slicedPersons" :key="index">
              <v-card class="cardWidth" height="90%" width="100%" max-width="95%" :style="getBorderByStatusFull(person)"
                @click="handleClicks($event, person, `flipCard-${index}`)">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-img :src="getAvatarByType(person.uuid)" class="white--text align-end" height="100%">
                        <font-awesome-icon :icon="['fas', 'phone']" class="buttonCallSwiper primary--text"
                          :style="{ fontSize: '30px' }" v-if="!disableCallBtn(person)" />
                        <div class="gradientBg">
                          <v-card-subtitle style="color: #fff !important"
                            class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                            {{ titelPerson(person) }} {{ namePerson(person) }}
                          </v-card-subtitle>
                          <v-card-subtitle v-if="person.user && hasRepresentative(person.uuid) && person.user.representative && state.group && person.user.representative.value in state.group"
                            :style="getBorderByStatusFull(getPersonByUuid(person.user.representative.value))"
                            class="d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between"
                            @click.stop.prevent>
                            <div class="representative-subtitle text-truncate">
                              <CallIcon :person="getPersonByUuid(person.user.representative.value)" :isRegularGrid="true"
                                :ownUUID="ownUUID" class="mr-1"></CallIcon>
                              <span class="colorWhite">
                                {{ getRepresentativeName(person) }}
                              </span>
                            </div>
                            <ButtonsFrontCard :person="person" :uuid="person.uuid" sectionName="members" class="pt-1"
                              :flipCard="flipCard" :index="index" :typeData="typeData"
                              style="position: inherit !important" />
                          </v-card-subtitle>
                          <ButtonsFrontCard v-if="!hasRepresentative(person.uuid) || !(person.user.representative && state.group && person.user.representative.value in state.group)" :person="person" :uuid="person.uuid"
                            sectionName="members" :flipCard="flipCard" :index="index" :typeData="typeData" />
                        </div>
                        <ButtonsCardWrapper :person="person" :isRegularGrid="true" :showMultiUserCallIcon="true" />
                      </v-img>
                    </div>
                  </template>
                  <UserInfoTooltip :person="person"></UserInfoTooltip>
                </v-tooltip>
              </v-card>
            </swiper-slide>
          </swiper>
        </div>
        <template v-if="amIGuest">
          <RemoveFromFavoriteModal :person="deleteFavorite.person" :showModal="deleteFavorite.show"
            :closeModal="closeDeleteFavoriteModal"></RemoveFromFavoriteModal>
        </template>
        <template v-if="changeUserFunction.show">
          <ChangeFunctionModal :modalData="changeUserFunction" :cancelChangeFunction="cancelChangeFunction">
          </ChangeFunctionModal>
        </template>
        <template v-if="showInvitationModal">
          <SendInvitationModal :closeShowInviteInfo="closeShowInviteInfo" :showInviteInfo="showInvitationModal" />
        </template>
      </template>
      <template v-else>
        <GridUserMessage />
      </template>
    </v-content>
  </div>
</template>

<script>
import store, { EventBus } from "../../store";
import Flipper from "vue-flipper";
import UserInfoTooltip from "./userInfoTooltip.vue";
import { isMobile, isIpad } from "../../lib/mobileUtil";
import DefaultAvatarGrid from "../image/defaultAvatarGrid.vue";
import RemoveFromFavoriteModal from "../modal/removeFromFavoriteModal.vue";
import ChangeFunctionModal from "../modal/changeFunctionModal.vue";
import UserProfle from "../modal/userProfile.vue";
import SendInvitationModal from "../modal/sendInvitationModal.vue";
import BackOrganisationCard from "./backOrganisationCard.vue";
import ButtonsFrontCard from "./buttonsFrontCard.vue";
import ButtonsCardWrapper from "./buttonsCardWrapper.vue";
import { hasRepresentative, getRepresentative } from "../../utils/representatives";
import {
  callPerson,
  disableCallBtn,
  amInABridgeCall,
  userInCall,
  directBridgeCall,
  personIsInBridgeCall,
} from "../../utils/calls";
import {
  setBorderByStatus,
  checkListSort,
  getPictureSize,
  getAvatarByType,
  getBorderByStatusFull
} from "../../utils/basicFunctions";
import { hasPrivilege } from "../../utils/privileges";
import InviteGuest from "../navbar/inviteGuest.vue";
import RegularGridHeader from "./regularGridHeader.vue";
import GridUserMessage from "./gridUserMessage.vue";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import CallIcon from "./callIcon.vue";
const hasOwn = Object.prototype.hasOwnProperty;
export default {
  components: {
    ButtonsFrontCard,
    Flipper,
    DefaultAvatarGrid,
    RemoveFromFavoriteModal,
    ChangeFunctionModal,
    UserProfle,
    UserInfoTooltip,
    SendInvitationModal,
    BackOrganisationCard,
    InviteGuest,
    RegularGridHeader,
    GridUserMessage,
    ButtonsCardWrapper,
    Swiper,
    SwiperSlide,
    CallIcon
  },
  directives: {
    swiper: directive
  },
  props: ["typeData", "removeGeneralWrapper"],
  data() {
    return {
      state: store.state,
      clicks: 0,
      timer: null,
      deleteFavorite: {
        show: false,
        person: undefined,
      },
      changeUserFunction: {
        show: false,
        person: undefined,
      },
      ownUUID: store.state.ownUUID,
      showUserProfile: false,
      showInvitationModal: false,
      sliceIndex: 48,
      isMobile: isMobile(),
      isIpad: isIpad(),
      ItemIndex: null,
      viewMenu: false,
      top: "0px",
      left: "0px",
      swiperOptions: {
        loop: true,
      }
    };
  },
  methods: {
    gotoInviteUserList() {
      this.setCurrentContentVisile("invitationformuser", true, this.$router);
      setTimeout(() => {
        EventBus.$emit("openTabInviteUsertList");
      }, 200);
    },
    openUserInvite() {
      this.setCurrentContentVisile("invitationformuser", true, this.$router);
    },
    handleScroll() {
      EventBus.$emit("closeMenu");
    },
    openMenu: function (e, uuid) {
      //userActionsList
      e.preventDefault();
      const element = document.getElementById(`userActionList-${uuid}`);
      if (element) {
        EventBus.$emit("closeMenu");
        element.click();
      }
    },
    selectIndex(Index) {
      this.ItemIndex = Index;
    },
    clickOnAddCard() {
      this.setCurrentContentVisile("invitationform", true, this.$router);
    },
    infiniteHandler($state) {
      if (this.sliceIndex < this.sortedPersons.length) {
        this.sliceIndex += 6;
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    directBridgeCall(uuid) {
      directBridgeCall(uuid, this.$router);
    },
    closeShowInviteInfo() {
      this.showInvitationModal = false;
    },
    changeFunction(person) {
      this.changeUserFunction = {
        show: true,
        person: person,
      };
    },
    cancelChangeFunction() {
      this.changeUserFunction = {
        show: false,
        person: undefined,
      };
    },
    closeDeleteFavoriteModal() {
      this.deleteFavorite = {
        show: false,
        person: undefined,
      };
    },
    userIsAdmin(uuid) {
      return store.getUserIsAdmin(uuid);
    },
    flipCard(reference) {
      this.$refs[reference][0].flipped = !this.$refs[reference][0].flipped;
    },
    handleClicks(event, person, reference) {
      if (person && person.uuid == this.ownUUID) return
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.clicks = 0;
          if (person.uuid !== this.ownUUID) {
            if (!this.disableCallBtn(person)) {
              this.callUser(person.uuid);
            }
          } else {
            this.setUserInModal();
          }
        }, 700);
      } else {
        clearTimeout(this.timer);
        this.clicks = 0;
        if (!this.disableCallBtn(person)) {
          if (this.inBridgeCall) {
            this.callUser(person.uuid);
          } else {
            if (!this.userIsInCall(person)) {
              if (personIsInBridgeCall(person.uuid)) {
                this.callUser(person.uuid);
              } else {
                this.directBridgeCall(person.uuid);
              }
            }
          }
        }
      }
    },
    setUserInModal() {
      store.state.ownModalUser = true;
      store.state.userOnModal = {
        ...store.state.user,
        connected: store.state.wsOpen,
        uuid: store.state.ownUUID,
      };
      this.toggleUserProfile();
    },
    toggleUserProfile() {
      this.showUserProfile = !this.showUserProfile;
    },
    disableCallBtn(personObj) {
      let person = personObj;
      return disableCallBtn(person);
    },
    userIsInCall(person) {
      return userInCall(person.uuid);
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    getAvatarByType(uuid) {
      return getAvatarByType(uuid);
    },
    namePerson(person) {
      if (person && person.user.name) {
        return person.user.name;
      }
    },
    titelPerson(person) {
      return store.getTitelForUuid(person.uuid);
    },
    getRepresentativeName(person) {
      return store.getUserRepresentativeName(person.uuid);
    },
    setBorderByStatus(person) {
      return setBorderByStatus(person);
    },
    getBorderByStatusFull(person) {
      return getBorderByStatusFull(person);
    },
    hasRepresentative(uuid) {
      return hasRepresentative(uuid);
    },
    getRepresentative(uuid) {
      return getRepresentative(uuid);
    },
    getPersonByUuid(uuid) {
      return store.getPersonByUuid(uuid);
    },
  },
  computed: {
    showSidebar() {
      return this.state.persisted.showSidebar;
    },
    inBridgeCall() {
      return amInABridgeCall();
    },
    getPictureSize() {
      return getPictureSize();
    },
    canISeeUser() {
      return (
        this.amIAdmin ||
        this.typeData === "favorites" || //exclude limit 24 in favorites #1816
        this.typeData === "coffee-break" ||
        Object.keys(this.sortedPersons).length < 24 ||
        (this.state.namespaceSettings.organisation || []).length === 0 ||
        this.state.namespaceSettings.displayAllUsers
      );
    },
    userList() {
      return store.getProcessedUserList();
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    amIGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    slicedPersons() {
      return this.sortedPersons.slice(0, this.sliceIndex);
    },
    sortedPersons() {
      let persons = [];
      if (this.typeData === "users") {
        for (let uuid in store.state.group) {
          const person = uuid
            && hasOwn.call(store.state.group, uuid)
            && store.state.group[uuid];
          if (
            !person ||
            !person.user ||
            person.user.hidedByAdmin ||
            !hasPrivilege(uuid)
          ) {
            continue;
          } else {
            persons.push({ ...person, uuid });
          }
        }
        return checkListSort(persons, this.state.user.userSettings.usersSort);
      } else if (this.typeData === "favorites") {
        if (Object.keys(store.state.persisted.favoriteUsers || {}).length) {
          for (let uuid in store.state.persisted.favoriteUsers) {
            if (
              hasOwn.call(store.state.persisted.favoriteUsers, uuid) &&
              hasOwn.call(store.state.group, uuid)
            ) {
              const person = uuid
                && store.getUuidFromFavorites(uuid)
                && store.state.group[uuid];
              if (person && person.user && !person.user.hidedByAdmin) {
                persons.push({ ...person, uuid });
              }
            }
          }
        }
        return checkListSort(
          persons,
          this.state.user.userSettings.favoritesSort
        );
      } else if (this.typeData === "coffee-break") {
        for (let uuid in store.state.group) {
          const person = uuid
            && hasOwn.call(store.state.group, uuid)
            && store.state.group[uuid];
          if (!person || !person.user || person.user.hidedByAdmin) continue;
          if (person.connected && person.user.presentFrom) {
            if (person.user.activity === "Coffee break") {
              persons.push({ ...person, uuid });
            }
          }
        }
        return checkListSort(persons);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.buttonCallSwiper {
  position: absolute;
  pointer-events: none;
  bottom: 40px;
  right: 10px;
}

.cursorPointer {
  cursor: pointer;
}

.buttonAdd {
  font-size: 49px;
  color: var(--v-accent-base);
}

.divAdd {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}

.theme--dark .gradientBg {
  background: linear-gradient(#00000099, #000000cc);
}

.gradientBg {
  position: relative;
}

.customFontSize {
  font-size: 13px !important;
  word-break: break-word;
  max-width: 85%;
}

.fontSize12 {
  font-size: 12px !important;
}

.colorWhite {
  color: white !important;
}

.theme--dark.v-btn.v-btn--disabled {
  color: #00000042 !important;
}

.contentGridWrapperMobile {
  height: calc(100vh - 200px) !important;
  overflow: auto;
}

.contentGridWrapper {
  height: calc(100vh - 130px);
  overflow: auto;
}

.custom6cols {
  width: 16%;
  flex-basis: 16%;
}

.cardWidth {
  max-width: 235px;
  box-shadow: none !important;
}

@media (max-width: 765px) {
  .contentGridWrapper .custom6cols {
    width: 50%;
    min-width: 50%;
  }
}

// 6 columns
@media (max-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}

@media (min-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .contentGridWrapper .custom6cols {
    width: 20% !important;
    flex-basis: 20% !important;
  }
}

@media (max-width: 890px) {
  .contentGridWrapper .custom6cols {
    width: 25% !important;
    flex-basis: 25% !important;
  }
}

@media (min-width: 765px) and (max-width: 1024px) {
  .cardWidth {
    max-width: 175px !important;
    min-width: 175px !important;
  }
}

.v-card__subtitle {
  color: black !important;
}

.v-card__title {
  font-size: 15px !important;
}

.representative-subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
}

.representative-subtitle span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-card__subtitle .frontButtons {
  position: inherit !important;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}
</style>

<style lang="scss">
.swiper-container {
  height: 100%;
  width: 100%;
}

.theme--light .swiper-slide {
  background: white;
}

.theme--dark .swiper-slide {
  background: #131313;
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100% !important;
}
</style>