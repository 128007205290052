<template>
  <v-badge
    bordered
    color="error"
    :content="totalNotificationFile"
    :value="totalNotificationFile"
    overlap
  >
    <v-menu offset-y :close-on-click="true" :max-height="370" :max-width="350">
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon :color="iconsBlack ? 'black' : 'white'" v-on="on" @click="setTotalNotificationFile(0)" id="v-tour-files">
                <!-- <v-btn icon color="white" v-on="on" > -->
                <font-awesome-icon
                  :icon="['fal', 'upload']"
                  :rotation="180"
                  :style="{ fontSize: '16px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t('components.drawerContentCard.files') }}</span>
          </v-tooltip>
        </div>
      </template>
      <DownloadsDropdown />
    </v-menu>
  </v-badge>
</template>

<script>
import store from '../../../store';
import DownloadsDropdown from "../downloadsDropdown.vue";
export default {
  props: ['totalNotificationFile', 'setTotalNotificationFile', 'iconsBlack'],
  components: { DownloadsDropdown },
  data: () => ({
    state: store.state,
  })
}
</script>