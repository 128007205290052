<template >
  <v-dialog v-model="showModal" persistent max-width="550" @keydown.esc="closeModal">
    <v-card>
      <HeaderModal :titleModal="$t('generics.info')" :closeModalFunction="closeModal" />
      <v-divider></v-divider>
      <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
          <v-card-text>
        <span>    {{ $t("components.adminSettingsModal.relayServerInfo2") }}</span>
      </v-card-text>
      </v-row>
      <FooterModal :closeModalFunction="closeModal"> </FooterModal>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
    props: ["showModal", "closeModal"],
  components: { HeaderModal, FooterModal },
  data: () => ({}),
  mounted: {},
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss">
</style>