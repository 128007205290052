var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-0",attrs:{"fill-height":"","fluid":""}},[(!_vm.isAllowed)?_c('SubdomainAvailable',{attrs:{"isAllowed":_vm.isAllowed}}):_vm._e(),_vm._v(" "),(_vm.isAllowed)?[_c('div',{staticClass:"fill-height fill-width"},[(_vm.state.setUpConfig.route !== 'paypal')?_c('v-row',{staticClass:"fill-height"},[_c('v-col',{staticClass:"primary backgroundImage hidden-sm-and-down",attrs:{"cols":"6","md":6,"xl":6}},[_c('div',{staticClass:"fill-half-width divContentText"},[_c('div',{staticClass:"logoLeftsideDiv"},[_c('v-img',{attrs:{"src":"assets/icon.png","max-height":"150","max-width":"150","contain":""}})],1),_vm._v(" "),_c('h2',{staticClass:"font-weight-bold mb-2"},[_vm._v(_vm._s(_vm.getUrl))]),_vm._v(" "),_c('p',{staticClass:"mb-0 customFontSize font-weight-medium"},[_vm._v("\n              "+_vm._s(_vm.isFree
                  ? _vm.$t("components.companyRegister.domainIsFree")
                  : _vm.$t("components.subdomainTaken.namespaceNotFree"))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"copyRightDiv d-flex"},[_vm._v("\n            RA-MICRO Software AG © 2024\n            "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"ml-2 cursorPointer",attrs:{"src":("img/flags/" + _vm.getSelectedLang + ".png")}},'v-img',attrs,false),on))]}}],null,false,571532548)},[_vm._v(" "),_c('v-list',_vm._l((_vm.getLangs),function(lang,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changeLang(lang.name)}}},[_c('v-list-item-title',{staticClass:"text-center"},[_c('v-img',{attrs:{"src":lang.img}})],1)],1)}),1)],1),_vm._v(" "),_c('span',{staticClass:"ml-2",domProps:{"innerHTML":_vm._s(_vm.$t('components.takeAction.privacyPolicylegalNotive'))},on:{"click":function($event){$event.stopPropagation();}}})],1)]),_vm._v(" "),_c('v-col',{staticClass:"formRightSide",attrs:{"cols":"6","md":6,"sm":12,"xs":12,"xl":6}},[_c('div',{staticClass:"contentRightSide"},[_c('v-row',{staticClass:"w-100 mx-0 my-0 justify-space-between",attrs:{"flex":""}},[_c('v-col',{staticClass:"px-0 py-0",attrs:{"align":"left"}},[_c('v-card-title',{class:{
                    'titleSize d-flex px-0 mb-0':
                      _vm.state.setUpConfig.route === 'subdomainAvailable',
                  }},[_c('span',{staticClass:"font-weight-medium",domProps:{"innerHTML":_vm._s(_vm.$t('components.companyRegister.getStarted'))}})])],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-row',{staticClass:"pt-4"},[_c('v-col',[(_vm.state.setUpConfig.route === 'subdomainAvailable')?_c('CompanyRegister',{attrs:{"setIsFree":_vm.setIsFree,"setNamespaceName":_vm.setNamespaceName}}):_vm._e(),_vm._v(" "),(_vm.state.setUpConfig.route === 'conditions')?_c('Conditions'):_vm._e(),_vm._v(" "),(_vm.state.setUpConfig.route == 'plans')?_c('Plans'):_vm._e(),_vm._v(" "),(_vm.state.setUpConfig.route == 'verifyEmail')?_c('VerifyEmail'):_vm._e()],1)],1)],1)])],1):_vm._e(),_vm._v(" "),(_vm.state.setUpConfig.route == 'paypal')?_c('PayPal',{attrs:{"isFromRegister":true,"namespaceName":_vm.namespaceName,"isFree":_vm.isFree}}):_vm._e()],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }