var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list-item',[[_c('font-awesome-icon',{staticClass:"mr-4",attrs:{"icon":['fal', 'inbox-in']}}),_vm._v(" "),_c('v-list-item-avatar',{staticClass:"borderRadius10 cursorPointer",style:({
          border: ("2px solid " + (_vm.setBorderByStatus(_vm.state.group[_vm.entry.uuid]))),
        }),attrs:{"tile":"","size":"60"}},[(
            _vm.getAvatarForUuid(_vm.entry.uuid) == 'img/default_profile_picture.png'
          )?_c('DefaultAvatarGrid',{attrs:{"size":120,"userData":_vm.state.group[_vm.entry.uuid]}}):_vm._e(),_vm._v(" "),(
            _vm.getAvatarForUuid(_vm.entry.uuid) != 'img/default_profile_picture.png'
          )?_c('v-img',{staticClass:"mx-2 borderRadius10",attrs:{"max-height":"60","max-width":"60","contain":"","src":_vm.getAvatarForUuid(_vm.entry.uuid)}}):_vm._e()],1),_vm._v(" "),_c('v-list-item-content',{staticClass:"pl-2"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.getNameForUuid(_vm.entry.uuid))}}),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(_vm.entry.fileName)}})],1),_vm._v(" "),_c('v-list-item-action',{staticClass:"ml-0"},[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(
            _vm.formatNoticationDate(new Date())
          )}}),_vm._v(" "),_c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[_c('DeleteBasket',{attrs:{"indexData":[_vm.entry.uuid, _vm.entry.fileName, _vm.entry.msgUUID],"delFunction":_vm.remove,"extraClasses":"mr-4","fontSize":"16","prevent":"true"}}),_vm._v(" "),(_vm.entry.incoming && !_vm.entry.actualMD5)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('font-awesome-icon',_vm._g({staticClass:"mr-4",style:({fontSize:16}),attrs:{"icon":['fal', 'download'],"color":"grey lighten-1"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.entry.resolve(true)}}},on))]}}],null,false,2211321691)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.fileItemList.download")))])]):_vm._e(),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('a',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.entry.incoming && _vm.entry.complete),expression:"entry.incoming && entry.complete"}],staticClass:"btnSave mr-4",attrs:{"href":_vm.entry.finalData,"download":_vm.entry.fileName,"target":"_blank"}},on),[_c('font-awesome-icon',{attrs:{"icon":['fal', 'save'],"color":"grey lighten-1"}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("generics.save")))])]),_vm._v(" "),(!_vm.entry.incoming)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('font-awesome-icon',_vm._g({staticClass:"mr-4",style:({fontSize:16}),attrs:{"icon":['fal', 'inbox-out'],"color":"grey lighten-1"}},on))]}}],null,false,214801822)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.fileItemList.outgoingFile")))])]):_vm._e()],1)],1)]],2),_vm._v(" "),_c('div',{staticClass:"parentProgressBar"},[_c('v-progress-linear',{attrs:{"active":true,"background-opacity":0.3,"bottom":false,"buffer-value":100,"height":4,"indeterminate":false,"query":false,"rounded":false,"stream":false,"striped":false,"top":false,"value":_vm.percentDone,"color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }