<template>
  <!-- NEW HEADER -->
  <v-card class="eventFromCard">
    <v-card-title
      :class="setBackground"
      :style="`color:${
        setBackground !== 'white' && setBackground !== null ? 'white' : 'unset'
      }`"
    >
      <v-row class="mx-0">
        <v-col cols="4" class="px-1 py-1">
          <span
            v-if="existingEvent && existingEvent.isConference"
            class="headline largeTitle"
          >
            <v-img
              class="mr-1 logoModal"
              src="assets/icon.png"
              max-height="30"
              max-width="30"
              contain
            ></v-img>
            {{
              existingEvent.editConference
                ? $t("components.groups.group")
                : $t("components.addGroupModal.addNewGroup")
            }}
          </span>
          <span v-else class="headline">
            <v-img
              class="mr-1 logoModal"
              src="assets/icon.png"
              max-height="30"
              max-width="30"
              contain
            ></v-img>
            {{ $t("components.meetingLineModal.videoCallPlaner") }}
          </span>
        </v-col>
        <v-col cols="8" class="px-1 py-2 mx-auto">
          <span class="font16 font-weight-bold" v-if="getStartDate">{{
            getStartDate
          }}</span>
          <v-menu
            v-else
            ref="startDateMenu"
            v-model="startDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formatedStart"
                readonly
                v-on="on"
                outlined
                dense
                hide-details
                class="w154"
                :label="$t('components.eventEntryForm.startEvent')"
              ></v-text-field>
            </template>
            <v-date-picker
              @click:date="$refs.startDateMenu.save(startEvent)"
              v-model="startEvent"
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="startTimeMenu"
            :disabled="!canEditEvent"
            v-model="startTimeMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="timeStart"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                style="height: 40px"
                :disabled="!canEditEvent || !formatedStart"
                class="buttonMinutes"
                :class="{
                  'mr-8': !isCreatingConference,
                  'ml-1 mr-1': isCreatingConference,
                }"
              >
                <span v-if="!timeStart && !timeEnd" class="font16">{{
                  $t("components.eventEntryForm.startTime")
                }}</span>
                <span
                  v-else
                  style="text-transform: none"
                  class="font16 font-weight-bold"
                  >{{ timeStart }} - {{ timeEnd }}</span
                >
                <font-awesome-icon :icon="['fal', 'clock']" class="iconClock" />
              </v-btn>
            </template>
            <v-time-picker
              class="font16"
              v-if="startTimeMenu"
              :disabled="!canEditEvent"
              v-model="timeStart"
              full-width
              format="24hr"
              @click:minute="$refs.startTimeMenu.save(timeStart)"
            ></v-time-picker>
          </v-menu>
          <v-text-field
            class="minutesInput"
            :class="{
              'ml-8': !isCreatingConference,
              'ml-0': isCreatingConference,
            }"
            outlined
            dense
            v-model="durationMin"
            type="number"
            min="0"
            step="1"
            :label="$t('components.eventEntryForm.minutes')"
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-title>
    <!-- END NEW HEADER -->
    <!-- add calendar here -->
    <!-- add event section -->
    <div
      :class="{ 'mr-4': !editMode, 'px-2': editMode }"
      style="width: 100%"
      v-if="checkImSupervisor"
    >
      <!-- name event -->
      <div class="mt-4">
        <v-row class="mx-0">
          <v-col cols="12" class="py-0 px-1">
            <v-text-field
              hide-details
              :disabled="!canEditEvent"
              v-model="eventName"
              dense
              outlined
              :label="$t('components.eventEntryForm.titleEvent')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0 px-1" v-if="1 === 3">
            <v-col cols="12" class="py-0 px-1">
              <v-checkbox
                v-model="protectCallComplex"
                class="mt-0"
                hide-details="true"
              >
                <template v-slot:label>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <p v-on="on" class="mb-0 labelTitle">
                        {{
                          $t("components.2faModal.btnEnablePINComplex")
                        }}&nbsp;{{ $t("generics.comingSoon") }}
                      </p>
                    </template>
                    <span>{{
                      $t("components.inviteGuestPanel.complexPinTT")
                    }}</span>
                  </v-tooltip>
                </template>
              </v-checkbox>
            </v-col>
            <v-row class="mx-0">
              <v-col cols="8" class="py-0 px-1" v-if="protectCallComplex">
                <v-text-field
                  style="margin-top: 15px"
                  autocomplete="off"
                  v-model="pinCode"
                  :label="
                    $t(
                      'components.inviteGuestPanel.enterComplexInvitationPinCode'
                    )
                  "
                  dense
                  @keydown.space.prevent
                  @blur="checkPin"
                  :maxlength="12"
                  :rules="[
                    (v) => !!v || '',
                    (v) => (v && v.length >= 12) || '',
                    (v) =>
                      complexPinRegex.test(v) ||
                      this.$t(
                        'components.inviteGuestPanel.ttComplexPinRequirements'
                      ),
                  ]"
                ></v-text-field>
              </v-col>
              <v-col class="col-1 px-1 py-0" v-if="protectCallComplex">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon class="my-3 btnPinDet">
                      <font-awesome-icon
                        :icon="['fal', 'question-circle']"
                        :style="{ fontSize: '15px' }"
                      />
                    </v-btn>
                  </template>
                  <span v-if="protectCallComplex">{{
                    $t("components.inviteGuestPanel.ttComplexPinRequirements")
                  }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                class="col-3 px-1 py-0"
                style="height: 30px; margin-top: 14px"
                v-if="protectCallComplex"
              >
                <v-checkbox
                  v-model="pinGenerated"
                  @change="generateComplxPin()"
                  class="mt-0"
                  hide-details="true"
                >
                  <template v-slot:label>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <p v-on="on" class="mb-0 labelTitle">
                          {{ $t("components.2fa.generatePin") }}
                        </p>
                      </template>
                      <span>{{ $t("components.2fa.generatePin") }}</span>
                    </v-tooltip>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="py-0 px-1">
            <!-- details event -->
            <v-textarea
              class="mt-3 textAreaDetails"
              :disabled="!canEditEvent"
              v-model="eventDetail"
              no-resize
              hide-details
              outlined
              :rows="this.detailsRow"
              :label="$t('components.eventEntryForm.detailEvents')"
            ></v-textarea>
          </v-col>
        </v-row>
        <!-- Inviter -->
        <v-row class="mx-0">
          <v-col cols="12" class="pb-0 px-1">
            <p
              style="margin: 0 auto; text-align: center; font-weight: bold"
              class="mb-1"
            >
              {{ $t("components.inviteVisitor.titleInvitationMenu") }}
            </p>
          </v-col>
        </v-row>
        <v-row class="mx-0 bgBlack">
          <v-col cols="3" class="px-1 px-1" v-if="uuidToRepresentStore"></v-col>
          <v-col cols="3" class="userColumn px-1 px-1">
            <v-checkbox
              class="mencheckbox departments cbNoteHead anfitrionCheckbox mt-0"
              :input-value="selectedAnfitrion === state.ownUUID"
              @click="setAnfitrion(state.ownUUID)"
            ></v-checkbox>
            <UserDisplay :userUUID="state.ownUUID" />
          </v-col>
          <v-col
            cols="3"
            class="userColumn px-1 px-1"
            v-if="uuidToRepresentStore"
          >
            <v-checkbox
              class="mencheckbox departments cbNoteHead anfitrionCheckbox mt-0"
              :input-value="selectedAnfitrion === actingAsUuid"
              @click="setAnfitrion(actingAsUuid)"
            ></v-checkbox>
            <UserDisplay :userUUID="actingAsUuid" />
          </v-col>
          <v-col cols="3" class="px-1 px-1" v-if="uuidToRepresentStore"></v-col>
        </v-row>
        <!-- end inviter -->
        <!-- participants -->
        <v-row class="mx-0">
          <v-col cols="12" class="pb-0 px-1 text-center">
            <p class="mb-1 text-center" style="font-weight: bold">
              {{ $t("components.eventEntryForm.participants") }}
            </p>
          </v-col>
          <v-col cols="3" class="py-1 px-1" @click="activeIndex = 0">
            <UserDisplay
              :isEditMode="true"
              :disabled="isDisabled0"
              :userUUID="
                allSelectedUsers.length > 0 && allSelectedUsers[0]
                  ? allSelectedUsers[0].uuid
                  : null
              "
              :newEvent="true"
              :uuid="allSelectedUsers[0] ? allSelectedUsers[0].uuid : null"
              :handlerShowUsers="handlerShowUsers"
              :userHasRejected="
                userHasRejectedEvent(
                  allSelectedUsers[0] ? allSelectedUsers[0].uuid : null
                )
              "
              :clearParticipant="clearParticipant"
              :activeIndex="0"
            />
            <div style="font-size: 14px"></div>
          </v-col>
          <v-col cols="3" class="py-1 px-1" @click="activeIndex = 1">
            <UserDisplay
              :isEditMode="true"
              :disabled="isDisabled1"
              :userUUID="
                allSelectedUsers.length > 0 && allSelectedUsers[1]
                  ? allSelectedUsers[1].uuid
                  : null
              "
              :newEvent="true"
              :uuid="allSelectedUsers[1] ? allSelectedUsers[1].uuid : null"
              :handlerShowUsers="handlerShowUsers"
              :userHasRejected="
                userHasRejectedEvent(
                  allSelectedUsers[1] ? allSelectedUsers[1].uuid : null
                )
              "
              :clearParticipant="clearParticipant"
              :activeIndex="1"
            />
            <div style="font-size: 14px"></div>
          </v-col>
          <v-col cols="3" class="py-1 px-1" @click="activeIndex = 2">
            <UserDisplay
              :isEditMode="true"
              :disabled="isDisabled2"
              :userUUID="
                allSelectedUsers.length > 0 && allSelectedUsers[2]
                  ? allSelectedUsers[2].uuid
                  : null
              "
              :newEvent="true"
              :uuid="allSelectedUsers[2] ? allSelectedUsers[2].uuid : null"
              :handlerShowUsers="handlerShowUsers"
              :userHasRejected="
                userHasRejectedEvent(
                  allSelectedUsers[2] ? allSelectedUsers[2].uuid : null
                )
              "
              :clearParticipant="clearParticipant"
              :activeIndex="2"
            />
            <div style="font-size: 14px"></div>
          </v-col>
          <v-col cols="3" class="py-1 px-1" @click="activeIndex = 3">
            <UserDisplay
              :isEditMode="true"
              :disabled="isDisabled3"
              :userUUID="
                allSelectedUsers.length > 0 && allSelectedUsers[3]
                  ? allSelectedUsers[3].uuid
                  : null
              "
              :newEvent="true"
              :uuid="allSelectedUsers[3] ? allSelectedUsers[3].uuid : null"
              :handlerShowUsers="handlerShowUsers"
              :userHasRejected="
                userHasRejectedEvent(
                  allSelectedUsers[3] ? allSelectedUsers[3].uuid : null
                )
              "
              :clearParticipant="clearParticipant"
              :activeIndex="3"
            />
            <div style="font-size: 14px"></div>
          </v-col>
        </v-row>
        <!-- end participant -->
        <!-- actions events -->
        <div v-if="!hideButtons" class="d-flex justify-end mt-3">
          <v-btn
            :disabled="!canDeleteEvent"
            class="mx-3"
            small
            color="error"
            @click="deleteEvent"
            >{{ $t("components.eventEntryForm.delete") }}</v-btn
          >
          <v-btn
            :disabled="!canEditEvent"
            class="mx-3"
            small
            color="error"
            @click="resetInputEvent"
            >{{ $t("components.eventEntryForm.reset") }}</v-btn
          >
          <v-btn
            :disabled="!canAddEvent"
            small
            color="primary"
            @click="saveEventData"
            >{{ $t("components.eventEntryForm.delete") }}</v-btn
          >
        </div>
      </div>
    </div>
    <v-dialog v-model="showUsers" persistent max-width="650">
      <v-tabs v-model="tabSelected">
        <v-img
          class="mx-2 logoModal top11"
          src="assets/icon.png"
          max-height="30"
          max-width="30"
          contain
        ></v-img>
        <v-tab key="users">
          {{ $t("components.inviteVisitor.participants") }}
        </v-tab>
        <v-tab key="invitees">
          {{ $t("components.inviteVisitor.participantsGuests") }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabSelected">
        <v-tab-item key="users">
          <v-card>
            <v-row class="mx-0">
              <v-col cols="12">
                <v-combobox
                  v-model="selectedUser"
                  :items="dataCombobox.users"
                  :search-input.sync="searchUsers"
                  :item-disabled="disableItem"
                  select
                  item-text="name"
                  item-value="uuid"
                  :label="$t('components.meetingLineModal.participants')"
                  dense
                  outlined
                  class="mt-3"
                  :disabled="!canEditEvent"
                  @change="handleChangeCombo"
                  hide-details
                  autofocus
                ></v-combobox>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item key="invitees">
          <v-card>
            <v-row class="mx-0">
              <v-col cols="12">
                <DirectCallMask
                  ref="directCallMaskRef"
                  :disableItem="disableItem"
                  :allSelectedUsers="allSelectedUsers"
                  :linkDateFrom="formattedStartDate"
                  :linkTimeFrom="timeStart"
                  :linkDateTo="formattedStartDate"
                  :linkTimeTo="timeEnd"
                  :durationMin="durationMin"
                  :subject="eventName"
                  :setNoticeText="setNoticeText"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-card color="primary" class="py-2 pl-4 pr-2 footerModal">
        <v-row class="px-2 btns w-100 mx-0 my-0">
          <template>
            <v-tooltip top v-if="tabSelected == 1 && directCallMaskRef">
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mr-2"
                  v-on="on"
                  @click="precheckNewVisitor"
                  :loading="directCallMaskRef.isProcessing"
                  :disabled="!disabledSaveInviteesButton"
                  >{{ $t("components.sendMessageModal.send") }}</v-btn
                >
              </template>
              <span>{{ $t("components.sendMessageModal.send") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="white" @click="cancel()">
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    :style="{ fontSize: '30px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("generics.close") }}</span>
            </v-tooltip>
          </template>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
    </v-dialog>
  </v-card>
</template>

<script>
import store, { EventBus } from "../../store";
import MD5 from "../../lib/md5";
import moment from "../../../sharedsrc/moment";
import { parallel } from "../../lib/asyncUtil";
import UserDisplay from "../userDisplay/userDisplay.vue";
import OneCallView from "../meetingLine/eventViews/oneCallView.vue";
import { v4 as uuidv4 } from "uuid";
import {
  updateTimelineItemEvent,
  deleteTimelineItemEvent,
  createTimelineEntryEffect,
} from "../../effector/timeline";
import {
  prepadeDataToSendMessageEffector,
  newMessageEvent,
} from "../../effector/message";
import {
  setConferenceForRepresentiveEffect,
  removeConferenceForRepresentiveEffect,
  uuidToRepresentStore,
  actingAsUuidStore,
} from "../../effector/representative";
import { resetEventStateModalEvent } from "../../effector/modals";
import {
  getTimelineEntryByUUID,
  sendInviteMailCustom,
  setRemoteUser,
} from "../../lib/wsMsg";
import DirectCallMask from "./directCallMask.vue";
export default {
  props: ["existingEvent", "hideButtons", "setBackground", "editMode"],
  components: {
    UserDisplay,
    OneCallView,
    DirectCallMask,
  },
  effector: {
    uuidToRepresentStore: uuidToRepresentStore,
    actingAsUuid: actingAsUuidStore,
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      detailsRow: 3,
      directCallMaskRef: null,
      tabSelected: 0,
      startDateMenu: false,
      startTimeMenu: false,
      timeStart: null,
      selectedAnfitrion: actingAsUuidStore.getState(),
      showUsers: false,
      complexPinRegex:
        /^(?=.*[0-9])(?=.*[a-zñöäü])(?=.*[A-ZÖÄÜÑ])(?=.*[*.!@$%^&(){}[\]\/:;<>,.?~_\+\-\=|]).{12,}$/,
      state: store.state,
      searchUsers: null,
      isConference: false,
      activeIndex: 0,
      disabled: true,
      formattedStartDate: "",
      formattedEndDate: "",
      type: "month",
      types: ["month", "week", "day"],
      mode: "column",
      value: "",
      colors: [
        {
          name: this.$t("components.calendarContainer.colors.blue"),
          value: "blue",
        },
        {
          name: this.$t("components.calendarContainer.colors.indigo"),
          value: "indigo",
        },
        {
          name: this.$t("components.calendarContainer.colors.deepPurple"),
          value: "deep-purple",
        },
        {
          name: this.$t("components.calendarContainer.colors.cyan"),
          value: "cyan",
        },
        {
          name: this.$t("components.calendarContainer.colors.green"),
          value: "green",
        },
        {
          name: this.$t("components.calendarContainer.colors.orange"),
          value: "orange",
        },
        {
          name: this.$t("components.calendarContainer.colors.greyDarken"),
          value: "grey darken-1",
        },
      ],
      colorSelected: {
        name: this.$t("components.calendarContainer.colors.orange"),
        value: "orange",
      },
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      startMenu: false,
      startEvent: "",
      endEvent: "",
      timeEnd: null,
      durationMin:
        Math.floor(
          store.state.namespaceSettings.defaultMeetingDuration.value / 1000 / 60
        ) || 0,
      endMenu: false,
      endTimeMenu: false,
      eventName: "",
      eventDetail: "",
      allSelectedUsers: [],
      selectedUser: null,
      rules: [
        (value) => !!value || "Required.",
        (value) => (value || "").length <= 4 || "Max 4 users",
        (value) => !value.find((e) => typeof e !== "object"),
      ],
      typeEvent: "videoCall",
      rsvp: true,
      protectCallComplex: false,
      pinCode: "",
      pinGenerated: false,
    };
  },
  mounted: function () {
    this.isConference = false;
    this.populateFromShowModalProp();
    EventBus.$on("setReference", this.setReference);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    if (window.innerHeight > 845) {
      this.detailsRow = 3;
    } else {
      this.detailsRow = 1;
    }
  },
  destroyed(){
    EventBus.$off("setReference", this.setReference);
    EventBus.$off("setActingUUID", this.setActingUUID);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    EventBus.$on("setActingUUID", this.setActingUUID);
  },
  watch: {
    windowHeight(newHeight) {
      if (newHeight > 845) {
        this.detailsRow = 3;
      } else {
        this.detailsRow = 1;
      }
    },
    durationMin() {
      setImmediate(() => {
        if (typeof this.durationMin !== "number")
          this.durationMin = parseInt(this.durationMin, 10);
        else if (this.durationMin > 120) {
          this.durationMin = 120;
        } else if (this.durationMin < 0) {
          this.durationMin = 0;
        } else {
          this.recalcEnd();
        }
      });
    },
    selectedUser() {
      setImmediate(() => {
        const filtered = this.allSelectedUsers.filter(
          (e) => typeof e === "object"
        );
        if (filtered.length !== this.allSelectedUsers.length) {
          this.allSelectedUsers = filtered;
        }
      });
    },
    existingEvent() {
      this.populateFromShowModalProp();
    },
    startEvent() {
      this.recalcEnd();
      this.startChanged();
    },
    timeStart() {
      this.recalcEnd();
      this.startChanged();
    },
    endEvent() {
      this.endChanged();
    },
    timeEnd() {
      this.endChanged();
    },
  },
  methods: {
    cancel() {
      this.showUsers = false;
      if (this.directCallMaskRef) this.directCallMaskRef.cleanForm();
    },
    setNoticeText(value) {
      this.noticeText = value;
    },
    generateComplxPin() {
      if (this.pinGenerated) {
        this.pinCode = this.randomPassword(12, 1, 1, 1, 1);
      } else {
        this.pinCode = "";
      }
    },
    randomPassword(
      len = 8,
      minUpper = 0,
      minLower = 0,
      minNumber = -1,
      minSpecial = -1
    ) {
      let chars =
          "!.$%&*+-0123456789?@ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz",
        A2Z = String.fromCharCode(...Array(91).keys()).slice(65), //A-Z
        a2z = String.fromCharCode(...Array(123).keys()).slice(97), //a-z
        zero2nine = String.fromCharCode(...Array(58).keys()).slice(48), //0-9
        specials = chars.replace(/\w/g, "");
      if (minSpecial < 0) chars = zero2nine + A2Z + a2z;
      if (minNumber < 0) chars = chars.replace(zero2nine, "");
      let minRequired = minSpecial + minUpper + minLower + minNumber;
      let rs = [].concat(
        Array.from(
          { length: minSpecial ? minSpecial : 0 },
          () => specials[Math.floor(Math.random() * specials.length)]
        ),
        Array.from(
          { length: minUpper ? minUpper : 0 },
          () => A2Z[Math.floor(Math.random() * A2Z.length)]
        ),
        Array.from(
          { length: minLower ? minLower : 0 },
          () => a2z[Math.floor(Math.random() * a2z.length)]
        ),
        Array.from(
          { length: minNumber ? minNumber : 0 },
          () => zero2nine[Math.floor(Math.random() * zero2nine.length)]
        ),
        Array.from(
          {
            length:
              Math.max(len, minRequired) - (minRequired ? minRequired : 0),
          },
          () => chars[Math.floor(Math.random() * chars.length)]
        )
      );
      return rs.sort(() => Math.random() > Math.random()).join(""); // eslint-disable-line no-self-compare
    },
    checkPin() {
      if (this.protectCallComplex) {
        return this.complexPinRegex.test(this.pinCode);
      } else {
        return true;
      }
    },
    userHasRejectedEvent(userUUID) {
      if (
        !this.existingEvent ||
        !this.existingEvent.rsvp ||
        userUUID === null
      ) {
        return false;
      } else {
        if (!this.existingEvent.rsvp[userUUID]) return false;
        if (this.existingEvent.rsvp[userUUID].status === "rejected")
          return true;
        return false;
      }
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    setActingUUID() {
      this.selectedAnfitrion = actingAsUuidStore.getState();
    },
    setAnfitrion(uuid) {
      this.selectedAnfitrion = uuid;
    },
    async precheckNewVisitor() {
      const visitorIdsArray = await this.directCallMaskRef.precheckNewVisitor();
      visitorIdsArray.forEach((visitorId) => {
        const tmpObj = {
          uuid: visitorId,
          name: store.getNameForUuid(visitorId),
        };
        if (
          this.allSelectedUsers.find((x) => x.uuid === visitorId) === undefined
        ) {
          this.handleChangeCombo(tmpObj);
        }
      });
    },
    setReference() {
      this.directCallMaskRef = this.$refs.directCallMaskRef;
    },
    clearParticipant(activeIndex) {
      if ((this.allSelectedUsers[activeIndex] || {}).uuid) {
        this.allSelectedUsers.splice(activeIndex, 1);
        this.searchUsers = ""; // claear search field
        this.activeIndex = null;
      }
    },
    handleChangeCombo(value) {
      if (!value || !value.uuid) {
        this.searchUsers = "";
        return;
      }
      if ((this.allSelectedUsers[this.activeIndex] || {}).uuid) {
        // editing box remove existent
        this.allSelectedUsers[this.activeIndex].name = value.name;
        this.allSelectedUsers[this.activeIndex].uuid = value.uuid;
      } else {
        if (
          this.allSelectedUsers.find((x) => x.uuid === value.uuid) === undefined
        ) {
          this.allSelectedUsers.push(value);
        }
      }
      this.searchUsers = ""; // claear search field
      this.handlerShowUsers(null); // close popup
      this.activeIndex = null;
    },
    handlerShowUsers(uuid) {
      if (!this.showUsers) {
        if (uuid !== null) {
          this.selectedUser = {
            value: uuid,
            name: store.getNameForUuid(uuid),
          };
        } else {
          this.selectedUser = null;
        }
        setTimeout(() => {
          this.showUsers = true;
          this.searchUsers = "";
        }, 250);
      } else {
        this.showUsers = false;
        this.searchUsers = "";
        this.selectedUser = null;
      }
    },
    startChanged() {
      if (!this.startEvent || !this.timeStart) return;
      this.formattedStartDate = this.format_Date(this.startEvent);
      this.startMenu = false;

      const dateTimeStart = this.startEvent + " " + this.timeStart;
      const dateTimeEnd = this.endEvent + " " + this.timeEnd;
      this.updateDurationMin(dateTimeStart, dateTimeEnd);
    },
    endChanged() {
      if (!this.startEvent || !this.timeStart) return;
      if (!this.endEvent || !this.timeEnd) return;
      this.formattedEndDate = this.format_Date(this.endEvent);
      this.endMenu = false;
      const dateTimeStart = this.startEvent + " " + this.timeStart;
      const dateTimeEnd = this.endEvent + " " + this.timeEnd;
      this.updateDurationMin(dateTimeStart, dateTimeEnd);
    },
    recalcEnd() {
      if (!this.startEvent || !this.timeStart) return;
      const start = new Date(this.startEvent + " " + this.timeStart);
      const end = new Date(start.getTime() + this.durationMin * 60000);
      const endDate = `${end.getFullYear()}-${
        end.getMonth() + 1
      }-${end.getDate()}`.replace(/-(\d)(?=(?:-|$))/g, "-0$1");
      const endTime = `${
        end.getHours() < 10 ? "0" + end.getHours() : end.getHours()
      }:${end.getMinutes() < 10 ? "0" + end.getMinutes() : end.getMinutes()}`;
      this.endEvent = endDate;
      this.timeEnd = endTime;
    },
    format_Date(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    populateFromShowModalProp(e) {
      if (this.existingEvent) {
        this.durationMin =
          Math.floor(
            store.state.namespaceSettings.defaultMeetingDuration.value /
              1000 /
              60
          ) || 0;
        if (this.existingEvent.name) this.eventName = this.existingEvent.name;
        if (this.existingEvent.details)
          this.eventDetail = this.existingEvent.details;
        if (this.existingEvent.users)
          this.allSelectedUsers = (this.existingEvent.users || []).map(
            (uuid) => {
              return { uuid, name: store.getNameForUuid(uuid) };
            }
          );
        if (this.existingEvent.color)
          this.colorSelected.value = this.existingEvent.color;
        if (this.existingEvent.rsvp) this.rsvp = true;
        if (this.existingEvent.start) {
          const start = new Date(this.existingEvent.start);
          const startDate = `${start.getFullYear()}-${
            start.getMonth() + 1
          }-${start.getDate()}`;
          const startTime = `${
            start.getHours() < 10 ? "0" + start.getHours() : start.getHours()
          }:${
            start.getMinutes() < 10
              ? "0" + start.getMinutes()
              : start.getMinutes()
          }`;
          this.startEvent = startDate;
          this.timeStart = startTime;
        }
        if (this.existingEvent.end) {
          const end = new Date(this.existingEvent.end);
          const endDate = `${end.getFullYear()}-${
            end.getMonth() + 1
          }-${end.getDate()}`.replace(/-(\d)(?=(?:-|$))/g, "-0$1");
          const endTime = `${
            end.getHours() < 10 ? "0" + end.getHours() : end.getHours()
          }:${
            end.getMinutes() < 10 ? "0" + end.getMinutes() : end.getMinutes()
          }`;
          this.endEvent = endDate;
          this.timeEnd = endTime;
        }
        if (this.existingEvent.start && this.existingEvent.end) {
          this.updateDurationMin(
            this.existingEvent.start,
            this.existingEvent.end
          );
        }
        if (this.existingEvent && this.existingEvent.isConference) {
          this.isConference = true;
        } else {
          this.isConference =
            this.existingEvent.groupId !== null &&
            this.existingEvent.groupId !== undefined;
        }

        if (this.existingEvent.host)
          this.selectedAnfitrion = this.existingEvent.host;
      } else {
        this.resetInputEvent();
      }
    },
    updateDurationMin(givenStart, givenEnd) {
      if (!givenStart || !givenEnd) return;
      const start = new Date(givenStart).getTime();
      const end = new Date(givenEnd).getTime();
      const diffMs = end - start;
      const diffMins = Math.floor(diffMs / 60000);
      this.durationMin = diffMins;
    },
    async updateGroup(groupId) {
      let currentGroup;
      if (this.actingAsUuid && this.selectedAnfitrion) {
        currentGroup = await store.state.group[
          this.selectedAnfitrion
        ].user.userGroups.find((e) => e.id === groupId);
      } else {
        currentGroup = await store.state.user.userGroups.find(
          (e) => e.id === groupId
        );
      }
      if (currentGroup) {
        currentGroup.newGroupName = this.eventDetail.trim();
        currentGroup.members = this.allSelectedUsers;
        this.saveData(groupId);
      }
    },
    async saveAsConference(eventID, GroupId) {
      if (GroupId && GroupId != null) {
        this.updateGroup(GroupId, eventID);
      } else {
        this.createGroup();
      }
    },
    async createGroup() {
      const dateTimeStart = this.startEvent + " " + this.timeStart;
      let newGroup = {};
      newGroup.id = uuidv4();
      newGroup.newGroupName = this.eventName.trim();
      newGroup.pinProtected = this.protectCallComplex;
      if (this.protectCallComplex && this.pinCode.trim().length >= 12) {
        newGroup.pinCode = MD5(this.pinCode);
      } else {
        newGroup.pinCode = "";
      }
      newGroup.members = this.allSelectedUsers;
      newGroup.owner =
        ((store.state.group[this.selectedAnfitrion] || {}).user || {}).name ||
        store.state.user.name;
      if (dateTimeStart && this.durationMin) {
        newGroup.dateTimeStart = dateTimeStart;
        newGroup.durationMin = this.durationMin;
        const resp = await this.saveData(newGroup.id);
        if (resp) {
          newGroup.timelineConference = resp;
        }
      }
      if (this.actingAsUuid && this.selectedAnfitrion !== store.state.ownUUID) {
        const tmpData = {
          uuid: this.selectedAnfitrion,
          group: newGroup,
        };
        setConferenceForRepresentiveEffect(tmpData);
      } else {
        store.state.user.userGroups.push(newGroup);
      }
    },
    async saveEventData() {
      let groupId = null;
      if (this.existingEvent && this.isConference) {
        if (this.existingEvent.groupId) {
          groupId = this.existingEvent.groupId;
        } else {
          groupId = this.getGroupId;
        }
        this.saveAsConference(this.existingEvent.uuid, groupId);
      } else if (!this.existingEvent && this.isConference) {
        this.saveAsConference(null, null);
      } else if (!this.isConference) {
      }
    },
    async saveData(groupId = null) {
      let meetingId = null;
      if (!this.canAddEvent) throw new Error("Not allowed");
      const dateTimeStart = this.startEvent + " " + this.timeStart;
      const dateTimeEnd = this.endEvent + " " + this.timeEnd;
      const tmpData = {
        name: this.eventName,
        details: this.eventDetail,
        users: this.allSelectedUsers
          .filter((e) => typeof e === "object")
          .map((user) => user.uuid),
        start: new Date(dateTimeStart),
        end: new Date(dateTimeEnd),
        color: this.isConference ? "orange" : this.colorSelected.value,
        type: this.typeEvent,
        groupId: groupId,
        host: this.selectedAnfitrion,
        eventType: "directCall",
      };
      if (this.existingEvent && this.existingEvent.uuid) {
        const extantEvent = await getTimelineEntryByUUID(
          this.existingEvent.uuid
        );
        if (!this.rsvp && this.existingEvent.rsvp) tmpData.rsvp = false;
        meetingId = this.existingEvent.uuid;
        const mssg = this.$t("components.notificationsModal.notificationText", [
          moment(tmpData.start).format("DD.MM.YYYY HH:mm"),
        ]);
        tmpData.uuid = meetingId;
        tmpData.creatorUUID = this.existingEvent.creatorUUID;
        updateTimelineItemEvent([this.existingEvent.uuid, tmpData, mssg]);
        // mail new users
        if (extantEvent && extantEvent.uuid) {
          const newUsers = tmpData.users.filter(
            (e) => extantEvent.users.indexOf(e) === -1
          );
          const removedUsers = extantEvent.users.filter(
            (e) => tmpData.users.indexOf(e) === -1
          );
          if (newUsers && newUsers.length > 0) {
            const newVisitors = newUsers.filter(
              (e) => store.state.group[e].user.visitorData
            );
            if (newVisitors && newVisitors.length > 0) {
              this.directCallMaskRef.handleUsersInvite(
                newVisitors,
                this.existingEvent
              );
            }
          }
          if (removedUsers && removedUsers.length > 0) {
            const removedVisitors = removedUsers.filter(
              (e) => store.state.group[e].user.visitorData
            );
            if (removedVisitors && removedVisitors.length > 0) {
              // send email on cancelled
              this.sendMailToCancelled(removedVisitors, this.existingEvent);
            }
          }
        }
      } else {
        if (this.rsvp) {
          tmpData.rsvp = {};
          tmpData.viewed = {};
          tmpData.users.forEach((element) => {
            tmpData.viewed[element] = {
              status: "false",
              lastChange: Date.now(),
            };
            tmpData.rsvp[element] = {
              status: "pending",
              lastChange: Date.now(),
            };
          });
        }
        const meeting = await createTimelineEntryEffect(tmpData);
        meetingId = meeting.uuid;
        // send visitors email and message on creation
        const visitorUsers = tmpData.users.filter(
          (e) => store.state.group[e].user.visitorData
        );
        const realUsers = tmpData.users.filter(
          (e) => !store.state.group[e].user.visitorData
        );
        if (realUsers && realUsers.length > 0) {
          let tmpUsers = [];
          realUsers.forEach(async (user) => {
            tmpUsers.push(user);
            if (
              store.state.group[user].user.userSettings.showVideoCallPlanner ==
              false
            ) {
              await setRemoteUser(user, {
                userSettings: {
                  /* ...store.state.group[user].user.userSettings, */ showVideoCallPlanner: true,
                },
              });
            }
          });
          if (tmpUsers.length > 0) {
            const startD = moment(this.existingEvent.start).format(
              "DD.MM.YYYY"
            );
            const startT = moment(this.existingEvent.start).format("HH:mm");
            const duration = 15;
            const notificationMssg = this.$t(
              "components.notificationsModal.newConference",
              [startD, startT, duration]
            );
            if (tmpUsers && tmpUsers.length) {
              tmpUsers = tmpUsers.filter(
                (uuid) => uuid !== store.state.ownUUID
              );
            }
            const dataMsg = prepadeDataToSendMessageEffector(
              tmpUsers,
              notificationMssg,
              "planner event",
              "plannerEvent"
            );
            newMessageEvent(dataMsg);
          }
        }
        if (visitorUsers && visitorUsers.length > 0) {
          this.directCallMaskRef.handleUsersInvite(visitorUsers, meeting);
        }
      }
      this.resetInputEvent();
      resetEventStateModalEvent();
      return meetingId;
    },
    async sendMailToCancelled(removedVisitors, event) {
      await parallel(1, [...removedVisitors], async (uuid) => {
        if (store.state.ownUUID === uuid) return;
        const language = (store.state.group[uuid].user || {}).language || "en";
        const email = (store.state.group[uuid].user || {}).email || "";
        if (email && email.indexOf("@") !== -1) {
          const textToSend =
            this.$t("components.conferenceForm.conferenceHasBeenCanceled") +
            " " +
            this.$t("components.conferenceForm.youWillBeNotified");
          await sendInviteMailCustom(uuid, {
            guest:
              ((store.state.group[uuid] || {}).user || {}).permissions === 10,
            name: ((store.state.group[uuid] || {}).user || {}).name,
            email,
            subject: this.$t("components.conferenceForm.subjectCanceledEmail", [
              event.name,
            ]),
            language,
            customMessage: textToSend,
            conferenceNotification: true,
            noInviteLink: true,
          });
        }
      });
    },
    async deleteEvent() {
      if (!this.canDeleteEvent) throw new Error("Not allowed");
      if (this.existingEvent && this.existingEvent.uuid) {
        let allGroups;
        if (
          this.actingAsUuid &&
          this.selectedAnfitrion !== store.state.ownUUID
        ) {
          allGroups = await store.state.group[this.selectedAnfitrion].user
            .userGroups;
        } else {
          allGroups = await store.state.user.userGroups;
        }
        deleteTimelineItemEvent(this.existingEvent && this.existingEvent.uuid);
        for (let i = 0; i < allGroups.length; i++) {
          const group = allGroups[i];
          if (group.id === this.existingEvent.groupId) {
            if (
              this.actingAsUuid &&
              this.selectedAnfitrion !== store.state.ownUUID
            ) {
              const tmpObj = {
                uuid: this.selectedAnfitrion,
                index: i,
              };
              removeConferenceForRepresentiveEffect(tmpObj);
            } else {
              let targetidx = store.state.user.userGroups.findIndex(
                (x) => x.id === this.existingEvent.id
              );
              if (targetidx !== undefined) {
                store.state.user.userGroups.splice(targetidx, 1);
              }
              allGroups.splice(i, 1);
            }
            break;
          }
        }
        this.showUsers = false;
        this.resetInputEvent();
        resetEventStateModalEvent();
      }
    },
    resetInputEvent() {
      if (this.existingEvent && this.existingEvent.isConference) {
        this.isConference = true;
        this.startEvent = null;
      } else {
        this.isConference = false;
      }
      this.startTimeMenu = false;
      this.pinGenerated = false;
      this.protectCallComplex = false;
      this.pinCode = "";
      this.timeStart = null;
      this.endEvent = "";
      this.endMenu = false;
      this.endTimeMenu = false;
      this.timeEnd = null;
      this.colorSelected = {
        name: this.$t("components.calendarContainer.colors.orange"),
        value: "orange",
      };
      this.eventName = "";
      this.eventDetail = "";
      this.allSelectedUsers = [];
      this.selectedUser = null;
      this.rsvp = true;
      this.durationMin =
        Math.floor(
          store.state.namespaceSettings.defaultMeetingDuration.value / 1000 / 60
        ) || 0;
      this.selectedAnfitrion = this.actingAsUuid;
    },
    disableItem(item) {
      return (
        this.allSelectedUsers.find((e) => e.uuid === item.uuid) != undefined
      );
    },
  },
  computed: {
    disabledSaveInviteesButton() {
      let result = false;
      if (
        this.directCallMaskRef.selectedUserName &&
        this.directCallMaskRef.selectedUserName.trim().length > 0
      ) {
        if (
          this.directCallMaskRef.selectedEmail &&
          this.directCallMaskRef.selectedEmail.length > 1 &&
          this.directCallMaskRef.validateEmail
        ) {
          result = true;
        } else if (
          this.directCallMaskRef.phoneNumberParsed &&
          this.directCallMaskRef.phoneNumberParsed.length > 1 &&
          this.directCallMaskRef.phoneInputValid
        ) {
          result = true;
        }
      }
      return result;
    },
    isCreatingConference() {
      if (!this.existingEvent) return false;
      if (this.existingEvent && this.existingEvent.isConference) {
        return true;
      }
    },
    formatedStart() {
      if (!this.startEvent) {
        return null;
      }
      return moment(new Date(this.startEvent)).format("DD.MM.YYYY");
    },
    getGroupId() {
      const conf =
        store.state.user.userGroups.find(
          (g) => g.timelineConference === this.existingEvent.uuid
        ) || null;
      if (conf !== null) {
        return conf.timelineConference;
      } else {
        return null;
      }
    },
    isDisabled0() {
      return !this.startEvent || !this.timeStart || !this.eventName;
    },
    isDisabled1() {
      return this.allSelectedUsers.length < 1;
    },
    isDisabled2() {
      return this.allSelectedUsers.length < 2;
    },
    isDisabled3() {
      return this.allSelectedUsers.length < 3;
    },
    getStartDate() {
      if (this.existingEvent && this.existingEvent.isConference) {
        return false;
      } else {
        return moment(new Date(this.startEvent)).format("DD.MM.YYYY");
      }
    },
    dataCombobox() {
      const result = { users: [], guests: [] };
      const users = this.state.group || {};
      for (const key in users) {
        const data = { name: (users[key].user || {}).name || "", uuid: key };
        if (data.uuid !== this.actingAsUuid && data.name !== "") {
          if (this.allSelectedUsers.find((e) => e.value === data.uuid))
            continue;
          if (!users[key].user.visitor) {
            result.users.push(data);
          }
          if (
            users[key].user.visitor &&
            ((users[key].user || {}).name || "") != "" &&
            (((users[key].user || {}).visitorData || {}).userInviter || 0) ===
              store.state.ownUUID
          ) {
            result.guests.push(data);
          }
        }
      }
      result.users.sort((a, b) => a.name.localeCompare(b.name));
      result.guests.sort((a, b) => a.name.localeCompare(b.name));
      return result;
    },
    canAddEvent() {
      if (this.protectCallComplex) {
        if (!this.checkPin()) {
          return false;
        }
      }
      return (
        this.allSelectedUsers.length > 0 &&
        this.startEvent !== "" &&
        this.timeStart &&
        this.endEvent !== "" &&
        this.timeEnd &&
        this.canEditEvent &&
        this.durationMin &&
        typeof this.durationMin === "number"
      );
    },
    isExistingEvent() {
      return this.existingEvent && this.existingEvent.uuid;
    },
    canEditEvent() {
      let result = false;
      if (!this.isEditMode && store.state.user.permissions !== 10) {
        result = true;
      }
      if (
        this.isExistingEvent &&
        this.existingEvent.creatorUUID === store.state.ownUUID
      ) {
        result = true;
        if (
          this.existingEvent.creatorUUID !== this.actingAsUuid ||
          this.existingEvent.creatorUUID !== store.state.ownUUID
        ) {
          result = false;
        }
      } else if (
        this.isExistingEvent &&
        this.existingEvent.creatorUUID !== store.state.ownUUID
      ) {
        result = false;
      }
      return result;
    },
    checkImSupervisor() {
      return true;
    },
    canDeleteEvent() {
      return this.canEditEvent && this.isExistingEvent;
    },
  },
};
</script>

<style lang="scss">
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95% !important;
}
.checkboxRSVP {
  & .v-input__slot {
    margin-bottom: 0px !important;
    margin-top: 2px !important;
  }
}
.minutesInput {
  label,
  fieldset,
  input {
    color: white !important;
  }
  input {
    text-align: center;
  }
}
.w154 {
  label,
  fieldset,
  input {
    color: white !important;
  }
  input {
    text-align: center;
  }
}
</style>
<style scoped lang="scss">
.largeTitle {
  word-break: break-word;
}
.top11 {
  top: 11px !important;
}
.logoModal {
  display: inline-block;
  top: 6px;
}
.userColumn {
  position: relative;
  margin: 0 auto;
}
.toTheRight {
  justify-content: flex-end;
}
.bgBlack {
  background-color: black;
}
.w154 {
  width: 154px;
  display: inline-block;
}
.buttonMinutes {
  background: transparent !important;
  color: white !important;
  border: solid 1px white;
  box-shadow: none !important;
  padding: 0 !important;
  height: 40px;
  text-transform: none;
  width: 149px;
}
.iconClock {
  font-size: 15px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5;
}
.minutesInput {
  width: 31%;
  display: inline-block;
}
.font13 {
  font-size: 15px;
}
.font16 {
  font-size: 16px;
}
.eventFromCard {
  box-shadow: none;
  width: 100%;
}
.anfitrionCheckbox {
  position: absolute;
  color: white;
  top: 11px;
  left: 5px;
  z-index: 9999;
}
</style>