<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      v-if="!companyRegisterObject.isRamicroCustomer"
    >
      <v-row>
        <v-col :cols="6" class="py-0">
          <v-autocomplete
            v-model="countryDropDown"
            :items="countriesArray"
            :label="$t('components.companyRegister.state')"
            autocomplete="very_false"
            required
            return-object
            item-text="name"
            item-value="value"
            outlined
            @change="setCountry"
            dense
            :rules="stateRules"
            :hide-details="isGermanLawyer"
            ref="countryDropdown"
            @mousedown="clearDropDown()"
          >
            <template slot="item" slot-scope="data">
              <img
                :src="`/img/icons/flags/${data.item.value.toLowerCase()}.png`"
                class="countryFlag"
              />
              <span>
                {{ data.item.name }}
              </span>
            </template>

            <template slot="selection" slot-scope="data">
              <img
                :src="`/img/icons/flags/${data.item.value.toLowerCase()}.png`"
                class="countryFlag"
              />
              <span>
                {{ data.item.name }}
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-autocomplete
            :hide-details="isGermanLawyer"
            v-model="companyRegisterObject.branchSelected"
            :items="branchesArray"
            :filter="customFilter"
            :label="`${$t('components.companyRegister.branch')}*`"
            autocomplete="very_false"
            required
            :return-object="true"
            @change="setBranch"
            :rules="branchRules"
            outlined
            dense
          >
            <template #item="data">
              <span class="spanDropdownSelection"> {{ data.item.text }}</span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <LawyerCompanyRegister v-if="isGermanLawyer && namespaceIsFree" />
      <CommonCompanyRegister
        ref="CommonCompanyRegister"
        :isGermanLawyer="isGermanLawyer"
        :isPackagedVersion="isPackagedVersion"
        :countryCodeInput="countryCodeInput"
        v-if="namespaceIsFree"
      />
        <!-- :checkVatNumber="checkVatNumber"
        :isEuropeanContries="isEuropeanContries"
        :vatNumberValid="vatNumberValid"
        :vatNumberLoading="vatNumberLoading" -->
      <v-row class="right" v-if="!namespaceIsFree">
        <v-col
          cols="12"
          :class="{ 'pb-0 pt-4': isGermanLawyer, 'py-0': !isGermanLawyer }"
        >
          <v-text-field
            v-model="newNamespace"
            :label="$t('components.companyRegister.labelnewNamespace')"
            :rules="namespaceRules"
            required
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" class="py-0 text-right">
          <v-btn
            color="primary"
            @click="
              checkIfNeedRedirect(
                companyRegisterObject.branchSelected,
                newNamespace
              )
            "
            :disabled="newNamespace == '' || newNamespace.trim().length == 0"
          >
            {{ $t("components.companyRegister.checkSubdomain") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="right" v-if="namespaceIsFree">
        <v-col cols="12" class="py-0">
          <v-checkbox
            v-model="terms"
            class="mt-0 checkBoxTerms"
            required
            :rules="acceptRules"
          >
            <template v-slot:label
              ><span
                @click.stop
                style="color: rgba(0, 0, 0, 0.6) !important"
                v-html="$t('components.takeAction.conditionsAccept')"
              ></span
            ></template>
          </v-checkbox>
        </v-col>
        <!-- <v-col cols="12" class="py-0" v-if="isGermanLawyer">
            <div>
              <v-checkbox
              v-model="termsRAMicro"
              class="mt-0 raMicroCheckBox"
              required
              :rules="acceptRules"
            >
              <template v-slot:label class="raMicroCheckBoxLabel">
                <div v-html="$t('components.raMicroCheckbox.line1')"></div>
              </template>
            </v-checkbox>
            </div>
            <div class="otherTerms">
                <div v-html="$t('components.raMicroCheckbox.line2')"></div>
                <div v-html="$t('components.raMicroCheckbox.line3')"></div>
                <div v-html="$t('components.raMicroCheckbox.line4')"></div>
                <div v-html="$t('components.raMicroCheckbox.line5')"></div>
            </div>
          </v-col> -->
      </v-row>
      <v-row class="right" v-if="namespaceIsFree">
        <v-col cols="12" class="py-0 text-right">
          <v-btn
            depressed
            class="mr-4"
            color="primary"
            @click="validate()"
            :disabled="
              !terms ||
              !companyPhoneValid ||
              (isGermanLawyer && !termsRAMicro && !terms) ||
              !isFormPreValid 
            "
          >
              <!-- (!isPackagedVersion && isEuropeanContries && !vatNumberValid) -->
            {{
              isGermanLawyer || isPackagedVersion
                ? $t("generics.startNow")
                : $t("components.companyRegister.buttonFreeTrial")
            }}
          </v-btn>

          <v-btn
            depressed
            v-if="!(isGermanLawyer || isPackagedVersion)"
            color="primary"
            @click="showPayPalScreen()"
            :disabled="
              !terms ||
              !companyPhoneValid ||
              (isGermanLawyer && !termsRAMicro && !terms) ||
              !isFormPreValid
            "
          >
              <!-- (!isPackagedVersion && isEuropeanContries && !vatNumberValid) -->
            {{ $t("components.companyRegister.buttonPayPal") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div
      class="divInfoUser text-center"
      v-if="companyRegisterObject.isRamicroCustomer"
    >
      Lieber RA-MICRO Kunde.
      <br />
      Sie verwenden die RA-MICRO Kanzleisoftware. Bitte aktivieren Sie Ihr
      kostenloses vOffice direkt aus Ihrer Kanzleisoftware heraus.
      <br />
      <br />
      <img src="/img/vOffice-ra-micro.png" class="imgRaMicro" />
      <div class="buttonBackRACustomer">
        <v-btn
          depressed
          outlined
          class=""
          color="primary"
          @click="companyRegisterObject.isRamicroCustomer = false"
          >{{ $t("tour.buttonPrevious") }}</v-btn
        >
      </div>
    </div>
    <ModalConditions
      :showModalConditions="showModalConditions"
      :closeModalConditions="closeModalConditions"
    />
  </div>
</template>
<script>
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import Vue from "vue";
import store, { EventBus } from "../../store";
import {
  // dispatchSuccessAlert,
  dispatchErrorAlert,
} from "../../effector/alerts";
// import countries from "../../countries.json";

import countriesDE from "../../countries_de.json";
import countriesEN from "../../countries_en.json";
import countriesES from "../../countries_es.json";

//import branches from "../../branches.json";
import CommonCompanyRegister from "./commonCompanyRegister.vue";
import LawyerCompanyRegister from "./lawyerCompanyRegister.vue";
import VueTelInput from "vue-tel-input";
import {
  /* checkRmoStoreByClientId, registerRmoCustomer, registerRmoSubdomain, */ setNamespaceSetting,
  // validateEuropeanVat,
} from "../../lib/wsMsg";
import ModalConditions from "../payment/payment-modals/modalConditions.vue"; //'./payment-modals/modalConditions.vue'

Vue.use(VueTelInput);
export default {
  props: ["setNamespaceName", "setIsFree"],
  components: {
    // 'vue-tel-input': VueTelInput
    ModalConditions,
    CommonCompanyRegister,
    LawyerCompanyRegister,
  },
  data: () => ({
    valid: true,
    state: store.state,
    setSetUpConfigRoute: store.setSetUpConfigRoute,
    companyRegisterObject: store.state.namespaceSettings.companyInfo,
    company_name: store.state.namespaceSettings.companyInfo.company_name,
    email: store.state.namespaceSettings.companyInfo.email,
    setcompanyRegisterObject: store.setcompanyRegisterObject,
    phoneValid: false,
    setShowSetup: store.setShowSetup,
    terms: false,
    termsRAMicro: false,
    showModalConditions: false,
    countrySelected: store.state.namespaceSettings.companyInfo.state,
    branchSelected: store.state.namespaceSettings.companyInfo.branch,
    domain: document.domain,
    checkRmoStoreByClientIdLoading: false,
    registerRmoSubdomainLoading: false,
    registerRmoCustomerLoading: false,
    availableLanguage: ["DE", "EN", "ES"],
    userCountry: undefined,
    browserLanguage: "EN",
    branchesForFree: ["tax-consultant", "court", "lawyers", "notary"],
    showBranchs: true,
    countryDropDown: null,
    namespaceIsFree: true,
    newNamespace: "",
    countryCodeInput: "",
    companyPhoneValid: false,
    // europeanContriesCode: [
    //   "AT",
    //   "BE",
    //   "BG",
    //   "CY",
    //   "CZ",
    //   "DE",
    //   "DK",
    //   "EE",
    //   "EL",
    //   "ES",
    //   "FI",
    //   "FR",
    //   "HR",
    //   "HU",
    //   "IE",
    //   "IT",
    //   "LT",
    //   "LU",
    //   "LV",
    //   "MT",
    //   "NL",
    //   "PL",
    //   "PT",
    //   "RO",
    //   "SE",
    //   "SI",
    //   "SK",
    //   "XI",
    // ],
    // vatNumberValid: false,
    // vatNumberLoading: false
  }),
  methods: {
    clearDropDown() {
      this.countryDropDown = null;
    },
    setPhoneValid(value) {
      this.companyPhoneValid = !!value;
    },
    //DONT REMOVE #3909
    // checkVatNumber() {
    //   //check if valid vatNumber
    //   const vatNumber = (((this.$refs.CommonCompanyRegister || {}).$refs || {}).vatInput || {}).value
    //   if (vatNumber) {
    //     let sendVatNumber = vatNumber;
    //     this.vatNumberLoading = true;
    //     const contrySelected = this.companyRegisterObject.countrySelected.value;
    //     //check if user insert the contrycode send number without it
    //     const firstTwoCharacter = vatNumber.substring(0, 2);
    //     if (firstTwoCharacter.toUpperCase() == contrySelected.toUpperCase()) {
    //       sendVatNumber = vatNumber.substring(2);
    //     }
    //     if (
    //       !this.isPackagedVersion &&
    //       this.isEuropeanContries &&
    //       this.companyRegisterObject &&
    //       contrySelected &&
    //       sendVatNumber
    //     ) {
    //       validateEuropeanVat(contrySelected, sendVatNumber).then(
    //         (validationInfo) => {
    //           this.vatNumberValid = validationInfo.valid;
    //           this.vatNumberLoading = false;
    //         },
    //         (err) => {
    //           this.vatNumberValid = false;
    //           console.warn(err, "err");
    //         }
    //       );
    //     }
    //   }
    // },
    getBranchParam() {
      return new URLSearchParams(window.location.search).get("branch");
    },
    getCountryParam() {
      return new URLSearchParams(window.location.search).get("country");
    },
    cleanNewNamespace() {
      this.newNamespace = "";
    },
    showPayPalScreen() {
      if (!this.$refs.form.validate()) return;
      // this.setSetUpConfigRoute("paypal");
      this.setSetUpConfigRoute("verifyEmail");
    },
    getUserCountry() {
      fetch("https://ipinfo.io/json")
        .then((res) => res.json())
        .then((json) => {
          this.userCountry = json.country;
          this.countriesArray.find((data, index) => {
            if (data.value === this.userCountry) {
              this.companyRegisterObject.countrySelected =
                this.countriesArray[index];
              this.setCountry(this.companyRegisterObject.countrySelected);
            }
          });
        })
        .catch((err) => {
          this.userCountry =
            (`${navigator.language || navigator.userLanguage}`.match(
              /-([A-Z]{2})([,;]|$)/
            ) || [])[1] || "DE";
          this.countriesArray.find((data, index) => {
            if (data.value === this.userCountry) {
              this.companyRegisterObject.countrySelected =
                this.countriesArray[index];
              this.setCountry(this.companyRegisterObject.countrySelected);
            }
          });
          console.warn(err);
        });
    },
    getBrowserLanguage() {
      this.browserLanguage = navigator.language || navigator.userLanguage;
      if (this.userCountry) {
        this.browserLanguage = this.userCountry.split("-")[0];
      }
    },
    setLanguage(language) {
      this.$locale.change(language.toLowerCase());
      this.$set(this.state.user, "language", this.$locale.current());
    },
    detectLanguage() {
      const browserLang = navigator.language || navigator.userLanguage;
      if (this.availableLanguage.indexOf(this.userCountry) !== -1) {
        this.setLanguage(this.userCountry);
      } else if (this.availableLanguage.indexOf(browserLang) !== -1) {
        this.setLanguage(this.browserLanguage);
      } else {
        this.setLanguage("EN");
      }
    },
    // async registerRmoSubdomainHook(id, zip) {
    //   this.registerRmoSubdomainLoading = true;
    //   const result = await registerRmoSubdomain(id, zip)
    //     .catch(err => {
    //       dispatchErrorAlert(err.message);
    //       console.warn('registerRmoSubdomain', err.stack || err);
    //     });
    //   this.registerRmoSubdomainLoading = false;
    //   return result;
    // },
    // async registerRmoCustomerHook(inputData) {
    //   this.registerRmoCustomerLoading = true;
    //   const result = await registerRmoCustomer(inputData)
    //     .catch(err => {
    //       dispatchErrorAlert(err.message);
    //       console.warn('registerRmoCustomer', err.stack || err);
    //     });
    //   this.registerRmoCustomerLoading = false;
    //   return result;
    // },
    // async checkRmoStoreByClientIdHook(id, zip) {
    //   this.checkRmoStoreByClientIdLoading = true;
    //   const result = await checkRmoStoreByClientId(id, zip)
    //     .catch(err => {
    //       dispatchErrorAlert(err.message);
    //       console.warn('checkRmoStoreByClientId', err.stack || err);
    //     });
    //   this.checkRmoStoreByClientIdLoading = false;
    //   return result;
    // },
    setCountry(value) {
      if (value) {
        this.countryDropDown = value;
        this.userCountry = value.value;
        this.companyRegisterObject.countrySelected = value;
        // if ( value.value === 'DE' ){
        //   this.showBranchs = true;
        // }else{
        //   this.showBranchs = false;
        //   if ( this.companyRegisterObject.branchSelected ){
        //     this.companyRegisterObject.branchSelected = undefined;
        //   }
        //   //this.companyRegisterObject.branchSelected = null;
        // }
        this.companyRegisterObject.state = value.value;
        this.detectLanguage();
        this.checkIfNeedRedirect(this.companyRegisterObject.branchSelected);
        // this.checkVatNumber();
        this.countryCodeInput = value.value;
      }
    },
    async checkIfNeedRedirect(branchValue, namespace = undefined) {
      if (process.env.PKG) return; // Skip namespaceExists and redirect in packaged version
      let redirect;
      const namespaceToCheck = namespace
        ? namespace
        : document.location.hostname.split(".")[0];

      if (
        this.branchesForFree.indexOf(branchValue) !== -1 &&
        this.companyRegisterObject.state === "DE"
      ) {
        // check if vOffice subdomain is free
        redirect = `https://${namespaceToCheck}.ra-micro.voffice.pro`;
      } else {
        redirect = `https://${namespaceToCheck}.voffice.pro`;
      }
      if (redirect) {
        this.setNamespaceName(redirect);
        const isFree = await fetch(redirect + "/api/namespaceExists")
          .then((r) => r.json())
          .then(
            (r) =>
              !(
                r.setupDone ||
                (redirect !==
                  `${document.location.protocol}//${document.location.host}` &&
                  r.creatorInfo)
              )
          );
        if (isFree) {
          this.namespaceIsFree = true;
          this.cleanNewNamespace();
          this.setIsFree(true);
          if (webLicensedBaseFeatures.isDev) {
            if (
              redirect !==
              `${document.location.protocol}//${document.location.host}`
            ) {
              console.log(
                "branchValue",
                branchValue,
                "country",
                this.companyRegisterObject.state
              );
              console.log(
                "would redirect to",
                redirect +
                  "/?branch=" +
                  encodeURIComponent(branchValue || "") +
                  "&country=" +
                  encodeURIComponent(this.companyRegisterObject.state)
              );
            }
          } else if (isFree) {
            if (
              redirect !==
              `${document.location.protocol}//${document.location.host}`
            ) {
              window.location.href =
                redirect +
                "/?branch=" +
                encodeURIComponent(branchValue || "") +
                "&country=" +
                encodeURIComponent(this.companyRegisterObject.state);
            }
          }
        } else {
          this.setIsFree(false);
          this.namespaceIsFree = false;
          dispatchErrorAlert(
            this.$t("components.subdomainTaken.namespaceNotFree")
          );
        }
      }
    },
    setBranch(value) {
      //this.branchSelected = value.value;
      this.companyRegisterObject.branchSelected = value.value;
      this.checkIfNeedRedirect(value.value);
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.text.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    closeModalConditions() {
      this.showModalConditions = !this.showModalConditions;
    },
    onTelInput({ number, isValid, country }) {
      if (isValid) {
        const phone = `${(number || {}).international || ""}`
          .replace(/^00/, "+")
          .replace(/[-\s]/g, "")
          .trim();
        if (!phone || !/^\+\d+$/.test(phone)) {
          isValid = false;
        } else {
          if (
            !this.state.user.recoveryPhone ||
            !store.state.namespaceSettings.companyInfo.phone ||
            this.state.user.recoveryPhone === `${store.state.namespaceSettings.companyInfo.phone || ''}`.replace(/^00/, "+")
          ) {
            this.$set(this.state.user, "recoveryPhone", phone);
          }
          store.state.namespaceSettings.companyInfo.phone = phone.replace(/^\+/, "00");
        }
      }
      this.phoneValid = isValid;
    },
    async validate() {
      if (this.$refs.form.validate()) {
        // NEW SIMPLE PROCESS
        if (this.checkData) {
          this.companyRegisterObject.email = this.companyRegisterObject.email
            .trim()
            .toLowerCase();
          store.state.namespaceSettings.companyInfo =
            this.companyRegisterObject;
          store.state.namespaceSettings.companyInfo.virtualOfficePrefix =
            this.companyRegisterObject.company_name;
          setNamespaceSetting(
            "companyInfo",
            store.state.namespaceSettings.companyInfo
          );
          if (process.env.PKG) {
            setNamespaceSetting("setupDone", true);
          }
          setNamespaceSetting("noNewUsers", true);
          localStorage.setItem(
            "emailRegistered",
            store.state.namespaceSettings.companyInfo.email
          );
          localStorage.setItem(
            "firstNameRegistered",
            store.state.namespaceSettings.companyInfo.firstName
          );
          localStorage.setItem(
            "lastNameRegistered",
            store.state.namespaceSettings.companyInfo.lastName
          );
          if (process.env.PKG) {
            this.setShowSetup(false);
          } else {
            this.setSetUpConfigRoute("verifyEmail");
          }
        }
        // END NEW SIMPLE PROCESS
        // check if this is a german lawyer (+ some other branches)
        // if (this.companyRegisterObject.countrySelected.value === 'DE' && this.branchesForFree.includes(this.companyRegisterObject.branchSelected)) {
        //   // if this is ramicro customer with number and password (zip)
        //   if (this.companyRegisterObject.isRamicroCustomer && this.companyRegisterObject.ramicroCustomerNumber && this.companyRegisterObject.zipCode) {
        //     console.log('starting ramicro check', this.companyRegisterObject.ramicroCustomerNumber, this.companyRegisterObject.zipCode);
        //     await this.checkRmoStoreByClientIdHook(this.companyRegisterObject.ramicroCustomerNumber, this.companyRegisterObject.zipCode)
        //       .then((ramicroCheckResult) => {
        //         console.log('ramicroCheckResult', ramicroCheckResult);
        //         // if check ok
        //         if (ramicroCheckResult.Errors.length > 0) {
        //             dispatchErrorAlert(ramicroCheckResult.Errors[0].Message)
        //             return false;
        //         } else {
        //           if (ramicroCheckResult.FurtherInformation) {
        //             // check if user already has voffice 150/19  24143
        //             console.log('ramicroCheckResult.FurtherInformation', ramicroCheckResult.FurtherInformation)
        //             if ( ramicroCheckResult.FurtherInformation.RaMicroSupportContractOrRaMicro1){
        //               console.log('IS RAMICRO CONTRACT')
        //               //this.hasValidCustomerId = true;
        //             }else{
        //               console.log('WHAT WE DO ???? ')
        //             }
        //             // if (ramicroCheckResult.FurtherInformation.VOffice && ramicroCheckResult.FurtherInformation.VOffice.SubDomain) {
        //             //   // show message that he has it and show the link to it
        //             //   const vofficeSubdomain = ramicroCheckResult.FurtherInformation.VOffice.SubDomain;
        //             //   const vofficeLink = 'https://' + vofficeSubdomain + '.ra-micro.voffice.pro';
        //             //   dispatchSuccessAlert(vofficeLink);
        //             // } else {
        //             //   // if he doesn't have voffice yet register it
        //             //   return this.registerRmoSubdomainHook(this.companyRegisterObject.ramicroCustomerNumber, this.companyRegisterObject.zipCode)
        //             //     .then(vofficeRegisterResult => {
        //             //       console.log('vofficeRegisterResult', vofficeRegisterResult);
        //             //       // check if this is successful result by looking for new voffice link
        //             //         if (
        //             //         vofficeRegisterResult &&
        //             //         vofficeRegisterResult.Subscriptions &&
        //             //         vofficeRegisterResult.Subscriptions[0] &&
        //             //         vofficeRegisterResult.Subscriptions[0].AdditionalInformation
        //             //       ) {
        //             //         const AdditionalInformation = JSON.parse(vofficeRegisterResult.Subscriptions[0].AdditionalInformation);
        //             //         const vOfficeUrl = AdditionalInformation.vOfficeUrl;
        //             //         dispatchSuccessAlert(vOfficeUrl);
        //             //       }
        //             //     });
        //             // }
        //           }
        //           console.log('SUBDOMAIN ADDED')
        //           // this.setSetUpConfigRoute('takeActionRaMicro');
        //         }
        //       });

        //   } else {
        //     // if it's not ramicro customer then register him
        //     let branchCode = 0;
        //     if (this.companyRegisterObject.branchSelected === 'court') {
        //       branchCode = 1;
        //     } else if (this.companyRegisterObject.branchSelected === 'lawyers') {
        //       branchCode = 1;
        //     } else if (this.companyRegisterObject.branchSelected === 'tax-consultant') {
        //       branchCode = 3;
        //     } else if (this.companyRegisterObject.branchSelected === 'notary') {
        //       branchCode = 8;
        //     }
        //     const inputData = {
        //       ak: this.isGermanLawyer ? 1 : 0,
        //       branchCode: branchCode,
        //       companyName: this.companyRegisterObject.companyName || this.companyRegisterObject.lawOffice,
        //       salutation: this.companyRegisterObject.salutationSelected === 'mr' ? 1 : 2,
        //       firstName: this.companyRegisterObject.firstName,
        //       lastName: this.companyRegisterObject.lastName,
        //       streetHouse: this.companyRegisterObject.streetHouse,
        //       salesPartner: this.companyRegisterObject.salesPartnerSelected,
        //       city: this.companyRegisterObject.city,
        //       zipCode: this.companyRegisterObject.zipCode,
        //       email: this.companyRegisterObject.email,
        //       country: this.companyRegisterObject.countrySelected.value,
        //       contactNumber: this.companyRegisterObject.phone
        //     };
        //     console.log('inputData', inputData);
        //     await this.registerRmoCustomerHook(inputData)
        //       .then((ramicroCustomerResult) => {
        //         console.log('ramicroCustomerResult', ramicroCustomerResult);
        //         // if success
        //         if (ramicroCustomerResult.Customer && ramicroCustomerResult.Customer.CustomerId) {
        //           const rmoCustomerId = ramicroCustomerResult.Customer.CustomerId;
        //           const rmoCustomerZip = this.companyRegisterObject.zipCode;
        //         }
        //         if (ramicroCustomerResult.Errors.length == 0) {
        //           // condition to redirect depends on the registration form
        //           //console.log('ramicroCustomerResult.Errors.length, subdmonain created');
        //           store.state.namespaceSettings.companyInfo = this.companyRegisterObject
        //           setNamespaceSetting('companyInfo', store.state.namespaceSettings.companyInfo);
        //           setNamespaceSetting('setupDone', true);
        //           setNamespaceSetting('noNewUsers', true);
        //           localStorage.setItem('emailRegistered', store.state.namespaceSettings.companyInfo.email);
        //           localStorage.setItem('firstNameRegistered', store.state.namespaceSettings.companyInfo.firstName);
        //           localStorage.setItem('lastNameRegistered', store.state.namespaceSettings.companyInfo.lastName);
        //           this.setShowSetup(false);
        //           // if ( (branchCode === 1 || branchCode === 3 || branchCode === 8) && this.isGermanLawyer) {
        //           //   this.setSetUpConfigRoute('takeActionRaMicro');
        //           // } else {
        //           //   this.setSetUpConfigRoute('takeAction');
        //           // }
        //         } else {
        //           dispatchErrorAlert(ramicroCustomerResult.Errors[0].Message)
        //           return false;
        //         }
        //       }
        //     );
        //   }
        // } else {
        //   // if this is simple user that has to pay then redirect him to step 2
        //   // console.log('this.companyRegisterObject', this.companyRegisterObject)
        //   if ( this.checkData ){
        //       this.companyRegisterObject.email = this.companyRegisterObject.email.trim().toLowerCase();
        //       store.state.namespaceSettings.companyInfo = this.companyRegisterObject
        //       setNamespaceSetting('companyInfo', store.state.namespaceSettings.companyInfo);
        //       setNamespaceSetting('setupDone', true);
        //       setNamespaceSetting('noNewUsers', true);
        //       localStorage.setItem('emailRegistered', store.state.namespaceSettings.companyInfo.email);
        //       localStorage.setItem('firstNameRegistered', store.state.namespaceSettings.companyInfo.firstName);
        //       localStorage.setItem('lastNameRegistered', store.state.namespaceSettings.companyInfo.lastName);
        //       this.setShowSetup(false);
        //       // console.log('DOOOONE SINGLE VOFFICE')
        //       // this.setSetUpConfigRoute('takeAction');
        //   }
        // }
        return false;
      }
    },
  },
  computed: {
    // isEuropeanContries() {
    //   if (
    //     this.countryDropDown &&
    //     this.countryDropDown.value &&
    //     this.europeanContriesCode.includes(
    //       this.countryDropDown.value.toUpperCase()
    //     )
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    namespaceRules() {
      return [(v) => !!v || this.$t("generics.required")];
    },
    acceptRules() {
      return [(v) => !!v || this.$t("components.takeAction.acceptRequired")];
    },
    checkData() {
      return (
        this.companyRegisterObject.email !== "" &&
        this.companyRegisterObject.firstName !== "" &&
        this.companyRegisterObject.lastName !== "" &&
        this.companyRegisterObject.company_name !== "" &&
        this.companyRegisterObject.branchSelected &&
        this.companyRegisterObject.countrySelected &&
        this.companyRegisterObject.countrySelected.value
      );
    },
    isGermanLawyer() {
      if (
        this.companyRegisterObject.countrySelected &&
        this.companyRegisterObject.countrySelected.value === "DE" &&
        this.companyRegisterObject.branchSelected &&
        (this.companyRegisterObject.branchSelected === "lawyers" ||
          this.companyRegisterObject.branchSelected === "tax-consultant" ||
          this.companyRegisterObject.branchSelected === "court" ||
          this.companyRegisterObject.branchSelected === "notary")
      ) {
        return true;
      } else {
        return false;
      }
    },
    isPackagedVersion() {
      return !!process.env.PKG;
    },
    countriesArray() {
      const currentLanguage = this.$locale.current().toUpperCase();
      let countriesFile = countriesEN;

      if (currentLanguage == "DE") {
        countriesFile = countriesDE;
      } else if (currentLanguage == "EN") {
        countriesFile = countriesEN;
      } else if (currentLanguage == "ES") {
        countriesFile = countriesES;
      }

      const tmpArray = [];
      countriesFile.forEach((country) => {
        const tmpObj = {
          value: country.alpha2.toUpperCase(),
          name: country.name,
        };
        tmpArray.push(tmpObj);
      });
      //console.log('tmpArray', tmpArray)
      const sortedCountries = tmpArray.sort((a, b) => a.name.localeCompare(b.name));
      //console.log('sortedCountries', sortedCountries)
      return sortedCountries;
    },
    branchesArray() {
      let arrayBranches = [];
      const branches = this.$t("components.companyRegister.branches");
      for (let key in branches) {
        if (key !== "others") {
          const obj = {
            text: branches[key],
            value: key,
          };
          arrayBranches.push(obj);
        }
      }
      arrayBranches = arrayBranches.sort(function (a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      const obj = {
        text: branches["others"],
        value: "others",
      };
      arrayBranches.push(obj);
      return arrayBranches;
    },
    isSESubdomain() {
      return webLicensedBaseFeatures.isSeCustomer;
    },
    isRaMicroSubdomain() {
      return webLicensedBaseFeatures.isRaMicroCustomer;
    },
    cols() {
      const { lg, sm, md, xs } = this.$vuetify.breakpoint;
      return lg
        ? [12, 12, 12, 12]
        : sm
        ? [12, 12, 12, 12]
        : md
        ? [12, 12, 12, 12]
        : xs
        ? [12, 12, 12, 12]
        : [12, 12, 12, 12];
    },
    cols2() {
      const { lg, sm, md, xs } = this.$vuetify.breakpoint;
      return lg ? [8, 4] : sm ? [8, 4] : md ? [8, 4] : xs ? [8, 4] : [8, 4];
    },
    cols3() {
      const { lg, sm, md, xs } = this.$vuetify.breakpoint;
      return lg ? [6, 6] : sm ? [6, 6] : md ? [6, 6] : xs ? [6, 6] : [6, 6];
    },
    companyNameRules() {
      return [
        (v) => !!v || this.$t("components.companyRegister.companyNameRequired"),
        (v) =>
          v.length >= 3 ||
          this.$t("components.companyRegister.companyNameMinimum"),
      ];
    },
    contactNameRules() {
      return [
        (v) => !!v || this.$t("components.companyRegister.contactNameRequired"),
      ];
    },
    employeesRules() {
      return [
        (v) =>
          !!parseInt(v, 10) ||
          this.$t("components.companyRegister.employeesRequired"),
      ];
    },
    taxNumberRules() {
      return [
        (v) => !!v || this.$t("components.companyRegister.taxNumberRequired"),
        (v) => /^[\w\d]+$/.test(v) || "",
      ];
    },
    streetAddressRules() {
      return [
        (v) =>
          !!v || this.$t("components.companyRegister.streetAddressRequired"),
      ];
    },
    cityRules() {
      return [(v) => !!v || this.$t("components.companyRegister.cityRequired")];
    },
    stateRules() {
      return [
        (v) => !!v || this.$t("components.companyRegister.stateRequired"),
      ];
    },
    branchRules() {
      return [
        (v) => !!v || this.$t("components.companyRegister.branchRequired"),
      ];
    },
    zipCodeRules() {
      return [
        (v) => !!v || this.$t("components.companyRegister.zipCodeRequired"),
      ];
    },
    houseNumberRules() {
      return [
        (v) => !!v || this.$t("components.companyRegister.houseNumberRequired"),
      ];
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("components.companyRegister.emailRequired"),
        (v) =>
          /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) ||
          this.$t("components.companyRegister.emailMustValid"),
      ];
    },
    isFormPreValid() {
      return (
        this.companyRegisterObject.countrySelected != "" &&
        this.companyRegisterObject.branchSelected &&
        this.companyRegisterObject.firstName != "" &&
        this.companyRegisterObject.lastName != "" &&
        this.companyRegisterObject.company_name != "" &&
        // this.companyRegisterObject.tax_number != '' &&
        this.companyRegisterObject.email != ""
      );
    },
  },
  created() {
    const branchParam = this.getBranchParam();
    const countryParam = this.getCountryParam();
    if (webLicensedBaseFeatures.isRaMicroCustomer) {
      this.companyRegisterObject.branchSelected =
        branchParam &&
        this.branchesArray.map((e) => e.value).includes(branchParam)
          ? branchParam
          : "lawyers";
    } else if (
      branchParam &&
      this.branchesArray.map((e) => e.value).includes(branchParam)
    ) {
      this.companyRegisterObject.branchSelected = branchParam;
    }
    this.userCountry = countryParam || "DE";
    this.countriesArray.find((data, index) => {
      if (data.value === this.userCountry) {
        this.companyRegisterObject.countrySelected =
          this.countriesArray[index];
        this.setCountry(this.companyRegisterObject.countrySelected);
      }
    });
  },
  destroyed() {
    EventBus.$off("phoneValid", this.setPhoneValid);
  },
  async mounted() {
    EventBus.$on("phoneValid", this.setPhoneValid);
    if (!webLicensedBaseFeatures.isRaMicroCustomer && !this.getCountryParam()) {
      await this.getUserCountry();
    }
    await this.getBrowserLanguage();
  },
};
</script>

<style  lang="scss">
.v-application {
  .checkBoxTerms {
    a {
      text-decoration: none !important;
    }
    &.error--text {
      color: initial !important;
    }
    label.error-text {
      color: initial !important;
    }
  }
}
.imgRaMicro {
  height: 250px;
}
.raMicroCheckBox {
  max-height: 35px;
  label {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}
.otherTerms {
  padding-left: 45px;
  font-size: 14px;
}
.countryFlag {
  width: 20px;
  margin-right: 5px;
}

.vueScrollConditions {
  max-height: calc(100% - 90px);
}
.text-right {
  text-align: right;
}
.right {
  justify-content: flex-end;
}
.titleForm {
  color: #02889d;
}
// .pContact{
//   margin-bottom: -6px !important;
//   font-size: 16px;
//   color: rgba(0,0,0,.6);
//   line-height: 20px;
//   height: 20px;
//   max-width: 133%;
//   transform: translateY(0px) translateX(-40px) scale(.75);
// }
// .vue-tel-input{
//   // border: none !important;
//   // border-bottom: 1px solid !important;
//   // border-radius: 0px !important;
//   // box-shadow: none !important;
//   // outline: none !important;
//   height: 35px;
// }
.borderError {
  border-color: red !important;
}
.v-stepper__header {
  display: none !important;
}
// .spanDropdownSelection{
//   font-size: .8125rem;
//     font-weight: 500;
// }
.buttonBackRACustomer {
  margin-top: 10px;
  width: 100%;
  text-align: left;
}
</style>
