<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="650"
    @keydown.esc="closeModal"
  >
    <v-card>
      <v-spacer></v-spacer>
      <v-row flex class="w-100 mx-0 my-0 justify-space-between">
        <v-col class="px-0 py-0" align="left">
          <HeaderModal
            :titleModal="$t('components.userListItem.displayAssistants')"
            :closeModalFunction="closeModal"
          />
        </v-col>
        <div class="pa-4">
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="black" @click="closeModal">
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    :style="{ fontSize: '25px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("generics.close") }}</span>
            </v-tooltip>
          </template>
        </div>
      </v-row>
      <v-card-text class="mx-auto">
        <v-row class="mx-auto" flex>
          <v-col cols="6" align="center">
            <v-switch
              class="my-0 py-2"
              @change="changeAssistantDisplay()"
              v-model="displayAssistant"
              :label="$t('components.userListItem.displayAssistantOption')"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <FooterModal :closeModalFunction="closeModal" :showCancel="true">
        <v-btn color="primary" @click="closeModal">{{
          $t("generics.accept")
        }}</v-btn>
      </FooterModal>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { setNamespaceSetting } from "../../lib/wsMsg";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";

export default {
  components: {
    FooterModal,
    HeaderModal,
  },
  props: ["showModal", "closeModal"],
  data: () => ({
    state: store.state,
    displayAssistant: "",
  }),
  mounted: function () {
    this.displayAssistant = this.state.namespaceSettings.displayAssistants;
  },
  methods: {
    changeAssistantDisplay() {
      setNamespaceSetting("displayAssistants", this.displayAssistant);
    },
  },
};
</script>

<style scoped lang="scss">
</style>