var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("topbar " + (_vm.uuid !== _vm.state.ownUUID ? 'remoteTopBar' : null) + " "),on:{"mousemove":function($event){_vm.moveMouserCardRemote && _vm.participantId
        ? _vm.moveMouserCardRemote(_vm.participantId)
        : false}}},[(_vm.showInfoIcons)?_c('div',{staticClass:"userIconInfo"},[_c('span',{staticClass:"userIconTile font-weight-light d-flex align-center"},[(_vm.amImoderator)?_c('font-awesome-icon',{staticClass:"mr-2",style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'users-crown']}}):_vm._e(),_vm._v(" "),(!_vm.isGuest && !_vm.amImoderator)?_c('font-awesome-icon',{staticClass:"mr-2",style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'user']}}):_vm._e(),_vm._v(" "),(_vm.isGuest)?_c('font-awesome-icon',{staticClass:"mr-2",style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'user-clock']}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.disableParticipantTT},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-card-subtitle',_vm._g({staticClass:"callerName font-weight-light pt-0 pb-0 px-0 text-truncate",class:{ 'font-weight-bold': _vm.showHand },staticStyle:{"color":"#fff !important"}},on),[_vm._v("\n          "+_vm._s(_vm.titelPerson())+" "+_vm._s(_vm.getName())+"\n        ")])]}}])},[_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(_vm.titelPerson())+" "+_vm._s(_vm.getName())+"\n      ")]),_vm._v(" "),(_vm.getLocation())?[_c('br'),_vm._v(" "),_c('span',[_vm._v("\n          "+_vm._s(_vm.getLocation())+"\n        ")])]:_vm._e(),_vm._v(" "),(_vm.getDescription())?[_c('br'),_vm._v(" "),_c('span',[_vm._v("\n          "+_vm._s(_vm.getDescription())+"\n        ")])]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"rightSideBar"},[(_vm.showHand)?_c('font-awesome-icon',{staticClass:"iconHand ml-2 mr-2",style:({ fontSize: '18px' }),attrs:{"color":"white","icon":['fal', 'hand-point-up']}}):_vm._e(),_vm._v(" "),((_vm.isOnlyOneCallUser || _vm.amIGuest) && _vm.unseeMessageOneCallUser)?_c('font-awesome-icon',{staticClass:"iconHand ml-2",style:({ fontSize: '12px' }),attrs:{"color":"red","icon":['fas', 'circle']},on:{"click":_vm.toggleMessage}}):_vm._e(),_vm._v(" "),(_vm.showInfoIcons)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({staticClass:"userIconTile font-weight-light d-flex align-center"},on),[(_vm.room && _vm.room.isE2EEEnabled())?_c('v-img',{attrs:{"lazy-src":"img/icons/e2e-lock-alt-white.svg\n            ","src":"img/icons/e2e-lock-alt-white.svg\n            ","max-width":"20px","width":"20px"}}):_c('v-img',{attrs:{"lazy-src":"img/icons/vo_e2e-lock-white.svg\n            ","src":"img/icons/vo_e2e-lock-white.svg\n            ","max-width":"20px","width":"20px"}})],1)]}}],null,false,4078463902)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.room && _vm.room.isE2EEEnabled()
            ? _vm.$t("components.callsContent.e2eEnableTT")
            : _vm.$t("components.callsContent.e2eDisableTT")))])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }