<template>
  <StatusBySections v-if="organigramIsActive" />
  <StatusByUsers v-else/>
</template>

<script>
import store from "../../store";
import StatusByUsers from "./statusByUsers.vue";
import StatusBySections from "./statusBySections.vue";
import { webLicensedBaseFeatures } from '../../../sharedsrc/licensedFeatures';
export default {
  components: { StatusByUsers, StatusBySections },
  data() {
    return {
      state: store.state,
    };
  },
  methods: {
  },
  computed: {
    isRaMicro() {
      return webLicensedBaseFeatures.isRaMicro || webLicensedBaseFeatures.isRaMicroCustomer;
    },
    hasSectionsData() {
      return Object.keys((this.state.namespaceSettings.processedOrganisation || {}).sections || []).length;
    },
    organigramIsActive(){
      return store.state.namespaceSettings.featureOrganization && this.hasSectionsData;
    },
  }
};
</script>

<style scoped lang="scss">

</style>