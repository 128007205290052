<template>
  <RegularGrid :typeData="'favorites'" />
</template>

<script>
import store, { syncedGroupState, syncedUserState } from "../../store";
import RegularGrid from "./regularGrid.vue";
const hasOwn = Object.prototype.hasOwnProperty;
export default {
  components: { RegularGrid },
  data() {
    return {
      state: store.state,
    };
  },
  methods: {},
  computed: {
    sortedPersonsLength() {
      let length = 0;
      if (Object.keys(store.state.persisted.favoriteUsers || {}).length) {
        for (let uuid in store.state.persisted.favoriteUsers) {
          if (
            hasOwn.call(store.state.persisted.favoriteUsers, uuid) &&
            hasOwn.call(store.state.group, uuid)
          ) {
            const person = uuid
              && store.getUuidFromFavorites(uuid)
              && store.state.group[uuid];
            if (person && person.user && !person.user.hidedByAdmin) {
              length++;
            }
          }
        }
      }
      return length;
    },
  },
  mounted() {
    const delay = Promise.all([
      syncedGroupState(true),
      syncedUserState().catch(() => {}),
    ]);
    this._startViewRedirectTimeout = setTimeout(() => {
      delay.then(() => {
        if (
          this._startViewRedirectTimeout &&
          this.sortedPersonsLength === 0 &&
          this.$route.path === "/my-favorites" &&
          store.state.user.userSettings.startView == "my-favorites"
        ) {
          this.$router.push({ path: "/home" });
        }
      });
    }, 2000);
  },
  destroyed() {
    if (this._startViewRedirectTimeout) {
      clearTimeout(this._startViewRedirectTimeout);
      this._startViewRedirectTimeout = undefined;
    }
  },
};
</script>