<template>
  <div class="h100">
    <!-- modal to inform the user that he is in a maximum member call -->
    <fullCallModal v-if="fullCall" :fullCall="fullCall" />
    <div class="callerName">

      <span v-if="uuid">{{ getDataForHeader(uuid) }}</span>
      <!-- v-if="ownerUUID === uuid" -->

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on" class="italicFont" style="float: right;margin-right:9px" > P2P</span>
        </template>
          <span>{{ $t('components.remotePersonCall.p2p')}}</span>
      </v-tooltip>
      <!-- <v-tooltip top v-if="uuid && (ownerUUID === uuid)">
        <template v-slot:activator="{ on }">
          <span v-on="on" style="float: left;margin-left:9px" >
            <font-awesome-icon
              v-on="on"
              :icon="['fal', 'users-crown']"
              :style="{ fontSize: '20px' }"
            />
          </span>
        </template>
        <span>{{ $t("components.remotePersonCall.moderator") }}</span>
      </v-tooltip> -->
      <span v-if="uuid && (ownerUUID === uuid)" style="float: left;margin-left:9px">Moderator</span>
      <v-tooltip top v-if="uuid && (ownerUUID !== uuid) && ( !isWaitingRoomUser(uuid) && !isVisitor )">
        <template v-slot:activator="{ on }">
          <span v-on="on" style="float: left;margin-left:9px" >
            <font-awesome-icon
              v-on="on"
              :icon="['fal', 'user-crown']"
              :style="{ fontSize: '20px' }"
            />
          </span>
        </template>
        <span>{{ $t("components.remotePersonCall.user") }}</span>
      </v-tooltip>

      <v-tooltip top v-if="uuid && (ownerUUID !== uuid) && ( isWaitingRoomUser(uuid) || isVisitor )">
        <template v-slot:activator="{ on }">
          <span v-on="on" style="float: left;margin-left:9px" >
            <font-awesome-icon
              v-on="on"
              :icon="['fal', 'user-friends']"
              :style="{ fontSize: '20px' }"
            />
          </span>
        </template>
        <span>{{ $t("components.remotePersonCall.guest") }}</span>
      </v-tooltip>
      
    </div>
    <div v-if="state.nerd" class="rtcStatus">
      <div>
        IceConnectionState:
        <span
          v-bind:class="{ green: state.rtc[uuid].iceConnectionState === 'connected' }"
        >{{ state.rtc[uuid].iceConnectionState }}</span>
      </div>
      <div>
        IceGatheringState:
        <span
          v-bind:class="{ green: state.rtc[uuid].iceGatheringState === 'complete' }"
        >{{ state.rtc[uuid].iceGatheringState }}</span>
      </div>
      <div>
        SignalingState:
        <span
          v-bind:class="{ green: state.rtc[uuid].signalingState === 'stable' }"
        >{{ state.rtc[uuid].signalingState }}</span>
      </div>
      <div>
        RingingState:
        <span
          v-bind:class="{ green: state.rtc[uuid].ringingState === 'connected' }"
        >{{ state.rtc[uuid].ringingState }}</span>
      </div>
      <div v-if="activeCandidates">
        ConnectionType:
        <span
          v-bind:class="{ green: !isTurn }"
        >{{ activeCandidates.local.candidateType }} / {{ activeCandidates.remote.candidateType }}</span>
      </div>
      <div
        v-if="this.videoDetailsFirst && this.videoDetailsFirst.width"
      >First: {{ this.videoDetailsFirst.width || 0 }}x{{ this.videoDetailsFirst.height || 0 }}@{{ Math.floor(this.videoDetailsFirst.frameRate || 0) }}</div>
      <div
        v-if="this.videoDetailsSecond && this.videoDetailsSecond.width"
      >Second: {{ this.videoDetailsSecond.width || 0 }}x{{ this.videoDetailsSecond.height || 0 }}@{{ Math.floor(this.videoDetailsSecond.frameRate || 0) }}</div>

    </div>
     <!-- @dblclick="fullSreen($event)" -->
    <div class="videos" id="remotePersonCallVideosContainer" ref="videos">
      <div class="onHoldOverlay" v-if="onHold">
        <font-awesome-icon class="onHoldCenterIcon" :icon="['fal', 'phone-plus']" :style="{ fontSize: '45px' }" />
        <div class="messageToParticipants messageToParticipantsBlink">{{ $t('components.remotePersonCall.onHoldMessage')}}</div>
      </div>
       <!-- <span v-if="uuid === ownerUUID" class="italicFont videoIdTag" >{{callInfo.callUUID || ''}}</span> -->
      <!-- <div v-if="IsFullScreen()">
        test {{IsFullScreen()}}
      </div>-->
      <!-- <template v-if="IsFullScreen()">
        <v-card class="mx-auto cardPositionInfo" max-width="300" flat color="transparent">
          <v-card-text class="headline font-weight-bold">
            <v-list-item class="grow opacity">
              <v-list-item-avatar tile size="60">
                <v-img
                  class="elevation-6"
                  :src="getAvatarForUuid(uuid)"
                  lazy-src="assets/default_profile_picture.png"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="white--text">{{getNameForUuid(uuid)}}</v-list-item-title>
                <v-list-item-subtitle class="white--text">{{getPositionForUuid(uuid)}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </template>
      <template v-if="IsFullScreen()">
        <v-card class="mx-auto cardPositionInfoLogo" max-width="300" flat color="transparent">
          <v-card-text class="d-flex align-center opacity">
            <v-img class="mx-2" src="assets/icon.png" max-height="40" max-width="40" contain></v-img>
            <div class="white--text v-toolbar__title font-weight-thin">vOffice</div>
          </v-card-text>
        </v-card>
      </template> -->

      <!-- <VueDragResize
        v-resize="handleResize"
        v-if="remote.second"
        :parent="true"
        class="bg"
        :isActive="true"
        :x="0"
        :y="0"
        :aspectRatio="true"
        :minw="200"
        :minh="150"
        :w="200"
        :h="150"
        :parentW="pLimitW"
        :parentH="pLimitH"
        v-on:resizing="resize"
        v-on:dragging="resize"
        :parentLimitation="true"
      >
        <video
          id="remote-first"
          class="remote-first"
          autoplay="autoplay"
          :src-object.prop.camel="remote.first"
          v-bind:class="{ hidden: !remote.first || !haveRemoteFirstVideo}"
          :muted="isLocalSoundMuted"
          @click.prevent
        ></video>
      </VueDragResize> -->
      <video
        id="remote-first"
        class="remote-first"
        autoplay="autoplay"
        :src-object.prop.camel="remote.first"
        v-bind:class="{ hidden: !remote.first || !haveRemoteFirstVideo}"
        :muted="isLocalSoundMuted"
        v-show="!remote.second"
        @click.prevent
        ref="remoteFirst"
      ></video>
      <video
        id="remote-second"
        class="remote-second"
        autoplay="autoplay"
        :src-object.prop.camel="remote.second"
        v-bind:class="{ hidden: !remote.second }"
        :muted="isLocalSoundMuted"
        @click.prevent
        ref="remoteSecond"
      ></video>

      <div class="noVideoStream" v-if="state.rtc[uuid].ringingState === 'ringing'">
        <div class="noVideoStreamContent">
          <div class="callAvatarWrapper">
            <v-img
              class="noVideoStreamAvatar"
              contain
              max-width="160"
              max-height="160"
              :src="getAvatarForUuid(uuid)"
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
          </div>
          <div>
            <span
              class="callingText"
            >{{ $t('components.remotePersonCall.calling')}} {{ getNameForUuid(uuid) }}</span>
            <div id="wave">
              <div color="primary" class="dot"></div>
              <div color="primary" class="dot"></div>
              <div color="primary" class="dot"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="noVideoStream" v-if="state.rtc[uuid].ringingState === 'accepted'">
        <div class="noVideoStreamContent">
          <span class="callingText">{{ $t('components.remotePersonCall.callInProgress') }}</span>
        </div>
      </div>
      <div class="noVideoStream" v-if="state.rtc[uuid].ringingState === 'connected'">
        <!-- NO VIDEO STREAM -->
        <div class="noVideoStreamContent">
          <div class="callAvatarWrapper">
            <v-img
              class="noVideoStreamAvatar"
              contain
              max-width="160"
              max-height="160"
              :src="getAvatarForUuid(uuid)"
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
          </div>
          <div>
            <span class="callingText">{{ $t('components.remotePersonCall.inCallWith')}} {{ getNameForUuid(uuid) }}</span>
            <br>
            <span v-if="onHold">{{ $t('components.remotePersonCall.onHold')}}</span>
          </div>
        </div>
      </div>

      <div
        v-bind:class="{ hidden: Object.keys(state.remoteStreams).length == 0}"
        class="buttonsUser"
      >
        <!-- <div class="divButtonsLocal"> -->
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="white" class="mx-3" @click.stop.prevent="ShowTicketEntry(uuid)">
              <font-awesome-icon :icon="['fal', 'ballot-check']" :style="{ fontSize: '20px' }" />
            </v-btn>
          </template>
          <span>{{ $t('components.userListItem.tasks') }}</span>
        </v-tooltip> -->
        <v-tooltip top v-if="iamOwner">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"  color="white" icon class="mx-3" @click.stop.prevent="transferModerator(uuid)">
              <font-awesome-icon
                :icon="['fal', 'users-crown']"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t('components.remotePersonCall.transferModerator') }}</span>
        </v-tooltip>

        <v-tooltip top v-if="iamOwner">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"  color="white" icon class="mx-3" @click.stop.prevent="getWebrtcConnections()[uuid].die(true)">
              <font-awesome-icon
                :icon="['fal', 'phone']"
                :style="{ fontSize: '20px' }"
                class="faPhoneRotate"
              />
            </v-btn>
          </template>
          <span>{{ $t('generics.hangUp') }}</span>
        </v-tooltip>

        <!-- <v-tooltip top v-if="state.ownUUID === state.presentationView.owner"> -->
          <v-tooltip top v-if="state.ownUUID === ownerUUID">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="white" icon @click.stop.prevent="toggleMuteUserAudio(uuid)" class="mx-3">
                 <font-awesome-icon
                  :icon="['fal', 'microphone']"
                  :style="{ fontSize: '20px' }"
                  v-if="!isMyAudioMutedByAdmin"
                />
                <font-awesome-icon
                  :icon="['fal', 'microphone-slash']"
                  :style="{ fontSize: '20px' }"
                  v-if="isMyAudioMutedByAdmin"
                />
              </v-btn>
          </template>
          <span>
            {{ !isMyAudioMutedByAdmin ? $t('components.remotePersonCall.muteUser') : $t('components.remotePersonCall.unMuteUser') }}
          </span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="white" @click.stop.prevent="setShowModalSenMsg(uuid)" class="mx-3">
              <font-awesome-icon :icon="['fal', 'paper-plane']" :style="{ fontSize: '20px' }" />
              <!-- <font-awesome-icon
                  :icon="['fal', 'lock']"
                  :style="{ fontSize: '14px' }"
                  class="lockMessageIcon"
                /> -->
            </v-btn>
          </template>
          <span>{{ $t('components.userProfile.sendAMessage') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              color="white"
              class="mx-3"
              @click.prevent.stop="sendMail(uuid)"
            >
              <!-- target="_blank"
              :href="`mailto:${getEmailForUuid(uuid)}?subject=${state.user.name}%20from%20vOffice&body=${emailSalutation}%20${getNameForUuid(uuid)},`" -->
              <font-awesome-icon :icon="['fal', 'envelope-square']" :style="{ fontSize: '24px' }" />
            </v-btn>
          </template>
          <span>{{ $t('components.userListItem.sendEMail') }}</span>
        </v-tooltip>

         <v-tooltip top v-if="Object.keys(state.remoteStreams).length == 1">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="white"
              icon
              @click.stop.prevent="changeActualSize"
              class="mx-3"
            >
              <font-awesome-icon :icon="['fal', 'expand-wide']" :style="{ fontSize: '20px' }" v-if="!fullScreen" />
              <font-awesome-icon :icon="['fas', 'compress']" :style="{ fontSize: '20px' }" v-if="fullScreen" />
            </v-btn>
          </template>
          <span>
            {{ $t('components.userListItem.enlarge') }}
          </span>
        </v-tooltip>


        <!-- <v-tooltip top v-if="Object.keys(state.remoteStreams).length == 1 || onHold">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="white"
              icon
              @click.stop.prevent="onHold ? unholdCall(uuid) : holdCall(uuid)"
              class="mx-3"
            >
              <font-awesome-icon :icon="['fal', 'phone-plus']" :style="{ fontSize: '20px' }" />
            </v-btn>
          </template>
          <span>{{ onHold ? $t('components.remotePersonCall.resume') : $t('components.remotePersonCall.hold') }}</span>
        </v-tooltip> -->

        <v-tooltip top v-if="isPayEnabled && Object.keys(state.remoteStreams).length == 1 && (isWaitingRoom || isVisitor)">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            color="white"
            icon
            @click.stop.prevent="showModalPaymentRequest()"
            class="mx-3"
          >
            <font-awesome-icon :icon="['fal', 'euro-sign']" :style="{ fontSize: '20px' }" v-if="state.namespaceSettings.currency === 'EUR'"/>
            <font-awesome-icon :icon="['far', 'pound-sign']" :style="{ fontSize: '20px' }" v-if="state.namespaceSettings.currency === 'GBP'"/>
            <v-img
            contain
            height="20"
            v-if="state.namespaceSettings.currency === 'CHF'"
            src="img/paymentIcons/swiss-franc.png"
            lazy-src="img/paymentIcons/swiss-franc.png"
          ></v-img>
            
          </v-btn>
        </template>
        <span>{{ $t('components.callsContent.requestPayment') }}</span>
      </v-tooltip>

        <v-tooltip top v-if="state.nerd">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="white"
              icon
              @click.stop.prevent="triggerIceRestart(uuid)"
              class="mx-3"
            >
              <font-awesome-icon v-show="!isStable" :icon="['fal', 'eclipse']" :style="{ fontSize: '20px' }" />
              <font-awesome-icon v-show="isStable" :icon="['fal', 'star']" :style="{ fontSize: '20px' }" />
            </v-btn>
          </template>
          <span>Restart ICE</span>
        </v-tooltip>

        <!-- <v-tooltip top v-if="Object.keys(state.remoteStreams).length == 2 && uuid === Object.keys(state.remoteStreams)[1] && hasOnHold">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="white"
              icon
              @click.stop.prevent="transferCall(uuidOnHold)"
              class="mx-3"
            >
              <font-awesome-icon :icon="['fal', 'random']" :style="{ fontSize: '20px' }" />
            </v-btn>
          </template>
          <span>{{ $t('components.userListItem.forwarding') }}</span>
        </v-tooltip> -->

        <!-- <span v-if="activeCandidates && activeCandidates.remote.candidateType === 'relay'" class="yellow--text">Relay</span> -->
        <!-- <span v-if="state.namespaceSettings.showRelayLabel && (activeCandidates && (activeCandidates.remote.candidateType === 'relay' || activeCandidates.local.candidateType === 'relay'))" :class="activeCandidates && (activeCandidates.remote.candidateType === 'relay' || activeCandidates.local.candidateType === 'relay') ? 'darkGreen' : 'lightGreen'">Relay</span> -->
      </div>

      <!-- <div class="divButtons" v-if="Object.keys(state.remoteStreams).length > 1">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon class="redBtn mx-3" @click="getWebrtcConnections()[uuid].die(true)" >
              <font-awesome-icon
                :icon="['fal', 'phone']"
                :rotation="270"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t('generics.hangUp') }}</span>
        </v-tooltip>
      </div>-->
    </div>
  </div>
</template>

<script>
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import store, { EventBus } from "../../store";
import CallInfoManager from '../../lib/callInfoManager';
// import { getWebrtcConnections } from '../../lib/rtcCall';
import { getWebrtcConnections, holdCall, unholdCall, transferCall, triggerIceRestart } from "../../lib/rtcConn";
import AudioStreamMeter from "./audioStreamMeter.vue";
import fullCallModal from "../modal/fullCallModal.vue";
import VueDragResize from "vue-drag-resize";
import { setRequestPaymentModal, setSendMessageModalEvent, setHistoryMessagesModalEvent } from '../../effector/modals';
import { dispatchColorChangeTimelineEntry } from '../../effector/timeline';
import {ownerUUID, callInfoStateStore, allowedToTalkMap, notAllowedToTalkMap, callUpdateAddAllowedToTalkUUID, callUpdateRemoveAllowedToTalkUUID, amMutedByGlobal, callUpdateRemoveNotAllowedToTalkUUID, callUpdateAddNotAllowedToTalkUUID} from "../../effector/call"
import { isVisitor, isWaitingRoomUser } from '../../utils/privileges';
// import {getSubjectVisitor} from "../../utils/basicFunctions.js";
export default {
  components: { AudioStreamMeter, fullCallModal, VueDragResize },
  props: ["uuid", "remote", "isLocalSoundMuted", 'changeActualSize', 'splitViews', 'fullScreen'],
  effector: {
    ownerUUID: ownerUUID,
    callInfo: callInfoStateStore,
    allowedToTalk: allowedToTalkMap,
    notAllowedToTalk: notAllowedToTalkMap,
    amMutedByGlobal :amMutedByGlobal
  },
  data() {
    return {
      state: store.state,
      localMute: false,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      pLimitW: 0,
      pLimitH: 0,
      // uuidOnHold: undefined,
    };
  },
  methods: {
    sendMail(uuid){
      window.open(`mailto:${this.getEmailForUuid(uuid)}?subject=${this.state.user.name}%20from%20vOffice&body=${this.emailSalutation}%20${this.getNameForUuid(uuid)}`)
    },
    isWaitingRoomUser(uuid){
      return isWaitingRoomUser(uuid);
    },
    showModalPaymentRequest(){
      setRequestPaymentModal(this.uuid);
      this.splitViews();
      EventBus.$emit('splitViews')
    },
    toggleMuteUserAudio(uuid){
      // if (this.allowedToTalk.length === 0 || this.amMutedByGlobal) {
      if (this.isMyAudioMutedByAdmin) {
        if (this.allowedToTalk.length === 0) {
          callUpdateRemoveNotAllowedToTalkUUID(uuid);
        } else {
          callUpdateAddAllowedToTalkUUID(uuid);
        }
      } else {
        if (this.allowedToTalk.indexOf(uuid) !== -1) {
          callUpdateRemoveAllowedToTalkUUID(uuid);
        } else {
          callUpdateAddNotAllowedToTalkUUID(uuid);
        }
        // callUpdateAddNotAllowedToTalkUUID(uuid);
        // callUpdateRemoveAllowedToTalkUUID(uuid);
      }
    },
    transferModerator(uuid){
      //  console.log('owner ', uuid, this.getNameForUuid(uuid))
      return CallInfoManager.transferOwnership(uuid);
    },
    attachSinkId(element, sinkId) {
      try {
        if (Array.isArray(element)) element = element[0];
        // console.log('remotePersonCall attachSinkId', sinkId, element);
        if (!element) {
          console.warn('attachSinkId element is null', element);
          return;
        }
        if (typeof element.sinkId !== "undefined") {
          element
            .setSinkId(sinkId)
            .then(() => {
              // console.log(`Success, audio output device attached: ${sinkId}`);
            })
            .catch(error => {
              let errorMessage = error;
              if (error.name === "SecurityError") {
                errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
              }
              console.error(errorMessage);
              // Jump back to first output device in the list as it's the default.
              // if (this.$refs.audioOutput.selectedIndex) this.$refs.audioOutput.selectedIndex = 0;
            });
        } else {
          console.warn("Browser does not support output device selection.");
        }
      } catch (err) {
        console.warn('attachSinkId err', err);
      }
    },
    changeAudioDestination() {
      if (this.$refs.remoteFirst) this.attachSinkId(this.$refs.remoteFirst, this.audioDestination);
      if (this.$refs.remoteSecond) this.attachSinkId(this.$refs.remoteSecond, this.audioDestination);
    },
    holdCall(uuid) { return holdCall(uuid); },
    unholdCall(uuid) { return unholdCall(uuid); },
    // transferCall(uuid) { return transferCall(uuid, this.uuid); },
    setShowModalSenMsg(uuid) {
      // return store.setShowModalSenMsg(uuid);
      // setSendMessageModalEvent({show:true, users: [uuid], type: 'regularMsg'})
      setHistoryMessagesModalEvent(uuid)
    },
    // ShowTicketEntry(assignee) {
    //   console.log("show ticket entry ", this.state.showTicketEntry, assignee);
    //   this.state.currentContentVisile.showComponent = true;
    //   this.state.currentContentVisile.component = "ticketEntry";
    //   this.state.showTicketEntry = true;
    //   this.state.ticket.t_assignee = assignee;
    // },
    // fullSreen(event) {
    //   if (!this.IsFullScreen()) {
    //     // const elem = event.target;
    //     const elem = this.$refs.videos;
    //     if (elem.requestFullscreen) {
    //       elem.requestFullscreen();
    //     } else if (elem.mozRequestFullScreen) {
    //       /* Firefox */
    //       elem.mozRequestFullScreen();
    //     } else if (elem.webkitRequestFullscreen) {
    //       /* Chrome, Safari & Opera */
    //       elem.webkitRequestFullscreen();
    //     } else if (elem.msRequestFullscreen) {
    //       /* IE/Edge */
    //       elem.msRequestFullscreen();
    //     }
    //   } else {
    //     if (document.exitFullscreen) {
    //       document.exitFullscreen().catch(() => {});
    //     } else if (document.webkitExitFullscreen) {
    //       document.webkitExitFullscreen();
    //     } else if (document.mozCancelFullScreen) {
    //       document.mozCancelFullScreen();
    //     } else if (document.msExitFullscreen) {
    //       document.msExitFullscreen();
    //     }
    //   }
    // },
    IsFullScreen() {
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      );
    },
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
      this.handleResize();
    },
    handleResize() {
      const el = this.$refs.videos;
      if (el) {
        this.pLimitW = el.clientWidth;
        this.pLimitH = el.clientHeight;
      }
    },
    getWebrtcConnections() {
      return getWebrtcConnections();
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getPositionForUuid(uuid) {
      return store.getPositionForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getEmailForUuid(uuid) {
       return store.getEmailForUuid(uuid);
    },
    getDataForHeader(uuid) {
      // FIXME: dont hardcode first item here once we have decent section/location/team logic
      let userList = store.getProcessedUserList();
      let data = [];
      // if (
      //   userList[uuid] &&
      //   userList[uuid].teamSections &&
      //   userList[uuid].teamSections[0]
      // ) {
      //   data.push(userList[uuid].teamSections[0]);
      // }
      // if (this.state.group[uuid].user.locations) {
      //   data.push(this.state.group[uuid].user.locations[0]);
      // }
      // if (
      //   userList[uuid] &&
      //   userList[uuid].memberOfTeams &&
      //   userList[uuid].memberOfTeams[0]
      // ) {
      //   data.push(userList[uuid].memberOfTeams[0]);
      // }

      if (this.state.group[uuid].user.position) {
        data.push(this.state.group[uuid].user.position)
      }

      if(isVisitor(uuid)){
        data.push(this.$t('components.userSettings.guest'))
      }
      if (this.state.group[uuid].user.name) {
        let titel = ''
        if(this.state.group[uuid].user.titel && this.state.group[uuid].user.titel !== 'unknown'){
          titel = this.state.group[uuid].user.titel + ' '
        }
        let name = titel + this.state.group[uuid].user.name
        data.push(name);
      }
      // if(isVisitor(uuid)){
      //   data.push(getSubjectVisitor(uuid))
      // }
      // if(this.ownerUUID === uuid){
      //   // data.push('Moderator')
      // }
      // if (this.state.group[uuid].user.sections) {
      //   data.push(this.state.group[uuid].user.sections[0]);
      // }
      // if (this.state.group[uuid].user.locations) {
      //   data.push(this.state.group[uuid].user.locations[0]);
      // }
      // if (this.state.group[uuid].user.teams) {
      //   data.push(this.state.group[uuid].user.teams[0]);
      // }
      // if (this.state.group[uuid].user.name) {
      //   data.push(this.state.group[uuid].user.name);
      // }

      return data.filter(Boolean).join(" - ");
    },
    toggleLocalMute() {
      this.localMute = !this.localMute;
    },
    triggerIceRestart() {
      triggerIceRestart(this.uuid);
    }
  },
  watch: {
    'callInfo': function () {
      if (this.isConnectionStablished && this.callInfo) {
        if (this.callInfo.isConference && this.callInfo.confId && this.callInfo.ownerConference === this.state.ownUUID && this.callInfo.color !== 'red') {
          const confObj = {
            isConference: this.callInfo.isConference,
            confId: this.callInfo.confId,
            ownerConference: this.callInfo.ownerConference,
            color: 'red'
          };
          store.setUserInConference(confObj);
          dispatchColorChangeTimelineEntry([this.callInfo.confId, 'red']);
        }
      }
    },
    audioDestination() {
      this.changeAudioDestination();
    },
  },
  mounted() {
    this.changeAudioDestination();
  },
  computed: {
    isMyAudioMutedByAdmin() {
      // console.log('is allowed to talk ', this.allowedToTalk.indexOf(this.uuid) != -1)
      // return this.allowedToTalk.indexOf(this.uuid) != -1;
      if (this.notAllowedToTalk.indexOf(this.uuid) !== -1) return true;
      if (this.allowedToTalk.length !== 0 && this.allowedToTalk.indexOf(this.uuid) === -1) return true;
      return false;
    },
    iamOwner(){
      return store.state.ownerMeeting;
    },
    //   hasOnHold() {
    //   if (Object.keys(store.state.remoteStreams).length == 2) {
    //     for (let uuid in store.state.remoteStreams) {
    //       if (store.state.rtc[uuid].onHold) {
    //         this.uuidOnHold = uuid;
    //         return true;
    //       }
    //     }
    //     return false;
    //   }
    // },
    audioDestination() {
      return this.state.persisted.mediaDeviceSetup.audioOutputId;
    },
    emailSalutation () {
      return encodeURIComponent(this.$t('components.userListItem.emailSalutation'));
    },
    transferTargets() {
      const uuids = [];
      for (const uuid of Object.keys(this.state.rtc)) {
        const stat = this.state.rtc[uuid];
        if (stat.onHold) uuids.push(uuid);
      }
      return uuids;
    },
    onHold() {
      if (this.state.rtc[this.uuid]) {
        return this.state.rtc[this.uuid].onHold;
      }
      return false;
    },
    isConnectionStablished(){
      return this.state.rtc[this.uuid].ringingState == 'connected';
    },
    isStable() {
      if (this.state.rtc[this.uuid]) {
        return this.state.rtc[this.uuid].signalingState === 'stable' && this.state.rtc[this.uuid].iceConnectionState === 'connected' && this.state.rtc[this.uuid].iceGatheringState === 'complete';
      }
      return false;
    },
    stats() {
      const o = {};
      const s = this.state.rtc[this.uuid].stats || [];
      // console.log('Stats', s.entries());
      s.forEach(d => (o[d.id] = d));
      return o;
    },
    activeCandidatePair() {
      return Object.values(this.stats).find(
        e =>
          e.type === "candidate-pair" && e.state === "succeeded" && e.nominated && e.writable
      );
    },
    activeCandidates() {
      if (!this.activeCandidatePair) return;
      return {
        local: this.stats[this.activeCandidatePair.localCandidateId],
        remote: this.stats[this.activeCandidatePair.remoteCandidateId]
      };
    },
    isTurn() {
      if (!this.activeCandidates) return false; // Assume no till we know
      if (
        this.activeCandidates.local.candidateType === "relay" ||
        this.activeCandidates.remote.candidateType === "relay"
      )
        return true;
      return false;
    },
    fullCall() {
      return this.state.isCallFull;
    },
    haveRemoteFirstVideo() {
      if (!this.remote) return false;
      if (!this.remote.first) return false;
      for (const track of this.remote.first.getTracks()) {
        if (track.kind === "video") return true;
      }
      return false;
    },
    videoDetailsFirst() {
      try {
        if (!this.remote) return;
        if (!this.remote.first) return;
        if (!this.state.currentTS) return; // For the ref
        return this.remote.first.getVideoTracks()[0].getSettings();
      } catch (err) {
        console.warn("videoDetailsFirst Err:", err.message);
      }
    },
    videoDetailsSecond() {
      try {
        if (!this.remote) return;
        if (!this.remote.second) return;
        if (!this.state.currentTS) return; // For the ref
        return this.remote.second.getVideoTracks()[0].getSettings();
      } catch (err) {
        console.warn("videoDetailsSecond Err:", err.message);
      }
    },
    isPayEnabled() {
      // See also websrc/components/modal/newAdminSettingsModal.vue isPayEnabled
      return webLicensedBaseFeatures.isVisitorPayAvailable;
    },
    isWaitingRoom(){
      return (this.state.group[this.uuid].user.visitorData && this.state.group[this.uuid].user.visitorData.isWaitingRoom)
    },
    isVisitor(){
      return (this.state.group[this.uuid].user.visitor);
    }
    // isFirstAudioMuted() {
    //   if (!this.remote.first) return false;
    //   for (const track of this.remote.first.getTracks()) {
    //     if (track.kind === 'audio') return !track.enabled;
    //   }
    //   return false;
    // },
  }
};
</script>

<style scoped lang="scss">
.lockMessageIcon{
  position: absolute;
  right: -6px;
  top: -10px;
}
.darkGreen{
  color: darkgreen;
}
.lightGreen{
  color: lightgreen;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.cardPositionInfo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  margin: auto;
}

.videoIdTag {
  float: left;
  margin-left: 4px;
  color: white;
  position: absolute;
  top: 32px;
  z-index: 9999;
  -webkit-text-stroke: .06em black;
  font-size: 1em;
  font-family: verdana;
}
.cardPositionInfoLogo {
  position: absolute;
  left: 0;
  right: unset;
  bottom: 5px;
  margin: auto;
}
.opacity {
  opacity: 0.4;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
video {
  display: flex;
  background-color: black;
  border-radius: 3px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
}
.h100 {
  height: 100%;
}
.callerName {
  position: absolute;
  width: 100%;
  color: white;
  // z-index: 2;
  z-index: 99999999;
  height: 30px;
  padding: 6px;
  background-color: linear-gradient;
  background: rgb(21, 30, 39);
  // background: linear-gradient(
  //   0deg,
  //   rgba(21, 30, 39, 0.42) 0%,
  //   rgba(31, 43, 48, 0.43) 100%
  // );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.86) 0%,
    rgba(0, 0, 0, 0.77) 100%
  );
  font-size: 15px;
  text-align: center;
}
.rtcStatus {
  font-size: 10px;
  z-index: 1;
  position: absolute;
  padding: 10px;
  background: #000000b5;
  border-radius: 3px;
  left: 5px;
  bottom: 5px;
  color: white;
}
.green {
  color: green;
}

.theme--light .noVideoStreamContent{
  background-color: #f2f2f2 !important;
}

.theme--dark .noVideoStreamContent{
  background-color: #272727 !important;
}

.videos {
  position: relative;
  height: 100%;
  width: 100%;
  .divButtons {
    display: block;
    position: absolute;
    text-align: center;
    width: 30px;
    height: auto;
    justify-content: center;
    left: 25px;
    top: calc(50% - 90px);
    .redBtn {
      background: red;
      color: white !important;
      border-radius: 4px !important;
    }
  }
  // &:hover {
  //   > .divButtons {
  //     display: block;
  //   }
  // }
  .remote-first {
    width: 100%;
    height: 100%;
    max-height: unset;
    &.sharing {
      position: absolute;
      bottom: 0;
      width: 350px;
      left: 0;
      height: auto;
      animation: width 0.7s linear;
    }
  }
  .remote-second {
    width: 100%;
    height: 100%;
    max-height: unset;
  }
  .noVideoStream {
    position: absolute;
    display: table;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: -10;
    font-weight: bold;
    font-size: 30px;
    .noVideoStreamContent {
      display: table-cell;
      vertical-align: middle;
    }
    .noVideoStreamAvatar {
      width: 160px;
      height: 160px;
      border-radius: 10px;
      margin: 0 auto;
    }
    .v-avatar .v-image {
      border-radius: 10px !important;
    }
    .callAvatarWrapper {
      padding-bottom: 10px;
      img,
      .v-avatar .v-image {
        width: 160px;
        height: 160px;
        border: 4px solid white;
        margin-bottom: 10px !important;
        border-radius: 10px !important;
      }
    }
  }
  .callingText {
    font-weight: normal;
    font-size: 30px;
  }
  #wave {
    margin-top: 25px;
    .dot {
      background: #02889d;
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 3px;
      animation: wave 1.3s linear infinite;
    }
    .dot:nth-child(2) {
      animation-delay: -1.1s;
    }
    .dot:nth-child(3) {
      animation-delay: -0.9s;
    }

    @keyframes wave {
      0%,
      60%,
      100% {
        transform: initial;
      }

      30% {
        transform: translateY(-15px);
      }
    }
  }
}
.bg {
  background-color: black;
  z-index: 999999 !important;
}

.buttonsUser {
  // display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 45px;
  padding-top: 7px !important;
  bottom: 0px;
  position: absolute;
  width: 100%;
  z-index: 9999999999999;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  .pUserName {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0px;
    margin-top: 5px;
    color: white;
  }
  .divButtonsLocal {
    button {
      margin-right: 5px;
      color: white;
      // background: #02889D;
      // border-radius: 4px;
      &:last-of-type {
        margin-right: 0px;
      }
      &.redBtn {
        // background: red;
      }
    }
  }
}

.onHoldOverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color:rgba(163, 163, 163, 0.38)
}

.onHoldCenterIcon {
  position: absolute;
  top: 5%;
  left: 5%;
  // width: 100%;
  // height: 100%;
}
.messageToParticipantsBlink {
  animation: blink 2s linear infinite;
}
.messageToParticipants {
  // font-size: 12px;
  z-index: 1;
  position: absolute;
  padding: 10px;
  background: red;
  border-radius: 3px;
  right: 5px;
  bottom: 55px;
  color: white;
}
</style>