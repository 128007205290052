<template>
  <div ref="test" id ="test">
    <v-content class="px-0 py-0 contentGridWrapper" ref="organisationGridContainer" id="organisationGridContainer">
      <v-row class="mx-0">
        <v-col :cols="amIAdmin && isEditableView ? 10 : 12" class="py-0 px-0">
          <!-- START SECTIONS -->
          <template>
            <v-row class="row d-flex mx-auto">
              <v-col cols="7" class="d-flex justify-start py-0">
                <span class="apartTitle text-white subtitle-1 font-weight-bold mb-0">
                  {{ getApartLabel("sections") }}

                  <v-tooltip top v-if="amIAdmin && currentApart === 'sections' && isEditableView">
                    <template v-slot:activator="{ on }">
                      <font-awesome-icon v-on="on" @click="getIsEditing ? cleanEditing() : setEditing('section')"
                        class="cursorPointer primary--text" :icon="['fal', getIsEditing ? 'times' : 'pen']"
                        :style="{ fontSize: '18px' }" />
                    </template>
                    <span>{{getIsEditing ? $t('components.organisationGrid.cancelEdit') : $t('components.organisationGrid.editTT') }}</span>
                  </v-tooltip>
                </span>
              </v-col>
              <v-col v-if="isEditableView && (currentApart === 'departments' || currentApart === 'sections')" cols="5"
                class="d-flex justify-end py-0">
                <span class="apartTitle text-white subtitle-1 font-weight-bold mb-0">
                  {{ getApartLabel("assistants") }}
                </span>
              </v-col>
            </v-row>
            <v-row class="mx-auto rowHeight">
              <!-- ADD NEW SECTION CARD -->
              <v-col :class="`custom6cols py-1 px-2`" cols="6" v-if="amIAdmin && showPlusSection && isEditableView">
                <draggable class="list-group" ghost-class="ghost" handle=".handle" :list="[sections]" group="people"
                  @change="log($event, 'addSection')">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-card v-on="on" class="cardWidth cursorPointer" :style="{ border: `1px solid #efefef` }">
                        <div class="divCardAdd">
                          <div class="divAdd">
                            <font-awesome-icon :icon="['fal', 'plus-circle']" class="buttonAdd" style="font-size:20px" />
                            <p class="mb-0 mt-2 primary--text px-2">
                              {{$t('components.organisationGrid.addField')}}
                            </p>
                          </div>
                        </div>
                        <v-text-field outlined :label="$t('components.organisationCarousel.headOfDepartment')" class="py-2"
                          dense hide-details v-model="newSectionName"></v-text-field>
                      </v-card>
                    </template>
                    <span>
                      {{$t('components.organisationGrid.dragTT')}}
                    </span>
                  </v-tooltip>
                </draggable>
              </v-col>
              <v-col v-for="(section, name, index) in sections" :key="name" :class="`${
              (currentApart === 'sections' || currentApart === 'departments' || currentApart === 'teams') &&  isEditableView ? 'mr-auto' : null
              } custom6cols py-1 px-2`" cols="6" @mouseenter="selectIndex(`section-${index}`)"
                @mouseleave="ItemIndex = null">
                <!-- EDIT SECTON CARD -->
                <draggable v-if="getIsEditing && getEditingType == 'section'" class="list-group" ghost-class="ghost"
                  handle=".handle" :list="[sections]" group="people" @change="editingItemOrga($event)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-card v-on="on" class="cardWidth cursorPointer" :style="{ border: `1px solid #efefef` }">
                        <div class="divCardAdd d-flex align-center">
                          <div v-for="supervisor in section.supervisors" :key="supervisor.uuid" class="editSupervisorCard" :style="{ backgroundImage: 'url(' + getAvatarByType(supervisor.uuid) + ')' }">
                            <div class="h100 gradientBg justify-center align-center d-flex flex-column text-center">
                              <div class="px-1 pb-2 w100 my-0 white--text text-truncate">{{getNameForUuid(supervisor.uuid)}}</div>
                              <!-- <v-btn x-small fab color="white"  @click="editingItemOrga(supervisor.uuid, true)" v-if="section.supervisors.length >1"><font-awesome-icon color="grey" :style="{ fontSize: '16px' }" :icon="['fal', 'trash']" /></v-btn>  -->
                              <div v-if="section.supervisors.length >1" class="deleteBasketSupervisor d-flex align-center justify-center text-center">
                                <DeleteBasket
                                  :indexData="[supervisor.uuid, true]"
                                  :delFunction="editingItemOrga" extraClasses="" fontSize="16" prevent=false 
                                  :organigramTT="$t('components.organisationGrid.deleteBasketTT')"
                                  />
                                  
                              </div>
                            </div>
                          </div>
                        </div>
                        <v-text-field outlined :label="$t('components.organisationCarousel.section')" class="py-2"
                          dense hide-details v-model="isEditing.name">
                          <template style="margin-top: 12px" v-slot:append>
                            <font-awesome-icon :icon="['fal', 'save']" class="mt-1 cursorPointer"
                              :style="{ fontSize: '15px' }" @click="editingItemOrga()" />
                          </template>
                        </v-text-field>
                      </v-card>
                    </template>
                    <span>{{ $t('components.organisationGrid.editCardTT') }}</span>
                  </v-tooltip>
                </draggable>

                <!-- SECTIONS CARD -->
                <draggable v-if="!getIsEditing || getIsEditing && getEditingType !== 'section'"
                  class="list-group sectionsDrag" ghost-class="ghost" handle=".handle" :list="[sections]"
                  group="people">
                  <div class="cardWidth">
                    <div class="d-flex">
                      <div
                        v-if="amIAdmin && isEditableView && ItemIndex === `section-${index}`"
                        class="deleteBasketOrga d-flex align-center justify-center text-center"
                      >
                        <DeleteBasket
                          :indexData="[{type: 'section', name: name}]"
                          :delFunction="removeStructure" extraClasses="" fontSize="16" prevent=false
                          :organigramTT="$t('components.organisationGrid.deleteBasketTT')"
                          />
                      </div>
                      <!-- no supervisor -->
                      <div v-if="!section.supervisors.length" class='w100'>
                        <v-card style="border: 4px solid rgb(128, 128, 128);">
                            <div>
                              <v-img class="white--text align-end" height="180px">
                                <div class="gradientBg">
                                  <v-card-subtitle style="color: #fff !important"
                                    class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                                    {{ name }}
                                  </v-card-subtitle>
                                  <v-card-subtitle style="color: #fff !important"
                                    class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate subTitleCard">
                                  </v-card-subtitle>
                                </div>
                              </v-img>
                            </div>
                        </v-card>
                      </div>
                      <!-- end no supervisor -->
                      <div
                        v-for="(supervisor, i) in section.supervisors"
                        :key="supervisor.uuid"
                        :class="section.supervisors.length > 1 ? 'w50' : 'w100'"
                      >
                        <v-card
                          @click="!disableCallBtn(getPersonByUuid(supervisor.uuid)) ? callUser(supervisor.uuid) : ''"
                          :style="getBorderCard(section.supervisors, supervisor.uuid, i)">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <div v-on="on">
                                <v-img class="white--text align-end" height="180px"
                                  :src="getAvatarForUuid(supervisor.uuid)">
                                  <!-- <div class="deleteBasketOrga"
                                    v-if="amIAdmin && isEditableView && ItemIndex === `section-${index}`">
                                    <DeleteBasket :indexData="[{type: 'section', name: name}]" :delFunction="removeStructure"
                                      extraClasses="" fontSize="16" prevent=false />
                                  </div> -->
                                  <div class="gradientBg">
                                    <v-card-subtitle style="color: #fff !important"
                                      class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                                      {{ name }}
                                    </v-card-subtitle>
                                    <v-card-subtitle style="color: #fff !important"
                                      class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate subTitleCard">
                                      {{ getTitelForUuid(supervisor.uuid) + " " + getNameForUuid(supervisor.uuid) }}
                                    </v-card-subtitle>
                                    <v-card-subtitle
                                      v-if="hasRepresentative(supervisor.uuid)"
                                      :style="getBorderByStatusFull(getPersonByUuid(getRepresentativeId(supervisor.uuid)))"
                                      class="d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between"
                                      @click.stop.prevent>
                                      <div class="representative-subtitle text-truncate">
                                        <CallIcon :person="getPersonByUuid(getRepresentativeId(supervisor.uuid))"
                                          :isRegularGrid="true"
                                          class="mr-1"></CallIcon>
                                        <span class="colorWhite">
                                          {{ getRepresentativeName(supervisor.uuid) }}
                                        </span>
                                      </div>
                                      <ButtonsFrontCard :isOrga="true" :person="getPersonByUuid(supervisor.uuid)"
                                        :uuid="supervisor.uuid" :sectionData="{ type: 'sections', name: name }"
                                        :isRegularGrid="true" @click.stop.prevent style="position: inherit !important" class="pt-1" />
                                    </v-card-subtitle>
                                    <ButtonsFrontCard v-if="!hasRepresentative(supervisor.uuid)"
                                      :isOrga="true" :person="getPersonByUuid(supervisor.uuid)"
                                      :uuid="supervisor.uuid" :sectionData="{ type: 'sections', name: name }"
                                      :isRegularGrid="true" @click.stop.prevent />
                                  </div>
                                  <ButtonsCardWrapper :showMultiUserCallIcon="true" :isOrganigram="true" :person="getPersonByUuid(supervisor.uuid)" :isRegularGrid="true" />
                                </v-img>
                              </div>
                            </template>
                            <UserInfoTooltip :organisationData="{'name':name}" :person="getPersonByUuid(supervisor.uuid)"></UserInfoTooltip>
                          </v-tooltip>
                        </v-card>
                      </div>
                    </div>
                    <div class="d-flex justify-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <font-awesome-icon
                            v-on="on"
                            @click="handleClicks($event, name, 'sections', `flipSection-${index}`);"
                            :icon="!isSectionSelected ? ['fal', 'angle-down'] : ['fal', 'angle-up']"
                            class="cursorPointer arrowIcon"
                            style="font-size:40px"
                          />
                        </template>
                        <span>
                          {{ $t('components.organisationGrid.arrowIconTT') }}
                        </span>
                      </v-tooltip>
                    </div>
                  </div>
                </draggable>
              </v-col>

              <!-- START SECTION ASSISTANTS -->
              <v-col class="d-flex custom6cols py-1 px-2" cols="6"
                v-if="amIAdmin && currentApart === 'sections' && isEditableView">
                <draggable class="list-group" handle=".handle" ghost-class="ghost" :list="[sections]" group="people"
                  @change="log($event, 'addAssistant')">
                  <v-card class="cardWidth cursorPointer" :style="{ border: `1px solid #efefef` }">
                    <div class="divCardAdd assitants">
                      <div class="divAdd">
                        <font-awesome-icon :icon="['fal', 'plus-circle']" class="buttonAdd" style="font-size:20px" />
                        <p class="mb-0 mt-2 primary--text px-2">
                          {{$t('components.organisationGrid.addField')}}
                        </p>
                      </div>
                    </div>
                  </v-card>
                </draggable>
              </v-col>
              <v-col v-for="(section, name, index) in specificApartAssistants(
              'sections'
            )" :key="name" class="py-1 px-2" cols="6"
                :class="{'assistantsViewMode custom6colsViewing': !isEditableView, 'custom6cols': isEditableView}"
                @mouseenter="selectIndex(`sectionAssistant-${index}`)" @mouseleave="ItemIndex = null">
                <p v-if="!isEditableView && index == 0"
                  class="apartTitleViewMode apartTitle text-white subtitle-1 font-weight-bold mb-0">
                  {{ getApartLabel("assistants") }}
                </p>
                <v-card :class="` cardWidth`" @click="handleClicks($event,null,null,`flipSectionAssistant-${index}`)"
                  :style="getBorderByStatusFull(section)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }"
                      v-on:click="!disableCallBtn(section) ? callUser(section.uuid) : ''">
                      <div v-on="on">
                        <v-img class="white--text align-end" :height="!isEditableView ? '100px' : '180px'"
                          :src="getSupervisorAvatar(section, 'members')">
                          <div class="deleteBasketOrga"
                            v-if="amIAdmin && isEditableView && ItemIndex === `sectionAssistant-${index}`">
                            <DeleteBasket :indexData="[{user: section, type: 'sections'}]"
                              :delFunction="removeAssistant" extraClasses="" fontSize="16" prevent=false 
                              :organigramTT="$t('components.organisationGrid.deleteBasketTT')"
                              />
                          </div>
                          <div class="gradientBg">
                            <v-card-subtitle style="color: #fff !important"
                              class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate"
                              v-if="isEditableView">
                              {{ $t("components.organisationCarousel.assistant") }}
                            </v-card-subtitle>
                            <v-card-subtitle style="color: #fff !important"
                              class="subTitleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                              {{ getSupervisorTitel(section) + " " + getUserName(section) }}
                            </v-card-subtitle>
                            <v-card-subtitle v-if="section.user && hasRepresentative(section.uuid)"
                              :style="getBorderByStatusFull(getPersonByUuid(section.user.representative.value))"
                              class="d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between"
                              @click.stop.prevent>
                              <div class="representative-subtitle text-truncate">
                                <CallIcon :person="getPersonByUuid(section.user.representative.value)"
                                  :isRegularGrid="true"
                                  class="mr-1"></CallIcon>
                                <span class="colorWhite">
                                  {{ getRepresentativeName(section.uuid) }}
                                </span>
                              </div>
                              <ButtonsFrontCard :isOrga="true" :person="section" :uuid="section.uuid" class="pt-1"
                                sectionName="members" :isRegularGrid="true" @click.stop.prevent style="position: inherit !important" />
                            </v-card-subtitle>
                            <ButtonsFrontCard v-if="!section.user || !hasRepresentative(section.uuid)" :isOrga="true" :person="section" :uuid="section.uuid"
                              sectionName="members" :isRegularGrid="true" @click.stop.prevent />
                          </div>
                          <ButtonsCardWrapper :showMultiUserCallIcon="true" :isOrganigram="true" :person="section" :isRegularGrid="true" />
                        </v-img>
                      </div>
                    </template>
                    <UserInfoTooltip :person="section"></UserInfoTooltip>
                  </v-tooltip>
                </v-card>
              </v-col>
              <!-- END SECTION ASSISTANTS -->
            </v-row>
          </template>
          <!-- END SECTIONS -->
          <!-- START DEPARTMENTS -->
          <template v-if="showDepartment || showEmptyDepartments">
            <v-row v-if="showDepartment" class="row d-flex mx-auto">

              <v-col cols="7" class="d-flex justify-start py-0">
                <span class="apartTitle">
                  {{ getApartLabel("departments") }}

                  <v-tooltip top v-if="amIAdmin && currentApart === 'departments' && isEditableView">
                    <template v-slot:activator="{ on }">
                      <font-awesome-icon v-on="on" @click="getIsEditing ? cleanEditing() : setEditing('department')"
                        class="cursorPointer primary--text" :icon="['fal', getIsEditing ? 'times' : 'pen']"
                        :style="{ fontSize: '18px' }" />
                    </template>
                    <span>{{getIsEditing ? $t('components.organisationGrid.cancelEdit') : $t('components.organisationGrid.editTT') }}</span>
                  </v-tooltip>
                </span>
              </v-col>
              <v-col cols="5" class="d-flex justify-end py-0"
                v-if="isEditableView && (currentApart === 'departments' )">
                <!-- || || (showDepartmentAssistants && !hideRepeatedDepartmentAssistants) -->
                <span class="apartTitle text-white subtitle-1 font-weight-bold mb-0">
                  {{ $t("components.organisationCarousel.assistant") }}

                </span>
              </v-col>
            </v-row>
            <v-row class="mx-auto rowHeight">
              <v-col class="custom6cols py-1 px-2" cols="6" v-if="amIAdmin && showPlusDepartment && isEditableView">
                <draggable class="list-group" handle=".handle" ghost-class="ghost" :list="[sections]" group="people"
                  @change="log($event, 'addDepartment')">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-card v-on="on" class="cardWidth cursorPointer" :style="{ border: `1px solid #efefef` }">
                        <div class="divCardAdd">
                          <div class="divAdd">
                            <font-awesome-icon :icon="['fal', 'plus-circle']" class="buttonAdd" style="font-size:20px" />
                            <p class="mb-0 mt-2 primary--text px-2">
                              {{$t('components.organisationGrid.addField')}}
                            </p>
                          </div>
                        </div>
                        <v-text-field outlined :label="$t('components.organisationCarousel.department')"
                          class="py-2" dense hide-details v-model="newDepartmentName"></v-text-field>
                      </v-card>
                    </template>
                    <span>
                      {{$t('components.organisationGrid.dragTT')}}
                    </span>
                  </v-tooltip>
                </draggable>
              </v-col>
              <v-col v-for="(section, name, index) in departments" :key="name" class="custom6cols py-1 px-2`"
                :class="{'mr-auto': (currentApart === 'departments' || currentApart === 'teams') && isEditableView && isDepartmentSelected}"
                cols="6" @mouseenter="selectIndex(`department-${index}`)" @mouseleave="ItemIndex = null">
                <draggable v-if="getIsEditing && getEditingType == 'department'" class="list-group" ghost-class="ghost"
                  handle=".handle" :list="[sections]" group="people" @change="editingItemOrga($event)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-card v-on="on" class="cardWidth cursorPointer" :style="{ border: `1px solid #efefef` }">
                        <div class="divCardAdd d-flex align-center">
                          <div v-for="supervisor in section.supervisors" :key="supervisor.uuid" class="editSupervisorCard" :style="{ backgroundImage: 'url(' + getAvatarByType(supervisor.uuid) + ')' }">
                            <div class="h100 gradientBg justify-center align-center d-flex flex-column text-center">
                              <div class="px-1 pb-2 w100 my-0 white--text text-truncate">{{getNameForUuid(supervisor.uuid)}}</div>
                               <div v-if="section.supervisors.length >1" class="deleteBasketSupervisor d-flex align-center justify-center text-center">
                                <DeleteBasket
                                  :indexData="[supervisor.uuid, true]"
                                  :delFunction="editingItemOrga" extraClasses="" fontSize="16" prevent=false 
                                  :organigramTT="$t('components.organisationGrid.deleteBasketTT')"
                                  />
                              </div>
                              <!-- <v-btn x-small fab color="white"  @click="editingItemOrga(supervisor.uuid, true)" v-if="section.supervisors.length >1"><font-awesome-icon color="grey" :style="{ fontSize: '16px' }" :icon="['fal', 'trash']" /></v-btn>  -->
                            </div>
                          </div>
                        </div>
                        <v-text-field outlined :label="$t('components.organisationCarousel.department')"
                          class="py-2" dense hide-details v-model="isEditing.name">
                          <template style="margin-top: 12px" v-slot:append>
                            <font-awesome-icon :icon="['fal', 'save']" class="mt-1 cursorPointer"
                              :style="{ fontSize: '15px' }" @click="editingItemOrga()" />
                          </template>
                        </v-text-field>
                      </v-card>
                    </template>
                    <span>{{ $t('components.organisationGrid.editCardTT') }}</span>
                  </v-tooltip>
                </draggable>

                <draggable v-if="!getIsEditing || getIsEditing && getEditingType !== 'department'" ghost-class="ghost"
                  class="list-group departmentsDrag" :list="[sections]" group="people"
                  @end="checkMove($event, name, section.section, 'moveDepartment')">
                  <div class="cardWidth">
                    <div class="d-flex">
                      <div class="gradientTop">
                        <v-card-subtitle style="color: #fff !important"
                          class="titleCard colorWhite pt-1 pb-1 px-2 text-truncate">
                          {{section.section}}
                        </v-card-subtitle>
                      </div>
                      <div
                        v-if="amIAdmin && isEditableView && ItemIndex === `department-${index}`"
                        class="deleteBasketOrga d-flex align-center justify-center text-center"
                      >
                        <DeleteBasket
                          :indexData="[{type: 'department', name: name, parent: section.section}]"
                          :delFunction="removeStructure" extraClasses="" fontSize="16" prevent=false
                          :organigramTT="$t('components.organisationGrid.deleteBasketTT')"
                        />
                      </div>
                      <!-- no supervisor -->
                      <div v-if="!section.supervisors.length" class='w100'>
                        <v-card style="border: 4px solid rgb(128, 128, 128);">
                          <div>
                            <v-img class="white--text align-end" height="180px">
                              <div class="gradientBg">
                                <v-card-subtitle style="color: #fff !important"
                                  class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                                  {{
                            !section.uuid
                              ? name
                              : `${$t(
                                  "components.organisationCarousel.assistant"
                                )} - ${getAssistantSection}`
                            }}
                                </v-card-subtitle>
                              </div>
                            </v-img>
                          </div>
                        </v-card>
                      </div>
                      <!-- end no supervisor -->
                      <div v-for="(supervisor, i) in section.supervisors" :key="supervisor.uuid" :class="section.supervisors.length > 1 ? 'w50' : 'w100'">
                        <v-card
                          @click="!disableCallBtn(getPersonByUuid(supervisor.uuid)) ? callUser(supervisor.uuid) : ''"
                          :style="getBorderCard(section.supervisors, supervisor.uuid, i)">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }" v-on:click="!disableCallBtn(section) ? callUser(name) : ''">
                              <div v-on="on">
                                <v-img class="white--text align-end" height="180px" :src="supervisor.uuid ? getAvatarForUuid(supervisor.uuid) : null"
                                  :class="{'repeateadImage': isUserRepeated(section, 'departments')}">
                                  <div class="gradientBg">
                                    <v-card-subtitle style="color: #fff !important"
                                      class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                                      {{
                                !section.uuid
                                  ? name
                                  : `${$t(
                                      "components.organisationCarousel.assistant"
                                    )} - ${getAssistantSection}`
                                      }}
                                    </v-card-subtitle>
                                    <v-card-subtitle style="color: #fff !important"
                                      class="subTitleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                                      {{
                                section.uuid
                                  ? getTitelForUuid(supervisor.uuid) +
                                    " " +
                                    getNameForUuid(supervisor.uuid)
                                  : getTitelForUuid(supervisor.uuid) +
                                    " " +
                                    getNameForUuid(supervisor.uuid)
                                      }}
                                    </v-card-subtitle>
                                    <v-card-subtitle v-if="hasRepresentative(supervisor.uuid)"
                                      :style="getBorderByStatusFull(getPersonByUuid(getRepresentativeId(supervisor.uuid)))"
                                      class="d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between"
                                      @click.stop.prevent>
                                      <div class="representative-subtitle text-truncate">
                                        <CallIcon :person="getPersonByUuid(getRepresentativeId(supervisor.uuid))"
                                          :isRegularGrid="true"
                                          class="mr-1"></CallIcon>
                                        <span class="colorWhite">
                                          {{ getRepresentativeName(supervisor.uuid) }}
                                        </span>
                                      </div>
                                      <ButtonsFrontCard 
                                        :checkMove="checkMove"
                                        :isMovingDepa="true"
                                        :isMovingTeam="false"
                                        :isEditableView="isEditableView"
                                        :nameSectionToMove="name"
                                        :parentToMove="section.section"
                                        :moveType="'moveDepartment'"
                                        :isOrga="true"
                                        :person="section.uuid ? section : getPersonByUuid(supervisor.uuid)"
                                        :uuid="section.uuid ? name : supervisor.uuid"
                                        :sectionData="section.uuid ? undefined : { type: 'departments', name: name }"
                                        :isRegularGrid="true" v-if="section.uuid ? true : !isUserRepeated(section, 'departments')" @click.stop.prevent
                                        style="position: inherit !important" class="pt-1" />
                                    </v-card-subtitle>
                                    <ButtonsFrontCard 
                                      :checkMove="checkMove"
                                      :isMovingDepa="true"
                                      :isMovingTeam="false"
                                      :isEditableView="isEditableView"
                                      :nameSectionToMove="name"
                                      :parentToMove="section.section"
                                      :moveType="'moveDepartment'"
                                      :isOrga="true"
                                      :person="section.uuid ? section : getPersonByUuid(supervisor.uuid)"
                                      :uuid="section.uuid ? name : supervisor.uuid"
                                      :sectionData="section.uuid ? undefined : { type: 'departments', name: name }"
                                      :isRegularGrid="true" 
                                      v-if="!hasRepresentative(supervisor.uuid) && (section.uuid ? true : !isUserRepeated(section, 'departments'))"
                                      @click.stop.prevent />
                                  </div>
                                  <ButtonsCardWrapper :showMultiUserCallIcon="true" :customTop="true" :isOrganigram="true" :person="getPersonByUuid(supervisor.uuid)" :isRegularGrid="true" />
                                </v-img>
                              </div>
                            </template>
                            <UserInfoTooltip :organisationData="{'name':name} " :person="section.uuid ? section : getPersonByUuid(supervisor.uuid)">
                            </UserInfoTooltip>
                          </v-tooltip>
                        </v-card>

                      </div>
                    </div>
                    <div class="d-flex justify-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <font-awesome-icon
                            v-on="on"
                            @click="handleClicks($event,section.uuid ? null : name,section.uuid ? null : 'departments',`flipDepartment-${index}`)"
                            :icon="!isDepartmentSelected ? ['fal', 'angle-down'] : ['fal', 'angle-up']"
                            class="cursorPointer arrowIcon"
                            style="font-size:40px"
                          />
                        </template>
                        <span>
                          {{ $t('components.organisationGrid.arrowIconTT') }}
                        </span>
                      </v-tooltip>
                    </div>
                  </div>
                </draggable>
              </v-col>
              <!-- START DEPARTMENT ASSISTANTS || !hideRepeatedDepartmentAssistants-->
              <template v-if="(currentApart == 'departments' || currentApart == 'teams') && (isDepartmentSelected || isTeamSelected)">
                <v-col class="custom6cols py-1 px-2" cols="6"
                  v-if="amIAdmin && showPlusDepartmentAssistant && isEditableView">
                  <draggable class="list-group" ghost-class="ghost" handle=".handle" :list="[sections]" group="people"
                    @change="log($event, 'addDepartmentAssistant')">
                    <v-card class="cardWidth cursorPointer" :style="{ border: `1px solid #efefef` }">
                      <div class="divCardAdd assitants">
                        <div class="divAdd">
                          <font-awesome-icon :icon="['fal', 'plus-circle']" class="buttonAdd" style="font-size:20px" />
                          <p class="mb-0 mt-2 primary--text px-2">
                            {{$t('components.organisationGrid.addField')}}
                          </p>
                        </div>
                      </div>
                    </v-card>

                  </draggable>
                </v-col>

                <v-col v-for="(section, name, index) in specificApartAssistants('departments')" :key="name"
                  class="py-1 px-2" cols="6" @mouseenter="selectIndex(`depaAssistant-${index}`)"
                  :class="{'assistantsViewMode custom6colsViewing': !isEditableView, 'custom6cols': isEditableView}"
                  @mouseleave="ItemIndex = null">
                  <p v-if="!isEditableView && index == 0"
                    class="apartTitleViewMode apartTitle text-white subtitle-1 font-weight-bold mb-0">
                    {{ getApartLabel("assistants") }}
                  </p>
                  <v-card :class="` cardWidth`"
                    @click="handleClicks($event,null,null,`flipDepartmentAssistant-${index}`)"
                    :style="getBorderByStatusFull(section)">
                    <!-- :style="`${hasNoImage(getSupervisorAvatar(section, 'members')) ? 'border: 4px solid black' : getBorderByStatusFull(section)}`"> -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }"
                        v-on:click="!disableCallBtn(section) ? callUser(section.uuid) : ''">
                        <div v-on="on">
                          <v-img class="white--text align-end" :height="!isEditableView ? '100px' : '180px'"
                            :src="getSupervisorAvatar(section, 'members')">
                            <div class="deleteBasketOrga"
                              v-if="amIAdmin && isEditableView && ItemIndex === `depaAssistant-${index}`">
                              <DeleteBasket :indexData="[{user: section, type:'department'}]"
                                :delFunction="removeAssistant" extraClasses="" fontSize="16" prevent=false 
                                :organigramTT="$t('components.organisationGrid.deleteBasketTT')"
                                />
                            </div>

                            <div class="gradientBg">
                              <v-card-subtitle style="color: #fff !important"
                                class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate"
                                v-if="isEditableView">
                                {{ getApartLabel("assistants") }}

                              </v-card-subtitle>
                              <v-card-subtitle style="color: #fff !important"
                                class="subTitleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                                {{
                            getSupervisorTitel(section) +
                            " " +
                            getUserName(section)
                          }}
                              </v-card-subtitle>
                              <v-card-subtitle v-if="section.user && hasRepresentative(section.uuid)"
                                               :style="getBorderByStatusFull(getPersonByUuid(section.user.representative.value))"
                                               class="d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between"
                                               @click.stop.prevent>
                                <div class="representative-subtitle text-truncate">
                                  <CallIcon :person="getPersonByUuid(section.user.representative.value)"
                                            :isRegularGrid="true"
                                            class="mr-1"></CallIcon>
                                  <span class="colorWhite">
                                    {{ getRepresentativeName(section.uuid) }}
                                  </span>
                                </div>
                                <ButtonsFrontCard :isOrga="true" :person="section" :uuid="section.uuid"
                                                  :isRegularGrid="true" @click.stop.prevent
                                                  style="position: inherit !important" class="pt-1" />
                              </v-card-subtitle>
                              <ButtonsFrontCard v-if="!section.user || !hasRepresentative(section.uuid)" :isOrga="true" :person="section" :uuid="section.uuid"
                                :isRegularGrid="true" @click.stop.prevent />
                            </div>
                            <ButtonsCardWrapper :showMultiUserCallIcon="true" :isOrganigram="true" :person="section" :isRegularGrid="true" />
                          </v-img>
                        </div>
                      </template>
                      <UserInfoTooltip :person="section"></UserInfoTooltip>
                    </v-tooltip>
                  </v-card>
                </v-col>
              </template>
              <!-- END DEPARTMENT ASSISTANTS -->
            </v-row>
          </template>
          <!-- END DEPARTMENTS -->
          <!-- START TEAMS -->
          <template v-if="showTeam || showEmptyTeams">
            <div v-if="showTeam" class="mx-auto titleWrapper px-2">

              <span class="apartTitle">
                {{ getApartLabel("teams") }}

                <v-tooltip top v-if="amIAdmin && currentApart === 'teams' && isEditableView">
                  <template v-slot:activator="{ on }">
                    <font-awesome-icon v-on="on" @click="getIsEditing ? cleanEditing() : setEditing('team')"
                      class="cursorPointer primary--text" :icon="['fal', getIsEditing ? 'times' : 'pen']"
                      :style="{ fontSize: '18px' }" />
                  </template>
                  <span>{{getIsEditing ? $t('components.organisationGrid.cancelEdit') : $t('components.organisationGrid.editTT') }}</span>
                </v-tooltip>
              </span>
            </div>
            <v-row class="mx-auto rowHeight">
              <!-- v-if="Object.keys(teams).length > 0" -->
              <v-col :class="`custom6cols py-1 px-2`" cols="6" v-if="amIAdmin && showPlusTeam && isEditableView">
                <draggable class="list-group" ghost-class="ghost" handle=".handle" :list="[sections]" group="people"
                  @change="log($event, 'addTeam')">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-card v-on="on" class="cardWidth cursorPointer" :style="{ border: `1px solid #efefef` }">
                        <div class="divCardAdd">
                          <div class="divAdd">
                            <font-awesome-icon :icon="['fal', 'plus-circle']" class="buttonAdd" style="font-size:20px" />
                            <p class="mb-0 mt-2 primary--text px-2">
                              {{$t('components.organisationGrid.addField')}}
                            </p>
                          </div>
                        </div>
                        <v-text-field outlined :label="$t('components.organisationCarousel.team')" class="py-2"
                          dense hide-details v-model="newTeamName"></v-text-field>
                      </v-card>
                    </template>
                    <span>
                      {{$t('components.organisationGrid.dragTT')}}
                    </span>
                  </v-tooltip>
                </draggable>
              </v-col>
              <v-col v-for="(section, name, index) in teams" :key="name" class="custom6cols py-1 px-2" cols="6"
                @mouseenter="selectIndex(`team-${index}`)" @mouseleave="ItemIndex = null">
                <draggable v-if="getIsEditing && getEditingType == 'team'" class="list-group" ghost-class="ghost"
                  handle=".handle" :list="[sections]" group="people" @change="editingItemOrga($event)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-card v-on="on" class="cardWidth cursorPointer" :style="{ border: `1px solid #efefef` }">
                        <div class="divCardAdd d-flex align-center">
                          <div v-for="supervisor in section.supervisors" :key="supervisor.uuid" class="editSupervisorCard" :style="{ backgroundImage: 'url(' + getAvatarByType(supervisor.uuid) + ')' }">
                            <div class="h100 gradientBg justify-center align-center d-flex flex-column text-center">
                              <div class="px-1 pb-2 w100 my-0 white--text text-truncate">{{getNameForUuid(supervisor.uuid)}}</div>
                               <div v-if="section.supervisors.length >1" class="deleteBasketSupervisor d-flex align-center justify-center text-center">
                                <DeleteBasket
                                  :indexData="[supervisor.uuid, true]"
                                  :delFunction="editingItemOrga" extraClasses="" fontSize="16" prevent=false 
                                  :organigramTT="$t('components.organisationGrid.deleteBasketTT')"
                                  />
                              </div>
                              <!-- <v-btn x-small fab color="white"  @click="editingItemOrga(supervisor.uuid, true)" v-if="section.supervisors.length >1"><font-awesome-icon color="grey" :style="{ fontSize: '16px' }" :icon="['fal', 'trash']" /></v-btn>  -->
                            </div>
                          </div>
                        </div>
                        <v-text-field outlined :label="$t('components.organisationCarousel.team')" class="py-2"
                          dense hide-details v-model="isEditing.name">
                          <template style="margin-top: 12px" v-slot:append>
                            <font-awesome-icon :icon="['fal', 'save']" class="mt-1 cursorPointer"
                              :style="{ fontSize: '15px' }" @click="editingItemOrga()" />
                          </template>
                        </v-text-field>
                      </v-card>
                    </template>
                    <span>{{ $t('components.organisationGrid.editCardTT') }}</span>
                  </v-tooltip>
                </draggable>
                <draggable v-if="!getIsEditing || getIsEditing && getEditingType !== 'team'"
                  class="list-group teamsDrag" :list="[sections]" ghost-class="ghost" group="people"
                  @end="checkMove($event, name, getDepartmentName(section, 'teams'), 'moveTeam')">
                  <div class="cardWidth">
                    <div class="d-flex">
                    <div class="gradientTop" v-if="section.department && section.department !== '' ">
                      <v-card-subtitle style="color: #fff !important"
                        class="titleCard colorWhite pt-1 pb-1 px-2 text-truncate">
                        {{getDepartmentName(section, 'teams')}}
                      </v-card-subtitle>
                    </div>
                    <div
                      v-if="amIAdmin && isEditableView && ItemIndex === `team-${index}`"
                      class="deleteBasketOrga d-flex align-center justify-center text-center"
                    >
                      <DeleteBasket
                        :indexData="[{type: 'team', name: name, parent: getDepartmentName(section, 'teams')}]"
                        :delFunction="removeStructure" extraClasses="" fontSize="16" prevent=false 
                        :organigramTT="$t('components.organisationGrid.deleteBasketTT')"
                        />
                    </div>
                    <!-- no supervisor -->
                      <div v-if="!section.supervisors.length" class='w100'>
                        <v-card style="border: 4px solid rgb(128, 128, 128);">
                          <div>
                            <v-img class="white--text align-end" height="180px">
                              <div class="gradientBg">
                               <v-card-subtitle style="color: #fff !important"
                                    class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                                    {{ name }}
                                </v-card-subtitle>
                              </div>
                            </v-img>
                          </div>
                        </v-card>
                      </div>
                      <!-- end no supervisor -->
                    <div v-for="(supervisor, i) in section.supervisors" :key="supervisor.uuid" :class="section.supervisors.length > 1 ? 'w50' : 'w100'">
                      <v-card
                        @click="!disableCallBtn(getPersonByUuid(supervisor.uuid)) ? callUser(supervisor.uuid) : ''"
                        :style="getBorderCard(section.supervisors, supervisor.uuid, i)">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }" v-on:click="!disableCallBtn(section) ? callUser(name) : ''">
                            <div v-on="on">
                              <v-img class="white--text align-end" height="180px" :src="getAvatarForUuid(supervisor.uuid)"
                                :class="{'repeateadImage': isUserRepeated(section, 'teams')}">
                                <!-- <div v-if="amIAdmin && isEditableView && ItemIndex === `team-${index}`"
                                  class="deleteBasketOrga">
                                  <DeleteBasket
                                    :indexData="[{type: 'team', name: name, parent: getDepartmentName(section, 'teams')}]"
                                    :delFunction="removeStructure" extraClasses="" fontSize="16" prevent=false />
                                </div> -->
                                <!-- <div class="gradientTop" v-if="section.department && section.department !== '' ">
                                  <v-card-subtitle style="color: #fff !important"
                                    class="titleCard colorWhite pt-1 pb-1 px-2 text-truncate">
                                    {{getDepartmentName(section, 'teams')}}
                                  </v-card-subtitle>
                                </div> -->
                                <div class="gradientBg">
                                  <v-card-subtitle style="color: #fff !important"
                                                   class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                                    {{ name }}
                                  </v-card-subtitle>
                                  <v-card-subtitle style="color: #fff !important"
                                                   class="subTitleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                                    {{
                                getTitelForUuid(supervisor.uuid) +
                                " " +
                                getNameForUuid(supervisor.uuid)
                              }}
                                  </v-card-subtitle>
                                  <v-card-subtitle v-if="hasRepresentative(supervisor.uuid)"
                                    :style="getBorderByStatusFull(getPersonByUuid(getRepresentativeId(supervisor.uuid)))"
                                    class="d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between"
                                    @click.stop.prevent>
                                    <div class="representative-subtitle text-truncate">
                                      <CallIcon :person="getPersonByUuid(getRepresentativeId(supervisor.uuid))"
                                      :isRegularGrid="true"
                                      class="mr-1"></CallIcon>
                                      <span class="colorWhite">
                                        {{ getRepresentativeName(supervisor.uuid) }}
                                      </span>
                                    </div>
                                    <ButtonsFrontCard
                                      :checkMove="checkMove"
                                      :isMovingDepa="false"
                                      :isMovingTeam="true"
                                      :nameSectionToMove="name"
                                      :parentToMove="getDepartmentName(section, 'teams')"
                                      :moveType="'moveTeam'"
                                      :isEditableView="isEditableView"
                                      :isOrga="true"
                                      :person="getPersonByUuid(supervisor.uuid)"
                                      :uuid="supervisor.uuid"
                                      :sectionData="{ type: 'teams', name: name, data:section }" :isRegularGrid="true"
                                      style="position: inherit !important"
                                      class="pt-1"
                                      @click.stop.prevent />
                                  </v-card-subtitle>
                                  <ButtonsFrontCard 
                                    v-if="!hasRepresentative(supervisor.uuid)"
                                    :checkMove="checkMove"
                                    :isMovingDepa="false"
                                    :isMovingTeam="true"
                                    :nameSectionToMove="name"
                                    :parentToMove="getDepartmentName(section, 'teams')"
                                    :moveType="'moveTeam'"
                                    :isEditableView="isEditableView"
                                    :isOrga="true"
                                    :person="getPersonByUuid(supervisor.uuid)"
                                    :uuid="supervisor.uuid"
                                    :sectionData="{ type: 'teams', name: name, data:section }" :isRegularGrid="true"
                                    @click.stop.prevent />
                                  <!-- v-if="!isUserRepeated(section, 'teams')" -->
                                </div>
                                <ButtonsCardWrapper :showMultiUserCallIcon="true" :customTop="true" :isOrganigram="true" :person="getPersonByUuid(supervisor.uuid)" :isRegularGrid="true" />
                              </v-img>
                            </div>
                          </template>
                          <UserInfoTooltip :organisationData="{'name':name} "  :person="getPersonByUuid(supervisor.uuid)"></UserInfoTooltip>
                        </v-tooltip>
                      </v-card>

                    </div>
                    </div>
                    <div class="d-flex justify-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <font-awesome-icon
                            v-on="on"
                            @click="handleClicks($event, name, 'teams', `flipTeams-${index}`)"
                            :icon="!isTeamSelected ? ['fal', 'angle-down'] : ['fal', 'angle-up']"
                            class="cursorPointer arrowIcon"
                            style="font-size:40px"
                          />
                        </template>
                        <span>
                          {{ $t('components.organisationGrid.arrowIconTT') }}
                        </span>
                      </v-tooltip>
                    </div>
                  </div>
                </draggable>
              </v-col>
            </v-row>
          </template>
          <!-- END TEAMS -->
          <!-- START MEMBERS AND ASSISTANTS -->
          <template v-if="showMembers">
            <div v-if="
            showMembers
          " class="mx-auto titleWrapper px-2">

              <span class="apartTitle">
                {{ getApartLabel("members") }}

              </span>
            </div>

            <v-row class="mx-auto rowHeight">
              <!-- MEMBERS -->
              <v-col class="custom6cols py-1 px-2" cols="6" v-if="amIAdmin && showPlusMember && isEditableView">
                <draggable class="list-group" ghost-class="ghost" handle=".handle" :list="[sections]" group="people"
                  @change="log($event, 'addMember')">
                  <v-card class="cardWidth cursorPointer" :style="{ border: `1px solid #efefef` }">
                    <div class="divCardAdd assitants">
                      <div class="divAdd">
                        <font-awesome-icon :icon="['fal', 'plus-circle']" class="buttonAdd" style="font-size:20px" />
                        <p class="mb-0 mt-2 primary--text px-2">
                          {{$t('components.organisationGrid.addField')}}
                        </p>
                      </div>
                    </div>
                  </v-card>

                </draggable>
              </v-col>
              <!-- :class="`${showLargeUser == section.uuid ? 'custom6colsLargeUser' : 'custom6cols'}`" -->
              <v-col v-click-outside="closeLargeUser" v-for="(section, name, index) in members" :key="name"  class="custom6cols py-1 px-2" cols="6"
                @mouseenter="selectIndex(`members-${index}`)" @mouseleave="ItemIndex = null">
                <draggable class="list-group memberDrag" :list="[sections]"
                  :group="{ name: 'people', pull: 'clone', put: false }"
                  @end="checkMove($event, section, '', 'moveMember')">
                  <v-card :class="`${showLargeUser == section.uuid ? 'cardWidthLargeUser' : 'cardWidth'}`" @click="handleClicks($event, null, null, `flipMembers-${index}`); (!disableCallBtn(section) ? callUser(section.uuid) : '')"
                     :style="getBorderByStatusFull(section)">
                     <!-- :style="`${hasNoImage(getSupervisorAvatar(section, 'members')) ? 'border: 4px solid black' : getBorderByStatusFull(section)}`"> -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <!-- :height="`${showLargeUser == section.uuid ? '215px' : '180px'}` -->
                          <v-img class="white--text align-end" height="180px"
                            :src="getSupervisorAvatar(section, 'members')">
                            <div class="deleteBasketOrga"
                              v-if="amIAdmin && isEditableView && ItemIndex === `members-${index}`">
                              <DeleteBasket :indexData="section" :delFunction="deleteMember" extraClasses=""
                                fontSize="16" prevent=false 
                                :organigramTT="$t('components.organisationGrid.deleteBasketTT')"
                                />
                            </div>
                            <div class="gradientBg">
                              <v-card-subtitle style="color: #fff !important"
                                class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                                {{ getMemberPosition(section) }}
                              </v-card-subtitle>
                              <v-card-subtitle style="color: #fff !important"
                                class="subTitleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                                {{
                            getSupervisorTitel(section) +
                            " " +
                            getUserName(section)
                          }}
                              </v-card-subtitle>
                              <v-card-subtitle v-if="section.user && hasRepresentative(section.uuid)"
                                                :style="getBorderByStatusFull(getPersonByUuid(section.user.representative.value))"
                                                class="d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between"
                                                @click.stop.prevent>
                                <div class="representative-subtitle text-truncate">
                                  <CallIcon :person="getPersonByUuid(section.user.representative.value)"
                                            :isRegularGrid="true"
                                            class="mr-1"></CallIcon>
                                  <span class="colorWhite">
                                    {{ getRepresentativeName(section.uuid) }}
                                  </span>
                                </div>
                                <ButtonsFrontCard :teamInfo="currentCardName" :isOrga="true" :person="section" :uuid="section.uuid"
                                  sectionName="members" :sectionData="{ type: 'members', name: name, data:section }" :isRegularGrid="true" style="position: inherit !important" class="pt-1" @click.stop.prevent />
                              </v-card-subtitle>
                              <ButtonsFrontCard v-if="!hasRepresentative(section.uuid)" :teamInfo="currentCardName" :isOrga="true" :person="section" :uuid="section.uuid"
                                sectionName="members" :sectionData="{ type: 'members', name: name, data:section }" :isRegularGrid="true" @click.stop.prevent />
                            </div>
                            <ButtonsCardWrapper :showMultiUserCallIcon="true" :isOrganigram="true" :person="section" :isRegularGrid="true" />
                          </v-img>
                        </div>
                      </template>
                      <UserInfoTooltip :person="section"></UserInfoTooltip>
                    </v-tooltip>
                  </v-card>
                </draggable>
              </v-col>
            </v-row>
          </template>
          <!-- END MEMBERS -->
        </v-col>
        <v-col cols="2" class="py-0 px-0 listUsers" v-if="amIAdmin && isEditableView">
          <div class="header">
            <v-text-field dense single-line hide-details :label="$t('generics.search')" outlined v-model="searchUsers"
              class="py-2" clearable @click:clear="searchUsers = ''">

              <font-awesome-icon :icon="['fal', 'search']" slot="append" :style="{ fontSize: '18px' }" />
            </v-text-field>
          </div>
          <v-row class="mx-auto rowUsers pa-3">
            <v-list>
              <draggable style="width:100%" :list="usersSearchF" :group="{ name: 'people', pull: 'clone', put: false }"
                @change="log($event, 'userList')">
                <v-list-item class="my-1" v-for="(person, index) in usersSearchF" :key="index">
                  <v-list-item-avatar>
                    <v-img contain class="white--text align-end" height="80" :src="getAvatarForUuid(person.uuid)">
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-title>
                    {{person.name}}
                  </v-list-item-title>
                </v-list-item>
              </draggable>
            </v-list>

          </v-row>
        </v-col>
      </v-row>
      <MoveOrgaItemModal v-if="amIAdmin && isEditableView" :closeModal="closeModalMove" :destination="destination"
        :moveItem="moveItemF" :itemToMove="moveItem.name" :parent="moveItem.parent"
        :parentSection="moveItem.parentSection" :showModalMoveItem="showModalMoveItem" />
      <v-dialog :value="structureToDeleteType != null" max-width="550px">
        <v-card>
          <v-card-title class="headline" v-if="structureToDeleteType">
            <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
            {{ $t(`components.manageOrganisation.${structureToDeleteType}.delete`) }}
          </v-card-title>
          <v-card-text v-if="structureToDeleteType">
            <p>{{ $t(`components.manageOrganisation.modalDelete`, [structureToDelete != null ? structureToDelete[structureToDeleteType] : ""]) }} </p>
            <span>{{ $t(`components.manageOrganisation.confirmDelete`) }}</span>
            <div style="position: relative; width: 100%; padding-top: 5px;">
              <v-text-field ref="repeatStructureName"
                :placeholder="structureToDelete != null ? structureToDelete[structureToDeleteType] : ''"
                v-model="repeatStructureName" @keyup.enter="tryDeletion" />

            </div>
          </v-card-text>
          <v-card color="red" class="py-2 pl-4 mt-4 pr-2 footerModal">
            <v-row class="px-2 btns mx-0" flex>
              <v-col cols="8" class="px-0 py-0" align="right">
                <v-btn @click="deteleStructureAndSub" :disabled="isDeleteDisabled">{{ $t('generics.delete') }}</v-btn>
                <v-btn @click="structureToDeleteType = null">{{ $t('components.manageOrganisation.abort') }}</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-dialog>
    </v-content>
  </div>
</template>

<script>
  import {
    webLicensedBaseFeatures
  } from '../../../sharedsrc/licensedFeatures';
  import store, { EventBus } from "../../store";
  import {
    dispatchSuccessAlert,
    dispatchErrorAlert,
  } from "../../effector/alerts";
  import Vue from "vue";
  import { hasRepresentative } from "../../utils/representatives";
  import BackOrganisationCard from "./backOrganisationCard.vue";
  import ButtonsFrontCard from "./buttonsFrontCard.vue";
  import DefaultAvatarGrid from "../image/defaultAvatarGrid.vue";
  import {
    callPerson,
    disableCallBtn
  } from "../../utils/calls";
  import {
    getStatusColorByUser
  } from "../../utils/status";
  import InProgressModal from "../modal/inProgressModal.vue";
  import {
    getPictureSize,
    canIShowOrganigram,
    getBorderByStatusFull,
    getCustomBorderByStatusFull,
    getAvatarByType,
    getUserOrgaData,
    userInOrganigram
  } from "../../utils/basicFunctions";
  import UserInfoTooltip from "./userInfoTooltip.vue";
  import draggable from "vuedraggable";
  import {
    importUsersParsed,
    setNamespaceSetting
  } from "../../lib/wsMsg";
  import ButtonsCardWrapper from "./buttonsCardWrapper.vue";
  import {
    entries,
    cloneDeep,
    sortBy
  } from "lodash-es";
  import DeleteBasket from "../ui/deleteBasket.vue";
  import MoveOrgaItemModal from "../modal/moveOrgaItemModal.vue";
  import ClickOutside from "vue-click-outside";
  import CallIcon from "./callIcon.vue";
  /* eslint-disable no-prototype-builtins, no-useless-return */
  export default {
     directives: {
    ClickOutside,
  },
    components: {
      draggable,
      DeleteBasket,
      BackOrganisationCard,
      DefaultAvatarGrid,
      InProgressModal,
      UserInfoTooltip,
      ButtonsCardWrapper,
      ButtonsFrontCard,
      MoveOrgaItemModal,
      CallIcon
    },
    data() {
      return {
        test: false,
        state: store.state,
        ownUUID: store.state.ownUUID,
        showAllCards: false,
        sectionsList: {},
        departmentsList: {},
        teamsList: {},
        membersList: {},
        sectionsListOriginal: store.state.namespaceSettings.processedOrganisation.sections,
        departmentsListOriginal: store.state.namespaceSettings.processedOrganisation.departments,
        teamsListOriginal: store.state.namespaceSettings.processedOrganisation.teams,
        membersListOriginal: {},
        assistantsList: {},
        currentApart: "",
        currentCard: {},
        currentCardName: '',
        membersToShow: 6,
        teamsToShow: 12,
        showMembers: false,
        teamSelected: false,
        // variables to set cards after selected a team
        teamSelectedSectionCard: {},
        teamSelectedDepartmentCard: {},
        teamSelectedTeamCard: {},
        doubleClick: null,
        cardToFlip: null,
        initViewCardSeletected: {
          selected: false,
          apartName: "",
          apart: {},
        },
        showTeam: false,
        showDepartment: true,
        // initAdminView: true,
        showDialog: true,
        singleSectionDisabled: false,
        // showAllSectionsVisibility does not exists anymore
        // showAllSectionsVisibility: store.state.namespaceSettings.showAllSectionsVisibility,
        showAllSectionsVisibility: false,
        showEmptyTeams: false,
        showEmptyDepartments: false,
        latestSectionsList: {},
        latestDepartmentsList: {},

        actualUser: this.$route.params.uuid || store.state.ownUUID,
        setCurrentContentVisile: store.setCurrentContentVisile,
        showHidePanel: false,
        showAddSection: false,
        newSectionName: '',
        showAddAssistant: false,
        showAssistans: false,
        showAddDepartment: false,
        newDepartmentName: '',
        showAddTeam: false,
        newTeamName: '',
        organisation: [],
        selectedSection: {},
        selectedDepartment: {},
        selectedTeam: {},
        searchUsers: '',
        showAddMember: false,
        showAddDepartmentAssistant: false,
        structureToDeleteType: null,
        repeatStructureName: '',
        ItemIndex: null,
        showPlusSection: true,
        showPlusDepartment: false,
        showPlusTeam: false,
        showPlusMember: false,
        showPlusSectionAssistant: false,
        showPlusDepartmentAssistant: false,
        whatReset: {},
        isEditing: {
          active: false,
          type: '',
          name: '',
          newSupervisor: false,
          parent: false,
        },
        moveItem: {
          active: false,
          name: '',
          parent: '',
          parentSection: false,
          type: false
        },
        destination: null,
        showLargeUser: false,
        isSectionSelected: false,
        isDepartmentSelected: false,
        isTeamSelected: false,
        initialUserView: true
      };
    },
    watch: {
      "state.namespaceSettings.organisation": {
        handler: function (value) {
          this.organisation = cloneDeep(value)
        },
        deep: true,
      },
      structureToDelete: {
        handler(value) {
          if (value != null) {
            this.$nextTick(() => {
              this.$nextTick(() => {
                this.$refs.repeatStructureName.focus()
              })
            })
          }
        }
      },
      '$route.params.uuid':{
         immediate: true,
         deep: true,
        handler(value) {
          // if we have uuid in the url we open a large user #3985
          if(value){
            this.openLargeUser(value)
          }else{
            this.closeLargeUser()
          }
        },
      }
    },
    mounted() {
      if (!this.showOrganigram) {
        return this.gotoPage("home");
      }
      this.mountedData();
      // open large user #3985
      EventBus.$on('showLargeUserOrganisation', this.openLargeUser);
    },
    destroyed() {
      EventBus.$off('showLargeUserOrganisation', this.openLargeUser);
    },
    methods: {
      updateTeamsList(){
        this.teamsList = {};
        if (this.currentApart === "teams") {
          const newTeamsList = this.getStuffBySection("teams");
          const temporalTeams = this.checkApartSupervisors(
            newTeamsList
          );
          if (this.amIAdmin || !this.isUserInOrganigram || !this.initialUserView) {
            this.teamsList = temporalTeams;
          } else {
            this.teamsList = this.getMyTeams(temporalTeams);
          }
          const secretaryTeams = this.getSecretaryTeams();
          this.teamsList = Object.assign(JSON.parse(JSON.stringify(this.teamsList)), JSON.parse(JSON.stringify(secretaryTeams)));
        } else if (
          this.currentApart === "sections" &&
          this.showTeamsSelectedSection()
        ) {
          // condition if the user clicked in a section card and there is only one department, then show all the teams for this department
          this.getTeamsBySingleDepartment();
          const secretaryTeams = this.getSecretaryTeams();
          const newTeamsList = this.getStuffBySection("teams");
          const temporalTeams = this.checkApartSupervisors(newTeamsList);
          this.teamsList = temporalTeams;
          this.teamsList = Object.assign(JSON.parse(JSON.stringify(this.teamsList)), JSON.parse(JSON.stringify(secretaryTeams)));
        } else if (this.currentApart === "sections") {
          const newTeamsList = this.getStuffBySection("teams");
          const temporalTeams = this.checkApartSupervisors(
            newTeamsList
          );
          if (this.amIAdmin || !this.isUserInOrganigram || !this.initialUserView) {
            this.teamsList = temporalTeams;
          } else {
            this.teamsList = this.getMyTeams(temporalTeams);
          }
          const secretaryTeams = this.getSecretaryTeams();
          this.teamsList = Object.assign(JSON.parse(JSON.stringify(this.teamsList)), JSON.parse(JSON.stringify(secretaryTeams)));
        } else if (this.currentApart === "departments") {
          const newTeamsList = this.getStuffBySection("teams");
          const temporalTeams = this.checkApartSupervisors(
            newTeamsList
          );
          if (this.amIAdmin || !this.isUserInOrganigram || !this.initialUserView) {
            this.teamsList = temporalTeams;
          } else {
            this.teamsList = this.getMyTeams(temporalTeams);
          }
          const secretaryTeams = this.getSecretaryTeams();
          this.teamsList = Object.assign(JSON.parse(JSON.stringify(this.teamsList)), JSON.parse(JSON.stringify(secretaryTeams)));
        } else {
          if (
            this.initViewCardSeletected.selected === true &&
            this.initViewCardSeletected.apartName == "teams" &&
            !this.showEmptyTeams
          ) {
            // get all teams by current click team card department
            const temporalTeams = this.getTeamsInitCardSelected();
            this.teamsList = this.checkApartSupervisors(temporalTeams);
            const secretaryTeams = this.getSecretaryTeams();
            this.teamsList = Object.assign(JSON.parse(JSON.stringify(this.teamsList)), JSON.parse(JSON.stringify(secretaryTeams)));
          } else {
            this.teamsList = {};
            // if (this.showEmptyTeams && this.actualUser === this.state.ownUUID) {
            //   // show teams that don't have department/section assigned
            //   this.getAloneTeams();
            //   const secretaryTeams = this.getSecretaryTeams();
            //   this.teamsList = Object.assign(JSON.parse(JSON.stringify(this.teamsList)), JSON.parse(JSON.stringify(secretaryTeams)));
            // } else if (
            //   this.showEmptyTeams &&
            //   this.actualUser !== this.state.ownUUID
            // ) {
            //   this.getMyTeams();
            //   const temporalTeams = this.checkApartSupervisors(this.teamsList);
            //   this.teamsList = temporalTeams;
            //   const secretaryTeams = this.getSecretaryTeams();
            //   this.teamsList = Object.assign(JSON.parse(JSON.stringify(this.teamsList)), JSON.parse(JSON.stringify(secretaryTeams)));
            // } else {
            //   this.getMyTeams();
            //   const temporalTeams = this.checkApartSupervisors(this.teamsList);
            //   this.teamsList = temporalTeams;
            //   const secretaryTeams = this.getSecretaryTeams();
            //   this.teamsList = Object.assign(JSON.parse(JSON.stringify(this.teamsList)), JSON.parse(JSON.stringify(secretaryTeams)));
            // }
          }
        }
        if (!this.amIAdmin) {
          this.removeCardsFromBannedSections("teams");
        }
      },
      updateDepartmentsList() {
        this.departmentsList = {};
        if (this.isSectionSelected) {
          // get departments from the section selected // else return empty because departsment will be not showed
          if (this.currentApart === "departments") {
            const newDepartmentsList = this.getStuffBySection("departments");
                const temporalDepartments = this.checkApartSupervisors(
                  newDepartmentsList
                );
                if (this.amIAdmin || !this.isUserInOrganigram || !this.initialUserView) {
                  this.departmentsList = temporalDepartments;
                } else {
                  this.departmentsList = this.getMyDepartments(temporalDepartments);
                }
          } else if (
            this.currentApart === "sections" ||
            this.currentApart === "teams"
          ) {
            // if (this.currentApart === "sections" || this.currentApart === "teams") {
            //   temporalAssistants = this.assistants;
            // }
                const newDepartmentsList = this.getStuffBySection("departments");
                const temporalDepartments = this.checkApartSupervisors(
                  newDepartmentsList
                );
                if (this.amIAdmin || !this.isUserInOrganigram || !this.initialUserView) {
                  this.departmentsList = temporalDepartments;
                } else {
                  this.departmentsList = this.getMyDepartments(temporalDepartments);
                }
          } else {
            if (
              this.initViewCardSeletected.selected === true &&
              this.initViewCardSeletected.apartName == "departments"
            ) {
              // get all departments by current click team card section
              const temporalDepartments = this.getDepartmentsInitCardSelected();
              this.departmentsList = this.checkApartSupervisors(
                temporalDepartments
              );
            } else if (
              this.initViewCardSeletected.selected === true &&
              this.initViewCardSeletected.apartName == "teams"
            ) {
              // just to show the current apart
            } else {
              if (
                this.showEmptyDepartments &&
                this.actualUser === this.state.ownUUID
              ) {
                this.getAloneDepartments();
              } else if (
                this.showEmptyDepartments &&
                this.actualUser !== this.state.ownUUID
              ) {
                this.getMyDepartments();
                const temporalDepartments = this.checkApartSupervisors(
                  this.departmentsList
                );
                this.departmentsList = temporalDepartments;
              } else {
                this.getMyDepartments();
                const temporalDepartments = this.checkApartSupervisors(
                  this.departmentsList
                );
                this.departmentsList = temporalDepartments;
              }
            }
          }
          if (!this.amIAdmin) {
            this.removeCardsFromBannedSections("departments");
          }
          // this.latestDepartmentsList = this.departmentsList;
          // let temporalAssistantDeparts = {};
          // if (this.state.namespaceSettings.displayAssistants) {
          //   temporalAssistantDeparts = Object.assign(
          //     JSON.parse(JSON.stringify(temporalAssistants)),
          //     JSON.parse(JSON.stringify(this.departmentsList ))
          //   );
          // } else {
          //   temporalAssistantDeparts = Object.assign(
          //     JSON.parse(JSON.stringify(this.departmentsList )),
          //     JSON.parse(JSON.stringify(temporalAssistants))
          //   );
          // }
          // return temporalAssistantDeparts;

        }
        // let temporalAssistants = {};
        if (this.currentCard && this.currentCard.section === "all") {
          this.departmentsList = this.latestDepartmentsList;
          // return this.departmentsList;
        }
      },
      updateSectionsList(){
        this.sectionsList = {};
          // this condition does nothing, just update the sections at the beggining of the component.
          if (this.test) {}
          if (!this.isSectionSelected){
            if (this.amIAdmin || !this.isUserInOrganigram || !this.initialUserView) {
              this.getAllSections();
            } else {
              this.getMySections();
              // if(Object.keys(this.sectionsList).length === 0) {
              //   this.getAllSections();
              // }
            }
          } else {
            if (this.currentApart === "sections") {
              // insert in the sections list only the current selected section
              const newSectionsList = this.getStuffBySection("sections");
              // I selected a section card and departs with the sections selected needs to be returned
              const temporalSectionsList = this.checkApartSupervisors(
                newSectionsList
              );
              this.sectionsList = temporalSectionsList;
            } else if (this.currentApart === "departments") {
              const newSectionsList = this.getStuffBySection("sections");
              // I selected a section card and departs with the sections selected needs to be returned
              const temporalSectionsList = this.checkApartSupervisors(
                newSectionsList
              );
              // return temporalDepartments
              this.sectionsList = temporalSectionsList;
            } else if (this.currentApart === "teams") {
              const newSectionsList = this.getStuffBySection("sections");
              // I selected a section card and departs with the sections selected needs to be returned
              const temporalSectionsList = this.checkApartSupervisors(
                newSectionsList
              );
              // return temporalDepartments
              this.sectionsList = temporalSectionsList;
            } else {
              if (
                this.initViewCardSeletected.selected === true &&
                this.initViewCardSeletected.apartName == "sections"
              ) {
                this.getAllSections();
              } else if (this.showEmptyTeams) {
                //  reset the view
                this.getAllSections();
              } else if (
                (this.initViewCardSeletected.selected === true &&
                  this.initViewCardSeletected.apartName == "teams") ||
                this.initViewCardSeletected.apartName == "departments"
              ) {
                // just to show the curretn apart
              } else {
                if (
                  this.state.namespaceSettings.organisationFavorite ===
                  "multiSections" &&
                  this.state.namespaceSettings.favoriteSections.length > 0
                ) {
                  this.sectionsList = this.getFavoriteSections();
                } else {
                  // start get sections where the user is part of it
                  this.getMySections();
                }
                if (Object.keys(this.sectionsList).length === 0) {
                  // if the user (not admin) has nothing to show, show all sections..
                  this.getAllSections();
                } else {
                  // end
                  // get sections where the user is part of it
                  const temporalSectionsList = this.checkApartSupervisors(
                    this.sectionsList
                  );
                  this.sectionsList = temporalSectionsList;
                }
              }
            }
          }
        
      },
      loadSectionsData(){
        // this condition does nothing, just update the sections at the beggining of the component.
        if (!this.isSectionSelected){
          if (this.amIAdmin) {
            this.getAllSections();
          } else {
            this.getMySections();
            if(Object.keys(this.sectionsList).length === 0) {
              this.getAllSections();
            }
          }
        // delete 'all' section (section to put all teams #504)
        delete this.sectionsList["all"];
        this.latestSectionsList = this.sectionsList;
        }
      },
      getBorderCard(supervisorArray, uuid, index){
        if(supervisorArray.length > 1){
          // adjust the border for 2 people
          if( index == 0) {
            // remove right border for the first one
            return this.getCustomBorderByStatusFull(this.getPersonByUuid(uuid), 'right')
          } else if ( index == 1) {
            // remove left border for the second one
            return this.getCustomBorderByStatusFull(this.getPersonByUuid(uuid), 'left')
          }
        } else {
          // set border just for 1 person
          return this.getBorderByStatusFull(this.getPersonByUuid(uuid))
        }
      },
      getAvatarForUuid(uuid) {
        return store.getAvatarForUuid(uuid);
      },
      getAvatarByType(uuid) {
        return getAvatarByType(uuid);
      },
      getNameForUuid(uuid) {
        return store.getNameForUuid(uuid);
      },
      openLargeUser(userUUID){
        if(userUUID) {
          this.showLargeUser = userUUID
        }
      },
      closeLargeUser(){
        if(this.showLargeUser){
          this.showLargeUser = false
        }
      },
      scrollToBottom() {
        document.getElementById('organisationGridContainer').scrollTop = document.getElementById('organisationGridContainer').scrollHeight
    },
      closeModalMove() {
        this.moveItem = {
          active: false,
          name: '',
          parent: '',
          parentSection: false,
          type: false
        }
      },
      async moveItemF(where) {
        let movement;
        if (this.destination == 'sections') {
          this.organisation.filter(org => org.department === this.moveItem.name).forEach(org => {
            org.section = where
          });
          movement = 'moveDepa'
        } else if (this.destination == 'departments') {
          const sectionParent = this.state.namespaceSettings.processedOrganisation.departments[where].section
          this.organisation.filter(org => org.team === this.moveItem.name).forEach(org => {
            org.department = where
            org.section = sectionParent;
          });
          movement = 'moveTeam'
        } else if (this.destination == 'teams') {
          const teamIndex = this.organisation.findIndex(it => {
            return (it.team === where)
          });
          const currentTeamIndex = this.organisation.findIndex(it => {
            return (it.team === this.currentCardName)
          });
          const currentMemberIndex = this.organisation[currentTeamIndex].teamMembers.indexOf(this.moveItem.name.user
            .uuid);
          if (currentMemberIndex !== -1) {
            this.organisation[currentTeamIndex].teamMembers.splice(currentMemberIndex, 1);
          }
          if (!this.organisation[teamIndex].teamMembers) {
            this.organisation[teamIndex].teamMembers = [this.moveItem.name.user.uuid];
          } else {
            this.organisation[teamIndex].teamMembers.push(this.moveItem.name.user.uuid);
          }


          movement = 'moveUser';
        }
        await this.save(movement);

      },
      checkMove(evt, name, parent, type) {
        let classListTo;
        if ( typeof evt == 'object'){
          classListTo = evt.to.classList.value;
        }else{
          classListTo = evt;
        }
        if (this.amIAdmin && this.isEditableView && name && name !== '' && !this.moveItem.type) {
          this.moveItem.name = name;
          this.moveItem.parent = parent;
          if (type === 'moveDepartment' && classListTo.indexOf('sectionsDrag') !== -1) {
            this.destination = "sections";
            this.moveItem.active = true;
            this.moveItem.type = type;
          } else if (type == 'moveTeam' && classListTo.indexOf('departmentsDrag') !== -1) {
            this.destination = "departments";
            this.moveItem.active = true;
            this.moveItem.parentSection = this.currentCard.section
            this.moveItem.type = type;

          } else if (type == 'moveMember' && classListTo.indexOf('1teamsDrag') !== -1) {
            this.destination = "teams";
            this.moveItem.active = true;
            this.moveItem.type = type;
          }
        }
      },

      cleanEditing() {
        this.selectedSection = {};
        this.selectedDepartment = {};
        this.selectedTeam = {};
        this.isEditing = {
          active: false,
          type: '',
          name: '',
          newSupervisor: false,
          parent: false
        };
        return this.isEditing;
      },
      async renameStructure() {
        switch (this.isEditing.type) {
          case "section":
            this.organisation
              .filter(structure => structure.section === this.selectedSection.section)
              .forEach(structure => {
                structure.section = this.isEditing.name;
                if (this.isEditing.newSupervisor && !structure.department && !structure.team) {
                  structure.supervisor = this.isEditing.newSupervisor;
                }
              })
            break;
          case "department":
            this.organisation
              .filter(structure => structure.section === this.selectedSection.section)
              .filter(structure => structure.department === this.selectedDepartment.department)
              .forEach(structure => {
                structure.department = this.isEditing.name;
                if (this.isEditing.newSupervisor && !structure.team) {
                  structure.supervisor = this.isEditing.newSupervisor;
                }
              })
            break;
          case "team":
            this.organisation
              .filter(structure => structure === this.selectedTeam)
              .forEach(structure => {
                structure.team = this.isEditing.name;
                if (this.isEditing.newSupervisor) {
                  structure.supervisor = this.isEditing.newSupervisor;
                }
              })
            break;
          default:
            throw new Error("invalid type " + this.isEditing.type)
        }
        await this.save('editing');
      },
      async editingItemOrga(evt, remove = null) {
        let callRename = false;
        let nameToCheck, messageAlert, messageEmpty;
        let sectionToRename;
        let sectionParent;
        let departMentToRename;
        let parentDepa;
        let teamToRename;
        switch (this.isEditing.type) {
          case 'section':
            sectionToRename = this.organisation.filter(e => e.section && e.sectionRights && e.section == this
              .currentCardName);
            if (sectionToRename.length > 0) {
              this.selectedSection = sectionToRename[0];
            }
            nameToCheck = this.state.namespaceSettings.processedOrganisation.sections[this.isEditing.name]
            messageAlert = this.$t('components.organisationGrid.existingSection');
            messageEmpty = this.$t('components.organisationGrid.sectionRequired');
            // remove supervisor logic
            if(remove && evt) {
              const supervisorFound = this.selectedSection.supervisor.indexOf(evt)
              if(supervisorFound !== -1) {
                let originalSupervisors = this.selectedSection.supervisor;
                originalSupervisors.splice(supervisorFound, 1);
                this.isEditing.newSupervisor = originalSupervisors;
              }
            } else {
              //add supervisor logic
              if (evt && evt.added && evt.added.element.uuid) {
                const userUUID = evt.added.element.uuid
                if (!this.selectedSection.supervisor) {
                  this.isEditing.newSupervisor = [userUUID];
                } else if (this.selectedSection.supervisor.length < 2 && this.selectedSection.supervisor.indexOf(userUUID) == -1) {
                  this.isEditing.newSupervisor = [...this.selectedSection.supervisor, userUUID];
                }
              }
            }
            break;
          case 'department':
            sectionParent = this.organisation.filter(e => e.section && e.sectionRights && e.section == this
              .currentCard.section);
            departMentToRename = this.organisation.filter(e => e.department && e.departmentRights && e
              .section === this.currentCard.section && e.department == this.currentCardName);
            if (sectionParent.length > 0) {
              this.selectedSection = sectionParent[0];
            }
            if (departMentToRename.length > 0) {
              this.selectedDepartment = departMentToRename[0];
            }
            nameToCheck = this.state.namespaceSettings.processedOrganisation.departments[this.isEditing.name]
            messageAlert = this.$t('components.organisationGrid.existingDepa');
            messageEmpty = this.$t('components.organisationGrid.requiredDepa');
            // remove supervisor logic
            if(remove && evt) {
              const supervisorFound = this.selectedDepartment.supervisor.indexOf(evt)
              if(supervisorFound !== -1) {
                let originalSupervisors = this.selectedDepartment.supervisor;
                originalSupervisors.splice(supervisorFound, 1);
                this.isEditing.newSupervisor = originalSupervisors;
              }
            } else {
              //add supervisor logic
              if (evt && evt.added && evt.added.element.uuid) {
                const userUUID = evt.added.element.uuid
                if (!this.selectedDepartment.supervisor) {
                  this.isEditing.newSupervisor = [userUUID];
                } else if (this.selectedDepartment.supervisor.length < 2 && this.selectedDepartment.supervisor.indexOf(userUUID) == -1) {
                  this.isEditing.newSupervisor = [...this.selectedDepartment.supervisor, userUUID];
                }
              }
            }
            break;
          case 'team':
            parentDepa = this.currentCard.department
            teamToRename = this.organisation.filter(e => e.team && e.department === parentDepa && e.team == this
              .currentCardName);
            if (teamToRename.length > 0) {
              this.selectedTeam = teamToRename[0];
            }
            nameToCheck = this.state.namespaceSettings.processedOrganisation.teams[this.isEditing.name]
            messageAlert = this.$t('components.organisationGrid.existingTeam');
            messageEmpty = this.$t('components.organisationGrid.requiredTeam');
            // remove supervisor logic
            if(remove && evt) {
              const supervisorFound = this.selectedTeam.supervisor.indexOf(evt)
              if(supervisorFound !== -1) {
                let originalSupervisors = this.selectedTeam.supervisor;
                originalSupervisors.splice(supervisorFound, 1);
                this.isEditing.newSupervisor = originalSupervisors;
              }
            } else {
              //add supervisor logic
              if (evt && evt.added && evt.added.element.uuid) {
                const userUUID = evt.added.element.uuid
                if (!this.selectedTeam.supervisor) {
                  this.isEditing.newSupervisor = [userUUID];
                } else if (this.selectedTeam.supervisor.length < 2 && this.selectedTeam.supervisor.indexOf(userUUID) == -1) {
                  this.isEditing.newSupervisor = [...this.selectedTeam.supervisor, userUUID];
                }
              }
            }
            break;
        }
        // if (evt && evt.added && evt.added.element.uuid) {
        //   this.isEditing.newSupervisor = [evt.added.element.uuid];
        // }

        if (this.isEditing.name !== this.currentCardName) {
          if (this.isEditing.name !== '') {
            if (nameToCheck) {
              dispatchErrorAlert(messageAlert)
            } else {
              callRename = true;
            }
          } else {
            dispatchErrorAlert(messageEmpty)
          }
        } else {
          if (this.isEditing.newSupervisor) {
            callRename = true;
          }
        }

        if (callRename) {
          this.renameStructure();
        }

      },
      setEditing(type, parent = false) {
        this.isEditing.active = true;
        this.isEditing.type = type;
        this.isEditing.name = this.currentCardName;
        this.isEditing.parent = parent
      },
      async deleteMember(member) {
        const teamIndex = this.organisation.findIndex(it => {
          return (it.team === this.currentCardName)
        });
        const currentTeam = this.currentCardName
        const memberIndex = this.organisation[teamIndex].teamMembers.indexOf(member.uuid)
        if (memberIndex !== -1) {
          this.organisation[teamIndex].teamMembers.splice(memberIndex, 1);
          this.addRemoveMemberToDailyReport('removeMember', currentTeam, member.uuid);
          await this.save('deleteMember')
          this.updateBeaconTeams(currentTeam, this.organisation[teamIndex].teamMembers)
          this.currentCard.active = false;
          this.selectTeam(this.currentCardName, "teams");
        }
      },
      resetPlusSign(plus) {
        this.showPlusSection = false;
        this.showPlusDepartment = false;
        this.showPlusTeam = false;
        this.showPlusMember = false;
        this.showPlusSectionAssistant = false;
        this.showPlusDepartmentAssistant = false;
        switch (plus) {
          case 'section':
            this.showPlusDepartment = true;
            this.showPlusSectionAssistant = true;
            break;
          case 'department':
            this.showPlusTeam = true;
            this.showPlusDepartmentAssistant = true;
            break;
          case 'team':
            this.showPlusMember = true;
            break
          case 'resetFull':
            this.showPlusSection = true;
            break
        }
      },
      selectIndex(Index) {
        this.ItemIndex = Index;
      },
      tryDeletion() {
        if (!this.isDeleteDisabled) this.deteleStructureAndSub()
      },
      async removeAssistant(value) {
        let originalOrga = this.state.namespaceSettings.processedOrganisation;
        let supervisor;
        if (value.type == 'sections') {
          if (originalOrga.sections[this.currentCardName]) {
            supervisor = originalOrga.sections[this.currentCardName].supervisors[0].uuid;
          } else if (originalOrga.sections[this.currentCard.section]) {
            supervisor = originalOrga.sections[this.currentCard.section].supervisors[0].uuid;
          }
        } else if (value.type === 'department') {
          if (originalOrga.departments[this.currentCardName]) {
            supervisor = originalOrga.departments[this.currentCardName].supervisors[0].uuid;
          } else if (originalOrga.departments[this.currentCard.department]) {
            supervisor = originalOrga.departments[this.currentCard.department].supervisors[0].uuid;
          }
        }
        const group = this.state.group[supervisor]
        if (group && group.user) {
          if (group.user.assistants) {
            const userIndex = group.user.assistants.indexOf(value.user.uuid)
            if (userIndex !== -1) {
              group.user.assistants.splice(userIndex, 1);
              await this.save('deleteAssistant');
            }
          }
        } else {
          dispatchErrorAlert(this.$t('components.organisationGrid.somethingWrong'))
        }
      },
      removeStructure(val) {
        if (val.type === 'section') {
          const sectionToDelete = this.organisation.filter(e => e.section && e.sectionRights && e.section == val.name);
          if (sectionToDelete.length > 0) {
            this.selectedSection = sectionToDelete[0];
            this.structureToDeleteType = val.type
          }
        } else if (val.type === 'department') {
          const departMentToDelete = this.organisation.filter(e => e.department && e.departmentRights && e.section ===
            val.parent && e.department == val.name);
          if (departMentToDelete.length > 0) {
            this.selectedDepartment = departMentToDelete[0];
            this.structureToDeleteType = val.type
            this.whatReset.type = 'sections';
            this.whatReset.name = val.parent;

          }
        } else if (val.type === 'team') {
          const teamToDelete = this.organisation.filter(e => e.team && e.department === val.parent && e.team == val
            .name);
          if (teamToDelete.length > 0) {
            this.selectedTeam = teamToDelete[0];
            this.structureToDeleteType = val.type
            this.whatReset.type = 'departments';
            this.whatReset.name = val.parent;

          }
        }
      },
      async deteleStructureAndSub() {
        let teamsInBeacon = store.state.namespaceSettings.teamsShownInBeacon;
        let saveType;
        this.organisation = this.organisation.filter(org => org[this.structureToDeleteType] !== this
          .repeatStructureName);
        switch (this.structureToDeleteType) {
          case "section":
            this.selectedSection = {}
            this.selectedDepartment = {}
            this.selectedTeam = {}
            saveType = "deleteSection"
            break;
          case "department":
            this.selectedSection = {}
            this.selectedDepartment = {}
            this.selectedTeam = {}
            saveType = "deleteDepa"
            break;
          case "team":
            if (teamsInBeacon) {
              const teamIndex = teamsInBeacon.findIndex(e => e.team === this.selectedTeam.team);
              if (teamIndex !== -1) {
                teamsInBeacon.splice(teamIndex, 1);
                setNamespaceSetting("teamsShownInBeacon", teamsInBeacon);
              }
            }
            this.selectedSection = {}
            this.selectedDepartment = {}
            this.selectedTeam = {}
            saveType = "deleteTeam"
            break;
          default:
            throw new Error("invalid type " + this.structureToDeleteType)
        }
        this.structureToDeleteType = null
        this.repeatStructureName = ""

        await this.save(saveType);
      },
      filterPerson: function (person) {
        return (
          person.uuid !== store.state.ownUUID && !this.members[person.uuid]
        );
      },
      setInfoModal(data) {
        return store.setinfoModal(data);
      },
      async save(type = undefined) {
        this.organisation = this.organisation.filter(it => {
          return (it.sectionRights == 1 && it.section) ||
            (it.departmentRights == 1 && it.department) ||
            (it.sectionRights != 1 && it.departmentRights != 1 && it.team)
        })
        this.organisation = this.organisation.flatMap(it => {
          if (it.sectionRights == 1 && it.departmentRights == 1) {
            return [{
                section: it.section,
                sectionRights: 1,
                supervisor: it.supervisor,
              },
              {
                department: it.department,
                departmentRights: 1,
                supervisor: it.supervisor,
                section: it.section,
              }
            ]
          } else {
            return it
          }
        })

        this.state.namespaceSettings.organisation = this.organisation
        const data = {
          user: this.users,
          organisation: this.organisation
        }
        let dataInfoModal = {
          show: true,
          header: this.$t("generics.info"),
          body: this.$t("components.organisationGrid.changeWillSave"),
          disableFooter: true
        };
        this.setInfoModal(dataInfoModal);
        try {
          await importUsersParsed(data, { overwrite: false, update: true }); // Skip user deletion for extra safety
          this.cleandAddWithDragAndDrop();
          if (type == 'section') {
            dispatchSuccessAlert(this.$t('components.organisationGrid.sectionCreated'));
          } else if (type == 'department') {
            dispatchSuccessAlert(this.$t('components.organisationGrid.departmentCreated'));
          } else if (type == 'team') {
            dispatchSuccessAlert(this.$t('components.organisationGrid.teamCreated'));
          } else if (type == 'addMember') {
            dispatchSuccessAlert(this.$t('components.organisationGrid.memberAdded'));
          } else if (type == 'deleteSection') {
            dispatchSuccessAlert(this.$t('components.organisationGrid.sectionDeleted'));
          } else if (type == 'deleteDepa') {
            dispatchSuccessAlert(this.$t('components.organisationGrid.departmentDeleted'));
          } else if (type == 'deleteTeam') {
            dispatchSuccessAlert(this.$t('components.organisationGrid.teamDeleted'));
          } else if (type == 'deleteMember') {
            dispatchSuccessAlert(this.$t('components.organisationGrid.memberDeleted'));
          } else if (type === 'deleteAssistant') {
            dispatchSuccessAlert(this.$t('components.organisationGrid.assistantDeleted'));
          } else if (type == 'addAssistant') {
            dispatchSuccessAlert(this.$t('components.organisationGrid.assistantAdded'));
          } else if (type == 'editing') {
            dispatchSuccessAlert(this.$t('components.organisationGrid.itemEdited'));
            if (this.isEditing.type === 'section') {
              this.resetApart();
            } else if (this.isEditing.type === 'department') {
              this.selectSection(this.currentCard.section, "sections");
            } else if (this.isEditing.type === 'team') {
              this.selectDepartment(this.currentCard.department, "departments");
            }
            this.cleanEditing()
          } else if (type === 'moveDepa') {
            if (this.currentApart == 'departments') {
              this.currentCard.active = false;
              this.selectSection(this.currentCard.section, "sections");
            }
            dispatchSuccessAlert(this.$t('components.organisationGrid.depaMoved'));
            this.closeModalMove();
          } else if (type === 'moveTeam') {
            if (this.currentApart == 'teams') {
              this.currentCard.active = false;
              this.selectSection(this.currentCard.section, "sections");
            }
            dispatchSuccessAlert(this.$t('components.organisationGrid.itemMoved'));
            this.closeModalMove();
          } else if (type === 'moveUser') {
            if (this.userList[this.moveItem.name.user.uuid] && this.userList[this.moveItem.name.user.uuid]
              .memberOfTeams) {
              const actualTeam = this.userList[this.moveItem.name.user.uuid].memberOfTeams.indexOf(this
                .currentCardName)
              this.userList[this.moveItem.name.user.uuid].memberOfTeams.splice(actualTeam, 1);
            }
            if (this.membersList[this.moveItem.name.user.uuid]) {
              Vue.delete(this.membersList, this.moveItem.name.user.uuid);
            }
            dispatchSuccessAlert(this.$t('components.organisationGrid.userMoved'));
            this.closeModalMove();

          }
          if (type == 'deleteDepa' || type == "deleteSection") {
            if (this.whatReset) {
              if (this.whatReset.type == 'departments') {
                this.currentCard.active = false;
                this.selectDepartment(this.whatReset.name, "departments");
                this.whatReset = {};
              } else if (this.whatReset.type === 'sections') {
                this.currentCard.active = false;
                this.selectSection(this.whatReset.name, "sections");
                this.whatReset = {};
              }
            }
          }
          this.updateSectionsList();
          this.updateDepartmentsList();
          this.updateTeamsList();
        } catch (error) {
          this.error = error;
          dispatchErrorAlert(this.$t('components.organisationGrid.somethingWrong'))
          this.cleandAddWithDragAndDrop();
          console.error(error)
        }
        dataInfoModal = {
          show: false,
          header: '',
          body: '',
        };
        this.setInfoModal(dataInfoModal);
      },
      async addStructure(type, uuid, sectionParent = undefined) {
        const newStructure = {
          [type]: ""
        }
        if (type === "section") {
          newStructure.sectionRights = 1
          this.selectedSection = newStructure;
          this.selectedSection.section = this.newSectionName.trim();
          this.selectedSection.supervisor = [uuid];
        } else if (type === "department") {
          newStructure.departmentRights = 1
          newStructure.section = this.currentCardName;
          newStructure.department = this.newDepartmentName.trim()
          this.selectedDepartment = newStructure
          this.selectedDepartment.supervisor = [uuid];
        } else if (type === "team") {
          newStructure.section = sectionParent;
          newStructure.department = this.currentCardName;
          newStructure.team = this.newTeamName.trim();
          this.selectedTeam = newStructure;
          this.selectedTeam.supervisor = [uuid];
        } else {
          throw new Error("invalid type " + type)
        }
        this.organisation.push(newStructure);
        await this.save(type);

      },
      async log(evt, name) {
        try {
          let originalOrga = this.state.namespaceSettings.processedOrganisation;
          if (evt.added && evt.added.element && evt.added.element.uuid) {
            if (name === 'addSection') {
              if (this.newSectionName !== '') {
                if (originalOrga.sections[this.newSectionName.trim()]) {
                  dispatchErrorAlert(this.$t('components.organisationGrid.existingSection'))
                } else {
                  this.addStructure('section', evt.added.element.uuid);
                }
              } else {
                dispatchErrorAlert(this.$t('components.organisationGrid.sectionRequired'))
              }
            } else if (name === 'addDepartment') {
              if (this.newDepartmentName !== '') {
                if (originalOrga.sections[this.currentCardName || this.currentCard.section].departments.indexOf(this
                    .newDepartmentName.trim()) !== -1) {
                  dispatchErrorAlert(this.$t('components.organisationGrid.existingDepa'))
                } else {
                  this.addStructure('department', evt.added.element.uuid);
                }
              } else {
                dispatchErrorAlert(this.$t('components.organisationGrid.requiredDepa'))
              }
            } else if (name === 'addTeam') {
              if (this.newTeamName !== '') {
                if (originalOrga.departments[this.currentCardName] && originalOrga.departments[this.currentCardName].teams.indexOf(this.newTeamName.trim()) !== -1) {
                  dispatchErrorAlert(this.$t('components.organisationGrid.existingTeam'))
                } else if (originalOrga.departments[this.currentCardName] && originalOrga.departments[this.currentCardName].teams.indexOf(this.newTeamName.trim()) === -1) {
                  const sectionParent = originalOrga.departments[this.currentCardName].section;
                  this.addStructure('team', evt.added.element.uuid, sectionParent);
                   this.addTeamToDailyReport('addTeam',sectionParent, this.newTeamName.trim())
                }
              } else {
                dispatchErrorAlert(this.$t('components.organisationGrid.requiredTeam'))
              }
            } else if (name === 'addAssistant') {
              const supervisor = originalOrga.sections[this.currentCardName || this.currentCard.section].supervisors[
                0].uuid;
              const group = this.state.group[supervisor]
              if (group && group.user) {
                if (group.user.assistants) {
                  if (group.user.assistants.indexOf(evt.added.element.uuid) == -1) {
                    group.user.assistants.push(evt.added.element.uuid);
                    await this.save('addAssistant');
                  } else {
                    dispatchErrorAlert(this.$t('components.organisationGrid.existingAssistant'))
                  }
                } else {
                  group.user.assistants = [evt.added.element.uuid]
                  dispatchSuccessAlert(this.$t('components.organisationGrid.assistantAdded'));
                }
              } else {
                dispatchErrorAlert(this.$t('components.organisationGrid.somethingWrong'))
              }
              this.cleandAddWithDragAndDrop();

            } else if (name === 'addDepartmentAssistant') {
              const supervisor = originalOrga.departments[this.currentCardName].supervisors[0].uuid;
              const group = this.state.group[supervisor]
              if (group && group.user) {
                if (group.user.assistants) {
                  if (group.user.assistants.indexOf(evt.added.element.uuid) == -1) {
                    group.user.assistants.push(evt.added.element.uuid);
                    await this.save('addAssistant');
                  } else {
                    dispatchErrorAlert(this.$t('components.organisationGrid.existingAssistant'))
                  }
                } else {
                  group.user.assistants = [evt.added.element.uuid]
                  dispatchSuccessAlert(this.$t('components.organisationGrid.assistantAdded'));
                }
              } else {
                dispatchErrorAlert(this.$t('components.organisationGrid.somethingWrong'))
              }
              this.cleandAddWithDragAndDrop();
            } else if (name == 'addMember') {
              const teamIndex = this.organisation.findIndex(it => {
                return (it.team === this.currentCardName)
              });
              const currentTeam = this.currentCardName;
              if (!this.organisation[teamIndex].teamMembers) {
                this.organisation[teamIndex].teamMembers = [evt.added.element.uuid];
                await this.save('addMember');
                this.updateBeaconTeams(currentTeam, this.organisation[teamIndex].teamMembers)
                this.addRemoveMemberToDailyReport('addMember',currentTeam, evt.added.element.uuid)
              } else {
                if (this.organisation[teamIndex].teamMembers.indexOf(evt.added.element.uuid) === -1) {
                  this.organisation[teamIndex].teamMembers.push(evt.added.element.uuid);
                  await this.save('addMember');
                  this.updateBeaconTeams(currentTeam, this.organisation[teamIndex].teamMembers)
                  this.addRemoveMemberToDailyReport('addMember',currentTeam, evt.added.element.uuid)
                } else {
                  dispatchErrorAlert(this.$t('components.organisationGrid.existingMember'))
                }
              }


            }
          }
        } catch (error) {
          console.warn('Error on add by drag&drop', error);
          dispatchErrorAlert(error)
          this.cleandAddWithDragAndDrop();
        }

      },
      addRemoveMemberToDailyReport(type, teamName, userUUID){
        let dataNamespaceSettings = JSON.parse(
          JSON.stringify(store.state.namespaceSettings.dailyReportSettings)
        );
        const organisationTeam = this.state.namespaceSettings?.processedOrganisation?.teams[teamName];
        let sectionEmails, teamEmails, allEmails;
        let usersEmail = dataNamespaceSettings.members;
        if (organisationTeam) {
          sectionEmails = dataNamespaceSettings.sections[organisationTeam.section] || [];
          teamEmails = dataNamespaceSettings.teams[teamName] || [];
          allEmails = sectionEmails.concat(teamEmails);
        }

        switch (type) {
          case 'addMember':
            if(!usersEmail[userUUID]){
              const filterEmail = allEmails.filter((email)=> email.trim().length)
              usersEmail = {...usersEmail,  [userUUID]: filterEmail };
            }else{
              allEmails.forEach(email => {
                if (usersEmail[userUUID].indexOf(email) == -1 && email.trim().length) {
                  usersEmail[userUUID].push(email);
                }
              });
            }
            break;
          case 'removeMember':{
            let orgaData = getUserOrgaData(userUUID);
            // const userEmailArray = JSON.parse(JSON.stringify(usersEmail));
            teamEmails?.forEach(email => {
            //solo borramos este email si solo esta en el team teamName team que estamos borrando
              let weDelete = false;
              // for teams
              orgaData.teams.forEach(team => {
                if(team !== teamName) {
                  const teamEmail = dataNamespaceSettings.teams[team];
                  if (teamEmail.indexOf(email) !== -1 && teamEmails.indexOf(email) !== -1 ) {
                    // email exists, email will not be remove
                    weDelete = false;
                    return;
                  } else {
                    if(teamEmails.indexOf(email) !== -1){
                      weDelete = true;
                    }
                  }
                } else {
                  weDelete = true;
                }
              })
              if (weDelete) {
                // borramos team
                if (usersEmail[userUUID]) {
                  const emailIndex = usersEmail[userUUID].indexOf(email);
                  if (emailIndex !== -1) {
                    usersEmail[userUUID].splice(emailIndex, 1);
                  }
                }
              }
            });
            break;
          }
          default:
            break;
        }
        dataNamespaceSettings.members = usersEmail;
        setNamespaceSetting("dailyReportSettings", dataNamespaceSettings);
      },
      addTeamToDailyReport(type, section, teamName) {
        let dataNamespaceSettings = JSON.parse(
          JSON.stringify(store.state.namespaceSettings.dailyReportSettings)
        );
        let sectionEmails = dataNamespaceSettings.sections[section];
        let teamEmails = dataNamespaceSettings.teams;
        if(sectionEmails && type == 'addTeam'){
           if (!teamEmails[teamName]) {
             const filterEmail = sectionEmails.filter((email)=> email.trim().length)
            teamEmails = { ...teamEmails, [teamName]: filterEmail };
          } else {
            sectionEmails.forEach(email => {
              if (teamEmails[teamName].indexOf(email) == -1 && email.trim().length) {
                teamEmails[teamName].push(email);
              }
            });
          }
        }
        dataNamespaceSettings.teams = teamEmails;
        setNamespaceSetting("dailyReportSettings", dataNamespaceSettings);
      },
      updateBeaconTeams(team, teamMembers) {
        const beaconTeams = this.state.namespaceSettings.teamsShownInBeacon || [];
        if (beaconTeams.length > 0) {
          const beaconIndex = beaconTeams.findIndex(e => e.team == team)
          if (beaconTeams[beaconIndex]) {
            beaconTeams[beaconIndex].teamMembers = teamMembers
          }
          setNamespaceSetting("teamsShownInBeacon", beaconTeams);
        }
      },
      getCustomBorderByStatusFull(person, position) {
        try {
          return getCustomBorderByStatusFull(person, position);
        } catch {}
      },
      getBorderByStatusFull(person) {
        try {
          return getBorderByStatusFull(person);
        } catch {}
      },
      getApartLabel(apartName) {
        // function to get the title of each apart and compare if it should return plural or singular way
        switch (apartName) {
          case "sections":
            return Object.keys(this.sections).length > 1 ?
              this.$t("components.organisationCarousel.sections") :
              this.$t("components.organisationCarousel.section");
          case "departments":
            return Object.keys(this.departments).length > 1 ?
              this.$t("components.organisationCarousel.departments") :
              this.$t("components.organisationCarousel.department");
          case "teams":
            return Object.keys(this.teams).length > 1 ?
              this.$t("components.organisationCarousel.teams") :
              this.$t("components.organisationCarousel.team");
          case "members":
            return this.$t("generics.teamMember");
          case "assistants":
            return this.$t("components.organisationCarousel.assistant");
          default:
            break;
        }
      },
      teamSupervisorSectionConnection(section) {
        // function to check if the supervisor of a team is the supervisor of a section but not fot department ( intermediate field, no directly connection)
        const teamSupervisor = section.supervisors[0];
        const teamDepartment = section.department;
        const departmentSupervisor = this.departments[teamDepartment]
          .supervisors[0];
        const teamSection = section.section;
        const sectionSupervisor = this.sections[teamSection].supervisors[0];
        if (
          departmentSupervisor === teamSupervisor ||
          sectionSupervisor !== teamSupervisor
        ) {
          // department supervisor is the same that the team supervisor, so the connection with the team and the section is broken
          return false;
        } else if (sectionSupervisor === teamSupervisor) {
          // there is connection between the section and the team, so the image should be big
          return true;
        }
      },
      getUserAvatar(section) {
        if (
          section.supervisors[0] &&
          this.state.group[section.supervisors[0].uuid]
        ) {
          return (
            this.state.group[section.supervisors[0].uuid].user.avatar ||
            "img/default_profile_picture.png"
          );
        } else {
          return "img/default_profile_picture.png";
        }
      },
      getSectionMembersLength(section) {
        let finalResult = 0;
        const currentSection = this.state.namespaceSettings.processedOrganisation
          .sections[section];
        const teams = currentSection.teams;
        for (let i = 0; i < teams.length; i++) {
          const team = this.state.namespaceSettings.processedOrganisation.teams[
            teams[i]
          ];
          const teamMembersLength = team.members.length;
          finalResult += teamMembersLength;
        }
        return finalResult;
      },
      specificApartAssistants(apart) {
        let supervisorUUID = {};
        let supervisorUser = {};
        let finalResult = {};
        try {
          switch (apart) {
            case "sections":
              if (
                this.currentApart === "sections" 
                // ||
                // this.currentApart === "teams"
              ) {
                supervisorUUID = this.getSupervisorUuid(this.currentCard);
                supervisorUser = this.state.group[supervisorUUID].user;
              } else if (
                this.currentApart === "departments" 
                // ||
                // (this.currentApart === "departments") == "teams"
              ) {
                const sections = this.state.namespaceSettings
                  .processedOrganisation.sections;
                supervisorUUID = this.getSupervisorUuid(
                  sections[this.currentCard.section]
                );
                supervisorUser = this.state.group[supervisorUUID].user;
              } else if(this.currentApart === "teams"){
                const sections = this.state.namespaceSettings
                  .processedOrganisation.sections;
                supervisorUUID = this.getSupervisorUuid(
                  sections[this.currentCard.section]
                );
                supervisorUser = this.state.group[supervisorUUID].user;
              }
              break;
            case "departments":
              if (
                this.currentApart === "sections" &&
                Object.keys(this.departments).length === 1
              ) {
                const department = this.departmentsList[
                  Object.keys(this.departments)[0]
                ];
                supervisorUUID = this.getSupervisorUuid(department);
                supervisorUser = this.state.group[supervisorUUID].user;
              } else if (this.currentApart === "departments") {
                supervisorUUID = this.getSupervisorUuid(this.currentCard);
                supervisorUser = this.state.group[supervisorUUID].user;
              } else if (this.currentApart === "teams") {
                const departments = this.state.namespaceSettings
                  .processedOrganisation.departments;
                supervisorUUID = this.getSupervisorUuid(
                  departments[this.currentCard.department]
                );
                supervisorUser = this.state.group[supervisorUUID].user;
              }
              break;
            default:
              return {};
          }
          for (let i = 0; i < supervisorUser.assistants.length; i++) {
            const assistantUuid = supervisorUser.assistants[i];
            if (store.state.group[assistantUuid]) {
              Vue.set(finalResult, assistantUuid, {
                ...this.state.group[assistantUuid],
                uuid: assistantUuid,
              });
            }
          }
          return finalResult;
        } catch {
          return {};
        }
      },
      gotoPage(route) {
        this.setCurrentContentVisile(route, true, this.$router);
      },
      mountedData() {
        this.loadSectionsData();
        this.showDepartment = false;
        this.showTeam = false;
        this.showMembers = false;
        // if (this.state.namespaceSettings.showAllSectionsOrga.length > 0) {
        //   this.showTeam = true;
        // } else {
        //   this.showTeam = false;
        // }
        // if (Object.keys(this.teams).length === 1) {
        //   this.showMembers = false;
        // }
        // if (this.amIAdmin) {
        //   this.showDepartment = false;
        //   this.showTeam = false;
        // // } else {
        if (this.amIAdmin || !this.isUserInOrganigram) {
          this.getAllSections();
        }
        else if (!this.amIAdmin) {
          this.test = true;
          const firstSection = Object.keys(
            this.checkApartSupervisors(this.sectionsList)
          )[0];
          if (
            this.userList[this.actualUser] &&
            firstSection &&
            // !this.showAllSectionsVisibility &&
            this.state.namespaceSettings.organisationFavorite !==
            "multiSections" &&
            !this.$route.params.uuid
          ) {
            this.selectSectionInitView(
              firstSection,
              this.sectionsList[firstSection]
            );
          }
        // else {
        // if (this.ownUUID !== this.actualUser) {
          if(Object.keys(this.sectionsList).length > 0) {
            this.handleClicks('auto', Object.keys(this.sectionsList)[0], 'sections');
            const hasDepartment = !!(store.getProcessedUserList()[this.actualUser].supervisorOfDepartments || store.getProcessedUserList()[this.actualUser].teamDepartments);
            if (hasDepartment) {
              this.handleClicks('auto', Object.keys(this.departmentsList)[0], 'departments');
              const hasTeam = (store.getProcessedUserList()[this.actualUser].memberOfTeams || store.getProcessedUserList()[this.actualUser].supervisorOfTeams);
              if (hasTeam) {
                this.handleClicks('auto', Object.keys(this.teamsList)[0], 'teams');
              }
            }
          }
        //   if (hasDepartment) {
        //     // this.showDepartment = true;
        //   } else {
        //     this.showDepartment = false;
        //     this.showTeam = false;
        //   }
        //   if (hasTeam){
        //     // this.showDepartment = true;
        //     this.showTeam = true;
        //     if (Object.keys(this.teams).length > 0) {
        //       setTimeout(() => {
        //         this.selectTeam(Object.keys(this.teamsList)[0], "teams")
        //         this.isSectionSelected = true
        //         this.isDepartmentSelected = true;
        //       //   this.setShowMembers(true);
        //       //   Vue.set(this.teamsList[Object.keys(this.teamsList)[0]], "active", true);
        //       //   this.teamCardSelected(true);
        //       //   this.showEmptyDepartments = true;
        //       //   this.resetPlusSign('team')
        //       }, 100);
        //       setTimeout(() => {
        //         this.scrollToBottom();
        //       }, 200);
        //     }
        //   } else {
        //     this.showTeam = false;
        //   }
        }
        // }
        this.organisation = cloneDeep(this.state.namespaceSettings.organisation)
      },
      resetEverything() {
        // function to reset all variables
        this.test = false;
        this.ownUUID = this.state.ownUUID;
        this.showAllCards = false;
        this.sectionsList = {};
        this.departmentsList = {};
        this.teamsList = {};
        this.membersList = {};
        this.sectionsListOriginal;
        this.state.namespaceSettings.processedOrganisation.sections;
        this.departmentsListOriginal = this.state.namespaceSettings.processedOrganisation.departments;
        this.teamsListOriginal = this.state.namespaceSettings.processedOrganisation.teams;
        this.membersListOriginal = {};
        this.assistantsList = {};
        this.currentApart = "";
        this.currentCard = {};
        this.membersToShow = 6;
        this.teamsToShow = 12;
        this.showMembers = false;
        this.teamSelected = false;
        this.teamSelectedSectionCard = {};
        this.teamSelectedDepartmentCard = {};
        this.teamSelectedTeamCard = {};
        this.doubleClick = null;
        this.cardToFlip = null;
        this.initViewCardSeletected = {
          selected: false,
          apartName: "",
          apart: {},
        };
        this.showTeam = false;
        this.showDepartment = true;
        // this.initAdminView = true;
        this.showDialog = true;
        this.singleSectionDisabled = false;
        // this.showAllSectionsVisibility = this.state.namespaceSettings.showAllSectionsVisibility;
        // this.showAllSectionsVisibility = false;
        this.showEmptyTeams = false;
        this.showEmptyDepartments = false;
        this.latestSectionsList = {};
        this.latestDepartmentsList = {};
        this.selectedSection = {
          card: {},
          selected: false,
        };
        this.isSectionSelected = false;
        this.isDepartmentSelected = false;
        this.isTeamSelected = false;
      },
      getSecretaryTeams() {
        let allSectionTeamsList = {};
        // function to get the teams inside "ALL" section ( secretary section)
        if (this.state.namespaceSettings.processedOrganisation.sections["all"]) {
          const sectionTeams = this.state.namespaceSettings.processedOrganisation
            .sections["all"].teams;

          for (let i = 0; i < sectionTeams.length; i++) {
            const team = sectionTeams[i];
            allSectionTeamsList[
              team
            ] = this.state.namespaceSettings.processedOrganisation.teams[team];
          }
          return allSectionTeamsList;
        }
        return {};
      },
      hasNoImage(userAvatar) {
        if (userAvatar === "img/default_profile_picture.png") {
          return true;
        }
        return false;
      },
      getAllSections() {
        // function to show all sections and departments/teams that are alone (not assigned to an apart)
        const temporalSectionsList = this.getSectionsInitCardSelected();
        this.sectionsList = this.checkApartSupervisors(temporalSectionsList);
        // commented for now
        this.showEmptyTeams = true;
        this.showEmptyDepartments = true;
      },
      setHideAllAparts() {
        // hide aparts
        this.showDepartment = false;
        this.showTeam = false;
        this.showMembers = false;
      },
      setShowAllAparts() {
        // show aparts
        this.showDepartment = true;
        this.showTeam = true;
        this.showMembers = true;
      },
      checkShowAll(name) {
        // unused function old
        // function to check if the show all option is selected for the section, and show departments,teams and members
        const showAllSectionsOrga = this.state.namespaceSettings
          .showAllSectionsOrga;
        for (let i = 0; i < showAllSectionsOrga.length; i++) {
          const sectionName = showAllSectionsOrga[i];
          if (sectionName === name) {
            this.setShowAllAparts();
            return;
          } else {
            if (!this.showTeamsSelectedSection()) {
              this.showTeam = false;
            }
          }
        }
      },
      userIsConnected(person) {
        // return if the user is connected
        try {
          if (person) {
            return person.connected;
          }
          return false;
        } catch {
          return false;
        }
      },
      removeCardsFromBannedSections(apartName) {
        // check banned cards for each apart, deleted before to show
        const disabledSections = this.state.namespaceSettings.disabledSections;
        for (let i = 0; i < disabledSections.length; i++) {
          const bannedSection = disabledSections[i];
          if (apartName === "departments") {
            for (const key in this.departmentsList) {
              if (this.departmentsList.hasOwnProperty(key)) {
                const department = this.departmentsList[key];
                if (department.section && department.section === bannedSection) {
                  delete this.departmentsList[key];
                }
              }
            }
          } else if (apartName === "teams") {
            const orgaDepartments = this.state.namespaceSettings
              .processedOrganisation.departments;
            for (const key in this.teamsList) {
              if (this.teamsList.hasOwnProperty(key)) {
                const team = this.teamsList[key];
                if (
                  team.department &&
                  orgaDepartments[team.department].section === bannedSection
                ) {
                  delete this.teamsList[key];
                }
              }
            }
          }
        }
      },
      checkSectionDisabled(name) {
        if (!this.amIAdmin) {
          const disabledSections = this.state.namespaceSettings.disabledSections;
          for (let i = 0; i < disabledSections.length; i++) {
            const section = disabledSections[i];
            if (section === name) {
              this.singleSectionDisabled = true;
              this.showDialog = true;
              return true;
            }
          }
        }
        return false;
      },
      closeInProgressModal() {
        this.showDialog = false;
        this.singleSectionDisabled = false;
      },
      callUser(uuid) {
        callPerson(uuid, this.$router);
      },
      setInitAdminView(value) {
        this.initAdminView = value;
      },
      getTeamsInitCardSelected() {
        // function to reset the teams apart
        let finalResult = {};
        let department;
        if (
          this.initViewCardSeletected.apart.section &&
          this.initViewCardSeletected.apart.section === "all"
        ) {
          // for 'all' section
          if (this.selectedSection.selected) {
            // show teams when we have a section active
            for (let i = 0; i < this.selectedSection.card.teams.length; i++) {
              const team = this.selectedSection.card.teams[i];
              finalResult[
                team
              ] = this.state.namespaceSettings.processedOrganisation.teams[team];
            }
          } else {
            // show teams if we don't have section active
            for (const key in this.latestDepartmentsList) {
              if (this.latestDepartmentsList.hasOwnProperty(key)) {
                const department = this.latestDepartmentsList[key];
                for (let i = 0; i < department.teams.length; i++) {
                  const team = department.teams[i];
                  finalResult[
                    team
                  ] = this.state.namespaceSettings.processedOrganisation.teams[
                    team
                  ];
                }
              }
            }
          }
        } else if (
          !this.initViewCardSeletected.apart.department &&
          this.initViewCardSeletected.apart.section
        ) {
          // if there is no department but has section
          const teams = this.state.namespaceSettings.processedOrganisation
            .sections[this.initViewCardSeletected.apart.section].teams;
          for (let i = 0; i < teams.length; i++) {
            const team = teams[i];
            finalResult[
              team
            ] = this.state.namespaceSettings.processedOrganisation.teams[team];
          }
        } else {
          department = this.departmentsList[
            this.initViewCardSeletected.apart.department
          ];
          for (let i = 0; i < department.teams.length; i++) {
            const team = department.teams[i];
            finalResult[
              team
            ] = this.state.namespaceSettings.processedOrganisation.teams[team];
          }
        }
        return finalResult;
      },
      getDepartmentsInitCardSelected() {
        let finalResult = {};
        const section = this.sectionsList[
          this.initViewCardSeletected.apart.section
        ];
        for (let i = 0; i < section.departments.length; i++) {
          const department = section.departments[i];
          finalResult[
            department
          ] = this.state.namespaceSettings.processedOrganisation.departments[
            department
          ];
        }
        return finalResult;
      },
      getSectionsInitCardSelected() {
        let finalResult = {};
        finalResult = this.state.namespaceSettings.processedOrganisation.sections || {};
        return finalResult;
      },
      disableCallBtn(personObj) {
        let person = personObj;
        if (person && person.user.uuid === this.ownUUID) return true;
        return disableCallBtn(person);
      },
      fullCall() {
        return this.state.isCallFull;
      },
      flipCard(reference) {
        if (reference === "flipSection-default") {
          this.$refs[reference].flipped = !this.$refs[reference].flipped;
        } else {
          this.$refs[reference][0].flipped = !this.$refs[reference][0].flipped;
        }
      },
      cleandAddWithDragAndDrop() {
        this.newSectionName = '';
        this.newDepartmentName = '';
        this.newTeamName = '';
        this.searchUsers = '';
      },
      handleClicksOld(event, name, section, reference) {
        if (event.type === "click") {
          this.cleandAddWithDragAndDrop();
          if (section == "sections") {
            this.selectSection(name, section);
          } else if (section === "departments") {
            this.selectDepartment(name, section);
          } else if (section === "teams") {
            this.selectTeam(name, section);
          }
        }
        this.cleanEditing()
      },
      handleClicks(event, name, section, reference) {
        if (event?.type === "click" || event === "auto") {
          this.cleandAddWithDragAndDrop();
          if (section == "sections") {
            this.selectSection(name, section);
          } else if (section === "departments") {
            this.selectDepartment(name, section);
          } else if (section === "teams") {
            this.selectTeam(name, section);
          }
        }
        this.updateSectionsList();
        this.updateDepartmentsList();
        this.updateTeamsList();
        this.cleanEditing()
      },
      getStuffBySectionOld(apart) {
        if (apart === "sections") {
          // to know, before organisationSections variable was this.sectionsList
          const organisationSections = this.state.namespaceSettings
            .processedOrganisation.sections;
          if (this.currentApart === "sections") {
            let sectionsListByCurrentSection = {};
            sectionsListByCurrentSection[this.currentCardName] = this.currentCard;
            return sectionsListByCurrentSection;
          } else if (this.currentApart === "teams") {
            let sectionsListByDepartments = {};
            if (this.currentCard.section) {
              sectionsListByDepartments[this.currentCard.section] =
                organisationSections[this.currentCard.section];
            }
            return sectionsListByDepartments;
          } else {
            let sectionsListByDepartments = {};
            for (const key in organisationSections) {
              if (organisationSections.hasOwnProperty(key)) {
                const section = organisationSections[key];
                if (
                  key === this.currentCard.section &&
                  organisationSections[this.currentCard.section] !== undefined
                ) {
                  sectionsListByDepartments[key] = section;
                }
              }
            }
            return sectionsListByDepartments;
          }
        } else if (apart === "departments") {
          // to know, before organisationDepartments variable was this.departmentsList
          const organisationDepartments = this.state.namespaceSettings
            .processedOrganisation.departments;
          if (this.currentApart === "departments") {
            let departmentsListByCurrentDepartment = {};
            departmentsListByCurrentDepartment[
              this.currentCardName
            ] = this.currentCard;
            return departmentsListByCurrentDepartment;
          } else if (this.currentApart === "teams") {
            let departmentsListBySection = {};
            for (const key in organisationDepartments) {
              if (organisationDepartments.hasOwnProperty(key)) {
                const department = organisationDepartments[key];
                for (let i = 0; i < department.teams.length; i++) {
                  const team = department.teams[i];
                  if (team === this.currentCardName) {
                    departmentsListBySection[key] = department;
                  }
                }
              }
            }

            return departmentsListBySection;
          } else {
            let departmentsListBySection = {};
            for (const key in organisationDepartments) {
              if (organisationDepartments.hasOwnProperty(key)) {
                const department = organisationDepartments[key];
                if (department.section === this.currentCardName) {
                  departmentsListBySection[key] = department;
                }
              }
            }
            return departmentsListBySection;
          }
        } else if (apart === "teams") {
          // to know, before organisationTeams variable was this.teamsList
          const organisationTeams = this.state.namespaceSettings
            .processedOrganisation.teams;
          // teams stuff
          // get the teams from departments where his section is the current active
          let teamsListBySection = {};
          if (this.currentApart === "teams") {
            teamsListBySection[this.currentCardName] = this.currentCard;
          } else if (this.currentApart === "sections") {
            for (const key in organisationTeams) {
              if (organisationTeams.hasOwnProperty(key)) {
                const team = organisationTeams[key];
                if (team.section === this.currentCardName) {
                  teamsListBySection[key] = team;
                }
              }
            }
          } else if (this.currentApart === "departments") {
            for (const key in organisationTeams) {
              if (organisationTeams.hasOwnProperty(key)) {
                const team = organisationTeams[key];
                if (team.department === this.currentCardName) {
                  teamsListBySection[key] = team;
                }
              }
            }
          }
          return teamsListBySection;
        }
      },
      getStuffBySection(apart) {
        if (apart === "sections") {
          // to know, before organisationSections variable was this.sectionsList
          const organisationSections = this.state.namespaceSettings
            .processedOrganisation.sections;
          if (this.currentApart === "sections") {
            let sectionsListByCurrentSection = {};
            sectionsListByCurrentSection[this.currentCardName] = this.currentCard;
            return sectionsListByCurrentSection;
          } else if (this.currentApart === "teams") {
            let sectionsListByDepartments = {};
            if (this.currentCard.section) {
              sectionsListByDepartments[this.currentCard.section] =
                organisationSections[this.currentCard.section];
            }
            return sectionsListByDepartments;
          } else {
            let sectionsListByDepartments = {};
            for (const key in organisationSections) {
              if (organisationSections.hasOwnProperty(key)) {
                const section = organisationSections[key];
                if (
                  key === this.currentCard.section &&
                  organisationSections[this.currentCard.section] !== undefined
                ) {
                  sectionsListByDepartments[key] = section;
                }
              }
            }
            return sectionsListByDepartments;
          }
        } else if (apart === "departments") {
          // to know, before organisationDepartments variable was this.departmentsList
          const organisationDepartments = this.state.namespaceSettings
            .processedOrganisation.departments;
          if (this.currentApart === "departments") {
            if(this.isDepartmentSelected) {
              // set the department where the user clicked
              let departmentsListByCurrentDepartment = {};
              departmentsListByCurrentDepartment[this.currentCardName] = this.currentCard;
              return departmentsListByCurrentDepartment;
            } else {
              // show the all the departments in the section uncolapsed
              let departmentsListBySection = {};
              for (const key in organisationDepartments) {
                if (organisationDepartments.hasOwnProperty(key)) {
                  const department = organisationDepartments[key];
                  if (department.section === this.currentCard.section) {
                    departmentsListBySection[key] = department;
                  }
                }
              }
              return departmentsListBySection;
            }
          } else if (this.currentApart === "teams") {
              if(this.isDepartmentSelected) {
                let departmentsListBySection = {};
                for (const key in organisationDepartments) {
                  if (organisationDepartments.hasOwnProperty(key)) {
                    const department = organisationDepartments[key];
                    for (let i = 0; i < department.teams.length; i++) {
                      const team = department.teams[i];
                      if (team === this.currentCardName) {
                        departmentsListBySection[key] = department;
                      }
                    }
                  }
                }
                return departmentsListBySection;
              } else {
                // show the all the departments in the section uncolapsed
              let departmentsListBySection = {};
              for (const key in organisationDepartments) {
                if (organisationDepartments.hasOwnProperty(key)) {
                  const department = organisationDepartments[key];
                  if (department.section === this.currentCard.section) {
                    departmentsListBySection[key] = department;
                  }
                }
              }
              return departmentsListBySection;
              }
          } else {
            let departmentsListBySection = {};
            for (const key in organisationDepartments) {
              if (organisationDepartments.hasOwnProperty(key)) {
                const department = organisationDepartments[key];
                if (department.section === this.currentCardName) {
                  departmentsListBySection[key] = department;
                }
              }
            }
            return departmentsListBySection;
          }
        } else if (apart === "teams") {
          // to know, before organisationTeams variable was this.teamsList
          const organisationTeams = this.state.namespaceSettings
            .processedOrganisation.teams;
          // teams stuff
          // get the teams from departments where his section is the current active
          let teamsListBySection = {};
          if (this.currentApart === "teams") {
            if(this.isTeamSelected) {
              teamsListBySection[this.currentCardName] = this.currentCard;
            } else {
              for (const key in organisationTeams) {
                if (organisationTeams.hasOwnProperty(key)) {
                  const team = organisationTeams[key];
                  if (team.department === this.currentCard.department) {
                    teamsListBySection[key] = team;
                  }
                }
              }
            }
          } else if (this.currentApart === "sections") {
            for (const key in organisationTeams) {
              if (organisationTeams.hasOwnProperty(key)) {
                const team = organisationTeams[key];
                if (team.section === this.currentCardName) {
                  teamsListBySection[key] = team;
                }
              }
            }
          } else if (this.currentApart === "departments") {
            for (const key in organisationTeams) {
              if (organisationTeams.hasOwnProperty(key)) {
                const team = organisationTeams[key];
                if (team.department === this.currentCardName) {
                  teamsListBySection[key] = team;
                }
              }
            }
          }
          return teamsListBySection;
        }
      },
      selectSectionInitView(name, apart) {
        this.initViewCardSeletected = {
          selected: true,
          apart: this.sectionsList[name],
          apartName: "sections",
        };
        this.setApart(name, this.sectionsList[name], "sections");
        Vue.set(this.currentCard, "active", true);
      },
      selectSectionOld(name, apart) {
        if (this.checkSectionDisabled(name)) {
          // do nothing
          return;
        }
        this.setInitAdminView(false);
        this.setShowMembers(false);
        if (
          !this.amIAdmin &&
          // !this.showAllSectionsVisibility &&
          this.initViewCardSeletected.apartName !== "sections"
        ) {
          this.initViewCardSeletected = {
            selected: true,
            apart: this.sectionsList[name],
            apartName: apart,
          };
          this.showDepartment = false;
          this.showTeam = false;
          return;
        } else {
          this.showEmptyTeams = false; /*#543 */
          // this.showAllSectionsVisibility = false;
          this.showDepartment = true;
          this.showTeam = false;
          this.setApart(name, this.sectionsList[name], "sections");
          if (
            this.currentCard.active === undefined ||
            this.currentCard.active === false
          ) {
            this.selectedSection = {
              card: this.currentCard,
              selected: true,
            };
            if (this.amIAdmin) {
              this.initViewCardSeletected = {
                selected: true,
                apart: this.sectionsList[name],
                apartName: apart,
              };
            }
            Vue.set(this.currentCard, "active", true);
            const response = this.showTeamsSelectedSection();
            if (response) {
              const showMembers = this.showMembersSelectedDepartment();
              if (showMembers) {
                this.setShowMembers(true);
              } else {
                this.setShowMembers(false);
              }
              this.showTeam = true;
            } else {
              this.showTeam = false;
            }
            this.checkShowAll(name);
            // mimi 1
            this.resetPlusSign('section')
          } else {
            this.selectedSection = {
              card: {},
              selected: false,
            };
            this.showEmptyTeams = true; /*#543 */
            this.resetApart();
            this.resetSections();
            this.setHideAllAparts();
            this.resetTeamSelected(false);
            this.setShowMembers(false);
            this.resetPlusSign('resetFull')
          }
        }
      },
      selectDepartmentOld(name, apart) {
        this.showEmptyTeams = false;
        this.setInitAdminView(false);
        this.setShowMembers(false);
        this.showTeam = true;
        this.resetMembers();
        if (this.departmentsList[name]) {
          this.setApart(name, this.departmentsList[name], "departments");
        } else {
          if (this.state.namespaceSettings.processedOrganisation.departments[name]) {
            this.setApart(name, this.state.namespaceSettings.processedOrganisation.departments[name], "departments");
          }
        }
        if (
          this.currentCard.active === undefined ||
          this.currentCard.active === false
        ) {
          Vue.set(this.currentCard, "active", true);
          const canShowMembers = this.showMembersSelectedDepartment();
          if (canShowMembers) {
            this.showMembers = true;
          } else {
            this.showMembers = false;
          }
          this.resetPlusSign('department')
        } else {
          this.initViewCardSeletected = {
            selected: true,
            apart: this.departmentsList[name],
            apartName: apart,
          };
          this.resetApart();
          this.resetSections();
          this.resetDepartments();
          this.showDepartment = true;
          this.showTeam = false;
          this.resetPlusSign('section')
        }
      },
      
      selectSection(name, apart) {
        this.isDepartmentSelected = false;
        this.isTeamSelected = false;
        // mimi 1
        this.resetPlusSign('section')
        if (this.checkSectionDisabled(name)) {
          // do nothing
          return;
        }
        this.setShowMembers(false);
        // normal first click
        if (!this.isSectionSelected)  {
          // set section selected as true
          this.isSectionSelected = true;
          this.showDepartment = true;
          this.showTeam = false;
          this.showEmptyTeams = false; /*#543 */
          this.setApart(name, this.sectionsList[name], "sections");
          Vue.set(this.currentCard, "active", true);
          this.selectedSection = {
            card: this.currentCard,
            selected: true,
          };
          this.initViewCardSeletected = {
            selected: true,
            apart: this.sectionsList[name],
            apartName: apart,
          };
        } else {
          if (!this.amIAdmin && this.initialUserView) this.initialUserView = false;
          this.initViewCardSeletected = {
              selected: false,
              apart: '',
              apartName: {},
            };
          this.isSectionSelected = false;
          this.showEmptyTeams = false; /*#543 */
          this.showTeam = false;
          // reset everything
          this.selectedSection = {
            card: {},
            selected: false,
          };
          this.setHideAllAparts();
          this.resetApart();
          this.resetSections();
          this.resetTeamSelected(false);
          this.resetDepartments();
          this.setShowMembers(false);
          this.resetPlusSign('resetFull')
        }
      },
      selectDepartment(name, apart) {
        this.isTeamSelected = false;
        if(!this.isDepartmentSelected) {
          this.isDepartmentSelected = true;
          this.setApart(name, this.departmentsList[name], "departments");
          Vue.set(this.currentCard, "active", true);
          this.initViewCardSeletected = {
            selected: true,
            apart: this.departmentsList[name],
            apartName: apart,
          };
          // open department clicked
          this.showEmptyTeams = false;
          this.showTeam = true;
          this.showMembers = false;
          // this.setInitAdminView(false);
          // this.setShowMembers(false);
          this.resetMembers();
          this.resetPlusSign('department')
        } else {
          this.isDepartmentSelected = false;
          this.initViewCardSeletected = {
              selected: false,
              apart: '',
              apartName: {},
            };
          // close department clicked
          this.showEmptyTeams = false;
          // this.setInitAdminView(false);
          this.setShowMembers(false);
          // this.showTeam = true;
          this.showTeam = false;
          this.resetMembers();
          // this.resetApart();
          // this.currentApart = "departments"
          this.resetDepartments();
          this.showDepartment = true;
          // this.showTeam = false;
          this.resetPlusSign('section')
        }
        // this.showEmptyTeams = false;
        // this.setInitAdminView(false);
        // this.setShowMembers(false);
        // this.showTeam = true;
        // this.resetMembers();
        // if (this.departmentsList[name]) {
        //   this.setApart(name, this.departmentsList[name], "departments");
        // } else {
        //   if (this.state.namespaceSettings.processedOrganisation.departments[name]) {
        //     this.setApart(name, this.state.namespaceSettings.processedOrganisation.departments[name], "departments");
        //   }
        // }
        // if (
        //   this.currentCard.active === undefined ||
        //   this.currentCard.active === false
        // ) {
        //   // Vue.set(this.currentCard, "active", true);
        //   // canShowMembers const always returns false
        //   // const canShowMembers = this.showMembersSelectedDepartment();
        //   // if (canShowMembers) {
        //   //   this.showMembers = true;
        //   // } else {
        //     this.showMembers = false;
        //   // }
        //   this.resetPlusSign('department')
        // } else {
        //   // this.initViewCardSeletected = {
        //   //   selected: true,
        //   //   apart: this.departmentsList[name],
        //   //   apartName: apart,
        //   // };
        //   this.resetApart();
        //   // this.resetSections();
        //   this.resetDepartments();
        //   this.showDepartment = true;
        //   this.showTeam = false;
        //   this.resetPlusSign('section')
        // }
      },
      showMembersSelectedDepartment() {
        // check if the current selected department has only one team, show the members of the team automatically
        if (this.currentCard.teams) {
          if (this.currentCard.teams.length === 1) {
            return false;
          }
        }
        return false;
      },
      showTeamsSelectedSection() {
        // check if the current selected section has only one department, show the teams of the department automatically
        if (this.currentCard.departments) {
          if (this.currentCard.departments.length === 1) {
            return false;
          }
        }
        return false;
      },
      selectTeam(name, apart) {
        if(!this.isTeamSelected){
          this.isTeamSelected = true;
            if (this.currentCard.department || this.currentCard.section) {
            // if the team has section or department else show empty teams
            this.showEmptyTeams = false;
          } else {
            this.showEmptyTeams = true;
          }
          this.resetMembers();
          this.setApart(name, this.teamsList[name], "teams");
          Vue.set(this.currentCard, "active", true);
          this.setShowMembers(true);
          // this.teamCardSelected(true);
          this.teamSelected = true;
          this.showEmptyDepartments = true;
          this.initViewCardSeletected = {
            selected: true,
            apart: this.teamsList[name],
            apartName: apart,
          };
          this.resetPlusSign('team')
          this.resetTeamSelected(false);
          this.resetSections();
          this.resetDepartments();
          this.resetTeams();
          this.showDepartment = true;
          this.showTeam = true;
          this.showMembers = true;
        } else {
          this.isTeamSelected = false;
          this.initViewCardSeletected = {
            selected: false,
            apart: '',
            apartName: {},
          };
          this.showMembers = false;
          this.resetPlusSign('department');
        }
        // if (this.currentCard.department || this.currentCard.section) {
        //   // if the team has section or department else show empty teams
        //   this.showEmptyTeams = false;
        // } else {
        //   this.showEmptyTeams = true;
        // }
        // this.setInitAdminView(false);
        // this.resetMembers();
        // this.setApart(name, this.teamsList[name], "teams");
        // if (
        //   this.currentCard.active === undefined ||
        //   this.currentCard.active === false
        // ) {
        //   this.setShowMembers(true);
        //   Vue.set(this.currentCard, "active", true);
        //   this.teamCardSelected(true);
        //   this.showEmptyDepartments = true;
        //   this.resetPlusSign('team')
        // }
        // else {
          // this.initViewCardSeletected = {
          //   selected: true,
          //   apart: this.teamsList[name],
          //   apartName: apart,
          // };
          // this.resetTeamSelected(false);
          // this.resetApart();
          // this.resetSections();
          // this.resetDepartments();
          // this.resetTeams();
          // this.showDepartment = true;
          // this.showTeam = true;
          // this.showMembers = false;
          // this.resetPlusSign('department');
        // }
      },
      selectTeamOld(name, apart) {
        if (this.currentCard.department || this.currentCard.section) {
          // if the team has section or department else show empty teams
          this.showEmptyTeams = false;
        } else {
          this.showEmptyTeams = true;
        }
        this.setInitAdminView(false);
        this.resetMembers();
        this.setApart(name, this.teamsList[name], "teams");
        if (
          this.currentCard.active === undefined ||
          this.currentCard.active === false
        ) {
          this.setShowMembers(true);
          Vue.set(this.currentCard, "active", true);
          this.teamCardSelected(true);
          this.showEmptyDepartments = true;
          this.resetPlusSign('team')
        } else {
          this.initViewCardSeletected = {
            selected: true,
            apart: this.teamsList[name],
            apartName: apart,
          };
          this.resetTeamSelected(false);
          this.resetApart();
          this.resetSections();
          this.resetDepartments();
          this.resetTeams();
          this.showDepartment = true;
          this.showTeam = true;
          this.showMembers = false;
          this.resetPlusSign('department');
        }
      },
      getAllCards(value) {
        this.showAllCards = value;
      },
      resetTeamSelected(value) {
        this.teamSelected = value;
      },
      resetSections() {
        for (const key in this.sectionsList) {
          if (this.sectionsList.hasOwnProperty(key)) {
            const section = this.sectionsList[key];
            if (section.active !== undefined) {
              delete section.active;
            }
          }
        }
      },
      resetDepartments() {
        for (const key in this.departmentsList) {
          if (this.departmentsList.hasOwnProperty(key)) {
            const department = this.departmentsList[key];
            if (department.active !== undefined) {
              delete department.active;
            }
          }
        }
      },
      resetTeams() {
        for (const key in this.teamsList) {
          if (this.teamsList.hasOwnProperty(key)) {
            const team = this.teamsList[key];
            if (team.active !== undefined) {
              delete team.active;
            }
          }
        }
      },
      resetMembers() {
        this.membersList = {};
        this.assistantsList = {};
      },
      resetApart() {
        // function to keep the last card and apart that the user clicked
        this.currentCard = {};
        this.currentCardName = "";
        this.currentApart = "";
      },
      getOtherCards(name) {
        // function to get the section, department and team card after selected a team card
        this.teamSelectedTeamCard = this.currentCard;
        this.teamSelectedTeamCard.name = name;

        this.teamSelectedDepartmentCard = this.departmentsList[
          this.currentCard.department
        ];
        this.teamSelectedDepartmentCard.name = this.currentCard.department;

        this.teamSelectedSectionCard = this.sectionsList[
          this.teamSelectedDepartmentCard.section
        ];
        this.teamSelectedSectionCard.name = this.teamSelectedDepartmentCard.section;
      },
      setShowMembers(value) {
        this.showMembers = value;
      },
      teamCardSelected(value) {
        this.teamSelected = value;
      },
      setApart(name, card, type) {
        this.showEmptyDepartments = false; /* #474*/
        // function to keep the last card and apart that the user clicked
        this.currentCard = card;
        this.currentCardName = name;
        this.currentApart = type;
      },
      getSupervisorUuid(value) {
        try {
          if (value.supervisors.length > 0) {
            return value.supervisors[0].uuid;
          } else {
            return false;
          }
        } catch {}
      },
      getTitelForUuid(uuid) {
        return store.getTitelForUuid(uuid);
      },
      getSupervisorTitel(member) {
        return store.getTitelForUuid(member.uuid);
      },
      getUserName(member) {
        try {
          if (member.user) {
            if (member.user.name === "unknown") {
              return "";
            } else {
              return member.user.name;
            }
          } else {
            return "";
          }
        } catch {}
      },
      getMemberQualification(member) {
        try {
          if (member.user) {
            if (member.user.qualification === "unknown") {
              return "";
            } else {
              return member.user.qualification || "";
            }
          } else {
            return "";
          }
        } catch {}
      },
      getMemberPosition(member) {
        try {
          if (member.user) {
            if (member.user.position === "unknown") {
              return "";
            } else {
              return member.user.position || "";
            }
          } else {
            return "";
          }
        } catch {}
      },
      getSectionName(card, type) {
        if (type === "departments" || type === "teams") {
          return card.section;
        }
      },
      getDepartmentName(card, type) {
        if (type === "teams") {
          return card.department;
        }
      },
      isUserRepeated(card, type) {
        try {
          if (type === "sections") {
            return false;
          }
          if (type === "departments") {
            if (card.supervisors.length > 0) {
              for (const key in this.sectionsList) {
                if (this.sectionsList.hasOwnProperty(key)) {
                  const section = this.sectionsList[key];
                  if (
                    card.supervisors[0] &&
                    section.supervisors[0] &&
                    card.supervisors[0].uuid === section.supervisors[0].uuid
                  ) {
                    return true;
                  }
                }
              }
            }
            return false;
          }
          if (type === "teams") {
            if (card.supervisors.length > 0) {
              for (const key in this.sectionsList) {
                if (this.sectionsList.hasOwnProperty(key)) {
                  const section = this.sectionsList[key];
                  if (
                    card.supervisors[0] &&
                    section.supervisors[0] &&
                    card.supervisors[0].uuid === section.supervisors[0].uuid
                  ) {
                    return true;
                  }
                }
              }
              for (const key in this.departmentsList) {
                if (this.departmentsList.hasOwnProperty(key)) {
                  const department = this.departmentsList[key];
                  if (
                    card.supervisors[0] &&
                    department.supervisors[0] &&
                    card.supervisors[0].uuid === department.supervisors[0].uuid
                  ) {
                    return true;
                  }
                }
              }
            }
            return false;
          }
          if (type === "members") {
            if (card) {
              for (const key in this.sectionsList) {
                if (this.sectionsList.hasOwnProperty(key)) {
                  const section = this.sectionsList[key];
                  if (
                    card &&
                    section.supervisors[0] &&
                    card.uuid === section.supervisors[0].uuid
                  ) {
                    return true;
                  }
                }
              }
              for (const key in this.departmentsList) {
                if (this.departmentsList.hasOwnProperty(key)) {
                  const department = this.departmentsList[key];
                  if (
                    card &&
                    department.supervisors[0] &&
                    card.uuid === department.supervisors[0].uuid
                  ) {
                    return true;
                  }
                }
              }
              for (const key in this.teamsList) {
                if (this.teamsList.hasOwnProperty(key)) {
                  const team = this.teamsList[key];
                  if (
                    card &&
                    team.supervisors[0] &&
                    card.uuid === team.supervisors[0].uuid
                  ) {
                    return true;
                  }
                }
              }
              return false;
            }
          }
        } catch {
          return false;
        }
      },
      getSupervisorAvatar(card, type) {
        const defaultImage = "img/default_profile_picture.png";
        try {
          if (type === "sections") {
            // loop in sections searching for the card name
            if (card.supervisors.length > 0) {
              return (
                this.state.group[card.supervisors[0].uuid].user.avatar ||
                defaultImage
              );
            } else {
              return defaultImage;
            }
          }
          if (type === "departments") {
            // loop in departments searching for the card name
            let returnDefaultImage = false;
            if (card.supervisors.length > 0) {
              for (const key in this.sectionsList) {
                if (this.sectionsList.hasOwnProperty(key)) {
                  const section = this.sectionsList[key];
                  if (
                    card.supervisors[0].uuid &&
                    section.supervisors[0] &&
                    card.supervisors[0].uuid === section.supervisors[0].uuid
                  ) {
                    // same user supervisor as sections, dont repeat img
                    returnDefaultImage = true;
                    return;
                  }
                }
              }
              if (returnDefaultImage == true) {
                return defaultImage;
              } else {
                return (
                  this.state.group[card.supervisors[0].uuid].user.avatar ||
                  defaultImage
                );
              }
            } else {
              return defaultImage;
            }
          }
          if (type === "teams") {
            let returnDefaultImage = false;
            // loop in departments searching for the card name
            if (card.supervisors.length > 0) {
              for (const key in this.departmentsList) {
                if (this.departmentsList.hasOwnProperty(key)) {
                  const department = this.departmentsList[key];
                  if (
                    card.supervisors[0] &&
                    department.supervisors[0] &&
                    (card.supervisors[0].uuid ===
                      department.supervisors[0].uuid ||
                      card.supervisors[0].uuid === this.section)
                  ) {
                    // same user supervisor as departments, dont repeat img
                    returnDefaultImage = true;
                    return;
                    // return defaultImage;
                  }
                }
              }
              if (returnDefaultImage == true) {
                return defaultImage;
              } else {
                return (
                  this.state.group[card.supervisors[0].uuid].user.avatar ||
                  defaultImage
                );
              }
            } else {
              return defaultImage;
            }
          }
          if (type === "members") {
            // loop in departments searching for the card name
            if (card.uuid) {
              return this.state.group[card.uuid].user.avatar || defaultImage;
            }
            return defaultImage;
          }
        } catch {
          return defaultImage;
        }
      },
      getSupervisorPosition(section) {
        try {
          if (section.supervisors.length > 0) {
            try {
              if (this.state.group[section.supervisors[0].uuid].user.position) {
                return this.state.group[section.supervisors[0].uuid].user
                  .position;
              }
            } catch {}
          } else {
            return null;
          }
        } catch {
          return null;
        }
      },
      setBorderByStatus(person) {
        // for members
        try {
          return getStatusColorByUser(person.user, person.connected);
        } catch {}
      },
      getPersonByUuid(uuid){
        return store.getPersonByUuid(uuid);
      },
      getSupervisorPerson(section) {
        try {
          if (
            section.supervisors.length > 0 &&
            this.state.group[section.supervisors[0].uuid]
          ) {
            this.state.group[section.supervisors[0].uuid].uuid =
              section.supervisors[0].uuid;
            return this.state.group[section.supervisors[0].uuid];
          } else {
            return {};
          }
        } catch {
          return {};
        }
      },
      checkApartSupervisors(apart) {
        let imSupervisor = {};
        let notSupervisor = {};
        let finalObject = {};
        Object.keys(apart || {})
          .sort()
          .forEach((apartName) => {
            // check if the section have supervisors, else push the object to the normal sections (I'm not the supervisor)
            if (apart[apartName]) {
              if (apart[apartName].supervisors.length > 0) {
                for (let i = 0; i < apart[apartName].supervisors.length; i++) {
                  const supervisorUUID = apart[apartName].supervisors[i].uuid;
                  if (supervisorUUID == this.actualUser) {
                    // I'm the supervisor of the section. Before to push the object, check if it was pushed to notSupervisor variable
                    if (notSupervisor[apartName]) {
                      // delete the object
                      delete notSupervisor[apartName];
                    }
                    imSupervisor[apartName] = apart[apartName];
                    break;
                  } else {
                    // I'm not the supervisor of the section
                    notSupervisor[apartName] = apart[apartName];
                  }
                }
              } else {
                notSupervisor[apartName] = apart[apartName];
              }
            }
          });
        // join both variables to show the supervisors first
        finalObject = Object.assign(JSON.parse(JSON.stringify(imSupervisor)), JSON.parse(JSON.stringify(notSupervisor)));
        return finalObject;
      },

      getFavoriteSections() {
        const favorites = this.state.namespaceSettings.favoriteSections;
        let temporalSections = {};
        for (let i = 0; i < favorites.length; i++) {
          const section = favorites[i];
          temporalSections[
            section
          ] = this.state.namespaceSettings.processedOrganisation.sections[
            section
          ];
        }
        return temporalSections;
      },
      getMySections() {
        const myUser = this.actualUser;
        if (this.userList[myUser]) {
          // i'm in the list
          if (this.userList[myUser].supervisorOfSections) {
            const sections = this.userList[myUser].supervisorOfSections;
            // i'm the supervisor of any section
            for (let i = 0; i < sections.length; i++) {
              const section = sections[i];
              this.sectionsList[
                section
              ] = this.state.namespaceSettings.processedOrganisation.sections[
                section
              ];
            }
          }
          // check if i'm part of another section (not supervisor just I have assigned in q section)
          if (this.userList[myUser].teamSections) {
            const sections = this.userList[myUser].teamSections;
            for (let i = 0; i < sections.length; i++) {
              const section = sections[i];
              this.sectionsList[
                section
              ] = this.state.namespaceSettings.processedOrganisation.sections[
                section
              ];
            }
          }
          if (this.userList[myUser].supervisorOfDepartments) {
            const departments = this.userList[myUser].supervisorOfDepartments;
            for (let i = 0; i < departments.length; i++) {
              const department = departments[i];
              this.sectionsList[
                this.state.namespaceSettings.processedOrganisation.departments[
                department
              ].section
              ] = this.state.namespaceSettings.processedOrganisation.sections[
                this.state.namespaceSettings.processedOrganisation.departments[
                department
              ].section
              ];
            }
          }
        }
      },
      getMyDepartments(departList) {
        const temporalDeparts = {};
        const myUser = this.actualUser;
        if (this.userList[myUser]) {
          // i'm in the list
          if (this.userList[myUser].supervisorOfDepartments) {
            const departments = this.userList[myUser].supervisorOfDepartments;
            // i'm the supervisor of some section
            for (let i = 0; i < departments.length; i++) {
              const department = departments[i];
              if (departList[department]) {
                temporalDeparts[
                  department
                ] = this.state.namespaceSettings.processedOrganisation.departments[
                  department
                ];
              }
            }
          }
          // check if i'm part of another department (not supervisor just I have assigned in a department)
          if (this.userList[myUser].teamDepartments) {
            const departments = this.userList[myUser].teamDepartments;
            for (let i = 0; i < departments.length; i++) {
              const department = departments[i];
              if (departList[department]) {
                temporalDeparts[
                  department
                ] = this.state.namespaceSettings.processedOrganisation.departments[
                  department
                ];
              }
            }
          }
          for (const element in departList) {
            if (Object.hasOwnProperty.call(departList, element)) {
              const depart = departList[element];
              const section = depart.section;
              // team is inside the section where the user is supervisor
              if( this.userList[myUser]?.supervisorOfSections && this.userList[myUser]?.supervisorOfSections?.indexOf(section) !== -1
                ) {
                temporalDeparts[
                  element
                ] = this.state.namespaceSettings.processedOrganisation.departments[element];
              }
            }
          }
        }
        return temporalDeparts;
      },
      getMyTeams(teamList) {
        const temporalTeams = {}
        const myUser = this.actualUser;
        if (this.userList[myUser]) {
          // i'm in the list
          if (this.userList[myUser].supervisorOfTeams) {
            const teams = this.userList[myUser].supervisorOfTeams;
            // i'm the supervisor of any team
            for (let i = 0; i < teams.length; i++) {
              const team = teams[i];
              if (teamList[team]) {
                temporalTeams[
                  team
                ] = this.state.namespaceSettings.processedOrganisation.teams[team];
              }
            }
          }
          // check if i'm part of another team (not supervisor just I have assigned in a team)
          if (this.userList[myUser].memberOfTeams) {
            const teams = this.userList[myUser].memberOfTeams;
            for (let i = 0; i < teams.length; i++) {
              const team = teams[i];
              if (teamList[team]) {
                temporalTeams[
                  team
                ] = this.state.namespaceSettings.processedOrganisation.teams[team];
              }
            }
          }
          for (const team in teamList) {
            if (Object.hasOwnProperty.call(teamList, team)) {
              const element = teamList[team];
              const section = element.section;
              const department = element.department;
              // team is inside the section where the user is supervisor
              if( (this.userList[myUser]?.supervisorOfSections && this.userList[myUser]?.supervisorOfSections?.indexOf(section) !== -1) ||
                  ( this.userList[myUser]?.supervisorOfDepartments && this.userList[myUser]?.supervisorOfDepartments?.indexOf(department) !== 1)
                ) {
                temporalTeams[
                  team
                ] = this.state.namespaceSettings.processedOrganisation.teams[team];
              }
            }
          }
        }
        return temporalTeams;
      },
      getAloneTeams() {
        // function to get the teams that don't have departments/sections assigned
        const allTeams = this.state.namespaceSettings.processedOrganisation.teams;
        for (const key in allTeams) {
          if (allTeams.hasOwnProperty(key)) {
            const team = allTeams[key];
            if (!team.department && !team.section) {
              this.teamsList[key] = team;
            }
          }
        }
      },
      getAloneDepartments() {
        // function to get the departments that don't have sections assigned
        const allDeparts = this.state.namespaceSettings.processedOrganisation
          .departments;
        for (const key in allDeparts) {
          if (allDeparts.hasOwnProperty(key)) {
            const depart = allDeparts[key];
            if (!depart.section) {
              this.departmentsList[key] = depart;
            }
          }
        }
      },
      getTeamsBySingleDepartment() {
        this.teamsList = {};
        const allTeams = this.state.namespaceSettings.processedOrganisation.teams;
        // function to show all teams when a section has only one department
        for (const key in allTeams) {
          if (allTeams.hasOwnProperty(key)) {
            const team = allTeams[key];
            if (team.department === this.currentCard.departments[0]) {
              this.teamsList[key] = team;
            }
          }
        }
      },
      hasRepresentative(uuid) {
        return hasRepresentative(uuid);
      },
      getRepresentativeId(uuid) {
        return (((this.state.group[uuid] || {}).user || {}).representative || {}).value || '';
      },
      getRepresentativeName(uuid) {
        return store.getUserRepresentativeName(uuid);
      },
    },
    computed: {
      isUserInOrganigram() {
        return userInOrganigram(this.actualUser);
      },
      isEditableView() {
        return this.state.user.organisationEditable;
      },
      showModalMoveItem() {
        return this.moveItem.active;
      },
      getIsEditing() {
        return this.isEditing.active;
      },
      getEditingType() {
        return this.isEditing.type;
      },
      structureToDelete() {
        switch (this.structureToDeleteType) {
          case "section":
            return this.selectedSection;
          case "department":
            return this.selectedDepartment;
          case "team":
            return this.selectedTeam;
        }
      },
      isDeleteDisabled() {
        return this.structureToDelete != null && this.structureToDelete[this.structureToDeleteType] != this
          .repeatStructureName
      },
      users() {
        return sortBy(entries(this.state.group).map(([uuid, value]) => ({
          uuid,
          permissions: value.permissions,
          ...value.user
        })), ["name"]).filter(user => user && !user.guest && !user.visitor && user.permissions != 10)

      },
      usersSearchF() {
        if (this.searchUsers && this.searchUsers !== '' && this.searchUsers.length >= 2) {
          return sortBy(entries(this.state.group).map(([uuid, value]) => ({
            uuid,
            permissions: value.permissions,
            ...value.user
          })), ["name"]).filter(user => user && ((user || {}).name || "").toLowerCase().indexOf(this.searchUsers
            .toLowerCase()) !== -1 && !user.guest && !user.visitor && user.permissions != 10)
        } else {
          return sortBy(entries(this.state.group).map(([uuid, value]) => ({
            uuid,
            permissions: value.permissions,
            ...value.user
          })), ["name"]).filter(user => !user.guest && !user.visitor && user.permissions != 10)
        }
      },
      isRaMicro() {
        return webLicensedBaseFeatures.isRaMicro || webLicensedBaseFeatures.isRaMicroCustomer;
      },
      showOrganigram() {
        return canIShowOrganigram();
      },
      getPictureSize() {
        return getPictureSize();
      },
      showSectionAssistants() {
        if (Object.keys(this.specificApartAssistants("sections")).length > 0) {
          return true;
        }
        return false;
      },
      showDepartmentAssistants() {
        if (Object.keys(this.specificApartAssistants("departments")).length > 0) {
          return true;
        }
        return false;
      },
      hideRepeatedDepartmentAssistants() {
        if (this.showDepartmentAssistants) {
          const sectionName = Object.keys(this.sections)[0];
          const sectionSupervisorUUID = this.sections[sectionName].supervisors[0]
            .uuid;
          const departmentName = Object.keys(this.departments)[0];
          const departmentSupervisorUUID = this.departments[departmentName]
            .supervisors[0].uuid;
          if (sectionSupervisorUUID === departmentSupervisorUUID) {
            return true;
          } else {
            return false;
          }
        }
      },
      statsNav() {
        let totalSections = [];
        const sections = Object.keys(
          this.state.namespaceSettings.processedOrganisation.sections
        );
        for (let i = 0; i < sections.length; i++) {
          const section = sections[i];
          if (section !== "all") {
            totalSections.push(section);
          }
        }
        const totalDepartments = Object.keys(
          this.state.namespaceSettings.processedOrganisation.departments
        ).length;
        const totalTeams = Object.keys(
          this.state.namespaceSettings.processedOrganisation.teams
        ).length;
        return `${totalSections.length} ${this.$t(
        "generics.sections"
      )} / ${totalDepartments} ${this.$t(
        "generics.departments"
      )} / ${totalTeams} ${this.$t("components.teams.teams")}`;
      },
      visitedUSerId() {
        // function just for update component
        return this.$route.params.uuid || this.state.ownUUID;
      },
      getAssistantSection() {
        const temporalSectionName = Object.keys(
          this.state.namespaceSettings.processedOrganisation.sections
        )[0];
        return temporalSectionName;
      },
      amIAdmin() {
        let justForUpdate = this.visitedUSerId;
        justForUpdate = this.actualUser;
        return store.getUserIsAdmin(justForUpdate);
      },
      defaultSectionAdmin() {
        return store.getAdminList()[0];
      },
      amInACall() {
        return !!Object.keys(this.state.rtc).length;
      },
      userList() {
        return store.getProcessedUserList();
      },
      sectionsOld() {
        if (this.currentCard && this.currentCard.section === "all") {
          this.sectionsList = this.latestSectionsList;
          return this.sectionsList;
        } else {
          // this condition does nothing, just update the sections at the beggining of the component.
          if (this.test) {}
          if (
            (this.amIAdmin && this.initAdminView) ||
            // this.showAllSectionsVisibility ||
            (this.currentApart === "" &&
              this.state.user.userSettings.startView === "organisation")
          ) {
            
            // show all sections
            this.getAllSections();
          } else {
            if (this.currentApart === "sections") {
              // insert in the sections list only the current selected section
              const newSectionsList = this.getStuffBySection("sections");
              // I selected a section card and departs with the sections selected needs to be returned
              const temporalSectionsList = this.checkApartSupervisors(
                newSectionsList
              );
              this.sectionsList = temporalSectionsList;
            } else if (this.currentApart === "departments") {
              const newSectionsList = this.getStuffBySection("sections");
              // I selected a section card and departs with the sections selected needs to be returned
              const temporalSectionsList = this.checkApartSupervisors(
                newSectionsList
              );
              // return temporalDepartments
              this.sectionsList = temporalSectionsList;
            } else if (this.currentApart === "teams") {
              const newSectionsList = this.getStuffBySection("sections");
              // I selected a section card and departs with the sections selected needs to be returned
              const temporalSectionsList = this.checkApartSupervisors(
                newSectionsList
              );
              // return temporalDepartments
              this.sectionsList = temporalSectionsList;
            } else {
              if (
                this.initViewCardSeletected.selected === true &&
                this.initViewCardSeletected.apartName == "sections"
              ) {
                this.getAllSections();
              } else if (this.showEmptyTeams) {
                //  reset the view
                this.getAllSections();
              } else if (
                (this.initViewCardSeletected.selected === true &&
                  this.initViewCardSeletected.apartName == "teams") ||
                this.initViewCardSeletected.apartName == "departments"
              ) {
                // just to show the curretn apart
              } else {
                if (
                  this.state.namespaceSettings.organisationFavorite ===
                  "multiSections" &&
                  this.state.namespaceSettings.favoriteSections.length > 0
                ) {
                  this.sectionsList = this.getFavoriteSections();
                } else {
                  // start get sections where the user is part of it
                  this.getMySections();
                }
                if (Object.keys(this.sectionsList).length === 0) {
                  // if the user (not admin) has nothing to show, show all sections..
                  this.getAllSections();
                } else {
                  // end
                  // get sections where the user is part of it
                  const temporalSectionsList = this.checkApartSupervisors(
                    this.sectionsList
                  );
                  this.sectionsList = temporalSectionsList;
                }
              }
            }
          }
          // delete 'all' section (section to put all teams #504)
          delete this.sectionsList["all"];
          this.latestSectionsList = this.sectionsList;
          return this.sectionsList;
        }
      },
      sections() {
        return this.sectionsList;
      },
      departmentsOld() {
        let temporalAssistants = {};
        if (this.currentCard && this.currentCard.section === "all") {
          this.departmentsList = this.latestDepartmentsList;
          return this.departmentsList;
        } else {
          // if (!this.amIAdmin && this.showAllSectionsVisibility) {
          //   return (this.departmentsList = {});
          // }
          // this code is for if they want to sort the departments in order to if the own user is the supervisors of each departments like sections functionality)
          if (this.currentApart === "departments") {
            // insert in the department list only the current selected department card
            const newDepartmentsList = this.getStuffBySection("departments");
            // I selected a section card and departs with the sections selected needs to be returned
            const temporalDepartments = this.checkApartSupervisors(
              newDepartmentsList
            );
            this.departmentsList = temporalDepartments;
          } else if (
            this.currentApart === "sections" ||
            this.currentApart === "teams"
          ) {
            if (this.currentApart === "sections") {
              temporalAssistants = this.assistants;
            }
            const newDepartmentsList = this.getStuffBySection("departments");
            // I selected a section card and departs with the sections selected needs to be returned
            const temporalDepartments = this.checkApartSupervisors(
              newDepartmentsList
            );
            this.departmentsList = temporalDepartments;
          } else {
            if (
              this.initViewCardSeletected.selected === true &&
              this.initViewCardSeletected.apartName == "departments"
            ) {
              // get all departments by current click team card section
              const temporalDepartments = this.getDepartmentsInitCardSelected();
              this.departmentsList = this.checkApartSupervisors(
                temporalDepartments
              );
            } else if (
              this.initViewCardSeletected.selected === true &&
              this.initViewCardSeletected.apartName == "teams"
            ) {
              // just to show the current apart
            } else {
              this.departmentsList = {};
              if (
                this.showEmptyDepartments &&
                this.actualUser === this.state.ownUUID
              ) {
                this.getAloneDepartments();
              } else if (
                this.showEmptyDepartments &&
                this.actualUser !== this.state.ownUUID
              ) {
                this.getMyDepartments();
                const temporalDepartments = this.checkApartSupervisors(
                  this.departmentsList
                );
                this.departmentsList = temporalDepartments;
              } else {
                this.getMyDepartments();
                const temporalDepartments = this.checkApartSupervisors(
                  this.departmentsList
                );
                this.departmentsList = temporalDepartments;
              }
            }
          }
          if (!this.amIAdmin) {
            this.removeCardsFromBannedSections("departments");
          }
          this.latestDepartmentsList = this.departmentsList;
          let temporalAssistantDeparts = {};
          if (this.state.namespaceSettings.displayAssistants) {
            temporalAssistantDeparts = Object.assign(
              JSON.parse(JSON.stringify(temporalAssistants )),
              JSON.parse(JSON.stringify(this.departmentsList ))
            );
          } else {
            temporalAssistantDeparts = Object.assign(
              JSON.parse(JSON.stringify(this.departmentsList )),
              JSON.parse(JSON.stringify(temporalAssistants ))
            );
          }
          return temporalAssistantDeparts;
        }
      },
      departments() {
        return this.departmentsList;
      },
      teams() {
        return this.teamsList;
        // if (!this.amIAdmin && this.showAllSectionsVisibility) {
        //   return (this.teamsList = {});
        // }
        // this code is for if they want to sort the teams in order to if the own user is the supervisors of each teams like sections functionality)
        
        // return this.teamsList;
      },
      membersOld() {
        if (this.teamSelected) {
          for (let uuid in this.state.group) {
            if (this.userList[uuid]) {
              const currentMember = this.userList[uuid];
              if (currentMember.memberOfTeams) {
                for (let i = 0; i < currentMember.memberOfTeams.length; i++) {
                  const currentTeam = currentMember.memberOfTeams[i];
                  if (this.currentCardName === currentTeam) {
                    Vue.set(this.membersList, uuid, {
                      ...this.state.group[uuid],
                      uuid,
                    });
                    break;
                  }
                }
              }
            }
          }
        } else if (
          this.currentApart === "departments" &&
          this.showMembersSelectedDepartment()
        ) {
          // show members of a team if the current department has only one team
          for (let uuid in this.state.group) {
            if (this.userList[uuid]) {
              const currentMember = this.userList[uuid];
              if (currentMember.memberOfTeams) {
                for (let i = 0; i < currentMember.memberOfTeams.length; i++) {
                  const currentTeam = currentMember.memberOfTeams[i];
                  if (this.currentCard.teams[0] === currentTeam) {
                    Vue.set(this.membersList, uuid, {
                      ...this.state.group[uuid],
                      uuid,
                    });
                    break;
                  }
                }
              }
            }
          }
        } else if (
          this.currentApart === "sections" &&
          this.showMembersSelectedDepartment()
        ) {
          // show members of a team if the current department has only one team
          for (let uuid in this.state.group) {
            if (this.userList[uuid]) {
              const currentMember = this.userList[uuid];
              if (currentMember.memberOfTeams) {
                for (let i = 0; i < currentMember.memberOfTeams.length; i++) {
                  const currentTeam = currentMember.memberOfTeams[i];
                  if (this.currentCard.teams[0] === currentTeam) {
                    Vue.set(this.membersList, uuid, {
                      ...this.state.group[uuid],
                      uuid,
                    });
                    break;
                  }
                }
              }
            }
          }
        } else {
          // show all members for the teams that there are in the teamsList(teams that we are showing)
          this.resetMembers();
          if (this.actualUser !== this.state.ownUUID) {
            // code to show at the beginning only the user card
            const actualUser = this.actualUser;
            if (this.userList[actualUser]) {
              const currentMember = this.userList[actualUser];
              if (currentMember.memberOfTeams) {
                for (let i = 0; i < currentMember.memberOfTeams.length; i++) {
                  const currentTeam = currentMember.memberOfTeams[i];
                  for (const key in this.teamsList) {
                    if (this.teamsList.hasOwnProperty(key)) {
                      // const team = this.teamsList[key];
                      if (key === currentTeam) {
                        Vue.set(this.membersList, actualUser, {
                          ...this.state.group[actualUser],
                          actualUser,
                        });
                        break;
                      }
                    }
                  }
                }
              }
            } else {
              for (let uuid in this.state.group) {
                for (const key in this.teamsList) {
                  if (this.teamsList.hasOwnProperty(key)) {
                    const team = this.teamsList[key];
                    for (let index = 0; index < team.members.length; index++) {
                      const memberUUID = team.members[index];
                      if (uuid === memberUUID)
                        Vue.set(this.membersList, uuid, {
                          ...this.state.group[uuid],
                          uuid,
                        });
                    }
                    break;
                  }
                }
              }
            }
          }
        }
        return this.membersList;
      },
      members() {
        if (this.isTeamSelected) {
          for (let uuid in this.state.group) {
            if (this.userList[uuid]) {
              const currentMember = this.userList[uuid];
              if (currentMember.memberOfTeams) {
                for (let i = 0; i < currentMember.memberOfTeams.length; i++) {
                  const currentTeam = currentMember.memberOfTeams[i];
                  if (this.currentCardName === currentTeam) {
                    Vue.set(this.membersList, uuid, {
                      ...this.state.group[uuid],
                      uuid,
                    });
                    break;
                  }
                }
              }
            }
          }
        }
        else {
          // show all members for the teams that there are in the teamsList(teams that we are showing)
          this.resetMembers();
          if (this.actualUser !== this.state.ownUUID) {
            // code to show at the beginning only the user card
            const actualUser = this.actualUser;
            if (this.userList[actualUser]) {
              const currentMember = this.userList[actualUser];
              if (currentMember.memberOfTeams) {
                for (let i = 0; i < currentMember.memberOfTeams.length; i++) {
                  const currentTeam = currentMember.memberOfTeams[i];
                  for (const key in this.teamsList) {
                    if (this.teamsList.hasOwnProperty(key)) {
                      // const team = this.teamsList[key];
                      if (key === currentTeam) {
                        Vue.set(this.membersList, actualUser, {
                          ...this.state.group[actualUser],
                          actualUser,
                        });
                        break;
                      }
                    }
                  }
                }
              }
            } else {
              for (let uuid in this.state.group) {
                for (const key in this.teamsList) {
                  if (this.teamsList.hasOwnProperty(key)) {
                    const team = this.teamsList[key];
                    for (let index = 0; index < team.members.length; index++) {
                      const memberUUID = team.members[index];
                      if (uuid === memberUUID)
                        Vue.set(this.membersList, uuid, {
                          ...this.state.group[uuid],
                          uuid,
                        });
                    }
                    break;
                  }
                }
              }
            }
          }
        }
        return this.membersList;
      },
      assistants() {
        this.assistantsList = {};
        // get assistants
        try {
          if (this.currentApart === "sections") {
            for (let i = 0; i < this.currentCard.assistance.length; i++) {
              const assistantUuid = this.currentCard.assistance[i];
              if (store.state.group[assistantUuid]) {
                Vue.set(this.assistantsList, assistantUuid, {
                  ...this.state.group[assistantUuid],
                  uuid: assistantUuid,
                });
              }
            }
          } else if (this.currentApart === "departments") {
            const section = this.state.namespaceSettings.processedOrganisation
              .sections[this.currentCard.section];
            for (let i = 0; i < section.assistance.length; i++) {
              const assistantUuid = section.assistance[i];
              if (store.state.group[assistantUuid]) {
                Vue.set(this.assistantsList, assistantUuid, {
                  ...this.state.group[assistantUuid],
                  uuid: assistantUuid,
                });
              }
            }
          } else if (this.currentApart === "teams") {
            const teamSection = this.currentCard.section;
            const section = this.state.namespaceSettings.processedOrganisation
              .sections[teamSection];
            for (let i = 0; i < section.assistance.length; i++) {
              const assistantUuid = section.assistance[i];
              if (store.state.group[assistantUuid]) {
                Vue.set(this.assistantsList, assistantUuid, {
                  ...this.state.group[assistantUuid],
                  uuid: assistantUuid,
                });
              }
            }
          }
        } catch {
          this.assistantsList = {};
          return this.assistantsList;
        }

        return this.assistantsList;
      },
      featureOrganizationOption() {
        return store.state.namespaceSettings.featureOrganization;
      },
    },
    updated() {
      const userUUID = this.$route.params.uuid || this.state.ownUUID;
      if (this.actualUser !== userUUID) {
        this.resetEverything();
        this.actualUser = userUUID;
        this.mountedData();
      }
    },
  };
</script>

<style scoped lang="scss">
  .arrowIcon {
    color: #02889D;
  }
  .w50{
    width: 50%;
  }
  .w100{
    width: 100%;
  }
  .h100{
    height: 100%;
  }
  .editSupervisorCard{
    background-size: cover;
    background-position: center;
    background-color: black;
    width: 100%;
    height: 100%;
    min-width: 50%;
  }
  .custom6colsViewing {
    width: 8%;
    flex-basis: 8%;
    justify-content: revert;
    align-self: flex-end;
    position: relative;
    bottom:40px;
    .apartTitleViewMode {
      position: absolute;
      bottom: 110px;
    }
  }

  .cursorPointer {
    cursor: pointer;
  }
  
  .deleteBasketSupervisor{
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  .deleteBasketOrga {
    position: absolute;
    top: 6px;
    left: 6px;
    background: white;
    padding: 5px;
    border-radius: 50%;
    z-index: 999;
  }

  .ghost {
    display: none !important;
  }

  .divCardAdd {
    height: 125px;
    display: table;
    width: 100%;

    &.assitants {
      height: 180px;
    }

    .divAdd {
      display: table-cell;
      vertical-align: middle;
      text-align: center;

      .buttonAdd {
        font-size: 20px;
        color: var(--v-primary-base);
      }
    }
  }

  .theme--light .rowUsers {
    background: #f5f5f5;
  }

  .theme--dark .rowUsers {
    background: #272727;
  }

  .listUsers {
    position: fixed;
    width: 263px;
    right: 20px;
    .header {
      background-color: var(--v-primary-base);
    }

    .rowUsers {
      // height: calc(100vh - 190px);
      overflow: auto;
      max-height: 80vh;

      .v-list {
        background: transparent !important;
        width: 100%;

        .v-list-item:not(.theme--dark) {
          background: white !important
        }

        .v-avatar {
          border-radius: 5px !important;
        }
      }
    }
  }

  .listGroupUsers {
    min-height: 120px;
    max-height: 250px;
    padding: 12px;
    width: 100%;
    margin: 0 auto;
    border: 1px solid;
    border-radius: 4px;
    margin: 4px;
    max-width: calc(100% - 8px);
  }

  // NEW DESIGN
  .subTitleCard {
    color: rgb(255, 255, 255) !important;
    margin-top: -10px !important;
  }

  .theme--light .gradientBg,
  .theme--light .gradientTop {
    background: linear-gradient(#00000040, #00000099);
  }

  .theme--dark .gradientBg,
  .theme--dark .gradientTop {
    background: linear-gradient(#00000099, #000000cc);
  }

  .gradientBg {
    position: relative;
  }
  .gradientTop {
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(100% - 8px);
    text-align: center;
    z-index: 1;
    // position: absolute;
    // top: 0;
    // width: 100%;
    // text-align: center;
    // z-index: 1;
  }
  .customFontSize {
    font-size: 13px !important;
    word-break: break-word;
    max-width: 85%;
  }

  .colorWhite {
    color: white !important;
  }

  // END NEW DESIGN
  .theme--dark.v-btn.v-btn--disabled {
    color: #00000042 !important;
  }

  .iconCardsContainerBlack {
    position: absolute;
    top: 0;
    right: 0;
    background: unset;
    height: 120px;
  }

  .iconCardsContainer {
    position: absolute;
    top: 0;
    right: 0;
  }

  .waitingRoomTitleLeft {
    left: 10px;
  }

  .rowHeight {
    min-height: 176px !important;
  }

  .contentGridWrapper {
    // height: calc(100vh - 202px);
    height: calc(100vh - 130px);
    overflow-y: auto;
  }

  .apartTitle {
    font-weight: 600;
    font-size: 17px;
  }

  // 6 columns
  .cardWidth {
    max-width: 235px;
    box-shadow: none !important;
    position: relative;
  }

  @media (max-width: 765px) {
    .contentGridWrapper .custom6cols {
      width: 50%;
      min-width: 50%;
    }

    .contentGridWrapper .custom6colsViewing {
      width: 25%;
      min-width: 25%;
    }
  }

  // 6 columns
  @media (max-width: 1082px) {
    .cardWidth {
      width: 100%;
    }
  }

  @media (min-width: 1082px) {
    .cardWidth {
      width: 100%;
    }
  }

  .memberBackgroundOffline {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .custom7cols {
    width: 14%;
    max-width: 14%;
    flex-basis: 14%;
  }

  .custom6cols {
    width: 16%;
    flex-basis: 16%;
  }
  .custom6colsLargeUser {
    width: 20%;
    flex-basis: 20%;
  }
   .cardWidthLargeUser {
    max-width: 316px;
    box-shadow: none !important;
    position: relative;
  }
  .footerCard {
    background: white;
    padding: 0 !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .v-card__subtitle {
    color: black !important;
  }

  .v-card__title {
    font-size: 15px !important;
  }

  .opacity0 {
    opacity: 0;
  }

  .flipButton {
    padding: 0 5px;
    position: absolute;
    right: 5px;
    bottom: 13px;
    color: black;
    cursor: pointer;
    font-size: 22px;
  }

  .fontSize12 {
    font-size: 12px !important;
  }

  .representative-subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
  }

  .representative-subtitle span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .v-card__subtitle .frontButtons {
    position: inherit !important;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
  }

</style>

<style lang="scss">
  .repeateadImage {
    .v-image__image {
      filter: grayscale(1);
    }
  }

  .theme--light .listUsers {
    .header {
      .v-input__slot {
        background-color: white !important;
      }
    }
  }
</style>