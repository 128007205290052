<template>
  <v-dialog v-model="showcanJoinConferencesModal.show" persistent max-width="560" @keydown.esc="closeModal">
     <v-card>
      <HeaderModal
        :titleModal="$t('generics.info')"
        :closeModalFunction="closeModal"
      />
     <v-row class="mx-0">
     <v-col cols="12">
          {{$t('components.callsContent.likeJoinConference')}}
      </v-col>
      </v-row>
      <template>
        <FooterModal
          :closeModalFunction="closeModal"
          :showCancel="true"
        >
        <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mr-4"
                  v-on="on"
                  outlined
                  color="white"
                  @click="tryJoin"
                >
                {{$t("generics.accept")}}
                </v-btn>
              </template>
              <span>{{ $t("generics.accept") }}</span>
            </v-tooltip>
        </FooterModal>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { wsCall } from "../../lib/wsConnect";
import {
  canJoinConferenceStore,
  resetCanJoinConferenceEvent,
} from "../../effector/modals";
import {myConferenceDetails,} from "../../effector/groupConferences";
export default {
  props: [],
    components: {HeaderModal, FooterModal},
  data: () => ({
    state: store.state,
  }),
  effector: {
    showcanJoinConferencesModal: canJoinConferenceStore,
    myConferenceDetails: myConferenceDetails,
  },
  mounted() {
  
  },
  destroyed() {

  },
  methods: {
    tryJoin(){
      if(this.amIConferenceOwner){
        this.joinToConference()
      }else{
        wsCall("sendToUUID", this.showcanJoinConferencesModal.initiator, {
         type: "joinConference",
         message: this.$t('components.callsContent.joinConference'),
         sender: store.state.ownUUID,
       });
      }
      this.closeModal()
    },
    closeModal() {
      resetCanJoinConferenceEvent();
    },
    joinToConference(){
      const conferenceMemberArray = this.getConference?.members.map(user=>{
        return user.uuid
      })
      const userInConferenceCall= conferenceMemberArray.filter(uuid=>{
        const getCallInfo = this.state.group[uuid]?.user?.bridgeCallInfo || undefined;
        const getCallUUID = (this.state.group[uuid]?.user?.inBridgeCall || "").toString();
        return getCallInfo && !getCallInfo.conferenceHall && getCallInfo.isConferenceCall && getCallInfo.confId === this.getConference.confId.toString() && getCallUUID
      })
      if(userInConferenceCall.length>0){
        const usersUuid = userInConferenceCall[0]
        // condition to know if the conference is in process and get the info and get inside the call as a listener
        const getCallInfo = this.state.group[usersUuid]?.user?.bridgeCallInfo || undefined;
        if(getCallInfo) {
          // there is a conference call working which the user is part of it but user doesn't be inside atm
          const userData = getCallInfo?.conferenceAttendees.find(e => e.uuid == this.state.ownUUID );
          const getCallUUID = (this.state.group[usersUuid]?.user?.inBridgeCall || "").toString();
          if(userData && getCallUUID) {
            store.addRemoteBridgeStream(getCallUUID, { ...getCallInfo, ...getCallInfo.infoCall, isAudioOnly: true, calling: [] })
            store.setCurrentContentVisile("", false, this.$router);
          }
        }
      }
    },
  },
  computed:{
    getConference(){
      if(!this.showcanJoinConferencesModal?.initiator || !this.showcanJoinConferencesModal?.confId ) return false
      const conference = this.myConferenceDetails.find(
        (e) => e.confId === this.showcanJoinConferencesModal.confId 
      );
      return conference
    },
    amIConferenceOwner(){
      if(!this.getConference) return false;
      if(this.getConference?.confOwner !== this.state.ownUUID) return false
      const conferenceMemberArray = this.getConference?.members.map(user=>{
        return user.uuid
      })
      const userInConferenceCall= conferenceMemberArray.filter(uuid=>{
        const getCallInfo = this.state.group[uuid]?.user?.bridgeCallInfo || undefined;
        const getCallUUID = (this.state.group[uuid]?.user?.inBridgeCall || "").toString();
        return getCallInfo && !getCallInfo.conferenceHall && getCallInfo.isConferenceCall && getCallInfo.confId === this.getConference.confId.toString() && getCallUUID
      })
      const amIInThisConference = conferenceMemberArray.find(uuid => uuid == this.state.ownUUID );
      if(userInConferenceCall.length && amIInThisConference){
        return true
      }else{
        return false
      }
    },
  }

};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.thumbs-down {
  color: red;
}
.thumbs-up {
  color: green;
}
</style>