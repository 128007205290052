<template>
  <v-flex>
    <v-card
      v-if="isAllowed"
      fill-height
      class="mx-auto elevation-0 text-center"
      flat
      max-width="100%"
    >
      <!-- <v-img 
        class="imgLogo"
        contain
        src="assets/icon.png"
        lazy-src="assets/icon.png"
      ></v-img>-->
      <!-- <v-card-title class="text-center titleWelcome">{{ $t('components.subdomainAvailable.congratulations') }}</v-card-title> -->
      <v-card-subtitle
        class="text-center mt-5 mb-5 subtitleWelcome"
      >{{ $t('components.subdomainAvailable.thisDomainIsAvailableForYourSetup') }}</v-card-subtitle>
      <v-btn
        color="primary"
        :isLarge="true"
        class="btnWelcome"
        @click="setSetUpConfigRoute('configuration')"
      >{{ $t('components.subdomainAvailable.registerNow') }}</v-btn>
    </v-card>
    <v-card v-else fill-height class="mx-auto elevation-0 text-center" flat max-width="100%">
      <v-img 
        class="imgLogo"
        contain
        src="assets/icon.png"
        lazy-src="assets/icon.png"
      ></v-img>
      <v-card-subtitle
        class="text-center mt-5 mb-5 subtitleWelcome"
      >{{ $t('components.subdomainAvailable.nameNotAllowed') }}</v-card-subtitle>
    </v-card>
  </v-flex>
</template>

<script>
import store from "../../store";
export default {
  components: {},
  props: ["isAllowed"],
  data() {
    return {
      state: store.state,
      setSetUpConfigRoute: store.setSetUpConfigRoute
    };
  },
  computed: {},
  methods: {}
};
</script>

<style scoped lang="scss">
.imgLogo {
  height: 150px;
  margin: 80px auto 20px;
}
.titleWelcome {
  margin: 80px auto 20px;
  font-size: 30px;
  display: block;
  color: #02889d;
}
.subtitleWelcome {
  font-size: 19px;
}
.btnWelcome {
  width: 30%;
}
</style>
