import { combine, forward } from 'effector';
import { isDirtyStore, lastUpdateSnoozeStore } from './state';
import { slowCurrentTSStore } from '../clock';
import { setNewVersionAvailableModalEvent } from '../modals';
import { haveActiveCall } from '../call';
import {amInABridgeCall} from "../../utils/calls";

function isTsAfterNinePm(ts) {
  const date = new Date(ts);
  const hours = date.getHours();
  return (hours >= 21);
  //  return (hours >= 8);
}

const snoozeTime = 60000 * 60;
// const snoozeTime = 30000;

const shouldUpdate = combine([isDirtyStore, slowCurrentTSStore, lastUpdateSnoozeStore, haveActiveCall], ([isDirty, ts, lastSnoozeTs, callActive]) => {
  if (callActive) return false;
  if(amInABridgeCall()) return false;
  if (!ts) return false;
  if (!isTsAfterNinePm(ts)) return false;
  if (!isDirty) return false;
  if (lastSnoozeTs && (ts - lastSnoozeTs <= snoozeTime)) return false;
  return true;
});

shouldUpdate.watch((show) => {
  // console.log('shouldUpdate watch', show);
  setNewVersionAvailableModalEvent(show)
});

// forward({ from: shouldUpdate, to: setNewVersionAvailableModalEvent});