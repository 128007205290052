<template>
  <v-list max-width="320">
    <v-list-group
      :value="isActive"
      @click="handlerClickConference($event)"
      v-if="!mini"
      :appendIcon="sortedGroups.length === 0 ? null : '$expand'"
      active-class="listColor"
    >
      <span v-show="false" v-if="renderParadox"></span>
      <template v-slot:activator>
        <v-list-item-icon
          :class="`${
            getRoute === '/newconference'
              ? 'font-weight-bold primary--text'
              : ''
          }`"
        >
          <font-awesome-icon
            v-if="getRoute === '/newconference'"
            class="primary--text"
            :icon="['fas', 'users']"
          />
          <font-awesome-icon
            v-else
            class="primary--text"
            :icon="['fal', 'users']"
          />
        </v-list-item-icon>

        <v-list-item-title
          :class="`${getRoute === '/newconference' ? 'font-weight-bold' : ''}`"
          class="text-truncate"
          @click.stop.prevent="gotoMainConference()"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                class="btnAddGroup"
                @click.stop.prevent="handleAddCircle"
              >
                <font-awesome-icon
                  class="primary--text"
                  :icon="['fal', 'plus-circle']"
                  :style="{
                    fontSize: '20px',
                  }"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.sidebar.conferenceTT") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ $t("components.groups.konferenzen") }}</span>
            </template>
            <span>{{ $t("components.sidebar.conferencesTT") }}</span>
          </v-tooltip>
        </v-list-item-title>

        <CustomTT
          v-if="showCustomTT"
          :text="`${$t('components.hotkeys.controlKey')} + K`"
          position="bottom"
          :extraclass="'ctrk'"
        />
      </template>
      <TemplateList
        :selectTemplate="selectTemplate"
        :groups="sortedGroups"
        :section="'myTemplates'"
      />
    </v-list-group>
    <v-tooltip right v-if="mini">
      <template v-slot:activator="{ on }">
        <v-list-item v-on="on" class="cursorPointer">
          <v-list-item-icon>
            <font-awesome-icon
              @click.stop.prevent="handlerClickConferenceMini()"
              :icon="['fal', 'users']"
              class="primary--text cursorPointer"
            />
          </v-list-item-icon>
        </v-list-item>
      </template>
      <span>{{ $t("components.sidebar.conferencesTT") }}</span>
    </v-tooltip>
  </v-list>
</template>

<script>
import store, { EventBus } from "../../store";
import moment from "../../../sharedsrc/moment";
import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";
import TemplateList from "./templateList.vue";

export default {
  props: [
    "mini",
    "setCurrentContentVisile",
    "isGuest",
    "active",
    "data",
    "isMobile",
    "setList",
  ],
  components: {
    TemplateList,
    CustomTT,
  },

  data: () => ({
    state: store.state,
    renderParadox: false,
    moment: moment,
    loadConferenceContainer: null
  }),
  mounted() {
    setTimeout(() => (this.renderParadox = true), 50);
    EventBus.$on("openNewConferenceEvent", this.goToConferencePage);
  },
  destroyed(){
    clearTimeout(this.loadConferenceContainer);
    this.loadConferenceContainer = null;
    EventBus.$off("openNewConferenceEvent", this.goToConferencePage);
  },
  effector: {
    showCustomTT: hotkeysInfoStore,
  },
  watch: {
    "state.user": function (to, from) {
      this.renderParadox = false;
      setTimeout(() => (this.renderParadox = true), 50);
    },
  },
  methods: {
    handlerClickConference(event) {
      if (this.mini) {
        event.preventDefault();
        event.stopPropagation();
        this.goToPage("newconference");
      } else {
        this.setList("conferences");
      }
    },
    selectTemplate(groupId) {
      const extant = store.state.user.groups.find((e) => e.groupId === groupId);
      this.gotoPage("/newconference/");
      if (extant) {
        this.loadConferenceContainer = setTimeout(() => {
          if (extant.conferenceHall) {
            EventBus.$emit("loadFormHeader", {
              formType: "conferenceHall",
              id: groupId,
            });
          } else {
            EventBus.$emit("loadFormHeader", {
              formType: "conference",
              id: groupId,
            });
          }
        }, 10);
      }
    },
    gotoMainConference() {
      if (
        this.$router.history.current.fullPath.indexOf("/newconference") == -1
      ) {
        this.gotoPage("/newconference");
      } else {
        EventBus.$emit("loadMainConference");
      }
    },
    handleAddCircle() {
      if (
        this.$router.history.current.fullPath.indexOf("/newconference") == -1
      ) {
        this.gotoPage("/newconference/00");
      } else {
        EventBus.$emit("loadInserting");
      }
    },
    goToConferencePage() {
      this.gotoPage("/newconference");
    },
    handlerClickConferenceMini() {
      if (
        this.$router.history.current.fullPath.indexOf("/newconference") == -1
      ) {
        this.gotoPage("/newconference");
      }
    },
    gotoPage(route) {
      this.setCurrentContentVisile(route, true, this.$router);
    },
  },
  computed: {
    sortedGroups() {
      return store.state.user.groups || [];
    },
    isActive() {
      return this.active == "conferences";
    },
    getRoute() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss">
.v-list-item .v-list-item__title {
  line-height: 1.5 !important;
}
.titleGroup {
  .v-icon {
    font-size: 14px !important;
  }
}

.v-list {
  padding: 0;
}

.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.textColor {
  color: var(--v-primary-base);
}

.btnAddGroup {
  position: absolute !important;
  right: 57px;
  top: 5px;
}
.theme--dark .listColor {
  color: white !important;
}
.theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>

<style lang="scss" scoped>
.cursorPointer {
  cursor: pointer;
}
.mt-- {
  margin-top: -10px;
}
.spanDate {
  position: absolute;
  bottom: -11px;
  font-size: 14px;
  font-weight: normal;
  left: 48px;
}
.groupList {
  max-height: 275px;
  overflow-y: scroll;
  overflow-x: hidden;
  @media screen and (max-height: 650px) {
    max-height: 280px !important;
  }
}

.groupListMobile {
  max-height: 275px;
  overflow-y: scroll;
  overflow-x: hidden;
}

:deep() .v-list-group__header__append-icon {
  min-width: 0px !important;
}

.team-list {
  padding: 0 24px;
}
.ctrk {
  z-index: 2;
  bottom: -10px;
  left: 15px;
}
</style>