<template>
  <div>
    <v-list-item>
      <template>
        <!-- active (not used)-->
        <font-awesome-icon class="mr-4" :icon="['fal', 'inbox-in']" />
        <!-- <v-list-item-avatar tile  size="60" class="borderRadius10 cursorPointer hidden-sm-and-down">
          <v-img 
            class="mx-2 borderRadius10"
            max-height="60"
            max-width="60"
            contain
            :src="getAvatarForUuid(entry.uuid)"
          ></v-img>
        </v-list-item-avatar> -->
        <v-list-item-avatar
          tile
          size="60"
          class="borderRadius10 cursorPointer"
          :style="{
            border: `2px solid ${setBorderByStatus(state.group[entry.uuid])}`,
          }"
        >
          <DefaultAvatarGrid
            :size="120"
            :userData="state.group[entry.uuid]"
            v-if="
              getAvatarForUuid(entry.uuid) == 'img/default_profile_picture.png'
            "
          ></DefaultAvatarGrid>
          <v-img
            v-if="
              getAvatarForUuid(entry.uuid) != 'img/default_profile_picture.png'
            "
            class="mx-2 borderRadius10"
            max-height="60"
            max-width="60"
            contain
            :src="getAvatarForUuid(entry.uuid)"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content class="pl-2">
          <v-list-item-title
            v-text="getNameForUuid(entry.uuid)"
          ></v-list-item-title>
          <v-list-item-subtitle
            class="text--primary"
            v-text="entry.fileName"
          ></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action class="ml-0">
          <v-list-item-action-text
            v-text="
              formatNoticationDate(new Date())
            "
          ></v-list-item-action-text>
           <div class="d-flex" style="position: relative">
            <DeleteBasket
              :indexData="[entry.uuid, entry.fileName, entry.msgUUID]"
              :delFunction="remove"
              extraClasses="mr-4"
              fontSize="16"
              prevent= true
            />
            <v-tooltip top v-if="entry.incoming && !entry.actualMD5">
              <template v-slot:activator="{ on }">
                <font-awesome-icon
                  class="mr-4"
                  v-on="on"
                  @click.stop.prevent="entry.resolve(true)"
                  :icon="['fal', 'download']"
                  color="grey lighten-1"
                  :style="{fontSize:16}"
                ></font-awesome-icon>
              </template>
              <span>{{ $t("components.fileItemList.download") }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <a
                  class="btnSave mr-4"
                  v-on="on"
                  v-show="entry.incoming && entry.complete"
                  :href="entry.finalData"
                  :download="entry.fileName"
                  target="_blank"
                >
                  <font-awesome-icon
                    :icon="['fal', 'save']"
                    color="grey lighten-1"
                  ></font-awesome-icon>
                </a>
              </template>
              <span>{{ $t("generics.save") }}</span>
            </v-tooltip>

            <v-tooltip top v-if="!entry.incoming">
              <template v-slot:activator="{ on }">
                <font-awesome-icon
                  class="mr-4"
                  v-on="on"
                  :icon="['fal', 'inbox-out']"
                  color="grey lighten-1"
                  :style="{fontSize:16}"
                ></font-awesome-icon>
              </template>
              <span>{{ $t("components.fileItemList.outgoingFile") }}</span>
            </v-tooltip>
           </div>
        </v-list-item-action>
      </template>
    </v-list-item>
    <div class="parentProgressBar">
      <v-progress-linear
        :active="true"
        :background-opacity="0.3"
        :bottom="false"
        :buffer-value="100"
        :height="4"
        :indeterminate="false"
        :query="false"
        :rounded="false"
        :stream="false"
        :striped="false"
        :top="false"
        :value="percentDone"
        color="primary"
      ></v-progress-linear>
    </div>
  </div>
</template>

<script>
import store from "../../store";
import moment from "../../../sharedsrc/moment";
import DeleteBasket from "../../components/ui/deleteBasket.vue";
import { getStatusColorByUser } from "../../utils/status";
import DefaultAvatarGrid from "../image/defaultAvatarGrid.vue";
export default {
  props: ["entry"],
  components: {
    DefaultAvatarGrid,
    DeleteBasket,
  },
  data() {
    return {
      state: store.state,
      persisted: store.state.persisted,
      moment,
      user: store.state.user,
    };
  },
  watch: {},
  methods: {
    formatNoticationDate(date) {
      return this.moment(date).format("DD.MM.YYYY, H:mm") + " " + this.$t("components.meetingLineModal.hours");
    },
    setBorderByStatus(person) {
      if (!person || !person.user) return;
      return getStatusColorByUser(person.user, person.connected);
    },
    remove(uuid, filename, msgUUID) {
      store.delFileTransferFor(uuid, filename);
      this.removeMessage(uuid, msgUUID, undefined);
    },
    removeMessage: function (...args) {
      store.removeMessage(...args);
    },
    getNameForUuid: function (...args) {
      return store.getNameForUuid(...args);
    },
    getAvatarForUuid: function (...args) {
      return store.getAvatarForUuid(...args);
    },
    setShowModalNotify: function (...args) {
      store.setShowModalNotify(...args);
    },
    settotalNotification: function (...args) {
      store.settotalNotification(...args);
    },
  },
  computed: {
    percentDone() {
      if (!this.entry.sizeSoFar) return 0;
      return ((100 / this.entry.expectedSize) * this.entry.sizeSoFar).toFixed(
        1
      );
    },
    md5Match() {
      return this.entry.expectedMD5 === this.entry.actualMD5;
    },
  },
  mounted: function mounted() {},
};
</script>

<style scoped lang="scss">
.vueScroll {
  .__view {
    width: unset !important;
  }
}
.divNotNotis {
  height: 40px;
  padding: 9px;
  .notNotifications {
    color: var(--form-text);
  }
}
.btnSave {
  top: 2px;
  position: relative;
}
.parentProgressBar {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
}
</style>