<template>
  <v-dialog v-model="showReminderModal" persistent max-width="560">
    <template>
      <v-card class="mx-auto">
        <HeaderModal :titleModal="$t('components.reminderModal.title')" />
        <v-card-text>
          <v-row>
            <v-col cols="12">
              {{ $t('components.reminderModal.reminderInfo', [getPersonName, getCallDate, getCallTime,timezone] )}}
             </v-col>
             <v-col cols="12">
              {{ $t('components.reminderModal.deleteReminder')}}
             </v-col>
          </v-row>
        </v-card-text>
         <FooterModal :closeModalFunction="closeModal" :showFooter="true">
          <v-btn style="background-color: white !important;" depressed class="mr-3">
            <DeleteBasket
              :indexData="showReminderModal.uuid"
              :delFunction="deleteReminder"
              extraClasses="cursorPointer"
              fontSize="18"
            />
          </v-btn>
          <v-btn depressed @click="callUser()" :disabled="!showReminderModal || disableCallBtn">
            <font-awesome-icon
              :icon="['fal', 'phone']"
              :style="{ fontSize: '18px' }"
            />
          </v-btn>
          </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { resetReminderModalEvent,reminderModalStore } from "../../effector/modals";
import { deleteTimelineItemEvent } from "../../effector/timeline";
import { dispatchSuccessAlert } from "../../effector/alerts";
import DeleteBasket from "../../components/ui/deleteBasket.vue"
import { disableCallBtn, callPerson } from "../../utils/calls";
import moment from "moment"
export default {
  components: { HeaderModal, FooterModal, DeleteBasket },
  data: () => ({
    state: store.state,
    moment: moment,
    timezone: store.state.namespaceSettings.timezone || "Europe/Berlin"
  }),
  effector: {
    showReminderModal: reminderModalStore,
  },
  mounted() {},
  methods: {
    callUser(){
      if ( !this.showReminderModal) return;
      callPerson(this.showReminderModal.users[0], this.$router);
      this.closeModal();
    },
    async deleteReminder(eventUUID){
      try{
        await deleteTimelineItemEvent(eventUUID);
        this.closeModal();
      }catch(err){
        console.warn('Error deleting timelineEvent', err)
      }
    },
    closeModal() {
      resetReminderModalEvent();
    },
  },
  computed: {
    disableCallBtn(){
      if ( !this.showReminderModal) return true;
      return disableCallBtn(this.state.group[this.showReminderModal.users[0]])
    },
    getPersonName(){
      if ( this.showReminderModal && this.state.group[this.showReminderModal.users[0]] && this.state.group[this.showReminderModal.users[0]].user ){
        return this.state.group[this.showReminderModal.users[0]].user.name;
      }
      return '';
    },
    getCallDate(){
      if ( this.showReminderModal && this.showReminderModal.start){
        return this.moment(this.showReminderModal.start).format('DD.MM.YYYY')
      }
    },
    getCallTime(){
      if ( this.showReminderModal && this.showReminderModal.start){
        return this.moment(this.showReminderModal.start).format('HH:mm')
      }
    }
  }
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.thumbs-down {
  color: red;
}
.thumbs-up {
  color: green;
}
</style>