import store from '../store';

export function hasRepresentative(userUuid) {
  if (
    store.state.group[userUuid] &&
    store.state.group[userUuid].user &&
    store.state.group[userUuid].user.representative
  ) {
    const person = store.state.group[userUuid];
    const representativeUuid = person.user.representative.value;
    const today = new Date();
    const todayFormatted = today.toISOString().split('T')[0]; // Format as yyyy-mm-dd

    // Apply representative logic only when user is Out of office. Checking out of office dates as well as user.activity is undefined
    if (
      (
          store.getActivityForUuid(person.user.uuid) === "Out of Office" ||
          (todayFormatted >= person.user.startHolidays && todayFormatted <= person.user.endHolidays)
      ) && 
      representativeUuid
    ) {
      return representativeUuid;
    }
    
  }
}

export function getRepresentative(userUuid) {
  if (
    store.state.group[userUuid] &&
    store.state.group[userUuid].user &&
    store.state.group[userUuid].user.representative
  ) {
    const person = store.state.group[userUuid];
    return store.state.group[person.user.representative.value];
  }
}
