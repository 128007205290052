var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"paylink_sandbox"}},[_c('v-container',{staticClass:"py-0",attrs:{"fill-height":"","fluid":""}},[_c('v-app-bar',{class:("width100 topBar " + (_vm.isMobile ? 'px-2' : '')),attrs:{"clipped-left":_vm.primaryDrawer.clipped,"app":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-start":"","flex-column":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"mb-0 mt-0",class:{
                'ml-1 mt--6': _vm.isMobile,
                'ml-3': _vm.isMobile,
                'mr-2 ml-2': !_vm.isMobile,
                'mt-0 ml-5': !_vm.isMobile,
              },staticStyle:{"cursor":"pointer"},attrs:{"max-height":!_vm.isMobile ? 40 : 35,"max-width":!_vm.isMobile ? 40 : 35,"src":"assets/icon.png","contain":""}},on))]}}])},[_vm._v(" "),_c('span',[_vm._v("vOffice")])])],1),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{class:("font-weight-bold body-1 " + (_vm.isMobile ? 'pr-0' : ''))},[(!_vm.isMobile)?[_c('a',{staticClass:"linkApp font-weight-bold customTitle",attrs:{"href":"https://www.voffice.pro","target":"_blank"}},[_vm._v("\n                  vOffice\n                  "),_c('span',{class:(" font-weight-bold customTitle " + (_vm.isMobile &&
                      (_vm.state.namespaceSettings.companyInfo.virtualOfficePrefix
                        .length > 21 ||
                        _vm.virtualOfficePrefix.length > 21)
                        ? 'font13'
                        : ''))},[_vm._v(_vm._s(_vm.state.namespaceSettings.companyInfo
                        .virtualOfficePrefix || _vm.virtualOfficePrefix))])])]:_vm._e()],2)],1)],1)],1)],1),_vm._v(" "),(!_vm.hasError)?_c('div',{staticClass:"width100 text-center title"},[_vm._v("\n      "+_vm._s(_vm.$t("components.paypal.waitingRedirect"))+"\n      "),_c('div',{attrs:{"id":"wave"}},[_c('div',{staticClass:"dot",attrs:{"color":"primary"}}),_vm._v(" "),_c('div',{staticClass:"dot",attrs:{"color":"primary"}}),_vm._v(" "),_c('div',{staticClass:"dot",attrs:{"color":"primary"}})])]):_c('div',{staticClass:"width100 text-center title"},[_vm._v("\n       "+_vm._s(_vm.$t("generics.somethingWrong"))+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }