<template>
  <div v-if="person && person.user && uuid" class="frontButtons">
    <UserMoreActions
      :teamInfo="teamInfo"
      :person="person"
      :section="null"
      :customStyle="false"
      :isRegularGrid="true"
      :isUserInCall="isUserInCall"
      :hasOHold="hasOnHold"
      :isBasicView="isBasicView"
      :showUploadFile="showUploadFile"
      :disableSendFileBtn="disableSendFileBtn"
      :sendFileTo="sendFileTo"
      :openDropdown="openDropdown"
      :IsInList="IsInList"
      :showHelpscoutButton="showHelpscoutButton"
      :showVisitPage="showVisitPage"
      :openHelpsCoutModal="openHelpsCoutModal"
      :checkSendMessage="checkSendMessage"
      :typeData="typeData"
      :isOrga="isOrga"
      :isEditableView="isEditableView"
      :sectionData="sectionData"
      :checkMove="checkMove"   
      :isMovingDepa="isMovingDepa"
      :isMovingTeam="isMovingTeam"
      :nameSectionToMove="nameSectionToMove"
      :parentToMove="parentToMove"
      :moveType="moveType"
    />
    <template v-if="openingDropdown">
      <DonwnloadsFilesModal
        :showModal="openingDropdown"
        :closeModal="openDropdown"
      />
    </template>
    <template v-if="amIGuest">
      <RemoveFromFavoriteModal
        :person="deleteFavorite.person"
        :showModal="deleteFavorite.show"
        :closeModal="closeDeleteFavoriteModal"
      ></RemoveFromFavoriteModal>
    </template>
    <template>
      <ChangeFunctionModal
        :modalData="changeUserFunction"
        :cancelChangeFunction="cancelChangeFunction"
        v-if="changeUserFunction.show"
      ></ChangeFunctionModal>
    </template>
    <SendInvitationModal
      v-if="showInvitationModal"
      :closeShowInviteInfo="closeShowInviteInfo"
      :showInviteInfo="showInvitationModal"
    />
    <template v-if="deleteUser">
      <RemoveUserModal
        :person="person"
        :showModal="deleteUser"
        :closeModal="closeRemoveUserModal"
      ></RemoveUserModal>
    </template>
    <template v-if="showAddHomePage">
      <HomePageModal
        :sectionData="sectionData"
        :showModal="showAddHomePage"
        :closeModal="closeSetHomePageModal"
      ></HomePageModal>
    </template>
    <template v-if="showSendMessageToAll">
      <SendMessageModalGrid
        :sectionData="sectionData"
        :showModal="showSendMessageToAll"
        :closeModal="closeSendMessageModalGrid"
      ></SendMessageModalGrid>
    </template>
    <template v-if="showHelpscout">
      <InProgressModal
        :showModal="showHelpscout"
        :closeModal="closeHelpscoutModal"
      ></InProgressModal>
    </template>
    <template v-if="openHolidaysCalendar">
      <HolidaysCalendarModal
        :userUUID="person.uuid"
        :closeModal="toggleShowHolidaysModal"
        :showModal="openHolidaysCalendar"
      ></HolidaysCalendarModal>
    </template>
    <template v-if="openDisplayAssistants">
      <DisplayAssistantsModal
        :closeModal="toggleDisplayAssistantsModal"
        :showModal="openDisplayAssistants"
      ></DisplayAssistantsModal>
    </template>
    <template v-if="showDirectLink">
      <DirectLinkInvitationModal
        v-if="showDirectLink"
        :showModalDirectLink="showDirectLink"
        :openCloseModalDirectLink="setDirectLink"
      />
    </template>
  </div>
</template>

<script>
import store from "../../store";
import { sendFileTo } from "../../lib/rtcConn";
import { watchingList } from "../../effector/presence";
import RemoveFromFavoriteModal from "../modal/removeFromFavoriteModal.vue";
import UserProfile from "../modal/userProfile.vue";
import SendInvitationModal from "../modal/sendInvitationModal.vue";
import ChangeFunctionModal from "../modal/changeFunctionModal.vue";
import { disableCallBtn } from "../../utils/calls";
import RemoveUserModal from "../modal/removeUserModal.vue";
import HomePageModal from "../modal/homePageModal.vue";
import SendMessageModalGrid from "../modal/sendMessageModalGrid.vue";
import InProgressModal from "../modal/inProgressModal.vue";
import { basicView } from "../../utils/privileges";
import HolidaysCalendarModal from "../modal/holidaysCalendarModal.vue";
import DisplayAssistantsModal from "../modal/displayAssistantsModal.vue";
import DirectLinkInvitationModal from "../modal/directLinkInvitationModal.vue";
import DonwnloadsFilesModal from "../modal/downloadsFilesModal.vue";
import UserMoreActions from "../content/userMoreActions.vue";
import { setHistoryMessagesModalEvent } from "../../effector/modals";

export default {
  props: [
    "person",
    "uuid",
    "color",
    "sectionData",
    "typeData",
    "isOrga",
    "isEditableView",
    "checkMove",
    "isMovingDepa",
    "isMovingTeam",
    "nameSectionToMove",
    "parentToMove",
    "moveType",
    "teamInfo"
  ],
  components: {
    RemoveFromFavoriteModal,
    UserProfile,
    SendInvitationModal,
    ChangeFunctionModal,
    RemoveUserModal,
    HomePageModal,
    SendMessageModalGrid,
    InProgressModal,
    HolidaysCalendarModal,
    DisplayAssistantsModal,
    DirectLinkInvitationModal,
    DonwnloadsFilesModal,
    UserMoreActions,
  },
  data() {
    return {
      setCurrentContentVisile: store.setCurrentContentVisile,
      state: store.state,
      deleteFavorite: {
        show: false,
        person: undefined,
      },
      ownUUID: store.state.ownUUID,
      showInvitationModal: false,
      changeUserFunction: {
        show: false,
        person: undefined,
      },
      deleteUser: false,
      showAllSection: false,
      showAddHomePage: false,
      showSendMessageToAll: false,
      showHelpscout: false,
      hideShowUser: false,
      openHolidaysCalendar: false,
      openDisplayAssistants: false,
      showDirectLink: false,
      openingDropdown: false,
    };
  },
  effector: {
    watchingList: watchingList,
  },
  mounted: function () {
    this.checkHidedUser();
    this.checkShowAllSection();
  },
  methods: {
    openDropdown() {
      this.openingDropdown = !this.openingDropdown;
    },
    setDirectLink() {
      this.showDirectLink = !this.showDirectLink;
    },
    toggleDisplayAssistantsModal() {
      this.openDisplayAssistants = !this.openDisplayAssistants;
    },
    toggleShowHolidaysModal() {
      this.openHolidaysCalendar = !this.openHolidaysCalendar;
    },
    checkShowAllSection() {
      const showAll = this.state.namespaceSettings.showAllSectionsOrga;
      for (let i = 0; i < showAll.length; i++) {
        const section = showAll[i];
        if (this.sectionData && this.sectionData.name === section) {
          this.showAllSection = true;
          return;
        }
      }
      this.showAllSection = false;
    },
    checkHidedUser() {
      if (this.person) {
        const uuid = this.person.uuid;
        if (
          this.state.group[uuid] &&
          this.state.group[uuid].user.hidedByAdmin
        ) {
          this.hideShowUser = true;
        } else {
          this.hideShowUser = false;
        }
      }
    },
    closeHelpscoutModal() {
      this.showHelpscout = false;
    },
    openHelpsCoutModal() {
      this.showHelpscout = true;
    },
    isUserInCall(person) {
      if (
        person.connected &&
        person.user &&
        person.user.rtcCallStatus &&
        person.user.rtcCallStatus.length > 5
      ) {
        return true;
      } else {
        return false;
      }
    },
    closeSendMessageModalGrid() {
      this.showSendMessageToAll = false;
    },
    checkSendMessage(uuid) {
      if (uuid === this.ownUUID) {
        // I'm the user, send message to others managers and users (#178)
        this.showSendMessageToAll = true;
      } else {
        // send message to the user
        this.setShowModalSenMsg(uuid);
      }
    },
    closeSetHomePageModal() {
      this.showAddHomePage = false;
    },
    closeRemoveUserModal() {
      this.deleteUser = false;
    },
    changeFunction(person) {
      this.changeUserFunction = {
        show: true,
        person: person,
      };
    },
    cancelChangeFunction() {
      this.changeUserFunction = {
        show: false,
        person: undefined,
      };
    },
    closeShowInviteInfo() {
      this.showInvitationModal = false;
    },
    closeDeleteFavoriteModal() {
      this.deleteFavorite = {
        show: false,
        person: undefined,
      };
    },
    sendFileTo(personUuid) {
      let uuid = personUuid;
      return sendFileTo(uuid);
    },
    setShowModalSenMsg(uuid) {
      setHistoryMessagesModalEvent(uuid);
    },
    disableSendFileBtn(personObj) {
      let person = personObj;
      person = personObj;
      return !!(!person || !person.connected);
    },
    disableCallBtn(personObj) {
      let person = personObj;
      return disableCallBtn(person);
    },
  },
  computed: {
    IsInList() {
      if (this.watchingList.indexOf(this.person.uuid) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    showUploadFile() {
      return this.state.namespaceSettings.featureFileTranfer;
    },
    isBasicView() {
      return basicView();
    },
    hasOnHold() {
      if (Object.keys(store.state.remoteStreams).length == 1) {
        for (let uuid in store.state.remoteStreams) {
          if (store.state.rtc[uuid].onHold) {
            return true;
          }
        }
        return false;
      }
    },
    showVisitPage() {
      try {
        if (
          (
            ((this.state.namespaceSettings.organisationSettings || {})[
              this.sectionData.type
            ] || {})[this.sectionData.name] || {}
          ).homepage
        ) {
          return true;
        }
        return false;
      } catch {
        return false;
      }
    },
    showHelpscoutButton() {
      if (
        this.sectionData &&
        (this.sectionData.type === "sections" ||
          this.sectionData.type === "departments" ||
          this.sectionData.type === "teams")
      ) {
        return true;
      }
      return false;
    },
    amIGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
  },
};
</script>

<style scoped lang="scss">
.frontButtons {
  position: absolute;
  right: 1px;
  bottom: 6px;
  padding: 2px;
}
</style>