var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-badge',{attrs:{"bordered":"","color":"error","content":_vm.totalNotification,"value":_vm.totalNotification,"overlap":"","offset-y":_vm.isMobile && _vm.isNavbar ? 14 : !_vm.isMobile && _vm.isNavbar ? 25 : null,"offset-x":_vm.isMobile && _vm.isNavbar ? 13 : !_vm.isMobile && _vm.isNavbar ? 20 : null}},[_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"max-height":370,"max-width":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":_vm.isMobile,"icon":"","color":!_vm.hasPrivilege(_vm.state.ownUUID)
                  ? '#02889d'
                  : _vm.wholeBarWithColors()
                  ? 'white'
                  : 'black',"id":"v-tour-messages"},on:{"click":function($event){return _vm.setNotificationEffector()}}},on),[(!_vm.hasPrivilege(_vm.state.ownUUID))?_c('font-awesome-icon',{style:({ fontSize: '16px' }),attrs:{"icon":['fal', 'bell']}}):_c('font-awesome-icon',{style:({ fontSize: '16px' }),attrs:{"icon":['fal', 'paper-plane']}})],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.drawerContentCard.messages")))])])],1)]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_vm._v(" "),_c('NotificatiosnWaitingRoomDropDownEffector',{attrs:{"notificationType":'message',"closeMenu":_vm.closeMenu,"menuOpen":_vm.menuOpen}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }