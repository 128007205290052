<template>
  <v-card
    flat
    outlined
    class="userDisplay text-center relative"
    :disabled="disabled"
    :style="getCardStyle"
    @click="handlerShowUsers ? handlerShowUsers(uuid) : null"
  >
  <v-tooltip top v-if="userUUID && isEditMode">
      <template v-slot:activator="{ on }">
        <v-btn
          class="deleteUserFromList"
          v-on="on"
          color="primary"
          icon
        >
         <DeleteBasket :indexData="$event" :delFunction="deleteParticipant" extraClasses="" fontSize="18" prevent= false />
        </v-btn>
      </template>
      <span>{{$t('components.inviteVisitor.removeParticipants')}}</span>
    </v-tooltip>
    <DeleteBasket v-if="isNewConference" :indexData="indexToRemove" :delFunction="removeUserFromList" extraClasses="deleteUserFromList" fontSize="18" prevent= false />  
    <DefaultAvatarUserDisplay :size="windowHeight > 845 ? 78 : 50" :username="userName" v-if="useDefaultAvatar && userUUID" :userHasRejected="userHasRejected"/>
    <v-img
      v-if="userUUID && !useDefaultAvatar"
      contain
      class="white--text align-end ma"
      :class="{'rejectedUser': userHasRejected}"
      :height="windowHeight > 845 ? 78 : 50"
      :src="userAvatar"
      lazy-src="img/default_profile_picture.png"
    ></v-img>
    <div class="px-2 py-1 text-left " v-if="userUUID">
      <p class="mb-0 text-truncate">
        {{userName}}
        <!-- <v-btn v-if="!isMe" icon @click="callUser" :color="borderClass" :disabled="!userConnected">
      <font-awesome-icon :icon="['fal', 'phone']" :style="iconStyle" />
        </v-btn>-->
      </p>
      <p class="mb-0 text-truncate" v-if="userFunction">{{userFunction}}</p>
      <p class="mb-0 text-truncate" v-else-if="isOneCall">OneCall Participant</p>
      <p class="mb-0 text-truncate" v-else>---</p>
      <div v-if="userHasRejected && isEditMode" :class="{'buttonOnPic': userHasRejected}">
        <font-awesome-icon :icon="['fal', 'plus-circle']" style="fontSize: 20px;" />
      </div>
    </div>
    <div v-if="!userUUID" :class="windowHeight > 845 ? 'marginTop40' : 'marginTop23'">
      <font-awesome-icon :icon="['fal', 'plus-circle']" style="fontSize: 20px;" />
    </div>
    <!-- <div v-if="!isMe">
    <v-btn @click="callUser" :disabled="!userConnected">
      <font-awesome-icon :icon="['fal', 'phone']" :style="{ fontSize: '20px' }" />
    </v-btn>
    </div>-->
    <!-- <div v-else>
    <v-label>It's you!</v-label>
    </div>-->
  </v-card>
</template>

<script>
import { callUUID } from "../../lib/rtcConn";
import store from "../../store";
import DeleteBasket from "../../components/ui/deleteBasket.vue";
import { getStatusColorByUser } from "../../utils/status";
import { getUserStateStore, ownUserUUIDState } from "../../effector/users";
import DefaultAvatarUserDisplay from "./defaultAvatarUserDisplay.vue";

export default {
  props: ["userUUID", "newEvent", "handlerShowUsers", "uuid", "disabled", "userHasRejected", "isEditMode", "isNewConference", 'removeUserFromList', 'indexToRemove', "clearParticipant", "activeIndex"],
  components: { DefaultAvatarUserDisplay, DeleteBasket },
  data() {
    return {
      userGroupEntry: null,
      windowHeight: window.innerHeight,
    };
  },
  effector: {
    ownUserUUID: ownUserUUIDState,
  },
  methods: {
    deleteParticipant(event){
      event.preventDefault();
      event.stopPropagation();
      if ( this.clearParticipant ){
        this.clearParticipant(this.activeIndex)
      }
    },
    onResize() {
      this.windowHeight = window.innerHeight
    },
    hola() {
      // console.log('WTF!')
    },
    resubscribeFilter() {
      this.unsubFilter();
      this._unsubFilter = this.userFilter.watch((state) => {
        this.userGroupEntry = state;
      });
    },
    unsubFilter() {
      if (this._unsubFilter) this._unsubFilter();
    },
    callUser() {
      callUUID(this.userUUID);
    },
  },
  computed: {
    isDark() {
      return store.state.persisted.isDark;
    },
    userFilter() {
      return getUserStateStore(this.userUUID);
    },
    user() {
      if (this.userGroupEntry) return this.userGroupEntry.user;
    },
    userName() {
      if (this.user) return this.user.name;
    },
    userAvatar() {
      if (this.user) return this.user.avatar;
    },
    useDefaultAvatar() {
      if(this.userAvatar === undefined) return true;
      return this.userAvatar === "img/default_profile_picture.png";
    },
    userConnected() {
      if (this.userGroupEntry) return this.userGroupEntry.connected;
    },
    isMe() {
      return this.ownUserUUID === this.userUUID;
    },
    isOneCall() {
      return ((this.user || {}).visitorData || {}).isOneTime || false;
    },
    getCardStyle() {
      if ( this.isNewConference ){
        return {
          // borderWidth: 1,
          // borderColor: this.borderClass,
          // borderStyle: "solid",
          minHeight: this.windowHeight > 845 ? 143 : 100,
          // cursor: this.newEvent ? "pointer" : "default",
          // background: this.disabled ? "#e9ebec" :  this.userHasRejected  ? '#ff00006e' : "white",
        };
      }else{
        let backGroundColor = this.isDark ? '#1e1e1e' : '#e9ebec'
        return {
          borderWidth: 1,
          borderColor: this.borderClass,
          borderStyle: "solid",
          minHeight: this.windowHeight > 845 ? 143 : 100,
          cursor: this.newEvent ? "pointer" : "default",
          background: this.disabled ? backGroundColor :  this.userHasRejected  ? '#ff00006e' : backGroundColor,
        };
      }
    },
    borderClass() {
      return getStatusColorByUser(this.user, this.userConnected);
    },
    userFunction() {
      if (this.user) return this.user.position || false;
    },
    iconStyle() {
      return {
        fontSize: '20px',
      };
    },
  },
  watch: {
    userUUID() {
      this.resubscribeFilter();
    },
    windowHeight(newHeight, oldHeight) {
       this.windowHeight = newHeight
    },
  },
  mounted() {
    this.resubscribeFilter();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    this.unsubFilter();
    window.removeEventListener('resize', this.onResize); 
  },
};
</script>

<style lang="scss" scoped>
.deleteUserFromList{
  font-size: 18px;
  right: 5px;
  top: 5px;
  position: absolute;
  z-index: 5;
}
.relative{
  position: relative;
}
.buttonOnPic{
  position: absolute;
  left: calc(50% - 10px);
  top: 69px;
  color: white; 
}
.rejectedUser{
  filter: grayscale(1);
}
.marginTop40{
  margin-top: 40%;
}
.marginTop23{
  margin-top: 23%;
}
// .userDisplay {
// border: 1px solid black;
// border-radius: 5px;
// padding: 5px;
// margin: 5px;
// }
</style>
