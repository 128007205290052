import Vue from 'vue';
import PaypalPaymentView from './components/payment/paypalPaylinkView.vue';
import vuetify from './vuetify';

let inited = false;
export const initPaymentView = () => {
  if (inited) return;
  inited = true;
  try {
    PaypalPaymentView.vuetify = vuetify;
    const root = new Vue({
      render: createElement => createElement(PaypalPaymentView)
    });
    root.$mount('#payment_anchor');
  } catch (err) {
    console.warn('Init Vue Error:', err);
  }
};
