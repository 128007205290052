<template>
  <v-dialog
    fullscreen
    hide-overlay
    v-model="showModal"
    persistent
    @keydown.esc="closeModal"
  >
    <template>
      <v-card class="mx-auto">
        <HeaderModal :titleModal="$t('generics.hotkeys')" :closeModalFunction="closeModal" />
        <v-card-text class="fullHeigth d-flex justify-center align-center">
          <div :class="isMobile ? 'containerScroll': ''">
            <v-list>
               <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + a: {{ $t("components.hotkeys.myStatus") }}
              </v-list-item>
              <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + b: {{ $t("status.Coffee break")}}
              </v-list-item>
              <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + f: {{ $t("components.hotkeys.favorites") }}
              </v-list-item>
              <!-- <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + i: {{ $t("components.hotkeys.guestInvitation") }}
              </v-list-item> -->
              <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + k: {{ $t("components.hotkeys.conferenceCreation") }}
              </v-list-item>
              <!-- <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + l: {{ $t("components.hotkeys.myLocation") }}
              </v-list-item> -->
              <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + m: {{ $t("components.hotkeys.settingsMenu") }}
              </v-list-item>
              <!-- <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + o: {{ $t("components.hotkeys.organigram") }}
              </v-list-item> -->
              <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + p: {{ $t("components.hotkeys.userInfo") }}
              </v-list-item>
                 <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + q: {{ $t("components.hotkeys.quickBar") }}
              </v-list-item>
              <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + r: {{ $t("components.hotkeys.waitingRoom") }}
              </v-list-item>
              <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + s: {{ $t("components.hotkeys.sidebarSearch") }}
              </v-list-item>
              <!-- <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + u: {{ $t("components.hotkeys.userInvitation") }}
              </v-list-item> -->
              <v-list-item class="mx-3">
                {{ $t("components.hotkeys.controlKey") }} + enter: {{ $t("components.hotkeys.removeTimeFromCalls") }}
              </v-list-item>
              <v-list-item class="mx-3">
                {{ $t("components.hotkeys.space") }}: {{ $t("components.hotkeys.acceptAnIncomingCall") }}
              </v-list-item>
              <v-list-item class="mx-3">
                {{ $t("components.hotkeys.doublespace") }}: {{ $t("components.hotkeys.hangUp") }}
              </v-list-item>
              <v-list-item class="mx-3">
                {{ $t("components.hotkeys.doubleclick") }}: {{ $t("components.hotkeys.toCall") }}
              </v-list-item>
              <v-list-item class="mx-3">
                {{ $t("components.hotkeys.enter") }} + u: {{ $t("components.hotkeys.changeView") }}
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>
        <FooterModal :closeModalFunction="closeModal" />
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { resetHotkeysEvent, hotkeysStore } from "../../effector/modals";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { isMobile } from "../../lib/mobileUtil";

export default {
  props: [],
  components: {
    HeaderModal,
    FooterModal,
  },
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
    isMobile: isMobile(),
  }),
  mounted(){
    // document.getElementById('drawerContentCard').classList.add('hideElement');
  },
  destroyed(){
    // document.getElementById('drawerContentCard').classList.remove('hideElement');
  },
  effector: {
    showModal: hotkeysStore,
  },
  watch: {},
  methods: {
    closeModal() {
      resetHotkeysEvent();
    },
  },
};
</script>

<style scoped lang="scss">
.containerScroll{
  overflow: auto;
  max-height: 75vh;
}
.hideElement {
  opacity: 0;
}
.fullHeigth{
  height: calc(100% - 140px);
}
</style>
