<template>
  <div>
    <span>LogFilter</span>
    <input type='text' v-model="filter" />
  </div>
</template>

<script>
import { loggingFilter, setLoggingFilterEvent } from '../../../effector/logging';

export default {
  // props: [],
  components: { },
  data() {
    return {
    };
  },
  effector: {
    loggingFilter,
  },
  methods: {
  },
  computed: {
    filter: {
      get() {
        return this.loggingFilter;
      },
      set(val) {
        setLoggingFilterEvent(val);
      },
    }
  },
};
</script>

<style scoped lang="scss">

</style>