<template>
  <v-tooltip top v-if="conference && conference.confUUIDS && conference.confModerators.indexOf(state.ownUUID) != -1">
    <template v-slot:activator="{ on }">
      <v-btn
        class="mt-2 mx-auto"
        v-on="on"
        @click.stop.prevent="callEveryone(false, null)"
        depressed
        color="primary"
        small
        :disabled="amInACall"
      >
        <font-awesome-icon :icon="['fal', 'phone']" :style="{ fontSize: '20px' }" />
      </v-btn>
    </template>
    <span>{{$t('components.conferenceForm.startConference')}}</span>
  </v-tooltip>
</template>

<script>
import store from "../../../store";
import { v4 as uuidv4 } from "uuid";
import { hasPrivilege } from "../../../utils/privileges";
import { markAsCallWaitingRoomUserEvent } from "../../../effector/users/waitingInfo";
import {
  dispatchColorChangeTimelineEntry,
  timelineEvents
} from "../../../effector/timeline";
import { dispatchErrorAlert } from "../../../effector/alerts";
import { wsCallStartBridgeCall, amInABridgeCall } from "../../../utils/calls";

export default {
  props: ["conference"],
  components: {},
  data() {
    return {
      state: store.state
    };
  },
  created() {},
  effector: {
    timelineEvents: timelineEvents
  },
  watch: {},
  mounted: function() {},
  methods: {
    async callEveryone() {
      if (!this.conference || !this.conference.confId) {
        dispatchErrorAlert("Could not start conference");
        return;
      }
      const extantEvent = this.timelineEvents.find(
        e => e.confId === this.conference.confId
      );
      if (this.conference.confDuration) {
        const duration = Math.floor(
          this.conference.confDuration * 60 * 1000
        );
        this.setdurationMeeting(duration);
      } else {
        const duration = Math.floor(1440 * 60 * 1000);
        this.setdurationMeeting(duration);
      }

      if (this.conference.confUUIDS) {
        const usersToCall = [];
        const usersFunction = {};
        const usersToHideName = [];
        this.$router.push({ path: "/home" });
        this.conference.confUUIDS.forEach(async uuid => {
          if (uuid !== this.state.ownUUID) {
            const isConnected =
              (store.state.group[uuid] || {}).connected || false;
            const person = store.state.group[uuid];
            if (
              person &&
              person.user &&
              !person.user.rtcCallStatus &&
              !person.user.inBridgeCall
            ) {
              if (!this.amInACall) {
                if (
                  isConnected &&
                  (((store.state.group[uuid] || {}).user || {}).activity ||
                    false) !== "Holidays"
                ) {
                  usersToCall.push(uuid);
                  if (!usersFunction[uuid]) {
                    if (
                      this.state.group[uuid].user.position ||
                      this.state.group[uuid].user.visitorData?.function
                    ) {
                      usersFunction[uuid] =
                        this.state.group[uuid].user.position ||
                        this.state.group[uuid].user.visitorData.function;
                    } else {
                      usersFunction[uuid] = "";
                    }
                  }
                }
              }
            }
          } else {
            if (!usersFunction[uuid]) {
              if (
                this.state.group[uuid].user.position ||
                this.state.group[uuid].user.visitorData?.function
              ) {
                usersFunction[uuid] =
                  this.state.group[uuid].user.position ||
                  this.state.group[uuid].user.visitorData.function;
              } else {
                usersFunction[uuid] = "";
              }
            }
          }
        });
        this.conference.members.forEach(member => {
          if (member.showUserName == false) {
            usersToHideName.push(member.uuid);
          }
        });
        const id = uuidv4();
        const info = {
          callUUID: id,
          calling: usersToCall,
          isNormalMode:
            this.conference.confUUIDS.length > 0 ? true : false,
          initiator: this.state.ownUUID,
          callStartTs: Date.now(),
          callDurationMs: this.conference.isUnlimited
            ? 24 * 3600 * 1000
            : Math.floor(this.conference.confDuration * 60000) ||
              60000 * 20,
          infiniteCall: false,
          conferenceDocuments: [], 
          userConferencePosition: {}, 
          excludedFromCalling: [], 
          conferenceAttendees: this.getAttendeesObject(
            this.conference.confUUIDS || []
          ),
          confModerators: this.conference.confModerators || [],
          conferenceSettings: {}, 
          usersToHideName: usersToHideName || [],
          isConference: true,
          confId: this.conference.confId,
          isConferenceCall: this.conference.confId || undefined
        };
        usersToCall.forEach((user, idx) => {
          const infoStartBridgeCall = {
            callUUID: id,
            isNormalMode:
              this.conference.confUUIDS.length > 0 ? true : false,
            initiator: this.state.ownUUID,
            callStartTs: Date.now(),
            callDurationMs: this.conference.isUnlimited
              ? 24 * 3600 * 1000
              : Math.floor(this.conference.confDuration * 60000) ||
                60000 * 20,
            infiniteCall: false,
            conferenceDocuments: [],
            userConferencePosition: {},
            excludedFromCalling: [],
            conferenceAttendees: this.getAttendeesObject(
              this.conference.confUUIDS || []
            ),
            confModerators: this.conference.confModerators || [],
            conferenceSettings: {},
            usersToHideName: usersToHideName || [],
            isConference: true,
            confId: this.conference.confId,
            isConferenceCall: this.conference.confId || undefined,
            isPinProtected: this.conference.confPin.length > 0,
            pinCode: this.conference.confPin.length > 0 ? this.conference.confPin : false
          };
          if (!hasPrivilege(user)) {
            markAsCallWaitingRoomUserEvent(user);
          }
          wsCallStartBridgeCall(
            user,
            this.state.ownUUID,
            infoStartBridgeCall,
            idx > 0
          );
        });
        if (extantEvent && extantEvent.uuid) {
          dispatchColorChangeTimelineEntry([extantEvent.uuid, "red"]);
        }
        store.addRemoteBridgeStream(id, info);
        store.setCurrentContentVisile("", false, this.$router);
      }
    },
    getAttendeesObject(uuids) {
      let myMembers = [];
      uuids.forEach(uuid => {
        if (uuid) {
          const aMember = {
            uuid,
            name: store.getNameForUuid(uuid),
            addUserToCall: true,
            showUserName: true
          };
          myMembers.push(aMember);
        }
      });
      return myMembers;
    },
    setdurationMeeting(duration) {
      return store.setdurationMeeting(duration);
    }
  },
  computed: {
    amInACall(){
      return amInABridgeCall();
    }
  }
};
</script>

<style scoped lang="scss">
</style>
<style lang="scss">
</style>
