<template>
  <div class="blinken" v-if="display">
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Messages {{messagesToMe.length}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span>!</span>
          <ul>
            <li v-for="msg of messagesToMe" :key="msg.messageUUID">
              {{ msg.messageUUID }} {{ msg.header }} {{ msg.body }} {{ msg.date }} <button @click="deleteThis(msg.messageUUID)">DEL</button>
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <LogFilter />

    <DomainComp :domain="domain" />
  </div>
</template>

<script>
// TODO: https://vuetifyjs.com/en/components/system-bars/
import DomainComp from './domain.vue';
import LogFilter from './logFilter.vue';
import { messagesToMe, dispatchDeleteMessageEvent } from '../../../effector/message';

export default {
  props: ['domain'],
  components: { DomainComp, LogFilter },
  data() {
    return {
      // domain: null,
      display: true,
    };
  },
  effector: {
    messagesToMe,
  },
  methods: {
    setDisplay(val) { this.display = val; },
    deleteThis(uuid) { dispatchDeleteMessageEvent(uuid); },
  },
  computed: { },
};
</script>

<style scoped lang="scss">
.blinken {
  position: fixed;
  top: 100px;
  right: 5px;
  z-index: 100000;
  min-width: 400px;
  background-color: gray;
  border: 1px solid black;
  max-height: 90%;
  max-width: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>