<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal :titleModal="$t('generics.info')" :closeModalFunction="closeModal" />
      <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
        <v-card-text>
          <p class="breakSpaces">{{$t("components.userManagement.deleteUserText", [getUserName])}}</p>
        </v-card-text>
      </v-row>
      <FooterModal :closeModalFunction="closeModal">
        <v-btn color="primary" @click="deleteFunction">{{
          $t("generics.accept")
        }}</v-btn>
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  props: ["showModal", "closeModal", "deleteUser", "userId"],
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
    };
  },
  computed:{
      getUserName(){
          // console.log('this.state.group[this.userId].user.name', this.state.group[this.userId].user.name)
          return this.state.group[this.userId] ? this.state.group[this.userId].user.name : '';
      }
  },
  methods: {
    deleteFunction() {
      if (this.userId === store.state.ownUUID) return;
      this.deleteUser(this.userId);
    },
  },
};
</script>
