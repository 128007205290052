<template>
  <v-dialog
    v-model="showModalSettings"
    persistent
    max-width="650"
    @keydown.esc="closeMethod"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.callsContent.addParticipants')"
        :closeModalFunction="closeMethod"
      />
      <v-card
        :min-height="
          $refs.usersCombo && $refs.usersCombo.isMenuActive ? '450px' : ''
        "
      >
        <v-row class="mx-0">
          <v-col cols="12">
            <p>
              {{ $t('components.callsContent.shareLinkText') }}
            </p>
            <v-text-field
              id="inputLink"
              class="inputDirectInviteLink"
              readonly
              dense
              outlined
              hide-details
              ref="linkRef"
              v-model="instaLink"
              height="50"
            >
              <template v-slot:append>
                <v-btn
                  class="buttonCopyLink"
                  icon
                  @click.stop.prevent="copyLink($event)"
                >
                  <font-awesome-icon
                    :icon="['fal', 'copy']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <p class="my-0">
              {{ $t('components.callsContent.addEmailNote') }}
            </p>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-combobox
              :menu-props="{
                maxHeight: 180,
              }"
              v-model="selectedUsers"
              :items="getData"
              :search-input.sync="searchUsers"
              item-text="text"
              item-key="value"
              multiple
              outlined
              class="usersCombo"
              dense
              :return-object="true"
              chips
              ref="usersCombo"
            >
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item.value)"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :disabled="data.disabled"
                  @click:close="data.parent.selectItem(data.item)"
                  close
                  :color="!emailIsCorrect(data.item) ? 'red' : ''"
                  :text-color="!emailIsCorrect(data.item) ? 'white' : ''"
                >
                  <v-list-item-avatar
                    :style="getBorderByStatusFull(state.group[data.item.value])"
                    class="ml-0 mr-1"
                  >
                    <v-img :src="data.item.avatar" contain> </v-img>
                  </v-list-item-avatar>
                  {{ data.item.text }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item @click="handlerSelectUser(data.item)">
                  <v-list-item-avatar
                    :style="`${getBorderByStatusFull(
                      state.group[data.item.value]
                    )}, borderRadius: '5px'`"
                  >
                    <v-img
                      contain
                      class="white--text align-end"
                      height="40"
                      :src="data.item.avatar"
                    >
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-title>
                    {{ data.item.text }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-card>
      <FooterModal :closeModalFunction="closeMethod">
        <v-btn
          color="primary"
          :disabled="disableCallUsers"
          :loading="loadingAddBtn"
          @click="callUsers()"
          >{{ $t('generics.sendInvite') }}
          <template v-slot:loader>
            <span class="custom-loader">
              <font-awesome-icon
                :icon="['fal', 'spinner-third']"
                :style="{ fontSize: '15px', color: 'white' }"
              />
            </span>
          </template>
        </v-btn>
      </FooterModal>
    </v-card>
    <v-spacer></v-spacer>
  </v-dialog>
</template>

<script>
  import store from '../../../../store'
  import { sendMailDirectCall } from '../../../../lib/wsMsg'
  import {
    getBorderByStatusFull,
    checkListSort,
  } from '../../../../utils/basicFunctions'
  import HeaderModal from '../../../modal/modalComponents/headerModal.vue'
  import FooterModal from '../../../modal/modalComponents/footerModal.vue'
  import { disableCallBtn, callPerson } from '../../../../utils/calls'
  import { markAsCallWaitingRoomUserEvent } from '../../../../effector/users/waitingInfo'
  import { v4 as uuidv4 } from 'uuid'

  export default {
    props: ['closeModal', 'showModalSettings', 'instaLink'],
    components: { HeaderModal, FooterModal },
    data() {
      return {
        state: store.state,
        selectedUsers: [],
        ownUUID: store.state.ownUUID,
        searchUsers: '',
        loadingAddBtn: false,
      }
    },
    methods: {
      closeMethod() {
        this.selectedUsers = []
        this.searchUsers = ''
        this.closeModal()
      },
      handlerSelectUser(item) {
        if (!this.disableCallBtnFunction(this.state.group[item.value])) {
          this.$refs.usersCombo.selectItem(item)
          this.searchUsers = ''
        } else {
          if (item.isGuest) {
            this.$refs.usersCombo.selectItem(item)
            this.searchUsers = ''
          }
        }
      },
      getUserTitel(uuid) {
        return store.getTitelForUuid(uuid)
      },
      async simpleUserInvite(guestObject) {
        const subject =
          this.subject ||
          this.$t('components.inviteVisitor.line1', [
            window.location.hostname,
            (
              this.getUserTitel(this.ownUUID) +
              ' ' +
              store.state.user.name
            ).trim(),
          ])

        let inviteText =
          ' ' +
          this.$t('components.conferenceForm.youHaveBeenInvitedFrom', [
            store.state.user.name,
          ]) +
          '\n' +
          '\n' +
          this.$t('components.conferenceForm.pleaseClickTheLink') +
          '\n' +
          this.instaLink +
          '\n\n'
        const odata = {
          visitorId: guestObject.value,
          name: guestObject.name,
          email: guestObject.email || '',
          mobilePhone: guestObject.mobilePhone || '',
          inviteText: inviteText,
          subject: subject,
          subjectDisplay: subject,
          company_email: store.state.namespaceSettings.companyInfo.email,
          company_name: store.state.namespaceSettings.companyInfo.company_name,
          virtualOfficePrefix: this.virtualOfficePrefix,
        }
        odata.variables = {}
        await sendMailDirectCall(odata)
      },
      getBorderByStatusFull(person) {
        return getBorderByStatusFull(person)
      },
      callUsers() {
        this.loadingAddBtn = true
        setTimeout(() => {
          this.loadingAddBtn = false
          if (this.disableCallUsers) return
          const callableUsers = this.selectedUsers.filter(
            (e) =>
              !e.isNewItem &&
              ((e.isGuest && this.state.group[e.value].connected) ||
                (!e.isGuest && this.state.group[e.value].connected))
          )
          const newGuests = this.selectedUsers.filter(
            (e) =>
              e.isNewItem || (e.isGuest && !this.state.group[e.value].connected)
          )
          callableUsers.forEach((user) => {
            if (!this.disableCallBtnFunction(this.state.group[user.value])) {
              callPerson(user.value, this.$router)
              if (this.state.group[user.value].visitorData) {
                markAsCallWaitingRoomUserEvent(user.value)
              }
            }
          })
          newGuests.forEach((guest) => {
            this.simpleUserInvite(guest)
          })
          this.closeMethod()
        }, 300)
      },
      disableCallBtnFunction(personObj) {
        let person = personObj
        return disableCallBtn(person) === undefined
          ? true
          : disableCallBtn(person)
      },
      isEmailInvalid(email) {
        if (email && email.length > 0) {
          const re = /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/
          return !re.test(email.trim().toLowerCase())
        } else {
          return true
        }
      },
      emailIsCorrect(obj) {
        if (typeof obj == 'string') {
          const newItem = {
            text: obj,
            email: obj,
            avatar: 'img/default_profile_picture.png',
            value: uuidv4(),
            isGuest: true,
            isNewItem: true,
          }

          this.selectedUsers.splice(this.selectedUsers.indexOf(obj), 1)
          this.selectedUsers.push(newItem)
          if (this.isEmailInvalid(newItem.email)) {
            const userIndex = this.selectedUsers.findIndex(
              (e) => e.value == newItem.value
            )
            if (userIndex !== -1) {
              this.selectedUsers[userIndex].badEmail = true
            }
            return false
          }
        } else {
          if (obj.isNewItem) {
            if (this.isEmailInvalid(obj.email)) {
              const userIndex = this.selectedUsers.findIndex(
                (e) => e.value == obj.value
              )
              if (userIndex !== -1) {
                this.selectedUsers[userIndex].badEmail = true
              }
              return false
            }
          }
        }
        return true
      },
      copyLink(evt) {
        const inputLink = document.getElementById('inputLink')
        inputLink.focus()
        inputLink.select()
        document.execCommand('copy')
        evt.target.classList.add('greenClass')
        setTimeout(() => {
          evt.target.classList.remove('greenClass')
        }, 1000)
      },
    },
    computed: {
      disableCallUsers() {
        const hasErrors = this.selectedUsers.filter(
          (e) => e.badEmail && e.badEmail == true
        )
        if (hasErrors && hasErrors.length) {
          return true
        }
        return false
      },
      getData() {
        let temporalResult = []
        let finalResult = []
        for (let uuid in this.state.group) {
          if (uuid) {
            const person = this.state.group[uuid]
            if (
              !person ||
              !person.connected ||
              !person.user ||
              !person.user.name ||
              person.user.name === '' ||
              person.user.uuid === this.state.ownUUID ||
              (person.user.visitorData &&
                person.user.visitorData.userInviter !== this.state.ownUUID)
            ) {
              continue
            } else {
              temporalResult.push({ ...person, uuid })
            }
          }
        }
        const sortedPersons = checkListSort(temporalResult, 'alphabetical')
        for (let i = 0; i < sortedPersons.length; i++) {
          const person = sortedPersons[i]
          const userUUID = person.uuid || person.user.uuid
          const temporalData = {
            text: person.user.name || '',
            email: person.user.email || '',
            avatar: person.user.avatar || 'img/default_profile_picture.png',
            value: userUUID,
            isGuest: person.user.visitorData,
          }
          finalResult.push({ ...temporalData, userUUID })
        }
        return finalResult
      },
      virtualOfficePrefix() {
        return (
          store.state.namespaceSettings.companyInfo.virtualOfficePrefix ||
          store.state.namespaceSettings.companyInfo.company_name ||
          store.getVirtualOfficePrefix()
        )
      },
    },
  }
</script>

<style scoped lang="scss">
  .inputDirectInviteLink {
    width: 80%;
  }
  .buttonCopyLink {
    top: -5px;
    position: relative;
  }
  .custom-loader {
    animation: loader 1s infinite;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
<style lang="scss">
  .usersCombo {
    .v-select__slot {
      max-height: 130px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 5px !important;
      margin-top: 5px !important;
    }
  }
</style>
