<template>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      max-width="570"
      @keydown.esc="closeModal"
    >
      <v-card>
        <div>
          <HeaderModal
            :titleModal="$t('generics.setHomePage')"
            :closeModalFunction="closeModal"
          />
          <v-row flex class="w-100 mx-0 my-0"></v-row>
          <v-form style="width: 100%; margin: 0" ref="form" lazy-validation>
            <v-row style="width: 100%; margin: 0">
              <v-col class="col-12 px-2 py-2">
                <v-text-field
                  outlined
                  dense
                  v-model="externalHomePage"
                  class="mt-2"
                  label="URL"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <FooterModal :closeModalFunction="closeModal">
            <v-btn v-on="on" icon color="white" @click="setHomePage">
              <font-awesome-icon
                :icon="['fal', 'check']"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </FooterModal>
          <v-spacer></v-spacer>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { setOrganisationSetting } from "../../lib/wsMsg";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
export default {
  props: ["showModal", "closeModal", "sectionData"],
    components: {
    FooterModal,
    HeaderModal,
  },
  data() {
    return {
      state: store.state,
      externalHomePage: (
        ((store.state.namespaceSettings.organisationSettings || {})[
          this.sectionData.type
        ] || {})[this.sectionData.name] || {}
      ).homepage,
    };
  },
  methods: {
    setHomePage() {
      // add function to set the url link into the apart (apartHomePage) (sectionData)
      setOrganisationSetting(
        this.sectionData.type,
        this.sectionData.name,
        "homepage",
        this.externalHomePage.trim()
      );
      this.closeModal();
    },
  },
};
</script>
<style scoped lang="scss">
.w-100 {
  width: 100%;
}
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  color: #02889d;
}
.dataUserCard {
  margin-bottom: 0px;
}
.borderRadius10,
.v-avatar .v-image {
  border-radius: 10px !important;
}
.w-200 {
  width: 200px;
}
.w-100-230 {
  width: calc(100% - 230px);
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
