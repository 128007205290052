<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="740"
    @keydown.esc="saveStatus"
  >
    <v-card style="background-color: lightyellow">
      <HeaderModal
        :titleModal="$t('components.aditionalStatusInfoModal.customStatus')"
        :closeModalFunction="saveStatus"
      />
      <v-card-text>
        <v-textarea
          id="textareaStatus"
          name="input-7-1"
          counter
          no-resize
          maxlength="120"
          v-model="additionalStatus"
        ></v-textarea>
      </v-card-text>
      <FooterModal :closeModalFunction="saveStatus">
        <v-btn
          color="primary"
          :disabled="additionalStatus.length === 0"
          @click="resetStatus()"
          >{{ $t("components.aditionalStatusInfoModal.reset") }}</v-btn
        >
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  props: ["showModal", "closeModal"],
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
      additionalStatus: store.state.user.additionalStatus,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    saveStatus() {
      store.setAdditionalStatusUser(this.additionalStatus);
      this.closeModal();
    },
    resetStatus() {
      this.additionalStatus = "";
    },
  },
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
<style lang="scss">
#textareaStatus {
  color: black !important;
}
</style>