<template>
  <div>
    <canvas class="streamCanvas" ref="canvas"></canvas>
    <video class="streamVideo" ref="video" :src-object.prop.camel="stream" autoplay playsinline muted :class="{'videoAway': state.localStreams.display}"></video>
  </div>
</template>

<script>
import * as posenet from '@tensorflow-models/posenet';
import * as bodyPix from '@tensorflow-models/body-pix';
import store from '../../store';

import { addStreamToAllConnections, removeStreamToAllConnections, waitForRtcStable, ensureUserStream } from '../../lib/rtcConn';

export default {
  props: ['stream'],
  data() {
    return {
      state: store.state,
      run: false,
      canvasStream: undefined,
    };
  },
  watch: {
    stream: function(newVal, oldVal) {
      if (newVal && oldVal && newVal.id === oldVal.id) return;
      this.cleanup();
      if (newVal) {
        this.setup(newVal);
      }
    },
  },
  methods: {
    setup(stream) {
      try {
        // this.$refs.video.play();
        this.run = true;
        this.scheduleUpdate();
        this.createStream();
        // ensureUserStream();
      } catch (err) {
        console.warn('streamCanvas setup:', err.message);
        this.cleanup();
      }
    },
    cleanup() {
      try {
        this.run = false;
        this.destroyStream();
        // ensureUserStream();
      } catch (err) {
        console.warn('streamCanvas cleanup:', err.message);
      }
    },
    createStream() {
      this.canvasStream = this.$refs.canvas.captureStream(25);
      const audioTrack = this.state.localStreams.user.getTracks().filter(function(track) {
        return track.kind === 'audio';
      })[0];
      this.canvasStream.addTrack(audioTrack);
      store.setUserCanvas(this.canvasStream);
      // addStreamToAllConnections(this.canvasStream);
    },
    destroyStream() {
      if (this.canvasStream) {
        this.canvasStream.getTracks().forEach(function (track) {
          if (track.kind === 'audio') return;
          track.stop();
        });
        removeStreamToAllConnections(this.canvasStream);
        // this.canvasStream.stop();
      }
      this.canvasStream = undefined;
      store.setUserCanvas(undefined);
    },
    async updateFrame() {
      try {
        const video = this.$refs.video;
        if (!video || !video.videoWidth || !video.videoHeight) return;
        video.width = video.videoWidth
        video.height = video.videoHeight
        const segmentation = await this.estimateSegmentation();
        this.drawBokeh(segmentation, this.$refs.canvas, video);
        // console.log('Tick', segmentation);
      } catch (e) {
        console.warn('streamCanvas updateFrame:', e);
      } finally {
        this.scheduleUpdate();
      }
    },
    scheduleUpdate() {
      if (!this.run) return;
      // requestAnimationFrame(this.boundUpdateFrame);
      setTimeout(this.boundUpdateFrame, 1000 / 25);
      // TODO: test with settimeout for background?
    },
    drawBokeh(segmentation, canvas, video, color) {
      bodyPix.drawBokehEffect(
        canvas,
        video,
        segmentation,
        3,
        3,
        false
      );
    },
    async estimateSegmentation() {
      if (!this.net) await this.loadBodyPix();
      return await this.net.segmentMultiPerson(this.$refs.video, {
        internalResolution: 'low',
        segmentationThreshold: 0.5,
        maxDetections: 5,
        scoreThreshold: 0.2,
        nmsRadius: 20,
        numKeypointForMatching: 17,
        refineSteps: 10
      })
    },
    async loadBodyPix() {
      this.net = await bodyPix.load()
    },
  },
  async mounted() {
    if (this.stream) this.setup(this.stream);
  },
  beforeDestroy() {
    this.cleanup();
  },
  created() {
    this.boundUpdateFrame = this.updateFrame.bind(this);
  },
};
</script>

<style scoped lang="scss">
.streamCanvas {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: black;
}

.streamVideo {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.videoAway{
visibility: hidden;
}
</style>