<template>
  <div>
    <v-dialog v-model="showModal" persistent max-width="570" @keydown.esc="closeModal">
      <v-card>
        <div>
          <v-card-title class="headline">
            <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
            {{$t('components.adminSettingsModal.addLocation')}}
          </v-card-title>
          <div>
            <template>
              <v-list-item class="py-0 switchWrapper">
                <v-list-item-content class="py-2 my-0">
                  <template>
                    <v-row class="mx-auto py-0">
                      <v-col cols="17">
                        <v-textarea
                          v-model="newCustomLocation"
                          name="inputinvite"
                          :no-resize="true"
                          class="py-0 my-0 mr-6"
                          rows="1"
                          :rules="requiredRule"
                          maxlength="20"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="5">
                        <v-btn
                          color="primary"
                          :disabled="doesLocationExists || newCustomLocation.trim() === ''"
                          @click="saveCustomLocation()"
                        >{{$t('components.adminSettingsModal.addLocation')}}</v-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <template>
                    <v-combobox
                      v-model="userLocationsToEdit"
                      :items="locationCombobox"
                      select
                      :search-input.sync="searchLocation"
                      item-text="name"
                      :label="$t('components.adminSettingsModal.customLocations')"
                      multiple
                      class="mx-1 label600"
                      dense
                      auto-select-first
                      @change="searchLocation = ''"
                    ></v-combobox>
                  </template>
                </v-list-item-content>
              </v-list-item>
            </template>
          </div>
          <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
            <v-row class="px-2 btns w-100 mx-0 my-0">
              <v-btn
                :disabled="userLocationsToEdit.length === 0"
                color="primary"
                @click="deleteCustomLocations"
              >{{$t('components.adminSettingsModal.deleteLocation')}}</v-btn>
              <v-btn color="primary" @click="saveData()">{{$t('generics.save')}}</v-btn>
              <v-btn color="primary" @click="closeModal()">{{$t('generics.close')}}</v-btn>
            </v-row>
          </v-card>
          <v-spacer></v-spacer>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "../../store";
import { setNamespaceSetting } from "../../lib/wsMsg";
export default {
  props: ["showModal", "closeModal"],
  data() {
    return {
      state: store.state,
      userLocationsToEdit:
        store.state.namespaceSettings.activeCompanyLocations || [],
      searchLocation: null,
      newCustomLocation: "",
    };
  },
  methods: {
    saveData() {
      setNamespaceSetting("activeCompanyLocations", this.userLocationsToEdit);
      this.closeModal();
    },
    saveCustomLocation() {
      let companyCustomLocations = this.state.namespaceSettings
        .companyLocations;
      companyCustomLocations.push(this.newCustomLocation.trim());
      setNamespaceSetting("companyLocations", companyCustomLocations);
      this.newCustomLocation = "";
    },
    deleteCustomLocations() {
      let companyCustomLocations = this.state.namespaceSettings
        .companyLocations;
      if (this.userLocationsToEdit && companyCustomLocations) {
        for (let i = 0; i < this.userLocationsToEdit.length; i++) {
          const locationSelected = this.userLocationsToEdit[i];
          for (let j = 0; j < companyCustomLocations.length; j++) {
            const companyLocation = companyCustomLocations[j];
            if (locationSelected === companyLocation) {
              companyCustomLocations.splice(j, 1);
            }
          }
        }
        setNamespaceSetting("companyLocations", companyCustomLocations);
        this.userLocationsToEdit = "";
      }
    },
  },
  computed: {
    requiredRule() {
      return [
        (v) =>
          !this.doesLocationExists ||
          this.$t("components.adminSettingsModal.locationExists"),
      ];
    },
    doesLocationExists() {
      let nameAlreadyExists = false;
      const companyLocations = this.state.namespaceSettings.companyLocations;
      if (companyLocations) {
        for (let i = 0; i < companyLocations.length; i++) {
          const location = companyLocations[i];
          if (
            location.trim().toLowerCase() ===
            this.newCustomLocation.trim().toLowerCase()
          ) {
            nameAlreadyExists = true;
            break;
          }
        }
      }
      return nameAlreadyExists;
    },
    locationCombobox: function (data) {
      const locationsResult = [];
      const locations = this.state.namespaceSettings.companyLocations || [];
      if (locations) {
        for (let i = 0; i < locations.length; i++) {
          const location = locations[i];
          locationsResult.push(location);
        }
      }
      return locationsResult;
    },
  },
};
</script>
<style scoped lang="scss">
.w-100 {
  width: 100%;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
