<template>
  <v-dialog :value="visible" persistent max-width="460" @keydown.esc="hide(false)">
    <v-card>
      <v-card-title class="headline">
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        Helpscout Schnittstelle
      </v-card-title>
      <v-card-text>
        <p>{{ $t('components.excelEditor.helpscoutInfo') }}</p>
        <p><a href="https://docs.helpscout.com/article/1250-beacon-jumpstart-guide" target="_blank">{{ $t('components.excelEditor.helpscoutQuickstart') }}</a></p>
        <v-textarea
          v-model="rawHelpscoutBeaconText"
          outlined
          name="input-7-4"
          label="Helpscout Beacon HTML Code / Beacon ID"
          placeholder="<script type='text/javascript'..."
        ></v-textarea>
      </v-card-text>
      <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns mx-0" flex>
          <v-col cols="8" class="px-0 py-0" align="right">
            <v-btn color="primary" @click="hide(true)">{{ $t('generics.ok') }}</v-btn>
            <v-btn color="primary" @click="hide(false)">{{ $t('generics.cancel') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      rawHelpscoutBeaconText: null,
      callback: null,
      visible: false,
    }
  },
  methods: {
    hide(save) {
      if (save) {
        const uuidRegex = /([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}){1}/g
        const extractedBeaconId = this.rawHelpscoutBeaconText.match(uuidRegex)
        this.callback(extractedBeaconId ? extractedBeaconId[0] : undefined)
      }
      this.rawHelpscoutBeaconText = null
      this.callback = null
      this.visible = false
    },
    show(row, callback) {
      this.callback = callback
      this.rawHelpscoutBeaconText = row.helpscoutBeaconId
      this.$nextTick(() => this.visible = true)
    }
  },
}
</script>