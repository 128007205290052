<template>
<!-- UNSUED MODAL -->
  <div>
    <v-dialog v-model="showModal" persistent max-width="570" @keydown.esc="closeModal">
      <v-card>
        <v-card-title class="headline">
          <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
          {{ $t('components.drawerContentCard.files') }}
        </v-card-title>
        <DownloadsDropdown :customHeight="true" />
        <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
          <v-row class="px-2 btns w-100 mx-0 my-0">
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="white" @click="closeModal">
                    <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '25px' }" />
                  </v-btn>
                </template>
                <span>{{ $t('generics.close') }}</span>
              </v-tooltip>
            </template>
          </v-row>
        </v-card>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "../../store";
import DownloadsDropdown from "../sidebar/downloadsDropdown.vue";
export default {
  components: { DownloadsDropdown },
  props: ["showModal", "closeModal"],
  data() {
    return {
      state: store.state,
    };
  },
};
</script>
<style scoped lang="scss">
.w-100 {
  width: 100%;
}
.borderRadius10,
.v-avatar .v-image {
  border-radius: 10px !important;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>