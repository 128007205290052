<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('generics.info')"
          :closeModalFunction="closeModal"
        />
        <v-container>
          <p>
            {{ $t("components.mobile.callText1") }}
          </p>
        </v-container>
        <FooterModal :closeModalFunction="closeModal"> </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../../store";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";
import FooterModal from "../../modal/modalComponents/footerModal.vue";
import {
  resetMobileCallModalEvent,
  callMobileModalStore,
} from "../../../effector/modals";

export default {
  //   props: ["showModal", "closeModal"],
  components: {
    HeaderModal,
    FooterModal,
  },
  data() {
    return {
      state: store.state,
    };
  },
  effector: {
    showModal: callMobileModalStore,
  },
  mounted() {},
  destroyed() {},
  watch: {},
  methods: {
    closeModal() {
      resetMobileCallModalEvent();
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
</style>
