<template>
  <v-badge
    bordered
    color="error"
    :content="totalNotification"
    :value="totalNotification"
    overlap
    :offset-y="isMobile && isNavbar ? 14 : !isMobile && isNavbar ? 25 : null"
    :offset-x="isMobile && isNavbar ? 13 : !isMobile && isNavbar ? 20 : null"
  >
    <v-menu
      offset-y
      :close-on-click="true"
      :max-height="370"
      :max-width="350"
      v-model="menuOpen"
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :small="isMobile"
                icon
                :color="
                  !hasPrivilege(state.ownUUID)
                    ? '#02889d'
                    : wholeBarWithColors()
                    ? 'white'
                    : 'black'
                "
                v-on="on"
                @click="setNotificationEffector()"
                id="v-tour-messages"
              >
                <font-awesome-icon
                  v-if="!hasPrivilege(state.ownUUID)"
                  :icon="['fal', 'bell']"
                  :style="{ fontSize: '16px' }"
                />
                <font-awesome-icon
                  v-else
                  :icon="['fal', 'paper-plane']"
                  :style="{ fontSize: '16px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.drawerContentCard.messages") }}</span>
          </v-tooltip>
        </div>
      </template>
      <NotificatiosnWaitingRoomDropDownEffector
        :notificationType="'message'"
        :closeMenu="closeMenu"
        :menuOpen="menuOpen"
      />
    </v-menu>
  </v-badge>
</template>

<script>
import store from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import NotificatiosnWaitingRoomDropDownEffector from "../notificationsWaitingRoomDropDownEffector.vue";
import {
  unseenMessagesToMe,
  dispatchViewedMessageEvent,
  messagesToMe,
} from "../../../effector/message";
import DownloadsDropdown from "../downloadsDropdown.vue";
import { wholeBarWithColor } from "../../../utils/color";
import { hasPrivilege } from "../../../utils/privileges";

export default {
  props: ["setTotalNotificationCall", "setTotalNotificationFile", "isNavbar"],
  components: { NotificatiosnWaitingRoomDropDownEffector, DownloadsDropdown },
  effector: {
    unseenMessagesToMe: unseenMessagesToMe,
    messagesToMe: messagesToMe,
  },
  data: () => ({
    state: store.state,
    menuOpen: false,
    isMobile: isMobile(),
  }),
  methods: {
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    closeMenu() {
      this.menuOpen = false;
    },
    setNotificationEffector() {
      this.setAllNotification();
    },
    setAllNotification() {
      if (this.messagesToMe.length > 0) {
        dispatchViewedMessageEvent();
      }
      if (this.state.persisted.totalNotificationCall > 0) {
        this.setTotalNotificationCall(0);
      }
      if (this.state.persisted.totalNotificationFile > 0) {
        this.setTotalNotificationFile(0);
      }
    },
    wholeBarWithColors() {
      return wholeBarWithColor();
    },
  },
  computed: {
    totalNotification() {
      return (
        this.unseenMessagesToMe +
        this.state.persisted.totalNotificationCall +
        this.state.persisted.totalNotificationFile
      );
    },
  },
};
</script>