<template>
  <v-dialog
    v-model="showjoinConference"
    persistent
    max-width="735"
  >
    <v-card v-for="user in showjoinConference" :key="user">
      <HeaderModal :titleModal="$t('generics.message')" />
      <v-row flex :style="{ width: '100%', margin: 0 }">
        <div class="d-flex" :style="{ width: '100%' }">
          <div align="center">
            <v-badge
              bordered
              overlay
              height="60"
              width="60"
              bottom
              :color="badgetClassObj(user)"
              dot
              offset-x="22"
              offset-y="15"
            >
              <v-list-item-avatar tile size="150" class="borderRadius4">
                <template>
                  <DefaultAvatar
                    :size="150"
                    :userData="getUserData(user)"
                    v-if="
                      getUserData(user).avatar ===
                      'img/default_profile_picture.png'
                    "
                  ></DefaultAvatar>
                  <v-img
                    v-if="getUserData(user).avatar !== 'img/default_profile_picture.png'"
                    class="borderRadius4"
                    max-height="150"
                    max-width="150"
                    contain
                    :src="getUserData(user).avatar"
                    lazy-src="assets/default_profile_picture.png"
                  ></v-img>
                </template>
              </v-list-item-avatar>
            </v-badge>
          </div>
          <div class="text-center" :style="{ width: '100%' }">
            <v-divider class="mt-4"></v-divider>
            <div class="mt-2 ml-4">
              <p color="primary" class="userNameCard">
                {{ getUserData(user).titel }} {{ getUserData(user).name }}
              </p>
              <p color="primary">{{ getTextModal }}</p>
            </div>
            <v-divider class="mt-4"></v-divider>
          </div>
        </div>
      </v-row>
      <FooterModal :hideDefaultButtons="true">
        <template>
          <div class="text-left d-flex mb-2">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mr-4"
                  v-on="on"
                  outlined
                  x-small
                  color="white"
                  @click="acceptJoin(user)"
                >
                  <span>{{ $t("components.callsContent.beCalledLater") }}</span>
                </v-btn>
              </template>
              <span>{{ $t("components.callsContent.beCalledLater") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mr-4"
                  v-on="on"
                  outlined
                  x-small
                  color="white"
                  @click="declineJoin(user)"
                >
                  <span>{{ $t("components.callsContent.cantAnswer") }}</span>
                </v-btn>
              </template>
              <span>{{ $t("components.callsContent.cantAnswer") }}</span>
            </v-tooltip>
          </div>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mr-4"
                v-on="on"
                outlined
                color="white"
                @click="callUser(user)"
              >
                <span>{{ $t("generics.accept") }}</span>
              </v-btn>
            </template>
            <span>{{ $t("generics.accept") }}</span>
          </v-tooltip>
        </template>
      </FooterModal>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { getStatusColorByUser } from "../../utils/status";
import { wholeBarWithColor } from "../../utils/color";
import DefaultAvatar from "../image/defaultAvatar.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import { wsCall } from "../../lib/wsConnect";
import { callPerson } from "../../utils/calls";
import { joinConferenceStore, resetJoinConferenceEvent, deleteUserJoinConferenceEvent } from "../../effector/modals";
export default {
  data() {
    return {
      state: store.state,
    };
  },
  components: {
    DefaultAvatar,
    FooterModal,
    HeaderModal,
  },
  watch: {},
  mounted() {},
  effector: {
    showjoinConference: joinConferenceStore,
  },
  methods: {
    callUser(userUuid) {
      callPerson(userUuid);
      this.closeModal(userUuid);
    },
    acceptJoin(userUuid) {
      wsCall("sendToUUID", userUuid, {
        type: "answerJoinConference",
        message: "accepted",
        sender: this.state.ownUUID,
      });
      this.closeModal(userUuid);
    },
    declineJoin(userUuid) {
      wsCall("sendToUUID", userUuid, {
        type: "answerJoinConference",
        message: "denied",
        sender: this.state.ownUUID,
      });
      this.closeModal(userUuid);
    },
    wholeBarWithColors() {
      return wholeBarWithColor();
    },
    closeModal(userUuid) {
        if(this.showjoinConference.length>1){
            deleteUserJoinConferenceEvent(userUuid)
        }else{
          resetJoinConferenceEvent()
        }
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getUserData(userUuid) {
      return store.state.group[userUuid].user;
    },
    isUserConnected(userUuid) {
      return store.state.group[userUuid].connected;
    },
    badgetClassObj(userUuid) {
      if (this.wholeBarWithColors()) {
        return getStatusColorByUser(
          this.getUserData(userUuid),
          this.isUserConnected(userUuid)
        );
      } else {
        return "rgba(0,0,0,0.3)";
      }
    },
  },
  computed: {
    getTextModal() {
      return this.$t("components.callsContent.joinConference");
    },
  },
};
</script>
<style scoped lang="scss">
.borderRadius4 {
  border-radius: 4px;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
