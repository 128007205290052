<template>
  <!-- Incoming call modal  -->
  <div>
    <v-dialog :fullscreen="isMobile" v-model="haveCalls"  persistent max-width="700" class="incomingCallModalTest">
      <audio id="audioSource" ref="audioSource" :src="playingRingTone" autoplay loop></audio>
      <IncomingBridgeCallSub :key="call.uuid" v-for="call in incomingCallsArray" tabindex="0" :call="call" />
    </v-dialog>
    <v-dialog :fullscreen="isMobile" v-model="haveConferenceCalls" persistent max-width="700" class="incomingCallModalTest">
      <audio id="audioSource" ref="audioSource" :src="playingConfRingTone" autoplay loop></audio>
      <ConferenceIncomingBridgeCallSub :key="call.uuid" v-for="call in incommingConfCallsArray" tabindex="0" :call="call" />
    </v-dialog>
  </div>
  <!-- end incoming call modal -->
</template>

<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import IncomingBridgeCallSub from "./incomingBridgeCallSub.vue";
import ConferenceIncomingBridgeCallSub from "./conferenceIncomingBridgeCallSub.vue";

export default {
  components: { IncomingBridgeCallSub, ConferenceIncomingBridgeCallSub },
  data() {
    return {
      state: store.state,
      isMobile: isMobile()
    };
  },
  computed: {
    volumCallTone() {
      let volum = store.state.persisted.mediaDeviceSetup.volumeCallTone;
      if (isNaN(volum)) {
        volum = 100;
      }
      volum = volum / 100;
      return volum;
    },
    haveCalls() {
      if (store.state.nerd) console.debug('have calls', this.incomingCallsArray.length);
      return this.incomingCallsArray.length > 0;
    },
    haveConferenceCalls() {
      if (store.state.nerd) console.debug('have conference calls', this.incommingConfCallsArray.length);
      return this.incommingConfCallsArray.length > 0;
    },
    incommingConfCallsArray() {
      const confCalls = [];
      for (let uuid in store.state.incomingBridgeCallModal.calls) {
        const call = store.state.incomingBridgeCallModal.calls[uuid];
        if (!call) continue;
        if (call.isConference){
          confCalls.push({ ...call, uuid });
        }
      }
      return confCalls.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
    incomingCallsArray() {
      const calls = [];
      for (let uuid in store.state.incomingBridgeCallModal.calls) {
        const call = store.state.incomingBridgeCallModal.calls[uuid];
        if (!call) continue;
        if (!call.isConference){
          calls.push({ ...call, uuid });
        }
      }
      return calls.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
    playingRingTone() {
      if (this.state.persisted.notificationsMuted) return "";
      // if (!this.incomingCallsArray.length) return "";
      for (let call of this.incomingCallsArray) {
        if (this.$refs.audioSource) {
          this.$refs.audioSource.volume = this.volumCallTone;
        }
        if (
          store
            .getNameForUuid(call.uuid)
            .toLowerCase()
            .indexOf("lazy") !== -1
        ) {
          return "/media/incommingCallLazy.mp3";
        }
      }
      return "/media/incommingCall1.mp3";
    },
    playingConfRingTone() {
      if (this.state.persisted.notificationsMuted) return "";
      if (!this.incommingConfCallsArray.length) return "";
      for (let call of this.incommingConfCallsArray) {
        if (this.$refs.audioSource) {
          this.$refs.audioSource.volume = this.volumCallTone;
        }
        if (
          store
            .getNameForUuid(call.uuid)
            .toLowerCase()
            .indexOf("lazy") !== -1
        ) {
          return "/media/incommingCallLazy.mp3";
        }
      }
      return "/media/incommingCall1.mp3";
    },
  }
};
</script>
<style scoped lang="scss">
</style>
