<template>
  <v-dialog persistent v-model="showInviteInfo" max-width="560" @keydown.esc="closeShowInviteInfo">
    <v-card>
      <v-card-title class="headline justify-center">
        <v-layout row wrap justify-start>
          <v-img class="mx-2 logo" max-height="40" max-width="40" src="assets/icon.png" contain></v-img>
          <label style="margin-left: 50px; line-height: 2em;">{{ $t('components.sendInviteInfoModal.title') }}</label>
        </v-layout>
        <v-layout row wrap justify-end></v-layout>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="typeInvite === 'guest'" class="py-2">
        {{ $t('components.sendInviteInfoModal.firstLine') }}
        <br /> {{ $t('components.sendInviteInfoModal.secondLine') }}
      </v-card-text>
      <v-card-text v-else class="py-2">
        {{ $t('components.sendInviteInfoModal.userLine1') }}
        <br /> {{ $t('components.sendInviteInfoModal.userLine2') }}
        <br /> {{ $t('components.sendInviteInfoModal.userLine3') }}
      </v-card-text>
      <v-row class="px-2 btns mx-0" flex>
        <v-col class="px-2 py-2" align="right">
          <v-btn color="primary" @click="closeShowInviteInfo">{{ $t('generics.ok') }}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>


<script>
import store from "../../store";
// import CamAndMic from "../firstSetup/camAndMic.vue";
// import LanguageDropdown from "../navbar/languageDropdown.vue";
export default {
  //   components: { CamAndMic, LanguageDropdown },
  props: ["showInviteInfo", "closeShowInviteInfo", "typeInvite"],
  data: () => ({
    state: store.state,
    save: false
  }),
  methods: {
    // closeModal(){
    //   if (window.stream) {
    //     window.stream.getTracks().forEach(track => {
    //       track.stop();
    //     });
    //   }
    //   if ( this.toggleModalSettings ){
    //     this.toggleModalSettings();
    //   }
    // },
    // saveUserMedia(){
    //   this.$emit('saveUserMedia', true)
    // }
  },
  computed: {}
};
</script>

<style scoped lang="scss">
.btns {
  justify-content: flex-end;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}

.logo {
  left: 0;
  position: absolute;
}
</style>