<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="650"
    @keydown.esc="closeModal"
    :attach="getAttach"
    :hide-overlay="getAttach ? true : false"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.conferenceForm.titleModalPDF')"
        :closeModalFunction="closeModal"
      />

      <v-row class="mx-0">
        <v-col cols="12">
          <v-list>
            <v-list-item
              v-for="document in getDataConference.pdfDocuments"
              :key="document.documentId"
            >
              <v-list-item-content class="py-0">
                <v-list-item-title>
                  <a :href="fixDocPath(document.documentURL)" target="_blank">{{
                    document.documentName
                  }}</a>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon v-if="amIModerator">
                <v-btn  icon>
                  <DeleteBasket :indexData="document" :delFunction="deleteDocumentBtn" extraClasses="" fontSize="17" prevent= false />
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-row class="mx-0" v-if="showAddNewDocument">
        <v-col cols="6" class="py-0">
          <v-text-field
            hide-details
            v-model="documentName"
            dense
            solo
            :label="$t('components.conferenceForm.pdfSubject')"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            hide-details
            v-model="documentURL"
            dense
            solo
            :label="'URL'"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mx-0" v-if="amIModerator">
        <v-col cols="12" class="textRight">
          <v-tooltip top v-if="showAddNewDocument">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="primary"
                @click="saveNewDocument"
                :disabled="documentName == '' || documentURL == ''"
              >
                {{ $t("generics.save") }}
              </v-btn>
            </template>
            <span>{{ $t("generics.save") }}</span>
          </v-tooltip>

          <v-tooltip top v-if="!showAddNewDocument">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="primary"
                @click="showAddNewDocument = true"
              >
                {{ $t("components.conferenceForm.addpdfConference") }}
              </v-btn>
            </template>
            <span>{{ $t("components.conferenceForm.addpdfConference") }}</span>
          </v-tooltip>
          <v-tooltip top v-if="showAddNewDocument">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="primary"
                @click="showAddNewDocument = false"
              >
                {{ $t("generics.cancel") }}
              </v-btn>
            </template>
            <span>{{ $t("generics.cancel") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <FooterModal :closeModalFunction="closeModal" />
    </v-card>
    <v-spacer></v-spacer>
  </v-dialog>
</template>

<script>
import store from "../../store";
import {
  dispatchSuccessAlert,
  dispatchErrorAlert,
} from "../../effector/alerts";
import { resetConferenceDocumentsEvent } from "../../effector/modals";
import {
  timelineEvents,
  updateTimelineItemEvent,
} from "../../effector/timeline";
import { myConferenceDetails,  updateGroupConferenceEvent } from "../../effector/groupConferences";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import DeleteBasket from "../ui/deleteBasket.vue"
import { v4 as uuidv4 } from "uuid";
export default {
  props: ["showModal", "amIModerator", "attachTo"],
  components: {
    HeaderModal,
    FooterModal,
    DeleteBasket,
  },
  data() {
    return {
      state: store.state,
      showAddNewDocument: false,
      documentName: "",
      documentURL: "",
      pdfList: [],
    };
  },
  effector: {
    cacheTimeline: timelineEvents,
    myConferences: myConferenceDetails
  },
  methods: {
    fixDocPath(url){
     return (url.indexOf('://') === -1) ? 'http://' + url : url; 
    },
    closeModal() {
      resetConferenceDocumentsEvent();
    },
    deleteDocumentBtn(documentToDelete) {
      const conferenceId = this.showModal;
      let tmpData = this.getDataConference;
      this.pdfList = this.getDataConference.pdfDocuments;
      this.pdfList = this.getDataConference.pdfDocuments.filter(
        (e) => e.documentId !== documentToDelete.documentId
      );
      tmpData.pdfDocuments = this.pdfList;
      updateTimelineItemEvent([conferenceId, tmpData]);
      this.cleanForm();
      dispatchSuccessAlert(this.$t("generics.documentDeleted"));
    },
    isValidURL(s) {
      return s.match(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g
      );
    },
    saveNewDocument() {
      if (!this.isValidURL(this.documentURL)) {
        dispatchErrorAlert(this.$t("generics.invalidUrl"));
        return;
      }
      const conferenceId = this.showModal;
      this.pdfList = this.getDataConference.pdfDocuments;
      let tmpData = this.getDataConference;
      const newDocumentUUID = uuidv4();
      const documentObj = {
        documentId: newDocumentUUID,
        documentName: this.documentName,
        documentURL: this.documentURL,
      };
        if (this.pdfList && this.pdfList.length > 0 ){
        this.pdfList.push(documentObj);
        }else{
          this.pdfList = [documentObj]
        }
      tmpData.pdfDocuments = this.pdfList;
      updateTimelineItemEvent([conferenceId, tmpData]);
      const extant = this.myConferences.find(e => e.confId === tmpData.confId);
      if(extant) {
        extant.pdfDocuments = this.pdfList;
        updateGroupConferenceEvent(extant)
      } 

      // tmpData.confId
      dispatchSuccessAlert(this.$t("generics.documentsaved"));
      this.cleanForm();
    },
    cleanForm() {
      this.documentName = "";
      this.documentURL = "";
      this.showAddNewDocument = false;
      this.selectedDocument = null;
    },
  },
  computed: {
     getAttach(){
      if ( this.attachTo ){
        const element = document.getElementById(this.attachTo);
        if ( element ){
          return element;
        }
      }
      return false;
    },
    getDataConference() {
      const conferenceId = this.showModal;
      const dataConference = myConferenceDetails.find(e => e.confId === conferenceId)
      this.dataConference = dataConference;
      // const dataConference = this.cacheTimeline.find(
      //   (e) => e.uuid === conferenceId
      // );
      return dataConference;
    },
  },
};
</script>

<style scoped lang="scss">
.textRight {
  text-align: right;
}
</style>
