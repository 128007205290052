var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',{staticClass:"px-0 py-0",staticStyle:{"width":"100%","height":"90vh"}},[(_vm.hasPrivilege(_vm.ownUUID))?_c('v-card',{staticClass:"justify-center align-center noBoxShadow transparentBG",staticStyle:{"width":"100%","height":"100%","display":"table"},attrs:{"width":"100%","height":"100%"}},[_c('v-row',{staticStyle:{"width":"100%","height":"100%","display":"table-cell","vertical-align":"middle","text-align":"center","padding-bottom":"150px"}},[(_vm.isFirstView)?_c('div',{staticStyle:{"margin-bottom":"10px"},attrs:{"cols":"12"}},[_c('h2',[_vm._v("\n          "+_vm._s(_vm.$t("components.firstSetup.welcomeHeader", [_vm.virtualOfficePrefix]))+"\n        ")])]):_vm._e(),_vm._v(" "),(_vm.isFirstView)?_c('div',{staticStyle:{"margin-bottom":"14px"},attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$t("components.firstSetup.welcomeSub", [_vm.getPath])))])]):_vm._e(),_vm._v(" "),_c('div',{staticStyle:{"text-align":"center","padding-bottom":"15px"},attrs:{"cols":"12"}},[_c('v-img',{staticStyle:{"margin":"0 auto"},attrs:{"max-height":"225","max-width":"225","src":_vm.showCompanyLogo ? _vm.showCompanyLogo : 'assets/icon.png',"contain":""},on:{"click":function($event){_vm.state.namespaceSettings.companyHomePage
              ? _vm.goToExternalHomePage()
              : null}}})],1),_vm._v(" "),_c('div',{staticClass:"mb-3 mt-3",staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 headline font-weight-regular"},[_vm._v(_vm._s(_vm.$t("components.homeButtons.startMeeting")))])]),_vm._v(" "),_c('div',{staticStyle:{"text-align":"center","padding-top":"15px"},attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"justStart no-bg list buttonHome1 mb-2",attrs:{"depressed":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.callInstaconference()}}},on),[_c('font-awesome-icon',{staticClass:"primary--text",style:({ fontSize: '18px' }),attrs:{"icon":['fal', 'bolt']}}),_vm._v(" "),_c('span',{staticClass:"ml-3 font-weight-regular"},[_vm._v(_vm._s(_vm.$t("components.homeButtons.immediateMeeting")))])],1)]}}],null,false,757911275)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.homeButtons.immediateMeeting")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"justStart no-bg list buttonHome2 mb-2",attrs:{"depressed":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.goToPage('waitingRoom')}}},on),[_c('font-awesome-icon',{staticClass:"primary--text",style:({ fontSize: '18px' }),attrs:{"icon":['fal', 'users-class']}}),_vm._v(" "),_c('span',{staticClass:"ml-3 font-weight-regular"},[_vm._v(_vm._s(_vm.$t("components.homeButtons.WRGuest")))])],1)]}}],null,false,1446219144)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.homeButtons.WRGuest")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"justStart no-bg list mb-2",attrs:{"depressed":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.goToPage('conferences')}}},on),[_c('font-awesome-icon',{staticClass:"primary--text",style:({ fontSize: '18px' }),attrs:{"icon":['fal', 'users']}}),_vm._v(" "),_c('span',{staticClass:"ml-3 font-weight-regular"},[_vm._v(_vm._s(_vm.$t("components.conferenceForm.createConference")))])],1)]}}],null,false,1945800692)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.conferenceForm.createConference")))])])],1),_vm._v(" "),(_vm.isFirstView)?_c('div',{staticStyle:{"margin-bottom":"20px"},attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$t("components.firstSetup.welcomeText1")))])]):_vm._e(),_vm._v(" "),(_vm.isFirstView)?_c('div',{staticStyle:{"margin-bottom":"20px"},attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$t("components.firstSetup.welcomeText2")))])]):_vm._e(),_vm._v(" "),(_vm.isFirstView)?_c('div',{staticStyle:{"margin-bottom":"20px"},attrs:{"cols":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.$t('components.firstSetup.welcomeText3')))}})]):_vm._e()])],1):_vm._e(),_vm._v(" "),(!_vm.hasPrivilege(_vm.ownUUID))?[_c('WaitingRoomContainerNew')]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }