import Vue from 'vue';
import Vuetify from 'vuetify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FontsActuallyUsed from './fontawesome.js';
import i18n from './i18n.js';

Vue.component('font-awesome-icon', FontAwesomeIcon); // Register component globally
library.add(...FontsActuallyUsed); // Include needed icons

Vue.use(Vuetify);

// https://github.com/vuetifyjs/vuetify/issues/6755
const VImg = Vue.component('VImg').extend({
  name: 'VImg',
  methods: {
    onError() {
      // console.warn(`Image load failed\n${this.normalisedSrc.src}`);
      this.$emit('error', this.src);
    }
  },
});
Vue.component('VImg', VImg); // Override VImg globally

const opts = {
  icons: {
    iconFont: 'fa'
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#02889D',
        secondary: '#02889D',
        accent: '#3AA2B3',
        error: '#FF000B',
        warning: '#FFD000',
        info: '#02889D',
        success: '#009B1C'
      },
      dark: {
        primary: '#02889D'
      }
    }
  }
};

export default new Vuetify(opts);
