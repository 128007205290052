<template>
  <v-list>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-list-item @click.stop.prevent="handleWaitingRoom()" v-on="on">
          <v-list-item-icon
            class="primary--text"
            :class="activeBlueClass ? ' primary--text' : ''"
          >
            <font-awesome-icon
              v-if="currentPage('/waitingroomfor/' + waitingRoomFor)"
              class="primary--text"
              :icon="['fas', 'users-class']"
              :class="activeBlueClass ? 'primary--text' : ''"
            />
            <font-awesome-icon
              v-else
              class="primary--text"
              :icon="['fal', 'users-class']"
              :class="activeBlueClass ? 'primary--text' : ''"
            />
          </v-list-item-icon>
          <v-badge
            @click.native="handleWaitingRoom()"
            v-if="getconnecetedWaitingrooms > 0"
            right
            color="error"
            :content="getconnecetedWaitingrooms"
            :value="getconnecetedWaitingrooms"
            bordered
            overlap
            offset-x="7"
            offset-y="7"
            class="waitingRoomBadge"
          ></v-badge>
          <v-list-item-title
            :class="activeBlueClass ? 'font-weight-bold ' : ''"
          >
            {{ getTitleWaitingROOM }}
            {{
              `${
                assistantWaitingRoomList.length > 0
                  ? "(" + assistantWaitingRoomList.length + ")"
                  : ""
              }`
            }}
          </v-list-item-title>
        </v-list-item>
      </template>
      <span>{{ $t("components.waitingRoom.myWaitinRoomTT", [this.getTitelForUuid(this.waitingRoomFor) + " " + this.getNameForUuid(this.waitingRoomFor)] ) }}</span>
    </v-tooltip>
  </v-list>
</template>
<script>
import store from "../../store";
import { hasPrivilege } from "../../utils/privileges";
import { waitingInfoStore, waitingForInfoStore } from "../../effector/users/waitingInfo";
export default {
  props: ["active", "waitingRoomFor"],
  data: () => ({
    state: store.state,
    setCurrentContentVisile: store.setCurrentContentVisile,
    ownUUID: store.state.ownUUID,
  }),
  effector: {
    waitingUsesEffect: waitingInfoStore,
    waitingForUsesEffect: waitingForInfoStore
  },
  methods: {
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    handleWaitingRoom() {
      if (hasPrivilege(this.state.ownUUID)) {
        if (this.$route.path !== "/waitingroomfor") {
          if (this.state.ownUUID === this.waitingRoomFor) {
            store.setCurrentContentVisile("waitingroomfor", true, this.$router);
          } else {
            store.setCurrentContentVisile(
              `waitingroomfor/${this.waitingRoomFor}`,
              true,
              this.$router
            );
          }
        } else if (
          this.$route.path == "/waitingRoomFor" ||
          this.$route.path == "/waitingroomfor"
        ) {
          this.setCurrentContentVisile("home", true, this.$router);
          store.setShowHomeView(true);
        }
      }
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {
    getWaitingUsesEffect() {
      return this.waitingUsesEffect;
    },
    getWaitingForUsesEffect() {
      return this.waitingForUsesEffect;
    },
    getconnecetedWaitingrooms() {
      if (!this.assistantWaitingRoomList || !this.assistantWaitingRoomList.length) {
        return 0;
      }
      const calledVisitors = Object.entries(this.getWaitingForUsesEffect.called)
        .filter(([uuid, value]) => {
          return !!(value && this.assistantWaitingRoomList.find((e) => e.uuid === uuid))
        })
        .map(([uuid, value]) => uuid);
      const total = this.assistantWaitingRoomList.length - calledVisitors.length;
      return total;
    },
    activeBlueClass() {
      if (
        (this.getRoute.indexOf("/waitingroomfor") !== -1 &&
          this.$route.params.uuid === this.waitingRoomFor) ||
        (!hasPrivilege(this.state.ownUUID) && this.getRoute === "/home")
      ) {
        return true;
      } else {
        return false;
      }
    },
    getTitleWaitingROOM() {
      const waitingRoomName = this.$t(
        "components.drawerContentExpansion.guest"
      );
      if (this.waitingRoomFor === this.state.ownUUID) {
        return waitingRoomName;
      } else {
        return (
          waitingRoomName +
          " " +
          this.$t("generics.from") +
          " " +
          this.getTitelForUuid(this.waitingRoomFor) +
          " " +
          this.getNameForUuid(this.waitingRoomFor)
        );
      }
    },
    assistantWaitingRoomList() {
      return store.getWaitingRoomList(this.waitingRoomFor);
    },
    getRoute() {
      return this.$route.path;
    },
  },
};
</script>
<style scoped lang="scss">
.waitingRoomBadge {
  font-size: 10px !important;
  height: 16px !important;
  min-width: 16px !important;
  padding: 2px 6px !important;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  border-radius: 50% !important;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.theme--dark .listColor {
  color: white !important;
}
.theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
