<template>
  <!-- <div id="app">
    <div class="wrapper"> -->
  <emoji-picker @emoji="selectEmoji"  :search="search" class="emojiContainer">
    <div
    ref="test"
      class="emoji-invoker tooltipped"
      slot="emoji-invoker"
      slot-scope="{ events: { click: clickEvent } }"
      @click.stop="clickEvent"
    >
      <svg
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
        />
      </svg>
      <span class="tooltippedtooltip">{{ $t("components.callsContent.addEmoji") }}</span>
    </div>
    <div slot="emoji-picker" slot-scope="{ emojis, insert }">
      <div class="emoji-picker">
        <!-- :style="{ top: display.y + 'px', left: display.x + 'px' }" -->
        <div class="emoji-picker__search mb-3">
          <input
            id="searchEmoji"
            ref="searchEmoji"
            type="text"
            v-model="search"
            v-focus
            :placeholder="$t('generics.search')"
          />
        </div>
        <div>
          <div
            v-for="(emojiGroup, category) in emojis"
            :key="category"
            class="mb-2"
          >
            <template v-if="category !== 'Frequently used'">
              <h5 class="mb-1">{{ $t('components.emojiPicker.'+category) }}</h5>
              <div class="emojis">
                <span
                  v-for="(emoji, emojiName) in emojiGroup"
                  :key="emojiName"
                  @click="insert(emoji); closeEmoji()"
                  :title="$t(`emojis.${emojiName}`)"
                  >{{ emoji }}</span
                >
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </emoji-picker>
  <!-- </div>
  </div> -->
</template>

<script>
import store from "../../store";
import EmojiPicker from "vue-emoji-picker";

export default {
  components: {
    EmojiPicker,
  },
  props: ["selectEmoji"],
  data() {
    return {
      state: store.state,
      search: "",
      showEmoji: false
    };
  },
  methods: {
    closeEmoji(){
      this.$refs.test.click();
    }
  },
};
</script>

<style scoped lang="scss">
.tooltipped:hover .tooltippedtooltip {
    display: block;
}
.tooltippedButtonMenu:hover .tooltippedtooltipButton {
    display: block;
}
.tooltippedtooltip {
  font-size: 14px !important;
    border-radius: 4px;
    color: #fff;
    display: none;
    background: rgba(97, 97, 97, 0.9);
    margin-left: 77px;
    position: fixed;
    margin-top: -85px;
    z-index: 1000;
    width: 8%;
    pointer-events: none;
    padding: 5px;
    text-transform: none;
    font-family: Roboto,sans-serif;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: 0 !important;
    text-align: center;

}
.emojis{
  font-size: 27px;
}
.wrapper {
  position: relative;
  display: inline-block;
}

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 20rem;
  height: 12rem;
  outline: none;
}

.regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.emoji-invoker {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 100%;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #131519;
  overflow-x: hidden;
  bottom: 58px;
  left: 0px;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

.emojiContainer {
  width: 36px;
  height: 30px;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px black;
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #06a5df;
    border-radius: 10px;
  }
}
.emojiContainer:hover {
  background: #484a4f;
  border-radius: 4px;
}
</style>