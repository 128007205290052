<template>
     <v-list>
         <v-list-item  
         v-for="(group) in sortedGroups" 
         :key="group.id">
            <v-checkbox
                v-model="state.groupSelected"
                class="mencheckbox departments cbNoteHead"
               :items="sortedGroups"
               :label="group.title || group.newGroupName"
               :value="group.id"
               return-object
            ></v-checkbox>     
         </v-list-item>
     </v-list>
</template>

<script>
import store from '../../store';
export default {
  data() {
    return {
      state: store.state
    };
  },
  methods:{
  },
  computed:{
    sortedGroups(){
      if(!store.state.personToAddToGroup) return [];
      let aGroups = ((store.state.user || {}).userGroups || []);         
      return aGroups
        .filter((group) => {
          return !group.members.filter(member => (member.uuid === store.state.personToAddToGroup.uuid)).length;
        })
        .sort((a, b) => {
          return a.newGroupName < b.newGroupName ? -1 : (a.newGroupName > b.newGroupName ? 1 : 0);
        });
    }
  }
};
</script>

<style scoped lang="scss">

</style>

<style lang="scss">

</style>