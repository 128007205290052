<template>
  <div>
    <v-dialog
      :fullscreen="standalone"
      persistent
      max-width="980"
      v-model="state.showInviteInstaLink || showModal"
      class="tightDialog"
      @keydown.esc="closeMenu"
    >
      <div id="itsme" ref="modalContent">
        <!-- HEADER -->
        <v-row class="mx-0" justify="center">
           <v-card flat class="ml-5 mr-5 pt-2">
            <v-img 
              class="imgLogo"
              contain
              src="assets/icon.png"
              lazy-src="assets/icon.png"
              height="50"
              width="50"
            ></v-img>
          </v-card>
          <v-card-title class="font-weight-bold headline">{{$t('components.inviteVisitor.title')}}</v-card-title>
        </v-row>
        <v-divider></v-divider>

        <!-- CONTENT -->

        <vue-scroll class="vueScrollinviteOneCall">
          <div style="height: 310px">
            <v-row class="mx-0">
              <v-col cols="6" class="px-1 py-1">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-title @click.stop.prevent>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row class="mx-0">
                          <v-col class="col-12 px-1 py-1 pt-2">
                            <p class="mb-0 body-1">{{$t('components.inviteVisitor.titleInvitationMenu')}}</p>
                            <v-select
                              v-model="userSelected"
                              :items="getUserList"
                              class="mt-3"
                              :label="$t('generics.users')"
                              hide-details
                              outlined
                              dense
                              item-text="name"
                              item-value="value"
                              return-object
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row class="mx-0">
                          <!-- /////// Name ///////// -->
                          <!-- </v-col> -->
                           <v-col class="col-12 px-1 py-0 pt-2">
                            <p class="body-1">{{$t('components.inviteVisitor.invitationTo')}}</p>
                          </v-col>
                          <v-col class="col-6 px-1 py-1 pt-0">
                            <v-text-field
                              v-model="name"
                              required
                              :rules="userNameRules"
                              :label="$t('components.inviteVisitor.name')"
                            ></v-text-field>
                          </v-col>
                          <!-- /////// Email ///////// -->
                          <v-col class="col-6 px-1 py-1 pt-2">
                            <v-text-field
                              @click="errText = ''; successText = '' "
                              v-model="email"
                              :label="$t('components.inviteVisitor.invitationEmail')"
                              required
                              :rules="emailRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row style="width: 100%; margin: 0">
                          <!-- /////// Subject ///////// -->
                          <v-row style="width: 100%; margin: 0">
                            <v-col cols="12" class="py-0 px-2">
                              <v-textarea
                                v-model="subject"
                                name="subjectinput"
                                :no-resize="true"
                                class="py-0 my-0 bolder"
                                rows="2"
                                :placeholder="$t('components.inviteVisitor.subject')"
                                counter
                                maxlength="80"
                                auto-grow
                              />
                            </v-col>
                          </v-row>
                          <v-col class="col-6 px-1 py-1">
                            <span
                              style="font-size: 16px;"
                            >{{$t('components.inviteVisitor.linkValidUntil')}}</span>
                            <br />
                            <v-menu
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="linkDateFrom"
                                  v-on="on"
                                  readonly
                                >
                                  <font-awesome-icon
                                    :icon="['fal', 'calendar-alt']"
                                    :style="{ fontSize: '20px' } "
                                    slot="prepend"
                                  />
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="date"
                                @input="tixdate = false"
                                :pickTime="true"
                                :locale="this.$locale.current()"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                          <v-col class="col-6 px-1 py-1">
                            <span
                              style="font-size: 16px;"
                            >{{$t('components.inviteVisitor.videoCallTime')}}</span>
                            <br />
                            <v-combobox
                              v-model="appmtOptionselect"
                              :items="combinedAppointmentOptions"
                              @change="getText()"
                            ></v-combobox>
                          </v-col>
                        </v-row>

                        <!-- Do appintment or use dates -->
                        <v-row class="mx-0">
                          <v-col class="col-12 px-1 py-1">
                            <span style="font-size: 17px;">{{$t('components.inviteVisitor.fixtermin')}}</span>
                          </v-col>
                        </v-row>

                        <v-row style="width: 100%; margin: 0">
                          <v-col class="col-6 px-1 py-1">
                            <v-menu
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field v-model="linkDateTo" v-on="on" readonly>
                                  <font-awesome-icon
                                    :icon="['fal', 'calendar-alt']"
                                    :style="{ fontSize: '20px' } "
                                    slot="prepend"
                                  />
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="dateTo"
                                @input="tixdate = false"
                                :pickTime="true"
                                :locale="this.$locale.current()"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="6" sm="6" class="px-1 py-1">
                            <v-menu
                              ref="menutTo"
                              v-model="menutTo"
                              :close-on-content-click="false"
                              :nudge-left="150"
                              :return-value.sync="time"
                              transition="scale-transition"
                              offset-y
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="linkTimeTo"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <font-awesome-icon
                                    :icon="['far', 'clock']"
                                    :style="{ fontSize: '20px' } "
                                    slot="prepend"
                                  />
                                </v-text-field>
                              </template>
                              <v-time-picker
                                format="24hr"
                                v-if="menutTo"
                                v-model="linkTimeTo"
                                @click:minute="$refs.menutTo.save(linkTimeTo)"
                              ></v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row style="width: 100%; margin: 0">
                         
                          <!-- Duration Mins -->
                          <v-col class="col-12 px-1 py-1 pb-0">
                            <p
                              class="mb-0"
                              style="font-size: 15px;"
                            >{{$t('components.inviteVisitor.duration')}}</p>
                          </v-col>
                            
                          <v-col class="col-9 px-1 py-1">
                            <v-text-field
                              max-width="50"
                              v-model="durationMin"
                              hide-details
                              single-line
                              type="number"
                            />
                          </v-col>
                          <!-- Add to planner -->
                          <v-col class="col-3 px-1 py-1">
                            <!-- <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-checkbox
                                  v-on="on"
                                  v-model="addToPlanner"
                                  class="mt-0"
                                  :label="'+ ' + $t('components.inviteVisitor.planner')"
                                  :disabled="isAppointable"
                                ></v-checkbox>
                              </template>
                              <span>{{ $t('generics.comingSoon')}}</span>
                            </v-tooltip> -->
                          </v-col>
                        </v-row>
                        <v-row style="width: 100%; margin: 0">
                          <!-- /////// Paylink ///////// -->
                          <v-col class="col-12 px-1 py-1" style="text-align:center">
                              <span style="font-size:15px;">{{$t('components.inviteVisitor.payOneCall')}}</span>
                            </v-col>
                            <v-col class="col-6 px-1 py-1 pt-0">
                              <v-text-field
                                v-model="price"
                                :disabled="true"
                                :label="$t('components.inviteVisitor.price')"
                              ></v-text-field>
                            </v-col>
                            <v-col class="col-6 px-1 py-1 pt-0">
                              <v-text-field
                                v-model="fixedPrice"
                                :label="$t('components.inviteVisitor.fixedPrice')"
                                :disabled="true"
                              ></v-text-field>
                            </v-col>
                        </v-row>
                        <!-- /////// Error text ///////// -->
                        <v-row style="width: 100%; margin: 0" v-if="errText">
                          <div class="mx-auto">
                            <span style="color:red">{{errText}}</span>
                          </div>
                        </v-row>
                      </v-form>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-col>
              <!-- /////// Invite text ///////// -->
              <v-col cols="6" class="px-1 py-1 pt-2">
                <v-divider style="padding-bottom:15px;"></v-divider>
                <p class="mb-0 body-1">{{$t('components.inviteVisitor.invitationText')}}</p>
                <div style="padding-bottom:15px;">
                  <p style="font-weight:600; margin:0px;word-break: break-all;">{{computedSubject}}</p>
                </div>
                <div>
                  <v-textarea
                    spellcheck="false"
                    contenteditable="true"
                    v-model="inviteText"
                    :value="inviteText"
                    name="noteinput"
                    :no-resize="true"
                    class="py-0 my-0 pre inviteTextClass"
                    counter
                    rows="13"
                  />
                  <div class="mt-12" style="display:none;">Value: {{ inviteText }}</div>
                </div>
                <!-- /////// Invite Internal Notes //////// -->
                <v-col cols="12" class="col-8 px-1 py-1">
                  <v-textarea
                    v-model="internalnote"
                    name="noteinput"
                    :no-resize="true"
                    class="py-0 my-0"
                    rows="2"
                    placeholder="Interne Bemerkung"
                    counter
                    maxlength="80"
                  />
                </v-col>
                <v-col cols="12" class="col-8 px-1 py-1">
                  <v-switch
                    v-model="desactiveAutoSend"
                    :label="$t('components.inviteVisitor.desactivateAutoMail')"
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="col-8 px-1 py-1">
                  <v-text-field
                    id="invitateLink"
                    v-show="invitateLink && (desactiveAutoSend || standalone)"
                    readonly
                    :label="$t('components.inviteVisitor.invitateLink')"
                    :value="invitateLink"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'copy']"
                      :style="{ fontSize: '20px' } "
                      slot="append"
                      class="cursorPointer"
                      @click="copyLink()"
                    />
                  </v-text-field>
                  <span v-if="copiedLInk">{{$t('components.inviteVisitor.copiedLInk')}}</span>
                </v-col>
                <v-col cols="12" class="col-8 px-1 py-1">
                  <v-btn
                    @click="saveCustomMessage"
                    color="primary"
                    class="mr-2"
                  >{{ $t('components.inviteGuestPanel.saveText') }}</v-btn>
                  <v-btn
                    class="ml-2"
                    @click="inviteText = ''"
                    color="primary"
                  >{{ $t('components.inviteGuestPanel.emptyTextField') }}</v-btn>
                </v-col>
              </v-col>
            </v-row>
          </div>
        </vue-scroll>
        <!-- /////// Footer area ///////// -->
        <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
          <v-row class="px-2 btns w-100 mx-0 my-0">
            <span style="color: white;line-height: 2.3em;margin-right: 30px;">{{successText}}</span>
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :disabled="!valid"
                    v-on="on"
                    icon
                    color="white"
                    @click="precheckNewVisitor"
                    class="mr-3"
                  >
                    <font-awesome-icon :icon="['fal', 'check']" :style="{ fontSize: '20px' }" />
                  </v-btn>
                </template>
                <span>{{ $t('generics.accept') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    color="white"
                    @click.stop.prevent="closeMenu()"
                    class="ml-3"
                  >
                    <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
                  </v-btn>
                </template>
                <span>{{ $t('generics.close') }}</span>
              </v-tooltip>
            </template>
          </v-row>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template> 
<script>
import store from "../../store";
import { v4 as uuidv4 } from "uuid";
import { createVisitor } from "../../lib/inviteHelpers";
import { isEmailRegistered } from "../../lib/wsMsg";
import { newTimelineItemEvent } from '../../effector/timeline';
import { dispatchErrorAlert } from "../../effector/alerts";

function germanDateToIso(germanDate) {
  return germanDate.split(".").reverse().join("-")
}

function toClipboardObject(formData) {
  return {
    inviteLink: formData.instalink,
    recipientName: formData.name ? formData.name : undefined,
    recipientMail: formData.email ? formData.email : undefined,
    userName: formData.userInviter ? store.state.group[formData.userInviter].user.name : undefined,
    subject: formData.subject ? formData.subject : undefined,
    expirationDate: formData.linkDateFrom ? germanDateToIso(formData.linkDateFrom) : undefined,
    appointmentTimeOption: formData.appmtOptionselect ? formData.appmtOptionselect : undefined,
    dateTime: formData.linkDateTo ? formData.linkTimeTo ? `${germanDateToIso(formData.linkDateTo)}T${formData.linkTimeTo}` : germanDateToIso(formData.linkDateTo) : undefined,
    durationInMinutes: formData.duration ? parseInt(formData.duration) : undefined,
    invitationText: formData.inviteText ? formData.inviteText : undefined,
    internalNote: formData.internalnote ? formData.internalnote : undefined,
    deactivateMail: formData.desactiveAutoSend ? formData.desactiveAutoSend : undefined
  }
}

export default {
  props: {
    showModal: {
      default: false
    },
    standalone: {
      default: false
    },
    options: {
      default: null
    }
  },
  data() {
    return {
      createVisitorBusy: false,
      state: store.state,
      ownUUID: store.state.ownUUID,
      name: "",
      email: "",
      inviteText: "",
      isAppointment: false,
      appmtOptionselect: "",
      items: {},
      subject: "",
      instalink: "",
      created: "",
      addToPlanner: false,
      internalnote: "",
      linkreadonly: true,
      linkDateFrom: "",
      date: "",
      linkTimeFrom: null,
      menutfrom: false,
      linkDateTo: "",
      dateTo: "",
      linkTimeTo: null,
      menutTo: false,
      time: null,
      valid: false,
      durationMin: 0,
      errText: "",
      successText: "",
      sendingInvitation: false,
      userNameRules: [v => !!v || this.$t('generics.nameRequired')],
      emailRules: [
        v => !!v || "", //remove red mesagge ticket #132
        v => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) || "" //remove red mesagge ticket #132
      ],
      userSelected: { name: store.state.user.name, value: store.state.ownUUID },
      userList: [],
      desactiveAutoSend: false,
      invitateLink: undefined,
      copiedLInk: false,
      price: '',
      fixedPrice: ''
    };
  },

  watch: {
    inviteText(){
      if( this.inviteText === '' ){
        setTimeout(()=>{
          this.inviteText = this.textWithLink();
        }, 100)
      }
    },
    dateTo(val) {
      this.linkDateTo = this.formatDate(this.dateTo);
    },
    date(val) {
      this.linkDateFrom = this.formatDate(this.date);
    },
    "state.showInviteInstaLink": function(value) {
      // console.log('watch ')
      if (value === true) {
        // TODO: clear stuff
        this.appmtOptionselect = "";
        this.getText();
      }
    },
    name: function(value) {}
  },
  mounted() {
    this.getText();
    this.$refs.form.reset();
    this.setValuesFromOptions(this.options)
    this.userSelected = {
      name: store.state.user.name,
      value: store.state.ownUUID
    }
  },
  methods: {
    setValuesFromOptions(options) {
      if (options) {
        if (options.recipientName) this.name = options.recipientName
        if (options.recipientMail) this.email = options.recipientMail
        if (options.subject) this.subject = options.subject
        if (options.expirationDate) this.date = options.expirationDate.split("T")[0]
        if (options.appointmentTimeOption) this.appmtOptionselect = options.appointmentTimeOption
        if (options.dateTime) this.dateTo = options.dateTime.split("T")[0]
        if (options.dateTime) this.linkTimeTo = options.dateTime.split("T")[1]
        if (options.durationInMinutes) this.durationMin = options.durationInMinutes
        if (options.invitationText) this.inviteText = options.invitationText
        if (options.internalNote) this.internalnote = options.internalNote
        if (options.deactivateMail) this.desactiveAutoSend = options.deactivateMail
      }
    },
    buildAppointment(visitorId){
     // console.log('Building', visitorId);
      const dateTimeStart = new Date(this.dateTo + " " + this.linkTimeTo);
      const dateTimeEnd = new Date(this.dateTo + " " + this.linkTimeTo).getTime() + this.durationMin*60000;
     // console.log('Dates', this.dateTo, this.linkTimeTo, this.durationMin, dateTimeStart, dateTimeEnd);
      let selectedUsers = [];
      if(this.userSelected.value !== store.state.ownUUID){
        selectedUsers.push(this.userSelected.value);
      }
      selectedUsers.push(visitorId);

      const payload = {
        name: this.name,
        details: this.subject || '',
        users: selectedUsers,
        // img: this.imgAppointment,
        start: dateTimeStart,
        end: dateTimeEnd,
        color: 'orange',
        type: 'oneCall',
        oneCall: {
          visitorUUID: visitorId,
        },
      }
     // console.log('Payload OneCall ', payload);
      newTimelineItemEvent(payload);
    },
    saveCustomMessage() {
      this.state.persisted.customVisitorInviteText = this.inviteText;
    },
    copyLink() {
      let linktoToCopy = document.querySelector("#invitateLink");
      linktoToCopy.select();
      try {
        var successful = document.execCommand("copy");
        this.copiedLInk = true;
      } catch (err) {
        this.copiedLInk = false;
        console.warn("Oops, unable to copy");
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    getText() {
      if (this.state.persisted.customVisitorInviteText.length > 5)
        this.inviteText = this.state.persisted.customVisitorInviteText;
      else {
        this.inviteText = this.loadDefaultInviteText();
      }
      // console.log(this.inviteText, 'invite text')
    },
    precheckNewVisitor() {
      // console.log('precheck')
      if (this.email.length < 5) {
        this.valid = false;
        return;
      }
      // console.log('precheck 2')
      if (this.valid) {
        // console.log('precheck 3')
        this.handleNewVisitor();
      }
    },
    async handleNewVisitor() {
      // if (!this.state.nerd) return;
      // console.log(this.userSelected.value, "this.userSelected.value");
      if (this.createVisitorBusy) return;
      this.createVisitorBusy = true;
      try {
        const { key, found } = await isEmailRegistered(this.email);
        if (found && !(((this.state.group[key] || {}).user || {}).guest)) {
          this.errText = this.$t('components.inviteGuestPanel.errAlreadyUser');
          this.createVisitorBusy = false;
          // console.error('Unable to invite existing user!');
          return;
        }
        if (this.inviteText === "") {
          this.inviteText = this.loadDefaultInviteText();
        }
        const odata = {
          visitorId: uuidv4(), // key,
          name: this.name,
          email: this.email,
          inviteText: this.inviteText,
          subject: this.subject,
          created: Date.now(),
          linkDateFrom: this.linkDateFrom,
          linkTimeFrom: this.linkTimeFrom,
          linkDateTo: this.linkDateTo,
          linkTimeTo: this.linkTimeTo,
          internalnote: this.internalnote,
          instalink: this.instalink,
          duration: this.durationMin,
          inviters: {
            [this.userSelected.value]: true
          },
          userInviter: this.userSelected.value
        };
        odata.variables = {
          "%APPOINTMENT_FROM%": this.linkDateFrom
            ? this.$t("components.inviteVisitor.appointment") +
              " " +
              this.linkDateFrom +
              " " +
              this.$t("components.inviteVisitor.at") +
              " " +
              this.linkTimeFrom
            : "",
          "%APPOINTMENT_EXPIRES%": this.linkDateTo
            ? this.$t("components.inviteVisitor.expires") +
              " " +
              this.linkDateTo +
              " " +
              this.$t("components.inviteVisitor.at") +
              " " +
              this.linkTimeTo
            : ""
        };
        //console.log(odata, "DATAAAAAAAAAA");
        // const options = this.pinActive && this.pinCode
        //   ? { "2fa": { pin: { code: this.pinCode } } }
        //   : {};
        const options = {};
        const sendMail = !this.desactiveAutoSend;
        const inviteLink = await createVisitor(odata, {
          sendMail,
          ...options
        });
        odata.instalink = this.instalink = inviteLink;
        store.setUserVisitor(odata.visitorId, odata);
        if (this.isAppointable) {
          this.buildAppointment(odata.visitorId);
        }
        //update persisted options if any
        this.updateAppntOptions();
        if (sendMail) {
          this.successText = this.$t("components.inviteVisitor.successSent");
        } else {
          this.invitateLink = inviteLink;
        }

        if (this.standalone) {
          this.invitateLink = JSON.stringify(toClipboardObject(odata))
          this.$nextTick(() => {
            this.copyLink()
            window.close()
          });
        }

        this.$refs.form.reset();
        // reset User select
  
        this.$nextTick(() => {
          this.invitateLink = undefined;
          this.desactiveAutoSend = false;
        });

        this.userSelected = {
          name: store.state.user.name,
          value: store.state.ownUUID
        }
        this.errText= "";
        this.successText= "";

        //this.closeMenu();
      } catch (err) {
        if (err && err.message && err.message.indexOf('No such recipient here') != -1) {
          dispatchErrorAlert(this.$t("components.inviteVisitor.badEmail"))
        }
      }
      this.createVisitorBusy = false;
    },
    updateAppntOptions() {
      let allOptions = this.myCombinedAppointmentOptions();
      if (
        this.appmtOptionselect &&
        allOptions.indexOf(this.appmtOptionselect) === -1
      ) {
        store.state.persisted.usersCustomAppointmentEntries.push(
          this.appmtOptionselect
        );
        store.setusersCustomAppointmentEntries(
          store.state.persisted.usersCustomAppointmentEntries
        );
      }
    },
    clearForm() {
      this.visitorId = null;
      this.title = "";
      this.name = "";
      this.company = "";
      this.email = "";
      this.tel = "";
      this.addToPlanner = false;
      this.subject = "";
      this.created = "";
      this.linkDateFrom = "";
      this.linkTimeFrom = "";
      this.linkDateTo = "";
      this.linkTimeTo = "";
      this.internalnote = "";
      this.instalink = "";
      this.successText = "";
      this.durationMin = 0;
      this.userSelected = {
        name: this.state.user.name,
        value: this.state.ownUUID
      };
      this.desactiveAutoSend = false;
      this.invitateLink = undefined;
      this.copiedLInk = false;
    },
    textWithLink(){
      return(
        this.$t("components.inviteVisitor.line2") +
        " %INVITE_LINK%" +
        "\n"
      )
    },
    loadDefaultInviteText() {
      return (
        // this.$t("components.inviteVisitor.header") +
       "\n\n" +
        this.$t("components.inviteVisitor.line1") +
        // " %INVITE_NAME%" +
        "\n" +
        this.$t("components.inviteVisitor.line2") +
        " %INVITE_LINK%" +
        "\n\n" +
        this.$t("components.inviteVisitor.line3").join("\n") +
        "\n\n" +
        (this.appmtOptionselect
          ? this.$t("components.inviteVisitor.line4_0") +
            " " +
            this.appmtOptionselect +
            " \n"
          : ""
        ) +
        this.$t("components.inviteVisitor.line4_1") +
        " %APPOINTMENT_EXPIRES%" +
        "\n\n" +
        this.$t("components.inviteVisitor.line5_0") +
        " " +
        this.$t("components.inviteVisitor.line5_1") +
        " https://www.voffice.pro/hinweise/" +
        "\n" //+
        // this.$t("components.inviteVisitor.line6") +
        // "\n" //+
        // this.$t("components.inviteVisitor.line7") +
        // "\n" //+
        // this.$t("components.inviteVisitor.instaLinkInvite") +
        // "\n"
      );
    },
    myCombinedAppointmentOptions() {
      const baseOptions = [
        // this.$t("components.inviteVisitor.plsSelect"),
        this.$t("components.inviteVisitor.shortly"),
        this.$t("components.inviteVisitor.immediately"),
        this.$t("components.inviteVisitor.tillsixpm"),
        this.$t("components.inviteVisitor.asap"),
        this.$t("components.inviteVisitor.morning"),
        this.$t("components.inviteVisitor.afterTomorrow")
      ];
      const additionalOptions = (store.state.persisted.usersCustomAppointmentEntries || []).filter(v => !!v);
      const options = additionalOptions.length ? baseOptions.concat(additionalOptions) : baseOptions;
      return options;
    },
    selectId(e) {
      this.title = e.id;
    },
    closeMenu() {
      store.state.showInviteInstaLink = false;
      this.clearForm();
      if (this.standalone) {
        window.close()
      }
    }
  },
  computed: {
    isAppointable(){
      // must have start date , start time and minutes
       let canBePlotted = false;
        if( this.linkDateTo === null || this.linkTimeTo === null  ){
           canBePlotted = false;
        } else {
          if(this.durationMin > 0){
            canBePlotted = true;
          }
        }
        return canBePlotted;
    },
    getUserList() {
      const persons = Object.keys(store.state.group)
        .filter((uuid) => {
          const person = store.state.group[uuid];
          return person && person.user && person.user.name && person.permissions !== 10
            && !person.user.guest && !person.user.visitor
        })
        .map((uuid) => {
          const person = store.state.group[uuid];
          return { name: person.user.name, value: uuid };
        });
      return persons.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
      });
    },
    computedDateFormattedFrom() {
      // this.linkDateFrom = this.formatDate(this.date);
      return this.linkDateFrom;
    },
    computedDateFormattedTo() {
      // this.linkDateTo = this.formatDate(this.dateTo);
      return this.linkDateTo;
    },
    combinedAppointmentOptions() {
      // if (this.appmtOptionselect === "") {
      //   this.appmtOptionselect = this.$t("components.inviteVisitor.plsSelect");
      // }
      const options = this.myCombinedAppointmentOptions();
      return options;
    },
    computedSubject() {
      return this.subject;
    },
    soonPaylink() {
      return "Paylink " + this.$t("generics.comingSoon");
    },
    soonAddPlaner() {
      return "Add to vOffice planner " + this.$t("generics.comingSoon");
    },
    titleItems() {
      return [
        { id: 0, text: this.$t("components.inviteVisitor.without") },
        { id: 1, text: this.$t("components.inviteVisitor.mr") },
        { id: 2, text: this.$t("components.inviteVisitor.ms") },
        { id: 3, text: this.$t("components.inviteVisitor.company") }
      ];
    }
  }
};
</script>

<style scoped lang="scss">
.vueScrollinviteOneCall {
  height: calc(100% - 250px) !important;
}
.pre {
  white-space: pre;
}
.bolder {
  font-weight: 400;
}
.tightDialog {
  margin-top: 0px;
  padding-top: 0px;
}
.v-text-field {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.v-time-picker-title__time .v-picker__title__btn,
.v-time-picker-title__time span {
  height: 40px !important;
  font-size: 30px !important;
}
.autoscroll {
  overflow-y: auto;
}
.inviteTextClass {
  font-size: 13px;
  font-weight: normal;
}
.cursorPointer {
  cursor: pointer;
}
</style>

<style lang="scss">
.__view {
  width: unset !important;
}
.theme--light.v-input textarea {
  &::placeholder {
    color: black !important;
  }
}

.theme--dark.v-input textarea {
  &::placeholder {
    color: white !important;
  }
}
</style>
