<template>
  <v-tooltip top v-if="showIcon">
    <template v-slot:activator="{ on }">
      <v-btn
        class="customDASD white--text"
        @click="toggleDasdActivation()"
        v-on="on"
      >
        <!-- <span class="DASDText">DASD</span> -->
        <v-img
          class="mr-1"
          :src="
            dasdIconIsActive ? `img/icons/dasd-green.svg` : `img/icons/dasd-gr.svg`
          "
          height="16"
          width="40"
          contain
        />
        <v-img
          :src="
            dasdIconIsActive ? `img/icons/symbol-green.svg` : `img/icons/symbol-gray.svg`
          "
          height="16"
          width="16"
          contain
        />
      </v-btn>
    </template>
    <!-- dasdIconIsActive ? $t("generics.DASDActive") : $t("generics.DASDInactive") -->
    <span
      >{{ $t("components.externalServices.dasdTT1") }} www.dasd.de
      {{ $t("components.externalServices.dasdTT2") }}</span
    >
  </v-tooltip>
</template>

<script>
import store, { syncedUserState } from "../../store";
import {
  hasDasdUserId,
  hasOMKUserId,
  isExternalServicesStatusColorActive,
  isExternalServicesIconActive,
  getActivateExternalServices,
} from "../../utils/externalServices";
export default {
  components: {},
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
    };
  },
  mounted() {
    syncedUserState()
      .catch(() => {})
      .then(() => {
        const forcedExternalServicesStatus = ["green", "yellow"];
        if (
          JSON.stringify(this.state.user.externalServicesStatus) !==
          JSON.stringify(forcedExternalServicesStatus)
        ) {
          store.setExternalServicesStatus(forcedExternalServicesStatus);
        }
      });
  },
  watch: {
    hasDASDinWaitingRoom: {
      handler: function (hasDASD) {
        const activesExternalServices = this.getActivateExternalServices;
        if (
          this.dasdIconIsActive &&
          activesExternalServices.indexOf("dasd") !== -1
        ) {
          if (hasDASD && this.state.user.dasdUserStatus !== "busy") {
            this.setDasdUserStatus("busy");
          } else if (!hasDASD && this.state.user.dasdUserStatus === "busy") {
            this.setDasdUserStatus("active");
          }
        }
      },
    },
    hasDasdUserId: {
      handler: function (hasDASD) {
        const activesExternalServices = this.getActivateExternalServices;
        if (!hasDASD && activesExternalServices.indexOf("dasd") !== -1) {
          const index = activesExternalServices.indexOf("dasd");
          activesExternalServices.splice(index, 1);
          this.setDasdUserStatus("inactive");
          this.setExternalServicesActives(activesExternalServices);
        }
      },
    },
    hasOMKUserId: {
      handler: function (hasOmk) {
        let activesExternalServices = this.getActivateExternalServices;
        if (!hasOmk && activesExternalServices.indexOf("omk") !== -1) {
          const index = activesExternalServices.indexOf("omk");
          activesExternalServices.splice(index, 1);
          this.setExternalServicesActives(activesExternalServices);
        } else if (hasOmk && activesExternalServices.indexOf("omk") === -1) {
          activesExternalServices.push("omk");
        }
      },
    },
    getActivateExternalServices: {
      immediate: true,
      handler: function (actives) {
        if (this.dasdIconIsActive && actives.indexOf("dasd") !== -1) {
          if (this.state.user.dasdUserStatus !== "busy")
            this.setDasdUserStatus("active");
        } else {
          this.setDasdUserStatus("inactive");
        }
        if (this.omkIconIsActive && actives.indexOf("omk") !== -1) {
          this.setOmkUserStatus("active");
        } else {
          this.setOmkUserStatus("inactive");
        }
      },
    },
  },
  methods: {
    setExternalServicesActives(value) {
      return store.setExternalServicesActives(value);
    },
    setDasdUserStatus(dasdStatus) {
      return store.setDasdUserStatus(dasdStatus);
    },
    setOmkUserStatus(omkStatus) {
      return store.setOmkUserStatus(omkStatus);
    },
    toggleDasdActivation() {
      if (isExternalServicesStatusColorActive(this.state.user)) {
        const activesExternalServices = this.getActivateExternalServices;
        if (activesExternalServices.indexOf("dasd") !== -1) {
          const index = activesExternalServices.indexOf("dasd");
          activesExternalServices.splice(index, 1);
        } else {
          activesExternalServices.push("dasd");
        }
        return this.setExternalServicesActives(activesExternalServices);
      }
    },
    externalServicesIconActive() {
      return isExternalServicesIconActive(this.state.user);
    },
  },
  computed: {
    getActivateExternalServices() {
      return getActivateExternalServices(this.ownUUID);
    },
    showIcon() {
      return this.hasDasdUserId;
    },
    dasdIconIsActive() {
      const activesExternalServices = this.getActivateExternalServices;
      return (
        activesExternalServices.indexOf("dasd") !== -1 &&
        this.externalServicesIconActive()
      );
    },
    omkIconIsActive() {
      const activesExternalServices = this.getActivateExternalServices;
      return (
        activesExternalServices.indexOf("omk") !== -1 &&
        this.externalServicesIconActive()
      );
    },
    hasDasdEnabled() {
      return (
        hasDasdUserId(this.ownUUID) &&
        this.state.user.dasdUserStatus !== "inactive"
      );
    },
    hasDasdUserId() {
      return hasDasdUserId(this.ownUUID);
    },
    hasDASDinWaitingRoom() {
      const list = store.getWaitingRoomList();
      for (const element of list) {
        if (
          element.user &&
          element.user.visitorData &&
          element.user.visitorData.isDASD
        )
          return true;
      }
      return false;
    },
    hasOMKUserId() {
      return hasOMKUserId(this.ownUUID);
    },
  },
};
</script>

<style scoped lang="scss">
.DASDText {
  color: black;
  font-size: 16px;
  /* position: absolute; */
  font-weight: bold;
  letter-spacing: 0;
  font-family: "ROBOTO";
  margin-right: 3px;
}
.customDASD {
  position: absolute !important;
  right: -3px;
  bottom: 8px;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
</style>
