var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',{staticClass:"px-0 py-0"},[_c('v-row',[_c('v-col',{staticClass:"mx-2 mr-6"},[_c('v-card',{staticClass:"elevation-0"},[[_c('v-data-table',{staticClass:"guestTableList elevation-0",attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"fixed-header":"","height":"calc(100vh - 265px)"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"avatarList",attrs:{"size":"36"}},[_c('img',{attrs:{"src":item.avatar || 'img/default_profile_picture.png',"lazy-src":"img/default_profile_picture.png"}})])]}},{key:"item.sendMessage",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setShowModalSenMsg(item.visitorData.visitorId)}}},[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'paper-plane']}})],1):_vm._e()]}},{key:"item.editGuest",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item)?_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'edit']}})],1):_vm._e()]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("generics.inProgress")))])])]}},{key:"item.resentEmail",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.SendLinkToVisitor(
                    item.visitorData.visitorId,
                    item.visitorData
                  )}}},[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'redo']}})],1):_vm._e()]}},{key:"item.copyLink",fn:function(ref){
                  var item = ref.item;
return [(item)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyLink($event, item.visitorData)}}},[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'copy']}})],1):_vm._e()]}},{key:"item.deleteGuest",fn:function(ref){
                  var item = ref.item;
return [(item)?_c('v-btn',{attrs:{"icon":""}},[_c('DeleteBasket',{attrs:{"indexData":item.visitorData.visitorId,"delFunction":_vm.deleteVisitor,"extraClasses":"","fontSize":"20","prevent":"false"}})],1):_vm._e()]}}])})]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }