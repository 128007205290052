<template>
  <v-dialog
    v-model="externalServicesModal"
    persistent
    max-width="800"
    @keydown.esc="closeModal"
  >
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('components.externalServices.titleModal')"
          :closeModalFunction="closeModal"
        />
        <v-card-text>
          <v-list-item-title>{{
            $t("components.externalServices.releaseFor")
          }}</v-list-item-title>
          <div class="d-flex">
            <v-checkbox
              v-model="selectedServices"
              :label="$t('components.externalServices.dasd')"
              value="dasd"
              :disabled="
                !hasDasdUserId() ||
                (selectedServices.length == 1 &&
                  selectedServices.indexOf('dasd') !== -1)
              "
              @change="changeActivesServices()"
            ></v-checkbox>
            <v-checkbox
              class="ml-6"
              v-model="selectedServices"
              :label="$t('components.externalServices.omk')"
              value="omk"
              :disabled="
                !hasOMKUserId() ||
                (selectedServices.length == 1 &&
                  selectedServices.indexOf('omk') !== -1)
              "
              @change="changeActivesServices()"
            ></v-checkbox>
            <v-checkbox
              class="ml-6"
              v-model="selectedServices"
              :label="$t('components.externalServices.beacon')"
              value="beacon"
              :disabled="
                !hasActiveBeaconUserId() ||
                (selectedServices.length == 1 &&
                  selectedServices.indexOf('beacon') !== -1)
              "
              @change="changeActivesServices()"
            ></v-checkbox>
          </div>
          <v-list-item-title>{{
            $t("components.externalServices.releaseFor")
          }}</v-list-item-title>
          <div class="d-flex">
            <v-checkbox
              v-model="selectedStatus"
              :label="$t('components.externalServices.green')"
              value="green"
              @change="changeStatus()"
              :disabled="
                selectedStatus.length == 1 &&
                selectedStatus.indexOf('green') !== -1
              "
            ></v-checkbox>
            <v-checkbox
              class="ml-6"
              v-model="selectedStatus"
              :label="$t('components.externalServices.yellow')"
              value="yellow"
              @change="changeStatus()"
              :disabled="
                selectedStatus.length == 1 &&
                selectedStatus.indexOf('yellow') !== -1
              "
            ></v-checkbox>
            <v-checkbox
              class="ml-6"
              v-model="selectedStatus"
              :label="$t('components.externalServices.red')"
              value="red"
              @change="changeStatus()"
              :disabled="
                selectedStatus.length == 1 &&
                selectedStatus.indexOf('red') !== -1
              "
            ></v-checkbox>
          </div>
        </v-card-text>
        <FooterModal :closeModalFunction="closeModal" />
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import {
  resetExternalServicesModalEvent,
  externalServicesModalStore,
} from "../../effector/modals";
import {
  hasDasdUserId,
  hasOMKUserId,
  hasActiveBeaconUserId,
} from "../../utils/externalServices";

import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  props: [],
  components: { HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
    selectedStatus: store.state.user.externalServicesStatus,
    selectedServices: store.state.user.externalServicesActives,
  }),
  mounted() {
    if (!this.selectedServices || this.selectedServices.length == 0) {
      this.prepareDataModal();
    }
  },
  destroyed() {},
  effector: {
    externalServicesModal: externalServicesModalStore,
  },
  watch: {},
  methods: {
    changeStatus() {
      return store.setExternalServicesStatus(this.selectedStatus);
    },
    changeActivesServices() {
      this.setExternalServicesActives(this.selectedServices);
    },
    setExternalServicesActives(value) {
      return store.setExternalServicesActives(value);
    },
    prepareDataModal() {
      if (this.hasDasdUserId() && this.selectedServices.indexOf("dasd") == -1) {
        this.selectedServices.push("dasd");
      }
      if (
        this.hasActiveBeaconUserId() &&
        this.selectedServices.indexOf("beacon") == -1
      ) {
        this.selectedServices.push("beacon");
      }
      if (this.hasOMKUserId() && this.selectedServices.indexOf("omk") == -1) {
        this.selectedServices.push("omk");
      }
      this.setExternalServicesActives(this.selectedServices);
    },
    hasDasdUserId() {
      return hasDasdUserId(this.ownUUID);
    },
    hasActiveBeaconUserId() {
      return hasActiveBeaconUserId(this.ownUUID);
    },
    hasOMKUserId() {
      return hasOMKUserId(this.ownUUID);
    },
    closeModal() {
      resetExternalServicesModalEvent();
    },
  },
};
</script>

<style scoped lang="scss">
</style>