<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="650"
    @keydown.esc="closeModal"
    :attach="getAttach"
    :hide-overlay="getAttach ? true : false"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('generics.manageConference')"
        :closeModalFunction="closeModal"
      />
      <v-col cols="12">
        <v-list class="manageConfList">
          <v-list-item
            dense
            v-for="user in conferenceAttendees"
            :key="user.uuid"
            v-if="!isOnlyOneCallUser(user.uuid) && state.group[user.uuid]"
          >
            <v-list-item-content>
              <v-list-item-title class="d-flex">
                {{ user.name }}
                <!-- && !isConfOwner(user.uuid) -->
                <div
                  class="d-flex dbtnsAttendees ml-auto"
                  v-if="user.uuid !== state.ownUUID"
                >
                  <v-tooltip
                    bottom
                    :disabled="
                      checkUserInCall(user.uuid) || !isAvailable(user.uuid)
                    "
                    @click.stop.prevent="addUserToCall(user.uuid)"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        class="mr-2"
                        :disabled="
                          checkUserInCall(user.uuid) || !isAvailable(user.uuid)
                        "
                        @click.stop.prevent="addUserToCall(user.uuid)"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'door-open']"
                          :style="{ fontSize: '20px' }"
                          class="inIcon"
                          :class="{
                            greenButton:
                              !checkUserInCall(user.uuid) &&
                              isAvailable(user.uuid),
                          }"
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      hasPrivilege(user.uuid)
                        ? $t("generics.addUser")
                        : $t("generics.addGuest")
                    }}</span>
                  </v-tooltip>

                  <v-tooltip bottom :disabled="!checkUserInCall(user.uuid)">
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        :disabled="!checkUserInCall(user.uuid)"
                        @click.stop.prevent="hangUpUser(user.uuid)"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'door-closed']"
                          :style="{ fontSize: '20px' }"
                          class="outIcon"
                          v-on="on"
                          :class="{
                            greenButton: checkUserInCall(user.uuid),
                          }"
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      hasPrivilege(user.uuid)
                        ? $t("generics.removeUser")
                        : $t("generics.deleteGuest")
                    }}</span>
                  </v-tooltip>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <FooterModal :closeModalFunction="closeModal">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              :disabled="availableUserToCall.length == 0"
              @click="callAll()"
              >{{ $t("components.conferences.callAll") }}</v-btn
            >
          </template>
          <span>{{ $t("components.conferences.callAllTT") }}</span>
        </v-tooltip>
      </FooterModal>
    </v-card>
    <v-spacer></v-spacer>
  </v-dialog>
</template>
 
<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { isOnlyOneCallUser, hasPrivilege } from "../../utils/privileges";
import {
  myConferenceDetails,
  updateGroupConferenceEvent,
} from "../../effector/groupConferences";
import { bridgeInfoStore } from "../../effector/users/bridgeInfo";
import {myCompanyDocumentsDetails, updateCompanyDocumentEvent,} from "../../effector/companyDocuments"
import { callPerson, wsCallStartBridgeCall } from "../../utils/calls";
import { wsCall } from "../../lib/wsConnect";
import { getCompanyDocumentsForCall } from "../../lib/wsMsg";
import {
  myConferenceHallDetails,
} from "../../effector/conferenceHalls";

export default {
  props: [
    "isConferenceCall",
    "amIModerator",
    "showModal",
    "closeModal",
    "attachTo",
    "callUUID",
    "checkUserInCall",
    "kickUser",
    "getIsConferenceHall"
  ],
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
    };
  },
  watch: {},
  effector: {
    myConferenceDetails: myConferenceDetails,
    bridgeInfoStore: bridgeInfoStore,
    myDocuments: myCompanyDocumentsDetails,
    myConferenceHallDetails: myConferenceHallDetails,
  },
  mounted() {},
  destroyed() {},
  methods: {
    callAll() {
      this.availableUserToCall.forEach((user) => {
        this.addUserToCall(user.uuid);
      });
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    hangUpUser(uuid) {
      if (this.kickUser) {
        this.kickUser(uuid);
      }
    },
    isOnlyOneCallUser(uuid) {
      return isOnlyOneCallUser(uuid);
    },
    isAvailable(uuid) {
      return (store.state.group[uuid] || {}).connected;
    },
    isConfOwner(uuid) {
      if (this.isConferenceCall) {
        let confId = this.isConferenceCall;
        let dataConference = null;
        dataConference = this.myConferenceDetails.find(
          (e) => e.confId === confId
        );
        if (dataConference && dataConference.confOwner === uuid) {
          return true;
        } else {
          return false;
        }
      }
    },
    addUserToCall(uuid) {
      if (this.isConferenceCall) {
        if(this.getIsConferenceHall){
          this.addPersonToConferenceHall(uuid)
        }else{
          this.addUserToConference(uuid);
          callPerson(uuid, this.$router);
        }
      }
    },
    addPersonToConferenceHall(uuid){
       const remoteBridgeStreamsKey = Object.keys(
        store.state.remoteBridgeStreams
      );
      if (remoteBridgeStreamsKey.length > 0) {
        let callInfo =
          store.state.remoteBridgeStreams[remoteBridgeStreamsKey[0]];
        const callUUID = callInfo.callUUID;
        const bridgeInfo = this.bridgeInfoStore;
        const numberOfPeople = ((bridgeInfo.calls[callUUID] || {}).users || [])
          .length;
        // let userIsInConferenceAttendees = false;
        // for (let i = 0; i < callInfo.conferenceAttendees.length; i++) {
        //   const member = callInfo.conferenceAttendees[i];
        //   console.log(member, "member")
        //   if (member.uuid === uuid) {
        //     userIsInConferenceAttendees = true;
        //     // member.isPodium = this.isPodium;
        //   }
        // }
        // if (!userIsInConferenceAttendees) {
        //   callInfo.conferenceAttendees.push({
        //     addUserToCall: true,
        //     isPodium: false,
        //     name: store.getNameForUuid(uuid),
        //     showUserName: true,
        //     uuid: uuid,
        //   });
        // }
        const infoStartBridgeCall = {
          callUUID: callInfo.callUUID,
          isNormalMode: callInfo.isNormalMode,
          initiator: callInfo.initiator || "",
          callStartTs: callInfo.callStartTs,
          callDurationMs: callInfo.callDurationMs,
          infiniteCall: callInfo.infiniteCall,
          conferenceDocuments: callInfo.conferenceDocuments || [],
          userConferencePosition: callInfo.userConferencePosition || {},
          excludedFromCalling: callInfo.usersToExclude || [],
          conferenceAttendees: callInfo.conferenceAttendees || [],
          confModerators: callInfo.confModerators || [],
          conferenceSettings: callInfo.conferenceSettings || {},
          usersToHideName: callInfo.usersToHideName || [],
          isConference: callInfo.isConference,
          confId: callInfo.confId || "",
          isConferenceCall: callInfo.isConferenceCall || undefined,
          conferenceHall: callInfo.conferenceHall || false,
        };
        if (
          wsCallStartBridgeCall(
            uuid,
            store.state.ownUUID,
            infoStartBridgeCall,
            numberOfPeople > 1
          )
        ) {
          store.addBridgeCallingUser(uuid, callInfo.callUUID);
          store.setCurrentContentVisile("", false, this.$router);
          this.doUpdateDocumentStore(callInfo.confId, uuid)

        }
      }
    },
    doUpdateDocumentStore(confId, userUUID) {
      const foundStore = this.myDocuments.find((e) => e.confId === confId);
       const extant = this.myConferenceHallDetails.find(
        (e) => e.confId === confId
      );
      if (foundStore && foundStore.confId && extant) {
        let usersConference = extant.confUUIDS
        if(usersConference.indexOf(userUUID)==-1){
          usersConference.push(userUUID)
        }
        foundStore.docMembersUUIDS = usersConference;
        foundStore.moderators = extant.confModerators;
        updateCompanyDocumentEvent(foundStore);
      }
    },
    async addUserToConference(uuid) {
      let tmpData = this.getDataConference; // full conference
      if (!tmpData || !tmpData.confId) {
        console.warn("No conference to add user to");
        return;
      }
      if (tmpData.confUUIDS.indexOf(uuid) == -1) {
        const newMember = {
          isGuest: false,
          isModerator: false,
          name: this.state.group[uuid].user.name,
          uuid: uuid,
          isGroup: false,
          isTeam: false,
        };
        tmpData.members.push(newMember);
        tmpData.confRealUsers.push(uuid);
        tmpData.confUUIDS.push(uuid);
        await updateGroupConferenceEvent(tmpData);
        const newAttendees = this.getAttendeesObject(tmpData.confUUIDS);
        this.getConfOnlineUsers(tmpData, newAttendees);
      }
    },
    getAttendeesObject(uuids) {
      let myMembers = [];
      uuids.forEach((uuid) => {
        if (uuid) {
          const aMember = {
            uuid,
            name: store.getNameForUuid(uuid),
            addUserToCall: true,
            showUserName: true,
          };
          myMembers.push(aMember);
        }
      });
      return myMembers;
    },
    getConfOnlineUsers(entry, newAttendees) {
      let usernames = [];
      if (entry && entry.confUUIDS) {
        entry.confUUIDS.forEach((uuid) => {
          const username =
            ((store.state.group[uuid] || {}).user || {}).name || "";
          if (username && username !== "") {
            let data = { username: username, uuid: uuid };
            usernames.push(data);
          }
        });
        usernames.forEach((name) => {
          let isOnline = false;
          if (this.bridgeInfoStore && this.bridgeInfoStore.calls) {
            Object.keys(this.bridgeInfoStore.calls).forEach((call) => {
              if (this.bridgeInfoStore.calls[call].confId === entry.confId) {
                isOnline = this.bridgeInfoStore.users[name.uuid] || false;
              }
            });
          }
          if (isOnline) {
            wsCall("sendToUUID", name.uuid, {
              type: "bridge-signal",
              action: "updateConferenceAttendees",
              sender: this.state.ownUUID,
              info: {
                callUUID: this.getCallUUID,
                confAttendees: newAttendees,
              },
            });
          }
        });
      }
    },
  },
  computed: {
    getDataConference() {
      if (this.isConferenceCall) {
        let confId = this.isConferenceCall;
        let dataConference = null;
        dataConference = this.myConferenceDetails.find(
          (e) => e.confId === confId
        );
        if (!dataConference) {
          dataConference = getCompanyDocumentsForCall(confId, "conference");
        }
        this.dataConference = dataConference;
        return dataConference;
      }
    },
    availableUserToCall() {
      const availableUser = this.conferenceAttendees.filter(
        (user) =>
          user.uuid !== this.state.ownUUID &&
          !this.checkUserInCall(user.uuid) &&
          this.isAvailable(user.uuid)
      );
      return availableUser;
    },
    conferenceAttendees() {
      return (
        this.state.remoteBridgeStreams[this.callUUID].conferenceAttendees || []
      );
    },
    getAttach() {
      if (this.attachTo) {
        const element = document.getElementById(this.attachTo);
        if (element) {
          return element;
        }
      }
      return false;
    },
  },
};
</script>

<style  lang="scss">
</style>