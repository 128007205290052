var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',{staticClass:"px-0 py-0"},[_c('v-row',[_c('v-col',{staticClass:"mx-2"},[_c('v-card',{staticClass:"cardStyle",attrs:{"outlined":""}},[_c('StatsNumbers'),_vm._v(" "),_c('div',{staticClass:"d-flex justify-space-between pa-4"},[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t("components.statusContainer.userListStatus"))+":\n            "),_c('span',{style:({ color: _vm.getStatusColor + '!important' })},[_vm._v(_vm._s(_vm.statusType))])]),_vm._v(" "),_c('span',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"pt-0",staticStyle:{"width":"310px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('span',{staticClass:"ml-4 pointer",on:{"click":function($event){return _vm.closeStatus()}}},[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'times']}})],1)],1)]),_vm._v(" "),[_c('v-row',{staticClass:"py-2 contentWrapper mx-0"},[(_vm.filteredInterUsers.length <= 0)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v("\n              "+_vm._s(_vm.$t("generics.dataNotAvailable"))+"\n            ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.filteredInterUsers),function(user){return _c('v-col',{key:user.uuid,staticClass:"userCard d-flex",attrs:{"cols":"3"}},[_c('v-avatar',{staticClass:"profile",class:{
                  pointer:
                    !_vm.disableCallBtn(user.uuid) && user.uuid !== _vm.state.ownUUID,
                },attrs:{"color":"grey","size":"35","tile":""},on:{"click":function($event){!_vm.disableCallBtn(user.uuid) && user.uuid !== _vm.state.ownUUID
                    ? _vm.callUser(user.uuid)
                    : ''}}},[(
                    user.avatar &&
                    user.avatar !== 'img/default_profile_picture.png'
                  )?_c('v-img',{attrs:{"src":user.avatar || 'img/default_profile_picture.png',"lazy-src":"img/default_profile_picture.png"}}):_vm._e(),_vm._v(" "),(
                    !user.avatar ||
                    user.avatar === 'img/default_profile_picture.png'
                  )?_c('DefaultAvatar',{attrs:{"userData":user,"lowerText":true}}):_vm._e()],1),_vm._v(" "),_c('span',{staticClass:"pl-2 pt-2 text-truncate"},[_vm._v(_vm._s(user.name))])],1)})],2)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }