<template>
  <div style="padding: 2px">
    <template v-if="hideUserLocation">{{ null }}</template>
    <template v-else>
      <v-btn
        :width="isRegularGrid ? 25 : 20"
        :height="isRegularGrid ? 25 : 20"
        icon
        :color="isRegularGrid ? '#ffffff' : '#0000008a'"
        @click.stop.prevent
        class="locationIcon"
        :class="{ noWhiteBg: isRegularGrid }"
        :style="
          defaultAvatar
            ? 'color:#808080;'
            : isRegularGrid
            ? 'color:#ffffff; font-size: 15px;'
            : null
        "
      >
        <font-awesome-icon
          v-if="getPersonLocation(person) === 'homeOffice'"
          :icon="['fal', 'house-user']"
        />
        <font-awesome-icon
          v-else-if="getPersonLocation(person) === 'outOfHouse'"
          :icon="['fal', 'car-building']"
        />
        <font-awesome-icon
          v-else-if="getPersonLocation(person) === 'realoOffice'"
          :icon="['fal', 'building']"
        />
        <span v-else-if="getPersonLocation(person) === 'notSpecified'">--</span>
        <font-awesome-icon v-else :icon="['fal', 'location-arrow']" />
      </v-btn>
    </template>
  </div>
</template>
<script>
import store from "../../store";
import { getPictureSize } from "../../utils/basicFunctions";
export default {
  props: ["person", "defaultAvatar", "isRegularGrid"],
  data() {
    return {
      state: store.state,
    };
  },
  methods: {
    getPersonLocation(person) {
      if (person.user) {
        return person.user.userLocation;
      }
      return false;
    },
  },
  computed: {
    getPictureSize() {
      return getPictureSize();
    },
    hideUserLocation() {
      if (
        !this.state.namespaceSettings.enableLocation ||
        (this.person.user && this.person.user.activity === "Holidays")
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped lang="scss">
.theme--light .noWhiteBg {
  background: transparent!important;
  // background: linear-gradient(#00000040, #00000099) !important;
}
.theme--dark .noWhiteBg {
  background: transparent!important;
  // background: linear-gradient(#00000099, #000000cc) !important;
}
.noWhiteBg {
  color: #38a2b8 !important;
  svg {
    font-size: 15px !important;
  }
}
.locationIcon {
  // pointer-events: none;
  background-color: #ffffff;
}
</style>