<template>
  <v-app id="two_sandbox">
    <v-app-bar :clipped-left="primaryDrawer.clipped" app>
      <v-app-bar-nav-icon v-if="primaryDrawer.type !== 'permanent'" />
      <v-img
        class="mx-2"
        src="assets/icon.png"
        max-height="40"
        max-width="40"
        contain
      ></v-img>
      <v-toolbar-title class="d-none d-md-flex">vOffice</v-toolbar-title>
    </v-app-bar>
    <v-dialog v-model="showModal" persistent max-width="570">
      <v-card>
        <v-row flex class="w-100 mx-0 my-0 justify-space-between">
          <v-col class="px-0 py-0" align="left">
            <v-card-title class="headline">
              <v-img
                src="assets/icon.png"
                max-height="30"
                max-width="30"
                contain
              ></v-img>
              <span class="font-weight-medium pl-4 spanModalTitle">{{ $t("components.2fa.twoFactorAuthenticationTitle") }}</span>
            </v-card-title>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-card-text class="pt-8 pb-2">
          <div v-if="enabled === 'sms' || smsRecovery" class="d-flex mb-4 divInput">
            <span v-if="requestSmsDone" class="px-2 align-center d-flex">{{ $t('components.2fa.sentSMSMsg') }}</span>
          </div>
          <div class="d-flex justify-end divInput" v-if="showCodeInput">
            <v-text-field
              @keyup.enter="tfaCode && requestAccess()"
              :autocomplete="!rememberPin ? 'new-password' : null"
              :label="tfaCodeLabel"
              placeholder=" "
              autofocus
              v-model="tfaCode"
              outlined
              dense
              @keydown.space.prevent
              required
              hide-details
              :append-icon="`${showPassword ? 'mdi-eye-off' : 'mdi-eye'}`"
              :type="`${showPassword ? 'text' : 'password'}`"
              @click:append="showPass()"
            ></v-text-field>
          </div>
          <div v-if="enabled !== 'sms' && smsRecovery && requestSmsDone" class="d-flex mb-4 divInput">
            <span class="px-2 align-center d-flex">{{ $t('components.2fa.pinValidUntil') }}</span>
          </div>
          <div class="pt-0 pb-6 rememberPinStyle" v-if="enabled !== 'sms' && !requestSmsDone">
            <v-checkbox
              v-if="enabled === 'pin'"
              v-model="rememberPin"
              :label="$t('components.2fa.rememberPin')"
            ></v-checkbox>
            <a
              v-if="smsRecovery"
              class="link mb-3"
              @click="handleForgotPin"
            >{{ $t('components.2faSms.forgotPinQuestion') }}
            </a>
          </div>
        </v-card-text>
        <v-card-text class="py-0">
          <v-col class="col-12 px-2 py-0" align="left">
            <v-card-text style="color: red" class="px-0" v-show="showResult">{{
              message
            }}</v-card-text>
          </v-col>
        </v-card-text>
        <v-card color="primary" class="py-2 pl-4 mt-0 pr-2 footerModal">
          <v-row class="px-2 btns mx-0" >
            <v-col cols="6" class="px-0 py-0" align="left">
              <v-btn
              v-if="enabled === 'sms'"
              color="primary"
              :loading="requestingSms"
              :disabled="requestSmsDone"
              @click="handleForgotPin"
            >{{ $t('components.2faSms.sendSMS') }}</v-btn>
            </v-col>
            <v-col cols="6" class="px-0 py-0" align="right">
              <v-btn
                color="primary"
                @click="requestAccess"
                :disabled="!rememberPin && (!showCodeInput || !tfaCode)"
                :loading="requestingAccess"
                >{{ $t("components.2fa.login") }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-spacer></v-spacer>
      </v-card>
      <TwoFactorSendSmsModal v-if="showSendSMSModal"
      :toggleShowSendSMS="toggleShowSendSMS" 
      :showSendSMSModal="showSendSMSModal" 
      :requestSms="requestSms" 
      ></TwoFactorSendSmsModal>
    </v-dialog>
  </v-app>
</template>
<script>
import store from "../../store";
import TwoFactorSendSmsModal from "./modals/steps/2faSendSmsModal.vue"
export default {
  components: {
    TwoFactorSendSmsModal
  },
  data() {
    return {
      showModal: true,
      primaryDrawer: {
        model: null,
        type: "permanent",
        clipped: true,
        floating: false,
        mini: false,
      },
      state: store.state,
      ownUUID: store.state.ownUUID,
      requestingSms: false,
      requestSmsDone: false,
      enabled: false,
      smsRecovery: false,
      pinProtectionType: '',
      tfaCode: "",
      valid: true,
      requestingAccess: false,
      showResult: false,
      message: "",
      showPassword: false,
      showSendSMSModal: false,
    };
  },
  async created() {
    this.$vuetify.theme.dark = store.state.persisted.isDark; // Set dark theme based on persisted
    const result = await fetch("/api/2fa/status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-secret": this.secret,
      },
      body: JSON.stringify({
        uuid: this.ownUUID,
      }),
    }).then(res => res.json());
    if (result.success) {
      this.message = "";
      this.showResult = false;
      this.enabled = result.method;
      this.smsRecovery = (result.admin && result.smsRecovery) || false;
      this.pinProtectionType = result.type || 'simple';
    } else {
      this.message =
        result.error || this.$t("components.2faSms.anErrorOccurred");
      this.showResult = true;
      this.enabled = false;
    }
  },
  methods: {
    handleForgotPin (){
      if (this.enabled === 'sms') return this.requestSms();
      this.showSendSMSModal = true;
    },
    toggleShowSendSMS(){
      this.showSendSMSModal = !this.showSendSMSModal;
    },
    showPass() {
      this.showPassword = !this.showPassword;
    },
    async requestSms() {
      this.requestingSms = true;
      const result = await fetch("/api/2fa/sendSMS", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-secret": this.secret,
        },
        body: JSON.stringify({
          uuid: this.ownUUID,
          code: this.tfaCode,
        }),
      }).then(res => res.json()).catch(err => { console.warn('requestSms', err); return {}; });
      if (result.success) {
        this.message = "";
        this.showResult = false;
        this.requestSmsDone = true;
      } else {
        this.message =
          result.error || this.$t("components.2faSms.anErrorOccurred");
        this.showResult = true;
        this.requestSmsDone = false;
      }
      this.requestingSms = false;
    },
    async requestAccess() {
      this.requestingAccess = true;
      this.tfaCode = this.tfaCode.trim();
      const result = await fetch("/api/2fa/login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-secret": this.secret,
        },
        body: JSON.stringify({
          uuid: this.ownUUID,
          code: this.tfaCode,
        }),
      }).then(res => res.json());
      if (result.valid) {
        const localTwoTokenKey = "ownTwoToken_" + this.ownUUID;
        const twotoken = result.token;
        localStorage.setItem(localTwoTokenKey, twotoken);
        this.message = "";
        window.location.reload();
      } else if (result.success) {
        this.message = this.$t("components.2faOtp.invalidCode");
        this.showResult = true;
      } else if (result.error) {
        this.message =
          result.error || this.$t("components.2faSms.anErrorOccurred");
        this.showResult = true;
      }
      this.requestingAccess = false;
    },
  },
  computed: {
    rememberPin: {
      get() {
        if (this.requestSmsDone) return false; // We hide the checkbox if SMS requested
        if (this.enabled !== 'pin') return false; // We hide the checkbox if method is not pin
        return localStorage.getItem('rememberPin_'+this.ownUUID) === 'true';
      },
      set(newValue) {
        localStorage.setItem('rememberPin_'+this.ownUUID, newValue);
      }
    },
    secret() {
      const uuid = this.ownUUID;
      const localSecretKey = "ownSecret_" + uuid;
      return localStorage.getItem(localSecretKey);
    },
    showCodeInput() {
      return this.requestSmsDone || this.enabled !== "sms";
    },
    showSendSms() {
      return !this.requestSmsDone && this.enabled === "sms";
    },
    tfaCodeLabel() {
      switch (this.enabled) {
        case "otp":
          return this.$t("components.2fa.enterOtpCode");
        case "pin":
          if (this.pinProtectionType === 'complex') {
            return this.$t("components.2fa.enterComplexPinCode"); 
          } else {
            return this.$t("components.2fa.enterPinCode");
          } 
        case "sms":
          return this.$t("components.2fa.enterSmsCode");
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.link {
  color: var(--v-primary-base) !important;
}
.rememberPinStyle {
    width: 80%;
    margin: 0 auto;
}
.spanModalTitle {
  font-size: 18px;
  word-break: break-word;
  max-width: 90%;
  line-height: 2;
}
.divInput{
  width: 80%;
  margin: 0 auto;
}
#two_sandbox {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: auto;
  height: auto;
  z-index: 4;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>