<template>
  <v-content class="px-0 py-0">
    <v-row>
      <v-col class="mx-2">
        <v-card outlined>
          <div class="n pa-4">
            <span class="subtitle-1 font-weight-bold">
              {{ getTitelForUuid(selectedUser) }}
              {{ getNameForUuid(selectedUser) }}
            </span>
          </div>
          <template>
            <v-data-table
              :headers="headers"
              :items="dailyReports"
              :items-per-page="5"
              class="elevation-1"
              :page.sync="page"
              @page-count="pageCount = $event"
              fixed-header
              height="calc(100vh - 365px)"
            >
             <!-- report text -->
              <template v-slot:item.report="{ item }">
                 <span v-html="$sanitize(checkForUrls(item.report))"></span>
              </template>
              <!-- characters -->
              <template v-slot:item.characters="{ item }">
                 <span>{{item.report.length}}</span>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>
import store from "../../../store";
import {checkForUrls} from "../../../utils/basicFunctions"
export default {
  props: ["selectedUser"],
  components: {},
  data() {
    return {
      state: store.state,
      setCurrentContentVisile: store.setCurrentContentVisile,
      page: 1,
      pageCount: 0,
    };
  },
  methods: {
    checkForUrls(text){
      return checkForUrls(text, false)
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("components.stats.date"),
          value: "date",
          sortable: true,
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.adminSettingsModal.dailyReport"),
          value: "report",
          sortable: false,
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("generics.characters"),
          value: "characters",
          sortable: false,
          class: "font-weight-bold subtitle-1",
        },
      ];
    },
    dailyReports() {
      return [
        {
          date: new Date().toLocaleDateString(),
          report:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. www.google.es Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,",
          characters:0
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.lockMessageIcon {
  position: absolute;
  right: -6px;
  top: -10px;
}
.pointer {
  cursor: pointer;
}
.theme--dark.v-data-table tbody tr td {
  color: white !important;
}
.theme--light.v-data-table thead tr th {
  color: black !important;
}
.theme--dark.v-data-table thead tr th {
  color: white !important;
}
.holidaysColor {
  color: #7208ff !important;
}
.sizePagination {
  max-width: 120px;
  margin-right: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
</style>