<template>
  <v-card @click="doMakeSelection(confTemplate.groupId)">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-card-text v-on="on" style="cursor:pointer;">
          <v-img class="mr-1" :src="confTemplate.groupImage" max-height="30" max-width="30" contain></v-img>
          <p class="templateNameHeader">{{$t('components.groups.templateName')}}:</p>
          <p class="templateName">{{confTemplate.groupName}}</p>
        </v-card-text>
      </template>
      <span>
        <TemplateGeneralDetails :confTemplate="confTemplate" />
      </span>
    </v-tooltip>
  </v-card>
</template>

<script>
import store from "../../store";
import TemplateGeneralDetails from "../../components/meetingLine/eventDetails/templateGeneralDetails.vue"
export default {
  components: { TemplateGeneralDetails },
  props: ["confTemplate", "makeSelection"],
  data() {
    return {
      state: store.state
    };
  },
  methods: {
    doMakeSelection(id){
      this.makeSelection(id)
    },
  },
};
</script>

<style scoped lang="scss">
.v-card__title {
  font-size: 14px !important;
}
</style>

<style lang="scss">
.templateName {
  position: absolute;
  top: 30px;
  left: 60px;
}
.templateNameHeader {
  position: absolute;
  top: 10px;
  left: 60px;
}
</style>