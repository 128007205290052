<template>
  <v-dialog
    v-model="showStatusColorSettings"
    persistent
    max-width="650"
    @keydown.esc="closeModal"
  >
    <v-card>
      <v-row flex class="w-100 mx-0 my-0 justify-space-between">
        <v-col :cols="isMobile ? 12 : 5" class="px-0 py-0" align="left">
          <v-card-title class="headline">
            <v-img
              class="mr-1"
              src="assets/icon.png"
              max-height="30"
              max-width="30"
              contain
            ></v-img>
            {{ $t("components.navbar.statusColor") }}
          </v-card-title>
        </v-col>
        <div class="pa-4">
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="black" @click="closeModal()">
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    :style="{ fontSize: '25px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("generics.close") }}</span>
            </v-tooltip>
          </template>
        </div>
      </v-row>
      <v-spacer></v-spacer>
      <v-list-item class="py-0 switchWrapper">
        <v-list-item-content class="py-0 my-0">
          <v-switch
            class="my-0 py-2"
            @change="changeColor($event, 'whole')"
            v-model="state.user.colorsettings.wholeBar"
            :label="$t('components.navbar.wholeActionBar')"
          ></v-switch>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="py-0 switchWrapper">
        <v-list-item-content class="py-0 my-0">
          <v-switch
            class="my-0 py-2"
            @change="changeColor($event, 'handset')"
            v-model="state.user.colorsettings.handSetOnly"
            :label="$t('components.navbar.onlyHandset')"
          ></v-switch>
        </v-list-item-content>
      </v-list-item>
      <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns w-100 mx-0 my-0">
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  color="white"
                  @click="closeModal()"
                  class="closeButton"
                >
                  <font-awesome-icon
                    :icon="['fal', 'check']"
                    :style="{ fontSize: '25px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("generics.close") }}</span>
            </v-tooltip>
          </template>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
export default {
  props: ["showStatusColorSettings", "toggleStatusColorSettings"],
  data: () => ({
    state: store.state,
    setColorSettings: store.setColorSettings,
    isMobile: isMobile(),
  }),
  methods: {
    changeColor(evt, colorstate) {
      if (colorstate === "whole" && evt === true) {
        this.setColorSettings(true, false);
      } else if (colorstate === "whole" && evt === false) {
        this.setColorSettings(false, true);
      }
    },
    closeModal() {
      this.toggleStatusColorSettings();
    },
  },
  mounted() {
    if (typeof document.store.state.user.colorsettings === "undefined") {
      this.setColorSettings(true, false);
    }
  },
};
</script>

<style scoped lang="scss">
.closeButton {
  background-color: green !important;
  border-radius: 0 !important;
}
</style>