var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[(!_vm.actualDate)?_c('v-row',{staticClass:"w-100 mx-0 my-0 justify-space-between",attrs:{"flex":""}},[_c('v-col',{staticClass:"px-0 py-0",attrs:{"align":"left"}},[_c('v-card-title',{class:_vm.isMobile ? 'headlineMobile' : 'headline'},[_c('v-img',{attrs:{"src":"assets/icon.png","max-height":"30","max-width":"30","contain":""}}),_vm._v(" "),_c('span',{staticClass:"font-weight-medium pr-2 pl-4 spanModalCall",domProps:{"innerHTML":_vm._s(_vm.titleModal)}}),_vm._v(" "),(!!_vm.showInfoButton)?_c('v-tooltip',{attrs:{"top":"","content-class":"videoButtonTT"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('font-awesome-icon',_vm._g({style:({
                fontSize: '17px',
                position: 'relative',
              }),attrs:{"color":"#02889d","icon":['fal', 'info-circle']}},on))]}}],null,false,448263408)},[_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.showInfoButton == "holidays"
                ? _vm.$t("components.holidays.infoTT")
                : "")+"\n          ")])]):_vm._e(),_vm._v(" "),_vm._t("default")],2)],1),_vm._v(" "),(_vm.closeModalFunction)?_c('div',{staticClass:"pa-4"},[[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({ref:"closeButton",attrs:{"icon":"","color":"black"},on:{"click":_vm.closeModalFunction}},on),[_c('font-awesome-icon',{style:({ fontSize: '25px' }),attrs:{"icon":['fal', 'times'],"color":_vm.state.persisted.isDark ? 'white' : 'black'}})],1)]}}],null,false,1435469655)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("generics.close")))])])]],2):_vm._e()],1):_c('v-card-title',{class:("" + (_vm.isMobile ? 'headlineMobile' : 'headline styleHeader')),attrs:{"primary-title":""}},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"d-flex px-1 py-1",attrs:{"cols":"8"}},[_c('v-img',{staticClass:"imageLogo",attrs:{"max-height":30,"max-width":30,"src":'assets/icon.png',"contain":""}}),_vm._v(" "),_c('span',{staticClass:"font-weight-medium pl-4 spanModalCall"},[_vm._v(_vm._s(_vm.titleModal)+"\n        ")])],1),_vm._v(" "),_c('v-col',{staticClass:"px-1 py-1 text-right",attrs:{"cols":"4"}},[_c('span',{staticClass:"font15 pr-2"},[_vm._v(_vm._s(_vm.actualDate))]),_vm._v(" "),(_vm.closeModalFunction)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({ref:"closeButton",attrs:{"icon":"","color":"black"},on:{"click":function($event){return _vm.closeModalFunction($event)}}},on),[_c('font-awesome-icon',{style:({ fontSize: '25px' }),attrs:{"icon":['fal', 'times'],"color":_vm.state.persisted.isDark ? 'white' : 'black'}})],1)]}}],null,false,1979330873)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("generics.close")))])]):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }