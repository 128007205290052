<template>
  <div>
    <v-content
      v-if="viewingConferences"
      :class="`px-0 py-0 ${removeGeneralWrapper ? null : 'contentGridWrapper'}`"
      v-on:scroll.native="handleScroll"
    >
      <v-row class="mx-auto">
        <v-col class="custom6cols pb-1 px-2" cols="6">
          <v-card
            class="cardWidth cursorPointer"
            :style="{ border: `1px solid #efefef` }"
            @click="clickOnAddCard()"
          >
            <div style="height: 180px; display: table; width: 100%">
              <div class="divAdd">
                <font-awesome-icon :icon="['fal', 'plus']" class="buttonAdd" />
                <p class="mb-0 mt-2">{{$t('components.conferenceForm.createConferenceHall')}}</p>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col
          v-for="(conference, index) in getMyConferenceHallDetailsConfIsTemp"
          :key="index"
          class="custom6cols pb-1 px-2"
          cols="6"
        >
          <v-card
            class="cardWidth"
            style="background-color: #eaeaea"
            @click="editConference(conference)"
          >
            <v-tooltip top max-width="750">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-img
                    :src="conference.confAvatar"
                    class="white--text align-end"
                    style="border-radius: 4px"
                    height="180px"
                  >
                    <ConferenceDateTimeOverlay :conference="conference" />
                    <v-tooltip bottom>
                      <template #activator="{ on: onMenu }">
                        <font-awesome-icon
                          v-on="{ ...on, ...onMenu }"
                          v-if="conference.confIsReocurring"
                          :icon="['far', 'repeat-alt']"
                          color="orange"
                          :style="{
                            fontSize: '20px',
                            position: 'absolute',
                            top: 45,
                            right: 5,
                          }"
                        />
                      </template>
                      <span>{{
                        $t("components.conferenceForm.reocurringConf")
                      }}</span>
                    </v-tooltip>
                    <div class="gradientBg">
                      <v-card-subtitle
                        style="color: #fff !important"
                        class="
                          titleCard
                          colorWhite
                          pt-1
                          pb-1
                          px-2
                          text-truncate
                        "
                      >
                        <!--  elipses menu -->
                        <ConferenceCardActions
                          :conference="conference"
                          :editConference="editConference"
                          :deleteConference="deleteConferenceHall"
                          :checkSendMessage="checkSendMessage"
                          :existingConference="existingConference"
                          :duplicateConference="duplicateConference"
                          :callEveryone="callEveryone"
                          :reminderConference="reminderConference"
                          :amInACall="amInACall"
                        />
                      </v-card-subtitle>
                    </div>
                  </v-img>
                </div>
              </template>
              <ConferenceInfoTooltip
                :conference="conference"
              ></ConferenceInfoTooltip>
            </v-tooltip>
          </v-card>
        </v-col>
        <infinite-loading :distance="5" @infinite="infiniteHandler">
          <div slot="no-results"></div>
          <div slot="no-more"></div>
        </infinite-loading>
      </v-row>
    </v-content>
    <v-content
      v-if="!viewingConferences"
      :class="`px-0 py-0 ${removeGeneralWrapper ? null : 'contentGridWrapper'}`"
      v-on:scroll.native="handleScroll"
    >
      <div :class="isMobile ? 'participantsButMobile' : 'participantsBut'">
        <v-tooltip
          v-if="showJoinConferenceButton && !amInACall"
          top
          max-width="210"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="mr-1 mb-2 white--text"
              @click="joinToConference"
              depressed
              color="#FFA500"
              >{{ $t("components.conferenceForm.joinConference") }}</v-btn
            >
          </template>
          <span>{{ $t("components.conferenceForm.joinConference") }} </span>
        </v-tooltip>
        <v-btn class="mr-1 mb-2" depressed color="primary" :disabled="true"
          >{{ $t("components.conferenceForm.inviteeTotal") }}:
          {{ getTotalInvited }}</v-btn
        >
      </div>
      <v-row >
        <v-col class="mx-0 py-0" :cols="isMobile ? 12 : 7" :md="isMobile ? 12 : 5" v-if="!isPreviewingEmail">
          <v-row class="mx-0">
            <v-col cols="12" class="pl-0 pb-0">
              <v-combobox
                ref="templatesDropDown"
                v-model="conferenceName"
                :class="{ borderError: conferenceName == '' }"
                :disabled="!canEditEvent"
                :items="getUserGroups"
                :label="$t('components.conferenceForm.conferenceName')"
                dense
                outlined
                item-text="groupName"
                item-value="groupId"
                return-object
                @change="changeGroupCombo($event)"
                maxlength="100"
                :counter="100"
              >
                <template slot="append-outer" v-if="canEditEvent">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        small
                        :disabled="!canSaveGroup"
                        @click="savingTemplate($event)"
                      >
                        <font-awesome-icon
                          :style="{ fontSize: '26px' }"
                          class="cursorPointer mb-1"
                          :icon="['fal', 'save']"
                          :color="isEditingTemplate ? 'red' : ''"
                        ></font-awesome-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      isTemplate && !isEditingTemplate
                        ? $t("components.conferenceForm.editTemplate")
                        : isTemplate && isEditingTemplate
                        ? $t("generics.cancel")
                        : $t("components.conferenceForm.saveAsTemplate")
                    }}</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="
                      isEditingTemplate || typeof conferenceName === 'object'
                    "
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        small
                        @click="deleteTemplate($event)"
                      >
                        <font-awesome-icon
                          :style="{ fontSize: '23px' }"
                          class="cursorPointer"
                          :icon="['fal', 'trash']"
                          :color="!deleteTemplateActive ? '' : 'red'"
                        ></font-awesome-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $t("components.conferenceForm.deleteTemplate")
                    }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:item="data">
                  <v-list-item-title
                    >{{ data.item.groupName }} -
                    {{
                      moment(data.item.groupCreated).format("DD.MM.YYYY HH:mm")
                    }}</v-list-item-title
                  >
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" class="pl-0 pb-0 pt-0" v-if="canEditEvent">
              <v-textarea
                outlined
                dense
                rows="2"
                v-model="personalizedGreeting"
                :counter="150"
                maxlength="150"
                :label="$t('components.conferenceForm.personalizedSalutation')"
                no-resize
              />
            </v-col>
            <v-col :cols="isMobile ? 8 : 10" class="pl-0 pt-0" v-if="canEditEvent">
              <v-autocomplete
                v-model="selectedDropDowItem"
                :items="fillDropDown"
                :item-disabled="disabledItem"
                outlined
                dense
                color="blue-grey lighten-2"
                :label="$t('components.conferenceForm.selectParticipants')"
                item-text="name"
                item-value="uuid"
                multiple
                @change="handleSelection($event)"
                hide-selected
                hide-details
                class="mb-0"
                @input="searchInput = null"
                :disabled="isConferenceFull"
                :search-input.sync="searchInput"
                :no-data-text="
                  !isConferenceFull
                    ? $vuetify.noDataText
                    : $t('components.conferenceForm.limitUsers')
                "
              >
                <template v-slot:selection="data">{{
                  data.item.name
                }}</template>

                <template v-slot:item="data">
                  <div class="selectorBack d-flex">
                    <template>
                      <v-list-item-avatar class="borderRadius-4">
                        <img :src="data.item.avatar" />
                      </v-list-item-avatar>

                      <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                      <v-list-item-content v-if="data.item.group !== 'Teams'">
                        <v-list-item-title
                          v-html="
                            $sanitize(
                              !data.item.isGroup
                                ? data.item.name
                                : data.item.groupName
                            )
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="
                            !data.item.isGroup
                              ? getTypeOfParticipant(data.item.group)
                              : $t('components.addConferenceModal.group') +
                                ` - ${data.item.groupMembers.length}` +
                                ' ' +
                                $t('components.addConferenceModal.members')
                          "
                        ></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-content v-else>
                        <v-list-item-title
                          v-html="$sanitize(data.item.name)"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="
                            $t('components.addConferenceModal.team') +
                            ` - ${data.item.members.length}` +
                            ' ' +
                            $t('components.addConferenceModal.members')
                          "
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              :cols="isMobile ? 4 : 2"
              class="pl-0 pt-0 text-center"
              style="align-items: center; display: flex"
              v-if="canEditEvent"
            >
              <span class="caption">{{
                $t("components.conferenceForm.guest")
              }}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <font-awesome-icon
                    v-on="on"
                    @click="
                      isConferenceFull ? null : (showAddGuestModal = true)
                    "
                    class="primary--text cursorPointer ml-3"
                    :icon="['fal', 'plus-circle']"
                    :style="{ fontSize: '26px' }"
                    :disabled="isConferenceFull"
                  />
                </template>
                <span>{{ $t("components.conferenceForm.ttAddNewGuest") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" class="pl-0 pt-0">
              <v-row>
                <v-col cols="4">
                  <v-menu
                    ref="startDateMenu"
                    id="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    :disabled="!canEditEvent || isNotMyConference"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formatedStart"
                        :disabled="!canEditEvent || isNotMyConference"
                        readonly
                        v-on="on"
                        v-bind="attrs"
                        dense
                        hide-details
                        outlined
                        :label="$t('components.conferenceForm.startDate')"
                      >
                        <template
                          slot="append"
                          v-if="formatedStart != null || isNotMyConference"
                        >
                          <DeleteBasket
                            v-if="canEditEvent"
                            indexData
                            :delFunction="cleanDate"
                            extraClasses="cursorPointer"
                            fontSize="18"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      :min="moment(Date.now()).format('YYYY-MM-DD')"
                      @click:date="$refs.startDateMenu.save(startEvent)"
                      v-model="startEvent"
                      no-title
                      scrollable
                      :locale="this.$locale.current()"
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" class="">
                  <v-menu
                    ref="startTimeMenu"
                    :disabled="!canEditEvent"
                    v-model="startTimeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time2"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        @keydown.space="(event) => event.preventDefault()"
                        v-model="timeStart"
                        type="time"
                        class="timePicker"
                        v-on="on"
                        dense
                        hide-details
                        outlined
                        :label="$t('components.conferenceForm.from')"
                        :disabled="!canEditEvent || !formatedStart"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      class="font16"
                      v-if="startTimeMenu"
                      :disabled="!canEditEvent"
                      v-model="timeStart"
                      full-width
                      format="24hr"
                      @click:minute="$refs.startTimeMenu.save(timeStart)"
                      @click:hour="autocompleteMinutes"
                      min="00:00"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-menu
                    ref="endTimeMenu"
                    :disabled="!canEditEvent"
                    v-model="endTimeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time1"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        @keydown.space="(event) => event.preventDefault()"
                        v-model="timeEnd"
                        type="time"
                        class="timePicker"
                        v-on="on"
                        dense
                        outlined
                        :disabled="!canEditEvent || !timeStart || !startEvent"
                        hide-details
                        :label="$t('components.conferenceForm.to')"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      class="font16"
                      v-if="endTimeMenu"
                      :disabled="!canEditEvent"
                      v-model="timeEnd"
                      full-width
                      format="24hr"
                      @click:minute="$refs.endTimeMenu.save(timeEnd)"
                      @click:hour="autocompleteMinutesEnd"
                      :min="timeStart"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="isMobile ? 12 : 8" class="pl-0 pt-0" style="max-height: 75px">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-textarea
                    v-on="on"
                    :disabled="!canEditEvent"
                    outlined
                    dense
                    rows="2"
                    v-model="conferenceDescription"
                    :counter="150"
                    maxlength="150"
                    :label="$t('components.conferenceForm.description')"
                    no-resize
                    @blur="isEditingTemplate ? updateTemplate() : null"
                  />
                </template>
                <span>{{ $t("components.conferenceForm.ttDescription") }}</span>
              </v-tooltip>
            </v-col>
            <v-col :cols="isMobile ? 12 : 4" :class="`${isMobile ? 'pb-4' : 'pb-0'} pt-0 text-center`">
              <span class="caption">{{
                $t("components.conferenceForm.conferenceLogo")
              }}</span>
              <v-row>
                <v-col col="12" class="py-0">
                  <v-img
                    :src="conferenceImage"
                    width="80px"
                    height="80px"
                    max-height="80px"
                    max-width="80px"
                    class="confImage borderRadius-4 center"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2 borderRadius-4 uploadGroupImage"
                          depressed
                          small
                          fab
                          dark
                          color="primary"
                          v-on="on"
                          @click="setConferenceImage"
                          :disabled="!canEditEvent"
                        >
                          <font-awesome-icon
                            :icon="['fal', 'upload']"
                            :style="{ fontSize: '18px' }"
                          />
                        </v-btn>
                      </template>
                      <span>{{
                        $t("components.groups.uploadConferenceImage")
                      }}</span>
                    </v-tooltip>
                  </v-img>
                  <input
                    type="file"
                    accept="image/x-png, image/gif, image/jpeg"
                    ref="conferenceImageInput"
                    class="d-none"
                    @change="saveConferenceImage($event)"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pt-0"
              style="max-height: 75px"
              v-if="!canEditEvent && !confHasEnded"
            >
              <v-radio-group
                row
                v-model="rsvpValue"
                @change="conferenceRSVP"
                class="ml-2"
              >
                <v-radio
                  :value="true"
                  :label="$t('components.meetingTime.accept')"
                ></v-radio>
                <v-radio
                  :value="false"
                  :label="$t('components.meetingTime.decline')"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pt-0"
              style="max-height: 75px"
              v-if="isMod && confHasEnded"
            >
              <v-tooltip top max-width="210">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-2"
                    v-on="on"
                    depressed
                    color="primary"
                    @click="duplicateConference(conferenceToEdit.confId)"
                    >{{
                      $t("components.addConferenceModal.dupConfNewDate")
                    }}</v-btn
                  >
                </template>
                <span
                  >{{ $t("components.addConferenceModal.dupConfNewDate") }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="canEditEvent">
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :label="$t('components.inviteVisitor.pinAccess')"
                outlined
                class="mr-2 mt-0 pt-0"
                autocomplete="off"
                v-model="pinCode"
                dense
                @keydown.space.prevent
                @blur="pinCode != '' ? checkPin : null"
                :maxlength="6"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <p class="body-2 pt-1" v-if="pinCode">
                {{ $t("components.inviteGuestPanel.pinGuestSenden") }}
              </p>
            </v-col>
            <!-- Documents begin -->
            <!-- <v-col cols="6" class="pb-1 pt-0">
              <v-list class="py-0">
                <v-list-group
                  :append-icon="null"
                  no-action
                  class="scheculedConf"
                >
                  <template v-slot:activator>
                    <v-list-item-content class="py-0">
                      <v-list-item-title>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="toggleDocumentsView"
                              :disabled="!canEditEvent"
                              class="justStart no-bg list"
                              depressed
                            >
                              <font-awesome-icon
                                :icon="['fal', 'file-pdf']"
                                :style="{ fontSize: '18px' }"
                              />
                              <span class="ml-3">{{
                                $t("components.conferenceForm.addpdfConference")
                              }}</span>
                            </v-btn>
                          </template>
                          <span>{{
                            $t("components.conferenceForm.ttDocuments")
                          }}</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-group>
              </v-list>
            </v-col> -->
            <!-- Documents end -->
            <v-col :cols="isMobile ? 12 : 6" class="pb-1 pt-0" v-if="isDevelopment">
              <v-list class="py-0">
                <v-list-group
                  :append-icon="null"
                  no-action
                  class="scheculedConf"
                >
                  <template v-slot:activator>
                    <v-list-item-content class="py-0">
                      <v-list-item-title>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :disabled="!canEditEvent"
                              v-on="on"
                              class="justStart no-bg list"
                              depressed
                            >
                              <font-awesome-icon
                                :icon="['fal', 'copy']"
                                :style="{ fontSize: '18px' }"
                              />
                              <span class="ml-3">{{
                                $t(
                                  "components.addConferenceModal.confFrequency"
                                )
                              }}</span>
                            </v-btn>
                          </template>
                          <span>{{
                            $t("components.conferenceForm.ttReoccurring")
                          }}</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item class="pl-0 w-90">
                    <v-list-item-content>
                      <v-radio-group
                        v-model="scheduledInterval"
                        row
                        @change="confFrequencyUpdated()"
                      >
                        <v-radio
                          class="ml-3"
                          :label="$t('components.addConferenceModal.confDaily')"
                          value="d"
                        ></v-radio>
                        <v-radio
                          class="ml-3"
                          :label="
                            $t('components.addConferenceModal.confWeekly')
                          "
                          value="w"
                        ></v-radio>
                        <v-radio
                          class="ml-3"
                          :label="
                            $t('components.addConferenceModal.confMonthly')
                          "
                          value="m"
                        ></v-radio>
                      </v-radio-group>
                      <v-text-field
                        v-if="scheduledInterval && scheduledInterval !== ''"
                        class="frequencyInput mx-2"
                        dense
                        v-model="confFrequency"
                        :disabled="!canEditEvent"
                        type="number"
                        min="0"
                        max="30"
                        step="1"
                        hide-details
                        @keypress="checkCodeEntered($event)"
                        @keyup="checkFrequencyValue($event)"
                      ></v-text-field>
                      <span
                        v-if="scheduledInterval && scheduledInterval !== ''"
                        class="frequencyDenominator"
                        >{{ getFrequencyText }}</span
                      >
                      <v-btn
                        v-if="scheduledInterval && scheduledInterval !== ''"
                        class="ml-2 borderRadius-4 frequencyButton"
                        style="height: 36px"
                        @click="removeScheduledConferences()"
                        icon
                        outlined
                        depressed
                        small
                        fab
                        dark
                        color="primary"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'times']"
                          :style="{ fontSize: '18px' }"
                        />
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-col>
          </v-row>
          <v-row v-if="canEditEvent && isEditingConference">
            <v-col cols="6" class="pb-1 pt-0">
              <v-btn
                :disabled="!canEditEvent"
                class="justStart no-bg list"
                @click="doResendInviteToAll"
                depressed
              >
                <font-awesome-icon
                  :icon="['fal', 'redo']"
                  :style="{ fontSize: '18px' }"
                />
                <span class="ml-3">{{
                  $t("components.conferenceForm.resendInviteAll")
                }}</span>
              </v-btn>
            </v-col>
            <v-col cols="6" class="pb-1 pt-0">
              <v-btn
                class="justStart no-bg list"
                @click="doSendMessageToAll(conferenceToEdit.confId)"
                depressed
                :disabled="false"
              >
                <font-awesome-icon
                  :icon="['fal', 'paper-plane']"
                  :style="{ fontSize: '18px' }"
                />
                <span class="ml-3">{{
                  $t("components.conferenceForm.sendMessageAll")
                }}</span>
              </v-btn>
            </v-col>
            <v-col cols="6" class="pb-1 pt-0">
              <v-tooltip top max-width="210">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="justStart no-bg list"
                    @click="callEveryone(true, null)"
                    depressed
                    :disabled="!canEditEvent"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'loveseat']"
                      :style="{ fontSize: '18px' }"
                    />
                    <span class="ml-3">{{
                      $t("components.conferenceForm.sendConfToWaitingRoom")
                    }}</span>
                  </v-btn>
                </template>
                <span
                  >{{ $t("components.conferenceForm.sendConfToWaitingRoomTT") }}
                </span>
              </v-tooltip>
            </v-col>
            <v-col cols="6" class="pb-1 pt-0">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="justStart no-bg list"
                    :class="
                      !deleteActive
                        ? 'deleteBasketButtonNormal'
                        : 'deleteBasketButtonActive'
                    "
                    @click="deleteConferenceSafely($event, conferenceToEdit.confId)"
                    depressed
                    :disabled="!canEditEvent"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'trash']"
                      :style="{ fontSize: '18px' }"
                    />
                    <span class="ml-3">{{
                      $t("components.conferenceForm.deleteConference")
                    }}</span>
                  </v-btn>
                </template>
                <span>{{
                  $t("components.conferenceForm.confirmRemove")
                }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="!isPkg && canEditEvent">
            <v-switch
                class="my-0 py-3 mr-2"
                v-model="enableYouTubeCall"
                :label="$t('components.conferenceForm.enableYouTubeCall')"
              >
            </v-switch>
          </v-row>
          <v-row v-if="!areAPIKeysNeeded && canEditEvent && isYouTubeCall">
            <v-col v-if="!isGoogleApiLoaded && isGapiError" cols="12" class="pb-1 pt-0 mt-2 mb-1">
              <span class="mt-10 mb-6 px-5 redSpan">{{$t('components.conferenceForm.namespaceNotAllowed')}}</span>
            </v-col>
            <v-col v-if="isGoogleApiLoaded && isYouTubeCall" cols="12" class="pb-1 pt-0 mt-2 mb-1">
              <!-- GOOGLE SIGN IN/OUT BUTTONS  -->
              <v-tooltip v-if="!gLoggedUser" top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <google-signin-btn label="Sign In" customClass="my-button" @click="signin" depressed/>
                    <span
                      class="mt-10 mb-6 px-5 redSpan"
                      >{{$t('components.conferenceForm.loginYTRequired')}}
                    </span>
                  </div>
                </template>
                <span>{{$t('components.conferenceForm.needToLoginYTUser')}}</span>
              </v-tooltip>
              <v-tooltip v-if="gLoggedUser" top>
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <div v-on="on">
                      <google-signin-btn label="Sign Out" customClass="my-button" @click="signout"/>
                    </div>
                    <div class="px-5 align-self-center">
                      <span v-if="!canUserStreamInYT" class="mt-10 mb-6 redSpan">
                        {{$t('components.conferenceForm.notAbleToStream')}}
                      </span>
                      <span v-if="canUserStreamInYT" class="primary--text mt-10 mb-6 px-5">
                        {{$t('components.conferenceForm.ableToStream')}}
                      </span>
                    </div>
                  </div>
                </template>
                <span v-if="!canUserStreamInYT" >{{$t('components.conferenceForm.needLivestreamEnabled')}}</span>
                <span v-if="canUserStreamInYT" >{{$t('components.conferenceForm.yourUserIsOk')}}</span>
              </v-tooltip>
              <!-- GOOGLE SIGN IN/OUT BUTTONS  -->
            </v-col>
          </v-row>
          <v-row v-if="areAPIKeysNeeded && isYouTubeCall">
            <span class="mt-10 mb-6 redSpan">
              {{$t('components.conferenceForm.noAPIKeys')}}
            </span>
          </v-row>
        </v-col>
        <v-col :cols="isMobile ? 12 :5" :md="isMobile ? 12 :7" class="py-0 userList" v-if="!isPreviewingEmail">
          <v-tabs :centered="isMobile ? true : false" class="tabInvite " @change="setTypeParticipants($event)">
            <v-tab>{{$t('components.conferenceForm.podium')}}</v-tab>
            <v-tab>{{$t('components.conferenceForm.listeners')}}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="participantsType" :class="isMobile ? null : 'participantsScroll'">
            <v-tab-item>
              <!-- <template> -->
                <div
                  class="no-gutters flex-fill d-flex rowUsersConference"
                  :class="{
                    justifyRight:
                      getPodiumParticipants && getPodiumParticipants.length <= 4,
                  }"
                >
                    <!-- style="border: 4px solid #fff" -->
                  <template v-if="getPodiumParticipants.length <= 4">
                    <v-col
                      class="pr-2 pt-2"
                      :cols="getCols('podium')"
                      v-for="user in getPodiumParticipants"
                      :key="user.uuid"
                      :class="`${isMobile ? null : getWidth('podium')} ${
                        getPodiumParticipants.length == 3 ? 'mx-auto' : ''
                      }`"
                    >
                      <ListConferencesUsers
                        :isEditingConference="isEditingConference"
                        :deleteUser="deleteUser"
                        :user="user"
                        :toggleModerator="toggleModerator"
                        :groupModerator="conferenceModerator"
                        :canEditEvent="canEditEvent"
                        :conference="conferenceToEdit"
                        :isConferenceUser="true"
                        :isCardStyle="true"
                        :createGuestInvite="createGuestInvite"
                        :createUserInvite="createUserInvite"
                        :createUserReminderInvite="createUserReminderInvite"
                        :createGuestReminderInvite="createGuestReminderInvite"
                      />
                    </v-col>
                  </template>
                  <div class="w-100" v-if="getPodiumParticipants.length > 4">
                    <div
                      class="userDiv"
                      v-for="user in getPodiumParticipants"
                      :key="user.uuid"
                    >
                      <ListConferencesUsers
                        :isEditingConference="isEditingConference"
                        :deleteUser="deleteUser"
                        :user="user"
                        :toggleModerator="toggleModerator"
                        :groupModerator="conferenceModerator"
                        :canEditEvent="canEditEvent"
                        :conference="conferenceToEdit"
                        :isConferenceUser="true"
                        :isCardStyle="false"
                        :createGuestInvite="createGuestInvite"
                        :createUserInvite="createUserInvite"
                        :createUserReminderInvite="createUserReminderInvite"
                        :createGuestReminderInvite="createGuestReminderInvite"
                      />
                    </div>
                  </div>
                </div>
              <!-- </template> -->
            </v-tab-item>
            <v-tab-item>
              <template>
                <div
                  class="no-gutters flex-fill d-flex rowUsersConference"
                  :class="{
                    justifyRight:
                      getListenerParticipants && getListenerParticipants.length <= 4,
                  }"
                >
                  <template v-if="getListenerParticipants.length <= 4">
                    <v-col
                      class="pr-2 pt-2"
                      :cols="getCols('listeners')"
                      v-for="user in getListenerParticipants"
                      :key="user.uuid"
                      :class="`${isMobile ? null : getWidth('listeners')} ${
                        getListenerParticipants.length == 3 ? 'mx-auto' : ''
                      }`"
                    >
                        <!-- :toggleModerator="toggleModerator" -->
                      <ListConferencesUsers
                        :isEditingConference="isEditingConference"
                        :deleteUser="deleteUser"
                        :user="user"
                        :groupModerator="conferenceModerator"
                        :canEditEvent="canEditEvent"
                        :conference="conferenceToEdit"
                        :isConferenceUser="true"
                        :isCardStyle="true"
                        :createGuestInvite="createGuestInvite"
                        :createUserInvite="createUserInvite"
                        :createUserReminderInvite="createUserReminderInvite"
                        :createGuestReminderInvite="createGuestReminderInvite"
                      />
                    </v-col>
                  </template>
                  <div class="w-100" v-if="getListenerParticipants.length > 4">
                    <div
                      class="userDiv"
                      v-for="user in getListenerParticipants"
                      :key="user.uuid"
                    >
                        <!-- :toggleModerator="toggleModerator" -->
                      <ListConferencesUsers
                        :isEditingConference="isEditingConference"
                        :deleteUser="deleteUser"
                        :user="user"
                        :groupModerator="conferenceModerator"
                        :canEditEvent="canEditEvent"
                        :conference="conferenceToEdit"
                        :isConferenceUser="true"
                        :isCardStyle="false"
                        :createGuestInvite="createGuestInvite"
                        :createUserInvite="createUserInvite"
                        :createUserReminderInvite="createUserReminderInvite"
                        :createGuestReminderInvite="createGuestReminderInvite"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col
          class="mx-0 py-0 my-12"
          cols="12"
          md="12"
          v-if="isPreviewingEmail"
        >
          <span
            class="primary--text mt-10 mb-6 px-4"
            style="text-transform: uppercase; font-weight: 400"
            >{{ $t("components.conferenceForm.emailPreview") }}</span
          >
          <v-divider class="pb-3 mt-3"></v-divider>
          <span
            class="primary--text mt-10 mb-6 px-4"
            style="font-size: 13px; text-transform: uppercase; font-weight: 400"
            >{{ $t("components.invitePreview.invitePreviewForUser") }}</span
          >
          <v-card elevation="2" outlined>
            <v-card-text>
              <v-row align="center" class="mx-0">
                <v-col
                  class="grey--text ml-4 cols"
                  cols="12"
                  md="12"
                  v-html="$sanitize(userInvText)"
                ></v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-divider class="pb-3 mt-3"></v-divider>
          <span
            class="primary--text mt-10 mb-6 px-4"
            style="font-size: 13px; text-transform: uppercase; font-weight: 400"
            >{{ $t("components.invitePreview.invitePreviewForGuest") }}</span
          >
          <v-card elevation="2" outlined>
            <v-card-text>
              <v-row align="center" class="mx-0">
                <v-col
                  class="grey--text ml-4 cols"
                  cols="12"
                  md="12"
                  v-html="$sanitize(guestInvText)"
                ></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!--  Buttons -->
      <v-row class="mx-0">
        <v-col cols="12" :class="`pl-0 ${isMobile ? 'text-center' : 'text-right'}`">

          <v-divider class="pb-4"></v-divider>

          <v-tooltip top max-width="210">
            <template v-slot:activator="{ on }">
              <v-btn
                v-show="
                  isPreviewingEmail &&
                  canEditEvent &&
                  isNameValid
                "
                class="ml-2"
                v-on="on"
                @click="toggleEmailPreview()"
                depressed
                color="primary"
                >{{ $t("components.conferenceForm.back") }}</v-btn
              >
            </template>
            <span>{{ $t("components.conferenceForm.backTT") }} </span>
          </v-tooltip>

          <v-tooltip top max-width="210" v-if="canEditEvent">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="ml-2"
                :disabled="(startEvent == '' || timeStart === null) || !isNameValid || amInACall || (isYouTubeCall && (!isGoogleApiLoaded || !gLoggedUser || !canUserStreamInYT)) || isLoadingCall"
                @click="callNow()"
                :loading="isLoadingCall"
                depressed
                color="primary"
              >
                {{ $t("components.conferenceForm.startConference") }}
              </v-btn>
            </template>
            <span>
              {{ $t("components.conferenceForm.startConferenceTT") }}
            </span>
          </v-tooltip>

          <v-tooltip top max-width="210">
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-2"
                v-on="on"
                @click="toggleEmailPreview()"
                depressed
                color="primary"
                v-show="
                  !isPreviewingEmail &&
                  canEditEvent &&
                  isNameValid
                "
                >{{ $t("components.conferenceForm.emailPreview") }}</v-btn
              >
            </template>
            <span>{{ $t("components.conferenceForm.emailPreview") }} </span>
          </v-tooltip>

          <v-btn
            class="ml-2"
            v-if="!isEditingConference"
            @click="saveConference()"
            depressed
            color="primary"
            :disabled="disableSaveButton"
            >{{ $t("components.conferenceForm.groupSave") }}
          </v-btn>
          <v-tooltip
            top
            max-width="210"
            v-if="isEditingConference && canEditEvent"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="ml-2"
                :disabled="!canEditEvent || disableSaveButton"
                @click="doUpdateConference"
                depressed
                color="primary"
                >{{ $t("components.footer.doUpdate") }}</v-btn
              >
            </template>
            <span>{{ $t("components.footer.doUpdateTT") }} </span>
          </v-tooltip>

          <v-tooltip top v-if="isDevelopment && state.nerd">
            <template v-slot:activator="{ on }">
              <v-btn
                :class="`${isMobile ? 'mb-2' : null} ml-2`"
                v-on="on"
                @click="showJoinConferenceButton ? joinToConference : callEveryone(false, null)"
                depressed
                color="primary"
              >
                <font-awesome-icon
                  :icon="['fal', 'phone']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span
              >{{ $t("components.conferenceForm.startConferencePlanetTT") }}
            </span>
          </v-tooltip>

          <v-tooltip top max-width="210">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="ml-2 borderRadius-4"
                style="height: 36px"
                @click="goBackToGrid"
                icon
                outlined
                depressed
                small
                fab
                dark
                color="primary"
              >
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("generics.close") }} </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <!-- End buttons -->
      <!-- Modals -->
      <template v-if="showAddGuestModal">
        <AddGuestModal
          :addMember="addMember"
          :members="conferenceMembers"
          :isConference="true"
          :showModal="showAddGuestModal"
          :closeModal="closeGuestModal"
          :conferenceId="getConferenceId"
        />
      </template>
      <template v-if="showCroppie">
        <UploadGroupConfImg
          :confOrGroupId="conferenceId"
          :uploadConferenceImage="uploadConferenceImage"
          :showCroppie="showCroppie"
          :temporalImage="temporalImage"
          :resetInputImg="resetConferenceImageInput"
          :closeCroppie="openCloseCroppie"
          :isConference="true"
        />
      </template>
        <!-- :setTempDocStore="setTempDocStore" -->
      <DocumentsManager
        v-if="showConferenceDocumentsModal"
        eventType="conference"
        :documentsStore="conferenceStore"
        :showModal="showConferenceDocumentsModal"
        :amIModerator="canEditEvent"
      />
      <ConfirmLeave
        v-if="showConfrimModal"
        :getDiff="getDiff"
        :showConfrimModal="showConfrimModal"
        :stayInPage="stayInPage"
        :leavePage="leavePage"
      />
      <!-- End modals -->
    </v-content>
  </div>
</template>

<script>
// import { YOUTUBE_API_KEY, YOUTUBE_CLIENT_ID } from "../../../../sharedsrc/constants"
import { webLicensedBaseFeatures } from "../../../../sharedsrc/licensedFeatures";
import { v4 as uuidv4 } from "uuid";
import moment from "../../../../sharedsrc/moment";
import store, { EventBus } from "../../../store";
import DocumentsManager from "../../../components/documents/documentsMgr.vue";
import {
  timelineEvents,
  updateTimelineItemEvent,
  deleteTimelineItemEvent,
  createTimelineEntryEffect,
  dispatchRsvpTimelineEntry,
  dispatchFetchMyItemsForDateEvent
} from "../../../effector/timeline";
import {
  myCompanyDocumentsDetails,
  newCompanyDocumentEvent,
  updateCompanyDocumentEvent,
  dispatchDeleteCompanyDocumentEvent,
} from "../../../effector/companyDocuments";
import { markAsCallWaitingRoomUserEvent } from "../../../effector/users/waitingInfo";
import { uploadConferenceImage, setRemoteUser, sendMailConferenceCall } from "../../../lib/wsMsg";
import { hasPrivilege } from "../../../utils/privileges";
import {
  dispatchSuccessAlert,
  dispatchErrorAlert,
} from "../../../effector/alerts";
import { getTimezone } from "../../../utils/basicFunctions";
import {
  sendVisitorEmail,
  sendUserEmail,
  getInviteTextForGuest,
  getInviteTextForUser,
  getInviteTextForRem,
  getInviteTextForCancellation,
  getInviteTextGuestChange,
  getInviteTextUserChange,
} from "../../../lib/inviteHelpers";
import {
  prepadeDataToSendMessageEffector,
  newMessageEvent,
} from "../../../effector/message";
import {
  setSendMessageModalEvent,
  conferenceDocumentsStore,
} from "../../../effector/modals";
import { isMobile } from "../../../lib/mobileUtil";

import ConferenceInfoTooltip from "../conferenceInfoTooltip.vue";
import ConferenceCardActions from "../conferenceCardActions.vue";
import ConferenceDateTimeOverlay from "../conferenceDateTimeOverlay.vue";
import { wsCallStartBridgeCall, amInABridgeCall, disableCallBtn, personIsInBridgeCall } from "../../../utils/calls";
import {
  dispatchDeleteConferenceHallEvent,
  myConferenceHallStore,
  newConferenceHallEvent,
  myConferenceHallDetails,
  updateConferenceHallEvent,
} from "../../../effector/conferenceHalls";
import ListConferencesUsers from "../listConferencesUsers.vue";
import AddGuestModal from "../../modal/groups-conferences/addGuestModal.vue";
import DeleteBasket from "../../ui/deleteBasket.vue";
import ConfirmLeave from "../conferenceDataConfirmDialog.vue";
import UploadGroupConfImg from "../uploadGroupConfImg.vue";
import { parallel } from "../../../lib/asyncUtil";
import Vue from 'vue'
import VueGoogleApi from 'vue-google-api'
import { YOUTUBE_API_KEY_DEFAULT, YOUTUBE_CLIENT_ID_DEFAULT} from '../../../../sharedsrc/constants'


export default {
  props: [
    "removeGeneralWrapper",
    "existingConference",
    "groupFromConference",
    "sendMailToAll",
    "justSendMailToAll",
    "callGroup",
    "updateGroup",
    "saveGroup",
    "sendUserMessageParent",
    "currentTab"
  ],
  components: {
    ListConferencesUsers,
    AddGuestModal,
    DeleteBasket,
    ConferenceInfoTooltip,
    ConferenceCardActions,
    ConferenceDateTimeOverlay,
    UploadGroupConfImg,
    DocumentsManager,
    ConfirmLeave,
  },
  data() {
    return {
      enableYouTubeCall: false,
      gLoggedUser: false,
      isGoogleApiLoaded: false,
      isMobile: isMobile(),
      state: store.state,
      viewingConferences: true,
      conferenceToEdit: null,
      scheduledInterval: null,
      confFrequency: 0,
      conferenceModerator: store.state.ownUUID,
      sliceIndex: 48,
      isNotMyConference: false,
      isEditingConference: false,
      isInserting: false,
      isDuplicating: false,
      complexPinActive: false,
      complexPinRegex:
        /^(?=.*[0-9])(?=.*[a-zñöäü])(?=.*[A-ZÖÄÜÑ])(?=.*[*.!@$%^&(){}[\]\/:;<>,.?~_\+\-\=|]).{12,}$/,
      pinCode: "",
      startDateMenu: false,
      startTimeMenu: false,
      endTimeMenu: false,
      durationMin: null,
      startEvent: "",
      endEvent: "",
      timeStart: null,
      timeEnd: null,
      time1: null,
      time2: null,
      conferenceName: "",
      conferenceId: uuidv4(),
      conferenceDescription: "",
      conferenceMembers: [],
      totalMembers: 0,
      conferenceImage: store.state.namespaceSettings.companyLogo
        ? store.state.namespaceSettings.companyLogo
        : "/img/icon.png",
      conferenceTime: null,
      conferenceDate: null,
      selectedDropDowItem: [],
      allUsers: {},
      allTeams: {},
      allGroups: [],
      showAddGuestModal: false,
      searchInput: null,
      isUnlimited: false,
      noticeText: "",
      deleteActive: false,
      canEdit: false,
      rsvpValue: null,
      maxFrequency: 0,
      showCroppie: false,
      temporalImage: "",
      isEditingTemplate: false,
      moment: moment,
      deleteTemplateActive: false,
      pdfDocuments: [],
      // tempDocumentStore: null,
      personalizedGreeting: "",
      isPreviewingEmail: false,
      uInviteText: "",
      gInviteText: "",
      initSnapShot: {},
      postSnapShot: {},
      ignoreDirty: false,
      showConfirmation: false,
      confrimFrom: false,
      isChanging: false,
      participantsType: 0,
      canUserStreamInYT: false,
      liveStreamIngestionInfo: null,
      liveStreamURL: null,
      liveStreamId: null,
      liveBroadcastId: null,
      isLoadingCall: false,
      areAPIKeysNeeded: false,
      isGapiError: false,
      tobeNotifiedDeleteUser: []
    };
  },
  effector: {
    myConferences: myConferenceHallStore,
    myConferenceHallDetails: myConferenceHallDetails,
    timelineEvents: timelineEvents,
    myDocuments: myCompanyDocumentsDetails,
    showConferenceDocumentsModal: conferenceDocumentsStore,
  },
  watch: {
    conferenceToEdit: {
      deep: true,
      handler: function (value) {
        if (value) {
          this.isInserting = false;
          this.isDuplicating = false;
          this.loadExistingConference();
          this.isEditingConference = true;
          this.getInitSnapShot();
        } else {
          if (!this.isDuplicating) {
            this.resetForm();
          }
        }
      },
    },
    durationMin() {
      setImmediate(() => {
        if (this.durationMin == null) return;
        if (typeof this.durationMin !== "number" && this.durationMin !== "") {
          this.durationMin = parseInt(this.durationMin, 10);
        } else if (this.durationMin > 1440 && this.durationMin !== "") {
          this.durationMin = 1440;
        } else if (this.durationMin < 1 && this.durationMin !== "") {
          this.durationMin = 0;
        } else {
          if (this.durationMin !== "" && typeof this.durationMin === "number") {
            this.recalcEnd();
          }
        }
      });
    },
    conferenceDescription(newValue, oldValue) {
      if (newValue !== oldValue) {
      }
    },
    personalizedGreeting(newValue, oldValue) {
      if (newValue !== oldValue) {
      }
    },
    viewingConferences(value) {
      if (value) return;
      this.allUsers = JSON.parse(JSON.stringify(this.state.group || {}));
      this.allTeams = JSON.parse(
        JSON.stringify(
          this.state.namespaceSettings.processedOrganisation.teams || {}
        )
      );
      this.allGroups = JSON.parse(JSON.stringify(this.state.user.groups || []));
    },
    startEvent() {
      this.startChanged();
    },
    timeStart() {
      this.recalcEnd(false);
      this.startChanged();
    },
    endEvent() {
      this.endChanged();
    },
    timeEnd() {
      this.endChanged();
    },
  },
  beforeMount(){
    EventBus.$on("loadInsertingConferenceHall", this.clickOnAddCard);
    EventBus.$on("loadConferenceHallTemplate", this.loadConferenceHallTemplateMethod);
  },
  mounted() {
    EventBus.$on("loadInsertingToday", this.loadInsertingTodayMethod);
    //clickOnAddCard
    let extant = undefined;
    if (this.$router.history.current.params.id) {
      extant = this.myConferenceHallDetails.find(
        (e) => e.confId === this.$router.history.current.params.id
      );
      if (extant) {
        this.editConference(extant);
        this.$router.push({ path: "/newconference/" });
      } else if (
        this.$router.history.current.params.id.substring(0, 3) == "00-"
      ) {
        let data = this.$router.history.current.params.id.split("-");
        if (data[1] !== "form") {
          this.clickOnAddCard(data[1]);
        } else if (data[1] === "form") {
          this.clickOnAddCard();
        }
      } else if (
        this.$router.history.current.params.id.indexOf("template:") !== -1
      ) {
        const groupId = this.$router.history.current.params.id.split(":")[1];
        const extant = store.state.user.groups.find(
          (e) => e.groupId === groupId
        );
        if (extant) {
          this.$router.push({ path: "/newconference/" });
          this.viewingConferences = false;
          this.changeGroupCombo(extant);
          this.isEditingTemplate = extant.groupId;
        }
      }
    }
    if (!this.isEditingConference) {
      this.populateDefaultModerator();
    }
    if (!this.isPkg || this.isYouTubeCall) {
      this.loadGoogleLogin();
    }
  },
  destroyed() {
    this.tobeNotifiedDeleteUser = [];
    EventBus.$off("loadInsertingConferenceHall", this.clickOnAddCard);
    EventBus.$off("loadConferenceHallTemplate", this.loadConferenceHallTemplateMethod);
    EventBus.$off("loadInsertingToday", this.loadInsertingTodayMethod);
  },
  computed: {
    getMyConferenceHallDetailsConfIsTemp() {
      const finalResult = this.myConferenceHallDetails.filter(
        (e) => !e.confIsTemp
      );
      return this.sortConferences(finalResult);
    },
    showJoinConferenceButton(){
      if(!this.getConference) return false;
      const getCallInfo = this.state.group[this.getConference?.confOwner]?.user?.bridgeCallInfo || undefined;
      // condition to know if the call hall conference is in process and get the info and get inside the call as a listener
      if(getCallInfo && getCallInfo.conferenceHall && getCallInfo.confId === this.getConference.confId.toString()) {
        //  there is a hall conference call working which the user is part of it but user doesn't be inside atm
        const userData = getCallInfo?.conferenceAttendees.find(e => e.uuid == this.state.ownUUID );
        const getCallUUID = (this.state.group[this.getConference?.confOwner]?.user?.inBridgeCall || "").toString();
        if(userData && getCallUUID) return true;
      }
      return false;
    },
    getListenerParticipants() {
      const finalResult = this.getConferenceMembers.filter(
        (e) => e.isPodium === false
      );
      return finalResult;
    },
    getPodiumParticipants() {
      const finalResult = this.getConferenceMembers.filter(
        (e) => e.isPodium === true
      );
      return finalResult;
    },
    confHasEnded() {
      let hasEnded = false;
      if (this.conferenceToEdit) {
        let confId = this.conferenceToEdit.confId || 0;
        const extantEv = this.timelineEvents.find((e) => e.confId === confId);
        if (extantEv && extantEv.end) {
          const orgEnd = moment(new Date(extantEv.end));
          const elapsedMins = moment().diff(orgEnd, "minutes");
          if (elapsedMins > 2) {
            hasEnded = true;
          }
        }
      } else {
        hasEnded = false;
      }
      return hasEnded;
    },
    getDurationMin() {
      if (
        !this.durationMin ||
        this.durationMin.length <= 0 ||
        this.durationMin <= 0
      ) {
        return null;
      } else {
        return this.durationMin;
      }
    },
    isNameValid() {
      if (
        this.isTemplate &&
        typeof this.conferenceName === "object" &&
        this.conferenceName !== null
      ) {
        return true;
      } else {
        return (this.conferenceName || " ").trim().length > 0;
      }
    },
    isDirty() {
      if (this.ignoreDirty) {
        return false;
      }
      return (
        JSON.stringify(this.initSnapShot) !== JSON.stringify(this.postSnapShot)
      );
    },
    showConfrimModal() {
      return this.showConfirmation;
    },
    userInvText() {
      return this.uInviteText;
    },
    guestInvText() {
      return this.gInviteText;
    },
    conferenceStore() {
      let docStore = null;
      const foundStore = this.myDocuments.find(
        (e) => e.confId === this.conferenceId
      );
      if (foundStore && foundStore.confId) {
        docStore = foundStore;
        return docStore;
      }
      // else if (this.tempDocumentStore) {
      //   docStore = this.tempDocumentStore;
      //   return docStore;
      // }
      else {
        return docStore;
      }
    },
    isTemplate() {
      return typeof this.conferenceName == "object";
    },
    canSaveGroup() {
      if (
        typeof this.conferenceName == "string" &&
        this.conferenceName !== ""
      ) {
        return true;
      } else if (
        typeof this.conferenceName == "object" &&
        this.conferenceName &&
        this.conferenceName.groupName !== ""
      ) {
        return true;
      }
      return false;
    },
    getUserGroups() {
      let myGroups = store.state.user.groups || [];
      return myGroups.filter( (e) => e.conferenceHall === true);
    },
    isReOcurring() {
      if (!this.scheduledInterval || this.scheduledInterval == "") {
        return false;
      } else {
        return true;
      }
    },
    isDevelopment() {
      return webLicensedBaseFeatures.isDev;
    },
    isYouTubeCall() {
      return this.enableYouTubeCall && !webLicensedBaseFeatures.isPkg;
    },
    isPkg() {
      return webLicensedBaseFeatures.isPkg;
    },
    getFrequencyText() {
      let messg = "";
      switch (this.scheduledInterval) {
        case "d":
          messg =
            this.confFrequency && this.confFrequency > 1
              ? this.$t("components.addConferenceModal.days")
              : this.$t("components.addConferenceModal.day");
          break;
        case "w":
          messg =
            this.confFrequency && this.confFrequency > 1
              ? this.$t("components.addConferenceModal.weeks")
              : this.$t("components.addConferenceModal.week");
          break;
        case "m":
          messg =
            this.confFrequency && this.confFrequency > 1
              ? this.$t("components.addConferenceModal.months")
              : this.$t("components.addConferenceModal.month");
          break;
        default:
          messg = "";
          break;
      }
      return messg;
    },
    getConferenceId() {
      return this.conferenceId;
    },
    isMod() {
      if (this.conferenceToEdit) {
        if (
          this.conferenceToEdit.confModerators.indexOf(store.state.ownUUID) !==
          -1
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    canEditEvent() {
      if (this.isInserting || this.isDuplicating) return true;
      if (this.conferenceToEdit) {
        if (
          this.conferenceToEdit.confModerators.indexOf(store.state.ownUUID) !==
            -1 &&
          !this.confHasEnded
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    conferenceIsValid() {
      if (this.conferenceName) {
        if (
          Object.prototype.hasOwnProperty.call(this.conferenceName, "groupName")
        ) {
          return this.conferenceName.groupName.trim().length > 0;
        } else {
          return this.conferenceName.trim().length > 0;
        }
      } else {
        return false;
      }
    },
    formatedStart() {
      if (!this.startEvent) {
        return null;
      }
      return this.moment(new Date(this.startEvent)).format("DD.MM.YYYY");
    },
    isConferenceFull() {
      return this.conferenceMembers.length >= 200;
    },
    getTotalInvited() {
      this.totalMembers = 0;
      this.conferenceMembers.forEach((user) => {
        if (user.isTeam || user.isGroup) {
          this.totalMembers = this.totalMembers + user.memberCount;
        } else {
          this.totalMembers = this.totalMembers + 1;
        }
      });

      return this.totalMembers;
    },
    disableSaveButton() {
      return (
        !this.conferenceName ||
        !this.startEvent ||
        this.timeStart == null ||
        this.timeEnd == null ||
        (this.timeEnd && (this.durationMin == 0 || !this.durationMin)) ||
        (this.isReOcurring &&
          (isNaN(this.confFrequency) || this.confFrequency == 0)) ||
        (this.isReOcurring && !this.scheduledInterval)
      );
    },
    disableCallConference() {
      return this.conferenceMembers.length < 1;
    },
    fillDropDown() {
      if (
        !this.conferenceToEdit &&
        (!this.isInserting || this.isEditingConference)
      )
        return;
      let jumpRec = false;
      let result;
      const users = this.allUsers;
      const tmpUsersArray = [];
      const tmpVisitorsArray = [];
      const tmpUserTeamsArray = [];
      // get team members
      let data;
      for (const key in users) {
        data = {
          name: (users[key].user || {}).name || "",
          uuid: key,
          avatar:
            (users[key].user || {}).avatar || "img/default_profile_picture.png",
          isGuest: users[key].user.visitor ? true : false
        };
        if (tmpUsersArray.length === 0) {
          tmpUsersArray.push({
            header: this.$t("components.addConferenceModal.users"),
          });
        }
        if (!users[key].user.visitor && key !== store.state.ownUUID) {
          data.group = "Users";
          tmpUsersArray.push(data);
        } else if (
          store.state.persisted.userVisitors[key] &&
          users[key].user.visitor &&
          users[key].user.visitorData.userInviter === this.state.ownUUID
        ) {
          data.group = "Visitors";
          tmpVisitorsArray.push(data);
        }
      }

      if (tmpUsersArray.length > 0) {
        if (tmpVisitorsArray.length > 0) {
          tmpUsersArray.push({ divider: true });
          tmpUsersArray.push({
            header: this.$t("components.addConferenceModal.visitors"),
          });
          tmpUsersArray.sort((a, b) => {
            if (a.name && b.name) {
              let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
            }
            return 0;
          });
          tmpVisitorsArray.sort((a, b) => {
            if (a.name && b.name) {
              let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
            }
            return 0;
          });
          Array.prototype.push.apply(tmpUsersArray, tmpVisitorsArray);
        }
        for (const key in this.allTeams) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.allTeams && this.allTeams.hasOwnProperty(key)) {
            const team = this.allTeams[key];
            if (this.isEditingConference) {
              if (
                this.conferenceToEdit.members.findIndex(
                  (e) => e.name === key
                ) !== -1
              ) {
                jumpRec = true;
              }
            }
            if (!jumpRec) {
              const tmpTeamObj = {
                name: key,
                members: team.members,
                group: "Teams",
                avatar: "img/default_profile_picture.png",
              };
              tmpUserTeamsArray.push(tmpTeamObj);
            } else {
              jumpRec = false;
            }
          }
        }
        if (tmpUserTeamsArray.length > 0) {
          tmpUsersArray.push({ divider: true });
          tmpUsersArray.push({
            header: this.$t("components.addConferenceModal.teams"),
          });
          tmpUserTeamsArray.sort((a, b) => {
            if (a.name && b.name) {
              let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
            }
            return 0;
          });
          Array.prototype.push.apply(tmpUsersArray, tmpUserTeamsArray);
        }
        result = tmpUsersArray;
      }
      if (!this.isConferenceFull) {
        return result;
      } else {
        return [];
      }
    },
    getConferenceMembers() {
      return this.conferenceMembers;
    },
    getConference() {
      return this.conferenceToEdit;
    },
    amInACall() {
      return amInABridgeCall();
    },
  },
  methods: {
    sortConferences(conferenceList) {
      const now = new Date();

      // Function to parse the date and time strings into a Date object
      function parseDateTime(dateStr, timeStr) {
        const [year, month, day] = dateStr.split('-').map(Number);
        const [hour, minute] = timeStr.split(':').map(Number);
        return new Date(year, month - 1, day, hour, minute, 0, 0);
      }

      conferenceList.sort((a, b) => {
        const dateA = parseDateTime(a.confStartDate, a.confStartTime);
        const dateB = parseDateTime(b.confStartDate, b.confStartTime);

        const isPastA = dateA < now;
        const isPastB = dateB < now;
        const isTodayA = dateA.toDateString() === now.toDateString();
        const isTodayB = dateB.toDateString() === now.toDateString();

        if (isPastA && isPastB) {
          // Both are past: order by date ascending (oldest first)
          return dateA - dateB;
        } else if (isPastA) {
          // A is past and should come first
          return -1;
        } else if (isPastB) {
          // B is past and should come second
          return 1;
        } else if (isTodayA && isTodayB) {
          // Both are today: order by start time
          return dateA - dateB;
        } else {
          // All future dates or one today and one future: order by full date
          return dateA - dateB;
        }
      });

      return conferenceList;
    },
    loadInsertingTodayMethod(){
      if(this.currentTab === 'hallConference') {
        const date = new Date();
        const timestamp = date.getTime();
        this.clickOnAddCard(timestamp);
      }
    },
    loadConferenceHallTemplateMethod(data){
      EventBus.$emit("loadInsertingConferenceHall", data.id);
      this.doLoadTemplate(data.id);
    },
    joinToConference(){
      // condition to know if the call hall conference is in process and get the info and get inside the call as a listener
      const getCallInfo = this.state.group[this.getConference?.confOwner]?.user?.bridgeCallInfo || undefined;
      if(getCallInfo && getCallInfo.conferenceHall && getCallInfo.confId === this.getConference.confId) {
        //  there is a hall conference call working which the user is part of it but user doesn't be inside atm
        const userData = getCallInfo?.conferenceAttendees.find(e => e.uuid == this.state.ownUUID );
        const getCallUUID = (this.state.group[this.getConference?.confOwner]?.user?.inBridgeCall || "").toString();
        if(userData && getCallUUID) {
          if(userData.isPodium) {
            // set user as a podium, put into the call
            store.setInBridgeCall(getCallUUID)
            store.setRemoteBridgeStream(getCallUUID, { ...getCallInfo, ...getCallInfo.infoCall, isAudioOnly: true, calling: [] });
            store.setCurrentContentVisile("", false, this.$router);
          } else if (!userData.isPodium) {
            // getting user's conference data info
            this.state.remoteBridgeStreams = { [getCallUUID] : { ...getCallInfo, isAudioOnly: true, calling: [] } }
            this.state.user.inBridgeCallListener = getCallUUID

          }
        }
      }
    },
    loadGoogleLogin() {
      if(!this.$gapi){
        const eventApiKey = store.state.namespaceSettings.eventApiKey || YOUTUBE_API_KEY_DEFAULT
        const eventClientId = store.state.namespaceSettings.eventClientId || YOUTUBE_CLIENT_ID_DEFAULT
        // console.log('ICEICE going to use Google keys: ', eventApiKey, eventClientId)
        // Google API tests
        const config = {
          apiKey: eventApiKey,
          clientId:eventClientId,
          scope: 'https://www.googleapis.com/auth/youtube'
          // discoveryDocs: [ list_of_discoverydocs_urls ]
        }
        // console.log('ICEICE going to use api with config: ', config)
        if(!config.apiKey || !config.clientId) {
          console.warn('NO GOOGLE API KEYS')
          this.areAPIKeysNeeded = true
          return
        }
        Vue.use(VueGoogleApi, config)
      }
      // console.log('ICEICE isReady is: ', this.canUserStreamInYT)
      // console.log('ICEICE liveStreamIngest is: ', this.liveStreamIngestionInfo)
      this.$gapi.currentUser()
      .then(user => {
        this.isGoogleApiLoaded = true
        if (user) {
          console.debug('Signed in as: ', user);
          this.gLoggedUser = user
          this.checkIfUserCanStream()
        } else {
          this.gLoggedUser = false
          console.log('No user is connected.')
        }
      })
      .catch(error => {
        this.isGapiError = true
        console.warn('Error with gapi: ', error)
      })
    },
    async createYoutubeEnvironment() {
      this.liveStreamId = await this.createYoutubeLiveStream()
      this.liveBroadcastId = await this.createYoutubeLiveBroadcast()

      // console.log(`ICEICE liveStreamId is ${this.liveStreamId} and liveBroadcastId is ${this.liveBroadcastId}`)
      if (this.liveStreamId && this.liveBroadcastId) {
        const result = await this.bindLiveStreamWithBroadcast(this.liveBroadcastId, this.liveStreamId)
        // console.log('ICEICE goinf to return from createYoutubeEnvironment result is: ', result)
        return result
      } else {
        console.log('Something when wrong at youtube environment creation')
        return false
      }
    },
    async bindLiveStreamWithBroadcast(liveBroadcastId, liveStreamId) {
       const response = await this.$gapi.request({
        path: 'https://www.googleapis.com/youtube/v3/liveBroadcasts/bind',
        method: 'POST',
        params: { part: "snippet", id: liveBroadcastId, streamId: liveStreamId},
      })
      // console.log('ICEICE bindLiveStreamWithBroadcast response is: ', response)
      // console.log('ICEICE status is: ', response.result)
      if (response && response.result && response.status === 200 && response.result.id) {
        return true
      } else {
        return false
      }
    },
    async deleteYoutubeLiveStream() {
      const response = await this.$gapi.request({
        path: 'https://www.googleapis.com/youtube/v3/liveStreams',
        method: 'DELETE',
        body: {
          id: this.liveStreamId
        }
      })
      if (response) {
        // console.log('ICEICE deleteYoutubeLiveStream response is: ', response)
        return true
      } else {
        return false
      }
    },
    async createYoutubeLiveStream() {
      const response = await this.$gapi.request({
        path: 'https://www.googleapis.com/youtube/v3/liveStreams',
        method: 'POST',
        params: { part: "snippet,cdn,contentDetails" },
        body: {
          snippet: {
            title: this.conferenceName || this.$t("components.conferenceForm.newConference")
          },
          cdn: {
            format: "16:9",
            ingestionType: "rtmp",
            frameRate: "30fps",
            resolution: "720p"
          },
          contentDetails: {
            isReusable: true
          }
        }
      })
      // console.log('ICEICE createYoutubeLiveStream response is: ', response)
      if (response && response.result
      && response.result.id
      && response.result.cdn
      && response.result.cdn.ingestionInfo
      && response.result.cdn.ingestionInfo.rtmpsIngestionAddress
      && response.result.cdn.ingestionInfo.streamName) {
        this.liveStreamIngestionInfo = response.result.cdn.ingestionInfo
        this.liveStreamURL = this.liveStreamIngestionInfo.rtmpsIngestionAddress + '/' + this.liveStreamIngestionInfo.streamName
        return response.result.id
      } else {
        return false
      }
    },
    async deleteYoutubeBroadcast() {
      const response = await this.$gapi.request({
        path: 'https://www.googleapis.com/youtube/v3/liveBroadcasts',
        method: 'DELETE',
        body: {
          id: this.liveBroadcastId
        }
      })
      if (response) {
        // console.log('ICEICE deleteYoutubeBroadcast response is: ', response)
        return true
      } else {
        return false
      }
    },
    async createYoutubeLiveBroadcast() {
      let now = new Date()
      now = new Date(now.valueOf() + 60000) // Add a minute just for testing purposes
      const response = await this.$gapi.request({
        path: 'https://www.googleapis.com/youtube/v3/liveBroadcasts',
        method: 'POST',
        params: { part: "snippet,contentDetails,status" },
        body: {
          snippet: {
            title: this.conferenceName || this.$t("components.conferenceForm.newConference"),
            scheduledStartTime: now
          },
          contentDetails: {
            // enableClosedCaptions: true,
            // // enableContentEncryption: true,
            // enableDvr: false,
            // enableEmbed: true,
            // enableAutoStart: true,
            // enableAutoStop: true,
            // enableLowLatency: false,
            // latencyPreference: "ultraLow"
            // recordFromStart: false,
            // "startWithSlate": true
            closedCaptionsType: "closedCaptionsDisabled",
            enableAutoStart: true,
            enableAutoStop: true,
            enableClosedCaptions: false,
            enableContentEncryption: false,
            enableDvr: false,
            enableEmbed: true,
            enableLowLatency: false,
            latencyPreference: "ultraLow",
            monitorStream: {
                enableMonitorStream: true,
                broadcastStreamDelayMs: 0
            },
            recordFromStart: true,
            startWithSlate: false
          },
          status: {
            privacyStatus: "unlisted",
            selfDeclaredMadeForKids: false
          }
        }
      })
      if (response && response.result && response.result.id) {
        return response.result.id
      } else {
        return false
      }
    },
    checkIfUserCanStream() {
      try{
        this.$gapi.request({
          path: 'https://www.googleapis.com/youtube/v3/liveBroadcasts',
          method: 'GET',
          params: {
            broadcastStatus: 'all'
          }
        }).then( response => {
          if(response) {
            // console.log('ICEICE response for checkIfUserCanStream is: ', response)
            this.canUserStreamInYT = true
            // console.log('ICEICE after change value canUserStreamInYT is: ', this.canUserStreamInYT)
          }
        }).catch( error => {
          if(error) {
            this.canUserStreamInYT = false
            console.error('Error from checkIfUserCanStream is: ', error)
            // console.log('ICEICE after change value canUserStreamInYT is: ', this.canUserStreamInYT)
          }
        })
      } catch (error) {
        this.canUserStreamInYT = false
        console.log('Catching error on checkIfUserCanStream', error)
      }
    },
    getYoutubeLiveBroadcast() {
      this.$gapi.request({
        path: 'https://www.googleapis.com/youtube/v3/liveBroadcasts',
        method: 'GET',
        params: {
          broadcastStatus: 'all'
        }
      }).then(response => {
        console.debug('liveBroadcasts', response);
      })

      this.$gapi.request({
        path: 'https://www.googleapis.com/youtube/v3/liveStreams',
        method: 'GET',
        params: {
          mine: true
        }
      }).then(response => {
        console.debug('liveStreams', response);
      })
    },
    signout () {
      this.$gapi.signOut()
        .then( () => {
          this.gLoggedUser = false
          this.canUserStreamInYT = false
        })
    },
    async generateYoutubeStream() {
      const canUserStreamInYT = await this.createYoutubeEnvironment()
      await this.saveConference(false, false)
      return canUserStreamInYT
    },
    signin () {
      this.$gapi.signIn()
        .then(user => {
          this.gLoggedUser = user
          this.checkIfUserCanStream()
        })
    },
    sendMailConferenceCallOfflineUser(users, options) {
      let userOffline = users.filter((uuid)=>uuid!==this.state.ownUUID &&  (disableCallBtn(store.state.group[uuid]) || personIsInBridgeCall(uuid)))
      if(userOffline && userOffline.length>0){
        sendMailConferenceCall(userOffline, options)
      }
    },
    getPodiumParticipantUUID(uuid) {
      for (let index = 0; index < this.getConferenceMembers.length; index++) {
        const member = this.getConferenceMembers[index];
        if(member.uuid === uuid) return member.isPodium
      }
    },
    disabledItem(item){
      if(this.participantsType == 1){
        if (this.getListenerParticipants.length >= 188) return true;
      } else {
        // if (item.members && (item.members.length + this.getPodiumParticipants.length) >= 13 ) return true;
        // is a team, check if there is space in the podium list to add all the team members, if not, disable the entire team
        if (this.getPodiumParticipants.length >= 12) return true;
      }
      return false;
    },
    getCols(type) {
      const getParticipants = type === 'podium' ? this.getPodiumParticipants : this.getListenerParticipants
      if (getParticipants.length <= 4) {
        return 6;
      } else if (
        getParticipants.length > 4 &&
        getParticipants.length <= 15
      ) {
        return 4;
      } else if (getParticipants.length > 15) {
        return 3;
      }
    },
    getWidth(type) {
      const getParticipants = type === 'podium' ? this.getPodiumParticipants : this.getListenerParticipants
      if (getParticipants.length <= 4) {
        return "maxWidth35";
      } else if (
        getParticipants.length > 4 &&
        getParticipants.length <= 9
      ) {
        return "maxWidth33";
      } else if (
        getParticipants.length > 9 &&
        getParticipants.length <= 12
      ) {
        return "maxWidth25";
      } else if (
        getParticipants.length > 12 &&
        getParticipants.length <= 15
      ) {
        return "maxWidth20";
      } else if (
        getParticipants.length > 15 &&
        getParticipants.length <= 16
      ) {
        return "maxWidth25";
      } else if (getParticipants.length > 16) {
        return "maxWidth20";
      }
    },
    setTypeParticipants(event) {
      this.participantsType = event;
    },
    getDiff() {
      return this.showMeDaMoney(this.initSnapShot, this.postSnapShot);
    },
    stayInPage() {
      this.showConfirmation = false;
    },
    resetSnapShots() {
      this.initSnapShot = {};
      this.postSnapShot = {};
      this.ignoreDirty = true;
    },
    async leavePage() {
      // clean up snapshots
      await this.resetSnapShots();
      this.showConfirmation = false;
      if (this.confrimFrom == "goBackToGrid") {
        this.confrimFrom = false;
        this.goBackToGrid();
      } else {
        let whereTo = this.confrimFrom;
        this.confrimFrom = false;
        if (this.$router.history.current.path !== whereTo) {
          this.$router.push({ path: whereTo });
        }
      }
    },
    async goBackToGrid() {
      if (!this.viewingConferences) {
        if (this.$refs.templatesDropDown) {
          if (
            this.$refs.templatesDropDown.internalSearch &&
            this.$refs.templatesDropDown.internalSearch !== ""
          ) {
            this.conferenceName = this.$refs.templatesDropDown.internalSearch;
          }
        }
        await this.getPostSnapShot();
        if (this.isDirty && !this.isChanging && !this.isMod) {
          this.confrimFrom = "goBackToGrid";
          this.showConfirmation = true;
        } else {
          await this.resetForm();
          this.isPreviewingEmail = false;
          this.viewingConferences = true;
          this.conferenceToEdit = null;
          this.isEditingConference = false;
        }
        this.isChanging = false;
      } else {
      }
    },
    toggleEmailPreview() {
      this.isPreviewingEmail = !this.isPreviewingEmail;
      if (this.isPreviewingEmail) {
        this.uInviteText = this.doGetInviteTextForUser(true);
        this.gInviteText = this.doGetInviteTextForGuest(true);
      }
    },
    // setTempDocStore(tempStore) {
    //   this.tempDocumentStore = tempStore;
    // },
    toggleDocumentsView() {
      this.showConferenceDocumentsModal = !this.showConferenceDocumentsModal;
    },
    doLoadTemplate(groupId) {
      this.resetForm();
      this.ignoreDirty = false;
      const extant = store.state.user.groups.find((e) => e.groupId === groupId);
      if (extant) {
        this.viewingConferences = false;
        this.changeGroupCombo(extant);
        this.isEditingTemplate = extant.groupId;
      }
    },
    deleteTemplate(event) {
      event.stopPropagation();
      event.preventDefault();
      try {
        if (!this.deleteTemplateActive) {
          this.deleteTemplateActive = true;
          setTimeout(() => {
            this.deleteTemplateActive = false;
          }, 1500);
        } else {
          this.deleteTemplateActive = false;
          const groupIndex = store.state.user.groups.findIndex(
            (e) => e.groupId === this.isEditingTemplate
          );
          const groups = store.state.user.groups;
          groups.splice(groupIndex, 1);
          store.state.user.groups = groups;
          dispatchSuccessAlert(
            this.$t("components.conferenceForm.templateDeleted")
          );
          this.resetForm();

          this.syncSelector();
        }
      } catch (err) {
        this.deleteTemplateActive = false;
        console.warn("Error deleting group", err);
        dispatchErrorAlert("Error deleting template");
      }
    },
    async savingTemplate(evt) {
      if (!this.isEditingTemplate) {
        evt.preventDefault();
        evt.stopPropagation();
        if (typeof this.conferenceName == "string") {
          if (
            this.conferenceName !== "" &&
            this.conferenceName.trim().length > 0
          ) {
            const groupData = {
              groupId: uuidv4(),
              groupName: this.conferenceName,
              groupImage: this.conferenceImage,
              groupDescription: this.conferenceDescription || "",
              groupMembers: this.conferenceMembers,
              conferenceHall: true,
              liveBroadcastId: this.liveBroadcastId || undefined
            };
            await this.saveGroup(groupData);
            // const obj = store.state.user.groups.find(
            //   (e) => e.groupId === groupData.groupId
            // );
            // this.changeGroupCombo(obj);
            this.$refs.templatesDropDown.blur();
            this.isEditingTemplate = groupData.groupId;
          }
        } else {
          this.isEditingTemplate = this.conferenceName.groupId;
          this.$refs.templatesDropDown.blur();
        }
      } else {
        this.isEditingTemplate = false;
      }
    },
    async updateTemplate() {
      try {
        const tmpObj = {
          groupId: this.isEditingTemplate,
          groupName:
            typeof this.conferenceName == "object"
              ? this.conferenceName.groupName
              : this.conferenceName,
          groupImage: this.conferenceImage,
          groupDescription: this.conferenceDescription || "",
          groupMembers: this.conferenceMembers,
          groupUUIDS: await this.extractUsers(store.state.user.uuid),
          conferenceHall: true,
          liveBroadcastId: this.liveBroadcastId || undefined
        };
        await this.updateGroup(tmpObj);
      } catch (err) {
        console.warn("Error updating group", err);
        dispatchErrorAlert("Error updating group");
      }
    },
    async changeGroupCombo(evt) {
      if (typeof evt == "object") {
        if (evt && evt.groupId) {
          await this.resetForm();
          this.conferenceName = evt;
          this.conferenceDescription = evt.groupDescription;
          this.selectGroup(evt.groupId);
          this.getInitSnapShot();
          this.ignoreDirty = false;
          this.isDuplicating = true;
          this.isInserting = true;
        }
      } else {
        if (this.isEditingTemplate) {
          this.updateTemplate();
        }
      }
    },
    checkCodeEntered(ev) {
      var charCode = ev.which ? ev.which : ev.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        ev.preventDefault();
        ev.stopPropagation();
      }
    },
    checkFrequencyValue(ev) {
      if (this.confFrequency > this.maxFrequency) {
        this.confFrequency = this.maxFrequency;
      }
    },
    confFrequencyUpdated() {
      switch (this.scheduledInterval) {
        case "d":
          this.maxFrequency = 365;
          break;
        case "w":
          this.maxFrequency = 52;
          break;
        case "m":
          this.maxFrequency = 12;
          break;
        default:
          this.maxFrequency = 0;
          break;
      }
      // check current value against max
      if (this.confFrequency > 0) {
        if (this.confFrequency > this.maxFrequency) {
          this.confFrequency = 0;
        }
      }
    },
    removeScheduledConferences() {
      this.confFrequency = 0;
      this.maxFrequency = 0;
      this.scheduledInterval = "";
    },
    openCloseCroppie(value = undefined) {
      this.showCroppie = value ? value : !this.showCroppie;
    },
    getTypeOfParticipant(type) {
      if (type === "Visitors") {
        return this.$t("components.addConferenceModal.visitor");
      } else if (type === "Users") {
        return this.$t("components.addConferenceModal.users");
      }
    },
    autocompleteMinutesEnd(hour) {
      let finalHour = hour;
      if (hour < 10) finalHour = '0' + hour;
      this.timeEnd = finalHour + ":00";
    },
    autocompleteMinutes(hour) {
      let finalHour = hour;
      if (hour < 10) finalHour = '0' + hour;
      this.timeStart = finalHour + ":00";
    },
    async conferenceRSVP() {
      const timelineEv = this.timelineEvents.find(
        (e) => e.confId === this.conferenceId
      );

      if (timelineEv) {
        await dispatchRsvpTimelineEntry([
          timelineEv.uuid,
          this.rsvpValue == true ? true : false,
        ]);
      }
    },
    checkPin() {
      if (this.complexPinActive) {
        return this.complexPinRegex.test(this.pinCode);
      } else if (this.pinCode) {
        return /^\d{6}$/.test(this.pinCode);
      } else {
        return true;
      }
    },
    async reminderConference(conf) {
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conference = {
        personalizedGreeting: conf.confPersonalizedGreet,
        confStartDate: conf.confStartDate,
        confStartTime: conf.confStartTime,
        inviter: store.state.user.name,
        confName: conf.confName,
        isUnlimited: conf.isUnlimited,
        durationMin: conf.confDuration,
        confDescription: conf.confDescription,
      };
      const text = await getInviteTextForRem(tZone, conference, false);
      let subject = this.$t("components.invitePreview.inviteRemSubject");
      let mssgType = "conf-reminder";
      const noInviteLink = false;
      const dates = {
        startEvent: conf.confStartDate,
        timeStart: conf.confStartTime,
        timezone: tZone,
      };
      const evName = conference.confName;
      const sendMsg = true;

      this.justSendMailToAll(
        conf.confUUIDS,
        subject,
        text,
        mssgType,
        dates,
        evName,
        sendMsg,
        noInviteLink
      );
    },
    duplicateConference(id) {
      const original = this.myConferenceHallDetails.find((e) => e.confId == id);
      if (original && original.confId) {
        this.isDuplicating = true;
        this.isInserting = true;
        this.conferenceToEdit = undefined;
        this.isEditingConference = false;
        this.viewingConferences = false;
        this.conferenceName = original.confName;
        this.conferenceId = uuidv4();
        this.conferenceDescription = original.confDescription;
        this.conferenceImage = original.confAvatar;
        this.conferenceMembers = original.members;
        this.startEvent = "";
        this.timeEnd = null;
        this.timeStart = null;
        this.durationMin = null;
        this.pinCode = "";
        this.isUnlimited = original.isUnlimited;
        // this.syncSelector();
      }
    },
    doSendMessageToAll(confId) {
      const uuidArrays = [];
      this.conferenceToEdit.confUUIDS.forEach((uuid) => {
        if (uuid !== this.state.ownUUID) {
          uuidArrays.push(uuid);
        }
      });
      this.setShowModalSendMsg(uuidArrays, confId);
    },
    doResendInviteToAll() {
      if (!this.conferenceToEdit) return false;
      const timelineEv = this.timelineEvents.find(
        (e) => e.confId === this.conferenceToEdit.confId
      );
      if (!timelineEv) return false;
      this.createInvitations(this.conferenceToEdit, timelineEv.uuid);
    },
    setShowModalSendMsg(members, confId) {
      setSendMessageModalEvent({
        show: true,
        users: members,
        type: "conferenceMsg",
        confId: confId,
      });
    },
    loadConference(confId) {
      const original = this.myConferenceHallDetails.find((e) => e.confId == confId);
      if (original) {
        this.editConference(original);
        this.ignoreDirty = true;
        this.$router.push({ path: "/newconference/" });
      }
    },
    checkUsers(members) {
      let users = [];
      if (members && members.length > 0) {
        members.forEach((member) => {
          if (member.uuid && this.state.group[member.uuid]) {
            users.push(member);
          }
        });
        this.conferenceMembers = users;
      } else {
        this.conferenceMembers = members;
      }
    },
    loadExistingConference() {
      this.ignoreDirty = false;
      this.isUnlimited = this.conferenceToEdit.isUnlimited;
      this.conferenceName = this.conferenceToEdit.confName;
      this.conferenceId = this.conferenceToEdit.confId;
      this.conferenceDescription = this.conferenceToEdit.confDescription;
      this.personalizedGreeting =
        this.conferenceToEdit.confPersonalizedGreet || "";
      this.conferenceImage = this.conferenceToEdit.confAvatar;
      this.checkUsers(this.conferenceToEdit.members);

      (this.scheduledInterval = this.conferenceToEdit.confIsReoInterval || ""),
        (this.confFrequency = this.conferenceToEdit.confIsReoFrequency || 0),
        (this.startEvent = this.conferenceToEdit.confStartDate);
      this.timeEnd = this.isUnlimited
        ? null
        : this.conferenceToEdit.confEndTime;
      this.timeStart = this.conferenceToEdit.confStartTime;
      const conferenceISOStartDate = new Date(
        this.startEvent +
          "T" +
          this.timeStart
            .split(":")
            .map((e) => e.padStart(2, "0"))
            .join(":") +
          ":00" +
          (this.conferenceToEdit.confTimezone ||
            getTimezone(this.startEvent, this.timeStart))
      );
      const conferenceISOEndDate = this.isUnlimited
        ? null
        : new Date(
            this.startEvent +
              "T" +
              this.timeEnd
                .split(":")
                .map((e) => e.padStart(2, "0"))
                .join(":") +
              ":00" +
              (this.conferenceToEdit.confTimezone ||
                getTimezone(this.startEvent, this.timeEnd))
          );
      this.startEvent = this.moment(conferenceISOStartDate).format(
        "YYYY-MM-DD"
      );
      this.timeEnd = this.isUnlimited
        ? null
        : this.moment(conferenceISOEndDate).format("HH:mm");
      this.timeStart = this.moment(conferenceISOStartDate).format("HH:mm");
      this.durationMin = this.isUnlimited
        ? null
        : this.conferenceToEdit.confDuration;
      this.isUnlimited = this.conferenceToEdit.isUnlimited;
      this.pinCode = this.conferenceToEdit.confPin || "";
      this.enableYouTubeCall = this.conferenceToEdit.isYouTubeConf ? this.conferenceToEdit.isYouTubeConf : false;
      this.liveStreamIngestionInfo = this.conferenceToEdit.ytIngestionInfo
      this.liveStreamURL = this.conferenceToEdit.liveStreamURL
      this.liveStreamId = this.conferenceToEdit.liveStreamId
      this.liveBroadcastId= this.conferenceToEdit.liveBroadcastId
      this.rsvpValue = this.extractRSVP();
      this.syncSelector();
      if (this.isYouTubeCall) {
        this.checkIfUserCanStream();
      }
      const dateCounter = new Date(this.startEvent);
      dispatchFetchMyItemsForDateEvent(dateCounter.toISOString());
    },
    syncSelector() {
      // removes allready selected users groups or teams from dropDown
      if (!this.conferenceMembers) {
        this.goBackToGrid();
        return;
      }
      this.conferenceMembers.forEach((member) => {
        if (member.isTeam) {
          const extant = this.allTeams[member.name];
          if (extant) {
            this.allTeams[member.name] = undefined;
          }
        } else if (member.isGroup) {
          const extant = this.allGroups.find((e) => e.groupId === member.uuid);
          if (extant) {
            this.allGroups = this.allGroups.filter(
              (group) => group.groupId !== member.uuid
            );
          }
        } else {
          // users and guests
          this.allUsers[member.uuid] = undefined;
          delete this.allUsers[member.uuid];
        }
      });
    },
    handleScroll() {
      EventBus.$emit("closeMenu");
    },
    checkSendMessage(confMembers, confId) {
      const uuidArrays = [];
      confMembers.forEach((uuid) => {
        if (uuid !== this.state.ownUUID) {
          uuidArrays.push(uuid);
        }
      });
      this.setShowModalSendMsg(uuidArrays, confId);
    },
    async deletedMessageConf(extantEv, isTempConf, conf) {
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conference = {
        personalizedGreeting: conf.confPersonalizedGreet,
        confStartDate: conf.confStartDate,
        confStartTime: conf.confStartTime,
        inviter: store.state.user.name,
        confName: conf.confName,
        isUnlimited: conf.isUnlimited,
        durationMin: conf.confDuration,
      };
      const text = await getInviteTextForCancellation(tZone, conference, false);
      let subject = this.$t("components.invitePreview.inviteCancelSubject");
      const startEvent = this.moment(extantEv.start).format("YYYY-MM-DD");
      const timeStart = this.moment(extantEv.start).format("HH:mm");
      const mssgType = "conf-delete";
      const noInviteLink = true;
      const evName = conf.confName;
      const dates = {
        startEvent: startEvent,
        timeStart: timeStart,
      };

      let sendMsg = !conf.confIsTemp;
      this.justSendMailToAll(
        conf.confUUIDS,
        subject,
        text,
        mssgType,
        dates,
        evName,
        sendMsg,
        noInviteLink
      );
    },
    async deleteConferenceSafely(event, confId) {
      event.stopPropagation();
      event.preventDefault();
      let isTempConf = false;
      try {
        if (!this.deleteActive) {
          this.deleteActive = true;
          setTimeout(() => {
            this.deleteActive = false;
          }, 1500);
        } else {
          this.deleteActive = false;
          const extantEv = this.timelineEvents.find((e) => e.confId === confId);
          const extantConf = this.myConferenceHallDetails.find(
            (e) => e.confId === confId
          );
          if (extantConf) {
            isTempConf = extantConf.confIsTemp;
          }
          if (extantEv && extantEv.uuid) {
            await deleteTimelineItemEvent(extantEv.uuid);
          }
          if (confId) {
            // await this.deleteConferenceDocumentStore(confId);
            await dispatchDeleteConferenceHallEvent(confId);
          }
          dispatchSuccessAlert(
            this.$t("components.conferences.conferenceDeleted")
          );
          // only notify if conference has not occurred
          if (extantEv && extantEv.end) {
            const orgEnd = moment(new Date(extantEv.end));
            const elapsedMins = moment().diff(orgEnd, "minutes");
            if (elapsedMins < 3) {
              const tobeNotified = extantEv.users.filter(
                (item) => item != store.state.ownUUID
              );
              if (tobeNotified && tobeNotified.length) {
                await this.deletedMessageConf(extantEv, isTempConf, extantConf);
              }
            }
          }
          await this.resetSnapShots();
          this.goBackToGrid();
        }
      } catch (err) {
        this.deleteActive = false;
        console.warn("Error deleting group", err);
        dispatchErrorAlert("Error deleting group");
      }
    },
    async deleteConferenceHall(confId) {
      const extantEv = this.timelineEvents.find((e) => e.confId === confId);
      const extantConf = this.myConferenceHallDetails.find(
        (e) => e.confId == confId
      );
      if (extantEv && extantEv.uuid) {
        await deleteTimelineItemEvent(extantEv.uuid);
      }
      if (confId) {
        // await this.deleteConferenceDocumentStore(confId);
        await dispatchDeleteConferenceHallEvent(confId);
      }
      if (extantEv && extantEv.end) {
        const orgEnd = moment(new Date(extantEv.end));
        const elapsedMins = moment().diff(orgEnd, "minutes");
        if (elapsedMins < 3) {
          const tobeNotified = extantEv.users.filter(
            (item) => item != store.state.ownUUID
          );
          if (tobeNotified && tobeNotified.length) {
            await this.deletedMessageConf(
              extantEv,
              extantConf.isTemp,
              extantConf
            );
          }
        }
      }
      await this.resetSnapShots();
      this.goBackToGrid();
    },
    async deleteConferenceDocumentStore(confId) {
      const foundStore = this.myDocuments.find((e) => e.confId === confId);
      if (foundStore && foundStore.docStoreUUID) {
        await dispatchDeleteCompanyDocumentEvent(foundStore.docStoreUUID);
      } else {
        console.warn("Store not found for deletion");
      }
    },
    editConference(conference) {
      this.viewingConferences = false;
      this.isEditingConference = true;
      this.$nextTick(() => (this.conferenceToEdit = conference));
    },
    infiniteHandler($state) {
      if (this.sliceIndex < this.myConferences.length) {
        this.sliceIndex += 6;
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    selectGroup(groupId) {
      let extant = store.state.user.groups.find((e) => e.groupId == groupId);
      if (extant) {
        extant.groupMembers.forEach((member) => {
          this.addMember(member);
        });
      }
    },
    updateDurationMin(givenStart, givenEnd) {
      if (!givenStart || !givenEnd) return;
      const start = new Date(givenStart).getTime();
      const end = new Date(givenEnd).getTime();
      const diffMs = end - start;
      let diffMins = Math.floor(diffMs / 60000);
      if (diffMins < 0) {
        diffMins = 0;
      }
      this.durationMin = diffMins;
    },
    startChanged() {
      if (!this.startEvent || !this.timeStart) return;
      this.formattedStartDate = this.format_Date(this.startEvent);
      this.startMenu = false;
      if (this.timeEnd && this.timeStart) {
        const cStart = parseInt(this.timeStart.replace(":", ""));
        const cEnd = parseInt(this.timeEnd.replace(":", ""));
        if (cStart < cEnd) {
          this.endChanged();
        } else {
          this.timeEnd = null;
          this.durationMin = null;
        }
      }
    },
    endChanged() {
      if (!this.startEvent || !this.timeStart) return;
      this.endEvent = this.startEvent;
      if (!this.endEvent || !this.timeEnd) return;
      this.formattedEndDate = this.format_Date(this.endEvent);
      this.endMenu = false;
      const dateTimeStart =
        this.startEvent + "T" + this.zeroFill(this.timeStart);
      const dateTimeEnd = this.endEvent + "T" + this.zeroFill(this.timeEnd);
      this.updateDurationMin(dateTimeStart, dateTimeEnd);
    },
    recalcEnd(recalc = true) {
      if (!this.startEvent || !this.timeStart) return;
      const start = new Date(
        this.startEvent + "T" + this.zeroFill(this.timeStart)
      );
      const end = new Date(start.getTime() + this.durationMin * 60000);
      const endDate = `${end.getFullYear()}-${
        end.getMonth() + 1
      }-${end.getDate()}`.replace(/-(\d)(?=(?:-|$))/g, "-0$1");
      const endTime = `${
        end.getHours() < 10 ? "0" + end.getHours() : end.getHours()
      }:${end.getMinutes() < 10 ? "0" + end.getMinutes() : end.getMinutes()}`;

      if (recalc) {
        this.endEvent = endDate;
        this.timeEnd = endTime;
      }
    },
    hasUserRejected(uuid) {
      const timelineEv = this.timelineEvents.find(
        (e) => e.confId === this.conferenceToEdit.confId
      );
      if (!timelineEv) return false;
      const extant = timelineEv.rsvp[uuid] || {};
      if (extant == {}) return false;
      if (extant.status) {
        if (extant.status == "rejected") {
          return true;
        } else if (extant.status == "accepted") {
          return false;
        } else if (extant.status == "pending") {
          return false;
        } else {
          return false;
        }
      }
    },
    extractRSVP() {
      const timelineEv = this.timelineEvents.find(
        (e) => e.confId === this.conferenceToEdit.confId
      );
      if (!timelineEv) return null;
      const extant = timelineEv.rsvp[store.state.ownUUID] || {};
      if (extant == {}) return null;
      if (extant.status) {
        if (extant.status == "rejected") {
          return false;
        } else if (extant.status == "accepted") {
          return true;
        } else if (extant.status == "pending") {
          return null;
        } else {
          return null;
        }
      }
    },
    format_Date(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    cleanDate() {
      if (
        // this.isMyConference ||
        this.conferenceModerator == store.state.ownUUID
      ) {
        this.startEvent = "";
        this.formatedStart;
        this.timeEnd = null;
        this.timeStart = null;
        this.durationMin = null;
        document.getElementById("startDateMenu").blur();
      }
    },
    toggleModerator(user) {
      if (user.isModerator) {
        const extant = this.conferenceMembers.find((e) => e.uuid === user.uuid);
        extant.isModerator = false;
      } else {
        const extant = this.conferenceMembers.find((e) => e.uuid === user.uuid);
        extant.isModerator = true;
      }
    },
    closeGuestModal() {
      this.showAddGuestModal = false;
    },
    addMember(memberToAdd) {
      if (!this.isConferenceFull && this.state.group[memberToAdd.uuid]) {
        if (!this.conferenceMembers.find((e) => e.uuid === memberToAdd.uuid)) {
          this.conferenceMembers.push(memberToAdd);
          if (this.isEditingTemplate) {
            this.updateTemplate();
          }
        }
      }
    },
    async createDocStore(confObject) {
      if (this.tempDocumentStore && this.tempDocumentStore.documents.length) {
        this.tempDocumentStore.docMembersUUIDS = confObject.confUUIDS;
        this.tempDocumentStore.moderators = confObject.confModerators;
        this.tempDocumentStore.confId = confObject.confId;
        await newCompanyDocumentEvent(this.tempDocumentStore);
        this.tempDocumentStore = null;
      }
    },
    async saveConference(isTempConf = false, goBackToGrid = true) {
      // save conference
      await this.resetSnapShots();
      let transResults = false;
      let confObject = {};
      if (this.conferenceIsValid) {
        if (!this.timeEnd && (!this.durationMin || !this.durationMin == 0)) {
          this.isUnlimited = true;
        } else {
          this.isUnlimited = false;
        }
        try {
          confObject = {
            confId: this.conferenceId,
            confIsTemp: isTempConf,
            confName:
              typeof this.conferenceName == "string"
                ? this.conferenceName
                : this.conferenceName.groupName,
            confDescription: this.conferenceDescription,
            confOwner: store.state.user.uuid,
            confPersonalizedGreet: this.personalizedGreeting,
            confModerators: await this.getModerators(),
            confPin: this.pinCode,
            confStartDate: this.startEvent,
            confStartTime: this.timeStart,
            confEndDate: this.isUnlimited ? this.startEvent : this.endEvent,
            confEndTime: !this.timeEnd ? this.timeStart : this.timeEnd,
            confTimezone: getTimezone(this.startEvent, this.timeStart),
            confDuration: this.isUnlimited ? 0 : this.durationMin,
            isUnlimited: this.isUnlimited,
            confIsReocurring: this.isReOcurring,
            confIsReoInterval: this.scheduledInterval,
            confIsReoFrequency: this.confFrequency,
            members: this.conferenceMembers,
            confUUIDS: await this.extractUsers(store.state.user.uuid),
            confRealUsers: await this.extractOnlyUsers(store.state.user.uuid),
            creationServerTs: Date.now(),
            updatedServerTs: Date.now(),
            confAvatar: this.conferenceImage,
            timelineEventId: undefined,
            conferenceHall: true,
            isYouTubeConf: this.isYouTubeCall,
            ytIngestionInfo: this.liveStreamIngestionInfo,
            liveStreamURL: this.liveStreamURL,
            liveStreamId: this.liveStreamId,
            liveBroadcastId: this.liveBroadcastId
          };
          await newConferenceHallEvent(confObject);
          transResults = true;
          if (!isTempConf) {
            this.isChanging = true;
            if (goBackToGrid) this.goBackToGrid();
            dispatchSuccessAlert(this.$t("components.groups.templateCreated"));
          }
        } catch (err) {
          console.warn("Error creating conference", err);
          return dispatchErrorAlert(
            this.$t("components.conferences.errCreateConference")
          );
        }
      } else {
        return dispatchErrorAlert(
          this.$t("components.conferences.errCreateConference")
        );
      }

      // CREATE DOCUMENT STORE
      // await this.createDocStore(confObject);
      // CREATE TIMELINE EVENT TO ALL USERS
      if (transResults) {
        transResults = false;
        confObject.rsvp = true;

        const event = await this.createTimelineEntry(confObject);
        if (event) {
          this.createInvitations(confObject, event.uuid);
          try {
            transResults = true;
          } catch (error) {
            console.warn("Error sending conf email", error);
            return false;
          }
          return event;
        }
      }
    },
    zeroFill(time) {
      return ("0" + time).slice(-5);
    },
    getDurationPhrase(num) {
      let hours = num / 60;
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);

      if (rhours === 1 && rminutes === 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHourMinute", [
            rhours,
            rminutes,
          ]) + "."
        );
      if (rhours === 1 && rminutes > 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHourMinutes", [
            rhours,
            rminutes,
          ]) + "."
        );
      if (rhours > 1 && rminutes === 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHoursMinute", [
            rhours,
            rminutes,
          ]) + "."
        );
      if (rhours > 1 && rminutes > 1)
        return (
          this.$t(
            "components.invitePreview.inviteAllLineDurationHoursMinutes",
            [rhours, rminutes]
          ) + "."
        );
      if (rhours === 0 && rminutes === 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationMinute", [
            rminutes,
          ]) + "."
        );
      if (rhours === 0 && rminutes > 1)
        return (
          this.$t("components.invitePreview.inviteAllLineDuration", [
            rminutes,
          ]) + "."
        );
      if (rhours === 1 && rminutes === 0)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHour", [
            rhours,
          ]) + "."
        );
      if (rhours > 1 && rminutes === 0)
        return (
          this.$t("components.invitePreview.inviteAllLineDurationHours", [
            rhours,
          ]) + "."
        );
    },
    async createTimelineEntry(data) {
      const dateTimeStart =
        data.confStartDate + "T" + this.zeroFill(data.confStartTime);
      const dateTimeEnd =
        data.confEndDate + "T" + this.zeroFill(data.confEndTime);
      const tmpData = {
        confId: data.confId,
        name: data.confName,
        details: data.confDescription,
        isTemp: data.confIsTemp,
        users: data.confUUIDS,
        start: new Date(dateTimeStart),
        end: new Date(dateTimeEnd),
        color: "orange",
        type: "videoCall",
        host: data.confOwner,
        title: data.confName.trim(),
        eventType: "bridgeCall",
        isUnlimited: data.isUnlimited,
        isReOcurring: data.confIsReocurring || false,
        confIsReoInterval: data.confIsReoInterval || null,
        confIsReoFrequency: data.confIsReoFrequency || null,
        viewed: {},
        rsvp: true,
        conferenceHall: true,
        liveBroadcastId: this.liveBroadcastId || undefined
      };
      const meeting = await createTimelineEntryEffect(tmpData);
      return meeting;
    },
    async doUpdateConference() {
      if (this.conferenceIsValid) {
        let refConference = await this.myConferenceHallDetails.find(
          (e) => e.confId === this.conferenceId
        );
        let guestEmailTextForChange = "";
        let userEmailTextForChange = "";
        let conf = {};
        if (!this.timeEnd && !this.durationMin) {
          this.isUnlimited = true;
        } else {
          this.isUnlimited = false;
        }
        try {
          const confObject = {
            confId: this.conferenceId,
            confName: this.conferenceName,
            confDescription: this.conferenceDescription,
            confOwner: this.conferenceToEdit.confOwner,
            confPersonalizedGreet: this.personalizedGreeting,
            confModerators: await this.getModerators(),
            confPin: this.pinCode,
            confStartDate: this.startEvent,
            confEndDate: this.isUnlimited ? this.startEvent : this.endEvent,
            confEndTime: this.isUnlimited ? this.timeStart : this.timeEnd,
            confTimezone: getTimezone(this.startEvent, this.timeStart),
            confDuration: this.isUnlimited ? 0 : this.durationMin,
            confStartTime: this.timeStart,
            confIsReocurring: this.isReOcurring,
            confIsReoInterval: this.scheduledInterval,
            confIsReoFrequency: this.confFrequency,
            isUnlimited: this.isUnlimited,
            members: this.conferenceMembers,
            confUUIDS: await this.extractUsers(this.conferenceToEdit.confOwner),
            confRealUsers: await this.extractOnlyUsers(
              this.conferenceToEdit.confOwner
            ),
            creationServerTs: this.conferenceToEdit.creationServerTs,
            updatedServerTs: Date.now(),
            confAvatar: this.conferenceImage,
            timelineEventId: undefined,
            conferenceHall: this.conferenceToEdit.conferenceHall || true,
            ytIngestionInfo: this.conferenceToEdit.ytIngestionInfo || false,
            liveStreamURL: this.liveStreamURL || false,
            liveStreamId: this.liveStreamId || false,
            liveBroadcastId: this.liveBroadcastId || false,
            isYouTubeConf: this.isYouTubeCall
          };
          const extantEv = this.timelineEvents.find(
            (e) => e.confId === confObject.confId
          );
          if (extantEv && extantEv.uuid) {
            let isMeaningful = false;
            const newUsers = confObject.confUUIDS.filter(
              (e) => this.conferenceToEdit.confUUIDS.indexOf(e) === -1
            );
            // const oldUsers = this.conferenceToEdit.confUUIDS;
            await updateConferenceHallEvent(confObject);

            // UPDATE DOCUMENT STORE
            // await this.doUpdateDocumentStore(confObject);
            // NOTIFICATIONS
            if (refConference && refConference.confId) {
              const tZone =
                store.state.namespaceSettings.timezone || "Europe/Berlin";
              const oldConfStartDate = refConference.confStartDate;
              const oldConfStartTime = refConference.confStartTime;
              const asHtml = false;
              conf = {
                personalizedGreeting: this.personalizedGreeting,
                confStartDate: this.startEvent,
                confStartTime: this.timeStart,
                inviter: store.state.user.name,
                confName: this.conferenceName,
                isUnlimited: this.timeEnd ? false : true,
                durationMin: this.durationMin,
                confDescription: this.conferenceDescription,
                conferenceHall: true,
                liveBroadcastId: this.liveBroadcastId || undefined
              };
              guestEmailTextForChange = await getInviteTextGuestChange(
                tZone,
                conf,
                oldConfStartDate,
                oldConfStartTime,
                asHtml
              );
              userEmailTextForChange = await getInviteTextUserChange(
                tZone,
                conf,
                oldConfStartDate,
                oldConfStartTime,
                asHtml
              );
            }
            const dateTimeStart =
              confObject.confStartDate +
              "T" +
              this.zeroFill(confObject.confStartTime);
            const dateTimeEnd =
              confObject.confEndDate +
              "T" +
              this.zeroFill(confObject.confEndTime);
            const tmpData = {
              confId: confObject.confId,
              name: confObject.confName,
              details: confObject.confDescription,
              users: confObject.confUUIDS,
              start: new Date(dateTimeStart),
              end: new Date(dateTimeEnd),
              uuid: extantEv.uuid,
              isUnlimited: confObject.isUnlimited,
              isReOcurring: confObject.confIsReocurring || false,
              confIsReoInterval: confObject.confIsReoInterval || null,
              confIsReoFrequency: confObject.confIsReoFrequency || null,
              color: "orange",
              type: "videoCall",
              title: confObject.confName.trim(),
              eventType: "bridgeCall",
            };
            const timezone =
              store.state.namespaceSettings.timezone || "Europe/Berlin";
            const confStartMoment = this.moment(tmpData.start).tz(timezone);
            const confEndMoment = this.moment(tmpData.end).tz(timezone);

            const body = confObject.isUnlimited
              ? this.$t(
                  "components.notificationsModal.eventChangesTimezoneNoDuration",
                  [
                    confObject.confName.trim(),
                    confStartMoment.format("DD.MM.YYYY"),
                    confStartMoment.format("HH:mm"),
                    timezone,
                  ]
                )
              : this.$t("components.notificationsModal.eventChangesTimezone1", [
                  confObject.confName.trim(),
                  confStartMoment.format("DD.MM.YYYY"),
                  confStartMoment.format("HH:mm"),
                  timezone,
                  await this.getDurationPhrase(this.getDurationMin),
                  confEndMoment.format("HH:mm"),
                ]);
            const mssg = {
              body: body,
              header: {
                translateMessage: {
                  translateKey: confObject.isUnlimited
                    ? this.$t(
                        "components.notificationsModal.eventChangesTimezoneNoDuration",
                        [
                          confObject.confName.trim(),
                          confStartMoment.format("DD.MM.YYYY"),
                          confStartMoment.format("HH:mm"),
                          timezone,
                        ]
                      )
                    : this.$t(
                        "components.notificationsModal.eventChangesTimezone1",
                        [
                          confObject.confName.trim(),
                          confStartMoment.format("DD.MM.YYYY"),
                          confStartMoment.format("HH:mm"),
                          timezone,
                          await this.getDurationPhrase(this.getDurationMin),
                          confEndMoment.format("HH:mm"),
                        ]
                      ),
                },
              },
            };

            if (
              new Date(extantEv.start).getDate() !==
                new Date(tmpData.start).getDate() ||
              new Date(extantEv.start).getTime() !==
                new Date(tmpData.start).getTime() ||
              new Date(extantEv.end).getDate() !==
                new Date(tmpData.end).getDate() ||
              new Date(extantEv.end).getTime() !==
                new Date(tmpData.end).getTime()
            ) {
              isMeaningful = true;
            }
            await updateTimelineItemEvent([extantEv.uuid, tmpData, mssg]);
            if (isMeaningful) {
              setTimeout(() => {
                this.SendChangeEmail(
                  confObject,
                  extantEv.uuid,
                  guestEmailTextForChange,
                  userEmailTextForChange
                );
              }, 1000);
            }

            if (newUsers && newUsers.length) {
              newUsers.forEach((uuid) => {
                const person = store.state.group[uuid];
                if (person) {
                  if (person.permissions == 10) {
                    this.createGuestInvite(
                      person.user,
                      extantEv.uuid,
                      confObject
                    );
                  } else {
                    this.createUserInvite(
                      person.user,
                      extantEv.uuid,
                      confObject
                    );
                  }
                }
              });
            }
          }
          this.isChanging = true;
          this.deleteUserNotification();
          this.goBackToGrid();
          dispatchSuccessAlert(
            this.$t("components.conferences.conferenceUpdated")
          );
        } catch (err) {
          console.warn("Error creating conference", err);
          dispatchErrorAlert("Error updating conference");
        }
      } else {
        dispatchErrorAlert("Error updating conference");
      }
    },
    async SendChangeEmail(
      confObject,
      eventUUID,
      guestEmailTextForChange,
      userEmailTextForChange
    ) {
      if (confObject && confObject.confUUIDS && confObject.confUUIDS.length) {
        let gdata = {};
        let udata = {};
        const uuids = confObject.confUUIDS.filter(
          (e) => e !== store.state.ownUUID
        );
        await parallel(1, [...uuids], async (uuid) => {
          if (store.state.group[uuid].user.visitor) {
            gdata = {
              visitorId: uuid,
              name: store.state.group[uuid].user.name,
              email: store.state.group[uuid].user.email || "",
              mobilePhone: store.state.group[uuid].user.mobilePhone || "",
              inviteText: guestEmailTextForChange,
              subject: this.$t("components.invitePreview.inviteChangeSubject"),
              subjectDisplay: this.$t(
                "components.invitePreview.inviteChangeSubject"
              ),
            };
            await sendVisitorEmail(gdata, { eventUUID: eventUUID });
          } else {
            const user = store.state.group[uuid].user;
            udata = {
              inviteText: userEmailTextForChange,
              subject: this.$t("components.invitePreview.inviteChangeSubject"),
              subjectDisplay: this.$t(
                "components.invitePreview.inviteChangeSubject"
              ),
            };
            udata.variables = {};
            await sendUserEmail(user, udata, { eventUUID: eventUUID });
          }
        });
      }
    },
    getModerators() {
      let mods = [];
      this.conferenceMembers.forEach((member) => {
        if (member.isModerator === true) {
          mods.push(member.uuid);
        }
      });
      return mods;
    },
    extractOnlyUsers(owner) {
      const allUsers = this.extractUsers(owner);
      let onlyUsers = [];
      if (allUsers && allUsers.length) {
        onlyUsers = allUsers.filter(
          (e) => (store.state.group[e] || {}).permissions !== 10
        );
      }
      return onlyUsers;
    },
    extractUsers(owner) {
      let users = [];
      this.conferenceMembers.forEach((member) => {
        if (member.isTeam) {
          const extant =
            this.state.namespaceSettings.processedOrganisation.teams[
              member.name
            ];
          const tempMembers = extant.members;
          extant.supervisors.forEach((element) => {
            if (tempMembers.indexOf(element.uuid) == -1) {
              if (this.state.group[element.uuid]) {
                tempMembers.push(element.uuid);
              }
            }
          });
          tempMembers.forEach((id) => {
            if (users.indexOf(id) == -1) {
              if (this.state.group[id]) {
                users.push(id);
              }
            }
          });
        } else if (member.isGroup) {
          if (this.state.group[member.uuid]) {
            const extant = store.state.group[owner].user.groups.find(
              (e) => e.groupId == member.uuid
            );
            const extantMmembers = extant.groupMembers;
            const members = extantMmembers.map((member) => member.uuid);
            members.forEach((el) => {
              if (users.indexOf(el) == -1) {
                users.push(el);
              }
            });
          }
        } else if (member.uuid) {
          if (this.state.group[member.uuid]) {
            if (users.indexOf(member.uuid) == -1) {
              users.push(member.uuid);
            }
          }
        }
      });
      return users;
    },
    showMeDaMoney(a, b) {
      return Object.keys(a).filter((k) => a[k] !== b[k]);
    },
    handleSelection(event) {
      if (this.isConferenceFull) {
        this.searchInput = null;
        event.preventDefault();
        event.stopPropagation();
      }
      let name = "";
      const idx = event[event.length - 1];
      const user = this.allUsers[idx];
      if(idx && this.tobeNotifiedDeleteUser.indexOf(idx)!==-1){
        this.tobeNotifiedDeleteUser.splice(this.tobeNotifiedDeleteUser.indexOf(idx), 1)
      }
      if (user && user.user) {
        const uuid = idx;
        if (user.user.visitor && user.user.visitorData.visitorId) {
          // is visitor
          const dataObj = {
            isGuest: true,
            isModerator: false,
            name: user.user.name,
            userFunction: user.user.userFunction,
            uuid: user.user.visitorData.visitorId,
            isGroup: false,
            isTeam: false,
            isPodium: (this.participantsType == 0) ? true : false,
          };
          name = this.$t("components.conferenceForm.guestAdded", [
            user.user.name,
          ]);
          this.addMember(dataObj);
          this.allUsers[idx] = undefined;
          delete this.allUsers[idx];
          this.selectedDropDowItem = [];
        } else if (user.user && uuid) {
          // is user
          const dataObj = {
            isGuest: false,
            isModerator: false,
            name: user.user.name,
            userFunction: user.user.userFunction,
            uuid: uuid,
            isGroup: false,
            isTeam: false,
            isPodium: (this.participantsType == 0) ? true : false,
          };
          name = this.$t("components.conferenceForm.userAdded", [
            user.user.name,
          ]);
          this.addMember(dataObj);
          this.allUsers[idx] = undefined;
          delete this.allUsers[idx];
          this.selectedDropDowItem = [];
        }
      } else if (this.allGroups.findIndex((e) => e.groupName === idx) !== -1) {
        // is group
        const extant = this.allGroups.find((e) => e.groupName === idx);
        if (extant && extant.groupMembers) {
          extant.groupMembers.forEach((member) => {
            this.addMember(member);
          });
          name = this.$t("components.conferenceForm.groupAdded", [
            extant.groupName,
          ]);
          const newArray = this.allGroups.filter((e) => e.groupName !== idx);
          this.allGroups = newArray;
          this.selectedDropDowItem = [];
        }
      } else if (this.allTeams[idx] != undefined) {
        // is team
        const extant = this.allTeams[idx];
        if (extant && extant.members) {
          extant.members.forEach((uuid) => {
            if (
              (((store.state.group[uuid] || {}).user || {}).name || false) ===
              false
            ) {
              // skip no data in user
            } else {
              const user = store.state.group[uuid].user;
              const dataObj = {
                isGuest: false,
                isModerator: false,
                name: user.name,
                userFunction: user.userFunction || "",
                uuid: uuid,
                isGroup: false,
                isTeam: false,
                isPodium: user.visitorData ? false : true,
              };
              this.addMember(dataObj);
            }
          });
          if (extant && extant.supervisors) {
            const superv = extant.supervisors.map((e) => e.uuid);
            if (superv && superv.length) {
              superv.forEach((uuid) => {
                if (
                  (((store.state.group[uuid] || {}).user || {}).name ||
                    false) === false
                ) {
                  // skip no data in user
                } else {
                  const user = store.state.group[uuid].user;
                  const dataObj = {
                    isGuest: false,
                    isModerator: false,
                    name: user.name,
                    userFunction: user.userFunction || "",
                    uuid: uuid,
                    isGroup: false,
                    isTeam: false,
                    isPodium: user.visitorData ? false : true,
                  };
                  this.addMember(dataObj);
                }
              });
            }
          }
          name = this.$t("components.conferenceForm.teamAdded", [idx]);
          // remove from array and clear entry
          this.allTeams[idx] = undefined;
          delete this.allTeams[idx];
          this.selectedDropDowItem = [];
        }
      }
      dispatchSuccessAlert(name);
    },
    doUpdateDocumentStore(conf) {
      const foundStore = this.myDocuments.find((e) => e.confId === conf.confId);
      if (foundStore && foundStore.confId) {
        foundStore.docMembersUUIDS = conf.confUUIDS;
        foundStore.moderators = conf.confModerators;
        updateCompanyDocumentEvent(foundStore);
      } else {
        this.createDocStore(conf);
      }
    },
    async deleteUser(user) {
      if (!user) return false; // missing param
      if (!user.isGroup && !user.isTeam) {
        if (!user.visitor) {
          const extant = store.state.group[user.uuid] || false;
          if (!extant) return false;
          this.conferenceMembers = this.conferenceMembers.filter(
            (e) => e.uuid !== user.uuid
          );
          this.allUsers = { ...this.allUsers, [user.uuid]: extant };
          if (this.tobeNotifiedDeleteUser.indexOf(user.uuid) === -1) {
            this.tobeNotifiedDeleteUser.push(user.uuid);
          }
        } else {
          const extant = store.state.group[user.visitorData.visitorId] || false;
          if (!extant) return false;
          this.conferenceMembers = this.conferenceMembers.filter(
            (e) => e.uuid !== user.visitorData.visitorIduuid
          );
          this.allUsers = { ...this.allUsers, [user.uuid]: extant };
          if (this.tobeNotifiedDeleteUser.indexOf(user.uuid) === -1) {
            this.tobeNotifiedDeleteUser.push(user.uuid);
          }
        }
        this.totalMembers--;
      } else if (user.isGroup) {
        const extant = store.state.user.groups.find(
          (e) => e.groupId === user.uuid
        );
        if (extant) {
          this.allGroups.push(extant);

          const idx = this.conferenceMembers.findIndex(
            (e) => e.uuid === user.uuid
          );
          if (idx !== -1) {
            this.conferenceMembers.splice(idx, 1);
          }
          const groupM = extant.groupMembers.map((e) => e.uuid);
          groupM.forEach((uuid) => {
            if (this.tobeNotifiedDeleteUser.indexOf(uuid) === -1) {
              this.tobeNotifiedDeleteUser.push(user.visitorData.visitorId);
            }
          });
        }
      } else if (user.isTeam) {
        const extant =
          this.state.namespaceSettings.processedOrganisation.teams[user.name];
        if (extant) {
          this.allTeams[user.name] = extant;

          const idx = this.conferenceMembers.findIndex(
            (e) => e.name === user.name
          );
          if (idx !== -1) {
            this.conferenceMembers.splice(idx, 1);
          }
          let tempUsers = extant.members || [];
          if (extant.supervisors && extant.supervisors.length) {
            extant.supervisors.forEach((element) => {
              if (tempUsers.indexOf(element.uuid) == -1) {
                tempUsers.push(element.uuid);
              }
            });
          }
          tempUsers.forEach((uuid) => {
            if (this.tobeNotifiedDeleteUser.indexOf(uuid) === -1) {
              this.tobeNotifiedDeleteUser.push(user.visitorData.visitorId);
            }
          });
        }
      }
    },
    async deleteUserNotification() {
      if (this.tobeNotifiedDeleteUser && this.tobeNotifiedDeleteUser.length && this.conferenceToEdit) {
        const tobeNotifiedDeleteUser = this.tobeNotifiedDeleteUser;
        this.tobeNotifiedDeleteUser = [];
        const conferenceToEdit = this.conferenceToEdit;
        const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
        const conference = {
          personalizedGreeting: conferenceToEdit.confPersonalizedGreet,
          confStartDate: conferenceToEdit.confStartDate,
          confStartTime: conferenceToEdit.confStartTime,
          inviter: store.state.user.name,
          confName: conferenceToEdit.confName,
          isUnlimited: conferenceToEdit.isUnlimited,
          durationMin: conferenceToEdit.confDuration,
          confDescription: conferenceToEdit.confDescription,
        };
        const text = await getInviteTextForCancellation(
          tZone,
          conference,
          false
        );
        let subject = this.$t("components.invitePreview.inviteCancelSubject");
        const mssgType = "conf-delete";
        const evName = conferenceToEdit.confName;
        const dates = {
          startEvent: conferenceToEdit.confStartDate,
          timeStart: conferenceToEdit.confStartTime,
        };
        let sendMsg = !conferenceToEdit.confIsTemp;
        this.sendMailToAll(
          tobeNotifiedDeleteUser,
          subject,
          text,
          mssgType,
          evName,
          dates,
          sendMsg
        );
      }
    },
    async resetForm() {
      this.tobeNotifiedDeleteUser = [];
      this.ignoreDirty = false;
      this.showConfirmation = false;
      this.isUnlimited = true;
      this.isEditingConference = false;
      this.conferenceName = "";
      this.conferenceId = uuidv4();
      this.conferenceDescription = "";
      this.conferenceImage = this.state.namespaceSettings.companyLogo
        ? this.state.namespaceSettings.companyLogo
        : "/img/icon.png";
      this.durationMin = null;
      this.startEvent = "";
      this.endEvent = "";
      this.timeStart = null;
      this.timeEnd = null;
      this.conferenceMembers = [];
      this.selectedDropDowItem = [];
      this.scheduledInterval = "";
      this.confFrequency = 0;
      this.pinCode = "";
      this.isEditingTemplate = false;
      this.personalizedGreeting = "";
      this.allUsers = JSON.parse(JSON.stringify(this.state.group || {}));
      this.allTeams = JSON.parse(
        JSON.stringify(
          this.state.namespaceSettings.processedOrganisation.teams || {}
        )
      );
      this.allGroups = JSON.parse(JSON.stringify(this.state.user.groups || []));
      await this.populateDefaultModerator();
      await this.resetSnapShots();
    },
    populateDefaultModerator() {
      let extant = false;
      this.conferenceMembers.forEach((member) => {
        if (member.uuid === store.state.ownUUID) {
          extant = true;
        }
      });
      if (extant) return;
      const tmpObj = {
        name: store.state.user.name,
        uuid: store.state.ownUUID,
        isModerator: true,
        isGuest: false,
        userFunction: this.state.group[store.state.ownUUID].user.position || "",
        isGroup: false,
        isTeam: false,
        isPodium: true,
      };
      this.conferenceMembers.push(tmpObj);
    },
    clickOnAddCard(date = null) {
      if (
        this.$router.history.current.path !== "/newconference" &&
        this.$router.history.current.path !== "/newconference/"
      ) {
        this.$router.push({ path: "/newconference/" });
      }
      this.isInserting = true;
      this.ignoreDirty = false;
      this.viewingConferences = false;
      this.conferenceToEdit = null;
      this.isEditingConference = false;
      this.liveStreamIngestionInfo = null;
      this.liveStreamURL = false;
      this.liveStreamId = false;
      this.liveBroadcastId = false;
      this.canUserStreamInYT = false;
      this.enableYouTubeCall = false;
      if (!this.isPkg || this.isYouTubeCall) {
        this.loadGoogleLogin();
      }
      this.getInitSnapShot();
      if (date) {
        const fdate = new Date(parseInt(date));
        this.startEvent = this.moment(fdate).format("YYYY-MM-DD");
        this.initSnapShot.startEvent = this.startEvent;
      }
    },
    getInitSnapShot() {
      this.initSnapShot = {
        name: this.conferenceName == null ? "" : this.conferenceName,
        greet: this.personalizedGreeting,
        members: this.conferenceMembers.length || 0,
        start: this.startEvent,
        startTime: this.timeStart,
        end: this.timeEnd,
        desc: this.conferenceDescription,
        img: this.conferenceImage,
        pin: this.pinCode,
        docsTemp: this.tempDocumentStore,
      };
    },
    async getPostSnapShot() {
      this.postSnapShot = {
        name: this.conferenceName == null ? "" : this.conferenceName,
        greet: this.personalizedGreeting,
        members: this.conferenceMembers.length || 0,
        start: this.startEvent,
        startTime: this.timeStart,
        end: this.timeEnd,
        desc: this.conferenceDescription,
        img: this.conferenceImage,
        pin: this.pinCode,
        // docsTemp: this.tempDocumentStore,
      };
    },
    saveConferenceImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files || !files.length) return;
      var reader = new FileReader();
      reader.onload = (e) => {
        this.temporalImage = e.target.result;
        this.showCroppie = true;
      };
      reader.readAsDataURL(files[0]);
    },
    async uploadConferenceImage(id, img) {
      var location = await new Promise((resolve, reject) => {
        uploadConferenceImage(id, img).then(resolve).catch(reject);
      });
      var url = "/" + location + "?_=" + Date.now();
      this.conferenceImage = url;
      this.resetConferenceImageInput();
    },
    resetConferenceImageInput() {
      this.$refs.conferenceImageInput.value = "";
    },
    setConferenceImage() {
      this.$refs.conferenceImageInput.click();
    },
    async callNow() {
      // Loader
      this.isLoadingCall = true
      // Create YouTube broadcast&stream
      this.canUserStreamInYT = this.isYouTubeCall ? await this.generateYoutubeStream() : false
      // create temp object
      let tempConf;
      if (!this.conferenceToEdit) {
        const isTemp = true;
        this.isUnlimited = true;
        this.conferenceId = uuidv4();
        this.conferenceDescription = this.conferenceName + "-Temp";
        this.conferenceImage = this.state.namespaceSettings.companyLogo
          ? this.state.namespaceSettings.companyLogo
          : "/img/icon.png";
        const conferenceISOStartDate = new Date();
        this.startEvent = this.moment(conferenceISOStartDate).format(
          "YYYY-MM-DD"
        );
        this.endEvent = this.startEvent;
        this.timeStart = this.moment(conferenceISOStartDate).format("HH:mm");
        this.timeEnd = this.timeStart;
        this.durationMin = null;
        tempConf = await this.saveConference(isTemp);
      } else {
        tempConf = this.conferenceToEdit;
      }
      if (tempConf && tempConf.confId && ((this.canUserStreamInYT && this.isYouTubeCall) || !this.isYouTubeCall)) {
        const myConference = this.myConferenceHallDetails.find(
          (e) => e.confId == tempConf.confId
        );
        if (myConference && myConference.confId) {
          if (myConference.liveBroadcastId && this.liveBroadcastId && myConference.liveBroadcastId !== this.liveBroadcastId) {
            myConference.liveBroadcastId = this.liveBroadcastId;
          }
          if (myConference.liveStreamURL && this.liveStreamURL && myConference.liveStreamURL !== this.liveStreamURL) {
            myConference.liveStreamURL = this.liveStreamURL;
          }
          if (!this.isYouTubeCall) {
            myConference.liveBroadcastId = false;
            myConference.liveStreamURL = false;
          }
          this.callGroup(myConference, this.conferenceMembers);
          // send email to offline participants
          const timelineEv = this.timelineEvents.find(
            (e) => e.confId === myConference.confId
          );
          if (timelineEv) {
            const optionsSendMailCoferenceCall = {
              eventUUID : timelineEv.uuid
            }
            let usersUuid = this.conferenceMembers.map((user)=>user.uuid)
            this.sendMailConferenceCallOfflineUser(usersUuid, optionsSendMailCoferenceCall)
          }
        }
      }
    },
    async callEveryone(waitingRoomMode = false, conference = null) {
      // await this.doUpdateConference();
      this.resetSnapShots();
      if (conference && conference.confId) {
        this.conferenceToEdit = conference;
      }
      const extantEvent = this.timelineEvents.find(
        (e) => e.confId === this.conferenceToEdit.confId
      );
      if (this.conferenceToEdit.confDuration) {
        const duration = Math.floor(
          this.conferenceToEdit.confDuration * 60 * 1000
        );
        this.setdurationMeeting(duration);
      } else {
        const duration = Math.floor(1440 * 60 * 1000);
        this.setdurationMeeting(duration);
      }

      if (this.conferenceToEdit.confUUIDS) {
        let usersToCall = [];
        const usersFunction = [];
        const regUsers = [];
        const mobUsers = [];
        let doCall = true;
        const usersToHideName = [];
        this.$router.push({ path: "/home" });
        this.conferenceToEdit.confUUIDS.forEach((uuid) => {
          if (uuid !== this.state.ownUUID) {
            const isConnected =
              (store.state.group[uuid] || {}).connected || false;
            const person = store.state.group[uuid];
            if (
              person &&
              person.user &&
              !person.user.rtcCallStatus &&
              !person.user.inBridgeCall
            ) {
              if (!this.amInACall) {
                if (
                  isConnected &&
                  (person.user.activity || false) !== "Holidays" &&
                  (person.user.activity || false) !== "Out of Office" &&
                  (person.user.activity || false) !== "No Calls" &&
                  (person.user.activity || false) !== "Break" &&
                  this.hasUserRejected(uuid) == false
                ) {
                  if (person.user.isMobile) {
                    mobUsers.push(uuid);
                  } else {
                    regUsers.push(uuid);
                  }
                  if (!waitingRoomMode) {
                    usersToCall.push(uuid);
                  }
                  if (!usersFunction[uuid]) {
                    if (
                      this.state.group[uuid].user.position ||
                      this.state.group[uuid].user.visitorData?.function
                    ) {
                      usersFunction[uuid] =
                        this.state.group[uuid].user.position ||
                        this.state.group[uuid].user.visitorData.function;
                    } else {
                      usersFunction[uuid] = "";
                    }
                  }
                }
              }
            }
          } else {
            if (!usersFunction[uuid]) {
              if (
                this.state.group[uuid].user.position ||
                this.state.group[uuid].user.visitorData?.function
              ) {
                usersFunction[uuid] =
                  this.state.group[uuid].user.position ||
                  this.state.group[uuid].user.visitorData.function;
              } else {
                usersFunction[uuid] = "";
              }
            }
          }
        });
        this.conferenceMembers.forEach((member) => {
          if (member.showUserName == false) {
            usersToHideName.push(member.uuid);
          }
        });
        /*
        if (mobUsers.length && regUsers.length) {
          // SHOW POPUP MOBILES CANT BE CALLED
          dispatchErrorAlert(this.$t("components.conferenceForm.mobAdvice"));
          if (usersToCall.length) usersToCall = regUsers;
        } else if (!regUsers.length && mobUsers.length > 1) {
          // SHOW POPUP MOBILES CANT BE CALLED
          dispatchErrorAlert(this.$t("components.conferenceForm.mobAdvice"));
          if (usersToCall.length) doCall = false;
        }
        */
        if (doCall) {
          const id = uuidv4();
          const info = {
            callUUID: id,
            calling: usersToCall,
            isNormalMode:
              this.conferenceToEdit.confUUIDS.length > 0 ? true : false,
            initiator: this.state.ownUUID,
            callStartTs: Date.now(),
            callDurationMs: this.conferenceToEdit.isUnlimited
              ? 24 * 3600 * 1000
              : Math.floor(this.conferenceToEdit.confDuration * 60000) ||
                60000 * 20,
            infiniteCall: false,
            conferenceDocuments: [], // this.documentsPdf || [],//newGroupDate.pdfDocuments || [],
            userConferencePosition: {}, //usersFunction || {},
            excludedFromCalling: [], //usersToExclude || [],
            conferenceAttendees: this.getAttendeesObject(
              this.conferenceToEdit.confUUIDS || []
            ),
            confModerators: this.conferenceToEdit.confModerators || [],
            conferenceSettings: {}, //newGroupDate.settings || {},
            usersToHideName: usersToHideName || [],
            isConference: true,
            confId: this.conferenceToEdit.confId,
            isConferenceCall: this.conferenceToEdit.confId || undefined,
            conferenceHall: true,
            liveBroadcastId: this.liveBroadcastId || undefined
          };
          // let thereIsSomeoneToCall = false;
          usersToCall.forEach((user, idx) => {
            const infoStartBridgeCall = {
              callUUID: id,
              isNormalMode:
                this.conferenceToEdit.confUUIDS.length > 0 ? true : false,
              initiator: this.state.ownUUID,
              callStartTs: Date.now(),
              callDurationMs: this.conferenceToEdit.isUnlimited
                ? 24 * 3600 * 1000
                : Math.floor(this.conferenceToEdit.confDuration * 60000) ||
                  60000 * 20,
              infiniteCall: false,
              conferenceDocuments: [], // this.documentsPdf || [],//newGroupDate.pdfDocuments || [],
              userConferencePosition: {}, //usersFunction || {},
              excludedFromCalling: [], //usersToExclude || [],
              conferenceAttendees: this.getAttendeesObject(
                this.conferenceToEdit.confUUIDS || []
              ),
              confModerators: this.conferenceToEdit.confModerators || [],
              conferenceSettings: {}, //newGroupDate.settings || {},
              usersToHideName: usersToHideName || [],
              isConference: true,
              confId: this.conferenceToEdit.confId,
              isConferenceCall: this.conferenceToEdit.confId || undefined,
              isPinProtected: this.pinCode.length > 0,
              pinCode: this.pinCode.length > 0 ? this.pinCode : false,
              conferenceHall: true,
              liveBroadcastId: this.liveBroadcastId || undefined
            };
            if (!hasPrivilege(user)) {
              markAsCallWaitingRoomUserEvent(user);
            }
            wsCallStartBridgeCall(
              user,
              this.state.ownUUID,
              infoStartBridgeCall,
              idx > 0
            );
          });
          if (extantEvent.uuid) {
            //TODO: Revise not finding event.... when force update on callEveryone
            // dispatchColorChangeTimelineEntry([extantEvent.uuid, "red"]);
          }
          store.addRemoteBridgeStream(id, info);
          store.setCurrentContentVisile("", false, this.$router);
          // send email to offline participants
          const timelineEv = this.timelineEvents.find(
            (e) => e.confId === this.conferenceToEdit.confId
          );
          if (timelineEv){
            const optionsSendMailCoferenceCall = {
              eventUUID : timelineEv.uuid
            }
            this.sendMailConferenceCallOfflineUser(this.conferenceToEdit.confUUIDS, optionsSendMailCoferenceCall)
          }
        }
      }
    },
    getAttendeesObject(uuids) {
      let myMembers = [];
      uuids.forEach((uuid) => {
        if (uuid) {
          const aMember = {
            uuid,
            name: store.getNameForUuid(uuid),
            addUserToCall: true,
            showUserName: true,
            isPodium: this.getPodiumParticipantUUID(uuid)
          };
          myMembers.push(aMember);
        }
      });
      return myMembers;
    },
    setdurationMeeting(duration) {
      return store.setdurationMeeting(duration);
    },
    ///////////////////////////////////
    // CALLS SECTION  END
    //////////////////////////////////

    ///////////////////////////////////
    // EMAIL SECTION  START
    //////////////////////////////////
    async createInvitations(confObject = null, eventId) {
      if (confObject) {
        this.conferenceToEdit = confObject;
      }
      const filteredGuests = this.conferenceToEdit.confUUIDS.filter(
        (e) => store.state.group[e].user.visitorData
      );
      const realUsers = this.conferenceToEdit.confUUIDS.filter(
        (e) => !store.state.group[e].user.visitorData
      );
      // invite guests by mail
      for (const item of filteredGuests) {
        let tmpUserObj = {};
        let user = (store.state.group[item] || {}).user || false;
        if (user) {
          tmpUserObj = {
            name: user.name,
            uuid: user.uuid,
            email: store.state.group[user.uuid].user.email || "",
            mobilePhone: store.state.group[user.uuid].user.mobilePhone || "",
          };
          await setRemoteUser(user.uuid, {
            visitorData: {
              ...store.state.group[user.uuid].user.visitorData,
              linkDateFrom: this.formattedStartDate,
              linkTimeFrom: this.timeStart,
              linkDateTo: this.formattedStartDate,
              linkTimeTo: this.timeEnd,
            },
          });
          await this.createGuestInvite(tmpUserObj, eventId, confObject);
        }
      }
      // invite users by mail
      for (const item of realUsers) {
        let tmpUserObj = {};
        let user = (store.state.group[item] || {}).user || false;
        if (user && user.uuid !== store.state.ownUUID) {
          tmpUserObj = {
            name: user.name,
            uuid: user.uuid,
            email: store.state.group[user.uuid].user.email || "",
            mobilePhone: store.state.group[user.uuid].user.mobilePhone || "",
          };
          await this.createUserInvite(tmpUserObj, eventId, confObject);
        }
      }
      // send user invite by message
      if (realUsers && realUsers.length > 0) {
        const tmpUsers = [];
        realUsers.forEach(async (user) => {
          if (user !== this.state.ownUUID) {
            tmpUsers.push(user);
          }
          if (
            ((((store.state.group[user] || {}).user || {}).userSettings || {})
              .showVideoCallPlanner || true) == false
          ) {
            await setRemoteUser(user, {
              userSettings: { showVideoCallPlanner: true },
            });
          }
        });
        if (tmpUsers.length > 0 && !this.conferenceToEdit.confIsTemp) {
          const usersUuid = tmpUsers.filter(
            (uuid) => uuid !== this.state.ownUUID
          );
          this.sendUserMessage(
            usersUuid,
            "conf-invite",
            confObject.confName,
            eventId
          );
        }
        if (
          confObject.confUUIDS &&
          confObject.confUUIDS.length &&
          confObject.confUUIDS.length > 1
        ) {
          dispatchSuccessAlert(
            this.$t("components.conferenceForm.invitationsSent")
          );
        }
      }
    },
    doGetInviteTextForUser(asHtml = false) {
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conf = {
        personalizedGreeting: this.personalizedGreeting,
        confStartDate: this.startEvent,
        confStartTime: this.timeStart,
        inviter: store.state.user.name,
        confName: (this.isTemplate && this.conferenceName.groupName) ?  this.conferenceName.groupName : this.conferenceName,
        isUnlimited: this.timeEnd ? false : true,
        durationMin: this.durationMin,
        confDescription: this.conferenceDescription,
      };
      getInviteTextForUser(tZone, conf, asHtml).then((res) => {
        this.uInviteText = res;
        return res;
      });
    },
    doGetInviteTextForGuest(asHtml = false) {
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conf = {
        personalizedGreeting: this.personalizedGreeting,
        confStartDate: this.startEvent,
        confStartTime: this.timeStart,
        inviter: store.state.user.name,
        confName: (this.isTemplate && this.conferenceName.groupName) ?  this.conferenceName.groupName : this.conferenceName,
        isUnlimited: this.timeEnd ? false : true,
        durationMin: this.durationMin,
        confDescription: this.conferenceDescription,
      };
      return getInviteTextForGuest(tZone, conf, asHtml).then((res) => {
        this.gInviteText = res;
        return res;
      });
    },
    // user reminder invite
    async createUserReminderInvite(user, eventId, confObject) {
      const subject = this.$t("components.invitePreview.inviteRemSubject");
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conf = {
        personalizedGreeting:
          confObject.confPersonalizedGreet || this.personalizedGreeting,
        confStartDate: confObject.confStartDate,
        confStartTime: confObject.confStartTime,
        inviter: store.state.user.name,
        confName: confObject.confName || this.conferenceName,
        isUnlimited: confObject.isUnlimited,
        durationMin: confObject.confDuration,
        confDescription: confObject.confDescription,
      };
      getInviteTextForRem(tZone, conf, false).then((res) => {
        const odata = {
          inviteText: res,
          subject: subject,
          subjectDisplay: subject,
        };
        odata.variables = {};
        return sendUserEmail(user, odata, { eventUUID: eventId });
      });
    },
    // user invite mail
    async createUserInvite(user, eventId, confObject) {
      const subject = this.$t(
        "components.inviteVisitor.emailConferenceSubject"
      );
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conf = {
        personalizedGreeting:
          confObject.confPersonalizedGreet || this.personalizedGreeting,
        confStartDate: confObject.confStartDate,
        confStartTime: confObject.confStartTime,
        inviter: store.state.user.name,
        confName: confObject.confName || this.conferenceName,
        isUnlimited: confObject.isUnlimited,
        durationMin: confObject.confDuration,
        confDescription: confObject.confDescription,
      };
      getInviteTextForUser(tZone, conf, false).then((res) => {
        const odata = {
          inviteText: res,
          subject: subject,
          subjectDisplay: subject,
        };
        odata.variables = {};
        return sendUserEmail(user, odata, { eventUUID: eventId });
      });
    },
    // guest invite mail
    async createGuestInvite(guestObject, meetingId, confObject) {
      const subject = this.$t(
        "components.inviteVisitor.emailConferenceSubject"
      );
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conf = {
        personalizedGreeting:
          confObject.confPersonalizedGreet || this.personalizedGreeting,
        confStartDate: confObject.confStartDate,
        confStartTime: confObject.confStartTime,
        inviter: store.state.user.name,
        confName: confObject.confName || this.conferenceName,
        isUnlimited: confObject.isUnlimited,
        durationMin: confObject.confDuration,
        confDescription: confObject.confDescription,
      };
      getInviteTextForGuest(tZone, conf, false).then((res) => {
        const odata = {
          visitorId: guestObject.uuid,
          name: guestObject.name,
          email: guestObject.email || "",
          mobilePhone: guestObject.mobilePhone || "",
          inviteText: res,
          subject: subject,
          subjectDisplay: subject,
        };

        if (!guestObject.email && guestObject.mobilePhone) {
          odata.inviteText =
            this.state.user.name + " " + this.virtualOfficePrefix + "\n";
          odata.inviteText +=
            this.state.namespaceSettings.customVisitorWhatsAppInviteText ||
            this.$t("components.inviteVisitor.whatsappText").join("\n");
        }
        odata.variables = {};
        return sendVisitorEmail(odata, { eventUUID: meetingId });
      });
    },
    // guest invite reminder
    async createGuestReminderInvite(guestObject, meetingId, confObject) {
      const subject = this.$t("components.invitePreview.inviteRemSubject");
      const tZone = store.state.namespaceSettings.timezone || "Europe/Berlin";
      const conf = {
        personalizedGreeting:
          confObject.confPersonalizedGreet || this.personalizedGreeting,
        confStartDate: confObject.confStartDate,
        confStartTime: confObject.confStartTime,
        inviter: store.state.user.name,
        confName: confObject.confName || this.conferenceName,
        isUnlimited: confObject.isUnlimited,
        durationMin: confObject.confDuration,
        confDescription: confObject.confDescription,
      };
      getInviteTextForRem(tZone, conf, false).then((res) => {
        const odata = {
          visitorId: guestObject.uuid,
          name: guestObject.name,
          email: guestObject.email || "",
          mobilePhone: guestObject.mobilePhone || "",
          inviteText: res,
          subject: subject,
          subjectDisplay: subject,
        };

        if (!guestObject.email && guestObject.mobilePhone) {
          odata.inviteText =
            this.state.user.name + " " + this.virtualOfficePrefix + "\n";
          odata.inviteText +=
            this.state.namespaceSettings.customVisitorWhatsAppInviteText ||
            this.$t("components.inviteVisitor.whatsappText").join("\n");
        }
        odata.variables = {};
        return sendVisitorEmail(odata, { eventUUID: meetingId });
      });
    },
    ///////////////////////////////////
    // EMAIL SECTION  END
    //////////////////////////////////
    async sendUserMessage(uuids, type, title, eventId) {
      const timezone =
        store.state.namespaceSettings.timezone || "Europe/Berlin";
      const confDate =
        this.startEvent && this.timeStart
          ? new Date(
              this.startEvent +
                "T" +
                this.timeStart
                  .split(":")
                  .map((e) => e.padStart(2, "0"))
                  .join(":") +
                ":00" +
                getTimezone(this.startEvent, this.timeStart)
            )
          : new Date();
      const confMoment = this.moment(confDate).tz(timezone);

      if (uuids.length > 0) {
        let notificationMssg = "";
        let header = "";
        if (type == "conf-invite") {
          const dateParam =
            `${confMoment.format("DD.MM.YY, HH:mm")}` +
            " " +
            this.$t("components.meetingLineModal.hours") +
            " " +
            `(${timezone})`;
          notificationMssg = this.$t(
            "components.addConferenceModal.headerConferenceInvite",
            [title, store.state.group[store.state.ownUUID].user.name, dateParam]
          );
          header = {
            meetingId: eventId,
            typeMsg: "newConference",
            translateMessage: {
              translateKey:
                "components.addConferenceModal.headerConferenceInvite",
              params: [
                title,
                store.state.group[store.state.ownUUID].user.name,
                dateParam,
              ],
            },
          };
        }
        if (uuids && uuids.length) {
          uuids = uuids.filter((uuid) => uuid !== store.state.ownUUID);
          await parallel(1, [...uuids], async (user) => {
            const dataMsg = prepadeDataToSendMessageEffector(
              user,
              notificationMssg +
                " " +
                this.$t("components.meetingLineModal.hours"),
              header,
              "plannerEvent",
              true
            );
            newMessageEvent(dataMsg);
          });
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.participantsScroll {
  height: calc(100vh - 40vh);
}
.participantsButMobile {
  background-color: white;
  height: 36px;
}
.participantsBut {
  background-color: white;
  position: absolute;
  top: -62px;
  right: 2px;
  height: 36px;
}
.theme--dark .participantsButMobile, .theme--dark .participantsBut {
  background-color: #313131;
}
.center {
  margin: 0 auto;
}
.rowUsersConference {
  flex-direction: row;
  flex-wrap: wrap;
}
.row.justifyRight {
  justify-content: flex-end;
  height: 100%;
}
.maxWidth35 {
  // flex: 0 0 35% !important;
  // max-width: 35% !important;
  height: 270px;
}
.maxWidth33 {
  flex: 0 0 33% !important;
  max-width: 33% !important;
}
.maxWidth25 {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}
.maxWidth20 {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}
.uploadGroupImage {
  position: absolute;
  bottom: 8px;
  left: 12px;
}
.borderRadius-4 {
  border-radius: 4px;
}
.theme--light.v-btn.list {
  color: rgb(117, 117, 117);
}
.list {
  text-transform: none;
  font-weight: 300;
  padding-left: 5px !important;
  letter-spacing: 0;
  font-size: 16px !important;
}
.no-bg {
  background-color: transparent !important;
}
.deleteBasketButtonNormal {
  color: rgb(117, 117, 117) !important;
}
.deleteBasketButtonActive {
  color: red !important;
  font-weight: 600 !important;
}
.justStart {
  justify-content: flex-start;
}
.confImage {
  max-width: 160px;
  max-height: 125px;
}
.v-select__selection {
  display: none;
}
.w-100 {
  width: 100%;
}
.heightScroll {
  min-height: calc(100% - 100px);
  max-height: calc(100% - 100px);
  overflow: auto;
}
.userList {
  overflow: auto;
  max-height: 100%;
  position: relative;
}
.titleUserDiv {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
@media (max-width: 959.9px) {
  .userDiv {
    width: 100%;
  }
}
@media (min-width: 960px) {
  .userDiv {
    width: 50%;
  }
}
.userDiv {
  display: inline-block;
  text-align: left;
  padding: 12px;
}

.userAvatar {
  height: 40px;
  width: 40px;
  display: inline-block;
  vertical-align: middle;
}

.userName {
  vertical-align: middle;
}

.hoverDiv {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  display: table;
  text-align: center;
  z-index: 1;
  background: #78787854;

  & div {
    height: 100%;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    svg {
      font-size: 30px;
      color: white;
    }
  }
}

.cursorPointer {
  cursor: pointer;
}

.buttonAdd {
  font-size: 49px;
  color: var(--v-primary-base);
}

.divAdd {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.subTitleCard {
  color: rgb(255, 255, 255) !important;
  margin-top: -6px !important;
}

.theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}

.theme--dark .gradientBg {
  background: linear-gradient(#00000099, #000000cc);
}

.gradientBg {
  // min-height: 45px;
  // max-height: 45px;
  position: relative;
}

.buttonFlip {
  font-size: 20px;
  width: 1.25em;
}

.customFontSize {
  font-size: 13px !important;
  word-break: break-word;
  max-width: 85%;
}

.colorWhite {
  color: white !important;
}

/*.theme--dark.v-btn.v-btn--disabled {
  color: #00000042 !important;
}*/

.buttonCallFrontCard {
  background: white;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  // padding: 2px !important;
  font-size: 0px !important;
  // position: relative !important;
  // right: -2px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.divIconUserOnline {
  text-align: center;
  height: 25px;
  width: 25px;
  background: white;
  border-radius: 50%;
  display: table;

  & .frontOnline {
    width: 17px;
    vertical-align: middle;
    margin-top: 4px !important;
  }
}

.iconCardsContainerBlack {
  position: absolute;
  top: 0;
  right: 0;
  // background: #00000054;
  background: unset;
  height: 120px;
}

.iconCardsContainer {
  position: absolute;
  right: 0;
  top: 0;
  height: 28px;
}
.contentGridWrapper {
  height: calc(100vh - 230px);
  overflow-y: visible;
}

.custom6cols {
  width: 16%;
  flex-basis: 16%;
}

.cardWidth {
  max-width: 235px;
  box-shadow: none !important;
}

@media (max-width: 765px) {
  .contentGridWrapper .custom6cols {
    width: 50%;
    min-width: 50%;
  }
}

// 6 columns
@media (max-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}

@media (min-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .contentGridWrapper .custom6cols {
    width: 20% !important;
    flex-basis: 20% !important;
  }
}

@media (max-width: 890px) {
  .contentGridWrapper .custom6cols {
    width: 25% !important;
    flex-basis: 25% !important;
  }
}

@media (min-width: 765px) and (max-width: 1024px) {
  .cardWidth {
    max-width: 175px !important;
    min-width: 175px !important;
  }
}

.footerCard {
  background: white;
  padding: 0 !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.v-card__subtitle {
  color: black !important;
}

.v-card__title {
  font-size: 15px !important;
}

.opactity0 {
  opacity: 0;
}

.flipButton {
  padding: 0 5px;
  position: absolute;
  right: 5px;
  bottom: 13px;
  color: black;
  cursor: pointer;
  font-size: 22px;
}
</style>
<style lang="scss">
.timePicker input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    pointer-events: none;
}
.v-list-item__content {
  padding: 0px !important;
}
.frequencyDenominator {
  max-width: 70px !important;
}
.frequencyInput {
  max-width: 28px !important;
}
.frequencyButton {
  max-width: 30px;
}
.scheculedConf {
  .v-list-group__header {
    padding: 0px;
    min-height: unset;
  }
  .v-list-item--link:before {
    background-color: transparent !important;
  }
}
.selectorBack {
  width: 100%;
  background-color: #e4f4f6;
  margin: 3px;
  padding-left: 7px;
}
.theme--dark .selectorBack {
  background-color: #e4f4f61a;
}
.borderError {
  fieldset {
    border-color: red !important;
  }
}
.w-90 {
  width: 90%;
}
.redSpan {
  color: var(--v-error-base);
}
.logoAvatar {
  position: relative;
  top: 10px;
}
.theme--dark .deleteBasketButtonNormal {
  color: white !important;
}
</style>