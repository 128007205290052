<template>
  <div class="pa-0 col-xl-2 col-lg-2 col-md-3 col-sm-3 px-2 d-flex justify-end white">
    <div class="">
      <v-tooltip bottom v-if="amIModerator">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" class="mx-1" x-small v-on="on" @click="openConferenceDocumentsModal()">
            <span class="hidden-md-and-down pl-1 mr-3">{{$t('components.conferenceForm.addpdfConference')}}</span>
            <font-awesome-icon
              :icon="['fal', 'plus']"
              :style="{ fontSize: '12px' }"
            />
          </v-btn>
        </template>
        <span>{{$t('components.conferenceForm.addpdfConference')}}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!amIModerator">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" class="mx-1" x-small v-on="on" @click="openConferenceDocumentsModal()">
            <span class="hidden-md-and-down pl-1 mr-3">{{$t('components.conferenceForm.PDFDoc')}}</span>
            <font-awesome-icon
              :icon="['fal', 'plus']"
              :style="{ fontSize: '12px' }"
              class="d-lg-none"
            />
          </v-btn>
        </template>
        <span>{{$t('components.conferenceForm.PDFDoc')}}</span>
      </v-tooltip>
    </div>
    <ConferenceDocuments v-if="showConferenceDocumentsModal" :showModal="showConferenceDocumentsModal" :amIModerator="amIModerator"/>
  </div>
</template>

<script>
import store from "../../store";
import {
  fetchEventByUUIDEffect,
  timelineEvents,
} from "../../effector/timeline";
import {setConferenceDocumentsEvent, conferenceDocumentsStore} from "../../effector/modals"
import ConferenceDocuments from "./conferenceDocuments.vue"
export default {
  props: ["isConferenceCall", "amIModerator"],
  components:{
    ConferenceDocuments
  },
  data() {
    return {
      state: store.state,
      dataConference: null,
    };
  },
  watch: {},
  effector: {
    cacheTimeline: timelineEvents,
    showConferenceDocumentsModal: conferenceDocumentsStore
  },
  mounted() {},
  destroyed() {},
  methods: {
    openConferenceDocumentsModal(){
      setConferenceDocumentsEvent(this.isConferenceCall)
    },
  },
  computed: {

  },
};
</script>

<style  lang="scss">
</style>