var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chatContainer"},[_c('div',{staticClass:"d-flex justify-space-between align-baseline w100 pt-2 pl-4 pr-3"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"title white--text"},on),[_vm._v("\n          "+_vm._s(_vm.$t("components.callsContent.groupChat"))+"\n        ")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.callsContent.groupChatTT")))])]),_vm._v(" "),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.toggleChatMessageNotificationSound()}}},on),[(_vm.chatMessageNotificationSound)?_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'volume-up'],"color":"white"}}):_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'volume-slash'],"color":"white"}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.chatMessageNotificationSound ? _vm.$t("components.callsContent.muteChatNotifications") : _vm.$t("components.callsContent.unmuteChatNotifications")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.toggleOpenChat()}}},on),[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'times'],"color":"white"}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.callsContent.closeGroupChat")))])])],1)],1),_vm._v(" "),_c('v-col',{staticClass:"flex-grow-1 flex-shrink-0 containerMessages",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"d-flex flex-column fill-height backgroundCard",attrs:{"flat":""}},[_c('v-card-text',{ref:"chatMessageContainer",staticClass:"pl-1 pr-3 flex-grow-1 overflow-y-auto chatMessageContainer"},[_vm._l((_vm.chatMessages),function(message,index){return [_c('div',{key:index,class:{
              'd-flex flex-row-reverse': message.creatorUUID === _vm.ownUUID,
            }},[_c('v-card',{staticClass:"px-2 pt-3 pb-0 mb-2 white--text cardMessage",attrs:{"color":message.creatorUUID === _vm.ownUUID ? '#484a4f' : '#242528',"label":""}},[(
                  message.creatorUUID !== _vm.ownUUID &&
                  (index == 0 ||
                    (index > 0 &&
                      _vm.chatMessages[index - 1].creatorUUID !==
                        message.creatorUUID))
                )?_c('v-row',[_c('v-col',{staticClass:"\n                    pt-0\n                    pb-2\n                    text-left\n                    align-self-start\n                    font-weight-bold\n                    text-truncate\n                  ",attrs:{"cols":"12"}},[_vm._v("\n                  "+_vm._s(_vm.getTitelForUuid(message.creatorUUID))+"\n                  "+_vm._s(_vm.getNameForUuid(message.creatorUUID))+"\n                ")])],1):_vm._e(),_vm._v(" "),_c('div',{class:_vm.isOnlyOneEmoji(message.messageText)
                    ? 'messageContentEmoji'
                    : null,staticStyle:{"white-space":"pre-line"}},[_c('div',{staticClass:"messageText",domProps:{"innerHTML":_vm._s(_vm.checkMessage(message.messageText))}})]),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-1 text-right align-self-end",attrs:{"cols":"12"}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.dateFormatted(message.date)))])])],1)],1)],1)]})],2)],1)],1),_vm._v(" "),_c('v-col',{staticClass:"containerInputMessage",attrs:{"cols":"12","id":"containerInputMessage "}},[_c('v-textarea',{staticClass:"inputChatText",attrs:{"rows":"1","counter":"250","auto-grow":"","dark":"","autofocus":"","outlined":"","single-line":"","maxlength":"250","autocomplete":"off","dense":"","label":_vm.$t('components.notificationsModal.typeAMessage')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.textToSend.trim().length > 0 ? _vm.sendMessage() : null}},model:{value:(_vm.textToSend),callback:function ($$v) {_vm.textToSend=$$v},expression:"textToSend"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"disabled":_vm.textToSend.trim().length === 0,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.textToSend.trim().length === 0
                  ? 'sendColorBtnDisabled'
                  : 'sendColorBtn',attrs:{"x-small":"","disabled":_vm.textToSend.trim().length === 0,"height":30},on:{"click":function($event){return _vm.sendMessage()}}},on),[_c('font-awesome-icon',{style:({ fontSize: '18px' }),attrs:{"icon":['fal', 'paper-plane'],"color":"primary"}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.groups.sendMessage")))])])],1),_vm._v(" "),_c('template',{slot:"prepend-inner"},[_c('EmojiPicker',{attrs:{"selectEmoji":_vm.selectEmoji}})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }