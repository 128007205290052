<template>
  <v-img
    @click="setUserInModal ? setUserInModal() : null"
    class="borderRadius4 cursorPointer"
    src="img/default_profile_picture.png"
    :contain="getPictureSize"
    lazy-src="assets/default_profile_picture.png"
    :class="{
      'mx-2': !mini,
      borderRadius50: mini && isMobile,
      borderRadius4: !isMobile,
    }"
  ></v-img>
</template>

<script>
import store from "../../store";
import { getPictureSize } from "../../utils/basicFunctions";
import { isMobile } from "../../lib/mobileUtil";

export default {
  props: ["setUserInModal", "userData", "size", "lowerText", "mini"],
  components: {},
  data: () => ({
    state: store.state,
    isMobile: isMobile(),
  }),
  methods: {
    firstNameLetter() {
      if (!this.userData.name) return "";
      const firstName = this.userData.name.split(" ");
      if (firstName[0]) {
        return firstName[0].charAt(0);
      } else {
        return "";
      }
    },
    firstSurnameLetter() {
      if (!this.userData.name) return "";
      const secondName = this.userData.name.split(" ");
      if (secondName[1]) {
        return secondName[1].charAt(0);
      } else {
        return "";
      }
    },
  },
  computed: {
    getPictureSize() {
      return getPictureSize();
    },
    guestPending() {
      if (this.userData.rtcCallStart === undefined && this.userData.guest) {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.borderRadius5 {
  border-radius: 5px !important;
}
.borderRadius4 {
  border-radius: 4px !important;
}
</style>

