<template>
  <v-card flat class="mx-auto" width="100%">
    <UserListItem flat :person="getInviterPerson" :section="'Users'" />
  </v-card>
</template>

<script>
import store from "../../store";
import UserListItem from "./userListItem.vue";
export default {
  props: ["active", "setList", "mini"],
  components: {
    UserListItem,
  },
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
  }),
  watch: {
    $route(to, from) {
      if (to.path !== "/messages") {
        store.setSubordinateUser("");
      }
      store.state.user.activeGroupName = "";
    },
  },
  computed: {
    getInviterPerson() {
      const inviterPerson = (Object.keys(this.state.user.inviters || {}) || [])
        .concat(
          Object.keys((this.state.user.visitorData || {}).inviters || {}) || []
        )
        .concat([(this.state.user.visitorData || {}).userInviter])
        .filter(
          (uuid) =>
            !!uuid && this.state.group[uuid] && this.state.group[uuid].user
        )
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((uuid) => {
          const person = this.state.group[uuid];
          if (!person || !person.user) return null;
          return { ...person, uuid };
        })
        .filter((v) => !!v);

      if (inviterPerson[0] && this.state.group[inviterPerson[0].uuid]) {
        let result = this.state.group[inviterPerson[0].uuid];
        result.uuid = inviterPerson[0].uuid;
        result.user.uuid = inviterPerson[0].uuid;
        return result;
      } else {
        return undefined;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.select-box {
  background-color: var(--v-primary-base) !important;
}

.v-list {
  padding: 0;
}

.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item__title {
  line-height: 2.5 !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-expansion-panel-header {
  padding: 0px 25px 0px 16px !important;
}
.v-expansion-panel-header__icon {
  min-width: 48px !important;
  margin-right: 8px !important;
}
</style>

