import Vue from 'vue';
import PayPalInstance from './components/payment/paypalInstance.vue';
import vuetify from './vuetify';

let inited = false;
export const initLicenseExpired = () => {
  if (inited) return;
  inited = true;
  try {
    PayPalInstance.vuetify = vuetify;
    const root = new Vue({
      render: createElement => createElement(PayPalInstance)
    });
    root.$mount('#license_anchor');
  } catch (err) {
    console.warn('Init Vue Error:', err);
  }
};