<template>
  <ConferenceTable
    :headers="headers"
    :users="loadDataTable"
    :itemsPerPage="itemsPerPage"
    :getItemPerPage="getItemPerPage"
  />
</template>

<script>
import moment from "../../../../../sharedsrc/moment";
import store from "../../../../store";
import ConferenceTable from "./conferenceTable.vue";
import {
  dispatchFetchMyItemsForDateEvent,
  timelineStats,
} from "../../../../effector/timeline";
export default {
  components: { ConferenceTable },
  data() {
    return {
      state: store.state,
      itemsPerPage: store.state.persisted.itemsPerPageStatus,
    };
  },
  beforeMount() {
    this.getStatsConference();
  },
  effector: {
    timelineStats: timelineStats,
  },
  methods: {
    getStatsConference() {
      const dateCounter = new Date(moment().subtract(7, "d"));
      const endDate = new Date();
      // eslint-disable-next-line no-unmodified-loop-condition
      while (dateCounter <= endDate) {
        dispatchFetchMyItemsForDateEvent(dateCounter.toISOString());
        dateCounter.setDate(dateCounter.getDate() + 1);
      }
    },
    getItemPerPage(val) {
      this.itemsPerPage = val;
      this.state.persisted.itemsPerPageStatus = this.itemsPerPage;
    },
    getUserNames(usersArray) {
      let fullString = "";
      for (let i = 0; i < usersArray.length; i++) {
        const userUUID = usersArray[i];
        fullString += (i > 0 ? ", " : "") + this.getFullNameForUuid(userUUID);
      }
      return fullString;
    },
    getFullNameForUuid(uuid) {
      return this.getTitelForUuid(uuid) + " " + this.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
  },
  computed: {
    loadDataTable() {
      let tmpData = this.getTimelineStats.map((data) => {
        let tmp = {
          dateConference: `${moment(new Date(data.start)).format("DD.MM.YY - H:mm")} ${this.$t("components.meetingLineModal.hours")}`,
          ownerConference: this.getFullNameForUuid(data.creatorUUID),
          nameConference: data.name || data.title,
          userConference: this.getUserNames(data.users),
          users: data.users
        };
        return tmp;
      });
      return tmpData;
    },
    getTimelineStats() {
      return this.timelineStats;
    },
    headers() {
      return [
        {
          text: this.$t("components.stats.date"),
          value: "dateConference",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.stats.conferenceOwner"),
          value: "ownerConference",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.name"),
          value: "nameConference",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.inviteVisitor.participants"),
          value: "userConference",
          sortable: false,
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.nachricht"),
          value: "sendMessage",
          sortable: false,
          class: "font-weight-bold subtitle-1",
        },
      ];
    },
  },
};
</script>