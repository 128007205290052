<template>
  <v-dialog
    persistent
    v-model="showCroppie"
    max-width="570"
    @keydown.esc="cancelCrop()">
  <v-card v-show="croppieLoaded">
    <HeaderModal
      :titleModal="isConference ? $t('components.groups.uploadConferenceImage') : $t('components.groups.uploadGroupImage')"
      :closeModalFunction="cancelCrop"
    />
    <v-row class="d-flex mx-auto pt-5 pb-2 pl-3">
      <v-col cols="12" class="py-0">
        <vue-croppie
          ref="croppieRefSquare"
          class="sliderSize"
          :enableResize="false"
          :enforceBoundary="true"
          :enableExif="true"
          :boundary="{ width: 256, height: 256 }"
          :viewport="{ width: 246, height: 246, type: 'square' }"
        ></vue-croppie>
      </v-col>
      <div class="rotateIcons">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="grey" @click="rotate(-90)">
              <font-awesome-icon
                :icon="['fal', 'undo']"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.navbar.rotateLeft") }}</span>
        </v-tooltip>
        <br />
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="grey" @click="rotate(90)">
              <font-awesome-icon
                :icon="['fal', 'undo']"
                :style="{ fontSize: '20px' }"
                class="fa-flip-horizontal"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.navbar.rotateRight") }}</span>
        </v-tooltip>
      </div>
    </v-row>
    <v-card color="primary" class="py-2 pl-4 footerModal">
      <v-row class="px-2 btns" flex :style="{ width: '100%', margin: 0 }">
        <v-col class="px-0 py-0" align="right">
          <v-btn class="buttonPrimaryFooter" color="white" @click="crop()">{{
            $t("generics.save")
          }}</v-btn>

          <v-btn class="ml-3 mr-2" color="white" style="border-radius: 4px; min-width: 45px;" icon tile outlined @click="cancelCrop()">
            <font-awesome-icon
              :icon="['fal', 'times']"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-spacer></v-spacer>
  </v-card>
</v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
export default {
  components: {
    HeaderModal
  },
  props: [
    "uploadConferenceImage",
    "confOrGroupId",
    "showCroppie",
    "closeCroppie",
    "temporalImage",
    "resetInputImg",
    "isConference"
  ],
  data() {
    return {
      state: store.state,
      croppieLoaded: false,
    };
  },
  methods: {
    async crop() {
      const options = {
        type: "base64",
        size: { width: 256, height: 256 },
        format: "jpg",
      };

      const setAvatarPromise = this.$refs.croppieRefSquare
        .result(options)
        .then((output) => this.uploadConferenceImage(this.confOrGroupId, output));
      await Promise.all([setAvatarPromise]);
      this.closeCroppie();
    },
    cancelCrop() {
      this.closeCroppie();
      this.resetInputImg();
      this.croppieLoaded = false;
    },
    rotate(rotationAngle) {
      this.$refs.croppieRefSquare.rotate(rotationAngle);
    },
  },

  computed: {},
  mounted() {
    if (this.temporalImage) {
      setTimeout(() => {
        this.$refs.croppieRefSquare.bind({
          url: this.temporalImage,
        });
        this.croppieLoaded = true;
      }, 250)
    }
  },
};
</script>

<style scoped lang="scss">
.croppie-container {
  height: unset;
}
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: 2px solid #02889d !important;
}
.rotateIcons {
  position: absolute;
  right: 16%;
  top: 40%;
}
</style>
<style lang="scss">
.sliderSize .cr-slider-wrap {
  width: 100%;
  margin: 15px auto;
}
.sliderSize .cr-slider {
  width: 256px;
}

</style>