<template>
  <v-dialog
    v-model="showModalMessage"
    persistent
    max-width="560px"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal :titleModal="titleModal" :closeModalFunction="closeModal" />
      <v-divider></v-divider>
      <v-form
        ref="messageForm"
        v-model="valid"
        lazy-validation
        :autocomplete="false"
      >
        <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
          <v-textarea
            counter
            contenteditable="true"
            :label="$t('components.sendMessageModal.message')"
            :rules="rules"
            v-model="messagetext"
            :no-resize="true"
            maxlength="250"
            autofocus
            id="messageTextarea"
            @keydown.esc="closeModal"
          ></v-textarea>
        </v-row>
        <FooterModal :closeModalFunction="closeModal" :showCancel="true">
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="white"
                class="btnRed mr-2"
                @click="
                  seeUserMessages(showSendMessageModalStore.users[0].uuid)
                "
              >
                <font-awesome-icon
                  :icon="['fal', 'eye']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.sendMessageModal.seeYourMessages") }}</span>
          </v-tooltip> -->
          <!-- <v-menu
            v-if="1 == 2"
            :value="shown"
            top
            :offset-y="true"
            :close-on-click="true"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="white" icon @click="shown = true">
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </template>
            <div style="width: 425px; height: 120px; background-color: white">
              <v-row
                class="px-2 btns"
                flex
                :style="{ width: '100%', margin: 0, 'margin-botton': '12px' }"
              >
                <v-text-field
                  style="margin: 2px 10px 2px 10px"
                  hide-details
                  label="Text"
                  v-model="linkText"
                  autofocus
                ></v-text-field>
              </v-row>
              <v-row
                style="margin-top: 12px"
                class="px-2 btns"
                flex
                :style="{ width: '100%', margin: 0 }"
              >
                <v-text-field
                  style="margin: 2px 10px 2px 10px"
                  hide-details
                  label="Url"
                  v-model="linkUrl"
                ></v-text-field>
              </v-row>
            </div>
            <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
              <v-col lg="12" class="px-0 py-0" align="right">
                <v-btn color="primary" @click="linkifyme">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn @click="closeMenu" color="primary">
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </v-col>
            </v-card>
          </v-menu> -->
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="sendMessage()"
            :disabled="messagetext.length == 0 || !messagetext"
            >{{ $t("components.sendMessageModal.send") }}</v-btn
          >
        </FooterModal>
      </v-form>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import DialogDrag from "vue-dialog-drag";
import { wsCall } from "../../lib/wsConnect";
import { sendInviteMailCustom } from "../../lib/wsMsg";
import { newMessageEvent } from "../../effector/message";
import { typingInfoEvent } from "../../effector/rtc";
import {
  sendMessageModalStore,
  resetSendMessageModalEvent,
} from "../../effector/modals";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { myConferenceDetails } from "../../effector/groupConferences";
import { timelineEvents } from "../../effector/timeline";
import moment from "../../../sharedsrc/moment";

export default {
  components: { HeaderModal, FooterModal, DialogDrag },
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
    valid: true,
    setCurrentContentVisile: store.setCurrentContentVisile,
    shown: false,
    linkText: "",
    linkUrl: "",
    rules: [(v) => !!v || "", (v) => !!v.trim() || "Message can not be blank"],
    messagetext: "",
    headerConference: "",
    dataConference: null,
    moment: moment,
  }),
  effector: {
    showSendMessageModalStore: sendMessageModalStore,
    myConferenceDetails: myConferenceDetails,
    timelineEvents: timelineEvents,
  },
  watch: {
    showModalMessage: {
      handler(value) {
        if (value && Object.keys(store.state.remoteStreams).length > 0) {
          const obj = {
            uuid: this.showSendMessageModalStore.users[0],
            show: true,
            from: this.state.ownUUID,
          };
          typingInfoEvent(obj);
        } else if (!value) {
          const obj = {
            uuid: undefined,
            show: false,
            from: undefined,
          };
          typingInfoEvent(obj);
        }
        if (this.isConferenceMessage) {
          this.dataConference = this.myConferenceDetails.find(
            (e) => e.confId === this.isConferenceMessage
          );

          if (
            this.dataConference &&
            this.dataConference.confName &&
            this.dataConference.confStartDate &&
            this.dataConference.confStartTime
          ) {
            const confStartDate = moment(this.dataConference.confStartDate).format("DD.MM.YYYY");
            this.headerConference = `${this.dataConference.confName} -  ${confStartDate} ${this.dataConference.confStartTime} ${this.$t('generics.hours')} <br/> <br/>`;
          }
        }
        // console.log(this.dataConference, "dataaaaaaaaaa")
      },
    },
  },
  methods: {
    closeModal() {
      this.closeModalMsg();
      this.messagetext = "";
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    sendMessage() {
      const typeMsg = this.showSendMessageModalStore.type;
      const users = this.showSendMessageModalStore.users;
      if (users.length > 0) {
        users.forEach((uuid) => {
          if (typeMsg && typeMsg === "popUp") {
            this.sendMessagePopUp(uuid, this.messagetext);
          } else {
            if (this.isConferenceMessage) {
              this.preparedataToSendMail(uuid);
            }
            const text = this.headerConference + this.messagetext;
            this.preparedataToSendMsgEffector(uuid, text);
          }
        });
      }
      this.closeModal();
    },
    sendMessagePopUp(uuid, message) {
      wsCall("sendToUUID", uuid, {
        type: "rejectCall",
        message: message,
        sender: this.state.ownUUID,
      });
    },
    seeUserMessages() {
      this.closeModal();
      this.setCurrentContentVisile("messages", false, this.$router);
    },
    linkifyme() {
      let link =
        ' <a href="' +
        this.linkUrl +
        '" target="_blank">' +
        this.linkText +
        "</a>";
      this.messagetext += link;
      this.closeMenu();
    },
    closeMenu() {
      this.shown = false;
    },
    async preparedataToSendMail(userUUID) {
      if (!userUUID) return;
      const email = (store.state.group[userUUID].user || {}).email || "";
      const language =
        (store.state.group[userUUID].user || {}).language || "en";
      const myUserName =
        this.getTitelForUuid(this.state.ownUUID) +
        " " +
        this.getNameForUuid(this.state.ownUUID);
        const confStartDate = moment(this.dataConference.confStartDate).format("DD.MM.YYYY");
      const temporalMessageText = !this.confHasEnded
        ?this.dataConference.confPersonalizedGreet+ `\n` + this.$t("components.conferenceForm.conferenceMessageText", [
            myUserName,
            this.dataConference.confName,
            confStartDate,
            this.dataConference.confStartTime,
          ]) +
          `\n` +
          this.messagetext +
          `\n`
        : this.dataConference.confPersonalizedGreet+ `\n` + this.$t("components.conferenceForm.conferenceMessageTextThanks", [
            this.dataConference.confName,
            confStartDate,
            this.dataConference.confStartTime,
          ]) +
          `\n` +
          this.$t("components.conferenceForm.conferenceMessageTextInviter", [
            myUserName,
          ]) +
          `\n` +
          this.messagetext;
      const temporalSubject = this.$t(
        "components.conferenceForm.conferenceMessageSubject",
        [this.dataConference.confName]
      );
      if (email && email.indexOf("@") !== -1) {
        await sendInviteMailCustom(userUUID, {
          guest:
            ((store.state.group[userUUID] || {}).user || {}).permissions === 10,
          name: ((store.state.group[userUUID] || {}).user || {}).name,
          email,
          subject: temporalSubject,
          language,
          customMessage: temporalMessageText,
          conferenceNotification: true,
          noInviteLink: true,
        });
      }
    },
    preparedataToSendMsgEffector(id, text) {
      const userToSendMsg = {};
      userToSendMsg[id] = {
        userUUID: id,
        read: false,
        replied: false,
        deleted: false,
        viewed: false,
      };
      const dataMsg = {
        body: text,
        header: "individual message",
        date: Date.now(),
        users: userToSendMsg,
        isReply: false,
        creatorUUID: this.ownUUID,
        masterMessageUUID: null,
        parentMessageUUID: null,
        type: "message",
      };
      newMessageEvent(dataMsg);
    },
    closeModalMsg() {
      resetSendMessageModalEvent();
    },
  },
  computed: {
    confHasEnded() {
      let hasEnded = false;
      if (this.dataConference) {
        let confId = this.dataConference.confId || 0;
        const extantEv = this.timelineEvents.find((e) => e.confId === confId);
        if (extantEv && extantEv.end) {
          const orgEnd = moment(new Date(extantEv.end));
          const elapsedMins = moment().diff(orgEnd, "minutes");
          if (elapsedMins > 2) {
            hasEnded = true;
          }
        }
      } else {
        hasEnded = false;
      }
      return hasEnded;
    },
    isConferenceMessage() {
      if (
        this.showSendMessageModalStore.type === "conferenceMsg" &&
        this.showSendMessageModalStore.confId
      ) {
        return this.showSendMessageModalStore.confId;
      }
      return false;
    },
    titleModal() {
      if (this.showSendMessageModalStore) {
        const typeMsg = this.showSendMessageModalStore.type;
        if (typeMsg === "conferenceMsg") {
          return this.$t("components.sendMessageModal.sendMessageTo", [
            this.$t("components.stats.conference"),
          ]);
        } else if (typeMsg === "groupmessage") {
          return this.$t("components.sendMessageModal.sendMessageTo", [
            this.$t("components.groups.agroup"),
          ]);
        } else {
          return this.$t("components.sendMessageModal.sendMessageTo", [
            this.getNameForUuid(this.showSendMessageModalStore.users[0]),
          ]);
        }
      }
      return "";
    },
    showModalMessage: {
      get: function () {
        return this.showSendMessageModalStore.show;
      },
      set: function (newValue) {},
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-drag {
  user-select: text !important;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
</style>