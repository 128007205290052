<template>
  <v-dialog
    v-model="breakStatusModal"
    persistent
    max-width="350"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('status.Break')"
        :closeModalFunction="closeModal"
      />
      <v-card-text class="mx-auto py-2">
        <v-row class="mx-auto" flex>
          <v-col cols="12">
            <v-menu
              ref="endTimeMenu"
              v-model="endTimeMenu"
              :close-on-content-click="false"
              :nudge-left="130"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  id="clockInputBreakStatusModal"
                  @keydown.space="(event) => event.preventDefault()"
                  v-model="breakTimeEnd"
                  type="time"
                  class="timePicker"
                  v-on="on"
                  dense
                  hide-details
                  outlined
                  :label="$t('generics.expectedReturn')"
                  autofocus
                ></v-text-field>
              </template>
              <v-time-picker
                class="font16"
                v-if="endTimeMenu"
                v-model="breakTimeEnd"
                full-width
                format="24hr"
                @click:minute="$refs.endTimeMenu.save(breakTimeEnd)"
                @click:hour="autocompleteMinutesEnd"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <FooterModal :showCancel="true" :closeModalFunction="closeModal">
        <v-btn color="primary" class="ml-2" @click="saveTime" :disabled="disabledSave">{{
          $t("generics.save")
        }}</v-btn>
        <v-btn color="primary" class="ml-2" @click="deleteTime" v-if="showRemoveButton">{{
          $t("components.holidays.deleteHolidays")
        }}</v-btn>
      </FooterModal>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { resetBreakStatusModalEvent, breakStatusModalStore } from "../../effector/modals";
import { isMobile } from "../../lib/mobileUtil";
export default {
  components: { HeaderModal, FooterModal },
  data: () => ({
    isMobile: isMobile(),
    state: store.state,
    startTimeMenu: false,
    endTimeMenu: false,
    breakTimeEnd: store.state.user.breakTimeEnd || '',
  }),
  effector: {
    breakStatusModal: breakStatusModalStore,
  },
  mounted(){
    document.addEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    this._clockInputTimeout = setTimeout(() => {
      const clockInput = document.getElementById("clockInputBreakStatusModal");
      if (clockInput) clockInput.click();
    }, 200);
  },
  destroyed(){
    document.removeEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    if (this._clockInputTimeout) clearTimeout(this._clockInputTimeout);
  },
  methods: {
    onKeyUp(event) {
      if (event.type === "keyup" && event.keyCode === 13) {
        this.saveTime();
      }
    },
    saveTime(){
      store.state.user.activity = "Break";
      const breakTimeEnd = moment().toDate();
      const [hours, minutes] = this.breakTimeEnd.split(':');
      breakTimeEnd.setHours(+hours, +minutes, 0, 0);
      store.setBreakTime(breakTimeEnd.toISOString());
      this.closeModal();
    },
    deleteTime(){
      this.breakTimeEnd = '';
      store.setBreakTime(this.breakTimeEnd)
      this.closeModal();
    },
    autocompleteMinutesEnd(hour) {
      const additionalDigit = hour < 10 ? '0' : '';
      this.breakTimeEnd = additionalDigit + hour + ":00";
    },
    closeModal() {
      resetBreakStatusModalEvent(false);
    },
  },
  computed: {
    disabledSave(){
      if (this.breakTimeEnd == '') return true;
      return false;
    },
    showRemoveButton(){
      if (this.state.user.breakTimeEnd != '') return true;
      return false;
    }
  }
};
</script>

<style scoped lang="scss">
</style>