<template>
  <v-dialog
    v-model="showModalMoveItem"
    persistent
    max-width="560"
    @keydown.esc="closeModal"
  >
    <template>
      <v-card>
        <HeaderModal
          :titleModal="$t('components.manageOrganisation.headline')"
          :closeModalFunction="closeModal"
        />
        <v-row class="px-5 mx-0">
          <v-col cols="12" class="px-0">
            <p class="mb-0" v-html="$sanitize($t('components.organisationGrid.whereMove', [itemToMoveSanitized]))"></p>
          </v-col>
           <v-col cols="12" class="px-0">
            <v-select
              v-model="selectedItem"
              :items="getListItem"
              select
              return-object
              item-text="name"
              item-value="name"
              dense
              outlined
              hide-details
              autofocus
              :label="destination == 'sections' ? $t('components.organisationCarousel.sections') : destination == 'departments' ? $t('components.organisationCarousel.departments') : destination == 'teams' ? $t('components.addConferenceModal.teams') : 'default'"
            ></v-select>
          </v-col>
        </v-row>
        <FooterModal
          :closeModalFunction="closeModal"
          :showFooter="true"
        >
          <v-btn
            :disabled="!selectedItem"
            color="primary"
            @click="moveItem(selectedItem.name); resetSelected()"
            >{{ $t("generics.save") }}
          </v-btn>
        </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  props: ["showModalMoveItem", "closeModal", "moveItem", "destination", "itemToMove", "parent", "parentSection"],
  data: () => ({
    state: store.state,
    selectedItem: null
  }),
  methods: {
    resetSelected(){
      this.selectedItem = null;
    }
  },
  computed:{
    itemToMoveSanitized() {
      if (  typeof(this.itemToMove) === 'object' ){
        return this.$sanitize(this.itemToMove.user.name);
      }else{
        return this.$sanitize(this.itemToMove);
      }
    },
    getListItem(){
      let keys;
      if ( this.destination == 'sections' ){
        keys = Object.keys(this.state.namespaceSettings.processedOrganisation.sections);
      }else if ( this.destination == 'departments' ){
        keys = Object.keys(this.state.namespaceSettings.processedOrganisation.departments);
      }else if ( this.destination === 'teams'){
        keys = Object.keys(this.state.namespaceSettings.processedOrganisation.teams);
      }
      if ( keys && keys.length > 0 ){
          const tmpArray = [];
          keys.forEach(section => {
            if ( section !== this.parent ){
              if ( this.destination == 'teams' && this.itemToMove.user){
                if ( this.itemToMove && this.state.namespaceSettings.processedOrganisation.teams[section].members.indexOf(this.itemToMove.user.uuid) == -1 ){
                  const obj = {
                    name: section
                  }
                  tmpArray.push(obj)
                }
              }else{
                const obj = {
                  name: section
                }
                tmpArray.push(obj)
              }
            }
          });
          return tmpArray.sort((a,b) => a.name.localeCompare(b.name))
        }
    }
  }
};
</script>

<style scoped lang="scss">
.itemMoveOrga{

}
.pointer {
  cursor: pointer;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>