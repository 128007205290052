var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mx-auto",staticStyle:{"max-height":"60px"},on:{"click":function($event){return _vm.handleClickInHeader($event)}}},[_c('v-spacer'),_vm._v(" "),_c('v-col',{staticClass:"px-0 d-flex justify-center",attrs:{"cols":"6"}},[(_vm.typeData === 'users' || _vm.typeData === 'favorites')?_c('span',{staticClass:"text-white customFontSize subtitle-1 font-weight-bold mb-0"},[_vm._v("\n      "+_vm._s(this.inputSearch.trim().length > 2 ? _vm.$t("generics.searchResult") : 
        _vm.typeData === "users"
          ? _vm.$t("generics.users")
          : _vm.typeData === "favorites"
          ? _vm.$t("components.footernav.myFavorites")
          : "")+"\n    ")]):_vm._e(),_vm._v(" "),(!_vm.isMobile && _vm.amIAdmin && _vm.$route.path === '/users')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({},on),[_c('InviteGuest',{attrs:{"isNavBar":true,"isSidebar":true,"typeInvite":'user',"isIpad":_vm.isIpad}})],1)]}}],null,false,4130884268)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.footernav.inviteUser")))])]):_vm._e(),_vm._v(" "),(_vm.$route.path === '/users')?[_c('v-checkbox',{staticClass:"py-0 mx-2 my-0",on:{"change":function($event){return _vm.isShowGreen(_vm.showGreen)},"targetEvent":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"mb-0 font-weight-bold labelTitle"},[_vm._v("\n            "+_vm._s(_vm.$t("components.organisationCarousel.green"))+"\n          ")])]},proxy:true}],null,false,204470973),model:{value:(_vm.showGreen),callback:function ($$v) {_vm.showGreen=$$v},expression:"showGreen"}}),_vm._v(" "),_c('v-checkbox',{staticClass:"py-0 mx-2 my-0",on:{"change":function($event){return _vm.isShowOrange(_vm.showOrange)},"targetEvent":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"mb-0 font-weight-bold labelTitle"},[_vm._v("\n            "+_vm._s(_vm.$t("components.organisationCarousel.orange"))+"\n          ")])]},proxy:true}],null,false,1265303382),model:{value:(_vm.showOrange),callback:function ($$v) {_vm.showOrange=$$v},expression:"showOrange"}})]:_vm._e()],2),_vm._v(" "),_c('v-spacer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }