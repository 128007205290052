 <template>
  <!-- End modal insert note -->
  <v-dialog persistent v-model="state.showUserModalMultiSelect" max-width="560" @keydown.esc="cancelEdit">
    <v-card>
      <v-card-title class="headline">
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        {{ $t('components.userSelectModal.selectGroupMembers') }}
      </v-card-title>
      <v-divider></v-divider>
      <v-row :style="{width: '100%', margin: 0}" class="px-5">
        <v-col cols="12">
          <v-combobox
            v-model="state.user.activeGroupMembers.members"
            :items="dataCombobox.users"
            select
            :search-input.sync="searchUsers"
            item-text="name"
            :label="$t('generics.users')"
            multiple
            class="mx-1 label600"
            dense
            counter
            :rules="rules"
            auto-select-first
            @change="searchUsers = ''"
          ></v-combobox>
        </v-col>
        <v-col cols="12">
          <v-combobox
            v-model="state.user.activeGroupMembers.guests"
            :items="dataCombobox.guests"
            select
            :search-input.sync="searchVisitors"
            item-text="name"
            :label="$t('generics.guest')"
            multiple
            class="mx-1 label600"
            dense
            counter
            :rules="rules"
            auto-select-first
            @change="searchVisitors = ''"
          ></v-combobox>
        </v-col>
        <v-col cols="12">
          <v-combobox
            v-model="state.user.activeGroupMembers.visitors"
            :items="dataCombobox.visitors"
            select
            :search-input.sync="searchGuests"
            item-text="name"
            :label="$t('generics.myVisitors')"
            multiple
            class="mx-1 label600"
            dense
            counter
            auto-select-first
            :rules="rules"
            @change="searchGuests = ''"
          ></v-combobox>
        </v-col>
      </v-row>
      <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns" flex :style="{width: '100%', margin: 0}">
          <v-col lg="6" class="px-0 py-0" align="right">
            <v-btn color="primary" @click.stop.prevent="cancelEdit">{{ $t('generics.cancel') }}</v-btn>
            <v-btn
              color="primary"
              @click.stop.prevent="prepareUserDataSelection"
              v-bind:disabled="checkMaxUsers"
            >{{ $t('generics.save') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import { setNamespaceSetting } from "../../lib/wsMsg";

export default {
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      rules: [
        value => !!value || this.$t('generics.required'),
        value => (value || "").length <= 24 || "Max 24 users"
      ],
      editing: true,
      activeGroup: store.state.user.targetGroupId,
      preSaveMembers: [],
      searchUsers: null,
      searchVisitors: null,
      searchGuests: null
    };
  },

  methods: {
    cancelEdit() {
      // console.log('cancelling')
      // store.state.user.activeGroupMembers.members = [];
      store.state.user.targetGroupId = 0;
      this.activeGroup = 0;
      this.closeModalMultiSelectUsers();
    },
    closeModalMultiSelectUsers() {
      this.state.showUserModalMultiSelect = false;
    },
    prepareUserDataSelection() {
      if (this.checkMaxUsers > 24) {
        return;
      }
      store.state.user.userGroups.find(
        x => x.id === store.state.user.targetGroupId
      ).members = store.state.user.activeGroupMembers.members;

      store.state.user.userGroups.find(
        x => x.id === store.state.user.targetGroupId
      ).visitors = store.state.user.activeGroupMembers.visitors;

      store.state.user.userGroups.find(
        x => x.id === store.state.user.targetGroupId
      ).guests = store.state.user.activeGroupMembers.guests;

      this.closeModalMultiSelectUsers();
    }
  },
  mounted: function() {},

  computed: {
    checkMaxUsers() {
      let totaLength =
        (((store.state.user || {}).activeGroupMembers || []).members || [])
          .length +
        (((store.state.user || {}).activeGroupMembers || []).guests || [])
          .length +
        (((store.state.user || {}).activeGroupMembers || []).visitors || [])
          .length;
      return totaLength > 24;
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    dataCombobox: function(data) {
      const result = { users: [], guests: [], visitors: [] };
      const users = this.state.group || {};
      const visitors = this.state.persisted.userVisitors || {};
      for (const key in users) {
        const data = { name: (users[key].user || {}).name || "", uuid: key };
        const isGuest = store.getUuidFromGuests(key);
        if (isGuest && data.name !== "") {
          result.guests.push(data);
        } else if (!isGuest && data.name !== "") {
          result.users.push(data);
        }
      }
      for (const visitorId in visitors) {
        const visitor = store.state.persisted.userVisitors[visitorId];
        if (visitor) {
          result.visitors.push(visitor);
        }
      }
      return result;
    },

    todayDate() {
      const today = new Date().toLocaleDateString([], {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
      });
      return today;
    }
  }
};
</script>
<style scoped lang="scss">
.fontsemi {
  font-weight: 600 !important;
}
.footerDate {
  color: white;
  line-height: 2.8em;
  text-align: right;
}
.noteText {
  white-space: pre-line;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}

.messageText {
  overflow: hidden;
  white-space: pre-line;
  user-select: text;
}

.messageTextExpand {
  overflow: hidden;
  overflow-y: auto;
  padding-right: 5px;
  white-space: pre-line;
  user-select: text;
  word-break: break-word;
}
</style>