<template>
  <v-dialog v-model="showSendMessageInCallModal" persistent max-width="400" @keydown.esc="closeModal">
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('components.groups.sendMessage')"
          :closeModalFunction="closeModal"
        >
          <v-spacer></v-spacer>
        </HeaderModal>
        <v-card-text class="py-0 pt-4">
         <v-textarea
            counter
            contenteditable="true"
            :label="$t('components.sendMessageModal.message')"
            :rules="rules"
            v-model="messageText"
            :no-resize="true"
            maxlength="250"
            autofocus
            id="messageTextarea"
          ></v-textarea>
        </v-card-text>

        <FooterModal :closeModalFunction="closeModal" :showCancel="true">
           <v-btn
            color="primary"
            @click="
              sendMessage();
            "
            :disabled="
              messageText.length == 0
            "
            >{{ $t("components.sendMessageModal.send") }}</v-btn
          >
        </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { resetSendMessageInCallModalEvent, setNewMessageFromCallEvent, sendMessageInCallStore } from "../../effector/modals";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  components: { HeaderModal, FooterModal },
  props: [],
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
    messageText: '',
    rules: [(v) => !!v || "", (v) => !!v.trim() || "Message can not be blank"],

  }),
  mounted() {
    // console.log('newversion modal mounted');
  },
  destroyed() {

  },
  methods: {
    sendMessage(){
      // console.log("sendMessage")
      const data = {
        show: true,
        text: this.messageText,
        owner: this.state.ownUUID
      }
      setNewMessageFromCallEvent(data)
      this.closeModal()
    },
    closeModal() {
      resetSendMessageInCallModalEvent();
    },
  },
  effector: {
    showSendMessageInCallModal: sendMessageInCallStore,
  },
  watch: {
  
  },
};
</script>

<style scoped lang="scss">
</style>