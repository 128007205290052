import Vue from 'vue';
import vuetify from '../../../vuetify';
import BlinkenLights from './blinkenlights.vue';

let blinkHolder;
export default function blinkenlights(domain) {
  // console.log('blinkenlights', blinkHolder, domain);
  if (!blinkHolder && domain) {
    blinkHolder = new Vue({
      render: createElement => createElement(BlinkenLights, { props: { domain } }),
    });

    BlinkenLights.vuetify = vuetify;

    // console.log('BLINKENLIGHTS', domain, blinkHolder);
    const container = document.createElement('div');
    document.body.appendChild(container);
    blinkHolder.$mount(container);
  } else if (blinkHolder) {
    blinkHolder.$children[0].setDisplay(!!domain);
  }
}
