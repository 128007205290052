<template>
  <v-list max-width="320" @click.stop.prevent>
    <v-list-group
      active-class="listColor"
      v-if="!isGuest && !mini"
      :value="isActive"
      @click="handlerClickCoffBr($event)"
      :appendIcon="sortedCoffeeBreakUsers.length === 0 ? null : '$expand'"
    >
      <template v-slot:activator>
        <v-list-item-icon>
          <font-awesome-icon
            v-if="currentPage('/coffee-break')"
            :icon="['fas', 'mug']"
            class="primary--text"
          />
          <font-awesome-icon
            v-else
            :icon="['fal', 'mug']"
            class="primary--text"
          />
        </v-list-item-icon>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
              <!-- class="primary--text" -->
            <v-list-item-title
              :class="`${
                getRoute === '/coffee-break'
                  ? 'font-weight-bold'
                  : ''
              }`"
              @click.stop.prevent="
                setCurrentContentVisile('', false, $router);
                handleMyCoffeeBreakView();
              "
              v-on="on"
            >
              {{ $t("status.Coffee break") }}
            </v-list-item-title>
          </template>
          <span>{{ $t("status.Coffee break") }}</span>
        </v-tooltip>
        <CustomTT
          v-if="showCustomTT"
          :text="`${$t('components.hotkeys.controlKey')} + B`"
          position="bottom"
          :extraclass="'ctrb'"
        />
      </template>
      <UserList :persons="sortedCoffeeBreakUsers" :section="'coffee-break'" />
    </v-list-group>
    <v-tooltip right v-if="!isGuest && mini">
      <template v-slot:activator="{ on }">
        <v-list-item v-on="on" class="cursorPointer">
          <v-list-item-icon @click.stop.prevent="handlerClickCoffBr()">
            <font-awesome-icon
              v-if="currentPage('/coffee-break')"
              :icon="['fas', 'mug']"
              class="primary--text"
            />
            <font-awesome-icon
              v-if="!currentPage('/coffee-break')"
              :icon="['fal', 'mug']"
              class="primary--text"
            />
            <v-badge
              @click.native="handleMyCoffeeBreakView()"
              v-if="getConnectedCoffeeBreak > 0"
              right
              color="error"
              :content="getConnectedCoffeeBreak"
              :value="getConnectedCoffeeBreak"
              bordered
              overlap
              offset-x="7"
              offset-y="7"
              class="coffeeBreakBadge"
            ></v-badge>
          </v-list-item-icon>
        </v-list-item>
      </template>
      <span>{{ $t("status.Coffee break") }}</span>
    </v-tooltip>
  </v-list>
</template>

<script>
import store, { EventBus } from "../../store";
import UserList from "./userList.vue";
import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";

export default {
  props: [
    "mini",
    "persons",
    "filterPerson",
    "setCurrentContentVisile",
    "isGuest",
    "sortedCoffeeBreakUsers",
    "active",
    "setList",
    "isMobile",
  ],
  components: {
    UserList,
    CustomTT,
  },
  effector: {
    showCustomTT: hotkeysInfoStore,
  },
  data: () => ({
    state: store.state,
  }),
  mounted() {
    EventBus.$on("openCoffeeBreakEvent", this.handleMyCoffeeBreakView);
  },
  destroyed(){
    EventBus.$off("openCoffeeBreakEvent", this.handleMyCoffeeBreakView);
  },
  methods: {
    handlerClickCoffBr(event) {
      if (this.mini) {
        this.goToPage("coffee-break");
      } else {
        this.setList("coffee-break");
      }
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    goToPage(page) {
      this.setCurrentContentVisile(page, true, this.$router);
    },
    handleMyCoffeeBreakView() {
      if (!this.isMobile) {
        this.setCurrentContentVisile("coffee-break", true, this.$router);
      }
    },
  },
  computed: {
    getConnectedCoffeeBreak() {
      let tempCoffeeBreak = 0;
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (person.connected && person.user && person.user.presentFrom) {
          if (
            person.user.activity === "Coffee break"
          ) {
            tempCoffeeBreak++;
          }
        }
      }
      return tempCoffeeBreak;
    },
    isActive() {
      return this.active == "coffee-break";
    },
    getRoute() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
.coffeeBreakBadge {
  font-size: 10px !important;
  height: 16px !important;
  min-width: 16px !important;
  padding: 2px 6px !important;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  border-radius: 50% !important;
}
.cursorPointer {
  cursor: pointer;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.textColor {
  color: #008000;
}

.theme--dark .listColor {
  color: white !important;
}
.theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
.ctrb {
  left: 15px;
}
</style>

