var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{attrs:{"max-width":"320"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[(!_vm.mini)?_c('v-list-group',{attrs:{"active-class":"listColor","value":_vm.isActive,"appendIcon":'$expand'},on:{"click":function($event){return _vm.handlerClickWaitingRoom($event)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"primary--text",class:("" + (_vm.getRoute === '/waitingroom' ? ' primary--text' : ''))},[(_vm.currentPage('/waitingroom'))?_c('font-awesome-icon',{staticClass:"primary--text",attrs:{"icon":['fas', 'users-class']}}):_c('font-awesome-icon',{staticClass:"primary--text",attrs:{"icon":['fal', 'users-class']}})],1),_vm._v(" "),(_vm.getconnecetedWaitingrooms > 0)?_c('v-badge',{staticClass:"waitingRoomBadge",attrs:{"right":"","color":"error","content":_vm.getconnecetedWaitingrooms,"value":_vm.getconnecetedWaitingrooms,"bordered":"","overlap":"","offset-x":"7","offset-y":"7"},nativeOn:{"click":function($event){return _vm.handleWaitingRoom()}}}):_vm._e(),_vm._v(" "),_c('v-list-item-title',{staticClass:"pr-4",class:("" + (_vm.getRoute === '/waitingroom' ||
          (!_vm.hasPrivilege(_vm.state.ownUUID) && ("" + (_vm.getRoute === '/home')))
            ? 'font-weight-bold'
            : '')),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.setCurrentContentVisile('', false, _vm.$router);
          _vm.handleWaitingRoom();}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("\n              "+_vm._s(_vm.getTitleWaitingROOM)+"\n              "+_vm._s(("" + (_vm.getconnecetedWaitingrooms > 0
                    ? "(" + _vm.getconnecetedWaitingrooms + ")"
                    : "")))+"\n            ")])]}}],null,false,3879445098)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.waitingRoomTT))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnAddGroup",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.gotoPage('invitationform')}}},on),[_c('font-awesome-icon',{staticClass:"primary--text",style:({
                  fontSize: '20px',
                }),attrs:{"icon":['fal', 'plus-circle']}})],1)]}}],null,false,3293448960)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.sidebar.guestTT")))])])],1),_vm._v(" "),(_vm.showCustomTT)?_c('CustomTT',{attrs:{"text":((_vm.$t('components.hotkeys.controlKey')) + " + R"),"position":"bottom","extraclass":'ctrr'}}):_vm._e()]},proxy:true}],null,false,1847602696)},[_vm._v(" "),_c('UserList',{attrs:{"persons":_vm.sortedUsersWaitingRoom,"section":'guests',"typeData":"waitingroom"}})],1):_vm._e(),_vm._v(" "),(!_vm.isGuest && _vm.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"cursorPointer"},on),[_c('v-list-item-icon',{on:{"click":function($event){return _vm.handleWaitingRoom($event)}}},[(_vm.currentPage('/waitingroom'))?_c('font-awesome-icon',{staticClass:"primary--text",attrs:{"icon":['fas', 'users-class']}}):_vm._e(),_vm._v(" "),(!_vm.currentPage('/waitingroom'))?_c('font-awesome-icon',{staticClass:"primary--text",attrs:{"icon":['fal', 'users-class']}}):_vm._e(),_vm._v(" "),(_vm.getconnecetedWaitingrooms > 0)?_c('v-badge',{staticClass:"waitingRoomBadge",attrs:{"right":"","color":"error","content":_vm.getconnecetedWaitingrooms,"value":_vm.getconnecetedWaitingrooms,"bordered":"","overlap":"","offset-x":"7","offset-y":"7"},nativeOn:{"click":function($event){return _vm.handleWaitingRoom()}}}):_vm._e()],1)],1)]}}],null,false,1294862209)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.waitingRoomTT))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }