<template>
  <StatusTable
    :headers="headers"
    :users="loadDataTable"
    :itemsPerPage="itemsPerPage"
    :statusType="getStatusType"
    :getItemPerPage="getItemPerPage"
  />
</template>

<script>
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import StatusTable from "./statusTable.vue";
import {  checkIsDateInRange } from "../../utils/basicFunctions";
export default {
  components: { StatusTable },
  data() {
    return {
      state: store.state,
      moment: moment,
      itemsPerPage: store.state.persisted.itemsPerPageStatus,
    };
  },
  methods: {
    getItemPerPage(val) {
      this.itemsPerPage = val;
      this.state.persisted.itemsPerPageStatus = this.itemsPerPage;
    },
    // checkIsDateInRange(startDate, endDate, date) {
    //   let range = moment().range(startDate, endDate);
    //   return range.contains(date);
    // },
    getMemberData(member, uuid) {
      const person = JSON.parse(JSON.stringify(member));
      person.user.nametitel = this.getUserTitel(uuid) + " " + person.user.name;
      person.user.uuid = uuid;
      person.user.connected = person.connected;
      person.user.holidays =
        person.user.startHolidays &&
        person.user.endHolidays &&
        (moment(new Date(person.user.endHolidays)).isSame(new Date(), "day") ||
          !moment(new Date(person.user.endHolidays)).isBefore(
            new Date(),
            "day"
          ))
          ? moment(new Date(person.user.startHolidays)).format("DD.MM.YYYY") +
            " - " +
            moment(new Date(person.user.endHolidays)).format("DD.MM.YYYY")
          : "";
      person.user.isInHolidays = checkIsDateInRange(
        new Date(person.user.startHolidays),
        new Date(person.user.endHolidays),
        new Date()
      );
      person.user.presentFrom = person.user.presentFrom
        ? moment(new Date(person.user.presentFrom)).format("DD.MM.YY H:mm")
        : "";
      person.user.presentFrom = person.user.presentFrom.startsWith(
        this.todaysDate
      )
        ? person.user.presentFrom
        : "";
      return person;
    },
    getUserTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {
    loadDataTable() {
      const membersList = this.state.group;
      const showUsersAvailable = () => {
        let tempAvailable = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (person.connected && person.user && person.user.presentFrom) {
            if (person.user.activity === "Available") {
              const memberData = this.getMemberData(person, uuid);
              tempAvailable.push(memberData.user);
            }
          }
        }
        return tempAvailable;
      };
      const showUsersBusy = () => {
        let tempBusy = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (person.connected && person.user && person.user.presentFrom) {
            if (
              person.user.activity === "Out of Office" ||
              person.user.activity === "Only phone" ||
              person.user.activity === "No Calls" ||
              person.user.activity === "Break"
            ) {
              const memberData = this.getMemberData(person, uuid);
              tempBusy.push(memberData.user);
            }
          }
        }
        return tempBusy;
      };
      const showUsersNotAvailable = () => {
        let tempNotAvailable = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (person.connected && person.user && person.user.presentFrom) {
            if (
              (person.user.activity === "Busy" ||
                person.user.activity === "In meeting" ||
                person.user.activity === "Holidays" ||
                person.user.activity === "Just urgent calls") &&
              person.connected
            ) {
              const memberData = this.getMemberData(person, uuid);
              tempNotAvailable.push(memberData.user);
            }
          }
        }
        return tempNotAvailable;
      };
      const showUsersCoffeBreak = () => {
        let tempCoffeBreak = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (person.connected && person.user && person.user.presentFrom) {
            if (
              (person.user.activity === "Coffee break" ) &&
              person.connected
            ) {
              const memberData = this.getMemberData(person, uuid);
              tempCoffeBreak.push(memberData.user);
            }
          }
        }
        return tempCoffeBreak;
      };
      const showUsersConnections = () => {
        let tempConnections = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (person.connected && person.user && person.user.presentFrom) {
            if (
              person.user.inBridgeCall ||
              (person.user.rtcCallStatus &&
                person.user.rtcCallStatus.length > 5)
            ) {
              const memberData = this.getMemberData(person, uuid);
              tempConnections.push(memberData.user);
            }
          }
        }
        return tempConnections;
      };
      const showUsersOffline = () => {
        let tempOffline = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if ((person.user && (!person.connected || !person.user.presentFrom))|| (person.user &&  person.user.activity === "No status"))  {
            const memberData = this.getMemberData(person, uuid);
            tempOffline.push(memberData.user);
          }
        }
        return tempOffline;
      };
      const showUsersList = () => {
        let tempTotal = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (person === undefined) continue;
          if (person.user) {
            const memberData = this.getMemberData(person, uuid);
            tempTotal.push(memberData.user);
          }
        }
        return tempTotal;
      };
      const showUsersInHolidays = () => {
        let tempOffline = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (
            person.user &&
            person.user.startHolidays &&
            person.user.endHolidays &&
            (moment(new Date(person.user.endHolidays)).isSame(
              new Date(),
              "day"
            ) ||
              !moment(new Date(person.user.endHolidays)).isBefore(
                new Date(),
                "day"
              ))
          ) {
            const memberData = this.getMemberData(person, uuid);
            tempOffline.push(memberData.user);
          }
        }
        tempOffline.sort(function (a, b) {
          let aDate = new Date(a.startHolidays);
          let bDate = new Date(b.startHolidays);
          return aDate < bDate
            ? -1
            : aDate > bDate
            ? 1
            : (a.uuid || "").localeCompare(b.uuid || "");
        });
        return tempOffline;
      };
      switch (this.$route.path) {
        case "/status/available":
          return showUsersAvailable();
        case "/status/not-available":
          return showUsersNotAvailable();
        case "/status/no-call":
          return showUsersBusy();
        case "/status/in-call":
          return showUsersConnections();
        case "/status/offline":
          return showUsersOffline();
        case "/status/total":
          return showUsersList();
        case "/status/holidays":
          return showUsersInHolidays();
          case "/status/coffee-break":
          return showUsersCoffeBreak();
        default:
          break;
      }
    },
    headers() {
      return [
        {
          text: this.$t("literals.Picture"),
          value: "avatar",
          sortable: false,
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.nachricht"),
          value: "sendMessage",
          sortable: false,
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.name"),
          value: "nametitel",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.urlaub"),
          value: "holidays",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.E-Mail"),
          value: "email",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.telefon"),
          value: "phone",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("literals.Function"),
          value: "position",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("literals.loginday"),
          value: "presentFrom",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("literals.Location"),
          value: "location",
          class: "font-weight-bold subtitle-1",
        },
      ];
    },
    getStatusType() {
      let type = "";
      switch (this.$route.path) {
        case "/status/available":
          type = this.$t("generics.available");
          break;
        case "/status/not-available":
          type = this.$t("components.navbar.statusMaybeAvailable");
          break;
        case "/status/no-call":
          type = this.$t("generics.noCall");
          break;
        case "/status/in-call":
          type = this.$t("generics.inCall");
          break;
        case "/status/offline":
          type = this.$t("generics.offline");
          break;
        case "/status/total":
          type = this.$t("generics.total");
          break;
        case "/status/holidays":
          type = this.$t("status.Holidays");
          break;
        case "/status/coffee-break":
          type = this.$t("status.Coffee break");
          break
        default:
          type = "";
          break;
      }
      return type;
    },
    todaysDate() {
      return moment(new Date(this.state.currentTS)).format("DD.MM.YY");
    },
  },
};
</script>