<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="735"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('generics.message')"
        :closeModalFunction="closeModal
        "
      />
      <v-row flex :style="{ width: '100%', margin: 0 }">
        <div class="d-flex" :style="{ width: '100%' }">
          <div align="center">
            <v-badge
              bordered
              overlay
              height="60"
              width="60"
              bottom
              :color="badgetClassObj"
              dot
              offset-x="22"
              offset-y="15"
            >
              <v-list-item-avatar tile size="150" class="borderRadius10">
                <template>
                  <DefaultAvatar
                    :size="150"
                    :userData="getUserData"
                    v-if="
                      getUserData.avatar === 'img/default_profile_picture.png'
                    "
                  ></DefaultAvatar>
                  <v-img
                    class="borderRadius10"
                    max-height="150"
                    max-width="150"
                    contain
                    :src="
                      getUserData.avatar !== 'img/default_profile_picture.png'
                        ? getUserData.avatar
                        : 'img/default_profile_picture.png'
                    "
                    lazy-src="assets/default_profile_picture.png"
                  ></v-img>
                </template>
              </v-list-item-avatar>
            </v-badge>
          </div>
          <div class="text-center" :style="{ width: '100%' }">
            <v-divider class="mt-4"></v-divider>
            <div class="mt-2 ml-4">
              <p color="primary" class="userNameCard">
                {{ getUserData.titel }} {{ getUserData.name }}
              </p>
              <p color="primary">{{ getTextModal }}</p>
            </div>
            <v-divider class="mt-4"></v-divider>
          </div>
        </div>
      </v-row>
      <FooterModal
        :hideDefaultButtons="
          state.modalPopUp.type === 'answerJoinConference' ? false : true
        "
        :closeModalFunction="closeModal"
      >
      </FooterModal>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { getStatusColorByUser } from "../../utils/status";
import { wholeBarWithColor } from "../../utils/color";
import DefaultAvatar from "../image/defaultAvatar.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import { wsCall } from "../../lib/wsConnect";
import { callPerson } from "../../utils/calls";
import {joinConferenceStore} from "../../effector/modals"
export default {
  data() {
    return {
      state: store.state,
    };
  },
  components: {
    DefaultAvatar,
    FooterModal,
    HeaderModal,
  },
  watch: {},
  mounted() {},
  effector:{
    showjoinConference: joinConferenceStore
  },
  methods: {
    wholeBarWithColors() {
      return wholeBarWithColor();
    },
    closeModal() {
      return store.setmodalPopUp(undefined);
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
  },
  computed: {
    getTypeMsg() {
      return this.state.modalPopUp.type;
    },
    getTextModal() {
      switch (this.getTypeMsg) {
        case "joinConference":
          return this.$t("components.callsContent.joinConference");
        case "answerJoinConference":
          if (this.state.modalPopUp.msg === "accepted") {
            return this.$t("components.callsContent.beCalledLater");
          } else {
            return this.$t("components.callsContent.cantAnswer");
          }
        default:
          break;
      }
    },
    showModal() {
      return (
        (store.state.modalPopUp &&
          store.state.modalPopUp.type === "answerJoinConference")
      );
    },
    badgetClassObj() {
      if (this.wholeBarWithColors()) {
        return getStatusColorByUser(this.getUserData, this.isUserConnected);
      } else {
        return "rgba(0,0,0,0.3)";
      }
    },
    getUserData() {
      if (store.state.modalPopUp.uuid) {
        return store.state.group[store.state.modalPopUp.uuid].user;
      }
    },
    isUserConnected() {
      if (store.state.modalPopUp.uuid) {
        return store.state.group[store.state.modalPopUp.uuid].connected;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
