 <template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="460"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal :titleModal="'Info'" :closeModalFunction="closeModal" />
      <v-card-text>{{
        $t("components.organisationCarousel.inProgress")
      }}</v-card-text>
      <FooterModal :closeModalFunction="closeModal" :showClose="true" />
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  components: { HeaderModal, FooterModal },
  props: ["closeModal", "showModal"],
  data() {
    return {
      state: store.state,
    };
  },
};
</script>