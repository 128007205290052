<template>
  <div v-if="conference">
      <label class="ml-2 userName font-weight-medium">{{conference.confName}}</label>
      <v-menu offset-y scrollable :close-on-click="true" v-if="conference.confId && canEditConference">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="groupCardEllipses" >
            <font-awesome-icon
              :icon="['fal', 'ellipsis-v']"
              :style="{ fontSize: '20px' }"
            />  
          </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="canEditConference" @click="editConference(conference)">
              <v-list-item-title>{{ $t("components.addConferenceModal.editConference")}}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="canEditConference && !amInACall && !conference.conferenceHall" @click="callAllMembers(conference)">
              <v-list-item-title>{{ $t("components.addGroupModal.callAll")}}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="canEditConference" @click="reminderConference(conference)">
              <v-list-item-title>{{ $t("components.addConferenceModal.confReminder")}}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="canEditConference" @click="checkSendMessage(conference.confUUIDS, conference.confId)">
             <v-list-item-title>{{ $t("components.addGroupModal.sendMessageToAll")}}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="canEditConference" @click="duplicateConference(conference.confId)">
             <v-list-item-title>{{ $t("components.addConferenceModal.dupConfNewDate")}}</v-list-item-title>
            </v-list-item>
             <v-list-item v-if="canEditConference"  @click="preDeleteConference($event, conference.confId)">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-list-item-title v-on="on" :class="!deleteActive ? 'deleteBasketLinkNormal' :'deleteBasketLinkActive'">{{ $t("components.addConferenceModal.deleteConference")}}</v-list-item-title>
                </template>
                <span>{{
                  $t("components.conferenceForm.confirmRemove")
                }}</span>
              </v-tooltip>
            </v-list-item>
          </v-list> 
      </v-menu>
  </div>
</template>
<script>
import store, { EventBus } from "../../store";
import { getAvatarByType } from "../../utils/basicFunctions";
import { hasPrivilege } from "../../utils/privileges";

import { dispatchSuccessAlert, dispatchErrorAlert } from '../../effector/alerts';
export default {
  components: {},
  props: ["conference", "editConference", "deleteConference", "checkSendMessage","existingConference", "duplicateConference", "reminderConference", "callEveryone", "amInACall"],
  data() {
    return {
      state: store.state,
      deleteActive: false
    }
  },
  computed:{
    canEditConference(){
      return (this.conference.confModerators.indexOf(this.state.ownUUID) !== -1);
    }
  },
  methods: {
    callAllMembers(conference){
      this.callEveryone(false,conference);  
    },
    createFromExistingGroup(groupId){
      this.existingGroup(groupId);
    },
    async preDeleteConference(event, confId){
      event.stopPropagation();
      event.preventDefault();
       try{
        if (!this.deleteActive) {
          this.deleteActive = true;
          setTimeout(() => {
            this.deleteActive = false; 
          },1500)
        } else {
          this.deleteActive = false;
          await this.deleteConference(confId);
          this.viewingConferences = true;
          dispatchSuccessAlert(this.$t('components.conferences.conferenceDeleted'));      
        }
      }catch(err){
          this.deleteActive = false;
          console.warn('Error deleting group', err)
          dispatchErrorAlert('Error deleting group')
      }

    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getAvatarByType(uuid) {
      return getAvatarByType(uuid);
    },
    getNameForUuid(uuid){
      return store.getNameForUuid(uuid);
    },
  }
}
</script>
<style scoped lang="scss">
.theme--light .deleteBasketLinkNormal {
  color: rgb(32, 31, 31);
}
.deleteBasketLinkActive{
  color: red !important;
  font-weight: 600 !important;
}
.groupCardEllipses {
  color: white !important;
  float: right;
  max-height: 20px;
  max-width: 20px;
}
.userName{
  vertical-align: middle;
}
</style>