<template>
  <v-dialog v-model="showModal" persistent max-width="560">
    <template>
      <v-card class="mx-auto">
        <HeaderModal :titleModal="$t('components.beforeEndingCallModal.title')" />
        <v-card-text>
          {{
            amIModerator
              ? $t("components.beforeEndingCallModal.text")
              : $t("components.beforeEndingCallModal.textRegularUser")
          }}
          <div class="mt-8 d-flex justify-center" v-if="amIModerator">
            <div class="mx-3 pointer">
              <v-btn class="primary" @click="setMoreTime()">{{
                $t("components.userAgreementModal.ok")
              }}</v-btn>
              <v-btn class="primary" @click="endCall()">{{
                $t("components.beforeEndingCallModal.end")
              }}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import { setCallEndedInfoModalEvent } from "../../effector/modals/";
export default {
  components: { HeaderModal },
  props: [
    "showModal",
    "closeModal",
    "hangUpBridgeCall",
    "addMoreTime",
    "callUUID",
    "amIModerator",
  ],
  data: () => ({
    state: store.state,
    currentCallDuration: 0
  }),
  mounted(){
    if (this.state.remoteBridgeStreams && this.state.remoteBridgeStreams[this.callUUID]) {
      this.currentCallDuration = this.state.remoteBridgeStreams[this.callUUID].callDurationMs;
    }
  },
  destroyed(){
    if (this.currentCallDuration == (this.state.remoteBridgeStreams || {})[this.callUUID]?.callDurationMs) {
      setCallEndedInfoModalEvent(true);
    }
  },
  watch: {
    "state.remoteBridgeStreams": {
      immediate: true,
      deep: true,
      handler: function (newValue) {
        if (
          newValue && (
            !newValue[this.callUUID] ||
            (this.currentCallDuration !== newValue[this.callUUID].callDurationMs)
          )
        ) {
          if (this.showModal) {
            this.closeModal(false);
          }
          if (!newValue[this.callUUID]) {
            setCallEndedInfoModalEvent(true);
          }
        }
      },
    },
  },
  methods: {
    setMoreTime() {
      const newDuration = 300000;
      this.addMoreTime(newDuration);
      this.closeModal(false);
    },
    endCall() {
      this.hangUpBridgeCall();
      this.ringAudioFinalCall();
      this.closeModal(false);
    },
    ringAudioFinalCall() {
      try {
        const audio = new Audio("/media/endCall.mp3");
        audio
          .play()
          .catch((error) =>
            console.warn("Failed to play notification audio", error)
          );
      } catch (error) {
        console.warn("Failed to play notification audio", error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>