<template>
  <v-dialog
    v-model="deleteVisitorPaylinkModal.showModal"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('generics.info')"
        :closeModalFunction="closeModal"
      />
       <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
       <v-card-text>
          <p v-if="deleteVisitorPaylinkModal.isPaylink" class="breakSpaces">{{ $t("components.inviteVisitor.deleteVisitorPaylink") }}</p>
          <p v-else class="breakSpaces">{{ $t("components.inviteVisitor.deleteVisitorAmountPay") }}</p>
       </v-card-text>
      </v-row>
      <FooterModal :closeModalFunction="closeModal" >
          <v-btn color="primary" @click="deleteVisitor" :loading="loading">{{
          $t("generics.ok")
        }}</v-btn>
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { deleteVisitorPaylinkModalStore, resetDeleteVisitorPaylinkModalEvent } from "../../effector/modals";
import { deleteVisitor } from "../../utils/waitingRoom";
import { timelineEvents } from "../../effector/timeline";
export default {
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
      loading: false
    };
  },
  effector: {
    deleteVisitorPaylinkModal: deleteVisitorPaylinkModalStore,
    timelineEvents: timelineEvents,
  },
  methods: {
    closeModal(){
      resetDeleteVisitorPaylinkModalEvent();
    },
    async deleteVisitor() {
      this.loading = true;
      await deleteVisitor(this.deleteVisitorPaylinkModal.showModal, this.timelineEvents);
      this.loading = false;
      this.closeModal();
    },
  },
  computed: {
  },
};
</script>
<style scoped lang="scss">
.breakSpaces{
  white-space: break-spaces;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
