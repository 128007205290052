<template>
  <v-dialog
    v-model="uploadAvatarModalData.showModal"
    persistent
    max-width="550"
    @keydown.esc="closeModal"
  >
    <HeaderModal
      :titleModal="$t('components.userManagement.changePicture')"
      :closeModalFunction="closeModal"
    />
    <v-card-text>
      <div class="d-flex justify-center" style="position: relative">
        <v-row class="d-flex mx-auto pt-5 pb-2 pl-3">
          <v-col cols="12" class="py-0">
            <vue-croppie
              ref="croppieRefSquare"
              class="sliderSize"
              :enableResize="false"
              :enforceBoundary="true"
              :enableExif="true"
              :boundary="{ width: 256, height: 256 }"
              :viewport="{ width: 246, height: 246, type: 'square' }"
            ></vue-croppie>
          </v-col>
          <div class="rotateIcons">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="grey" @click="rotate(-90)">
                  <font-awesome-icon
                    :icon="['fal', 'undo']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.navbar.rotateLeft") }}</span>
            </v-tooltip>
            <br />
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="grey" @click="rotate(90)">
                  <font-awesome-icon
                    :icon="['fal', 'undo']"
                    :style="{ fontSize: '20px' }"
                    class="fa-flip-horizontal"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.navbar.rotateRight") }}</span>
            </v-tooltip>
          </div>
        </v-row>
      </div>
    </v-card-text>
    <FooterModal :closeModalFunction="closeModal" :showFooter="true">
      <v-btn class="buttonPrimaryFooter" color="white" @click="crop()">{{
        $t("generics.save")
      }}</v-btn>
    </FooterModal>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "./modalComponents/headerModal.vue";
import FooterModal from "./modalComponents/footerModal.vue";
import {
  uploadAvatarModalStore,
  resetUploadAvatarModalEvent,
} from "../../effector/modals";
export default {
  components: { HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
  }),
  effector: {
    uploadAvatarModalData: uploadAvatarModalStore,
  },
  methods: {
    closeModal() {
      resetUploadAvatarModalEvent();
    },
    async crop() {
      const options = {
        type: "base64",
        size: { width: 256, height: 256 },
        format: "jpg",
      };
      const setAvatarPromise = this.$refs.croppieRefSquare
        .result(options)
        .then((output) =>
          store.setAvatar(output, this.uploadAvatarModalData.userId)
        );
      await Promise.all([setAvatarPromise]);
      this.closeModal();
    },
    rotate(rotationAngle) {
      this.$refs.croppieRefSquare.rotate(rotationAngle);
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.uploadAvatarModalData.imageUrl) {
        this.$refs.croppieRefSquare.bind({
          url: this.uploadAvatarModalData.imageUrl,
        });
      }
    }, 400);
  },
};
</script>

<style scoped lang="scss">
.croppie-container {
  height: unset;
}
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: 2px solid #02889d !important;
}
.rotateIcons {
  position: absolute;
  right: 10%;
  top: 40%;
}
</style>
<style lang="scss">
.sliderSize .cr-slider-wrap {
  width: 100%;
  margin: 15px auto;
}
.sliderSize .cr-slider {
  width: 216px;
}
</style>