
<template>
  <vue-scroll :class="`${!isMobile ? 'vueScrollFooter' : 'vueScrollFooterMobile'}`">
    <v-row :style="{width: '100%', margin: 0}" class="px-12 mx-0">
      <v-row :style="{width: '100%', margin: 0}" class="mx-0">
        <v-col cols="12" class="px-0">
          <h1 class="title textColor">{{ $t('components.contact.contactVoffice') }}</h1>
        </v-col>
        <v-col cols="12" class="px-0">
          <div class="subtitle-1">{{ $t('components.contact.weAreHereToHelp') }}</div>
          <div class="subtitle-1">{{ $t('components.contact.weLookForwardToHearing') }}</div>
        </v-col>
      </v-row>
      <v-row :style="{width: '100%', margin: 0}" class="pt-5 mx-0">
        <v-col xs="12" lg="6" sm="12" md="12" xl="6">
          <template>
            <v-card v-if="!isRaMicro" class="mx-auto" max-width="700" flat>
              <!-- add google maps -->
              <iframe
                width="100%"
                height="250"
                frameborder="0"
                style="border:0"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDP2EtzMnb8DHaLF5giLCw64PFLNtBp1ns&q=Washingtonplatz+3,+Cube+Berlin+10557+Berlin,+Germany"
                allowfullscreen
              ></iframe>
              <v-card-text class="text--primary">
                <v-row :style="{width: '100%', margin: 0}" class="mx-0">
                  <v-col xs="12" lg="6" sm="6" md="6" xl="6" class="px-0 d-flex">
                    <div class="align-self-center">
                      <font-awesome-icon :icon="['fal', 'map-marker']" :style="{ fontSize: '20px' } " />
                    </div>
                    <div class="px-5">
                      <!-- <div>Bittube International SE</div> -->
                      <div v-if="!isRaMicro">RA-MICRO Software AG</div>
                      <div>Washingtonplatz 3, Cube Berlin</div>
                      <div>10557 Berlin</div>
                    </div>
                  </v-col>
                  <v-col xs="12" lg="6" sm="6" md="6" xl="6" class="px-0 flex-column">
                    <div class="d-flex mb-5">
                      <div>
                        <font-awesome-icon :icon="['fal', 'phone']" :style="{ fontSize: '20px' } " />
                      </div>
                      <div class="px-5">
                        <div>+49 30 43598 - 802</div>
                      </div>
                    </div>
                    <div class="d-flex mb-5">
                      <div>
                        <font-awesome-icon
                          :icon="['fal', 'paper-plane']"
                          :style="{ fontSize: '20px' } "
                        />
                        
                      </div>
                      <div class="px-5">
                        <!-- <div>info@bittube-int.com</div> -->
                        <div v-if="!isRaMicro">info@ra-micro.de</div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row :style="{width: '100%', margin: 0}" class="mx-0">
                  <v-col cols="12" class="px-0 d-flex">
                    <!-- <div class="caption">© 2020 - Bittube International SE</div> -->
                    <div class="caption"  v-if="!isRaMicro">© 2024 - RA-MICRO Software AG</div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card v-if="isRaMicro" class="mx-auto" max-width="700" flat>
              <!-- add google maps -->
              <iframe
                width="100%"
                height="250"
                frameborder="0"
                style="border:0"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDP2EtzMnb8DHaLF5giLCw64PFLNtBp1ns&q=Washingtonpl.+3,+10557+Berlin,+Germany"
                allowfullscreen
              ></iframe>
              <v-card-text class="text--primary">
                <v-row :style="{width: '100%', margin: 0}" class="mx-0">
                  <v-col xs="12" lg="6" sm="6" md="6" xl="6" class="px-0 d-flex">
                    <div class="align-self-center">
                      <font-awesome-icon :icon="['fal', 'map-marker']" :style="{ fontSize: '20px' } " />
                    </div>
                    <div class="px-5">
                      <div>RA-MICRO Software AG</div>
                      <div>WashingtonPlatz 3, Cube</div>
                      <div>10557 Berlin</div>
                    </div>
                  </v-col>
                  <v-col xs="12" lg="6" sm="6" md="6" xl="6" class="px-0 flex-column">
                    <div class="d-flex mb-5">
                      <div>
                        <font-awesome-icon :icon="['fal', 'phone']" :style="{ fontSize: '20px' } " />
                      </div>
                      <div class="px-5">
                        <div>+49 30 43598 - 802</div>
                      </div>
                    </div>
                    <div class="d-flex mb-5">
                      <div>
                        <font-awesome-icon
                          :icon="['fal', 'paper-plane']"
                          :style="{ fontSize: '20px' } "
                        />
                      </div>
                      <div class="px-5">
                        <div>voffice@ra-micro.de</div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row :style="{width: '100%', margin: 0}" class="mx-0">
                  <v-col cols="12" class="px-0 d-flex">
                    <div class="caption">© 2024 - RA-MICRO Software AG</div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-col>
        <v-col xs="12" lg="6" sm="12" md="12" xl="6" class="text-right">
          <template>
            <v-card class="mx-auto" max-width="700" flat>
              <!-- <v-img
            class="white--text align-end"
            height="200px"
            src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
              ></v-img>-->
              <v-card-text class="text--primary">
                <p class="title text-left textColor">{{ $t('components.contact.sendUsAMessage') }}</p>
                <template>
                  <v-form v-model="valid">
                    <v-text-field
                      v-model="name"
                      :label="$t('components.contact.name')"
                      required
                      :rules="[v => !!v || this.$t('generics.nameRequired')]"
                    ></v-text-field>
                    <v-text-field v-model="email" :label="$t('generics.email')" required :rules="emailRules"></v-text-field>
                    <v-text-field
                      v-model="message"
                      :label="$t('components.contact.typeYourMessageHere')"
                      required
                      :rules="[v => !!v || this.$t('components.contact.messageRequired') ]"
                    ></v-text-field>
                    <v-alert
                      transition="slide-x-transition"
                      text
                      color="info"
                      dismissible
                      v-if="showResult"
                    >{{result}}</v-alert>
                    <v-btn
                      class="mr-4"
                      color="primary"
                      @click="onSubmit"
                      :disabled="!valid || loading"
                      :loading="loading"
                    >{{ $t('components.contact.submit') }}</v-btn>
                    <!-- <vue-recaptcha
                      @verify="onVerify"
                      @expired="onExpired"
                      size="invisible"
                      ref="recaptcha"
                      sitekey="6LfHWukUAAAAAMFmIhhFvLDKjwkzx0OfzzPbRPzk"
                      :loadRecaptchaScript="true"
                    ></vue-recaptcha> -->
                  </v-form>
                </template>
              </v-card-text>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </v-row>
  </vue-scroll>
</template>

<script>
import { webLicensedBaseFeatures } from '../../../sharedsrc/licensedFeatures';
// import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
// import { validationMixin } from "vuelidate";
// import { required, maxLength, email } from "vuelidate/lib/validators";
// import VueRecaptcha from "vue-recaptcha";

export default {
  props: ["primaryDrawer"],
  // components: { VueRecaptcha },
  data() {
    return {
      name: "",
      email: "",
      message: "",
      emailRules: [
        v => !!v || this.$t('components.firstModal.emailRequired'),
        v => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) || this.$t('components.firstModal.emailMustValid')
      ],
      result: "",
      showResult: false,
      valid: false,
      loading: false,
      isMobile: isMobile()
    };
  },
  mounted(){
    // document.getElementById('drawerContentCard').classList.add('hideElement');
  },
  methods: {
    onSubmit: function() {
      this.loading = true;
      // this.$refs.recaptcha.execute();
      return this.onVerify(true);
    },
    onVerify: async function(response) {
      this.email = this.email.trim().toLowerCase();
      if (this.name && this.email && this.message && response) {
        const result = await fetch("/api/contact/send", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            message: this.message,
            "g-recaptcha-response": response
          })
        }).then(res => res.json());
        if (result.success) {
          // EMAIL SENT
          this.result = "Message sent successfully";
          this.showResult = true;
          this.loading = false;
          this.name = "";
          this.email = "";
          this.message = "";
        } else {
          // COULD NOT SEND EMAIL
          this.result = "An error occurred, please try later";
          this.showResult = true;
          this.loading = false;
        }
      }
    },
    onExpired: function() {
      this.result = "Captcha challenge expired";
      this.showResult = true;
    },
    resetRecaptcha() {
      // this.$refs.recaptcha.reset();
    },
  },
  computed: {
    isRaMicro () {
      return (webLicensedBaseFeatures.isRaMicro || webLicensedBaseFeatures.isRaMicroCustomer);
    }
  }
};
</script>

<style scoped lang="scss">
.hideElement {
  opacity: 0;
}
#updateIcon {
  animation: blink 1s linear infinite;
}
.vueScrollFooter {
  height: calc(100% - 350px) !important;
}
.vueScrollFooterMobile {
  height: calc(100% - 400px) !important;
}
.textColor {
  color: var(--v-primary-base);
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>