var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"750","height":"95%"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModal.apply(null, arguments)}},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',[(!_vm.editMode)?_c('v-card-title',{staticClass:"headline",class:_vm.setBackground,style:(("color:" + (_vm.setBackground !== 'white' && _vm.setBackground !== null
          ? 'white'
          : 'unset')))},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"px-1 py-1",attrs:{"cols":"6"}},[_c('v-img',{staticClass:"mr-1 logoModal",attrs:{"src":"assets/icon.png","max-height":"30","max-width":"30","contain":""}}),_vm._v("\n          "+_vm._s(_vm.$t("components.eventEntryForm.plannedVideoCall"))+"\n        ")],1),_vm._v(" "),(!_vm.editMode)?_c('v-col',{staticClass:"px-1 py-1 text-right",attrs:{"cols":"6"}},[_c('span',{staticClass:"font13"},[_vm._v(_vm._s(_vm.getStartDate))]),_vm._v(" "),_c('span',{staticClass:"font13"},[_vm._v(_vm._s(_vm.getTimeRange))]),_vm._v(" "),(_vm.canRSVP)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.rsvp(true)}}},on),[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'check']}})],1)]}}],null,false,3350554969)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.meetingLineModal.accept")))])]):_vm._e(),_vm._v(" "),(_vm.canRSVP)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.rsvp(false)}}},on),[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'times']}})],1)]}}],null,false,241254994)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.meetingLineModal.reject")))])]):_vm._e()],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('v-list-item',{staticClass:"py-0 switchWrapper",class:{ 'px-0': _vm.editMode }},[_c('NewEventForm',{directives:[{name:"show",rawName:"v-show",value:(_vm.editMode),expression:"editMode"}],ref:"entryForm",attrs:{"editMode":_vm.editMode,"existingEvent":_vm.modalEntry,"hideButtons":true,"setBackground":_vm.setBackground}}),_vm._v(" "),_c('ViewEvent',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editMode),expression:"!editMode"}],attrs:{"entry":_vm.modalEntry}})],1),_vm._v(" "),_c('v-card',{staticClass:"pl-4 pr-2 footerModal",attrs:{"color":"primary"}},[_c('v-row',{staticClass:"px-2 btns w-100 mx-0 my-0"},[[_c('v-list',{staticClass:"menuFooter",style:({ display: 'inline-flex' }),attrs:{"color":"primary"}},[(_vm.editMode)?_c('v-list-item',{staticClass:"mx-3"},[(_vm.editMode)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"closeButton",attrs:{"disabled":!_vm.canDeleteEvent,"icon":"","color":"white"}},on),[_c('DeleteBasket',{attrs:{"indexData":"","delFunction":_vm.deleteEvent,"extraClasses":"","fontSize":"25","prevent":"false"}})],1)]}}],null,false,408043105)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("generics.delete")))])]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.editMode)?_c('v-list-item',{staticClass:"mx-3"},[(_vm.editMode)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"closeButton",attrs:{"disabled":!_vm.canEditEvent,"icon":"","color":"white"},on:{"click":function($event){return _vm.resetForm()}}},on),[_c('font-awesome-icon',{style:({ fontSize: '25px' }),attrs:{"icon":['fal', 'undo']}})],1)]}}],null,false,3530317123)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.eventEntryForm.reset")))])]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.editMode)?_c('v-list-item',{staticClass:"mx-3"},[(_vm.editMode)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"closeButton",attrs:{"disabled":!_vm.canAddEvent,"icon":"","color":"white"},on:{"click":function($event){return _vm.saveEvent()}}},on),[_c('font-awesome-icon',{style:({ fontSize: '25px' }),attrs:{"icon":['fal', 'check']}})],1)]}}],null,false,4611462)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("generics.save")))])]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.haveExistingEntry && _vm.canEditEvent)?_c('v-list-item',{staticClass:"mx-3"},[(_vm.haveExistingEntry && _vm.canEditEvent)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"closeButton",attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.toggleEdit()}}},on),[(_vm.editMode)?_c('font-awesome-icon',{style:({ fontSize: '25px' }),attrs:{"icon":['fal', 'file']}}):_c('font-awesome-icon',{style:({ fontSize: '25px' }),attrs:{"icon":['fal', 'edit']}})],1)]}}],null,false,3839066117)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.editMode ? _vm.$t("generics.info") : _vm.$t("generics.edit")))])]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('v-list-item',{staticClass:"mx-3"},[(_vm.isMyConference)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"closeButton",attrs:{"icon":"","color":"white","disabled":!_vm.canCallEveryone},on:{"click":function($event){return _vm.callEveryone()}}},on),[_c('font-awesome-icon',{style:({ fontSize: '25px' }),attrs:{"icon":['fal', 'phone']}})],1)]}}],null,false,2136876348)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.eventEntryForm.callUsers")))])]):_vm._e()],1),_vm._v(" "),_c('v-list-item',{staticClass:"mx-3"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"closeButton",attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.closeModal()}}},on),[_c('font-awesome-icon',{style:({ fontSize: '25px' }),attrs:{"icon":['fal', 'times']}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("generics.close")))])])],1)],1)]],2)],1),_vm._v(" "),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }