<template>
  <v-dialog v-model="showModal" persistent max-width="620" @keydown.esc="closeModal">
    <v-card>
      <HeaderModal
        :titleModal="$t('components.adminSettingsModal.settingPay')"
        :closeModalFunction="closeModal"
      />
      <v-card-text>
        <div class="d-flex justify-center">
          <v-row>
            <v-col>
              <p>
                {{ $t("components.adminSettingsModal.payInfoText1") }}
              </p>
              <p>
                {{ $t("components.adminSettingsModal.payText2") }}
              </p>
              <p>
                <!-- {{ $t("components.adminSettingsModal.payStartSentence") }}  -->
                <span class="font-weight-bold">
                  {{
                    $t("components.adminSettingsModal.payOfficePaylink")
                  }}</span
                >
                 {{ $t("components.adminSettingsModal.paytext3") }} 
              </p>
              <p>
                <!-- {{ $t("components.adminSettingsModal.payStartSentence") }}  -->
                <span class="font-weight-bold">
                  {{
                    $t("components.adminSettingsModal.payOfficeFacepya")
                  }}</span
                >
                 {{ $t("components.adminSettingsModal.payText4") }} 
              </p>
              <p>
                {{ $t("components.adminSettingsModal.payText5", ['RA-MICRO Software AG']) }}
              </p>
              <!-- <v-checkbox
                v-model="terms"
                class="mt-0"
                :label="$t('components.adminSettingsModal.terms')"
              ></v-checkbox>
              <v-checkbox
                v-model="policy"
                class="mt-0"
                :label="$t('components.adminSettingsModal.policy')"
              ></v-checkbox>
              <v-checkbox
                v-model="declaration"
                class="mt-0"
                :label="$t('components.adminSettingsModal.declaration')"
              ></v-checkbox> -->
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <FooterModal
        :hideDefaultButtons="true"
        :closeModalFunction="closeModal"
        :showFooter="true"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-2"
              v-on="on"
              color="primary"
              @click="saveSettings"
              >{{ $t("generics.ok") }}</v-btn
            >
          </template>
          <span>{{ $t("generics.ok") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-2"
              v-on="on"
              color="primary"
              @click="closeCancelModal()"
              >{{ $t("generics.cancel") }}</v-btn
            >
          </template>
          <span>{{ $t("generics.cancel") }}</span>
        </v-tooltip>
      </FooterModal>
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import { setNamespaceSetting } from "../../lib/wsMsg";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  props: ["showModal", "closeModal"],
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
      terms: false,
      policy: false,
      declaration: false,
    };
  },
  methods: {
    saveSettings() {
      setNamespaceSetting("paySetting", true);
      this.closeModal();
    },
    closeCancelModal() {
      setNamespaceSetting("paySetting", false);
      this.closeModal(true);
    },
  },
  computed: {
    disabledOkButton() {
      if (!this.terms || !this.policy || !this.declaration) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped lang="scss">
</style>
