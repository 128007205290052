<template>
  <div>
    <v-dialog v-model="state.showAddToGroupModal" persistent max-width="550" @keydown.esc="closeModal">
      <v-card>
        <div>
          <HeaderModal
            :titleModal="titleModal"
            :closeModalFunction="closeModal"
          />
          <v-row flex class="w-100 mx-0 my-0"></v-row>
          <v-form
            style="width: 100%; margin: 0"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-row style="width: 100%; margin: 0">
              <v-col class="col-12 px-2 py-2">
                <GroupList />
              </v-col>
            </v-row>
          </v-form>
          <FooterModal :closeModalFunction="closeModal">
            <v-btn
              :disabled="(state.groupSelected || undefined) === undefined"
              @click="handleNewGroupMember"
            >
              {{ $t("generics.save") }}
            </v-btn>
          </FooterModal>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "../../store";
import GroupList from "../../components/content/groupList.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
//personToAddToGroup
export default {
  components: { GroupList, HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
      newTeamName: "",
      activeItem: null,
      closeOnClick: true,
      closeOnContent: false,
      isDepartment: false,
      selectedDepartment: [],
      selectionSearchTerm: "",
      valid: true,
    };
  },
  watch: {},
  methods: {
    closeModal() {
      store.state.showAddToGroupModal = false;
      this.clearObject();
    },
    handleNewGroupMember() {
      let canAddMember = true;
      const omembers = store.state.user.userGroups.find(
        (x) => x.id === document.store.state.groupSelected
      ).members;
      const user = store.state.personToAddToGroup.uuid;
      const name = store.state.personToAddToGroup.user.name;
      omembers.forEach((member) => {
        if (member.uuid === user) {
          canAddMember = false;
        }
      });

      if (canAddMember) {
        const data = { name: name, uuid: user };
        omembers.push(data);
        store.state.user.userGroups.find(
          (x) => x.id === document.store.state.groupSelected
        ).members = omembers;
        this.closeModal();
      }
    },
    clearObject() {
      store.state.showAddToGroupModal = false;
      store.state.personToAddToGroup = undefined;
      store.state.groupSelected = undefined;
    },
  },
  computed: {
    titleModal() {
      return this.$t("components.groups.addToGroupModal", [
        ((this.state.personToAddToGroup || "").user || {}).name || "",
      ]);
    },
    requiredRule() {
      return [
        (v) => !!v || this.$t("components.addTeamModal.teamNameRequired"),
        (v) =>
          !this.doesNameExists ||
          this.$t("components.addTeamModal.teamAlreadyExists"),
      ];
    },
    departmentsChecked() {
      return this.selectedDepartment.length > 0;
    },
    departmentData() {
      const departments = Object.keys(
        this.state.namespaceSettings.processedOrganisation.departments || {}
      );
      const filteredDepartments = departments.filter(
        (name) =>
          !this.selectionSearchTerm ||
          name.toLowerCase().indexOf(this.selectionSearchTerm.toLowerCase()) !==
            -1
      );
      return filteredDepartments;
    },
    doesNameExists() {
      return !!((this.state.namespaceSettings.processedOrganisation || {})
        .teams || {})[this.newTeamName.trim()];
    },
  },
};
</script>
<style scoped lang="scss">
.selactive {
  background-color: #c1c1c166;
}
.w-100 {
  width: 100%;
}
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  color: #02889d;
}
.dataUserCard {
  margin-bottom: 0px;
}
.borderRadius10,
.v-avatar .v-image {
  border-radius: 10px !important;
}
.w-200 {
  width: 200px;
}
.w-100-230 {
  width: calc(100% - 230px);
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
