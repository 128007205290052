<template>
    <div id="inviteVisitorMenu">
    <v-list class="invitationMenuList" :width="width" dense>
      <v-card-title class="headline">
          <v-img class="mr-2" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
          {{ typeInvite==='guest'? $t('components.inviteGuestPanel.headlineGuest') : $t('components.inviteGuestPanel.headlineUser') }}
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click.stop.prevent="showInviteInfo = true" style="margin-left: auto;">
                <font-awesome-icon :icon="['fal', 'info-circle']" :style="{ fontSize: '30px' }" />
              </v-btn>
            </template>
            <span>{{  $t('generics.info') }}</span>
          </v-tooltip>
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-list-item>
            <v-list-item-title @click.stop.prevent>
              <v-form ref="form" v-model="valid" lazy-validation>
                <div class="px-3">
                  <v-row style="width: 100%; margin: 0">
                    <v-col class="col-6 px-2 py-0">
                      <v-text-field
                        v-model="username"
                        :label="$t('components.inviteGuestPanel.name')"
                        required
                        :rules="userNameRules"
                      ></v-text-field>
                    </v-col>
                    <v-col class="col-6 px-2 py-0">
                      <v-text-field
                        v-model="email"
                        :label="$t('components.inviteGuestPanel.eMail')"
                        required
                        :rules="emailRules"
                        @blur="checkEmailPreSend()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row style="width: 100%; margin: 0" v-if="!isBasicView">
                    <v-col class="col-6 px-2 py-0">
                      <v-text-field
                        v-model="title"
                        :label="$t('components.inviteGuestPanel.title')"
                      ></v-text-field>
                    </v-col>
                    <v-col class="col-6 px-2 py-0">
                      <v-text-field
                        v-model="position"
                        :label="$t('components.inviteGuestPanel.position')"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row style="width: 100%; margin: 0" v-if="teams && !isBasicView">
                    <v-col cols="12" class="py-0 px-2">
                      <v-autocomplete :label="$t('components.inviteGuestPanel.teams')" v-model="selectedTeams" :items="teams" item-value="team" item-text="team" multiple clearable />
                    </v-col>
                  </v-row>

                  <v-row class="mx-0">
                    <v-col cols="12" class="py-0 px-2">
                      <!-- <v-radio-group v-model="radios" class="mt-0 height35"> -->
                      <v-row class="mx-0">
                        <!-- <v-col cols="5" class="px-0 py-0">
                          <v-radio :label="$t('generics.guest')" value="guest" @change="loadGuestCustomMessage"></v-radio>
                        </v-col>-->
                        <!-- sendInviteInfoModal -->
                        <!-- <v-col style="display:flex;" cols="2" class="px-0 py-0">
                          <v-btn
                            color="primary"
                            class="btnSend"
                            @click="showInviteInfo = true"
                          >Info</v-btn>
                        </v-col>-->
                        <!-- <v-col cols="5" class="px-0 py-0">
                          <v-radio :label="$t('generics.user')" value="user" @change="loadCustomMessage"></v-radio>
                        </v-col>-->
                      </v-row>
                      <!-- </v-radio-group> -->
                    </v-col>
                  </v-row>

                  <v-row class="mx-auto" style="width: 100%; margin: 0; padding:0px">
                    <div class="d-flex align-baseline">
                  
                    <v-tooltip top max-width="350">
                      <template v-slot:activator="{ on }">
                          <v-switch v-on="on"
                            v-if="amIAdmin && typeInvite !== 'guest'"
                            class="my-0 py-2 mr-6"
                            v-model="createAdmin"
                            width="100%"
                          >
                          <span slot="label" v-on="on">{{ $t('components.inviteGuestPanel.adminRights') }}</span>
                          </v-switch>
                        </template>
                      <span>{{ $t('components.inviteGuestPanel.adminRightsText') }}</span>
                    </v-tooltip>

                    <v-tooltip top max-width="350">
                      <template v-slot:activator="{ on }">
                          <v-switch v-on="on"
                            v-if="amIAdmin && typeInvite !== 'guest'"
                            class="my-0 py-2 mr-6"
                            @change="activateUserSupport()"
                            v-model="userSupport"
                            width="100%"
                          >
                          <span slot="label" v-on="on">{{$t('components.inviteGuestPanel.inviteUserSupport')}}</span>
                          </v-switch>
                        </template>
                      <span>{{$t('components.inviteGuestPanel.inviteSupportText')}}</span>
                    </v-tooltip>
              
                    <v-tooltip top max-width="350">
                      <template v-slot:activator="{ on }">
                      <v-checkbox
                      v-on="on"
                        class="mr-6"
                        v-model="pinActive"
                      >
                      <span slot="label" v-on="on">{{$t('components.2faModal.PINInviteUser')}}</span>
                      </v-checkbox>
                      </template>
                      <span>{{$t('components.2faModal.btnEnablePINText')}}</span>
                    </v-tooltip>
                      <v-text-field
                        v-on="on"
                        class="mr-6"
                        v-if="pinActive"
                        autocomplete="off"
                        v-model="pinCode"
                        :label="$t('components.inviteGuestPanel.enterInvitationPinCode')"
                        outlined
                        dense
                        @keydown.space.prevent
                        :maxlength="6"
                      ></v-text-field>
                        <!-- :rules="[
                        v => !!v || this.$t('components.2faOtp.codeRequired'),
                        v => v.length === 6 || '',
                        v => /^\d{6}$/.test(v) || this.$t('components.2faPin.sixDigitsRequired'),
                      ]" -->
                    </div>
                  </v-row>

                  <v-row style="width: 100%; margin: 0">
                    <v-col class="col-12 px-2 py-2">
                      <v-textarea
                        v-model="customMessage"
                        name="inputinvite"
                        :no-resize="true"
                        class="py-0 my-0"
                        rows="7"
                      ></v-textarea>
                        <!-- height="135" -->
                    </v-col>
                  </v-row>

                  <!-- // support user -->
                  <!-- <v-row v-if="amIAdmin && typeInvite !== 'guest'" class="mx-auto" style="width: 100%; margin: 0; padding:0px">
                  <v-col>
                    <v-switch
                      class="my-0 py-2"
                      @change="activateUserSupport()"
                      v-model="userSupport"
                      :label="$t('components.inviteGuestPanel.inviteSupport')"
                      width="100%"
                    ></v-switch>
                  </v-col>
                  </v-row>-->
                  <!-- endd support user -->
                  <v-row style="width: 100%; margin: 0; padding:0px" v-if="errMssg">
                    <v-col style="height: 24px;margin: 0px;padding: 0px;color:red;" cols="12">
                      <p style="float: right; margin-right: 45px;" class="danger">{{errMssg}}</p>
                    </v-col>
                  </v-row>
                </div>

                <v-card class="py-2 pl-4 mt-4 pr-2 footerModal">
                  <v-row class="btns mx-0" flex>
                    <v-col cols="12" class="px-0 py-0" align="right">
                      <v-btn
                          v-if="amIAdmin"
                          @click="deleteCustomMessage"
                          color="primary"
                        >{{ $t('components.inviteGuestPanel.deleteCustomInviteText') }}
                     </v-btn>
                      <v-btn
                          v-if="amIAdmin"
                          @click="saveCustomMessage"
                          color="primary"
                        >{{ $t('components.inviteGuestPanel.saveText') }}
                      </v-btn>
                      <v-btn
                          v-if="amIAdmin"
                          color="primary"
                          @click="showListInvite ? showListInvite = false : showListInvite = true;"
                          :loading="sendingInvitation"
                        >{{ $t('components.inviteGuestPanel.inviteList') }}
                      </v-btn>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :disabled="!valid || disableButtonSend"
                            color="primary"
                            @click="sendInvitation(false);"
                            :loading="sendingInvitation"
                          >{{ $t('components.inviteGuestPanel.sendAndNew') }}</v-btn>
                        </template>
                        <span>{{ $t('components.inviteGuestPanel.sendAndNewText') }}</span>
                      </v-tooltip>
                      <v-btn
                          :disabled="!valid || disableButtonSend"
                          color="primary"
                          @click="sendInvitation"
                          :loading="sendingInvitation"
                        >{{ $t('components.inviteGuestPanel.sendInvitation') }}</v-btn>
                        <v-btn
                          color="primary"
                          @click.stop.prevent="closeMenu(); cleanData();"
                        >{{ $t('generics.cancel') }}</v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-form>
            </v-list-item-title>
          </v-list-item>
          <v-divider v-if="state.persisted.invitationsSent.length > 0"></v-divider>
          <v-list-item  v-if="state.persisted.invitationsSent.length > 0 && showListInvite">
            <v-list-item-title>
              <v-card>
                <!-- <v-card-title
                :style="{fontSize: '14px', paddingTop: 10, paddingLeft: 0, paddingBottom: 0, paddingRight: 0}"
              >
                <font-awesome-icon
                  :icon="['fal', 'history']"
                  :style="{ fontSize: '14px', marginRight: 10 }"
                />
                {{ $t('components.inviteGuestPanel.history') }}
                </v-card-title>-->
                <v-container :style="{paddingLeft: 0,paddingRight: 0}">
                  <div v-for="(invitation, index) in getInvitationList" :key="index">
                    <div class="d-flex">
                      <p class="email">
                        {{invitation.email}}
                        <font-awesome-icon
                          v-if="invitation.uuid"
                          v-show="hasUuidBeenOnPage(invitation.uuid)"
                          :icon="['fal', 'check-double']"
                        />
                      </p>
                      <p class="date">{{moment(invitation.date).format("DD.MM.YYYY | H:mm")}}</p>
                    </div>
                    <v-divider class="mt-2 mb-2"></v-divider>
                  </div>
                </v-container>
              </v-card>
            </v-list-item-title>
          </v-list-item>
        </v-row>
      </v-container>
        <!-- <v-row v-if="showModalInfo">
          <v-list-item>
          {{$t('components.inviteGuestPanel.infoButton')}}
            </v-list-item>
          </v-row> -->
        <!-- <v-dialog v-model="dialog" persistent max-width="460">
          <v-card>
            <v-card-title class="headline">{{ $t('components.userListItem.invitationSent') }}</v-card-title>
            <v-card-text>
              {{ $t('components.userListItem.yourInvitationTo') }}
              <b>{{state.sendInvitationGuestModal.invitationData.name}} ({{state.sendInvitationGuestModal.invitationData.email}})</b>
              {{ $t('components.userListItem.hasBeenSuccessfullySent') }}
            </v-card-text>
            <v-spacer></v-spacer>
          </v-card>
        </v-dialog>-->
    </v-list>
    <sendInviteInfoModal
      v-if="showInviteInfo"
      :typeInvite="typeInvite"
      :closeShowInviteInfo="closeShowInviteInfo"
      :showInviteInfo="showInviteInfo"
    />
    <infoModalTypeInvitation  v-if="showModalInfo" :showInfoModalTypeInvitation="showModalInfo" :closeshowInfoModalTypeInvitation="closeshowInfoModalTypeInvitation" />
    <InfoPinModal v-if="showPinInfoModal" :showPinInfoModal="showPinInfoModal" :closePinInfoModal="closePinInfoModal" /> 
  </div>
</template>

<script>
import store from "../../store";
import {
  addUserInviters,
  createNewUser,
  isEmailRegistered,
  sendInviteMailCustom,
  setNamespaceSetting
} from "../../lib/wsMsg";
import moment from "../../../sharedsrc/moment";
import sendInviteInfoModal from "../modal/sendInviteInfoModal.vue";
import infoModalTypeInvitation from "./infoModalTypeInvitation.vue";
import InfoPinModal from "../modal/infoPinModal.vue";
import { basicView } from "../../utils/privileges";
export default {
  components: { sendInviteInfoModal, infoModalTypeInvitation, InfoPinModal },
  props: ["closeMenu", "width", "maxwidth", "fromRoot", "typeInvite"],
  data() {
    return {
      state: store.state,
      createAdmin: false,
      username: "",
      email: "",
      position: "",
      title: "",
      showInviteInfo: false,
      sendButtonDisabled: false,
      errMssg: "",
      customMessage: "",
      pinActive: false,
      pinCode: "",
      moment: moment,
      valid: true,
      sendingInvitation: false,
      selectedTeams: [],
      // dialog: false,
      // radios: "guest",
      userNameRules: [
        v => !!v || this.$t("components.inviteGuestPanel.usernameRequired")
      ],
      emailRules: [
        v => !!v || "", //remove red mesagge ticket #132
        v => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) || ""
      ],
      userSupport: false,
      showModalInfo: false,
      showListInvite: false,
      showPinInfoModal: false,
    };
  },
  methods: {

    closePinInfoModal(){
      this.showPinInfoModal = false;
    },
    closeshowInfoModalTypeInvitation(){
      this.showModalInfo = false;
    },
    activateUserSupport() {
      //console.log(this.userSupport, "userSupport");
    },
    closeShowInviteInfo() {
      this.showInviteInfo = false;
    },
    hasUuidBeenOnPage(uuid) {
      const person = this.state.group[uuid];
      return ((person || {}).user || {}).rtcCallStart !== undefined;
    },
    loadActualMessage() {
      if (this.typeInvite === "guest") {
        this.loadGuestCustomMessage();
      } else {
        this.loadCustomMessage();
      }
    },
    loadGuestCustomMessage() {
      this.customMessage =
        store.state.namespaceSettings.customInviteGuestMessage;
    },
    loadCustomMessage() {
      this.customMessage = store.state.namespaceSettings.customInviteMessage;
    },
    deleteCustomMessage() {
      this.customMessage = "";
      if (this.typeInvite === "guest") {
        store.state.namespaceSettings.customInviteGuestMessage = this.customMessage;
        setNamespaceSetting("customInviteGuestMessage", this.customMessage);
      } else {
        store.state.namespaceSettings.customInviteMessage = this.customMessage;
        setNamespaceSetting("customInviteMessage", this.customMessage);
      }
    },
    saveCustomMessage() {
      if (this.typeInvite === "guest") {
        store.state.namespaceSettings.customInviteGuestMessage = this.customMessage;
        setNamespaceSetting("customInviteGuestMessage", this.customMessage);
      } else {
        store.state.namespaceSettings.customInviteMessage = this.customMessage;
        setNamespaceSetting("customInviteMessage", this.customMessage);
      }
    },
    async checkEmailPreSend() {
      const email = this.email.trim().toLowerCase();
      const re = /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/;
      if (!re.test(email)) {
        this.valid = true;
        this.errMssg = "";
        return;
      }
      const emailRegistered = await isEmailRegistered(email);
      const isGuest = !!(this.typeInvite === "guest");
      const newUUID = emailRegistered.key;
      if (
        emailRegistered.found &&
        !((this.state.group[newUUID] || {}).user || {}).guest
      ) {
        this.valid = false;
        this.errMssg = this.$t("components.inviteGuestPanel.errAlreadyUser");
      } else {
        this.errMssg = "";
      }
    },
    async sendInvitation(close = true) {
      if (this.$refs.form.validate()) {
        this.sendingInvitation = true;
        this.email = this.email.trim().toLowerCase();
        const emailRegistered = await isEmailRegistered(this.email);
        const isGuest = !!(this.typeInvite === "guest");
        const newUUID = emailRegistered.key;
        if (
          emailRegistered.found &&
          !((this.state.group[newUUID] || {}).user || {}).guest
        ) {
          this.sendingInvitation = false;
          this.valid = false;
          this.errMssg = this.$t("components.inviteGuestPanel.errAlreadyUser");
          return;
        }
        if (
          emailRegistered.found &&
          ((this.state.group[newUUID] || {}).user || {}).visitor
        ) {
          this.sendingInvitation = false;
          this.valid = false;
          this.errMssg = this.$t(
            "components.inviteGuestPanel.errAlreadyVisitor"
          );
          return;
        }
        if (isGuest) {
          store.setUuidInInvited(newUUID, true);
        }
        if (!emailRegistered.found) {
          const options =
            this.pinActive && this.pinCode
              ? { "2fa": { pin: { code: this.pinCode } } }
              : {};
          if (this.userSupport) {
            options.userSupport = true;
          }
          if (this.createAdmin) {
            options.createAdmin = true;
          }
          if (this.selectedTeams.length > 0) {
            options.selectedTeams = this.selectedTeams
          }
          await createNewUser(
            {
              sections: [],
              locations: [],
              teams: [],
              position: this.position,
              qualification: "",
              titel: this.title,
              name: this.username,
              email: this.email,
              accessmail: "",
              phone: "",
              extension: "",
              mobilePhone: "",
              device: "",
              hours: "",
              homeHours: "",
              coreHours: "",
              guest: isGuest,
              visitor: false,
              inviters: {
                [this.state.ownUUID]: true
              }
            },
            newUUID,
            options
          );
          await sendInviteMailCustom(newUUID, {
            name: this.username,
            email: this.email,
            guest: isGuest,
            customMessage: this.customMessage
          });
        } else {
          await addUserInviters(newUUID);
        }
        const invitationObject = {
          uuid: newUUID,
          name: this.username,
          email: this.email,
          guest: isGuest,
          date: new Date(),
          customMessage: this.customMessage
        };
        store.state.sendInvitationGuestModal = {
          newInvitation: true,
          invitationData: invitationObject
        };
        store.state.persisted.invitationsSent.push(invitationObject);
        if (this.closeMenu && close) {
          this.closeMenu();
        }
        this.cleanData()
        this.state.showInvitationSent = true;
        // this.email = "";
        // this.username = "";
        // this.title = "";
        // this.position = "";
        // this.createAdmin = false;
        // this.selectedTeams = [];
        // this.customMessage = this.state.namespaceSettings.customInviteGuestMessage;
        // this.sendingInvitation = false;
        setTimeout(() => {
          // if (this.dialog) {
          //   this.dialog = false;
          // }
          if (this.state.showInvitationSent) {
            this.state.showInvitationSent = false;
          }
        }, 2000);
      }
    },
    cleanData(){
        // this.state.showInvitationSent = true;
        this.email = "";
        this.username = "";
        this.title = "";
        this.position = "";
        this.createAdmin = false;
        this.selectedTeams = [];
        // this.customMessage = "";
        this.customMessage = this.state.namespaceSettings.customInviteGuestMessage;
        this.sendingInvitation = false;
    }
  },
  computed: {
    disableButtonSend(){
      return (this.isEmailInvalid || (!this.username || this.username.length == 0))
    },
    isEmailInvalid() {
      if (this.email && this.email.length > 0) {
        const email = this.email.trim().toLowerCase();
        const re = /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/;
        return !re.test(email);
      } else {
        return true;
      }
    },
     isBasicView(){
      return basicView();
    },
    teams() {
      const organisation = this.state.namespaceSettings.organisation;
      if (organisation){
        return organisation && organisation.filter(it => it.departmentRights !== 1 && it.sectionRights !== 1)
      }
    },
    getInvitationList() {
      // return this.state.persisted.invitationsSent;
      const newArray = [...this.state.persisted.invitationsSent];
      return newArray.reverse();
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    }
  },
  watch: {
    fromRoot: {
      handler: function(value) {
        if (value === true) {
          this.userSupport = false;
          this.loadActualMessage();
        }
      },
      deep: true
    },
    typeInvite: {
      immediate: true,
      handler: function() {
        this.userSupport = false;
        this.loadActualMessage();
      }
    }
  }
};
</script>
<style scoped lang="scss">
.v-menu__content {
  min-width: unset !important;
}
.v-list {
  padding: 0;
}

.v-list-item {
  padding: 0;
}

.container {
  padding-bottom: 0;
  padding-top: 0;
} 

.logoModal{
  display: inline-block;
  top: 9px;
}
.titleModalInvite{
    text-align: center;
    margin-left: calc(50% - 100px);
}
.height35 {
  height: 35px;
}
form {
  font-weight: normal;
  input {
    font-size: 14px !important;
  }
}
.btnSend {
  float: right;
  // margin-bottom: 20px;
}
.tooltip-inner {
    white-space: pre-line;
}
.d-flex {
  p {
    &.email {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 65%;
      color: #02889d;
      margin: 0px;
    }
    &.date {
      width: 40%;
      font-size: 12px;
      vertical-align: middle;
      margin: 4px;
      text-align: right;
    }
  }
}
.btns {
  justify-content: flex-end;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.invitationMenuList {
  width: calc(100vw - 0px);
  padding-top: 0px;
  max-width: 1024px;
  max-height: 820px;
  // max-height: 768px;
}
</style>

<style lang="scss">
#inviteVisitorMenu {
  height: 100% !important;
  & .v-label {
    font-weight: 500 !important;
    font-size: 14px;
  }
  &  .invitationMenuList{
    width: 100% !important;
    padding-top: 0px;
    max-width: 100% !important;
    max-height: 100% !important;
    min-height: 100%;
  }
}
.v-dialog--fullscreen {
  z-index: 999999 !important;
}
</style>