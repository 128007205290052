import store, { EventBus } from '../store';
import { userInCall, wsCallStartBridgeCall } from "../utils/calls";
import { getTimezone } from "../utils/basicFunctions";
import { hasPrivilege } from "../utils/privileges";
import { myConferenceDetails, newGroupConferenceEvent } from "../effector/groupConferences";
import { markAsCallWaitingRoomUserEvent } from "../effector/users/waitingInfo";
import { createTimelineEntryEffect } from "../effector/timeline";
import { dispatchErrorAlert } from "../effector/alerts";
import moment from "../../sharedsrc/moment";
import { v4 as uuidv4 } from "uuid";

function usersInCall() {
    return userInCall(store.state.ownUUID);
  }

export async function startInstaConference($router) {
    const members = [{
        name: store.state.user.name,
        uuid: store.state.ownUUID,
        isModerator: true,
        isGuest: false,
        isGroup: false,
        isTeam: false,
        openModalSettingsCall: undefined,
        tempDocumentStore: null,
    }]
    // create temp object
    if ( usersInCall() ) return;
    let tempConf;
    const time = new Date();
    const startEvent = moment(time).format("YYYY-MM-DD");
    const timeStart = moment(time).format("HH:mm");
    const dataConference = {
      confIsTemp: true,
      isUnlimited: true,
      conferenceId: uuidv4(),
      confName: "Instaconference",
      conferenceDescription: "Instaconference-Temp",
      conferenceImage: store.state.namespaceSettings.companyLogo
        ? store.state.namespaceSettings.companyLogo
        : "/img/icon.png",
      conferenceISOStartDate: new Date(),
      startEvent: startEvent,
      endEvent: startEvent,
      timeStart: timeStart,
      timeEnd: timeStart,
      durationMin: null,
    };
    tempConf = await saveConference(dataConference, members);
    if (tempConf && tempConf.confId) {
        const myConferenceDetailsStore = myConferenceDetails.getState();
      const myConference = myConferenceDetailsStore.find(
        (e) => e.confId == tempConf.confId
      );
      if (myConference && myConference.confId) {
        callGroup(myConference, members, $router);
        EventBus.$emit("openModalSettingsCall");
      }
    }
  }
  function setdurationMeeting(duration = null) {
    return store.setdurationMeeting(duration || 300000);
  }
  async function callGroup(conf, members, $router) {
    const duration = 24 * 3600 * 1000;
    setdurationMeeting(duration);
    if (conf && conf.confUUIDS.length > 0) {
      const usersToCall = [];
      const usersFunction = [];
      // if (store.$route.path !== "/home") {
      //   $router.push({ path: "/home" });
      // }
      conf.confUUIDS.forEach((user) => {
        if (user !== store.state.ownUUID) {
          const isConnected =
            (store.state.group[user] || {}).connected || false;
          const person = store.state.group[user];
          if (
            person &&
            person.user &&
            (!person.user.rtcCallStatus.length ||
              0 > 5 ||
              !person.user.inBridgeCall)
          ) {
            if (!usersInCall()) {
              if (
                isConnected &&
                (((store.state.group[user] || {}).user || {}).activity ||
                  false) !== "Holidays"
              ) {
                usersToCall.push(user);
              }
            }
            if (!usersFunction[user]) {
              if (
                store.state.group[user].user.position ||
                store.state.group[user].user.visitorData?.function
              ) {
                usersFunction[user] =
                  store.state.group[user].user.position ||
                  store.state.group[user].user.visitorData.function;
              } else {
                usersFunction[user] = "";
              }
            }
          }
        }
      });
      const id = uuidv4();
      const info = {
        callUUID: id,
        calling: usersToCall,
        isNormalMode: true,
        initiator: store.state.ownUUID,
        callStartTs: Date.now(),
        callDurationMs: duration,
        infiniteCall: false,
        isConference: true,
        conferenceAttendees: getAttendeesObject(conf.confUUIDS),
        isConferenceCall: conf.confId || undefined,
        confModerators: await getModerators(members),
        confId: conf.confId,
      };
      usersToCall.forEach(async (user, idx) => {
        const infoStartBridgeCall = {
          callUUID: id,
          isNormalMode: conf.confUUIDS.length > 0 ? true : false,
          initiator: store.state.ownUUID,
          callStartTs: Date.now(),
          callDurationMs: info.callDurationMs,
          infiniteCall: false,
          conferenceDocuments: [],
          userConferencePosition: usersFunction || [],
          excludedFromCalling: [],
          conferenceAttendees: getAttendeesObject(conf.confUUIDS),
          conferenceSettings: {},
          usersToHideName: [],
          isConference: true,
          confId: conf.confId,
          isConferenceCall: conf.confId || undefined,
          confModerators: await getModerators(members),
          isPinProtected: conf.confPin.length > 0,
          pinCode: conf.confPin.length > 0 ? conf.confPin : false,
        };
        if (!hasPrivilege(user)) {
          markAsCallWaitingRoomUserEvent(user);
        }
        wsCallStartBridgeCall(
          user,
          store.state.ownUUID,
          infoStartBridgeCall,
          idx > 0
        );
      });
      store.addRemoteBridgeStream(id, info);
      store.setCurrentContentVisile("", false, $router);
    }
  }

  async function getModerators(members) {
    let mods = [];
    members.forEach((member) => {
      if (member.isModerator === true) {
        mods.push(member.uuid);
      }
    });
    return mods;
  }

  function getAttendeesObject(uuids) {
    let myMembers = [];
    uuids.forEach((uuid) => {
      if (uuid) {
        const aMember = {
          uuid,
          name: store.getNameForUuid(uuid),
          addUserToCall: true,
          showUserName: true,
        };
        myMembers.push(aMember);
      }
    });
    return myMembers;
  }

  function zeroFill(time) {
    return ("0" + time).slice(-5);
  }

  async function createTimelineEntry(data) {
    const dateTimeStart =
      data.confStartDate + "T" + zeroFill(data.confStartTime);
    const dateTimeEnd =
      data.confEndDate + "T" + zeroFill(data.confEndTime);
    const tmpData = {
      confId: data.confId,
      name: data.confName,
      details: data.confDescription,
      isTemp: data.confIsTemp,
      users: data.confUUIDS,
      start: new Date(dateTimeStart),
      end: new Date(dateTimeEnd),
      color: "orange",
      type: "videoCall",
      host: data.confOwner,
      title: data.confName.trim(),
      eventType: "bridgeCall",
      isUnlimited: data.isUnlimited,
      isReOcurring: data.confIsReocurring || false,
      confIsReoInterval: data.confIsReoInterval || null,
      confIsReoFrequency: data.confIsReoFrequency || null,

      viewed: {},
      rsvp: true,
    };
    const meeting = await createTimelineEntryEffect(tmpData);
    return meeting;
  }

  function extractOnlyUsers(owner, members) {
    const allUsers = extractUsers(owner, members);
    let onlyUsers = [];
    if (allUsers && allUsers.length) {
      onlyUsers = allUsers.filter(
        (e) => (store.state.group[e] || {}).permissions !== 10
      );
    }
    return onlyUsers;
  }
  function extractUsers(owner, members) {
    let users = [];
    members.forEach((member) => {
        // is not need it because a instant conference always starts with a individual normal user
    //   if (member.isTeam) {
    //     const extant =
    //       store.state.namespaceSettings.processedOrganisation.teams[
    //         member.name
    //       ];
    //     const tempMembers = extant.members;
    //     extant.supervisors.forEach((element) => {
    //       if (tempMembers.indexOf(element.uuid) == -1) {
    //         if (store.state.group[element.uuid]) {
    //           tempMembers.push(element.uuid);
    //         }
    //       }
    //     });
    //     tempMembers.forEach((id) => {
    //       if (users.indexOf(id) == -1) {
    //         if (store.state.group[id]) {
    //           users.push(id);
    //         }
    //       }
    //     });
    //   }
    //   else if (member.isGroup) {
    //     if (store.state.group[member.uuid]) {
    //       const extant = store.state.group[owner].user.groups.find(
    //         (e) => e.groupId == member.uuid
    //       );
    //       const extantMmembers = extant.groupMembers;
    //       const members = extantMmembers.map((member) => member.uuid);
    //       members.forEach((el) => {
    //         if (users.indexOf(el) == -1) {
    //           users.push(el);
    //         }
    //       });
    //     }
    //   } else if
      if(member.uuid) {
        if (store.state.group[member.uuid]) {
          if (users.indexOf(member.uuid) == -1) {
            users.push(member.uuid);
          }
        }
      }
    });
    return users;
  }

  async function saveConference(dataConference, members) {
    // save conference
    let transResults = false;
    let confObject = {};
    if (dataConference) {
      try {
        confObject = {
          confId: dataConference.conferenceId,
          confIsTemp: dataConference.confIsTemp,
          confName: dataConference.confName,
          confDescription: dataConference.conferenceDescription,
          confOwner: store.state.ownUUID,
          confModerators: [store.state.ownUUID],
          confPin: "",
          confStartDate: dataConference.startEvent,
          confStartTime: dataConference.timeStart,
          confEndDate: dataConference.isUnlimited
            ? dataConference.startEvent
            : dataConference.endEvent,
          confEndTime: dataConference.isUnlimited
            ? dataConference.timeStart
            : dataConference.timeEnd,
          confTimezone: getTimezone(dataConference.startEvent, dataConference.timeStart),
          confDuration: dataConference.isUnlimited
            ? 0
            : dataConference.durationMin,
          isUnlimited: dataConference.isUnlimited,
          confIsReocurring: false,
          confIsReoInterval: null,
          confIsReoFrequency: null,
          members: members,
          confUUIDS: await extractUsers(store.state.user.uuid, members),
          confRealUsers: await extractOnlyUsers(store.state.user.uuid, members),
          creationServerTs: Date.now(),
          updatedServerTs: Date.now(),
          confAvatar: dataConference.conferenceImage,
          timelineEventId: undefined,
        };
        await newGroupConferenceEvent(confObject);
        transResults = true;
      } catch (err) {
        console.warn("Error creating conference", err);
        return dispatchErrorAlert("Error creating conference");
      }
    } else {
      return dispatchErrorAlert("Error creating conference");
    }
    // // CREATE TIMELINE EVENT TO ALL USERS
    if (transResults) {
      transResults = false;
      confObject.rsvp = true;
      const event = await createTimelineEntry(confObject);
      if (event) {
        return event;
      }
    }
  }