<template>
  <!-- UNUSED MODAL -->
  <v-dialog
    v-model="showModal"
    persistent
    max-width="560"
    @keydown.esc="closeModal"
  >
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('components.inviteVisitor.textTitle')"
          :closeModalFunction="closeModal"
        />
       <v-card-text class="py-0 pt-4">
          <v-text-field
            v-model="textTitle"
            required
            outlined
            dense
            maxlength="50"
            counter
            :error-messages="titleExists ? $t('components.inviteVisitor.templateExists') : null"
          />
       </v-card-text>
        <FooterModal :closeModalFunction="closeModal">
          <v-btn  @click="saveTextAs(textTitle)" :disabled="titleExists || textTitle.length == 0">
            {{ $t("generics.save") }}
          </v-btn>
        </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  props: ["showModal", "closeModal", "saveTextAs"],
  data: () => ({
    state: store.state,
    textTitle: "",
  }),
  mounted: function () {
  },
  methods: {
  },
  computed:{
      titleExists(){
        const exists = this.state.namespaceSettings.inviteGuestCustomTexts.findIndex(item => item.title === this.textTitle)
        return exists === -1 ? false : true;
      }
  }
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>