<template>
  <div>
    <WebCam
      ref="webcam"
      :device-id="deviceId"
      @error="onError"
      @cameras="onCameras"
      @camera-change="onCameraChange"
      :width="width"
      :height="height || 500"
    />
  </div>
</template>

<script>
import store from "../../store";
import { WebCam } from "vue-web-cam";
import { setErrorCameraPermissionsModalEvent } from "../../effector/modals";
export default {
  props: ["closeCamera", "width", "height"],
  components: { WebCam },
  data: () => ({
    state: store.state,
    deviceId: null,
    devices: [],
  }),
  mounted() {
    this.getSetting();
  },
  watch: {
    devices: function () {
      this.getSetting();
      if (this.deviceId) return;
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.deviceId = first.deviceId;
      }
    },
  },
  methods: {
    getSetting() {
      const videoSetting = store.getRtcVideo();
      if (videoSetting && videoSetting.deviceId) {
        this.deviceId = videoSetting.deviceId;
      }
    },
    settmpSnapshot: function (...args) {
      store.settmpSnapshot(...args);
    },
    onError(error) {
      if (error.name === "NotAllowedError") {
        setErrorCameraPermissionsModalEvent(true);
        this.closeCamera();
      }
      store.setErrorWebcam(true);
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
    },
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
};
</script>
