<template>
  <v-dialog
    v-model="showErrorPayment"
    class="transparentDialog"
    persistent
    max-width="570"
    hide-overlay
    :attach="'#videos'"
  >
    <template>
      <v-card class="mx-auto">
        <!-- Title -->
        <HeaderModal
          :titleModal="$t('components.paymentModal.titleRequestPayment')"
          :closeModalFunction="declinePayment"
          :actualDate="actualDate"
        />
        <!-- End title -->
        <!-- Modal body -->
        <v-card-text>
          <v-row class="mx-0 mb-2">
            <v-col cols="12" v-show="isPayPalSandbox">
              <p class="mb-0 colorRed">
                {{ $t("components.paymentModal.testMode") }}
              </p>
            </v-col>
            <v-col cols="12">
              <p class="mb-0">
                {{ $t("components.paymentModal.paymentError") }}.
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- End modal body -->
        <!-- Footer -->
        <v-card color="primary" class="py-2 pl-4 pr-2 footerModal">
          <v-row class="px-2 btns w-100 mx-0 my-0">
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    outlined
                    icon
                    color="white"
                    @click="declinePayment($event)"
                    class="ml-2 closeButton"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'times']"
                      :style="{ fontSize: '20px' }"
                    />
                  </v-btn>
                </template>
                <span>{{ $t("generics.close") }}</span>
              </v-tooltip>
            </template>
          </v-row>
        </v-card>
        <!-- End footer -->
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import store from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import { getPayPalRegistrationSubscriptionEnv } from "../../../lib/wsMsg";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";
import moment from "moment";
export default {
  components: { HeaderModal },
  props: [],
  data: () => ({
    state: store.state,
    isPayPalSandbox: false,
    isMobile: isMobile(),
  }),
  created() {
    // Using getPayPalRegistrationSubscriptionEnv as it exposes the mode PayPal is configured in the environment
    getPayPalRegistrationSubscriptionEnv()
      .then(({ mode }) => {
        this.isPayPalSandbox = mode === "sandbox";
      })
      .catch((err) =>
        console.warn("getPayPalRegistrationSubscriptionEnv", err)
      );
  },
  methods: {
    declinePayment(event) {
      event.preventDefault();
      event.stopPropagation();
      this.state.user.paymentState.showErrorPayment = false;
      this.state.user.paymentState.attachTo = false;
    },
  },
  computed: {
    actualDate() {
      return moment(new Date(Date.now())).format("DD.MM.YYYY HH:mm");
    },
    showErrorPayment() {
      return (
        this.state.user.paymentState.showErrorPayment &&
        this.state.user.paymentState.attachTo
      );
    },
  },
};
</script>

<style scoped lang="scss">
.closeButton {
  border-radius: 4px;
  min-width: 45px;
}
.theme--light .v-dialog {
  .v-card {
    background: rgba(255, 255, 255, 0.7);
    word-break: break-word;
  }
}
.theme--dark .v-dialog {
  .v-card {
    background: rgba(30, 30, 30, 0.7);
    word-break: break-word;
  }
}
@media screen and (min-width: 993px) {
  .v-dialog__content {
    position: absolute !important;
  }
}
.colorRed {
  color: red;
}
</style>
<style lang="scss">
.videBridgeContainer {
  .v-dialog {
    background-color: transparent !important;
  }
}
</style>