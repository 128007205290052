<template>
  <v-list
    two-line
    v-if="
      messagesArray.length !== 0 ||
      Object.keys(state.FileTransferModal.files).length !== 0
    "
    :min-height="310"
    style="overflow: hidden"
  >
    <vue-scroll class="vueScroll">
      <div style="height: 310px">
        <v-list-item-group id="messagesDropDown">
          <div v-if="Object.keys(state.FileTransferModal.files).length !== 0">
            <div
              v-for="(entry, key) in state.FileTransferModal.files"
              v-bind:key="key"
            >
              <FileItemList :entry="entry" />
            </div>
          </div>
          <div v-for="(message, index) in messagesArray" :key="index">
            <v-list-item
              @click="openNotification(message)"
              :key="index"
              v-if="message"
            >
              <template v-slot:default="{}">
                <font-awesome-icon
                  v-if="!isSentMessage(message)"
                  class="mr-4"
                  :icon="['fal', 'inbox-in']"
                />
                <font-awesome-icon
                  v-if="isSentMessage(message)"
                  class="mr-4"
                  :icon="['fal', 'inbox-out']"
                />
                <v-list-item-avatar
                  tile
                  size="60"
                  class="borderRadius10 cursorPointer hidden-sm-and-down"
                  :style="{
                    border: `2px solid ${setBorderByStatus(
                      state.group[getDataUserMessage(message)]
                    )}`,
                  }"
                >
                  <DefaultAvatarGrid
                    :size="120"
                    :userData="state.group[getDataUserMessage(message)]"
                    v-if="
                      getAvatarForUuid(getDataUserMessage(message)) ==
                      'img/default_profile_picture.png'
                    "
                  ></DefaultAvatarGrid>
                  <v-img
                    v-if="
                      getAvatarForUuid(getDataUserMessage(message)) !=
                      'img/default_profile_picture.png'
                    "
                    class="mx-2 borderRadius10"
                    max-height="60"
                    max-width="60"
                    contain
                    :src="getAvatarForUuid(getDataUserMessage(message))"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content class="pl-2">
                  <!-- :class="( (message.type==='message' || message.type === 'plannerEvent')&& !message.users[ownUUID].read) ? 'font-weight-bold textBlue':''" -->
                  <v-list-item-title
                    v-text="getNameForUuid(getDataUserMessage(message))"
                    :class="unreadNotification(message)"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    class="text--primary"
                    v-text="getTextBody(message)"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="ml-0">
                  <v-list-item-action-text
                    v-text="formatNoticationDate(message.date)"
                  ></v-list-item-action-text>
                  <div class="d-flex" style="position: relative">
                    <DeleteBasket
                      :indexData="message"
                      :delFunction="deleteNotification"
                      extraClasses="mr-4"
                      fontSize="16"
                      prevent="true"
                    />
                    <v-tooltip
                      top
                      v-if="
                        !isWaitingRoomUser &&
                        (message.type == 'message' ||
                          message.type == 'plannerEvent')
                      "
                    >
                      <!-- !isSentMessage(message) && -->
                      <template v-slot:activator="{ on }">
                        <font-awesome-icon
                          v-on="on"
                          class="mr-4"
                          @click.stop.prevent="
                            setShowModalSenMsg(
                              getDataUserMessage(message),
                              'message'
                            )
                          "
                          :icon="['fal', 'paper-plane']"
                        />
                        <!-- <font-awesome-icon
                          :icon="['fal', 'lock']"
                          :style="{ fontSize: '10px' }"
                          class="lockMessageIcon"
                        /> -->
                        <!-- v-bind:disabled="mailBoxFull(state.group[message.creatorUUID]) === true" -->
                      </template>
                      <span>{{
                        $t("components.userProfile.sendAMessage")
                      }}</span>
                      <!-- <span>{{mailBoxFull(state.group[message.creatorUUID]) ? $t('components.userListItem.mailboxFull') : $t('components.userProfile.sendAMessage') }}</span> -->
                    </v-tooltip>

                    <v-tooltip
                      top
                      v-if="
                        message.type === 'call' &&
                        !isWaitingRoomUser &&
                        !isSentMessage(message)
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <font-awesome-icon
                          v-on="on"
                          class="mr-4"
                          :icon="['fal', 'phone']"
                          @click.stop="
                            disableCallBtn(message.creatorUUID)
                              ? false
                              : callUser(message.creatorUUID)
                          "
                          :color="
                            !disableCallBtn(message.creatorUUID)
                              ? setBorderByStatus(
                                  state.group[message.creatorUUID]
                                )
                              : 'grey'
                          "
                        ></font-awesome-icon>
                      </template>
                      <span>{{
                        disableCallBtn(message.creatorUUID)
                          ? "Dieser Benutzer ist zur Zeit offline"
                          : $t("components.userProfile.callUser")
                      }}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="message.type === 'file'">
                      <template>
                        <!-- <v-btn v-on="on" icon disabled> -->
                        <font-awesome-icon
                          class="mr-4"
                          style="pointer-events: none"
                          :icon="['fal', 'download']"
                          color="grey lighten-1"
                        ></font-awesome-icon>
                        <!-- </v-btn> -->
                      </template>
                      <span>{{
                        $t("components.downloadsDropdown.fileNotAvailable")
                      }}</span>
                    </v-tooltip>
                  </div>
                </v-list-item-action>
              </template>
            </v-list-item>
          </div>
        </v-list-item-group>
      </div>
    </vue-scroll>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          style="float: right; margin: 5px 5px 0px 0px"
          class="btnRed"
          icon
          color="white"
        >
          <DeleteBasket
            indexData=""
            :delFunction="removeAllListedItemsEffect"
            extraClasses=""
            fontSize="20"
            prevent="false"
          />
        </v-btn>
      </template>
      <span>{{ $t("components.downloadsDropdown.removeAll") }}</span>
    </v-tooltip>
  </v-list>
</template>

<script>
import store from "../../store";
import moment from "../../../sharedsrc/moment";
import striptags from "striptags";
import DeleteBasket from "../../components/ui/deleteBasket.vue";
import DefaultAvatarGrid from "../image/defaultAvatarGrid.vue";
import { callPerson, disableCallBtn, amInABridgeCall } from "../../utils/calls";
import { getStatusColorByUser } from "../../utils/status";
// import { hasRepresentative } from "../../utils/representatives";
import { isWaitingRoomUser, hasPrivilege } from "../../utils/privileges";
import {
  messagesReceivedAndSent,
  dispatchDeleteAllMessageEvent,
  dispatchDeleteMessageEvent,
  dispatchDeleteAllSentMessageEvent,
  dispatchDeleteConversationEvent,
} from "../../effector/message";
import {
  setNotificationModalEvent,
  // setSendMessageModalEvent,
  setHistoryMessagesModalEvent
} from "../../effector/modals";
import FileItemList from "./fileItemList.vue";
export default {
  props: ["notificationType", "closeMenu", "menuOpen"],
  components: { DefaultAvatarGrid, FileItemList, DeleteBasket },
  data() {
    return {
      state: store.state,
      persisted: store.state.persisted,
      moment: moment,
      user: store.state.user,
      ownUUID: store.state.ownUUID,
    };
  },
  watch: {
    menuOpen: {
      // immediate: true,
      deep: true,
      handler: function (value) {
        if (value && document.getElementById("messagesDropDown")) {
          setTimeout(() => {
            document
              .getElementById("messagesDropDown")
              .parentElement.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
              });
          }, 250);
        }
      },
    },
  },
  effector: {
    messagesToMe: messagesReceivedAndSent,
  },
  methods: {
    deleteNotification(message) {
      switch (message.type) {
        case "call":
        case "file":
          this.removeMessage(
            message.creatorUUID,
            message.messageUUID,
            undefined
          );
          break;
        case "message":
        case "plannerEvent":
          this.deleteFullConversation(this.getDataUserMessage(message));
          break;

        // case "plannerEvent":
        //   this.removeIndividualMessageEffect(message.messageUUID);
        //   break;

        default:
          break;
      }
    },
    deleteFullConversation(userUUID) {
      dispatchDeleteConversationEvent(userUUID);
      this.closeMenu();
    },
    getTextBody(msg) {
      switch (msg.type) {
        case "call":
          return this.$t("components.notificationsModal.missedCall");
        case "file":
          return `${this.$t("generics.file")}: ${msg.fileKey}`;
        case "message":
        case "plannerEvent":
          return msg.body || "New message";
        default:
          return "New message";
      }
      // message.type === 'call' ? $t('components.notificationsModal.missedCall') : message.body || 'New message'
    },
    fixHtml(body) {
      const idx = body.indexOf("<br");
      return striptags(idx !== -1 ? body.substring(0, idx) : body);
    },
    openNotification(message) {
      if (message.type == "call") {
        this.setShowModalNotify(message.creatorUUID, {
          id: message.messageUUID,
          type: "call",
          notification: message.info,
          date: message.date,
        });
      } else if (message.type == "message") {
        // setNotificationModalEvent(message.messageUUID);
        const userUUID = this.getDataUserMessage(message);
        setNotificationModalEvent(message.messageUUID);
      } else if (message.type === "plannerEvent") {
        setNotificationModalEvent(message.messageUUID);
      }
    },
    removeAllListedItemsEffect() {
      dispatchDeleteAllMessageEvent();
      dispatchDeleteAllSentMessageEvent();
      this.removeAllCalls();
    },
    removeAllCalls: function (...args) {
      if (this.messagesArray.length > 0) {
        // console.log(this.messagesArray, "messagesArray");
        this.messagesArray.forEach((message) => {
          if (message.type === "call" || message.type == "file") {
            this.removeMessage(
              message.creatorUUID,
              message.messageUUID,
              undefined
            );
          }
        });
      } else if (Object.keys(this.state.FileTransferModal.files).length !== 0) {
        for (const key in this.state.FileTransferModal.files) {
          if (
            Object.hasOwnProperty.call(this.state.FileTransferModal.files, key)
          ) {
            const element = this.state.FileTransferModal.files[key];
            if (element.incoming && element.complete) {
              const uuid = element.uuid;
              const fileName = element.fileName;
              const msgUUID = element.msgUUID;
              // console.log(element, uuid, fileName, msgUUID);
              store.delFileTransferFor(uuid, fileName);
              this.removeMessage(uuid, msgUUID, undefined);
            }
          }
        }
      }
    },
    removeIndividualMessageEffect(messageUUID) {
      dispatchDeleteMessageEvent(messageUUID);
    },
    // hasRepresentative(uuid) {
    //   return hasRepresentative(uuid);
    // },
    formatNoticationDate(date) {
      return this.moment(date).format("DD.MM.YYYY, H:mm") + " " + this.$t("components.meetingLineModal.hours");
    },
    setShowModalSenMsg(uuid, type = "") {
      this.closeMenu();
      // return store.setShowModalSenMsg(uuid, type);
      // setSendMessageModalEvent({
      //   show: true,
      //   users: [uuid],
      //   type: "regularMsg",
      // });
      setHistoryMessagesModalEvent(uuid)
    },
    mailBoxFull(person) {
      return ((person || {}).user || {}).unreadMessageCounter > 9;
    },
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    setBorderByStatus(person) {
      if (!person || !person.user) return;
      return getStatusColorByUser(person.user, person.connected);
    },
    handleTotalNotifications(message, type = "unknown") {
      if (type !== "unknown") {
        switch (type) {
          case "message":
            this.setTotalNotificationMessage(message);
            break;
          case "ticket":
            this.setTotalNotificationTicket(message);
            break;
          case "call":
            this.setTotalNotificationCall(message);
            break;
          case "file":
            this.setTotalNotificationFile(message);
            break;
          default:
            break;
        }
      }
    },
    setTotalNotificationCall: function (message) {
      // let totalChips = this.state.persisted.totalNotificationCall;
      // if (!message.read && totalChips > 0) {
      //   store.setTotalNotificationCall(--totalChips);
      // }
      // if (!message.read) {
      //   store.setMessageAsRead(message.uuidMessage, message);
      // }
    },
    setTotalNotificationTicket: function (message) {
      let totalChips = this.state.persisted.totalNotificationTicket;
      if (!message.read && totalChips > 0) {
        store.setTotalNotificationTicket(--totalChips);
      }
      if (!message.read) {
        store.setMessageAsRead(message.uuidMessage, message);
      }
    },
    setTotalNotificationFile: function (message) {
      let totalChips = this.state.persisted.totalNotificationFile;
      if (!message.read && totalChips > 0) {
        store.setTotalNotificationFile(--totalChips);
      }
      if (!message.read) {
        store.setMessageAsRead(message.uuidMessage, message);
      }
    },
    setTotalNotificationMessage: function (message) {
      let totalChips = this.state.persisted.totalNotificationMessage;
      if (!message.read && totalChips > 0) {
        store.setTotalNotificationMessage(--totalChips);
      }
      if (!message.read) {
        store.setMessageAsRead(message.uuidMessage, message);
      }
    },
    getUserIsGuest(uuid) {
      return store.getUserIsGuest(uuid);
    },
    removeMessage: function (...args) {
      store.removeMessage(...args);
    },
    getNameForUuid: function (...args) {
      return store.getNameForUuid(...args);
    },
    getAvatarForUuid: function (...args) {
      return store.getAvatarForUuid(...args);
    },
    setShowModalNotify: function (...args) {
      store.setShowModalNotify(...args);
    },
    settotalNotification: function (...args) {
      store.settotalNotification(...args);
    },

    disableCallBtn(uuid) {
      let person = this.state.group[uuid];
      return disableCallBtn(person);
    },
    isSentMessage(message) {
      if (message && message.creatorUUID == this.ownUUID) {
        return true;
      } else {
        return false;
      }
    },
    getDataUserMessage(message) {
      if (this.isSentMessage(message)) {
        return Object.keys(message.users)[0];
      } else {
        return message.creatorUUID;
      }
    },
    unreadNotification(message) {
      const ownUUID = this.state.ownUUID;
      if (
        (message.type === "message" || message.type === "plannerEvent") &&
        message.users[ownUUID] &&
        !message.users[ownUUID].read
      ) {
        return "font-weight-bold textBlue";
      } else {
        return "";
      }
    },
  },
  computed: {
    hasPrivilege() {
      return hasPrivilege(this.ownUUID);
    },
    amInABridgeCall() {
      return amInABridgeCall(this.ownUUID);
    },
    isWaitingRoomUser() {
      return isWaitingRoomUser(this.ownUUID);
    },
    amInACall() {
      return !!Object.keys(this.state.rtc).length;
    },
    getEmptyText: function () {
      if (this.notificationType == "missedCalls") {
        return this.$t("components.notificationDropDown.noMissedCalls");
      } else if (this.notificationType == "incomingTickets") {
        return this.$t("components.notificationDropDown.noNewTasks");
      } else if (this.notificationType === "messages") {
        return this.$t("components.notificationDropDown.noNewMessages");
      } else if (this.notificationType === "uploads") {
        return this.$t("components.notificationDropDown.noNewFiles");
      } else {
        return this.$t("components.notificationDropDown.noNewNotifications");
      }
    },
    messagesArray: function () {
      if (this.notificationType === "message") {
        const messageUsers = [];
        const messagesAndCalls = [];
        for (let message of this.messagesToMe) {
          const usersStr = this.isSentMessage(message)
            ? Object.keys(message.users || {}).join()
            : message.creatorUUID;
          if (usersStr) {
            if (messageUsers.includes(usersStr)) continue;
            messageUsers.push(usersStr);
          }
          messagesAndCalls.push(message);
        }
        for (let userUUID in store.state.persisted.userMessages.messages) {
          const userMessages =
            store.state.persisted.userMessages.messages[userUUID];
          if (!userMessages) continue;
          for (let msgUUID in userMessages) {
            const message = userMessages[msgUUID];
            if (message.info.notificationType === "call") {
              const user = {};
              user[this.ownUUID] = {
                read: message.read,
                userUUID: this.ownUUID,
              };
              const tmpData = {
                body: "Incoming call",
                creatorUUID: userUUID,
                date: message.date,
                header: "Incoming call",
                messageUUID: message.uuidMessage,
                type: "call",
                users: user,
              };
              messagesAndCalls.push(tmpData);
            }
            if (message.info.notificationType === "file") {
              const fileKey = userUUID + "_" + message.info.fileName;
              const user = {};
              user[this.ownUUID] = {
                read: message.read,
                userUUID: this.ownUUID,
              };
              const tmpData = {
                body: "New file",
                creatorUUID: userUUID,
                date: message.date,
                header: "New file",
                messageUUID: message.uuidMessage,
                type: "file",
                fileKey: fileKey,
                users: user,
              };
              if (!store.state.FileTransferModal.files[fileKey]) {
                messagesAndCalls.push(tmpData);
              } else {
                store.state.FileTransferModal.files[fileKey].msgUUID = msgUUID;
              }
            }
          }
        }
        return messagesAndCalls.sort(function (a, b) {
          const aDate = new Date(a.date);
          const bDate = new Date(b.date);
          return aDate > bDate
            ? -1
            : aDate < bDate
            ? 1
            : (a.uuid || "").localeCompare(b.uuid || "");
        });
      }
    },
  },
  mounted: function mounted() {},
};
</script>

<style scoped lang="scss">
.lockMessageIcon {
  position: absolute;
  right: 28px;
  top: -6px;
}
.textBlue {
  color: #02889d;
}

.btnRed {
  // background: red;
  border-radius: 4px;
}
.vueScroll {
  .__view {
    width: unset !important;
  }
}
.divNotNotis {
  height: 40px;
  padding: 9px;
  .notNotifications {
    color: var(--form-text);
  }
}
</style>