<template>
  <div>
    <v-dialog persistent v-model="showFirstModal" max-width="570">
      <v-card>
        <div class="w-100">
          <v-row flex class="w-100 mx-0 my-0 justify-space-between">
            <v-col class="px-0 py-0" align="left">
              <v-card-title class="headline d-flex">
                <v-img
                  src="assets/icon.png"
                  max-height="30"
                  max-width="30"
                  contain
                ></v-img>
                <span
                  class="font-weight-medium pl-4 spanModalCall"
                  v-html="$t('components.firstModal.firstUserSetup')"
                ></span>
                <v-tooltip top v-if="amIAdmin">
                  <template v-slot:activator="{ on }">
                    <div class="pl-2 mb-2">
                      <v-btn v-on="on" color="primary" small icon>
                        <font-awesome-icon
                          color="primary"
                          :icon="['fal', 'info-circle']"
                          :style="{ fontSize: '17px' }"
                        />
                      </v-btn>
                    </div>
                  </template>
                  <span>{{ $t("components.firstModal.infoA") }}</span>
                  <br />
                  <span>{{ $t("components.firstModal.infoB") }}</span>
                </v-tooltip>
              </v-card-title>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
        <camera
          v-if="this.showCam"
          :getSnapshot="getSnapshot"
          :closeCamera="closeCamera"
        />
        <div class="pt-5" v-show="showCroppie">
          <vue-croppie
            class="sliderSize"
            ref="croppieRef"
            :enableResize="false"
            :enforceBoundary="true"
            :enableExif="true"
            :boundary="{ width: 216, height: 216 }"
            :viewport="{ width: 206, height: 206, type: 'square' }"
          ></vue-croppie>
          <div class="rotateIcons">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="grey" @click="rotate(-90)">
                  <font-awesome-icon
                    :icon="['fal', 'undo']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.navbar.rotateLeft") }}</span>
            </v-tooltip>
            <br />
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="grey" @click="rotate(90)">
                  <font-awesome-icon
                    :icon="['fal', 'undo']"
                    :style="{ fontSize: '20px' }"
                    class="fa-flip-horizontal"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.navbar.rotateRight") }}</span>
            </v-tooltip>
          </div>
          <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
            <v-row class="px-2 btns" flex :style="{ width: '100%', margin: 0 }">
              <v-col lg="6" class="px-0 py-0" align="right">
                <v-btn
                  style="color: #11889d !important"
                  depressed
                  class="mr-2"
                  color="white"
                  @click="crop"
                >
                  {{ $t("generics.save") }}
                </v-btn>

                <v-btn
                  color="white"
                  style="border-radius: 4px"
                  icon
                  tile
                  outlined
                  @click="cancelCrop"
                >
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-spacer></v-spacer>
        </div>
        <v-form
          ref="userForm"
          v-model="valid"
          lazy-validation
          :autocomplete="false"
          v-if="!showCroppie && !this.showCam"
        >
          <input
            type="file"
            ref="fileInput"
            class="d-none"
            @change="showCroppieModal($event)"
          />
          <v-row class="pt-2" :style="{ width: '100%', margin: 0 }">
            <v-col
              v-for="n in 2"
              :key="n"
              :lg="cols[n - 1]"
              :md="cols[n - 1]"
              :sm="cols[n - 1]"
              :xl="cols[n - 1]"
              class="col-12"
            >
              <div v-if="n == 1" align="center">
                <div>
                  <v-list-item-avatar
                    tile
                    size="190"
                    class="
                      mx-0
                      my-0
                      buttonOverflow
                      borderRadius4
                      borderRadius10
                    "
                  >
                    <div style="overflow: hidden" class="borderRadius4">
                      <v-img
                        v-if="!showCam"
                        class="borderRadius10"
                        max-height="190"
                        max-width="190"
                        contain
                        v-bind:src="state.user.avatar"
                        lazy-src="assets/default_profile_picture.png"
                      >
                        <div
                          style="width: 100%"
                          v-if="
                            state.user.avatar ==
                            'img/default_profile_picture.png'
                          "
                        >
                          <p
                            class="
                              imageTextWrapper
                              px-2
                              font-weight-bold
                              justify-center
                            "
                          >
                            {{ $t("components.firstModal.textWrapper") }}
                          </p>
                        </div>
                      </v-img>
                      <div
                        flex
                        class="uploadAvatarButtons p-4"
                        v-if="!showCam"
                        :class="{
                          hasImage:
                            state.user.avatar !=
                            'img/default_profile_picture.png',
                          hasNoImage:
                            state.user.avatar ==
                            'img/default_profile_picture.png',
                        }"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              depressed
                              v-on="on"
                              color="primary"
                              class="mx-1"
                              isSmall="true"
                              v-on:click="selectAvatar"
                            >
                              <font-awesome-icon
                                :icon="['fal', 'upload']"
                                :style="{ fontSize: '20px' }"
                              />
                            </v-btn>
                          </template>
                          <span>{{
                            $t("components.navbar.uploadPicture")
                          }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              depressed
                              v-on="on"
                              color="primary"
                              class="mx-1"
                              isSmall="true"
                              v-on:click="showCamFunc"
                            >
                              <font-awesome-icon
                                :icon="['fal', 'camera']"
                                :style="{ fontSize: '20px' }"
                              />
                            </v-btn>
                          </template>
                          <span>{{
                            $t("components.camera.takeSnapshot")
                          }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-list-item-avatar>
                </div>
              </div>
              <div
                class="pt-2 pr-4 pl-0"
                v-if="
                  n == 2 &&
                  state.user.name == '' &&
                  !state.persisted.userUploadedExcel
                "
              >
                <v-row>
                  <v-col class="py-0 pr-0" cols="4">
                    <v-text-field
                      class="pb-0"
                      outlined
                      dense
                      v-model="titleName"
                      :label="$t('components.firstModal.title')"
                      :rules="tileRules"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="8">
                    <v-text-field
                      outlined
                      dense
                      v-model="first_name"
                      :label="$t('components.firstModal.firstName')"
                      :rules="firstNameRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                  outlined
                  dense
                  v-model="last_name"
                  :label="$t('components.firstModal.lastName')"
                  :rules="lastNameRules"
                  required
                ></v-text-field>

                <v-text-field
                  outlined
                  dense
                  v-model="email"
                  :label="$t('components.firstModal.email')"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <!-- /////// Error text ///////// -->
          <v-row :style="{ width: '100%', margin: 0 }" v-if="errText">
            <div class="mx-auto">
              <span style="color: red">{{ errText }}</span>
            </div>
          </v-row>

          <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
            <v-row class="px-2 btns" flex :style="{ width: '100%', margin: 0 }">
              <v-col lg="12" class="px-0 py-0" align="right">
                <v-btn
                  depressed
                  color="white"
                  @click="validate"
                  :class="`buttonIconPrimaryFooter ${
                    disableButton ? 'buttonIconPrimaryFooterDisable' : ''
                  }`"
                  :disabled="disableButton"
                  >{{ $t("generics.save") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
          <v-spacer></v-spacer>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "../../store";
import {
  migrateCreator,
  migrateUser,
  isEmailRegistered,
} from "../../lib/wsMsg";
import camera from "../camera/camera.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
export default {
  props: ["showFirstModal", "toggleUserFirstModal"],
  components: {
    camera,
    HeaderModal,
  },
  data() {
    return {
      ownUUID: store.state.ownUUID,
      adminSelected: null,
      userAdmins: [],
      allUsers: [],
      state: store.state,
      showCam: false,
      titleName: "",
      first_name: "",
      last_name: "",
      email: "",
      errText: "",
      valid: true,
      showCroppie: false,
      tmpAvatar: null,
    };
  },
  methods: {
    // start methods for camera
    showCamFunc() {
      this.showCam = true;
    },
    getSnapshot(image) {
      this.showCam = false;
      this.showCroppie = true;
      this.$refs.croppieRef.bind({
        url: image,
      });
    },
    closeCamera() {
      this.showCam = false;
    },
    // end methods for camera
    cancelCrop() {
      this.resetInputAvatar();
      this.toggleCroppieModal();
    },
    crop() {
      const options = {
        type: "base64",
        size: { width: 256, height: 256 },
        format: "jpg",
      };
      this.$refs.croppieRef.result(options, (output) => {
        store.setAvatar(output);
        this.toggleCroppieModal();
      });
    },
    result(output) {
      this.cropped = output;
    },
    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle);
    },
    selectAvatar() {
      this.$refs.fileInput.click();
    },
    migrateState(newUUID, name, titel) {
      const user =
        (
          this.state.group[newUUID] ||
          this.state.group[store.state.ownUUID] ||
          {}
        ).user || {};
      const userObj = {
        name: name || user.name,
        activity: "Available",
        rtcCallStatus: "",
        rtcCallStart: 0,
        avatar: this.state.user.avatar || "img/default_profile_picture.png",
        guest: user.guest || false,
        sections: user.sections || [],
        locations: user.locations || [],
        teams: user.teams || [],
        assignedTickets: user.assignedTickets || [],
        assignedTicketsByMe: user.assignedTicketsByMe || [],
        assignedArchivedTickets: user.assignedArchivedTickets || [],
        assignedArchivedTicketsByMe: user.assignedArchivedTicketsByMe || [],
        lastTicketNumber: user.lastTicketNumber || 0,
        titel: titel,
      };
      const persistedData = JSON.parse(
        localStorage.getItem("persistedState_" + store.state.ownUUID)
      );
      localStorage.setItem(
        `persistedState_${newUUID}`,
        JSON.stringify(persistedData)
      );
      localStorage.setItem(`userState_${newUUID}`, JSON.stringify(userObj));
    },
    validate() {
      this.errText = "";
      if (!this.state.persisted.userUploadedExcel) {
        if (this.$refs.userForm.validate()) {
          if (this.state.user.guest || this.state.user.visitor) {
            // condition to know if the guest has set the info the first time
            this.state.persisted.guestInfoConfigDone = true;
          } else {
            this.email = this.email.trim().toLowerCase();
            if (this.email === this.state.user.email && store.state.ownUUID.indexOf('-') === -1) {
              this.state.user.name = `${this.first_name} ${this.last_name}`;
            } else {
              const name = `${this.first_name} ${this.last_name}`;
              const email = this.email;
              // eslint-disable-next-line no-async-promise-executor
              return new Promise(async () => {
                const { key, found } = await isEmailRegistered(email);
                if (found) {
                  this.errText = this.$t(
                    "components.inviteGuestPanel.errAlreadyUser"
                  );
                  return;
                }
                if (
                  store.state.ownUUID ===
                  (store.state.namespaceSettings.creatorInfo || {}).uuid
                ) {
                  localStorage.setItem("creatorFirstSetup", true);
                }
                const uuid_secret = await migrateUser(
                  name,
                  email,
                  this.titleName
                );
                this.migrateState(key, name, this.titleName);
                window.location.hash = `#${uuid_secret}`;
              });
            }
          }
          store.state.identitySeted = true;
          this.toggleUserFirstModal();
        }
      } else {
        store.state.userFromConfig = true;
        const newUUID = this.adminSelected;
        if (
          store.state.ownUUID ===
          (store.state.namespaceSettings.creatorInfo || {}).uuid
        ) {
          localStorage.setItem("creatorFirstSetup", true);
        }
        migrateCreator(newUUID).then((secret) => {
          this.migrateState(newUUID);
          window.location.hash = `#${newUUID}_${secret}`;
        });
      }
    },
    showCroppieModal(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.croppieRef.bind({
          url: e.target.result,
        });
        this.showCroppie = true;
      };
      reader.readAsDataURL(files[0]);
      this.resetInputAvatar();
    },
    toggleCroppieModal() {
      this.showCroppie = false;
    },
    resetInputAvatar() {
      if (this.$refs && this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },
  },
  computed: {
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    tileRules() {
      return [
        (v) =>
          (v || "").indexOf(" ") < 0 ||
          this.$t("components.firstModal.noSpacesAllowed"),
      ];
    },
    firstNameRules() {
      return [(v) => !!v || this.$t("components.firstModal.firstNameRequired")];
    },
    lastNameRules() {
      return [(v) => !!v || this.$t("components.firstModal.lastNameRequired")];
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("components.firstModal.emailRequired"),
        (v) =>
          /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) ||
          this.$t("components.firstModal.emailMustValid"),
      ];
    },
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.breakpoint;
      return lg ? [5, 7] : sm ? [5, 7] : md ? [5, 7] : xl ? [5, 7] : [12, 12];
    },
    disableButton() {
      if (
        !this.state.user.guest &&
        !this.state.persisted.userUploadedExcel &&
        (!this.valid ||
          this.email === "" ||
          this.first_name === "" ||
          this.last_name === "")
      ) {
        return true;
      } else if (
        this.state.persisted.userUploadedExcel &&
        this.adminSelected === null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    const emailRegistered = localStorage.getItem("emailRegistered");
    const firstNameRegistered = localStorage.getItem("firstNameRegistered");
    const lastNameRegistered = localStorage.getItem("lastNameRegistered");
    if (lastNameRegistered !== null && lastNameRegistered !== undefined) {
      this.last_name = lastNameRegistered;
      localStorage.removeItem("lastNameRegistered");
    }
    if (firstNameRegistered !== null && firstNameRegistered !== undefined) {
      this.first_name = firstNameRegistered;
      localStorage.removeItem("firstNameRegistered");
    }
    if (emailRegistered !== null && emailRegistered !== undefined) {
      this.email = emailRegistered;
      localStorage.removeItem("emailRegistered");
    }
    if (this.state.persisted.userUploadedExcel) {
      setTimeout(() => {
        for (let key in store.state.group) {
          const obj = {
            value: key,
            text: store.state.group[key].user.name,
          };
          if ((obj.text || "").length) {
            this.allUsers.push(obj);
            if (store.state.group[key].permissions === 1) {
              this.userAdmins.push(obj);
            }
          }
        }
      }, 50);
    }
  },
};
</script>

<style scoped lang="scss">
.imageTextWrapper {
  color: red;
  width: 100%;
  position: relative;
  top: 10px;
}
.buttonOverflow {
  overflow: visible !important;
}
.btns {
  justify-content: flex-end;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
  display: block;
}
.uploadAvatarButtons {
  position: absolute;
  left: 0px;
  height: 190px;
  width: 190px;
  top: 0px;
  border-radius: 4px;
  text-align: center;
  padding-top: 90%;
  button {
    height: 33px !important;
    min-width: 40px !important;
    padding: 0 10px !important;
  }
  &.hasImage {
    background: #6666668f;
    display: block;
    &:hover {
      display: block;
    }
  }
  &.hasNoImage {
    display: block;
    &:hover {
      display: block;
    }
  }
}
.borderRadius10 {
  &:hover > .hasImage {
    display: block;
  }
  &:hover > .hasNoImage {
    display: block;
  }
}
.borderRadius4 {
  border-radius: 4px;
}
.croppie-container {
  height: unset;
}
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: 2px solid #02889d !important;
}
.rotateIcons {
  position: absolute;
  right: 16%;
  top: 40%;
}
</style>