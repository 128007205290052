<template>
  <v-dialog v-model="showModal" persistent max-width="460" @keydown.esc="closeModal">
    <v-card>
      <v-card-title class="headline">
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        {{$t('components.remotePersonCall.rejectCall')}}
      </v-card-title>
      <v-row flex :style="{width: '100%', margin: 0}">
        <div class="d-flex" :style="{width:'100%'}">
          <div align="center">
            <v-badge
              bordered
              overlay
              height="60"
              width="60"
              bottom
              :color="badgetClassObj"
              dot
              offset-x="22"
              offset-y="15"
            >
              <v-list-item-avatar tile size="150" class="borderRadius10">
                <template>
                  <DefaultAvatar
                    :size="150"
                    :userData="getUserData"
                    v-if="getUserData.avatar === 'img/default_profile_picture.png'"
                  ></DefaultAvatar>
                  <v-img
                    v-if="getUserData.avatar !== 'img/default_profile_picture.png'"
                    class="borderRadius10"
                    max-height="150"
                    max-width="150"
                    contain
                    :src="getUserData.avatar !== 'img/default_profile_picture.png' ?getUserData.avatar: 'img/default_profile_picture.png' "
                    lazy-src="assets/default_profile_picture.png"
                  ></v-img>
                </template>
              </v-list-item-avatar>
            </v-badge>
          </div>
          <div class="text-center" :style="{width:'100%'}">
            <v-divider class="mt-4"></v-divider>
            <div class="mt-2 ml-4">
              <p color="primary" class="userNameCard">{{getUserData.titel}} {{getUserData.name}}</p>
              <p color="primary">{{getUserData.email}}</p>
              <p class="dataUserCard my-0">{{modalMsg}}</p>
            </div>
            <v-divider class="mt-4"></v-divider>
          </div>
        </div>
      </v-row>
      <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns mx-0" flex>
          <v-col cols="8" class="px-0 py-0" align="right">
            <v-btn color="primary" @click="closeModal()">{{ $t('generics.ok') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { getStatusColorByUser } from "../../utils/status";
import {wholeBarWithColor} from "../../utils/color"
import DefaultAvatar from "../image/defaultAvatar.vue";
export default {
  components: { DefaultAvatar },
  data() {
    return {
      state: store.state
    };
  },
  watch: {},
  mounted() {},
  methods: {
    wholeBarWithColors(){
      return wholeBarWithColor()
    },
    closeModal() {
      return store.setmodalPopUp(undefined);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    }
  },
  computed: {
    modalMsg(){
      if (this.showModal && !this.state.modalPopUp.msg) {
        return this.$t('components.remotePersonCall.rejectCall')
      } else {
        return this.state.modalPopUp.msg
      }
    },
    showModal() {
      return (
        store.state.modalPopUp && store.state.modalPopUp.type === "rejectCall"
      );
    },
    badgetClassObj() {
      if (this.wholeBarWithColors()) {
        return getStatusColorByUser(
          this.getUserData,
          this.isUserConnected
        );
      } else {
        return "rgba(0,0,0,0.3)";
      }
    },
    getUserData() {
      if (store.state.modalPopUp.uuid) {
        return store.state.group[store.state.modalPopUp.uuid].user;
      }
    },
    isUserConnected() {
        if (store.state.modalPopUp.uuid) {
        return store.state.group[store.state.modalPopUp.uuid].connected;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
