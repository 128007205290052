import Vue from 'vue';
import VueMain from './vue/main.vue';
import vuetify from './vuetify';
import VueRouter from 'vue-router';
import router from './router';
import routerAcl from './utils/routerAcl';
import sanitize from './lib/sanitize'; // eslint-disable-line no-unused-vars
import VueSanitize from 'vue-sanitize';
import vuescroll from 'vuescroll';
import VueCroppie from 'vue-croppie';
import VueVirtualScroller from 'vue-virtual-scroller';
// import enableInlineVideo from 'iphone-inline-video';
import InfiniteLoading from 'vue-infinite-loading';
import VueDragResize from 'vue-drag-resize';
import VueMask from 'v-mask';
import linkify from 'vue-linkify';
import { VueEffector } from 'effector-vue';

import VueTour from 'vue-tour'

Vue.use(VueTour);

Vue.use(InfiniteLoading, { /* options */ });
Vue.use(VueRouter);
Vue.use(VueCroppie);
Vue.use(VueSanitize);
Vue.use(vuescroll);
Vue.use(VueVirtualScroller);
Vue.use(VueEffector);
Vue.component('vue-drag-resize', VueDragResize);
Vue.use(VueMask);
Vue.directive('linkified', linkify);

Vue.prototype.$vuescrollConfig = {
  bar: {
    showDelay: 500,
    onlyShowBarOnScroll: false,
    keepShow: true,
    background: '#02889d',
    opacity: 1,
    specifyBorderRadius: false,
    minSize: 0,
    size: '8px',
    disable: false
  }
};

const ignoredMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = (message, vm, componentTrace) => {
  if (message !== ignoredMessage) {
    console.error(message + componentTrace);
  }
};

let instance;

let inited = false;
const init = () => {
  if (inited) return;
  inited = true;
  try {
    Vue.directive('focus', {
      inserted (el) {
        Vue.nextTick(() => el.focus());
      }
    });

    VueMain.vuetify = vuetify;

    // https://github.com/vuejs/vue-hot-reload-api/issues/61
    const root = new Vue({
      router: routerAcl(router),
      render: createElement => createElement(VueMain)
    });

    root.$mount('#main_anchor');
  } catch (err) {
    console.warn('Init Vue Error:', err);
  }
};

const getInstance = () => {
  if (!inited) init();
  return instance;
};

export default {
  init,
  getInstance
};
