<template>
  <div>
    <div class="tableMenu pa-1">
      <div style="display: flex; align-items: center">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="addRow">
              <font-awesome-icon
                :icon="['fal', 'plus']"
                :style="{ fontSize: '18px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.excelEditor.addRow") }}</span>
        </v-tooltip>
        <div class="tableSearch ml-2">
          <input
            v-model="search"
            ref="searchInput"
            :placeholder="$t('generics.search')"
          />
          <div class="icon">
            <font-awesome-icon
              :icon="['far', 'search']"
              :style="{ fontSize: '15px' }"
            />
          </div>
        </div>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="loadDataTable"
      :items-per-page="10"
      :search="search"
      class="elevation-0 cursorPointer userTable"
      :page="page"
      @page-count="pageCount = $event"
      fixed-header
      height="calc(100vh - 395px)"
      @update:items-per-page="10"
      @click:row="setUser"
      single-select
      item-key="uuid"
      ref="userTable"
      v-model="tableSelectedRows"
    >
      <template v-slot:item.avatar="{ item }">
        <v-avatar class="profile my-2" color="grey" size="40" tile>
          <v-img
            v-if="
              item.avatar && item.avatar !== 'img/default_profile_picture.png'
            "
            :src="item.avatar || 'img/default_profile_picture.png'"
            lazy-src="img/default_profile_picture.png"
          ></v-img>
          <DefaultAvatar
            :userData="item"
            v-if="
              !item.avatar || item.avatar === 'img/default_profile_picture.png'
            "
            :lowerText="true"
          ></DefaultAvatar>
        </v-avatar>
      </template>
    </v-data-table>
    <template v-if="showCloseDetailModal">
      <CloseManagementDetailModal
        :setNewUser="setNewUser"
        :acceptCloseModal="acceptCloseModal"
        :showModal="showCloseDetailModal"
        :closeModal="openCloseDetailModal"
      />
    </template>
  </div>
</template>
<script>
import store, { EventBus } from "../../store";
import DefaultAvatar from "../image/defaultAvatar.vue";
import CloseManagementDetailModal from "../modal/closeManagementDetailModal.vue";
export default {
  props: [
    "setUserInfo",
    "addRecord",
    "selectedUser",
    "formIsChanged",
    "changesInForm",
  ],
  components: { DefaultAvatar, CloseManagementDetailModal },
  data() {
    return {
      state: store.state,
      search: "",
      page: 1,
      pageCount: 0,
      rowsToScrollCount: 10,
      selectedId: undefined,
      tableSelectedRows: undefined,
      showCloseDetailModal: false,
      newUser: undefined,
    };
  },
  mounted() {
    EventBus.$on("selectTableItem", this.selectTableItem.bind(this));
  },
  methods: {
    openCloseDetailModal() {
      this.showCloseDetailModal = !this.showCloseDetailModal;
    },
    selectTableItem(uuid) {
      const user = (store.state.group[uuid] || {}).user;
      if (!user) {
        console.warn("selectTableItem", uuid, "user not found");
        return;
      }
      this.setUserFromEvent(user);
    },
    addRow() {
      this.addRecord();
    },
    setUserFromEvent(user, row) {
      this.setUserInfo(user);
      if (row) {
        row.select(true);
        this.selectedId = user.uuid;
      } else if (this.$refs.userTable) {
        const idx = this.$refs.userTable.items.findIndex(
          (e) => e.uuid === user.uuid
        );
        if (idx === -1) {
          console.warn("setUserFromEvent", user.uuid, "user not found");
          return;
        }
        const perPage = this.$refs.userTable.itemsPerPage;
        const actualPage = Math.ceil((idx + 1) / perPage);
        this.page = actualPage;
        this.tableSelectedRows = [this.$refs.userTable.items[idx]];
      }
    },
    async acceptCloseModal() {
      await this.setUser(this.newUser.user, this.newUser.row);
      this.changesInForm(false);
    },
    setNewUser(value) {
      // function to show, close and set the properly functionality in the table after the modal
      this.newUser = value;
    },
    async setUser(user, row) {
      if (
        this.selectedUser === undefined ||
        (this.newUser && this.newUser.afterModal) ||
        !this.formIsChanged
      ) {
        // first time set user
        await this.setUserInfo(user);
        this.setNewUser(undefined);
      } else {
        // show modal and return
        this.setNewUser({ user, row, afterModal: true });
        this.openCloseDetailModal();
        return;
      }
      // continue with the normal functionality
      if (this.selectedUser) {
        row.select(true);
        this.selectedId = user.uuid;
      } else {
        row.select(false);
        this.selectedId = undefined;
      }
    },
    userIsGuest(uuid) {
      return store.getUserIsGuest(uuid);
    },
  },
  computed: {
    loadDataTable() {
      return Object.entries(this.state.group)
        .filter(([uuid, person]) => (person?.user?.name && !this.userIsGuest(uuid)))
        .map(([uuid, person]) => {
          const temporalData = JSON.parse(JSON.stringify({ ...person.user, permissions: person.permissions }));
          temporalData.isAdmin =
            person.permissions === 1
              ? this.$t("generics.yes")
              : ''
          return temporalData;
        })
        .sort((a, b) => (a.name.toString().localeCompare(b.name)));
    },
    headers() {
      return [
        {
          text: "",
          value: "avatar",
          sortable: false,
          class: "font-weight-medium subtitle-1",
        },
        {
          text: this.$t("excel.titel"),
          value: "titel",
          class: "font-weight-medium subtitle-1",
        },
        {
          text: this.$t("excel.name"),
          value: "name",
          class: "font-weight-medium subtitle-1",
        },
        {
          text: this.$t("excel.position"),
          value: "position",
          class: "font-weight-medium subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.E-Mail"),
          value: "email",
          class: "font-weight-medium subtitle-1",
        },
        {
          text: this.$t("generics.admin"),
          value: "isAdmin",
          class: "font-weight-medium subtitle-1",
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.tableSearch {
  input {
    color: black !important;
  }
}
.profile {
  border-radius: 4px;
}
.tableMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--v-primary-base);
  border: 1px solid var(--v-primary-base);
  border-bottom: 0;
  width: calc(100% - 1px);

  .v-btn,
  span {
    color: #ffffff !important;
  }
}
.theme--dark.v-data-table tbody tr td {
  color: white !important;
}
.theme--light.v-data-table thead tr th {
  color: black !important;
}
.theme--dark.v-data-table thead tr th {
  color: white !important;
}
.cursorPointer {
  cursor: pointer;
}
tr.v-data-table__selected {
  background: #044b41 !important;
}
</style>
<style lang="scss">
.userTable td:first-child {
  padding-left: 7px !important;
}
.userTable td {
  font-weight: 300;
}
.userTable {
  tr.v-data-table__selected {
    background: #e4f4f6 !important;
  }
}
.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #505050 !important;
}
.userTable .v-data-footer {
  margin-right: 0px !important;
}
</style>