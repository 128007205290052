
<template>
  <v-list>
    <v-list-group
      active-class="listColor"
      v-if="!isGuest && !mini"
      :value="state.searchTerm.length > 0 ? false : null || isActive"
      @click="
        setList('visitors');
        openMenuVisitors();
      "
      :appendIcon="sortedVisitors.length == 0 ? null : '$expand'"
    >
      <template v-slot:activator>
        <!-- <v-tooltip top max-width="300">
          <template v-slot:activator="{ on }"> -->
            <v-list-item-icon
              style="min-height: 0px !important"
              :class="`${getRoute === '/visitors' ? 'primary--text' : ''}`"
            >
              <font-awesome-icon
                v-if="currentPage('/guest')"
                class="primary--text"
                :icon="['fas', 'user-friends']"
              />
              <font-awesome-icon
                v-else
                class="primary--text"
                :icon="['fal', 'user-friends']"
              />
              <CustomTT
                v-if="showCustomTT"
                :text="`${$t('components.hotkeys.controlKey')} + I`"
                position="bottom"
              />
            </v-list-item-icon>
            <v-list-item-title
              @click.stop.prevent="openGridVisitors()"
              class="primary--text"
              :class="`${
                currentPage('/guest') ? 'font-weight-bold primary--text' : ''
              }`"
            >
              {{ $t("generics.invitationSidebar") }}
              <span>
                <template v-if="sortedVisitors.length > 0">
                  (<span class="textColor">{{ sortedVisitors.length }}</span
                  >)
                </template>
              </span>
              <v-btn
                icon
                class="btnAddGroup"
                v-bind:disabled="false"
                @click.stop.prevent="openInviteVisitor"
              >
                <font-awesome-icon
                  class="primary--text"
                  :icon="['fal', 'plus-circle']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </v-list-item-title>
            <!-- <v-tooltip top v-if="!isMobile">
              <template v-slot:activator="{ on }">
                <div v-on="on" class="btnInviteVisitor">
                  <InviteVisitor :typeInvite="'visitors'" :isSidebar="true" />
                </div>
              </template>
              <span>{{ $t("components.inviteVisitor.inviteUser") }}</span>
            </v-tooltip> -->
          <!-- </template>
          <span>{{ $t("generics.invitationSidebarTT") }}</span>
        </v-tooltip> -->
      </template>
      <VisitorList :visitors="sortedVisitors" :section="'My Visitors'" />
    </v-list-group>
    <v-tooltip right v-if="!isGuest && mini">
      <template v-slot:activator="{ on }">
        <v-list-item v-on="on" class="cursorPointer">
          <v-list-item-icon @click.stop.prevent="handlerClickVisitorsMini()">
            <!-- <InviteVisitor
              :mini="mini"
              :visitors="sortedVisitors"
              :typeInvite="'visitors'"
              :isSidebar="true"
            /> -->
            <font-awesome-icon
              v-if="currentPage('/guest')"
              class="primary--text cursorPointer"
              :icon="['fas', 'user-friends']"
              @click.stop.prevent="handlerClickVisitorsMini()"
              style="outline: none"
            />
            <font-awesome-icon
              style="outline: none"
              v-else
              class="primary--text cursorPointer"
              :icon="['fal', 'user-friends']"
            />
          </v-list-item-icon>
        </v-list-item>
      </template>
      <span>{{ $t("generics.guests") }}</span>
      </v-tooltip>
  </v-list>
</template>
<script>
import store, { EventBus } from "../../store";
import VisitorList from "./visitorList.vue";
import InviteVisitor from "../navbar/inviteVisitor.vue";

import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";

export default {
  props: [
    "mini",
    "visitors",
    "filterPerson",
    "setCurrentContentVisile",
    "isGuest",
    "active",
    "setList",
    "isMobile",
  ],
  components: {
    VisitorList,
    InviteVisitor,
    CustomTT,
  },
  effector: {
    showCustomTT: hotkeysInfoStore,
  },
  data: () => ({
    state: store.state,
  }),
  mounted() {},
  methods: {
    handlerClickVisitorsMini() {
      if (this.mini && this.visitors.length == 0) {
        this.openInviteVisitor();
      } else if (this.mini && this.visitors.length > 0) {
        if (this.$router.currentRoute.path !== "/guest") {
          this.$router.push({ path: "/guest" });
        }
      }
    },
    openInviteVisitor() {
      this.setCurrentContentVisile("invitationform", true, this.$router);
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    openGridVisitors() {
      if (this.sortedVisitors && this.sortedVisitors.length > 0) {
        this.handleVisitorsView();
      }
    },
    handleVisitorsView() {
      if (!this.isMobile) {
        this.setCurrentContentVisile("guest", true, this.$router);
      }
    },
    openMenuVisitors() {
      if (
        !this.mini &&
        this.sortedVisitors &&
        this.sortedVisitors.length == 0
      ) {
        // console.log("dentroooo");
        this.openInviteVisitor();
        // EventBus.$emit("openInviteGuestModalEvent");
      }
    },
  },
  computed: {
    canInviteGuests() {
      return store.getUserCanInviteGuests(this.state.ownUUID);
    },
    sortedVisitors() {
      return this.visitors.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
    },
    connectedGuestLen: function () {
      return this.sortedGuests.filter((p) => p.connected).length;
    },
    isActive() {
      return this.active == "visitors";
    },
    getRoute() {
      return this.$route.path;
    },
  },
};
</script>

<style  lang="scss">
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.titleGroup {
  .v-icon {
    font-size: 14px !important;
  }
}

.v-list {
  padding: 0;
}

.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.textColor {
  color: var(--v-primary-base);
}

.theme--dark .listColor {
  color: white !important;
}
.theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>

<style scoped lang="scss">
.cursorPointer {
  cursor: pointer;
}
.btnInviteVisitor {
  position: absolute !important;
  right: 57px;
  top: 5px;
}
</style>

