<template>
  <v-dialog v-model="showDirectInviteCallModal" persistent max-width="560" ref="modalDirectInviteCall">
    <template>
      <v-card class="mx-auto">
        <HeaderModal :titleModal="'Link'" />
        <v-card-text>
          <v-row>
            <v-text-field id="inputLink" readonly dense outlined hide-details :lable="'Direct lnk'" ref="linkRef" v-model="showDirectInviteCallModal"></v-text-field>
          </v-row>
        </v-card-text>
         <FooterModal :closeModalFunction="closeModal" :showFooter="true">
          <v-btn depressed @click="copyLink($event)">
            <font-awesome-icon
              :icon="['fal', 'copy']"
              :style="{ fontSize: '18px' }"
            />
          </v-btn>
          </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { resetDirectCallInviteModalEvent, directCallInvitationModalStore } from "../../effector/modals";
import { dispatchSuccessAlert } from "../../effector/alerts";
export default {
  components: { HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
  }),
  effector: {
    showDirectInviteCallModal: directCallInvitationModalStore,
  },
  mounted() {},
  methods: {
    async copyLink(evt) {
      if ( this.showDirectInviteCallModal ){
        document.getElementById('inputLink').focus();
        document.getElementById('inputLink').select();
        document.execCommand("copy");
        evt.target.classList.add("greenClass");
        setTimeout(() => {
          evt.target.classList.remove("greenClass");
          this.closeModal();
          dispatchSuccessAlert(this.$t('components.inviteVisitor.copiedLInk'))
          // document.getElementById('inputLink').blur();
        }, 1000);
      }
    },
    closeModal() {
      resetDirectCallInviteModalEvent(false);
    }
  },
  computed: {}
};
</script>

<style scoped lang="scss">
.greenClass {
  font-weight: 800;
  color: green;
}
.pointer {
  cursor: pointer;
}
.thumbs-down {
  color: red;
}
.thumbs-up {
  color: green;
}
</style>