<template>
  <v-dialog v-model="showModalSettings" persistent max-width="650" @keydown.esc="closeModal">
    <v-card>
      <HeaderModal
        :titleModal="$t('components.callsContent.addParticipants')"
        :closeModalFunction="closeModal"
      />
      <v-row class="mx-0">
        <v-col cols="12" class="text-center">
          <v-btn
            color="primary"
            large
            @click="openUsers();"
          >
            <font-awesome-icon
              :icon="['fal', 'user-plus']"
              class="mr-2"
              :style="{ fontSize: '20px' }"
            />
            {{$t('generics.user')}}
          </v-btn>

           <v-btn
            color="primary"
            large
            @click="handlerOpenVisitor(); closeModal()"
          >
            <font-awesome-icon
              :icon="['fal', 'user-plus']"
              class="mr-2"
              :style="{ fontSize: '20px' }"
            />
            {{$t('generics.guest')}}
          </v-btn>
        </v-col>
        <v-col cols="12" class="text-center">
          <p>
            {{$t('components.callsContent.shareLinkText')}}
          </p>

          <v-text-field
            id="inputLink"
            class="inputDirectInviteLink"
            readonly
            dense
            outlined
            hide-details
            ref="linkRef"
            v-model="instaLink"
          >
            <template v-slot:append>
              <v-btn
                class="buttonCopyLink"
                icon
                @click.stop.prevent="copyLink($event)"
              >
                <font-awesome-icon
                  :icon="['fal', 'copy']"
                  
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
             
            </template>
          </v-text-field>

        </v-col>
      </v-row>
      <FooterModal :closeModalFunction="closeModal">

      </FooterModal>
    </v-card>
    <v-spacer></v-spacer>
  </v-dialog>
</template>
 
<script>
import store from "../../../../store";
import HeaderModal from "../../../modal/modalComponents/headerModal.vue";
import FooterModal from "../../../modal/modalComponents/footerModal.vue";
export default {
  props: ["closeModal", "showModalSettings", "instaLink", "handlerOpenVisitor", "openUsers"],
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
    };
  },
  watch: {},
  effector: {},
  mounted() {},
  destroyed() {},
  methods: {
    copyLink(evt){
      const inputLink = document.getElementById("inputLink");
      inputLink.focus();
      inputLink.select();
      document.execCommand("copy");
      evt.target.classList.add("greenClass");
      setTimeout(() => {
        evt.target.classList.remove("greenClass");
      }, 1000);
    }
  },
  computed: {}
};
</script>

<style scoped lang="scss">
.inputDirectInviteLink {
  width: 80%;
  margin: 0 auto !important;
}
.buttonCopyLink{
  top: -5px;
  position: relative;
}
</style>

