<template>
  <div :class="getClassChat">
    <div
      class="d-flex justify-space-between align-baseline w100 pt-2 pl-4 pr-3"
    >
      <div class="title white--text">
        {{ $t("generics.info") }}
      </div>

      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn @click="toggleUserList()" v-on="on" icon>
              <font-awesome-icon
                :icon="['fal', 'times']"
                :style="{ fontSize: '20px' }"
                color="white"
              />
            </v-btn>
          </template>
          <span>{{ $t("generics.close") }}</span>
        </v-tooltip>
      </div>
    </div>

    <v-col cols="12" class="flex-grow-1 flex-shrink-0 containerUsers  overflow-y-auto">
     
      <v-list class="bgColor">
        <v-list-item class="px-0" v-for="uuid in getUserList" :key="uuid">
          <v-list-item-avatar
            tile
            size="60"
            class="borderRadius5 mx-2 my-2"
          >
            <DefaultAvatar
              :size="60"
              v-if="getAvatarSrc(uuid) === 'img/default_profile_picture.png'"
            ></DefaultAvatar>
            <v-img
              v-if="getAvatarSrc(uuid) !== 'img/default_profile_picture.png'"
              class="mx-2 borderRadius5 cursorPointer"
              @error="onImgError"
              :src="getAvatarSrc(uuid)"
              max-height="60"
              max-width="60"
              contain
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="contentWrapper">
            <div class="notranslate white--text text-truncate nameWidth">
              {{ getTitelForUuid(uuid) }}
              {{ getNameForUuid(uuid) }}
            </div>
            <div class="iconWidth">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn  @click="setUserInModal(uuid)" v-on="on" icon>
                    <font-awesome-icon
                      :icon="['fal', 'info-circle']"
                      :style="{ fontSize: '20px' }"
                      color="white"
                    />
                  </v-btn>
                </template>
                <span>{{ $t("components.adminSettingsModal.userInfo")  }}</span>
              </v-tooltip>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
  </div>
</template>
<script>
import store from "../../../store";
import DefaultAvatar from "../../image/defaultAvatar.vue";
import { isMobile } from "../../../lib/mobileUtil";
import { bridgeInfoStore } from "../../../effector/users/bridgeInfo";
import { setUserProfileEvent } from "../../../effector/modals";

export default {
  components: { DefaultAvatar },
  props: ["showTimeLine", "userListOpen", "toggleUserList", 'isListenerView'],
  data() {
    return {
      state: store.state,
      isMobile: isMobile(),
      ownUUID: store.state.ownUUID,
      failedImage: false,
    };
  },
  effector: {
    bridgeInfoStore: bridgeInfoStore,
  },
  watch: {
    outsideCallView: {
      // immediate: true,
      handler: function (outside) {
        if (outside && this.userListOpen) {
          this.toggleUserList();
        }
      },
    },
  },
  mounted() {},
  methods: {
      setUserInModal(uuid) {
      //open modal if im not guest
      if (!uuid) return;
      const person = this.state.group[uuid];
      store.state.ownModalUser = uuid === this.state.ownUUID;
      store.state.userOnModal = { ...person.user };
      store.state.userOnModal.connected = person.user.connected;
      store.state.userOnModal.uuid = uuid;
      store.state.userOnModal.userTS = person.userTS;
      setUserProfileEvent(true);
    },
    getAvatarSrc(uuid) {
      if (this.failedImage) return "img/default_profile_picture.png";
      if(!this.state.group[uuid]) return "img/default_profile_picture.png";
      return (
        (this.state.group[uuid].user || {}).avatar ||
        "img/default_profile_picture.png"
      );
    },
    onImgError() {
      this.failedImage = true;
    },
    getNameForUuid(userUUID) {
      if (
        !userUUID ||
        !store.state.group[userUUID] ||
        store.state.group[userUUID].directCallUUID
      )
        return this.$t("generics.anonymous");
      return store.getNameForUuid(userUUID);
    },
    getTitelForUuid(userUUID) {
      if (
        !userUUID ||
        !store.state.group[userUUID] ||
        store.state.group[userUUID].directCallUUID
      )
        return "";
      return store.getTitelForUuid(userUUID);
    },
  },
  computed: {
    getUserList() {
      // just fake user
      // return [
      //   "28f5236d9df9964a91473a58049255b8",
      //   "0a11c7b3d8400ef39c6f80fd8bc25054",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      //   "0db52e4ae8ca43978110703c59e2f2c9",
      // ];
      if (!this.bridgeInfoStore || !this.bridgeInfoStore.podium) return [];
      let callUUID = this.isListenerView
        ? this.state.user.inBridgeCallListener
        : this.state.user.inBridgeCall;
      if (!callUUID) return [];
      let podiumList = [];
      for (const key in this.bridgeInfoStore.podium) {
        if (Object.hasOwnProperty.call(this.bridgeInfoStore.podium, key)) {
          const element = this.bridgeInfoStore.podium[key];
          if (element == callUUID) {
            podiumList.push(key);
          }
        }
      }
      return podiumList || [];
    },
    getClassChat() {
      // return 'userListContainer';
      if (this.isMobile) {
        return "userListContainerMobile";
      } else if (this.amIGuest) {
        if (this.showTimeLine) {
          return "userListContainerGuest";
        } else {
          return "userListContainerNoTimelineGuest";
        }
      } else {
        if (this.showTimeLine) {
          return "userListContainer";
        } else {
          return "userListContainerNoTimeline";
        }
      }
    },
    outsideCallView() {
      if (
        this.state.currentContentVisile.showComponent &&
        (this.state.user.rtcCallStart ||
          Object.keys(this.state.remoteBridgeStreams).length > 0 ||
          (this.state.user.inBridgeCall &&
            this.state.user.inBridgeCall.length > 0))
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.nameWidth{
  width: 85%;
  max-width: 85%;
  min-width: 85%;
}
.iconWidth{
  width: 15%;
  max-width: 15%;
  min-width: 15%;
}
.bgColor {
  background: #131519 !important;
}
.borderRadius5 {
  border-radius: 5px !important;
}
.w100 {
  width: 100%;
}
.userListContainerGuest {
  position: relative;
  background: #131519;
  z-index: 2;
  height: 100%;
  width: 100%;
}
.userListContainerNoTimelineGuest {
  position: relative;
  background: #131519;
  z-index: 2;
  height: 100%;
  width: 100%;
  
}
.userListContainer {
  position: relative;
  background: #131519;
  z-index: 2;
  height: 100%;
  width: 100%;
}
.userListContainerNoTimeline {
  position: relative;
  background: #131519;
  z-index: 2;
  height: 100%;
  width: 100%;
}
.userListContainerMobile {
  position: absolute;
  background: #131519;
  width: calc(100% - 83%);
  z-index: 10;
  left: 0px;
  bottom: 56px;
  height: 100%;
  width: 100%;
}
.containerUsers {
  height: auto;
  max-height: calc(100% - 55px);
  min-height: auto;
  //just for firefox
  scrollbar-color: #06a5df #000000;
  scrollbar-width: thin;
}
.containerUsers::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.containerUsers::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #000000;
  border-radius: 10px;
}
/* Handle */
.containerUsers::-webkit-scrollbar-thumb {
  background: #06a5df;
  border-radius: 10px;
}
</style>
