<template>
  <v-content
    :class="`contentContainer  ${
      mini ? 'mainContentContainer' : 'mainContentContainerFullSidebar'
    } ${hasNotSidebar ? 'containerGuest' : ''} ${
      !showTimeline ? 'hideTimeline' : ''
    }`"
  >
    <!-- <Background
      v-if="
        Object.keys(state.remoteStreams).length == 0 &&
        !state.currentContentVisile.showComponent
      "
    /> -->
    <CallsContent
      :toggleModalSettings="toggleModalSettings"
      v-if="
        Object.keys(state.remoteStreams).length > 0 &&
        !isMobile &&
        !isIpad &&
        !state.presentationView.show
      "
    />
    <JitsiCall v-if="haveCalls" />
    <CallListenerWrapper v-if="haveCallsListener" :callUUID="haveCallsListener" />
    <CallsContentPresentation
      :openCloseSidebar="openCloseSidebar"
      :primaryDrawer="primaryDrawer"
      :toggleModalSettings="toggleModalSettings"
      :mini="mini"
      v-if="
        Object.keys(state.remoteStreams).length > 0 &&
        !isMobile &&
        !isIpad &&
        state.presentationView.show
      "
    />
    <router-view></router-view>
    <manage-organisation
      v-if="state.simpleOrganisationManagerVisible"
      :visible.sync="state.simpleOrganisationManagerVisible"
    />
    <EditExcelView
      v-if="
        state.currentContentVisile.showComponent &&
        state.currentContentVisile.component === 'editUploadExcell'
      "
    />
    <!-- DONT MOVE  ModalSendMessage of this schedule, imrpotant to the dragable function-->
    <ModalSendMessage v-if="!isMobile && !isIpad" />
    <ModalIncomingBridgeCall
      v-if="
        state.user &&
        (incommingCallsArray.length > 0 || incomingConfCallsArray.length > 0)
      "
    />
    <ModalSendMessageMobile v-if="isMobile || isIpad" />
    <NotificationsModal />
    <AddTeamModal />
    <AddToGroupModal />
    <TimeLineEventModal />
    <PingPongModal />
    <MeetingTimeModal v-if="state.showModalMeetingTime" />
    <UserSelectModal />
    <EndMeetingTimeModal v-if="state.showModalEndMeeting" />
    <FirstModal
      v-if="showModal"
      :showFirstModal="showFirstModal"
      :toggleUserFirstModal="toggleUserFirstModal"
    />
    <InfoModal v-if="showInfoModal" />
    <LicenseExpiredModal />
    <LicenseAlmostExpiredModal />
    <RejectCallModal
      v-if="state.modalPopUp && state.modalPopUp.type === 'rejectCall'"
    />
    <JoinConferenceStartedModal v-if="showjoinConference" />
    <JoinConferenceAnswerModal
      v-if="
        state.modalPopUp && state.modalPopUp.type === 'answerJoinConference'
      "
    />
    <InfoPopUpModal
      v-if="state.modalPopUp && state.modalPopUp.type === 'popUp'"
    />
    <FullBridgeCallModal v-if="showFullBridgeCallModal" />
    <CanJoinConferenceModal v-if="showcanJoinConferencesModal.show" />
    <VotingModal v-if="showModalVotingQuality" />
    <ReminderModal v-if="showModalReminder" />
    <DirectCallInvitationModal v-if="showDirectCallInviteModal" />
    <JoinBridgeCallModal v-if="showJoinBridgeCallModal.show" />
    <!-- <SendMessageInCallModal v-if="showSendMessageInCallModal" /> -->
    <RequestPaymentModal v-if="showModalRequestPayment" />
    <InProgressPaymentModal v-if="state.user.paymentState.paymenInprogress" />
    <SuccessPaymentModal v-if="state.user.paymentState.showSuccessPayment" />
    <ErrorPaymentModal v-if="state.user.paymentState.showErrorPayment" />
    <PaymentCanceled v-if="state.user.paymentState.showCancelPayment" />
    <PaymentEventModal v-if="state.user.requestedPayment.show" />
    <QRcodeModal v-if="showQrCodeModal" />
    <VideoTestModal v-if="showVideoTestModal" />
    <ExternalServicesModal v-if="showExternalServicesModal" />
    <VOfficeProVersionModal v-if="showvOfficeProVersionModalStore" />
    <BadQualityMessageModal v-if="showBadQualityMessageModal" />
    <ChangelogModal v-if="showChangelogModal" />
    <BeforeEndingCallModal v-if="showBeforeEndingCallModal" />
    <NewVersionAvailableModal v-if="showNewVersionAvailableModal" />
    <ActiveUserModal v-if="state.showModalActiveUser" />
    <AudioPolicyModal v-if="state.showModalAudioPolicy" />
    <NotificationsModalEffector v-if="showModalNotification" />
    <ErrorInCallModal v-if="showErrorInCallModal" />
    <HotkeysModal v-if="showHotkeysModal" />
    <UserProfile v-if="showUserProfilesModal" />
    <DeviceSetupModal
      v-if="showSetupDevice"
      :showSetupDevice="showSetupDevice"
      :toggleModalSettings="toggleModalSettings"
    />
    <LegalMailFooterModal v-if="showLegalMailFooter" />
    <SendMessageBeforeConferenceModal
      v-if="showSendMessageBeforeConference.show"
    />
    <SetLanguageModal v-if="showLanguageModal" />
    <!-- <MobileInsideCallModal v-if="showInsideMobileModal" /> -->
    <MobileCallModal v-if="showMobileModal" />
    <LogoutModal v-if="showLogoutModal" />
    <HistoryMessagesModalEffector v-if="showHistoryMessagesModal" />
    <UploadAvatarModal v-if="showUploadAvatarModal.showModal" />
    <CameraPermissions v-if="ShowerrorCameraPermissionsModal" />
    <DeleteVisitorPaylinkModal v-if="ShowDeleteVisitorPaylinkModalStore.showModal" />
    <CallUserInCallModal v-if="showCallUserInCallModalStore.showModal" />
    <CallPersonConferenceHallModal v-if="ShowCallPersonConferenceHallModalStore" />
    <CallEndedInfoModal v-if="showCallEndedInfoModalStore" />
    <BreakStatusModal v-if="showBreakStatusModalStore" />
    <DailyReportModal v-if="showDailyReportModalStore" />
    <DailyReportSettingsModal v-if="showDailyReportSettingsModalStore.showModal" />
    <BeaconDomainWhitelistModal v-if="ShowBeaconDomainWhitelistStore" />
    <v-alert
      transition="slide-x-transition"
      text
      color="info"
      dismissible
      v-if="state.showInvitationSent"
      class="slideInvitationSent"
    >
      {{ $t("components.userListItem.yourInvitationTo") }}
      <b
        >{{ state.sendInvitationGuestModal.invitationData.name }} ({{
          state.sendInvitationGuestModal.invitationData.email
        }})</b
      >
      {{ $t("components.userListItem.hasBeenSuccessfullySent") }}
    </v-alert>
  </v-content>
</template>
<script>
import store, { EventBus } from "../../store";
import { isMobile, isIpad } from "../../lib/mobileUtil";
import JitsiCall from "./calls/jitsiCall.vue";
import {
  badQualityMessageModalStore,
  qualityVotingModalStore,
  reminderModalStore,
  directCallInvitationModalStore,
  vOfficeProVersionModalStore,
  qrCodeModalStore,
  externalServicesModalStore,
  videoTestModalStore,
  changelogModalStore,
  errorInCallModalStore,
  beforeEndingCallModalStore,
  newVersionAvailableModalStore,
  notificationModalStore,
  paymentModalStore,
  joinBridgeCallStore,
  paymentInProgressModalStore,
  // sendMessageInCallStore,
  fullBridgeCallStore,
  hotkeysStore,
  userProfileStore,
  mailFooterStore,
  canJoinConferenceStore,
  sendMessageBeforeConferenceStore,
  languageModalStore,
  insideMobileModalStore,
  callMobileModalStore,
  logoutModalStore,
  historyMessagesModalStore,
  uploadAvatarModalStore,
  errorCameraPermissionsModalStore,
  deleteVisitorPaylinkModalStore,
  joinConferenceStore,
  callUserInCallModalStore,
  callPersonConferenceHallModalStore,
  callEndedInfoModalStore,
  breakStatusModalStore,
  dailyReportModalStore,
  dailyReportSettingsModalStore,
  beaconDomainWhitelistStore
} from "../../effector/modals";
import {
  timelineEvents,
  deleteTimelineItemEvent,
} from "../../effector/timeline";
import {
  myConferenceDetails,
  dispatchDeleteGroupConferenceEvent,
} from "../../effector/groupConferences";
import { bridgeInfoStore } from "../../effector/users/bridgeInfo";
import { dispatchDeleteCompanyDocumentEvent } from "../../effector/companyDocuments";
import Background from "./background.vue";
import EditExcelView from "./editExcelView.vue";
import PWAButton from "./PWAButton.vue";
import ModalIncomingCall from "../modal/incomingCall.vue";
import ModalIncomingBridgeCall from "../modal/incomingBridgeCall.vue";
import PingPongModal from "../modal/pingPongModal.vue";
import ModalSendMessage from "../modal/sendMessageModal.vue";
import ModalSendMessageMobile from "../modal/sendMessageModalMobile.vue";
import FirstModal from "../modal/firstModal.vue";
import DeviceSetupModal from "../modal/deviceSetupModal.vue";
import NotificationsModal from "../modal/notificationsModal.vue";
import CallsContent from "./callsContent.vue";
import CallsContentPresentation from "./callsContentPresentation.vue";
import UserSelectModal from "../modal/userSelectModal.vue";
import CallsContentMobile from "./callsContentMobile.vue";
import WelcomeMessageModal from "../modal/welcomeMessage.vue";
import AddGroupModal from "../modal/addGroupModal.vue";
import InviteVisitorModal from "../modal/inviteVisitorModal.vue";
import AddTeamModal from "../modal/addTeamModal.vue";
import AddToGroupModal from "../modal/addToGroupModal.vue";
import MeetingTimeModal from "../modal/meetingTime.vue";
import EndMeetingTimeModal from "../modal/endMeetingModal.vue";
import InfoModal from "../modal/infoModal.vue";
import InfoPopUpModal from "../modal/infoPopUpModal.vue";
import LicenseExpiredModal from "../modal/licenseExpiredModal.vue";
import LicenseAlmostExpiredModal from "../modal/licenseAlmostExpiredModal.vue";
import Home from "../content/home.vue";
import MeetingLine from "../meetingLine/meetingLineEffector.vue";
import RejectCallModal from "../modal/rejectCallModal.vue";
import JoinConferenceStartedModal from "../modal/joinConferenceStartedModal.vue";
import VotingModal from "../modal/votingModal.vue";
import ReminderModal from "../modal/reminderModal.vue";
import DirectCallInvitationModal from "../modal/directInviteCallModal.vue";
import RequestPaymentModal from "../modal/paymentModals/requestPaymentModal.vue";
import InProgressPaymentModal from "../modal/paymentModals/paymentInProgress.vue";
import SuccessPaymentModal from "../modal/paymentModals/successPayment.vue";
import ErrorPaymentModal from "../modal/paymentModals/errorPayment.vue";
import PaymentCanceled from "../modal/paymentModals/paymentCanceled.vue";
import PaymentEventModal from "../modal/paymentModals/paymentEventModal.vue";
import ChangelogModal from "../modal/changelogModal.vue";
import TimeLineEventModal from "../modal/timeLineEventModal.vue";
import NewEventForm from "../timeline/newEventForm.vue";
import ActiveUserModal from "../modal/activeUserModal.vue";
import AudioPolicyModal from "../modal/audioPolicyModal.vue"
import ManageOrganisation from "../config/ManageOrganisation.vue";
import ErrorInCallModal from "../modal/errorInCall.vue";
import BeforeEndingCallModal from "../modal/beforeEndingCallModal.vue";
import NewVersionAvailableModal from "../modal/newVersionAvailableModal.vue";
import BadQualityMessageModal from "../modal/badQualityMessageModal.vue";
import QRcodeModal from "../modal/qrCodeModal.vue";
import VideoTestModal from "../modal/videoTestModal.vue";
import SendMessageInCallModal from "../modal/sendMessageInCallModal.vue";
import ExternalServicesModal from "../modal/externalServicesModal.vue";
import VOfficeProVersionModal from "../modal/vOfficeProVersionModal.vue";
import CallEndedInfoModal from '../modal/callEndedInfoModal.vue';
import BreakStatusModal from '../modal/breakStatusModal.vue';
import DailyReportModal from "../modal/dailyReportModal.vue";
import DailyReportSettingsModal from "../modal/dailyReportSettingsModal.vue"
import {
  basicView,
  hasPrivilege,
  isOnlyOneCallUser,
  isWaitingRoomUser,
} from "../../utils/privileges";
import { incomingCallsBridge, incomingConfCalls } from "../../utils/calls";
import VideoBridgeContent from "./videoBridgeContent.vue";
import JoinBridgeCallModal from "../modal/joinBridgeCallModal.vue";
import NotificationsModalEffector from "../modal/notificationsModalEffector.vue";
import FullBridgeCallModal from "../modal/fullBridgeCallModal.vue";
import HotkeysModal from "../modal/hotkeysModal.vue";
import UserProfile from "../modal/userProfile.vue";
import LegalMailFooterModal from "../modal/legalMailFooterModal.vue";
import CanJoinConferenceModal from "../modal/canJoinConferenceModal.vue";
import SendMessageBeforeConferenceModal from "../modal/sendMessageBeforeConferenceModal.vue";
import LogoutModal from "../modal/logoutModal.vue";
import SetLanguageModal from "../modal/setLanguageModal.vue";
import MobileInsideCallModal from "../modal/mobile/mobileInsideCallModal.vue";
import MobileCallModal from "../modal/mobile/mobileCallModal.vue";
import HistoryMessagesModalEffector from "../modal/historyMessagesModalEffector.vue";
import UploadAvatarModal from "../modal/uploadAvatarModal.vue";
import CameraPermissions from "../modal/cameraPermissions.vue";
import DeleteVisitorPaylinkModal from "../modal/deleteVisitorPaylinkModal.vue";
import { getCompanyDocumentsForCall } from "../../lib/wsMsg";
import JoinConferenceAnswerModal from "../modal/joinConferenceAnswerModal.vue";
import CallUserInCallModal from "../modal/callUserInCallModal.vue";
import CallPersonConferenceHallModal from "../modal/callPersonConferenceHallModal.vue"
import CallListenerWrapper from "../content/calls/callListenerWrapper.vue"
import BeaconDomainWhitelistModal from "../modal/beaconDomainWhitelistModal.vue"

export default {
  components: {
    CallUserInCallModal,
    MobileCallModal,
    MobileInsideCallModal,
    Background,
    PWAButton,
    WelcomeMessageModal,
    ModalIncomingCall,
    CallsContent,
    FirstModal,
    ModalSendMessage,
    ModalSendMessageMobile,
    NotificationsModal,
    PingPongModal,
    EditExcelView,
    DeviceSetupModal,
    CallsContentMobile,
    MeetingTimeModal,
    Home,
    AddGroupModal,
    AddTeamModal,
    AddToGroupModal,
    UserSelectModal,
    EndMeetingTimeModal,
    InfoModal,
    InfoPopUpModal,
    LicenseExpiredModal,
    LicenseAlmostExpiredModal,
    CallsContentPresentation,
    MeetingLine,
    InviteVisitorModal,
    RejectCallModal,
    JoinConferenceStartedModal,
    TimeLineEventModal,
    VotingModal,
    ReminderModal,
    DirectCallInvitationModal,
    RequestPaymentModal,
    InProgressPaymentModal,
    PaymentEventModal,
    NewEventForm,
    ActiveUserModal,
    AudioPolicyModal,
    ManageOrganisation,
    ChangelogModal,
    ErrorInCallModal,
    BeforeEndingCallModal,
    NewVersionAvailableModal,
    NotificationsModalEffector,
    BadQualityMessageModal,
    QRcodeModal,
    VideoTestModal,
    ExternalServicesModal,
    VOfficeProVersionModal,
    ModalIncomingBridgeCall,
    VideoBridgeContent,
    JoinBridgeCallModal,
    SuccessPaymentModal,
    ErrorPaymentModal,
    SendMessageInCallModal,
    FullBridgeCallModal,
    HotkeysModal,
    UserProfile,
    LegalMailFooterModal,
    CanJoinConferenceModal,
    SendMessageBeforeConferenceModal,
    PaymentCanceled,
    LogoutModal,
    SetLanguageModal,
    HistoryMessagesModalEffector,
    UploadAvatarModal,
    CameraPermissions,
    DeleteVisitorPaylinkModal,
    JitsiCall,
    JoinConferenceAnswerModal,
    CallPersonConferenceHallModal,
    CallListenerWrapper,
    CallEndedInfoModal,
    BreakStatusModal,
    DailyReportModal,
    DailyReportSettingsModal,
    BeaconDomainWhitelistModal
  },
  props: ["primaryDrawer", "openCloseSidebar", "mini"],
  data() {
    return {
      state: store.state,
      showFirstModal: false,
      isMobile: isMobile(),
      isIpad: isIpad(),
      showSetupDevice: false,
    };
  },
  effector: {
    showCallUserInCallModalStore: callUserInCallModalStore,
    showModalVotingQuality: qualityVotingModalStore,
    showModalReminder: reminderModalStore,
    showDirectCallInviteModal: directCallInvitationModalStore,
    showModalRequestPayment: paymentModalStore,
    showBadQualityMessageModal: badQualityMessageModalStore,
    showChangelogModal: changelogModalStore,
    showErrorInCallModal: errorInCallModalStore,
    showBeforeEndingCallModal: beforeEndingCallModalStore,
    showNewVersionAvailableModal: newVersionAvailableModalStore,
    showModalNotification: notificationModalStore,
    showQrCodeModal: qrCodeModalStore,
    showVideoTestModal: videoTestModalStore,
    showExternalServicesModal: externalServicesModalStore,
    showvOfficeProVersionModalStore: vOfficeProVersionModalStore,
    showJoinBridgeCallModal: joinBridgeCallStore,
    // showSendMessageInCallModal: sendMessageInCallStore,
    showModalInProgressPayment: paymentInProgressModalStore,
    showFullBridgeCallModal: fullBridgeCallStore,
    showHotkeysModal: hotkeysStore,
    showUserProfilesModal: userProfileStore,
    showLegalMailFooter: mailFooterStore,
    showcanJoinConferencesModal: canJoinConferenceStore,
    showSendMessageBeforeConference: sendMessageBeforeConferenceStore,
    showLanguageModal: languageModalStore,
    showInsideMobileModal: insideMobileModalStore,
    showMobileModal: callMobileModalStore,
    showLogoutModal: logoutModalStore,
    showHistoryMessagesModal: historyMessagesModalStore,
    showUploadAvatarModal: uploadAvatarModalStore,
    ShowerrorCameraPermissionsModal: errorCameraPermissionsModalStore,
    ShowDeleteVisitorPaylinkModalStore: deleteVisitorPaylinkModalStore,
    bridgeInfoStore: bridgeInfoStore,
    myConferences: myConferenceDetails,
    timelineEvents: timelineEvents,
    showjoinConference: joinConferenceStore,
    ShowCallPersonConferenceHallModalStore: callPersonConferenceHallModalStore,
    showCallEndedInfoModalStore: callEndedInfoModalStore,
    showBreakStatusModalStore: breakStatusModalStore,
    showDailyReportModalStore: dailyReportModalStore,
    showDailyReportSettingsModalStore: dailyReportSettingsModalStore,
    ShowBeaconDomainWhitelistStore: beaconDomainWhitelistStore
  },
  mounted() {
    EventBus.$on("leavingConference", (params) => {
      setTimeout(() => {
        this.deleteConferenceByCallUUID(params.callId, params.conferenceId);
      }, 1000);
    });
    EventBus.$on("togleMicSettings", this.toggleModalSettings);
    EventBus.$on("rejoiningSession", this.conferenceGarbageCollect);
  },
  destroyed(){
    EventBus.$off("togleMicSettings", this.toggleModalSettings);
    EventBus.$off("rejoiningSession", this.conferenceGarbageCollect);
  },
  methods: {
    async conferenceGarbageCollect() {
      if (!this.myConferences) return;
      const myTemps = this.myConferences.filter(
        (e) =>
          e.confIsTemp == true &&
          e.confModerators.indexOf(store.state.ownUUID) !== -1
      );
      if (!myTemps) return;
      if (myTemps && myTemps.length) {
        myTemps.forEach((tempConf) => {
          if (tempConf && tempConf.callUUID) {
            this.deleteConferenceByCallUUID(tempConf.callUUID, tempConf.confId);
          }
        });
      }
    },
    async deleteConferenceByCallUUID(callUUID, conferenceId) {
      let deleteConference = false;
      let tmpData = this.myConferences.find((e) => e.confId === conferenceId);
      if (
        this.bridgeInfoStore &&
        this.bridgeInfoStore.calls &&
        Object.keys(this.bridgeInfoStore.calls).length
      ) {
        if (!this.bridgeInfoStore.calls[callUUID]) {
          deleteConference = true;
        } else {
          if (this.bridgeInfoStore.calls[callUUID].users.length == 0) {
            deleteConference = true;
          }
        }
      } else {
        deleteConference = true;
      }
      if (
        deleteConference &&
        tmpData &&
        tmpData.confIsTemp &&
        tmpData.confModerators.indexOf(this.state.ownUUID) !== -1
      ) {
        const extantEv = this.timelineEvents.find(
          (e) => e.confId === conferenceId
        );
        if (extantEv && extantEv.uuid) {
          await deleteTimelineItemEvent(extantEv.uuid);
        }
        await dispatchDeleteGroupConferenceEvent(conferenceId);

        const docStore = await getCompanyDocumentsForCall(
          conferenceId,
          "conference"
        );
        if (docStore && docStore.docStoreUUID) {
          dispatchDeleteCompanyDocumentEvent(docStore.docStoreUUID);
        }
      }
    },
    hasPrivilege() {
      return hasPrivilege(store.state.ownUUID);
    },
    toggleModalSettings() {
      this.showSetupDevice = !this.showSetupDevice;
      this.state.user.guestDeviceSetted = !this.state.user.guestDeviceSetted;
    },
    toggleUserFirstModal() {
      this.showFirstModal = false;
    },
  },
  computed: {
    haveCallsListener(){
      return this.state.user.inBridgeCallListener
    },
    haveCalls(){
      return this.state.user.inBridgeCall
      // const keyArray = Object.keys(this.state.remoteBridgeStreams)
      // const key = keyArray[0]
      // if(key && key.length > 0){
      //   if(!this.state.remoteBridgeStreams[key].conferenceHall){
      //     return true
      //   }else{
      //     if(this.state.user.inBridgeCall){
      //       return true
      //     }
      //   }
      //   return false
      // }
    },
    incomingConfCallsArray: function () {
      return incomingConfCalls();
    },
    incommingCallsArray() {
      return incomingCallsBridge();
    },
    showTimeline() {
      if (
        this.$route.path === "/invitationformuser" ||
        this.$route.path === "/invitationform"
      ) {
        return false;
      }
      return true;
    },
    isWaitingRoomUser() {
      return isWaitingRoomUser(this.state.ownUUID);
    },
    isOnlyOneCallUser() {
      return isOnlyOneCallUser(this.ownUUID);
    },
    hasNotSidebar() {
      return this.isOnlyOneCallUser || this.isWaitingRoomUser || this.isMobile;
    },
    isBasicView() {
      return basicView();
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    showModal() {
      if (
        !this.state.user.guest &&
        !this.state.user.visitor &&
        this.state.user.name == ""
      ) {
        this.showFirstModal = true;
        return true;
      } else if (
        this.state.user.guest &&
        !this.state.persisted.guestInfoConfigDone
      ) {
        this.showFirstModal = true;
        return true;
      }
    },
    showInfoModal() {
      return (
        store.state.infoModal.show &&
        store.state.infoModal.header &&
        store.state.infoModal.body
      );
    },
  },
};
</script>

<style scoped lang="scss">
.mainContentContainerFullSidebar {
  width: calc(100% - 321px);
  left: 320px;
  position: relative;
  animation: all 0.4 ease-in;
}
.hideTimeline {
  top: -60px;
}
.containerGuest {
  left: 0px !important;
  width: 100% !important;
}
.mainContentContainer {
  width: calc(100% - 60px);
  left: 60px;
  position: relative;
  animation: all 0.4 ease-in;
}
.contentContainer {
  margin-top: 0px;
  height: calc(100vh - 180px);
}
.dev {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
}
.v-content {
  z-index: 0;
}
.relative {
  position: relative;
}
.slideInvitationSent {
  position: absolute;
  bottom: 0;
  right: 12px;
}
.v-tour-start {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>