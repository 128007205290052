import Vue from 'vue';
import VueI18n from 'vue-i18n';
import moment from "../sharedsrc/moment";
import { defaultLocale, messages, availableLocales } from './_locales/index.js';

Vue.use(VueI18n);

const navigatorLanguage = () => {
  return window.navigator.language && `${window.navigator.language}`.slice(0, 2).toLowerCase();
};

const getLanguage = () => {
  const language = window.localStorage.getItem('language') || navigatorLanguage() || defaultLocale;
  const supported = language && availableLocales.includes(language);
  if (supported) return language;
  return defaultLocale;
};

const i18n = new VueI18n({
  locale: getLanguage(),
  fallbackLocale: defaultLocale,
  messages
});
moment.locale(i18n.locale);

Vue.prototype.$locale = {
  change(language) {
    i18n.locale = language;
    window.localStorage.setItem('language', language);
    moment.locale(i18n.locale);
  },
  current() {
    return i18n.locale;
  }
};

Vue.prototype.$t = (key, ...params) => i18n.t(key, ...params);

export default i18n;