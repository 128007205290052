import { combine } from 'effector';
import { usersDomain } from './domain';

import {
  receivedAllUserStateEvent,
  receivedSpecificUserStateEvent,
  setOwnUserUUIDEvent,
  findUserEmailEvent,
  findUserByEmailEvent,

} from './events';

export const allUsersState = usersDomain.createStore({}, { name: 'allUsers' });

allUsersState.on(receivedAllUserStateEvent, (state, payload) => {
  return payload;
});

allUsersState.on(receivedSpecificUserStateEvent, (state, payload) => {
  let [uuid, data, group] = payload;
  if (data === null) data = undefined;
  if (state === group && state[uuid] === data) return state;
  return { ...state, [uuid]: data };
});

export const allUserUUIDs = allUsersState.map(s => Object.keys(s));

export const foundExistingEmailState = usersDomain.createStore(null, { name: 'foundExistingEmailState' });
export const foundExistingUUIDByEmailState = usersDomain.createStore(null, { name: 'foundExistingUUIDByEmail' });

foundExistingEmailState.on(findUserEmailEvent, (state, payload) => {
  const emails = [];
  const allUsers = allUsersState.getState();
  for (const uuid of Object.keys(allUsers)) {
    if (allUsers[uuid] && allUsers[uuid].user && !allUsers[uuid].user.visitor && allUsers[uuid].user.email === payload) {
      if (emails.indexOf(payload) === -1) {
        emails.push(allUsers[uuid].user.email);
      }
    }
  }
  return emails;
});

foundExistingUUIDByEmailState.on(findUserByEmailEvent, (state, payload) => {
  const uuids = [];
  const allUsers = allUsersState.getState();
  for (const uuid of Object.keys(allUsers)) {
    if (allUsers[uuid] && allUsers[uuid].user && allUsers[uuid].user.email === payload) {
      if (uuids.indexOf(allUsers[uuid].user.uuid) === -1) {
        uuids.push(allUsers[uuid].user.uuid);
      }
    }
  }
  return uuids;
});

export const ownUserUUIDState = usersDomain.createStore(null, { name: 'ownUserUUID' });

ownUserUUIDState.on(setOwnUserUUIDEvent, (state, payload) => {
  return payload;
});

export const ownUserState = combine(allUsersState, ownUserUUIDState, (allState, ownUUID) => { return allState[ownUUID] || {}; });

// allUsersState.on(runPurgeVisitorEvent, (state, payload) => {
//   return payload;
// });

// export const stupidBullshitSearch = combine(allUsersState, ownUserUUIDState, (allUsers, ownUserUUID) => {
//   const invitees = [];
//   const userUUIDS = Object.keys(allUsers);
//   for (const uuid of userUUIDS) {
//     const state = allUsers[uuid];
//     if (!state || !state.visitorDate || !state.visitorDate.userInviter) continue;
//     if (state.invitedBy === ownUserUUID) {
//       invitees.push(uuid);
//     }
//   }
//   return invitees;
// });
