var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.person && _vm.person.user && _vm.person.user.uuid)?_c('span',[(_vm.organisationData && _vm.organisationData.name)?[_c('span',[_vm._v(_vm._s(_vm.organisationData.name))]),_vm._v(" "),_c('br')]:_vm._e(),_vm._v(" "),(_vm.person && _vm.person.user && _vm.person.user.uuid)?[_c('span',[_vm._v(_vm._s(_vm.assistant ? "Assistant" : _vm.getUserTitel(_vm.person.user.uuid))+"\n      "+_vm._s(_vm.person.user.name))]),_vm._v(" "),(_vm.hasRepresentative(_vm.person.user.uuid))?_c('br'):_vm._e(),_vm._v(" "),(_vm.hasRepresentative(_vm.person.user.uuid))?_c('span',[_vm._v(_vm._s(_vm.getUserRepresentative(_vm.person.user.uuid)))]):_vm._e(),_vm._v(" "),(
        _vm.person.user.position ||
        (_vm.person.user.visitorData &&
          _vm.person.user.visitorData.noteForContact &&
          _vm.person.user.visitorData.noteForContact.length)
      )?_c('br'):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.hasPrivilege(_vm.person.user.uuid) && _vm.person.user.position)?_c('span',[_vm._v(_vm._s(_vm.person.user.position))]):_vm._e(),_vm._v(" "),(
      !_vm.hasPrivilege(_vm.person.user.uuid) &&
      _vm.person.user.visitorData &&
      _vm.person.user.visitorData.noteForContact
    )?_c('span',[_vm._v(_vm._s(_vm.person.user.visitorData.noteForContact))]):_vm._e(),_vm._v(" "),(
      _vm.hasPrivilege(_vm.person.user.uuid) && _vm.getLocationTranslated(_vm.person.user)
    )?[_c('br'),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.getLocationTranslated(_vm.person.user)))])]:_vm._e(),_vm._v(" "),(_vm.state.namespaceSettings.displayUserStatus)?[(_vm.getUserActivity(_vm.person) && !_vm.isGuest)?_c('br'):_vm._e(),_vm._v(" "),(!_vm.isGuest)?_c('span',[(_vm.getCallText(_vm.person) && _vm.person.user.activity !== 'No status')?_c('font-awesome-icon',{attrs:{"icon":['fal', 'video']}}):_vm._e(),_vm._v("\n      "+_vm._s(_vm.getCallText(_vm.person) && _vm.person.user.activity !== "No status"
          ? _vm.getCallText(_vm.person)
          : _vm.checkStatusLabel)+"\n    ")],1):_vm._e(),_vm._v(" "),(
        (!_vm.getCallText(_vm.person) && _vm.getPersonInHolidays && !_vm.isGuest) ||
        (_vm.getCallText(_vm.person) && _vm.getPersonInHolidays)
      )?_c('br'):_vm._e(),_vm._v(" "),(_vm.getPersonInHolidays && !_vm.isGuest)?_c('span',[_vm._v(_vm._s(_vm.moment(new Date(_vm.person.user.startHolidays)).format("DD.MM.YY"))+"\n      -\n      "+_vm._s(_vm.moment(new Date(_vm.person.user.endHolidays)).format("DD.MM.YY")))]):_vm._e(),_vm._v(" "),(_vm.person.user.activity == 'Break' && _vm.person.user.breakTimeEnd)?[_c('br'),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.breakTimeEnd)+" "+_vm._s(_vm.$t("generics.hours"))+" ")])]:_vm._e(),_vm._v(" "),_c('br')]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }