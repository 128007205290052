<template>
  <v-row>
    <v-col cols="6" class="py-0">
      <v-text-field
        v-model="companyRegisterObject.lastName"
        :label="`${$t('components.companyRegister.lastName')}*`"
        :rules="contactNameRules"
        required
        outlined
        dense
      >
      </v-text-field>
    </v-col>
    <v-col cols="6" class="py-0">
      <v-text-field
        v-model="companyRegisterObject.firstName"
        :label="`${$t('components.companyRegister.firstName')}*`"
        :rules="contactNameRules"
        required
        outlined
        dense
      >
      </v-text-field>
    </v-col>
    <v-col cols="6" class="py-0">
      <v-text-field
        v-model="companyRegisterObject.company_name"
        :label="
          isGermanLawyer
            ? `${$t('components.companyRegister.firmenameOption')}*`
            : `${$t('components.companyRegister.companyName')}*`
        "
        :rules="companyNameRules"
        required
        outlined
        dense
      >
      </v-text-field>
    </v-col>
    <v-col cols="6" class="py-0 pRelative">
      <div>
        <p class="pContact px-1">
          {{ $t('components.companyRegister.contactNumber') }}*
          <v-tooltip top max-width="400">
            <template v-slot:activator="{ on }">
              <font-awesome-icon
                v-on="on"
                color="#02889d"
                :icon="['fal', 'info-circle']"
                :style="{
                  fontSize: '17px',
                  cursor:'pointer'
                }"
              />
            </template>
            <span>{{
              $t("components.companyRegister.phoneTT")
            }}</span>
          </v-tooltip>
        </p>
        <vue-tel-input
          :class="{ borderError: !phoneValid }"
          enabledCountryCode
          @onInput="onTelInput"
          ref="countryInput"
          name="number"
          id="numDisplay"
          class="form-control text-center input-sm"
          :placeholder="$t('components.companyRegister.example')"
          autocomplete="off"
          :defaultCountry="countryCodeInput"
          disabledFetchingCountry
        ></vue-tel-input>
      </div>
      <!-- <v-text-field
        v-model="companyRegisterObject.tax_number"
        :label="$t('components.companyRegister.taxNumber')"
        :rules="taxNumberRules"
        required
        outlined
        dense
        >
      </v-text-field> -->
    </v-col>
    <!-- <v-col cols="12" class="py-0" v-if="!isPackagedVersion && isEuropeanContries">
      <v-text-field
        ref="vatInput"
        v-model="vatNumber"
        :label="$t('components.companyRegister.vatNumber')"
        required
        outlined
        dense
        :rules="vatNumberRules"
        @blur="checkVatNumber"
      >
        <template slot="append">
          <span class="custom-loader-vat-number mt-1" v-if="vatNumberLoading && vatNumber.length > 0">
            <font-awesome-icon
              :icon="['fal', 'spinner-third']"
              :style="{ fontSize: '15px' }"
            />
          </span>
        </template>
      </v-text-field>
    </v-col> -->
    <v-col cols="12" class="py-0">
      <v-text-field
        v-model="companyRegisterObject.email"
        :label="`${$t('components.companyRegister.email')}*`"
        :rules="emailRules"
        required
        outlined
        dense
      >
      </v-text-field>
    </v-col>
    <!-- <v-col cols="6" class="py-0 pRelative">
       <div>
        <p class="pContact px-1">{{ $t('components.companyRegister.contactNumber') }}</p>
          <vue-tel-input :class="{ 'borderError': !phoneValid }" enabledCountryCode @onInput="onTelInput" name="number" id="numDisplay" class="form-control text-center input-sm" value="" placeholder="Example: 612 34 56 78" autocomplete="off"></vue-tel-input>
      </div>
    </v-col> -->
  </v-row>
</template>

<script>
  import store, { EventBus } from '../../store'
  export default {
    props: [
      'isGermanLawyer',
      // "isEuropeanContries",
      'isPackagedVersion',
      // "checkVatNumber",
      // "vatNumberValid",
      // "vatNumberLoading",
      'countryCodeInput',
    ],
    components: {},
    data: () => ({
      valid: true,
      state: store.state,
      setSetUpConfigRoute: store.setSetUpConfigRoute,
      companyRegisterObject: store.state.namespaceSettings.companyInfo,
      company_name: store.state.namespaceSettings.companyInfo.company_name,
      email: store.state.namespaceSettings.companyInfo.email,
      phoneValid: true,
      // vatNumber: '',
    }),
    watch: {
      countryCodeInput: {
        handler: function(value) {
          if (this.$refs.countryInput) {
            const countries = this.$refs.countryInput.filteredCountries
            countries.forEach((c) => {
              if (c.iso2 === value) {
                this.$refs.countryInput.activeCountry = c
              }
            })
          }
        },
      },
      // isEuropeanContries: {
      //   // immediate: true,
      //   handler: function (europeanContrie) {
      //     if(!europeanContrie){
      //       this.vatNumber=''
      //     }
      //   },
      // },
    },
    methods: {
      onTelInput({ number, isValid, country }) {
        if (isValid) {
          const phone = `${(number || {}).international || ""}`
            .replace(/^00/, "+")
            .replace(/[-\s]/g, "")
            .trim();
          if (!phone || !/^\+\d+$/.test(phone)) {
            isValid = false;
          } else {
            if (
              !this.state.user.recoveryPhone ||
              !store.state.namespaceSettings.companyInfo.phone ||
              this.state.user.recoveryPhone === `${store.state.namespaceSettings.companyInfo.phone || ''}`.replace(/^00/, "+")
            ) {
              this.$set(this.state.user, "recoveryPhone", phone);
            }
            store.state.namespaceSettings.companyInfo.phone = phone.replace(/^\+/, "00");
          }
        }
        this.phoneValid = isValid;
        EventBus.$emit("phoneValid", this.phoneValid);
      },
      // checkVat() {
      //   this.checkVatNumber(this.vatNumber);
      // },
    },
    computed: {
      cols() {
        const { lg, sm, md, xs } = this.$vuetify.breakpoint
        return lg
          ? [12, 12, 12, 12]
          : sm
          ? [12, 12, 12, 12]
          : md
          ? [12, 12, 12, 12]
          : xs
          ? [12, 12, 12, 12]
          : [12, 12, 12, 12]
      },
      cols2() {
        const { lg, sm, md, xs } = this.$vuetify.breakpoint
        return lg ? [8, 4] : sm ? [8, 4] : md ? [8, 4] : xs ? [8, 4] : [8, 4]
      },
      cols3() {
        const { lg, sm, md, xs } = this.$vuetify.breakpoint
        return lg ? [6, 6] : sm ? [6, 6] : md ? [6, 6] : xs ? [6, 6] : [6, 6]
      },
      // vatNumberRules() {
      //   if(!this.vatNumberLoading ){
      //     return [
      //       (v) => !!v || this.$t("components.companyRegister.vatNumberRequired"),
      //       !!this.vatNumberValid ||
      //         this.$t("components.companyRegister.vatNumberInvalid"),
      //     ];
      //   }
      // },
      companyNameRules() {
        return [
          (v) =>
            !!v || this.$t('components.companyRegister.companyNameRequired'),
          (v) =>
            v.length >= 3 ||
            this.$t('components.companyRegister.companyNameMinimum'),
        ]
      },
      contactNameRules() {
        return [
          (v) =>
            !!v || this.$t('components.companyRegister.contactNameRequired'),
          (v) =>
            /^[A-Za-zÑÖÄÜñöäü]{1,}[\-/\s]{0,1}[A-Za-zÑÖÄÜñöäü]{0,}$/.test(v) ||
            this.$t('components.companyRegister.contactNameInvalid'),
        ]
      },
      emailRules() {
        return [
          (v) => !!v || this.$t('components.companyRegister.emailRequired'),
          (v) =>
            /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) ||
            this.$t('components.companyRegister.emailMustValid'),
        ]
      },
      taxNumberRules() {
        return [
          (v) => !!v || this.$t('components.companyRegister.taxNumberRequired'),
          (v) => /^[\w\d]+$/.test(v) || '',
        ]
      },
    },
    mounted() {
      // this.$refs.form.validate();
    },
  }
</script>

<style scoped lang="scss">
  .pRelative {
    position: relative;
  }
  .pContact {
    margin-bottom: -6px !important;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
    height: 20px;
    max-width: 133%;
    transform: translateY(0px) translateX(-40px) scale(0.75);
    position: absolute;
    top: -10px;
    left: 48px;
    background: white;
  }
  .custom-loader-vat-number {
    animation: loader 1s infinite;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
