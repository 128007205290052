<template>
  <v-list max-width="320">
    <v-list-group
      :value="searchFoundSomethingDepartment || isActive"
      @click="setList('departments')"
      :color="isDarkMode? 'white':'black'"
    >
      <template v-slot:activator>
        <v-list-item-icon>
          <font-awesome-icon class="primary--text" :icon="['fal', 'sitemap']" />
        </v-list-item-icon>
        <v-list-item-title
          :class="`${
            getRoute === '/organisation' &&
            state.activeOrganisationSection == 'departments'
              ? 'font-weight-bold'
              : ''
          }`"
          @click.stop.prevent="
            handleOrganisation();
            searchFoundSomethingDepartment;
          "
        >
          <span class="pr-4 ">{{
            data ? Object.keys(data).length : "0"
          }}</span>
          {{
            state.namespaceSettings.departmentAlias
              ? state.namespaceSettings.departmentAlias
              : $t("generics.departments")
          }}
        </v-list-item-title>
      </template>
      <div>
        <v-list dense class="listHeight">
          <v-list-item
            link

            class="ml-9 cursorAuto"
            v-for="(people, department) in data"
            :key="department"
          >
          <div class="text-truncate " :class="selectedDepartmentName === department ? 'font-weight-bold primary--text': ''"  @click="openUserDepartment(department)">
            {{ department }}
            </div>
          </v-list-item>
        </v-list>
      </div>
      <!-- <vue-scroll class="vueScrollOrganistion">
        <div class="divScroll">
          <v-list-group
          :color="isDarkMode? 'white':'black'"
            no-action
            sub-group
            v-for="(people, department) in data"
            :key="department"
            class="vListGroupSections"
          >
            <template v-slot:activator>
              <v-list-item-content class="text-truncate">
                <v-list-item-title class=" fontSize15">{{
                  department
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <UserList :persons="people" :section="department" />
          </v-list-group>
        </div>
      </vue-scroll> -->
    </v-list-group>
  </v-list>
</template>

<script>
import store, { EventBus } from "../../store";
import UserList from "./userList.vue";

export default {
  props: ["data", "active", "setList", "searchFoundSomethingDepartment"],
  components: {
    UserList,
  },
  data: () => ({
    state: store.state,
    selectedDepartmentName: null
  }),
  watch:{
    $route (to, from){
      if(to.path !== '/departments'){
        this.selectedDepartmentName = null;
      }
    }
  },
  methods: {
    openUserDepartment(departmentName){
      if(this.selectedDepartmentName === departmentName){
        this.selectedDepartmentName = null;
        EventBus.$emit('showUsersOrganisation', undefined);
        return;
      }
      this.selectedDepartmentName = departmentName
        const data = {
          type:'departments',
          name: departmentName
        };
      if(this.$router.currentRoute.path !== '/departments'){
        this.$router.push({ path: `/departments` });
      }
      setTimeout(() => {
        EventBus.$emit('showUsersOrganisation', data);
      }, 900);
    },
    handleOrganisation: function () {
      this.setCurrentContentVisile("departments", true, this.$router);
      this.selectedDepartmentName = null;
      EventBus.$emit('showUsersOrganisation', undefined);
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
  },
  computed: {
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    getRoute() {
      return this.$route.path;
    },
    isActive() {
      return this.active == "departments";
    },
  },
};
</script>
<style scoped lang="scss">
.cursorAuto{
  cursor: auto;
}
.w80 {
  width: 80%;
}
.listHeight{
  max-height: 160px;
  overflow:auto;
}
.fontSize15 {
  font-size: 15px !important;
}
.vueScrollOrganistion {
  & .divScroll {
    max-height: calc(100vh - 650px) !important;
    @media screen and (max-height: 650px) {
      max-height: calc(100vh - 150px) !important;
    }
  }
  // avoiding empty spaces
}
.v-list {
  padding: 0;
}

.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}

.v-card .v-list-item {
  padding-left: 16px !important;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
</style>

<style lang="scss">
.vueScrollOrganistion {
  & .__view {
    width: unset !important;
  }
}
.vListGroupSections {
  .v-list-item {
    height: 36px !important;
    min-height: 36px !important;
    max-height: 36px !important;
  }
  .v-list-item__icon {
    margin: 5px !important;
  }
}
</style>