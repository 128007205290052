var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.messagesArray.length !== 0)?_c('v-list',{staticStyle:{"overflow":"hidden","height":"370px"},attrs:{"two-line":"","min-height":310}},[_c('div',{staticClass:"vueScroll"},[_c('v-list-item-group',{attrs:{"active-class":"primary--text"}},_vm._l((_vm.messagesArray),function(message,index){return _c('div',{key:index},[_vm._l((message),function(individual,i){return [((individual || {}).info)?_c('v-list-item',{key:i,class:message.read === false ? 'notiUnread' : '',on:{"click":function($event){_vm.setShowModalNotify(message.userUUID, {
                id: message.uuidMessage,
                type: individual.notificationType,
                notification: message.info,
                date: message.date,
              });
              _vm.handleTotalNotifications(message, individual.notificationType);}},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-list-item-avatar',{staticClass:"borderRadius10 cursorPointer",style:(_vm.getBorderByStatusFull(_vm.state.group[message.userUUID], '2px')),attrs:{"tile":"","size":"60"}},[(
                    _vm.getAvatarForUuid(message.userUUID) ==
                    'img/default_profile_picture.png'
                  )?_c('DefaultAvatarGrid',{attrs:{"size":120,"userData":_vm.state.group[message.userUUID]}}):_vm._e(),_vm._v(" "),(
                    _vm.getAvatarForUuid(message.userUUID) !=
                    'img/default_profile_picture.png'
                  )?_c('v-img',{staticClass:"mx-2 borderRadius10",attrs:{"max-height":"60","max-width":"60","contain":"","src":_vm.getAvatarForUuid(message.userUUID)}}):_vm._e()],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',{class:individual.notificationType === 'message' && !message.read
                      ? 'font-weight-bold textBlue'
                      : '',domProps:{"textContent":_vm._s(_vm.getNameForUuid(message.userUUID))}}),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(
                    individual.subject === 'Incoming call'
                      ? _vm.$t('components.notificationsModal.missedCall')
                      : individual.subject || 'New message'
                  )}})],1),_vm._v(" "),_c('v-list-item-action',[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(_vm.formatNoticationDate(message.date))}}),_vm._v(" "),_c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[_c('DeleteBasket',{attrs:{"indexData":[message, individual.notificationType],"delFunction":_vm.handleTotalNotifications,"extraFunction":_vm.removeMessage,"extraFunctionParams":[
                      message.userUUID,
                      message.uuidMessage,
                      undefined ],"extraClasses":"mr-4","fontSize":"16","prevent":"true"}}),_vm._v(" "),(
                      individual.notificationType === 'call' &&
                      !_vm.isWaitingRoomUser
                    )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('font-awesome-icon',_vm._g({staticClass:"mr-4",attrs:{"disabled":"","icon":['fal', 'phone'],"color":!_vm.disableCallBtn(message.userUUID)
                            ? _vm.setBorderByStatus(_vm.state.group[message.userUUID])
                            : 'grey'},on:{"click":function($event){$event.stopPropagation();_vm.handleTotalNotifications(
                            message,
                            individual.notificationType
                          );
                          _vm.disableCallBtn(message.userUUID)
                            ? ''
                            : _vm.callUser(message.userUUID);}}},on))]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.disableCallBtn(message.userUUID)
                        ? "Dieser Benutzer ist zur Zeit offline"
                        : _vm.$t("components.userProfile.callUser")))])]):_vm._e()],1)],1)]}}],null,true)}):_vm._e(),_vm._v(" "),(i + 1 < _vm.messagesArray.length)?_c('v-divider',{key:i}):_vm._e()]})],2)}),0)],1),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnRed",staticStyle:{"float":"right","margin":"5px 5px 0px 0px"},attrs:{"icon":"","color":"white"}},on),[_c('DeleteBasket',{attrs:{"indexData":"","delFunction":_vm.removeAllListedItems,"extraClasses":"","fontSize":"20","prevent":"false"}})],1)]}}],null,false,2480100443)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.downloadsDropdown.removeAll")))])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }