<template>
  <v-row class="mx-auto">
    <v-col class="px-2 d-flex justify-end">
      <div>
        <span> {{ $t("components.readGridMessage.text1") }} </span>
        <br />
        <span> {{ $t("components.readGridMessage.text2") }} </span>
        <br />
        <span> {{ $t("components.readGridMessage.text3") }} </span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import store from "../../store";
export default {
  data() {
    return {
      state: store.state,
    };
  },
  mounted() {
    // store.setReadGridMessage(true);
  },
  destroyed(){
    store.setReadGridMessage(true);
  },
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss">
</style>