<template>
  <v-dialog v-if="showModal" v-model="showModal" persistent max-width="550" >
    <v-card>
      <v-card-title class="title">
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        {{ $t('components.licenseExpiredModal.licenseExpired') }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <p>{{ $t('components.licenseExpiredModal.pleaseRenew') }}</p>
        <p>{{ $t('components.licenseExpiredModal.continueUsing') }}</p>
      </v-card-text>
      <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns mx-0" flex>
          <v-col cols="8" class="px-0 py-0" align="right">
            <v-btn color="primary" @click="openPage();">{{ $t('components.licenseExpiredModal.activate') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
export default {
  data() {
    return {
      state: store.state
    };
  },
  watch: { },
  mounted() { },
  methods: {
    openPage() {
      window.open("https://www.ra-micro-online.de/net/", "_blank");
    }
  },
  computed: {
    showModal() {
      // return true;
      // return (this.state.namespaceSettings.validSubscription === false); // http://gitlab.devcups.com/devcups/voffice/issues/542
      const expiresTs = (this.state.namespaceSettings.validSubscription || {}).expiresTs;
      return (expiresTs && Date.now() > expiresTs);
    }
  }
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
