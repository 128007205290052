<template>
  <v-dialog
    v-model="startView"
    persistent
    max-width="650"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.userSettings.startView')"
        :closeModalFunction="closeModal"
      />
      <div class="d-flex">
        <v-list-item class="py-0 switchWrapper">
          <v-list-item-content class="py-0 px-3 my-0">
            <v-radio-group v-model="startViewPage" row v-for="data in getStartView" :key="data.index">
              <v-radio :label="data.name" :value="data.value"></v-radio>
            </v-radio-group>
          </v-list-item-content>
        </v-list-item>
      </div>
      <FooterModal :closeModalFunction="changeStartView" />
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  props: ["startView", "showStartView"],
  data: () => ({
    state: store.state,
    isMobile: isMobile(),
    startViewPage: store.state.user.userSettings.startView || "my-favorites",
  }),
  methods: {
    setUserSettings(userSettings) {
      return store.setUserSettings(userSettings);
    },
    changeStartView() {
      const temporalUserSettings = store.state.user.userSettings;
      temporalUserSettings.startView = this.startViewPage;
      this.setUserSettings(temporalUserSettings);
      this.closeModal();
    },
    closeModal() {
      this.showStartView();
    },
  },
  computed:{
    getStartView() {
      return [
        {index:0, value: 'home', name: 'Logo'},
        {index:1, value: 'my-favorites', name: this.$t('components.userSettings.favorites')},
        {index:2, value: 'users', name: this.$t('components.userSettings.user')},
        {index:3, value: 'organisation', name: this.$t('components.userSettings.organization')},
        {index:4, value: 'waitingroom', name: this.$t("components.waitingRoom.waitingRoom")},
      ].sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
  }
};
</script>

<style scoped lang="scss">
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 16px !important;
}
</style>