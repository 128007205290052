var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mainMeetingLine timelineEntryContainer"},[(_vm.getCurrentDateTimelineEvents.length > 0)?_vm._l((_vm.getCurrentDateTimelineEvents),function(entry){return _c('MeetingLineItem',{key:entry.uuid,attrs:{"totalEntries":_vm.currentDateTimelineEvents.length,"index":entry.collisionIndex,"entry":entry,"date":_vm.date}})}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"meetingLineSpacerBody"},[_c('div',{staticClass:"boxupperLast",on:{"click":_vm.goToCalendar}},[_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex justify-space-around align-center align-center pt-2 pr-2 pl-1"},on),[_c('font-awesome-icon',{style:({ color: 'white', fontSize: '20px' }),attrs:{"icon":['fal', 'chevron-left']},on:{"click":function($event){return _vm.prevDay($event)}}}),_vm._v(" "),_c('span',{staticClass:"d-md-none d-sm-none d-none d-lg-flex white--text caption font-weight-bold"},[_vm._v(_vm._s(_vm.moment(_vm.date).format('DD.MM.YY')))]),_vm._v(" "),_c('font-awesome-icon',{style:({ color: 'white', fontSize: '20px' }),attrs:{"icon":['fal', 'chevron-right']},on:{"click":function($event){return _vm.nextDate($event)}}}),_vm._v(" "),(_vm.showRepresentativeBtn)?_c('v-menu',{ref:"startDateMenu",attrs:{"content-class":"bgw","close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('font-awesome-icon',_vm._g({staticClass:"mr-2",style:({ color: 'white', fontSize: '14px' }),attrs:{"icon":['fal', 'user']}},Object.assign({}, onMenu, onTooltip)))]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('components.calendarContainer.seeCalendarFor')))])])]}}],null,true),model:{value:(_vm.showSelector),callback:function ($$v) {_vm.showSelector=$$v},expression:"showSelector"}},[_vm._v(" "),_c('v-select',{staticClass:"ma-2 bgw",attrs:{"return-object":"","value":_vm.representing,"item-text":"name","item-value":"uuid","items":_vm.peopleIRepresent,"dense":"","outlined":"","hide-details":""},on:{"change":_vm.switchCalendarUser}})],1):_vm._e()],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('components.calendarContainer.videoCallPlanner')))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"width":"26px","height":"26px"},attrs:{"icon":""},on:{"click":function($event){return _vm.goToMyEvents($event)}}},on),[(_vm.totalEvents > 0)?_c('v-badge',{staticStyle:{"margin-left":"0px"},attrs:{"bordered":"","color":"error","content":_vm.totalEvents,"value":_vm.totalEvents,"height":"26","width":"26","overlap":""}}):_vm._e(),_vm._v(" "),(_vm.totalEvents == 0)?_c('font-awesome-icon',{attrs:{"color":"transparent","icon":['fal', 'eye']}}):_vm._e()],1)]}}])},[_vm._v(" "),(_vm.totalEvents == 1)?_c('span',[_vm._v(_vm._s(1 + ' '+ _vm.$t('components.calendarContainer.viewMyEvent')))]):(_vm.totalEvents > 1)?_c('span',[_vm._v(_vm._s(_vm.totalEvents + ' '+ _vm.$t('components.calendarContainer.viewMyEvents')))]):_c('span',[_vm._v(_vm._s(_vm.$t('components.calendarContainer.noEventsToSee')))])])],1)]),_vm._v(" "),_vm._l((['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18','19']),function(hour,index){return _c('MeetingLineSpacer',{key:index,attrs:{"hour":hour,"date":_vm.date,"handleNewEventEntry":_vm.handleNewEventEntry}})}),_vm._v(" "),_c('MeetingLineNowIndicator',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTimelineNow),expression:"showTimelineNow"}]})],2),_vm._v(" "),_c('div'),_vm._v(" "),_c('ModalEventType',{attrs:{"showModalEventType":_vm.showModalEventType,"showModalEventTypeFunction":_vm.showModalEventTypeFunction,"closeModalEventTypeFunction":_vm.closeModalEventTypeFunction,"cancelModalEventTypeFunction":_vm.cancelModalEventTypeFunction,"typeVideoCall":_vm.typeVideoCall,"changeEventType":_vm.changeEventType}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }