<template>
  <v-card class="mx-auto elevation-0" flat max-width="100%" outlined>
    <v-divider class="mt-1"></v-divider>
    <v-list-item three-line>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-list-item-avatar
            v-on="on"
            tile
            size="80"
            class="borderRadius5 mx-2 my-2 grayScale"
          >
            <v-img
              class="mx-2 borderRadius5 cursorPointer"
              @error="onImgError"
              src="img/default_profile_picture.png"
              max-height="80"
              max-width="80"
              contain
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
          </v-list-item-avatar>
        </template>
        <span>{{ visitor.name }}</span>
      </v-tooltip>
      <v-list-item-content class="contentWrapper">
        <div class="notranslate">
          <span style="font-size: 14px">{{ visitor.email }}</span>
        </div>
        <v-list-item-subtitle
          >{{ $t("components.inviteVisitor.appointmentshort") }}:
          {{ visitor.linkDateTo }}
          {{ visitor.linkTimeTo }}</v-list-item-subtitle
        >
        <v-tooltip top v-if="visitor.visitorData.subject">
          <template v-slot:activator="{ on }">
            <v-list-item-subtitle v-on="on" style="cursor: pointer"
              >{{ $t("components.inviteVisitor.subject") }}:
              {{
                visitor.visitorData.subject.substring(
                  0,
                  Math.min(visitor.visitorData.subject.length, 20)
                )
              }}...</v-list-item-subtitle
            >
          </template>
          <span>{{ visitor.visitorData.subject }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-list-item-subtitle v-on="on" style="cursor: pointer"
              >{{ $t("components.inviteVisitor.note") }}:
              {{
                visitor.visitorData.internalnote.substring(
                  0,
                  Math.min(visitor.visitorData.internalnote.length, 14)
                )
              }}...</v-list-item-subtitle
            >
          </template>
          <span>{{ visitor.visitorData.internalnote }}</span>
        </v-tooltip>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
import store from "../../../store";
import SendInstaLinkPanel from "../../navbar/sendInstaLinkPanel.vue";
export default {
  props: ["visitor"],
  components: { SendInstaLinkPanel },
  data() {
    return {
      state: store.state,
      failedImage: false,
      link: "",
    };
  },
  methods: {
    onImgError() {
      this.failedImage = true;
    },
  },
};
</script>

<style scoped lang="scss">
.contentWrapper {
  max-width: 200px;
}
.borderRadius5 {
  border-radius: 5px !important;
}
.cursorPointer {
  cursor: pointer;
}
.v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child) {
  margin-left: 0px !important;
}
.v-card .v-list-item {
  padding-left: 4px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0px !important;
}
.grayScale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>

