<template>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      max-width="570"
      @keydown.esc="closeModal"
    >
      <v-card>
        <HeaderModal
          :titleModal="$t('components.endMeetingModal.disconnected')"
        />
        <FooterModal :closeModalFunction="closeModal" :showClose="true">
        </FooterModal>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
    };
  },
  mounted() {
    try {
      const audio = new Audio("/media/notificationSound.mp3");
      audio
        .play()
        .catch((error) =>
          console.warn("Failed to play notification audio", error)
        );
      setTimeout(() => {
        this.closeModal();
      }, 2000);
    } catch (error) {
      console.warn("Failed to play notification audio", error);
    }
  },
  methods: {
    closeModal() {
      return store.setshowModalEndMeeting(undefined);
    },
  },
  computed: {
    showModal() {
      return store.state.showModalEndMeeting !== undefined;
    },
  },
};
</script>
