<template>
  <v-dialog
    v-model="showConfrimModal"
    persistent
    max-width="560"
    @keydown.esc="doLeavePage"
  >
    <template>
      <v-card>
        <HeaderModal
          :titleModal="$t('generics.info')"
          :closeModalFunction="doLeavePage"
        />
        <v-row :style="{ width: '100%', margin: 0 }" class="px-5 mb-5 mt-5">
          <span>{{$t('components.conferences.saveData')}}
          </span>  
        </v-row>
        <v-row v-if="isDebugging" :style="{ width: '100%', margin: 0 }" class="px-5 mb-5 mt-5">
          <span>{{showDiff}}
          </span>  
        </v-row>
        <FooterModal
          :hideDefaultButtons="true"
          :closeModalFunction="doLeavePage"
          :showFooter="true"
        >
        <v-btn
            class="mr-3"
            color="primary"
            @click="doLeavePage()"
            >{{ $t("components.conferences.yes") }}
          </v-btn>

          <v-btn
            color="primary"
            @click="doStayInPage()"
            >{{ $t("components.conferences.no") }}
          </v-btn>
        </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {

  props: ["stayInPage", "leavePage", "showConfrimModal", "getDiff"],
  components: { HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
    isDebugging: false,
  }),
  computed:{ 
    showDiff(){
      return this.getDiff();
    }
  },
 
  methods: {
    doStayInPage() {
     this.stayInPage()
    },
    doLeavePage() {
     this.leavePage();   
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>