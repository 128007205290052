<template>
  <v-dialog
    v-model="ShowCallPersonConferenceHallModalStore"
    persistent
    max-width="560"
    @keydown.esc="closeModal"
  >
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="`Call ${getNameForUuid(
            ShowCallPersonConferenceHallModalStore
          )}`"
          :closeModalFunction="closeModal"
        />
        <v-row flex class="w-100 mx-0 my-0">
          <v-divider class="mt-4"></v-divider>
          <v-col
            v-for="n in 2"
            :key="n"
            :lg="cols[n - 1]"
            :md="cols[n - 1]"
            :sm="cols[n - 1]"
            :xl="cols[n - 1]"
          >
            <div v-if="n == 1" align="center">
              <v-badge
                bordered
                overlay
                height="60"
                width="60"
                bottom
                color="#008000"
                dot
                offset-x="22"
                offset-y="15"
              >
                <v-list-item-avatar tile size="150" class="borderRadius10">
                  <v-img
                    class="borderRadius10"
                    max-height="150"
                    max-width="150"
                    contain
                    :src="
                      getAvatarForUuid(ShowCallPersonConferenceHallModalStore)
                    "
                    lazy-src="assets/default_profile_picture.png"
                  ></v-img>
                </v-list-item-avatar>
              </v-badge>
            </div>
            <div class="mt-2" v-if="n == 2">
              <div>{{$t("components.adminSettingsModal.events")}}</div>
              <div class="userNameCard my-0" :style="{ fontSize: '20px' }">
                <v-radio-group
                  v-model="isPodium"
                  :column="false"
                  class="mt-1 radioGroup"
                >
                  <v-radio
                    :disabled="getTotalUserInPodium>=12"
                    class="mr-8"
                    :label="$t('components.callsContent.podium')"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    class="mr-8"
                    :label="$t('components.callsContent.listener')"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </div>
            </div>
          </v-col>
        </v-row>
        <FooterModal :closeModalFunction="closeModal">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="white"
                ref="callPerson"
                @click="callPerson(ShowCallPersonConferenceHallModalStore)"
              >
                <font-awesome-icon
                  :icon="['fal', 'phone']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("generics.call") }}</span>
          </v-tooltip>
        </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
// import { wsCall } from "../../lib/wsConnect";
import { wsCallStartBridgeCall } from "../../utils/calls";
import {
  callPersonConferenceHallModalStore,
  resetCallPersonConferenceHallModalEvent,
} from "../../effector/modals";
import { bridgeInfoStore } from "../../effector/users/bridgeInfo";
import {
  myCompanyDocumentsDetails,
  updateCompanyDocumentEvent,
} from "../../effector/companyDocuments";
import {
  myConferenceHallDetails,
} from "../../effector/conferenceHalls";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  components: { HeaderModal, FooterModal },
  props: [],
  data: () => ({
    state: store.state,
    isPodium: false,
  }),
  effector: {
    ShowCallPersonConferenceHallModalStore: callPersonConferenceHallModalStore,
    bridgeInfoStore: bridgeInfoStore,
    myDocuments: myCompanyDocumentsDetails,
    myConferenceHallDetails: myConferenceHallDetails,
  },
  watch: {
    totalParticipantPodium: {
      immediate: true,
      handler: function (value) {
        if(value>=13){
          this.isPodium = false
        }
      }
    },
   },
  mounted() {},
  destroyed() {
    if (
      this.state.user.inBridgeCall &&
      this.state.user.inBridgeCall.length > 0
    ) {
      store.setCurrentContentVisile("/", false, this.$router);
    }
  },
  // beforeDestroy() {
  //   if (this.state.inBridgeCall && this.state.inBridgeCall.length > 0) {
  //     store.setCurrentContentVisile("/", false, this.$router);
  //   }
  // },
  methods: {
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    callPerson(uuid) {
      const remoteBridgeStreamsKey = Object.keys(
        store.state.remoteBridgeStreams
      );
      if (remoteBridgeStreamsKey.length > 0) {
        let callInfo =
          store.state.remoteBridgeStreams[remoteBridgeStreamsKey[0]];
        const callUUID = callInfo.callUUID;
        const bridgeInfo = this.bridgeInfoStore;
        const numberOfPeople = ((bridgeInfo.calls[callUUID] || {}).users || [])
          .length;
        let userIsInConferenceAttendees = false;
        for (let i = 0; i < callInfo.conferenceAttendees.length; i++) {
          const member = callInfo.conferenceAttendees[i];
          if (member.uuid === uuid) {
            userIsInConferenceAttendees = true;
            member.isPodium = this.isPodium;
          }
        }
        if (!userIsInConferenceAttendees) {
          callInfo.conferenceAttendees.push({
            addUserToCall: true,
            isPodium: this.isPodium,
            name: this.getNameForUuid(uuid),
            showUserName: true,
            uuid: uuid,
          });
        }
        const infoStartBridgeCall = {
          callUUID: callInfo.callUUID,
          isNormalMode: callInfo.isNormalMode,
          initiator: callInfo.initiator || "",
          callStartTs: callInfo.callStartTs,
          callDurationMs: callInfo.callDurationMs,
          infiniteCall: callInfo.infiniteCall,
          conferenceDocuments: callInfo.conferenceDocuments || [],
          userConferencePosition: callInfo.userConferencePosition || {},
          excludedFromCalling: callInfo.usersToExclude || [],
          conferenceAttendees: callInfo.conferenceAttendees || [],
          confModerators: callInfo.confModerators || [],
          conferenceSettings: callInfo.conferenceSettings || {},
          usersToHideName: callInfo.usersToHideName || [],
          isConference: callInfo.isConference,
          confId: callInfo.confId || "",
          isConferenceCall: callInfo.isConferenceCall || undefined,
          conferenceHall: callInfo.conferenceHall || false,
          liveBroadcastId: callInfo.liveBroadcastId || undefined
        };
        if (
          wsCallStartBridgeCall(
            uuid,
            store.state.ownUUID,
            infoStartBridgeCall,
            numberOfPeople > 1
          )
        ) {
          store.addBridgeCallingUser(uuid, callInfo.callUUID);
          store.setCurrentContentVisile("", false, this.$router);
          this.doUpdateDocumentStore(callInfo.confId, uuid)

        }
      }
      this.closeModal();
    },
    doUpdateDocumentStore(confId, userUUID) {
      const foundStore = this.myDocuments.find((e) => e.confId === confId);
       const extant = this.myConferenceHallDetails.find(
        (e) => e.confId === confId
      );
      if (foundStore && foundStore.confId && extant) {
        let usersConference = extant.confUUIDS
         if(usersConference.indexOf(userUUID)==-1){
          usersConference.push(userUUID)
        }
        foundStore.docMembersUUIDS = usersConference;
        foundStore.moderators = extant.confModerators;
        updateCompanyDocumentEvent(foundStore);
      }
    },
    closeModal() {
      resetCallPersonConferenceHallModalEvent();
    },
  },
  computed: {
    getTotalUserInPodium() {
      return this.getPodiumList.length;
    },
    getPodiumList() {
      if (!this.bridgeInfoStore || !this.bridgeInfoStore.podium) return [];
      let callUUID = this.isListenerView
        ? this.state.user.inBridgeCallListener
        : this.state.user.inBridgeCall;
      if (!callUUID) return [];
      let podiumList = [];
         for (const key in this.bridgeInfoStore.podium) {
        if (Object.hasOwnProperty.call(this.bridgeInfoStore.podium, key)) {
          const element = this.bridgeInfoStore.podium[key];
          if (element == callUUID) {
            podiumList.push(key);
          }
        }
      }
      return podiumList || [];
    },
    totalParticipantPodium() {
      const remoteBridgeStreamsKey = Object.keys(
        store.state.remoteBridgeStreams
      );
      let callInfo = store.state.remoteBridgeStreams[remoteBridgeStreamsKey[0]];
      return callInfo.conferenceAttendees.filter((e)=>e.isPodium).map((e) => e.uuid).length;
    },
    cols() {
      const { lg, sm, md, xs, xl } = this.$vuetify.breakpoint;
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : xl ? [4, 8] : [12, 12];
    },
  },
};
</script>

<style scoped lang="scss">
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  color: #02889d;
}
.pointer {
  cursor: pointer;
}
.thumbs-down {
  color: red;
}
.thumbs-up {
  color: green;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.borderRadius10{
  border-radius: 10px !important;
}
</style>