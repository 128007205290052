var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto elevation-0",attrs:{"flat":"","max-width":"100%","outlined":""}},[_c('v-divider',{staticClass:"mt-1"}),_vm._v(" "),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-avatar',_vm._g({staticClass:"borderRadius5 mx-2 my-2 grayScale",attrs:{"tile":"","size":"80"}},on),[_c('v-img',{staticClass:"mx-2 borderRadius5 cursorPointer",attrs:{"src":"img/default_profile_picture.png","max-height":"80","max-width":"80","contain":"","lazy-src":"assets/default_profile_picture.png"},on:{"error":_vm.onImgError}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.visitor.name))])]),_vm._v(" "),_c('v-list-item-content',{staticClass:"contentWrapper"},[_c('div',{staticClass:"notranslate"},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.visitor.email))])]),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t("components.inviteVisitor.appointmentshort"))+":\n        "+_vm._s(_vm.visitor.linkDateTo)+"\n        "+_vm._s(_vm.visitor.linkTimeTo))]),_vm._v(" "),(_vm.visitor.visitorData.subject)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-subtitle',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_vm._v(_vm._s(_vm.$t("components.inviteVisitor.subject"))+":\n            "+_vm._s(_vm.visitor.visitorData.subject.substring(
                0,
                Math.min(_vm.visitor.visitorData.subject.length, 20)
              ))+"...")])]}}],null,false,2340997686)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.visitor.visitorData.subject))])]):_vm._e(),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-list-item-subtitle',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_vm._v(_vm._s(_vm.$t("components.inviteVisitor.note"))+":\n            "+_vm._s(_vm.visitor.visitorData.internalnote.substring(
                0,
                Math.min(_vm.visitor.visitorData.internalnote.length, 14)
              ))+"...")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.visitor.visitorData.internalnote))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }