<template>
  <div ref="callContainer" :class="{
    'd-none': hideVideoContainer,
    maximizeScreen: maximizeScreen,
    maximizeScreenMobile: isMobile
  }">
    <div :class="localFullScreen ? 'containerWrapperFullScreen' : 'containerWrapper'">
      <SidebarCall
        v-if="hasPrivilege(state.ownUUID)"
        :maximizeScreen="true"
        :isConferenceCall="true"
        :getAmIModerator="false"
      />
      <div v-if="chatOpen || userListOpen" class="containerChat">
        <JitsiChat
          v-if="chatOpen"
          :toggleChat="toggleChat"
          :chatMessages="chatMessages"
          :chatOpen="chatOpen"
          :chatMessageNotificationSound="chatMessageNotificationSound"
          :toggleChatMessageNotificationSound="toggleChatMessageNotificationSound"
        />
        <UserInCallList v-if="userListOpen" :userListOpen="userListOpen" :toggleUserList="toggleUserList" :isListenerView="true"/>
      </div>
      <div :class="(chatOpen || userListOpen) ? 'containerIframe' : 'containerIframeFull'">
        <div id="iframe" ref="iframe" class="iframe" :class="{'preventClick': !isMobile}">
          <CallIframe
            v-if="getYoutubeVideoId"
            :setYoutubePlayer="setYoutubePlayer"
            :youTubeVideoId="getYoutubeVideoId"
          />
          <RecorderIframe
            v-else
            :setVideoClappr="setVideoClappr"
          />
        </div>
        <div class="listener">
          <ListenerView :chatOpen="chatOpen" :getAmIModerator="false" :isListenerView="true"/>
        </div>
      </div>
    </div>
    <div :class="localFullScreen ? 'containerLocalButtonsFullScreen' : 'containerLocalButtons'">
      <CallListenerLocalButtons
        :toggleRequestToSpeakConferenceHall="toggleRequestToSpeakConferenceHall"
        :requestedRaiseHand="requestedRaiseHand"
        :fullScreenActive="fullScreenActive"
        :toggleLocalFullScreen="toggleLocalFullScreen"
        :toggleOpenChat="toggleChat"
        :toggleVideoMute="toggleVideoMute"
        :toggleVideoClapprPlayerMute="toggleVideoClapprPlayerMute"
        :getTotalReceivedChatMessages="getTotalReceivedChatMessages"
        :chatOpen="chatOpen"
        :chatMessageNotificationSound="chatMessageNotificationSound"
        :toggleUserList="toggleUserList"
        :videoReady="!isPkg ? videoReady : true"
      />
    </div>
  </div>
</template>
<script>
import store, { syncedUserState } from "../../../store";
import { webLicensedBaseFeatures } from "../../../../sharedsrc/licensedFeatures";
import CallListenerLocalButtons from "./callListenerLocalButtons.vue";
import CallIframe from "./callIframe.vue";
import RecorderIframe from "./recorderIframe.vue"
import CallListenerChat from "./callListernerChat.vue";
import JitsiChat from "./jitsiChat.vue";
import UserInCallList from "./userInCallList.vue"
import { setCallChatEvent, resetCallChatEvent, callChatStore } from "../../../effector/callChat";
import {resetListenerRaisedHandEvent} from "../../../effector/listenerRaisedHand"
import { bridgeCallGetAllChatMessages, bridgeCallRaiseHand } from "../../../lib/wsMsg";
import ListenerView from "./listenerView.vue";
import SidebarCall from "./sidebarCall/sidebarCall.vue";
import {
  hasPrivilege,
} from "../../../utils/privileges";
import { isMobile } from "../../../lib/mobileUtil";
import { myConferenceHallDetails } from "../../../effector/conferenceHalls";
import { bridgeInfoStore } from "../../../effector/users/bridgeInfo";
import { isConferenceCall } from "../../../utils/calls";
import debounce from "lodash.debounce";

export default {
  components: { CallListenerLocalButtons, CallIframe, RecorderIframe,  CallListenerChat, ListenerView, SidebarCall, JitsiChat, UserInCallList },
  effector: {
    chatMessages: callChatStore,
    myConferenceHallDetails: myConferenceHallDetails,
    bridgeInfoStore: bridgeInfoStore
  },
  props: ["callUUID"],
  data() {
    return {
      state: store.state,
      isMobile: isMobile(),
      chatOpen: false,
      fullScreenActive: false,
      localFullScreen: false,
      chatMessageNotificationSound: true,
      requestToSpeakConferenceHall: {},
      youtubePlayer: null,
      videoClapprPlayer: null,
      maximizeScreen: null,
      userListOpen: false,
      requestedRaiseHand: false,
      videoReady: false
    };
  },
  destroyed(){
    resetCallChatEvent();
    resetListenerRaisedHandEvent();
    this.resetAudioCallOnSettings();
  },
  watch: {
    "state.currentContentVisile.showComponent": {
      handler: debounce(function (value) {
        if ((value !== undefined && value) && store.state.user.inBridgeCallListener) {
          if(!store.state.canAutoplayWithSound){
            this.youtubePlayer.mute();
          }
        }
      }, 250),
    },
  },
  mounted(){
    if (!this.isMobile) {
      this.setMaximizeScreen(true);
    }
    const getAllChatMessages = async () => {
      await syncedUserState();
      const messages = await bridgeCallGetAllChatMessages();
      setCallChatEvent(messages)
    };
    getAllChatMessages()
    store.setCurrentContentVisile("", false, this.$router)
  },
  methods: {
    resetAudioCallOnSettings() {
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.audioCallOn = true;
      store.setUserSettings(temporalUserSettings);
    },
    setMaximizeScreen(data) {
      this.maximizeScreen = data;
    },
    hasPrivilege(userUUID) {
      return hasPrivilege(userUUID);
    },
    setVideoClappr(value) {
      this.videoClapprPlayer = value;
      this.videoReady = true;
    },
    async toggleVideoClapprPlayerMute() {
      if (!this.videoClapprPlayer) return null;
      if (this.videoClapprPlayer.getVolume() > 0) {
        this.videoClapprPlayer.setVolume(0);
        store.setCanAutoplayWithSound(false);
        return true;
      } else {
        this.videoClapprPlayer.setVolume(100);
        store.setCanAutoplayWithSound(true);
        return false;
      }
    },
    setYoutubePlayer(value) {
      this.youtubePlayer = value;
      this.videoReady = true;
    },
    async toggleVideoMute() {
      if (!this.youtubePlayer) return null;
      const isPlayerMuted = await this.youtubePlayer.isMuted();
      if (isPlayerMuted) {
        this.youtubePlayer.unMute();
        store.setCanAutoplayWithSound(true);
        return false;
      } else {
        this.youtubePlayer.mute();
        store.setCanAutoplayWithSound(false);
        return true;
      }
    },
    async toggleRequestToSpeakConferenceHall() {
      this.requestedRaiseHand = !this.requestedRaiseHand
      await bridgeCallRaiseHand(this.requestedRaiseHand);
    },
    toggleChatMessageNotificationSound() {
      this.chatMessageNotificationSound = !this.chatMessageNotificationSound;
    },
    isFullScreen() {
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      );
    },
    toggleLocalFullScreen() {
      const element = this.$refs.iframe;
      if (!this.isFullScreen()) {
        this.enterFullScreen(element);
        this.localFullScreen = true;
      } else {
        this.exitFullScreen();
        this.localFullScreen = false;
      }
    },
    exitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen().catch(() => {});
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.fullScreenActive = false;
    },
    enterFullScreen(element) {
      if (element.requestFullscreen) {
        try {
          element.requestFullscreen();
        } catch (error) {
          console.error("request fullscreen", error);
        }
      } else if (element.webkitRequestFullscreen) {
        /* Safari */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        /* IE11 */
        element.msRequestFullscreen();
      }
      this.fullScreenActive = true;
    },
    toggleChat() {
      if(this.userListOpen){
        this.toggleUserList()
      }
      this.chatOpen = !this.chatOpen;
    },
    toggleUserList(){
      if(this.chatOpen){
        this.toggleChat()
      }
      this.userListOpen = !this.userListOpen
    }
  },
  computed: {
    isPkg() {
      return webLicensedBaseFeatures.isPkg;
    },
    getYoutubeVideoId() {
      const remoteBridgeStreamsKey = Object.keys(this.state.remoteBridgeStreams);
      let callInfo = this.state.remoteBridgeStreams[remoteBridgeStreamsKey[0]];
      return (callInfo || {}).liveBroadcastId;
    },
    isConferenceCall() {
      const callUUID = this.callUUID;
      return isConferenceCall(callUUID);
    },
    getTotalReceivedChatMessages() {
      if (!this.chatMessages) return 0;
      return this.chatMessages.filter(
        (message) => message.user !== this.state.ownUUID
      ).length;
    },
    hideVideoContainer() {
      return (
        this.state.currentContentVisile.showComponent ||
        !this.state.user.inBridgeCallListener
      );
    },
  },
};
</script>
<style scoped lang="scss">
.containerLocalButtons {
  height: 7vh;
}
.containerLocalButtonsFullScreen {
  height: 5vh;
}
.containerWrapperFullScreen {
  display: flex;
  height: 95vh;
}
.containerWrapper {
  display: flex;
  height: 94vh;
}
.containerChat {
  width: 20%;
}
.containerIframe {
  width: 80%;
}
.containerIframeFull {
  width: 100%;
}
.iframe{
  background-color: black;
  width: 100%;
  height: 80%;
  // height: 80%;
  min-height: 80%;
}
.preventClick {
  pointer-events: none;
}
.listener{
  width: 100%;
  height: 20%;
}
.maximizeScreen {
  background-color: black;
  position: absolute;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  z-index: 10;
}
.maximizeScreenMobile{
  position: absolute;
  width: 100vw;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 10;
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  /* Styles */
  }
}
@media (max-width: 1500px) {
  .containerChat{
    width: 25%;
  }
  .containerIframe {
    width: 75%;
  }
}
@media (max-width: 1100px) {
  .containerChat{
    width: 35%;
  }
  .containerIframe {
    width: 65%;
  }
}
@media (max-width: 800px) {
  .containerChat, .containerIframe {
    width: 50%;
  }
}
@media (max-width: 600px) {
  .containerChat {
    width: 100%;
  }
  .containerIframe {
    width: 0%;
  }
}
</style>