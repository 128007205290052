import store from '../store';
import { aDelay } from '../lib/asyncUtil';
// import { rtcMsg, sendNotificationToUUID } from './wsMsg';
// import { getOwnUUID } from './wsConnect';
// import Vue from 'vue';
import { addStreamToAllConnections, removeStreamToAllConnections, waitForRtcStable, ensureUserStream, getWebrtcConnections } from './rtcConn';
import { callSetPresentationViewEvent } from '../effector/call';

// const allStreams = [];
var currentUserMedia;
let currentDisplayMedia;

let tryWebcamBusy = false;
export async function tryWebcam (isMutedAudio= undefined) {
  try {
    // console.log('tryWebcam', tryWebcamBusy, currentUserMedia);
    if (tryWebcamBusy) return;
    tryWebcamBusy = true;
    await waitForRtcStable();
    store.closeUserMedia();
    store.toggleRtcVideo();
    // await aDelay(500);
    await waitForRtcStable();
    await getUserMedia()
    .then((localStream) => {
      // console.log('tryWebcam localStream:', localStream);
      if (!localStream) return;
      // addStreamToAllConnections(localStream);
        ensureUserStream();
        localStream.oninactive = () => {
          closeUserMedia();
        };
      }).catch(err => console.warn('Failed to getUserMedia:', err.message));
    // await aDelay(500);
    await waitForRtcStable();
  } catch (err) {
    // console.log('tryWebcam Err:', err.message);
  } finally {
    await aDelay(1000);
    tryWebcamBusy = false;
    if(isMutedAudio){
      toggleMuteLocalAudio()
    }
  }
}

let tryScreenShareBusy = false;
export async function tryScreenShare () {
  try {
    if (tryScreenShareBusy) return;
    tryScreenShareBusy = true;
    await waitForRtcStable();
    await getDisplayMedia()
      .then((localStream) => {
        // console.log('tryScreenShare localStream:', localStream);
        if (!localStream) return;
        addStreamToAllConnections(localStream);
        // setPresentationViewForScreenShare()
        
        localStream.oninactive = () => {
          closeDisplayMedia();
        };
      }).catch(err => console.warn('Failed to getDisplayMedia:', err.message));
    await waitForRtcStable();
  } catch (err) {
    // console.log('tryScreenShare Err:', err.message);
  } finally {
    await aDelay(1000);
    tryScreenShareBusy = false;
  }
}

const UserMediaQualities = {
  high: {
    width: { min: 320, ideal: 1280, max: 1280 },
    height: { min: 240, ideal: 720, max: 720 },
    frameRate: { min: 5, ideal: 25, max: 30 },
  },
  medium: {
    width: { min: 320, ideal: 480, max: 1280 },
    height: { min: 240, ideal: 360, max: 720 },
    frameRate: { min: 5, ideal: 20, max: 20 },
  },
  low: {
    width: { min: 320, ideal: 320, max: 1280 },
    height: { min: 240, ideal: 240, max: 720 },
    frameRate: { min: 5, ideal: 15, max: 15 },
  },
}

// export async function getUserMedia () {
//   try {
//     if (!currentUserMedia) {
//       const videoQualitySetting = store.getRtcVideoQuality() || 'medium';
//       const videoSetting = store.getRtcVideo();
//       const audioSetting = store.getRtcAudio();
//       const settings = {
//         video: videoSetting !== false ? {
//           // TODO: firefox options
//           // optional: {
//           //   // maxWidth: 1280,
//           //   // maxHeight: 720,
//           //   maxWidth: 480,
//           //   maxHeight: 360,
//           //   minWidth: 256,
//           //   minHeight: 144,
//           //   maxFrameRate: 20,
//           // },
//           facingMode: 'user',
//           ...UserMediaQualities[videoQualitySetting],
//           ...videoSetting, // TODO: FIXME
//         } : false,
//         audio: audioSetting,
//       };
//       console.log('getUserMedia Settings:', settings, videoSetting);
//       currentUserMedia = await navigator.mediaDevices.getUserMedia(settings);
//       store.state.localStreams.user = currentUserMedia;
//     }
//     return currentUserMedia;
//   } catch (err) {
//     console.log('getUserMedia Err:', err);
//     return undefined;
//   }
// }

function getVideoConstraints() {
  const videoSetting = store.getRtcVideo();
  const videoQualitySetting = store.getRtcVideoQuality() || 'medium';
  // const videoFacing = store.getRtcVideoFacing() || 'user';

  return videoSetting !== false ? {
    // facingMode: 'user', // videoFacing,
    ...UserMediaQualities[videoQualitySetting],
    ...videoSetting,
  } : false;
}

export async function getUserMediaImpl () {
  try {
    if (!currentUserMedia) {
      const audioSetting = store.getRtcAudio();
      const settings = {
        video: getVideoConstraints(),
        audio: audioSetting,
      };
      // console.log('getUserMedia Settings:', settings);
      currentUserMedia = await navigator.mediaDevices.getUserMedia(settings);
      store.state.localStreams.user = currentUserMedia;
    }
    return currentUserMedia;
  } catch (err) {
    // console.log('getUserMedia Err:', err);
    return undefined;
  }
}

let getUserMediaPromise;
// Needed because of sudden cycleMedia calls at start of session
export async function getUserMedia() {
  // console.log('getUserMedia called');
  if (!getUserMediaPromise) {
    // console.log('getUserMedia new');
    getUserMediaPromise = getUserMediaImpl();
    getUserMediaPromise.finally(() => {
      // console.log('getUserMedia finally');
      getUserMediaPromise = null;
    });
  }
  // else console.log('getUserMedia extant');
  return getUserMediaPromise;
}

export async function getDisplayMedia () {
  try {
    if (!currentDisplayMedia) {
      currentDisplayMedia = await navigator.mediaDevices.getDisplayMedia({ video: {
        width: { ideal: 1920, max: 1920 },
        height: { ideal: 1080, max: 1080 },
        frameRate: { ideal: 20, max: 30 },
      }, audio: true });
      store.state.localStreams.display = currentDisplayMedia;
    }
    return currentDisplayMedia;
  } catch (err) {
    // console.log('getDisplayMedia Err:', err.message);
    removePresentationViewForSchreenShare();
    return undefined;
  }
}

export function toggleMuteLocalAudio () {
  if (!currentUserMedia) return;
  currentUserMedia.getTracks().forEach(function (track) {
    if (track.kind === 'audio') track.enabled = !track.enabled;
    // console.log('derp', track);
  });
  store.state.localStreams.lastMicChange = Date.now();
}

export function setMuteLocalAudio (bool) {
  if (!currentUserMedia) return;
  const shouldBeEnabled = !bool;
  // currentUserMedia.getTracks().forEach(function (track) {
  //   if (track.kind === 'audio') track.enabled = !bool;
  // });

  const tracks = currentUserMedia.getTracks();
  const track = tracks.find(t => t.kind === 'audio');
  if (!track) return false;

  if (track.enabled !== shouldBeEnabled) {
    track.enabled = shouldBeEnabled;
    store.state.localStreams.lastMicChange = Date.now();
    return true;
  }
  return false;
}

export function closeUserMedia () {
  if (!currentUserMedia) return;
  removeStreamToAllConnections(currentUserMedia);
  currentUserMedia.getTracks().forEach(function (track) {
    track.stop();
  });
  store.state.localStreams.user = undefined;
  currentUserMedia = undefined;
}

export function closeDisplayMedia () {
  if (!currentDisplayMedia) return;
  removeStreamToAllConnections(currentDisplayMedia);
  currentDisplayMedia.getTracks().forEach(function (track) {
    track.stop();
  });
  store.state.localStreams.display = undefined;
  currentDisplayMedia = undefined;
  // removePresentationViewForSchreenShare();
}

// export function setPresentationViewForScreenShare(){
//   throw new Error('Deprecated');

//   // let personRemote = Object.keys(store.state.remoteStreams);
//   // for (const key in personRemote) {
//   //   let remote_uuid = personRemote[key];
//   //   if (store.state.rtc[remote_uuid].ringingState == "connected") {
//   //       setPresentationViewScreenShare(remote_uuid);
//   //   }
//   // }  
// }
export function removePresentationViewForSchreenShare(){
  if ( store.state.presentationView.fromScreenshare ){
    callSetPresentationViewEvent({ value: false, owner: undefined, fromScreenshare: true });
  }
  // throw new Error('Deprecated');

  // if ( store.state.presentationView.fromScreenshare ){
  //   store.setPresentationView(
  //     false,
  //     undefined,
  //     true
  //   );
  //   let personRemote = Object.keys(store.state.remoteStreams);
  //   for (const key in personRemote) {
  //     let remote_uuid = personRemote[key];
  //     if (store.state.rtc[remote_uuid].ringingState == "connected") {
  //         setPresentationViewScreenShare(remote_uuid);
  //     }
  //   }   
  // }
}

export async function applyAudioConstraints() {
  if (!currentUserMedia) return;
  try {
    const audioSetting = store.getRtcAudio();
    // console.log('applyAudioConstraints', audioSetting);
    currentUserMedia.getTracks().forEach(function (track) {
      if (track.kind === 'audio') {
        if (audioSetting) {
          track.enabled = true;
          track.applyConstraints(audioSetting);
        } else {
          track.enabled = false;
        }
      }
    });
  } catch (err) {
    console.warn('applyAudioConstraints', err);
  }
}

export async function applyVideoConstraints() {
  if (!currentUserMedia) return;
  try {
    const constraints = getVideoConstraints();
    // console.log('applyVideoConstraints', constraints);
    currentUserMedia.getTracks().forEach(function (track) {
      if (track.kind === 'video') {
        if (constraints) {
          track.enabled = true;
          track.applyConstraints(constraints);
        } else {
          track.enabled = false;
        }
      }
    });
  } catch (err) {
    console.warn('applyVideoConstraints', err);
  }
}

async function checkAudioDevice() {
  if (!currentUserMedia) return;
  const audioSetting = store.getRtcAudio();
  if (!audioSetting) return;
  const wantedDeviceId = audioSetting.deviceId;
  if (!wantedDeviceId) return;
  // console.log('checkAudioDevice wantedDeviceId', wantedDeviceId);

  // console.log('checkAudioDevice currentUserMedia', currentUserMedia);
  const audioTrack = await currentUserMedia.getAudioTracks()[0];
  // console.log('checkAudioDevice audioTrack', audioTrack);
  const currentDeviceLabel = audioTrack.label;

  // console.log('checkAudioDevice audioSetting', audioSetting);
  const devices = await navigator.mediaDevices.enumerateDevices();
  // console.log('checkAudioDevice devices', devices);

  const currentAudioDevice = devices.find(device => device.label === currentDeviceLabel);
  const desiredAudioDevice = devices.find(device => device.deviceId === wantedDeviceId);

  // console.log('checkAudioDevice currentAudioDevice', currentAudioDevice);
  // console.log('checkAudioDevice desiredAudioDevice', desiredAudioDevice);
}

export async function cycleUserMedia() {
  // return;
  // if (!currentUserMedia) return;
  try {
    if (store.state.ensureUserMediaLock) return;
    if (Object.values(getWebrtcConnections()).length === 0) return;
    // console.log('cycleUserMedia Called');
    store.state.ensureUserMediaLock = true;

    if (currentUserMedia) {
      currentUserMedia.oninactive = null;
      currentUserMedia.getTracks().forEach(function (track) {
        track.stop();
      });
      currentUserMedia = null;
    }

    const newThing = await getUserMedia();
    if (newThing) {
      for (const webrtc of Object.values(getWebrtcConnections())) {
        // console.log('cycleUserMedia webrtc', webrtc);
        for (const sender of Object.values(webrtc.senders)) {
          // console.log('cycleUserMedia sender', sender);
          if (sender.track && sender.track.kind === 'audio' && sender.track.readyState === 'ended') {
            sender.replaceTrack(newThing.getAudioTracks()[0]);
          } else if (sender.track && sender.track.kind === 'video' && sender.track.readyState === 'ended') {
            sender.replaceTrack(newThing.getVideoTracks()[0]);
          }
        }
      }
    }
  } catch (err) {
    console.warn('cycleUserMedia', err);
  }
  store.state.ensureUserMediaLock = false;
}

let constraintApplicationTimeout;

try {
  // navigator.mediaDevices.ondevicechange = function(event) {
  navigator.mediaDevices.addEventListener('devicechange', async function(event) {
    // console.log('onDeviceChange', event, store.state.persisted.reactToDeviceChanges);
    if (!store.state.persisted.reactToDeviceChanges) return;

    if (constraintApplicationTimeout) clearTimeout(constraintApplicationTimeout);
    constraintApplicationTimeout = setTimeout(async function() {
      constraintApplicationTimeout = null;
      // console.log('Applying constraints');
      // await applyAudioConstraints();
      // await applyVideoConstraints();
      // await checkAudioDevice();
      await cycleUserMedia();
    }, 1500);
    // const devices = await navigator.mediaDevices.enumerateDevices();
    // for (const device of devices) {
      // console.log('Enum', device);
    // }
  });
} catch (err) {
  console.warn('Caught mediaDevices onDeviceChange Register:', err);
}