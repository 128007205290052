<template >
  <v-dialog v-model="showModal" persistent max-width="560" @keydown.esc="closeModal">
    <v-card>
      <v-card-title class="headline">
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        {{$t('components.userProfile.sendAMessage')}}
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="messageForm" lazy-validation :autocomplete="false">
        <v-row :style="{width: '100%', margin: 0}" class="px-5">
          <v-textarea
            counter
            contenteditable="true"
            :label="$t('components.sendMessageModal.message')"
            :rules="rules"
            v-model="messageText"
            :no-resize="true"
            maxlength="250"
            autofocus
          ></v-textarea>
        </v-row>
        <v-row :style="{width: '100%', margin: 0}" class="px-2" >
          <v-checkbox
            :disabled="disabledDepartCheck"
            v-model="departmentsChecked"
            class="mencheckbox sel-check-item-first px-1 mx-auto"
            :label="$t('components.organisationCarousel.departmentManagers')"
            :value="departmentsChecked"
            @change="changeSendToDepartments"
          ></v-checkbox>
          <v-checkbox
            @change="changeSendToTeams"
            :disabled="disabledTeamCheck"
            v-model="teamsChecked"
            class="mencheckbox sel-check-item-first px-1 mx-auto"
            :label="$t('components.organisationCarousel.teamManagers')"
            :value="teamsChecked"
          ></v-checkbox>
          <v-checkbox
            @change="changeSendToMembers"
            :disabled="disabledMemberCheck"
            v-model="membersChecked"
            class="mencheckbox sel-check-item-first px-1 mx-auto"
            :label="$t('components.organisationCarousel.members')"
            :value="membersChecked"
          ></v-checkbox>
        </v-row>
        <!-- <v-row :style="{width: '100%', margin: 0}" class="px-5">
          <p
            style="font-size: 14px;margin: 0 auto;font-weight: 400;"
          >* {{ $t('components.sendMessageModal.messageSpaceHint')}}</p>
        </v-row> -->
        <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
          <v-row class="px-2 btns" flex :style="{width: '100%', margin: 0}">
            <v-col lg="6" class="px-0 py-0" align="left">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    color="white"
                    class="btnRed mr-2"
                    @click="seeUserMessages()"
                  >
                    <font-awesome-icon :icon="['fal', 'eye']" :style="{ fontSize: '20px' }" />
                  </v-btn>
                </template>
                <span>{{ $t('components.sendMessageModal.seeYourMessages') }}</span>
              </v-tooltip>
            </v-col>
            <v-col lg="6" class="px-0 py-0" align="right">
              <v-btn color="primary" @click="closeModal();">{{ $t('generics.cancel') }}</v-btn>
              <v-btn
                color="primary"
                @click="sendMessage()"
                :disabled="disabledSendButton"
              >{{ $t('components.sendMessageModal.send') }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { sendOfflineMsg, sendMsg } from "../../lib/wsMsg.js";
export default {
  props: ["showModal", "closeModal", "sectionData"],
  components: {},
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
    departSuperVisorsList: [],
    teamSuperVisorsList: [],
    memberList: [],
    finalUsersList: [],
    messageText: "",
    departmentsChecked: false,
    teamsChecked: false,
    membersChecked: false,
    setCurrentContentVisile: store.setCurrentContentVisile,
    rules: [v => !!v || "", v => !!v.trim() || "Message can not be blank"],
  }),
  methods: {
    changeSendToDepartments() {
      if (this.departmentsChecked === true) {
        //   add departs managers
        const departs = this.state.namespaceSettings.processedOrganisation
          .departments;
        for (const key in departs) {
          if (departs.hasOwnProperty(key)) {
            const depart = departs[key];
            if (depart.section === this.sectionData.name) {
              for (let i = 0; i < depart.supervisors.length; i++) {
                const supervisor = depart.supervisors[i];
                if (supervisor.uuid !== this.ownUUID) {
                  this.departSuperVisorsList.push(supervisor.uuid);
                }
              }
            }
          }
        }
      } else {
        //   remove departs managers
        this.departSuperVisorsList = [];
      }
    },
    changeSendToTeams() {
      if (this.teamsChecked === true) {
        if (this.sectionData.type === "sections") {
          const departments = this.state.namespaceSettings.processedOrganisation
            .sections[this.sectionData.name].departments;
          const teams = this.state.namespaceSettings.processedOrganisation
            .teams;
          for (let i = 0; i < departments.length; i++) {
            const depart = departments[i];
            for (const key in teams) {
              if (teams.hasOwnProperty(key)) {
                const team = teams[key];
                if (team.department === depart) {
                  for (let j = 0; j < team.supervisors.length; j++) {
                    const supervisor = team.supervisors[j];
                    if (supervisor.uuid !== this.ownUUID) {
                      this.teamSuperVisorsList.push(supervisor.uuid);
                    }
                  }
                }
              }
            }
          }
        } else if (this.sectionData.type === "departments") {
          //   add teams managers
          const teams = this.state.namespaceSettings.processedOrganisation
            .teams;
          for (const key in teams) {
            if (teams.hasOwnProperty(key)) {
              const team = teams[key];
              if (team.department === this.sectionData.name) {
                for (let i = 0; i < team.supervisors.length; i++) {
                  const supervisor = team.supervisors[i];
                  if (supervisor.uuid !== this.ownUUID) {
                    this.teamSuperVisorsList.push(supervisor.uuid);
                  }
                }
              }
            }
          }
        }
      } else {
        //   remove teams managers
        this.teamSuperVisorsList = [];
      }
    },
    changeSendToMembers() {
      if (this.membersChecked === true) {
        for (const key in this.userList) {
          if (this.userList.hasOwnProperty(key)) {
            const user = this.userList[key];
            if (key !== this.ownUUID) {
              if (this.sectionData.type === "sections") {
                if (
                  user.teamSections &&
                  user.teamSections.indexOf(this.sectionData.name) !== -1
                ) {
                  this.memberList.push(key);
                }
              } else if (this.sectionData.type === "departments") {
                if (
                  user.teamDepartments &&
                  user.teamDepartments.indexOf(this.sectionData.name) !== -1
                ) {
                  this.memberList.push(key);
                }
              } else if (this.sectionData.type === "teams") {
                if (
                  user.memberOfTeams &&
                  user.memberOfTeams.indexOf(this.sectionData.name) !== -1
                ) {
                  this.memberList.push(key);
                }
              }
            }
          }
        }
      } else {
        //   remove members list
        this.memberList = [];
      }
    },
    sendMessage() {
      // loop to send the message to each uuids
        this.finalUsersList = this.departSuperVisorsList
        .concat(this.teamSuperVisorsList)
        .concat(this.memberList);
  
      for (let i = 0; i < this.finalUsersList.length; i++) {
        const userId = this.finalUsersList[i];
        const header = {
          sendTo: {
            name: userId,
            category: {}
          }
        };
        if (this.userIsConnected(userId)) {
          this.sendMsg(userId, this.messageText, header);
        } else {
          this.sendOfflineMsg(userId, this.messageText, header);
        }
      }
      this.closeModal();
    },
    seeUserMessages(ownUUID) {
      // store.state.user.subordinateActiveUser = ownUUID;
      this.state.showModalSenMsg = undefined;
      this.setCurrentContentVisile("messages", false, this.$router);
    },
    userIsConnected(uuid) {
      const users = this.state.group || {};
      if (users[uuid].connected) {
        return true;
      } else {
        return false;
      }
    },
    sendOfflineMsg(...arg) {
      return sendOfflineMsg(...arg);
    },
    sendMsg(...arg) {
      return sendMsg(...arg);
    }
  },
  computed: {
    disabledSendButton() {
      if (
        (this.departmentsChecked || this.teamsChecked || this.membersChecked )  &&
        this.messageText !== ""
      ) {
        return false;
      }
      return true;
    },
    userList() {
      return store.getProcessedUserList();
    },
    disabledDepartCheck() {
      if (this.sectionData.type === "sections") {
        return false;
      } else {
        return true;
      }
    },
    disabledTeamCheck() {
      if (
        this.sectionData.type === "sections" ||
        this.sectionData.type === "departments"
      ) {
        return false;
      } else {
        return true;
      }
    },
    disabledMemberCheck() {
      if (
        this.sectionData.type === "sections" ||
        this.sectionData.type === "departments" ||
        this.sectionData.type === "teams"
      ) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
</style>