<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="650"
    @keydown.esc="closeModal"
    :attach="getAttach"
    :hide-overlay="getAttach ? true : false"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.conferenceForm.titleInviteVisitor')"
        :closeModalFunction="closeModal"
      />
        <v-col cols="12">
            <v-col cols="12" v-if="existingGuests.length>0" class="px-6">
              <v-combobox
                v-model="selectedGuest"
                :items="existingGuests"
                :search-input.sync="searchGuest"
                select
                item-text="name"
                item-value="uuid"
                :label="$t('components.conferenceForm.vOfficeUsers')"
                dense
                outlined
                class="mt-3"
                @change="callUser"
                hide-details
                autofocus
                :item-disabled="userIsInCall"
              >
                <template v-slot:item="data">
                  <div>
                    <span
                      class="statusSpan"
                      :style="{ background: data.item.statusColor }"
                    ></span>
                    <span
                      :style="{ marginLeft: '25px' }"
                      v-text="data.item.name"
                    ></span>
                  </div>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12">
              <ConferencesDirectCallMask
                ref="directCallMaskRef"
                :setNoticeText="setNoticeText"
              />
            </v-col>
        </v-col>
      <FooterModal :closeModalFunction="closeModal">
        <v-tooltip top v-if="directCallMaskRef">
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-2"
              color="primary"
              v-on="on"
              @click="addVisitor"
              :disabled="
                !directCallMaskRef.validData || directCallMaskRef.isProcessing
              "
              >{{ $t("generics.add") }}</v-btn
            >
          </template>
          <span>{{ $t("generics.add") }}</span>
        </v-tooltip>
      </FooterModal>
    </v-card>
    <v-spacer></v-spacer>
  </v-dialog>
</template>
 
<script>
import store, { EventBus } from "../../../../store";
import HeaderModal from "../../../modal/modalComponents/headerModal.vue";
import FooterModal from "../../../modal/modalComponents/footerModal.vue";
import ConferencesDirectCallMask from "../../../timeline/conferencesDirectCallMask.vue";
import { getStatusColorByUser } from "../../../../utils/status";
import { callPerson, personIsInBridgeCall } from "../../../../utils/calls";

export default {
  props: [
    "isConferenceCall",
    "amIModerator",
    "showModal",
    "closeModal",
    "dataConference",
    "attachTo",
  ],
  components: { HeaderModal, FooterModal, ConferencesDirectCallMask },
  data() {
    return {
      state: store.state,
      selectedGuest: null,
      searchGuest: "",
      directCallMaskRef: null,
      noticeText: null,
    };
  },
  watch: {},
  effector: {},
  mounted() {
    EventBus.$on("setReference", this.setReference);
    this.directCallMaskRef = this.$refs.directCallMaskRef;
  },
  destroyed() {
    EventBus.$off("setReference", this.setReference);
  },
  methods: {
    setReference() {
      this.directCallMaskRef = this.$refs.directCallMaskRef;
    },
    userIsInCall(item) {
      return personIsInBridgeCall(item.uuid);
    },
     userIsInCallByUuid(uuid) {
      return personIsInBridgeCall(uuid);
    },
    getStatusColorByUser(uuid) {
      if (store.state.group[uuid] && store.state.group[uuid].user) {
        const isConnected = store.state.group[uuid].connected;
        return getStatusColorByUser(store.state.group[uuid].user, isConnected);
      }
    },
    callUser() {
      if (this.selectedGuest && this.selectedGuest.uuid) {
        // this.addUserToConference(this.selectedGuest.uuid);
        callPerson(this.selectedGuest.uuid, this.$router);
        this.closeModal();
      }
    },
    setNoticeText(value) {
      this.noticeText = value;
    },
    async addVisitor() {
      if (!this.directCallMaskRef) throw new Error("Reference not found");
      await this.directCallMaskRef.addUserToList(false);
      this.closeModal();
    },
  },
  computed: {
    existingGuests() {
      let guests = [];
      guests = Object.keys(store.state.persisted.userVisitors)
        .filter(
          (uuid) =>
            !!store.state.persisted.userVisitors[uuid] &&
            store.state.group[uuid] &&
            store.state.group[uuid].connected
        )
        .map((uuid) => ({
          name: store.state.group[uuid].user.name,
          uuid: uuid,
          statusColor: this.getStatusColorByUser(uuid),
        }));
      return guests;
    },
    getAttach() {
      if (this.attachTo) {
        const element = document.getElementById(this.attachTo);
        if (element) {
          return element;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.statusSpan {
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 50%;
  top: 14px;
}
</style>