<template>
  <div class="chatContainer">
    <div
      class="d-flex justify-space-between align-baseline w100 pt-2 pl-4 pr-3"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <div class="title white--text" v-on="on">
            {{ $t("components.callsContent.groupChat") }}
          </div>
        </template>
        <span>{{ $t("components.callsContent.groupChatTT") }}</span>
      </v-tooltip>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn @click="toggleChatMessageNotificationSound()" v-on="on" icon>
              <font-awesome-icon
                :icon="['fal', 'volume-up']"
                :style="{ fontSize: '20px' }"
                color="white"
                v-if="chatMessageNotificationSound"
              />
                <font-awesome-icon
                :icon="['fal', 'volume-slash']"
                :style="{ fontSize: '20px' }"
                color="white"
                v-else
              />
            </v-btn>
          </template>
          <span>{{ chatMessageNotificationSound ? $t("components.callsContent.muteChatNotifications") : $t("components.callsContent.unmuteChatNotifications") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn @click="toggleOpenChat()" v-on="on" icon>
              <font-awesome-icon
                :icon="['fal', 'times']"
                :style="{ fontSize: '20px' }"
                color="white"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.callsContent.closeGroupChat") }}</span>
        </v-tooltip>
      </div>
    </div>

    <v-col cols="12" class="flex-grow-1 flex-shrink-0 containerMessages">
      <v-card flat class="d-flex flex-column fill-height backgroundCard">
        <v-card-text
          class="pl-1 pr-3 flex-grow-1 overflow-y-auto chatMessageContainer"
          ref="chatMessageContainer"
        >
          <template v-for="(message, index) in chatMessages">
            <div
              :key="index"
              :class="{
                'd-flex flex-row-reverse': message.creatorUUID === ownUUID,
              }"
            >
              <v-card
                :color="message.creatorUUID === ownUUID ? '#484a4f' : '#242528'"
                class="px-2 pt-3 pb-0 mb-2 white--text cardMessage"
                label
              >
                <v-row
                  v-if="
                    message.creatorUUID !== ownUUID &&
                    (index == 0 ||
                      (index > 0 &&
                        chatMessages[index - 1].creatorUUID !==
                          message.creatorUUID))
                  "
                >
                  <v-col
                    cols="12"
                    class="
                      pt-0
                      pb-2
                      text-left
                      align-self-start
                      font-weight-bold
                      text-truncate
                    "
                  >
                    {{ getTitelForUuid(message.creatorUUID) }}
                    {{ getNameForUuid(message.creatorUUID) }}
                  </v-col>
                </v-row>
                <div
                  style="white-space: pre-line"
                  :class="
                    isOnlyOneEmoji(message.messageText)
                      ? 'messageContentEmoji'
                      : null
                  "
                >
                  <div
                    class="messageText"
                    v-html="checkMessage(message.messageText)"
                  ></div>
                </div>
                <v-row>
                  <v-col cols="12" class="pb-0 pt-1 text-right align-self-end">
                    <span class="caption">{{
                      dateFormatted(message.date)
                    }}</span>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="containerInputMessage" id="containerInputMessage ">
      <v-textarea
        v-model="textToSend"
        rows="1"
        counter="250"
        auto-grow
        dark
        @keyup.enter="textToSend.trim().length > 0 ? sendMessage() : null"
        autofocus
        outlined
        single-line
        maxlength="250"
        autocomplete="off"
        dense
        :label="$t('components.notificationsModal.typeAMessage')"
        class="inputChatText"
      >
        <template slot="append">
          <v-tooltip :disabled="textToSend.trim().length === 0" top>
            <template v-slot:activator="{ on }">
              <v-btn
                :class="
                  textToSend.trim().length === 0
                    ? 'sendColorBtnDisabled'
                    : 'sendColorBtn'
                "
                x-small
                v-on="on"
                @click="sendMessage()"
                :disabled="textToSend.trim().length === 0"
                :height="30"
              >
                <font-awesome-icon
                  :icon="['fal', 'paper-plane']"
                  :style="{ fontSize: '18px' }"
                  color="primary"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.groups.sendMessage") }}</span>
          </v-tooltip>
        </template>
        <template slot="prepend-inner">
          <EmojiPicker :selectEmoji="selectEmoji" />
        </template>
      </v-textarea>
    </v-col>
  </div>
</template>
<script>
import store from "../../../store";
import { v4 as uuidv4 } from "uuid";
import moment from "../../../../sharedsrc/moment";
import { checkForUrls, checkForEmojis } from "../../../utils/basicFunctions";
import { isMobile } from "../../../lib/mobileUtil";
import EmojiPicker from "../emojiPicker.vue";

export default {
  components: { EmojiPicker },
  props: ["room", "chatMessages", "showTimeLine", "chatOpen", "toggleChat", "chatMessageNotificationSound","toggleChatMessageNotificationSound",
  "toggleOpenChat"],
  data() {
    return {
      state: store.state,
      isMobile: isMobile(),
      ownUUID: store.state.ownUUID,
      textToSend: "",
      moment: moment,
    };
  },
  watch: {
    chatMessages: {
      handler: function (received) {
        if (received && this.chatOpen) {
          const percentage =
            (this.$refs["chatMessageContainer"].scrollTop /
              (this.$refs["chatMessageContainer"].scrollHeight -
                this.$refs["chatMessageContainer"].clientHeight)) *
            100;
          if (percentage > 70) {
            setTimeout(() => {
              this.scrollToBottom();
            }, 300);
          }
        }
      },
    },
    chatOpen: {
      handler: function (isOpen) {
        if (isOpen) {
          this.scrollToBottom();
        }
      },
    },
    outsideCallView: {
      handler: function (outside) {
        if (outside && this.chatOpen) {
          this.toggleChat();
        }
      },
    },
  },
  mounted() {},
  methods: {
    isOnlyOneEmoji(message) {
      if (!message) return false;
      const regexExp =
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
      let outPutMessage = message;
      if (
        outPutMessage.match(regexExp) &&
        outPutMessage.match(regexExp).length == 1 &&
        outPutMessage.trim().length == 2
      ) {
        return true;
      }
      return false;
    },
    selectEmoji(emoji) {
      if (this.textToSend.length < 250) {
        this.textToSend += emoji;
      }
    },
    scrollToBottom() {
      // scroll to bottom
      this.$refs["chatMessageContainer"].scrollTop =
        this.$refs["chatMessageContainer"].scrollHeight;
    },
    checkForUrls(message) {
      return checkForUrls(message, false);
    },
    checkForEmojis(message) {
      return checkForEmojis(message);
    },
    checkMessage(message) {
      const finalMessageWithUrl = this.checkForUrls(message);
      const finalMessageWithEmoji = this.checkForEmojis(finalMessageWithUrl);
      return this.$sanitize(finalMessageWithEmoji);
    },
    sendMessage() {
      let dataToSend = [];
      const data = {
        messageUuid: uuidv4(),
        messageText: this.$sanitize(this.textToSend),
        participantId: this.room.myUserId(),
        creatorUUID: this.state.ownUUID,
        date: new Date().getTime(),
      };
      dataToSend.push(data);
      this.room.sendTextMessage(
        JSON.stringify({
          cmd: "cmdChatMessage",
          value: dataToSend,
        })
      );
      this.textToSend = "";
    },
    dateFormatted(date) {
      return this.moment(new Date(date)).format("HH:mm");
    },
    getNameForUuid(userUUID) {
      if (!userUUID || !store.state.group[userUUID] || store.state.group[userUUID].directCallUUID)
        return this.$t("generics.anonymous");
      return store.getNameForUuid(userUUID);
    },
    getTitelForUuid(userUUID) {
      if (!userUUID || !store.state.group[userUUID] || store.state.group[userUUID].directCallUUID) return "";
      return store.getTitelForUuid(userUUID);
    },
  },
  computed: {
    amIGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    // getClassChat() {
    //   if (this.isMobile) {
    //     return "chatContainerMobile";
    //   } else if (this.amIGuest) {
    //     if (this.showTimeLine) {
    //       return "chatContainerGuest";
    //     } else {
    //       return "chatContainerNoTimelineGuest";
    //     }
    //   } else {
    //     if (this.showTimeLine) {
    //       return "chatContainer";
    //     } else {
    //       return "chatContainerNoTimeline";
    //     }
    //   }
    // },
    outsideCallView() {
      if (
        this.state.currentContentVisile.showComponent &&
        (this.state.user.rtcCallStart ||
          Object.keys(this.state.remoteBridgeStreams).length > 0 ||
          (this.state.user.inBridgeCall &&
            this.state.user.inBridgeCall.length > 0))
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.cardMessage {
  height: auto;
  word-break: break-word;
  max-width: 95%;
  width: fit-content;
}
.messageContentEmoji {
  padding: 12px 0 12px 0;
}
.sendColorBtn {
  background: #1b67ec !important;
}
.sendColorBtnDisabled {
  background: #131519 !important;
  background-color: #131519 !important;
}
.w100 {
  width: 100%;
}
.chatContainerGuest {
  position: absolute;
  background: #131519;
  width: calc(100% - 83%);
  min-width: calc(100% - 83%);
  max-width: calc(100% - 83%);
  z-index: 2;
  left: 0px;
  bottom: 60px;
  height: calc(100% - 114px);
  max-height: calc(100% - 114px);
  min-height: calc(100% - 114px);
}
.chatContainerNoTimelineGuest {
  position: absolute;
  background: #131519;
  width: calc(100% - 83%);
  min-width: calc(100% - 83%);
  max-width: calc(100% - 83%);
  z-index: 2;
  left: 0px;
  bottom: 60px;
  height: calc(100% - 90px);
  max-height: calc(100% - 90px);
  min-height: calc(100% - 90px);
}
.chatContainer {
//   position: absolute;
  width: 100%;
  position: relative;
  background: #131519;
//   width: calc(100% - 83%);
//   min-width: calc(100% - 83%);
//   max-width: calc(100% - 83%);
  z-index: 2;
//   left: 55px;
//   bottom: 60px;
//   height: calc(100% - 114px);
    height: 100%;
//   max-height: calc(100% - 114px);
//   min-height: calc(100% - 114px);
}
.chatContainerNoTimeline {
  position: absolute;
  background: #131519;
  width: calc(100% - 83%);
  min-width: calc(100% - 83%);
  max-width: calc(100% - 83%);
  z-index: 2;
  left: 55px;
  bottom: 60px;
  height: calc(100% - 90px);
  max-height: calc(100% - 90px);
  min-height: calc(100% - 90px);
}
.chatContainerMobile {
  position: absolute;
  background: #131519;
  width: calc(100% - 83%);
  z-index: 2;
  left: 0px;
  bottom: 56px;
  height: calc(100% - 90px);
  max-height: calc(100% - 90px);
  min-height: calc(100% - 90px);
  width: 100%;
}
.containerMessages {
  height: auto;
  max-height: calc(100% - 125px);
  min-height: auto;
}
.chatMessageContainer {
  height: auto;
  max-height: calc(100% - 125px);
  min-height: auto;
  //just for firefox
  scrollbar-color: #06a5df #000000;
  scrollbar-width: thin;
}
.backgroundCard {
  background: transparent !important;
  background-color: transparent !important;
  color: white !important;
  position: absolute;
  width: 100%;
  padding: 12px;
}
.containerMessages {
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px black;
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #06a5df;
    border-radius: 10px;
  }
}

@media (max-width: 1400px) {
  .chatContainerNoTimeline,
  .chatContainer {
    width: 33%;
    min-width: 33%;
    max-width: 33%;
  }
}
@media (max-width: 800px) {
  .chatContainerNoTimeline,
  .chatContainer {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }
}
@media (max-width: 600px) {
  .chatContainerNoTimeline,
  .chatContainer {
    width: 90%;
    min-width: 90%;
    max-width: 90%;
  }
}
</style>
<style lang="scss">
.containerMessages {
  .emojisInText {
    font-size: 25px !important;
    line-height: 30px !important;
  }
  .singleEmojiInText {
    font-size: 50px !important;
  }
}
.containerInputMessage {
  position: absolute;
  bottom: 0;
  background: #131519;
  .theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #131519 !important;
  }
}
.inputChatText {
  .v-input__slot {
    padding: 0 9px !important;
  }
  .v-text-field__slot {
    margin-bottom: 4px !important;
  }
  .v-input__append-inner {
    margin-top: 5px !important;
  }
  .v-input__prepend-inner {
    margin-top: 5px !important;
  }
  .v-label {
    justify-content: center !important;
    top: unset !important;
    max-width: 100% !important;
  }
}
</style>
