var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.state.nerd)?_c('div',{staticClass:"debugContainer"},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"background":"black","color":"white"}},[_vm._v("\n        Debug Calls\n      ")]),_vm._v(" "),_c('v-expansion-panel-content',{staticStyle:{"background":"black","color":"white"}},[(_vm.localStats)?_c('div',[_c('div',[_c('div',{staticStyle:{"color":"green"}},[_vm._v("Server stats")]),_vm._v(" "),_c('div',[_vm._v("\n              type:\n              "+_vm._s(_vm.localStats.transport && _vm.localStats.transport[0]
                  ? _vm.localStats.transport[0].type
                  : "")+"\n            ")]),_vm._v(" "),_c('div',[_vm._v("\n              p2p:\n              "+_vm._s(_vm.localStats.transport && _vm.localStats.transport[0]
                  ? _vm.localStats.transport[0].p2p
                  : "")+"\n            ")]),_vm._v(" "),_c('div',[_vm._v("\n              E2E:\n              "+_vm._s(_vm.room && _vm.room.isE2EEEnabled()
                  ? 'true'
                  : "false")+"\n            ")]),_vm._v(" "),_c('div',[_vm._v("connectionQuality: "+_vm._s(_vm.localStats.connectionQuality))]),_vm._v(" "),_c('div',[_vm._v("\n              maxEnabledResolution: "+_vm._s(_vm.localStats.maxEnabledResolution)+"\n            ")]),_vm._v(" "),_c('div',[_vm._v("bitrate: "+_vm._s(_vm.localStats.bitrate))]),_vm._v(" "),_c('div',[_vm._v("packetLoss: "+_vm._s(_vm.localStats.packetLoss))])]),_vm._v(" "),_c('div',{staticClass:"mt-2",staticStyle:{"color":"green"}},[_vm._v("\n            Stats "+_vm._s(_vm.state.user.name)+" - "+_vm._s(this.getMyUserId)+"\n          ")]),_vm._v(" "),_c('div',[_vm._v("\n            Webcam Resolution:\n            "+_vm._s(_vm.localStats.resolution && _vm.localStats.resolution[this.getMyUserId]
                ? _vm.localStats.resolution[this.getMyUserId]
                : "")+"\n          ")]),_vm._v(" "),(_vm.getMyDesktopRoomUserID)?_c('div',[_vm._v("\n            ShareScreen Resolution:\n            "+_vm._s(_vm.localStats.resolution &&
              _vm.localStats.resolution[this.getMyDesktopRoomUserID]
                ? _vm.localStats.resolution[_vm.getMyDesktopRoomUserID]
                : "")+"\n          ")]):_vm._e(),_vm._v(" "),_c('div',[_vm._v("\n            Framerate:\n            "+_vm._s(_vm.localStats.framerate && _vm.localStats.framerate[this.getMyUserId]
                ? _vm.localStats.framerate[this.getMyUserId]
                : "")+"\n          ")]),_vm._v(" "),_vm._l((_vm.remoteParticipants),function(participant){return _c('div',{key:participant.participantId,staticClass:"mt-2"},[_c('div',{staticStyle:{"color":"green"}},[_vm._v("\n              Stats "+_vm._s(_vm.getNameForUuid(participant.userUUID))+" -\n              "+_vm._s(participant.participantId)+"\n            ")]),_vm._v(" "),_c('div',[_vm._v("\n              Webcam Resolution:\n              "+_vm._s(_vm.localStats.resolution &&
                _vm.localStats.resolution[participant.participantId]
                  ? _vm.localStats.resolution[participant.participantId]
                  : "")+"\n            ")]),_vm._v(" "),_c('div',[_vm._v("\n              Framerate:\n              "+_vm._s(_vm.localStats.framerate &&
                _vm.localStats.framerate[participant.participantId]
                  ? _vm.localStats.framerate[participant.participantId]
                  : "")+"\n            ")])])}),_vm._v(" "),_c('div',{staticClass:"d-none"},[_vm._v(_vm._s(_vm.localStats))])],2):_c('div',[_vm._v("Loading...")])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }