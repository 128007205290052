<template>
    <v-content class="px-0" style="width:100%;height:100%;overflow:auto;">
        <v-row class="mx-0">
            <div class="mainEventView pt-2">
              <div class="child"  v-for="(evt, key) in filteredEvents" :key="key">
                  <div align="left" class="bodyHeader pb-3">
                    <span class="font-weight-medium">
                      {{ getWeeklyDay(key, 'day') }},
                    </span>
                    <span class="font-weight-medium">
                      {{getWeeklyDay(key)}}
                    </span>
                </div>
                <div align="left" class="eventPill mb-4" v-for="event in evt" :key="event.uuid"  @click="showEvent(event)">
                    <div class="backDate px-4 py-3">
                      <div align="left">
                       <span class="font-weight-regular">
                        <span class="Capital"> {{ $t('components.conferenceForm.from') }}</span>
                         {{ buildHourText(event) }}
                      </span>
                      </div>
                    </div>
                  <v-divider class="dividerWeight" :class="getEventColor(event)"></v-divider>
                    <div class="LineHeight px-4 py-3">
                      <span class="font-weight-medium">
                      {{ buildTitleText(event) }}
                    </span>
                    <br>
                      <span>
                        <span class="font-weight-regular"> {{ $t('components.inviteVisitor.moderator') }}:</span>
                        {{ buildModeratorText(event) }}
                      </span> 
                      <br>
                      <span>
                        <span class="font-weight-regular"> {{ $t('components.callsContent.participants') }}:</span>
                        {{ buildUsersText(event) }}
                      </span> 
                    </div>
                  </div>
                    
                  </div>
              
            </div>
        </v-row>
    </v-content>
</template>

<script>
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { /* setEventStateModalEvent, */ setReminderModalEvent } from "../../effector/modals";
// import { dispatchErrorAlert } from "../../effector/alerts";
import { timelineEvents, dispatchFetchMyItemsForDateEvent } from '../../effector/timeline';
import { uuidToRepresentStore } from '../../effector/representative';

export default {
  components: {  },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      moment: moment,
      weekDays: [this.$t('generics.weekDaysComplete.Sun'), this.$t('generics.weekDaysComplete.mon'), this.$t('generics.weekDaysComplete.tue'), this.$t('generics.weekDaysComplete.wed'), this.$t('generics.weekDaysComplete.thu'), this.$t('generics.weekDaysComplete.fri'), this.$t('generics.weekDaysComplete.sat')],
    }
  },
  effector: {
    rawEvents: timelineEvents,
    uuidToRepresentStore: uuidToRepresentStore
  },
  mounted: function(){
    store.state.user.lastViewedCalendar = new Date().getTime();
  },
  methods: {
    buildTitleText(event){
      let respLine = '';
      if (!event) return '';
      if (event.title){
        respLine += event.title;   
      }
      return respLine;
      
    },
    buildModeratorText(event){
      let respLine = '';
      if (!event) return '';
      if (event.creatorUUID){
        respLine += store.state.group[event.creatorUUID].user.name;
        //respLine += '<span style="font-weight: bold;">' + this.$t('components.inviteVisitor.moderator') + '</span>:' + '   ' + store.state.group[event.creatorUUID].user.name + '. ' + '   ';
      }
      return respLine;
    },
    buildUsersText(event){
      let respLine = '';
      if (!event) return '';
      if (event.users && event.users.length){
        respLine +=  event.users.length;
        //respLine += '<span style="font-weight: bold;">' + this.$t('components.modalNote.users') + '</span>:' + '   ' + event.users.length;
      }
      return respLine;
    },
    buildHourText(event){
      let respLine = '';
      if (!event) return '';
      if (event.start && event.end){
        if(event.isUnlimited){
          respLine += moment(event.start).format('HH:mm');  
          } else {
            respLine += moment(event.start).format('HH:mm') + '  ' + this.$t('components.conferenceForm.to') + '   ' + moment(event.end).format('HH:mm') ;  
          }
        }
      return respLine;
      
    },
    getWeeklyDay(date, type){
      date = date.split('.')
      date = `${date[2]}-${date[0]}-${date[1]}`
        if ( type == 'day'){
          const newdate = new Date(date).getDay();
          return this.weekDays[newdate];
        } else {
          return moment(date).format('DD.MM.YYYY');
        }
    },
    getEventColor(event) {
      if ( !event.rsvp ){
        return event.color;
      }else{
        const usersAccepted = [];
        const usersRejected = [];
        for ( const userUUID of Object.keys(event.rsvp) ){
          const rsvp = event.rsvp[userUUID];
          if (rsvp && rsvp.status === 'accepted'){
            rsvp.color = 'green';
            usersAccepted.push(userUUID);
          }else if ( rsvp && rsvp.status === 'rejected' ){
            rsvp.color = 'red';
            usersRejected.push(userUUID);
          }else if ( rsvp && rsvp.status === 'pending' ){
            rsvp.color = event.color;
          }
        }
        const myUUID = this.uuidToRepresentStore || this.state.ownUUID
        if ( event.creatorUUID == myUUID && usersAccepted.length === Object.keys(event.rsvp).length ){
          return 'greenClass';
        }else if ( event.creatorUUID == myUUID && usersRejected.length > 0 ) {
          return 'red';
        } else if ( event.rsvp[myUUID] ){
          return event.rsvp[myUUID].color;
        }else{
          return event.color
        }
      }
    },
    showEvent(event){
       if ( event.eventType && event.eventType == 'guesscall'){
         setReminderModalEvent(event);
        // setEventStateModalEvent(event);
        // dispatchErrorAlert(this.$t('components.conferenceForm.reocurringConf'));
      }else{
        this.$router.push({ path: `/newconference/${event.confId}` });
      }
    },
  },
  computed:{
    filteredEvents(){
      const dateMidnight = new Date(Math.floor(Date.now() / 86400000) * 86400000);
      const dateCounter = new Date();
      const endDate = new Date();
      endDate.setDate(dateCounter.getDate() + 30);
      // eslint-disable-next-line no-unmodified-loop-condition
      while (dateCounter <= endDate) {
        dispatchFetchMyItemsForDateEvent(dateCounter.toISOString());
        dateCounter.setDate(dateCounter.getDate() + 1);
      }
      const eventsObject = {};
      this.rawEvents.forEach(event => {
        if (dateMidnight < new Date(event.start)) {
          const date = this.moment(event.start).format('MM.DD.YYYY');
          if ( !eventsObject[date] ){
            eventsObject[date] = [];
          }
          eventsObject[date].push(event);
        }
      });
      return eventsObject;
    },
  }
} 

</script>


<style scoped lang="scss">
.backDate{
  background-color:#ffffff!important;
  border-radius: 4px 4px 0px 0px;
}
.theme--dark .backDate{  
  background-color:#1e1e1e!important;
}

.dividerWeight  {
  border: 1px solid;
}

.LineHeight{
  line-height: 1.7em;
}
.greenClass{
  background-color:#008000!important;
  border-color: #008000!important;
}

.bodyDay{  
  background-color:#ffffff!important;
}

.eventPill{
  /*border-radius: 4px;*/
  /*width: 95%;*/
  cursor: pointer;
  background-color:#F5F5F5;
  border-radius:4px;
  border: 1px solid #e0e0e0;
}
.theme--dark .eventPill{
  background-color:#363636;
      border: 1px solid #545454;
}
.child{
  /*flex: 1 0 25%;*/
  /*margin: 2px;*/
  margin-top: 15px;
  margin-right: 10px;
  margin-left: 10px;
  min-height: 100px;
 /*border: 1px solid #e0e0e0;*/
  /*padding: 2px 4px;*/
  font-size: 15px;
  overflow: hidden;
  /*text-align: center;*/
  text-overflow: ellipsis;
  /*text-transform: capitalize;*/
  /*max-width: calc((100% / 3) - 4px);*/
  width: calc((100% / 4) - 20px);
  white-space: nowrap;
}

@media (min-width: 920.5px) and (max-width: 1230px) { 
.child{
  width: calc((100% / 3) - 20px);
  }
}
@media (max-width: 920px) { 
.child{
  width: calc((100% / 2) - 20px);
  }
}

.Capital{
text-transform: capitalize;
}
.eventViewHead{
  display: flex;
}
.mainEventView{
   display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  // width: 100%;
  // height: 100%;
  // display: flex;
  // flex-direction: column;
  // min-height: 0;
  // flex-wrap: wrap;
}

</style>