var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',{staticClass:"px-0 py-0"},[_c('v-row',[_c('v-col',{staticClass:"mx-2"},[_c('v-card',{attrs:{"outlined":""}},[_c('StatsNumbers'),_vm._v(" "),_c('div',{staticClass:"d-flex justify-space-between pa-4"},[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t("components.statusContainer.userListStatus"))+":\n            "),_c('span',{style:({ color: _vm.getStatusColor + '!important' })},[_vm._v(_vm._s(_vm.statusType))])]),_vm._v(" "),_c('span',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"pt-0",staticStyle:{"width":"310px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('span',{staticClass:"ml-4 pointer",on:{"click":function($event){return _vm.closeStatus()}}},[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'times']}})],1)],1)]),_vm._v(" "),[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"page":_vm.page,"fixed-header":"","height":"calc(100vh - 365px)"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"update:items-per-page":_vm.getItemPerPage},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"profile",class:{
                  pointer:
                    !_vm.disableCallBtn(item.uuid) && item.uuid !== _vm.state.ownUUID,
                },attrs:{"color":"grey","size":"35","tile":""},on:{"click":function($event){!_vm.disableCallBtn(item.uuid) && item.uuid !== _vm.state.ownUUID
                    ? _vm.callUser(item.uuid) : ''}}},[(
                    item.avatar &&
                    item.avatar !== 'img/default_profile_picture.png'
                  )?_c('v-img',{attrs:{"src":item.avatar || 'img/default_profile_picture.png',"lazy-src":"img/default_profile_picture.png"}}):_vm._e(),_vm._v(" "),(
                    !item.avatar ||
                    item.avatar === 'img/default_profile_picture.png'
                  )?_c('DefaultAvatar',{attrs:{"userData":item,"lowerText":true}}):_vm._e()],1)]}},{key:"item.sendMessage",fn:function(ref){
                  var item = ref.item;
return [(item && item.uuid !== _vm.state.ownUUID)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setShowModalSenMsg(item.uuid)}}},[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'paper-plane']}})],1):_vm._e()]}},{key:"item.holidays",fn:function(ref){
                  var item = ref.item;
return [_c('span',{class:item.isInHolidays ? 'holidaysColor' : null},[_vm._v(_vm._s(item.holidays))])]}},{key:"item.presentFrom",fn:function(ref){
                  var item = ref.item;
return [(item.connected && _vm.canSeeLastLogin)?_c('span',[_vm._v(_vm._s(item.presentFrom || ""))]):_vm._e()]}}])})]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }