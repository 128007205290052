import { render, staticRenderFns } from "./statusColorModal.vue?vue&type=template&id=678e4ee6&scoped=true"
import script from "./statusColorModal.vue?vue&type=script&lang=js"
export * from "./statusColorModal.vue?vue&type=script&lang=js"
import style0 from "./statusColorModal.vue?vue&type=style&index=0&id=678e4ee6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "678e4ee6",
  null
  
)

export default component.exports