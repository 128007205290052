<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('generics.cameraPermissions')"
        :closeModalFunction="closeModal"
      />
      <v-row class="mx-0 pt-4 pb-1 px-4">
        <v-col>
          <p>{{ $t("generics.cameraPermissionsInfo") }}</p>
        </v-col>
      </v-row>
      <FooterModal :closeModalFunction="closeModal">
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import {errorCameraPermissionsModalStore, resetErrorCameraPermissionsModalEvent} from "../../effector/modals"
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
    };
  },
  effector:{
    showModal: errorCameraPermissionsModalStore
  },

  methods: {
    closeModal() {
      resetErrorCameraPermissionsModalEvent()
    },
  },
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
