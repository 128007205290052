import { render, staticRenderFns } from "./visitorListItem.vue?vue&type=template&id=27f68080&scoped=true"
import script from "./visitorListItem.vue?vue&type=script&lang=js"
export * from "./visitorListItem.vue?vue&type=script&lang=js"
import style0 from "./visitorListItem.vue?vue&type=style&index=0&id=27f68080&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27f68080",
  null
  
)

export default component.exports