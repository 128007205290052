var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{attrs:{"max-width":"320"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[(!_vm.isGuest && !_vm.mini)?_c('v-list-group',{attrs:{"active-class":"listColor","value":_vm.isActive,"appendIcon":_vm.sortedCoffeeBreakUsers.length === 0 ? null : '$expand'},on:{"click":function($event){return _vm.handlerClickCoffBr($event)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[(_vm.currentPage('/coffee-break'))?_c('font-awesome-icon',{staticClass:"primary--text",attrs:{"icon":['fas', 'mug']}}):_c('font-awesome-icon',{staticClass:"primary--text",attrs:{"icon":['fal', 'mug']}})],1),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-title',_vm._g({class:("" + (_vm.getRoute === '/coffee-break'
                ? 'font-weight-bold'
                : '')),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.setCurrentContentVisile('', false, _vm.$router);
              _vm.handleMyCoffeeBreakView();}}},on),[_vm._v("\n            "+_vm._s(_vm.$t("status.Coffee break"))+"\n          ")])]}}],null,false,2609919652)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("status.Coffee break")))])]),_vm._v(" "),(_vm.showCustomTT)?_c('CustomTT',{attrs:{"text":((_vm.$t('components.hotkeys.controlKey')) + " + B"),"position":"bottom","extraclass":'ctrb'}}):_vm._e()]},proxy:true}],null,false,592762340)},[_vm._v(" "),_c('UserList',{attrs:{"persons":_vm.sortedCoffeeBreakUsers,"section":'coffee-break'}})],1):_vm._e(),_vm._v(" "),(!_vm.isGuest && _vm.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"cursorPointer"},on),[_c('v-list-item-icon',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handlerClickCoffBr()}}},[(_vm.currentPage('/coffee-break'))?_c('font-awesome-icon',{staticClass:"primary--text",attrs:{"icon":['fas', 'mug']}}):_vm._e(),_vm._v(" "),(!_vm.currentPage('/coffee-break'))?_c('font-awesome-icon',{staticClass:"primary--text",attrs:{"icon":['fal', 'mug']}}):_vm._e(),_vm._v(" "),(_vm.getConnectedCoffeeBreak > 0)?_c('v-badge',{staticClass:"coffeeBreakBadge",attrs:{"right":"","color":"error","content":_vm.getConnectedCoffeeBreak,"value":_vm.getConnectedCoffeeBreak,"bordered":"","overlap":"","offset-x":"7","offset-y":"7"},nativeOn:{"click":function($event){return _vm.handleMyCoffeeBreakView()}}}):_vm._e()],1)],1)]}}],null,false,2969581262)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("status.Coffee break")))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }