<template>
  <v-list >
    <v-list-group 
      active-class="listColor"
      v-if="!isGuest"
      :value="state.searchTerm.length>0 ? false: null || isActive"
      @click="setList('invites') "
      :appendIcon="sortedInvites.length == 0 ? null : '$expand'"
    >
      <template v-slot:activator>

        <v-tooltip top max-width="300"> 
         <template v-slot:activator="{ on }">
             <v-list-item-icon @click="handlerClickInvitesIcon" style="min-height:0px !important" :class="`${getRoute === '/invites' ? 'primary--text' : ''}`">
              <font-awesome-icon  v-if="currentPage('/invites')" class="primary--text" :icon="['fas', 'user-friends']" />
              <font-awesome-icon v-else class="primary--text" :icon="['fal', 'user-friends']" />
            </v-list-item-icon>
              <v-list-item-title
                @click.stop.prevent="openGridInvites()"
              v-on="on"
              class="primary--text"
                :class="`${
               currentPage('/invites') ? 'font-weight-bold primary--text' : ''
              }`"
              >
              
              {{ $t('generics.userInvites')  }}
                <span>
                  <template v-if="sortedInvites.length > 0">
                    (<span class="textColor">{{sortedInvites.length}}</span>)
                  </template>
                </span>
              </v-list-item-title>
              </template> 
                <span>{{ $t('generics.userInvites') }}</span>
            </v-tooltip> 
      </template>
      <VisitorList :visitors="sortedInvites" :section="'My Invites'" />
    </v-list-group>
  </v-list>
</template>
<script>
import store from "../../store";
import VisitorList from "./visitorList.vue";

export default {
  props: [
    "invites",
    "filterPerson",
    "setCurrentContentVisile",
    "isGuest",
    "active",
    "setList",
    "isMobile",
    "openSidebar",
    "mini"
  ],
  components: {
    VisitorList,
  },
  data: () => ({
    state: store.state
  }),
  mounted(){
  },
  methods: {
    handlerClickInvitesIcon(evt){
      if(this.mini){
        evt.stopPropagation()
        evt.preventDefault()
        this.handleInvitesView()
      }
    },
    openGridInvites(){
       if (this.sortedInvites && this.sortedInvites.length > 0) {
        this.handleInvitesView();
      }
    },
     handleInvitesView() {
      if (!this.isMobile) {
        this.setCurrentContentVisile("invites", true, this.$router);
      }
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
  },
  computed: {
    sortedInvites () {
      return this.invites.sort((a, b) => {
        return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
      });
    },
    isActive() {
      return this.active == "invites";
    },
    getRoute() {
      return this.$route.path;
    }
  }
};
</script>

<style  lang="scss">
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.titleGroup {
  .v-icon {
    font-size: 14px !important;
  }
}

.v-list {
  padding: 0;
}

.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.textColor {
  color: var(--v-primary-base);
}

.theme--dark .listColor {
  color: white !important;
}
.theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>

<style scoped lang="scss">
.btnInviteVisitor{
    position: absolute !important;
    right: 57px;
    top: 5px;
}
</style>

