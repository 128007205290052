
<template>
  <v-footer fixed>
    <div class="d-flex justify-space-between align-center w100">
      <v-img
        v-if="mini"
        max-width="25"
        class="cursorPointer"
        :class="{
          'ml-3': isMobile && !mini,
          'ml-6 mr-0': !isMobile && !mini,
          'ml-0': mini,
        }"
        style="display: inline-flex"
        :src="'assets/icon.png'"
        @click="sheet = !sheet"
        contain
      ></v-img>
      <v-tooltip
        top
        v-if="dirty && !callInfoStateStore.callActive && !inBridgeCall && !mini"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            id="updateIcon"
            class="buttonReload"
            v-on="on"
            icon
            color="red"
            @click="reloadPage()"
          >
            <font-awesome-icon id="updateFont" :icon="['far', 'repeat']" />
          </v-btn>
        </template>
        <span>{{ $t("components.footer.newUpdate") }}</span>
      </v-tooltip>
      <v-btn
        text
        @click="sheet = !sheet"
        class="w100"
        :style="{ textTransform: 'none' }"
        v-if="!mini"
      >
        <span style="font-size: 15px" class="primary--text">
          vOffice &copy;</span
        >
      </v-btn>
      <v-bottom-sheet padless v-model="sheet" persistent>
        <v-sheet class="text-center" min-height="200px">
          <v-row :style="{ width: '100%', margin: 0 }" class="px-5 mx-0">
            <v-col cols="12" class="px-0">
              <v-btn class="mt-6" text color="red" @click="closeFooter()">{{
                $t("components.footer.close")
              }}</v-btn>
            </v-col>
          </v-row>
          <!-- Add content footer -->
          <template>
            <Contact v-if="activeTab === 'ContactUs'" />
            <About v-if="activeTab === 'AboutUs'" />
          </template>
          <!-- End content footer -->
          <v-row justify="center" no-gutters class="py-8">
            <v-btn
              v-for="link in links"
              :key="link"
              text
              rounded
              class="my-2"
              @click="changeTab(link)"
              >{{ $t(`literals.${link}`) }}</v-btn
            >
            <v-col class="primary py-2 text-center white--text" cols="12">
              <strong>RA-MICRO Software AG</strong>
              &copy; {{ new Date().getFullYear() }}
            </v-col>
          </v-row>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </v-footer>
</template>

<script>
import store from "../../store";
import Contact from "./contact.vue";
import About from "./about.vue";
import { callInfoStateStore } from "../../effector/call";
import { isDirtyStore } from "../../effector/version";
import { isMobile } from "../../lib/mobileUtil";
import { amInABridgeCall } from "../../utils/calls";

export default {
  props: ["mini"],
  components: { Contact, About },
  data() {
    return {
      sheet: false,
      state: store.state,
      isMobile: isMobile(),
      links: ["Imprint", "Privacy Policy", "Contact Us"],
      activeTab: "Home",
    };
  },
  effector: {
    dirty: isDirtyStore,
    callInfoStateStore: callInfoStateStore,
  },
  methods: {
    closeFooter() {
      this.sheet = !this.sheet;
      this.activeTab = "Home";
    },
    changeTab(tab) {
      this.activeTab = tab.replace(/ /g, "");
      if (tab == "Imprint") {
        this.openInNewTab("https://www.ra-micro.de/info/impressum.html");
      }
      if (tab == "Privacy Policy") {
        this.openInNewTab(
          "https://www.voffice.pro/datenschutzerklaerung-voffice-software"
        );
      }
    },
    openInNewTab(url) {
      window.open(url);
    },
    reloadPage() {
      window.location.reload();
    },
  },
  computed: {
    inBridgeCall() {
      return amInABridgeCall();
    },
  },
};
</script>

<style scoped lang="scss">
.buttonReload {
  position: absolute;
  z-index: 999;
}
.w100 {
  width: 100%;
}
.cursorPointer {
  cursor: pointer;
}
#updateFont {
  animation: blink 1s linear infinite;
  animation-direction: alternate;
}
.w100 {
  width: 100%;
}

@keyframes blink {
  0% {
    font-size: 12px;
  }
  50% {
    font-size: 16px;
  }
  100% {
    font-size: 20px;
  }
}
</style>