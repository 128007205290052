<template>
  <!-- <iframe
    width="100%"
    height="100%"
    src="https://www.youtube.com/embed/5qap5aO4i9A?enablejsapi=1&autoplay=1&disablekb=1&enablejsapi=1&loop=1&controls=0&mute=0"
    enablejsapi="1"
    id="conferenceHallTest"
    title="CONFERENCE HALL TEST"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
    allowfullscreen

  ></iframe> -->
  <youtube :video-id="youTubeVideoId" 
          ref="youtube" 
          :player-vars="playerVars" 
          @playing="playing"
          @ready="setEventListener"
          width="100%"
          height="100%">
  </youtube>
</template>
<script>
import store from "../../../store";
import Vue from 'vue'
import VueYoutube from 'vue-youtube'
 
Vue.use(VueYoutube)

export default {
  props: [
    "setYoutubePlayer",
    "youTubeVideoId"
  ],
  components: {},
  data() {
    return {
      state: store.state,
      playerVars: {
        mute: this.canEnableSound,
        autoplay: 1,
        controls: 0
      }
    };
  },
  methods: {
    playing() {
      console.log('\o/ we are watching!!!')
      this.setYoutubePlayer(this.$refs.youtube.player)
    },
    setEventListener() {
      this.$refs.youtube.player.on('stateChange', (event) => {
        switch(event.data) {
          // PAUSE EVENT
          case 2:
            // Hack: This tries to prevent YTPlayer auto-pause after a time of inactivity
            this.$refs.youtube.player.playVideo()
          break;
        }
      });
    }
  },
  computed: {
    canEnableSound() {
      return !store.state.canAutoplayWithSound
    },
    player() {
      return this.$refs.youtube.player
    },
  }
};
</script>
