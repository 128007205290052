
<template id="parentWrapper">
  <div id="confWrapper" class="confWrapper">
    <div class="d-flex">
      <ConferenceLeftBar v-if="isConferenceCall" :isConferenceCall="isConferenceCall"  :amIModerator="amIModerator" :hangUpBridgeCall="hangUpBridgeCall" :getCallUUID="getCallUUID" />
      <ProgressLinearBridge
        :amIModerator="amIModerator"
        :updateTimeForUser="updateTimeForUser"
        :setCallDuration="setCallDuration"
        :hangUpBridgeCall="hangUpBridgeCall"
        :redirectToStartView="redirectToStartView"
        :isConferenceCall="isConferenceCall"
        v-if="showTimeLine"
      />
      <ConferenceRightBar v-if="isConferenceCall" :isConferenceCall="isConferenceCall"  :amIModerator="amIModerator" />
    </div>
    <VideoBridgeManager
      v-if="!rejectedCall"
      :isRecorder="false"
      :ownUUID="ownUUID"
      :amICalling="amICalling"
      :getCallUUID="getCallUUID"
      :getAvatarForUuid="getAvatarForUuid"
      :getTitelForUuid="getTitelForUuid"
      :getVisitorFunctionForUuid="getVisitorFunctionForUuid"
      :bridgeCallScreenShareState="bridgeCallScreenShareState"
      :getNameForUuid="getNameForUuid"
      :jvbOptions="jvbOptions"
      :hangUpCallingCb="hangUpCalling"
      :rejectCallCb="rejectCall"
      :showDebug="state.nerd"
      :isNormalMode="isNormalMode"
      :hideVideoContainer="hideVideoContainer"
      :videoDeviceId="videoDeviceId"
      :audioDeviceId="audioDeviceId"
      :amIInitiator="amIInitiator"
      :assistantsInitiatorList="assistantsInitiatorList"
      :showSendMessage="setShowModalSenMsg"
      :getEmailForUuid="getEmailForUuid"
      :emailSalutation="emailSalutation"
      :openCloseBridgeStreamSettings="openCloseBridgeStreamSettings"
      :getStreamData="getStreamData"
      :getModerator="getModerator"
      :currentCallTime="currentCallTime"
      :setCallDuration="setCallDuration"
      :bridgeCallEnd="bridgeCallEnd"
      :redirectToStartView="redirectToStartView"
      :toggleModalSettings="toggleModalSettings"
      :companyLogo="companyLogo"
      :hasPrivilege="hasPrivilege"
      :userIsOneCall="userIsOneCall"
      :setSendMessageInCallModal="setSendMessageInCallModal"
      :setNewMessageFromCall="setNewMessageFromCall"
      :newMessageFromCall="newMessageFromCall"
      :fetchWallPaperSrc="fetchWallPaperSrc"
      :resetNewMessageFromCallEvent="resetMessange"
      :conferenceDocuments="confDocuments"
      :userConferencePosition="getuserConferencePosition"
      :conferenceAttendees="confAttendees"
      :confModerators="confPreModerators"
      :dispatchMessage="dispatchMessage"
      :callUserConference="callUserConference"
      :conferenceSettings="gettConferenceSettings"
      :usersToHideName="getUsersToHideName"
      :showTimeLine="showTimeLine"
      :isConferenceCall="isConferenceCall"
      :updateAttendanceList="updateAttendanceList"
      :isVisitor="isVisitor"
      :isWaitingRoomUser="isWaitingRoomUser"
      :isPayEnabled="isPayEnabled"
      :currency="getCurrency"
      :setRequestPayment="setRequestPayment"
      :isAudioOnly="getIsAudioOnly"
      :hasMobileParticipant="getIsMobile"
      :hasCallEnded="hasCallEnded"
      :setInfoModal="setInfoModal"
      :allMessages="allMessages"
    />
    <!--  :participantInPayment="getparticipantInPayment"
      :uuidInPayment="getuuidInPayment"
      :setUserInPayment="setUserInPayment"
      :setAttach="setAttach"
      :isVisitorfromPayment="isVisitorfromPayment" -->

    <!-- ^ If you add any props above, please add shims in websrc\vue\recorder.vue -->
    <template v-if="showStreamSettings">
      <BridgeCallStreamSettingsModal :showModal="showStreamSettings" :closeModal="openCloseBridgeStreamSettings" />
    </template>
</div>
</template>
<script>
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import store, { EventBus } from "../../store";
import { callPerson, isConferenceCall } from "../../utils/calls";
import { dispatchSuccessAlert, dispatchErrorAlert } from "../../effector/alerts";
import { timelineEvents, deleteTimelineItemEvent } from '../../effector/timeline';
import { messagesToMe } from "../../effector/message"
import { wsCall } from "../../lib/wsConnect";
import { bridgeCallScreenShareState, setPaymentState, updateTimelineEntryColor } from "../../lib/wsMsg";
import { jvbOptions } from "../../lib/rtcSettings";
import VideoBridgeManager from "../../components/content/videoBridgeManager.vue";
import { bridgeInfoStore, updateOnlineStatusEvent } from "../../effector/users/bridgeInfo";
import BridgeCallStreamSettingsModal from "../modal/bridgeCallStreamSettingsModal.vue"
import ProgressLinearBridge from "../progressLinearBridge/progressLinearBridge.vue";
import ConferenceLeftBar from "../conferenceLeftBar/conferenceLeftBar.vue"
import ConferenceRightBar from "../conferenceRightBar/conferenceRightBar.vue"
import { isGuestOrVisitor } from "../../utils/routerAcl.js";
import { hasPrivilege, isVisitor, isWaitingRoomUser, isOnlyOneCallUser } from "../../utils/privileges"
import { setRequestPaymentModal, setRequestPaymentEventModal, setSendMessageInCallModalEvent, setNewMessageFromCallEvent, newMessageFromCallStore, resetNewMessageFromCallEvent, setSendMessageModalEvent, setHistoryMessagesModalEvent } from "../../effector/modals";

export default {
  props: ["toggleModalSettings"],
  components: { VideoBridgeManager, BridgeCallStreamSettingsModal, ProgressLinearBridge, ConferenceLeftBar, ConferenceRightBar },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      rejectedCall: false,
      isNormalMode: true,
      previousGridSize: "2x2",
      gridSize: "2x2",
      gridHasChanged: false,
      micMute: false,
      videDisabled: false,
      lockAudioToggle: true,
      lockVideoToggle: true,
      maxUserSize: 2,
      showStreamSettings: false,
      amIModerator: false,
      currentCallTime: undefined,
      bridgeCallEnd: false,
      setCurrentContentVisile: store.setCurrentContentVisile,
      participantInPayment: null,
      uuidInPayment:null


      // moderatorUUID: undefined
    };
  },
  effector:{
    newMessageFromCall: newMessageFromCallStore,
    bridgeInfoStore: bridgeInfoStore,
    rawEvents: timelineEvents,
    allMessages: messagesToMe,
  },
  computed: {
    isVisitorfromPayment(){
      return this.state.user.paymentState.fromPayment;
    },
    getparticipantInPayment(){
      return this.participantInPayment;
    },
    getuuidInPayment(){
      return this.uuidInPayment;
    },
    getIsAudioOnly(){
      const callUUID = this.getCallUUID;
      if (this.state.remoteBridgeStreams[callUUID]){
        return this.state.remoteBridgeStreams[callUUID].isAudioOnly;
      } else {
        return true;
      }
    },
    getIsMobile(){
      const callUUID = this.getCallUUID;
      if (this.state.remoteBridgeStreams[callUUID] && this.state.remoteBridgeStreams[callUUID].calling && this.state.remoteBridgeStreams[callUUID].calling.length) {
        const calling = this.state.remoteBridgeStreams[callUUID].calling;
        const group = this.state.group;
        const retVal = [...calling].reduce((acc, uuid) => (acc || !!((group[uuid] || {}).user || {}).isMobile), false);
        if (retVal) return retVal; // Return true if any of the users being called isMobile
      }
      if (this.bridgeInfoStore && this.bridgeInfoStore.calls && this.bridgeInfoStore.calls[callUUID]) {
        return this.bridgeInfoStore.calls[callUUID].isMobile;
      } else {
        return false;
      }
    },
    getCurrency(){
      return this.state.namespaceSettings.currency;
    },
    isPayEnabled() {
      // See also websrc/components/modal/newAdminSettingsModal.vue isPayEnabled
      return webLicensedBaseFeatures.isVisitorPayAvailable && this.state.namespaceSettings.paySetting;
    },
    isConferenceCall(){
      const callUUID = this.getCallUUID;
     return isConferenceCall(callUUID); 
    },
    getUsersToHideName(){
     const callUUID = this.getCallUUID;
     return this.state.remoteBridgeStreams[callUUID].usersToHideName || []; 
    },
    gettConferenceSettings(){
      const callUUID = this.getCallUUID;
      return this.state.remoteBridgeStreams[callUUID].conferenceSettings || {};   
    },
    confDocuments() {
      const callUUID = this.getCallUUID;
      return this.state.remoteBridgeStreams[callUUID].conferenceDocuments || [];//[{documentId:123, documentName:"pdfDoc1", documentURL: "https://gdlp01.c-wss.com/gds/1/0300004731/02/eosrt3-eos1100d-bim2-c-en.pdf"},{documentId:456, documentName:"pdfDoc2", documentURL: "https://gdlp01.c-wss.com/gds/0/0300004730/02/eosrt3-eos1100d-im2-c-en.pdf"}];
    },
    getuserConferencePosition(){
      const callUUID = this.getCallUUID;
      return this.state.remoteBridgeStreams[callUUID].userConferencePosition || {};
    },
    getExcludedFromCalling(){
      const callUUID = this.getCallUUID;
      return this.state.remoteBridgeStreams[callUUID].excludedFromCalling || [];
    },
    confAttendees() {
      const callUUID = this.getCallUUID;
      return this.state.remoteBridgeStreams[callUUID].conferenceAttendees || [];
    },
     confPreModerators() {
      const callUUID = this.getCallUUID;
      return this.state.remoteBridgeStreams[callUUID].confModerators || [];
    },
    
    companyLogo(){
           return (
        store.state.namespaceSettings.companyLogo ||
        "/img/icon.png"
      );
    },
    getStreamData() {
      return this.state.user.streamingSettings || {};
    },
    // ispresentationMode() {
    //   return !this.isNormalMode;
    // },
    amIInitiator(){
      const callUUID = this.getCallUUID;
      return this.state.remoteBridgeStreams[callUUID].initiator;
    },
    assistantsInitiatorList() {
      if (this.amIInitiator === this.ownUUID && this.state.group[this.amIInitiator]) {
        return this.state.group[this.amIInitiator].user.assistants
      }
      return undefined;
    },
    hideVideoContainer() {
      return (
        this.state.currentContentVisile.showComponent ||
        Object.keys(this.state.remoteBridgeStreams).length === 0
      );
    },
    jvbOptions() {
      return jvbOptions();
    },
    videoDeviceId() {
      return this.state.persisted.mediaDeviceSetup.videoDeviceId;
    },
    audioDeviceId() {
      return this.state.persisted.mediaDeviceSetup.audioDeviceId;
    },
    getCallUUID() {
      const remoteStreamsKey = Object.keys(this.state.remoteBridgeStreams)[0];
      return remoteStreamsKey;
    },
    amICalling() {
      const callUUID = this.getCallUUID;
      const excluded = this.getExcludedFromCalling;
      if ( excluded && excluded.length > 0 ){
        return this.state.remoteBridgeStreams[callUUID].calling.filter(e => this.getExcludedFromCalling.indexOf(e) == - 1);
      }else{
        return this.state.remoteBridgeStreams[callUUID].calling
      }
    },
    usersInCall() {
      const remoteStreamsKey = Object.keys(this.state.remoteBridgeStreams);
      return this.state.remoteBridgeStreams[remoteStreamsKey[0]].users;
    },
    showTimeLine(){
      if ( this.getIsMobile ){
        return false;
      }
      const callUUID = this.getCallUUID
      if(this.state.remoteBridgeStreams[callUUID]){
        if(this.state.remoteBridgeStreams[callUUID].infiniteCall){
          return false
        }else {
          return true
        }
      } else {
        return false
      }
    }
  },
  watch: {
    getCallUUID: {
      immediate: true,
      handler: function (callUUID) {
        if (callUUID) {
          this.state.user.originalActivity = this.state.user.activity;
          if (this.state.user.activity !== 'No status') {
            this.state.user.activity = "inCall";
          }
          this.isNormalMode = this.state.remoteBridgeStreams[
            callUUID
          ].isNormalMode;
        } else {
          this.state.user.activity = this.state.user.originalActivity;
        }
      },
    },
    // ispresentationMode: {
    //   handler: function (ispresentationMode) {
    //     console.log("isNormalMode", ispresentationMode);
    //     this.changeNormalMode(ispresentationMode);
    //     //  return store.changeIsNormalModeBridgeStream(!ispresentationMode)
    //   },
    // },
    "state.remoteBridgeStreams": {
      // immediate: true,
      handler: function (remoteBridgeStreams) {
        const remoteBridgeStreamsKey = Object.keys(remoteBridgeStreams)[0];
        if(remoteBridgeStreamsKey){
          if(remoteBridgeStreams[remoteBridgeStreamsKey].isNormalMode !== this.isNormalMode){
            this.isNormalMode = remoteBridgeStreams[remoteBridgeStreamsKey].isNormalMode
          }
        }
      },
      deep: true,
    },
    // amICalling: {
    //   // immediate: true,
    //   handler: function (callingList) {
    //     if (callingList && callingList.length == "0") {
    //       setTimeout(() => {
    //         if (this.participantsSize < 2) {
    //           this.rejectCall();
    //         }
    //       }, 3000);
    //     }
    //   },
    //   deep: true,
    // },
  },
  beforeDestroy() {
    this.bridgeCallEnd = false;
    this.state.user.activity = this.state.user.originalActivity;
  },
  destroyed() {
    this.bridgeCallEnd = false;
    this.state.user.activity = this.state.user.originalActivity;
  },
  mounted() {
    EventBus.$on('receivedPaymentRequest', (paymentInfo)=> {  
      this.setUserInPayment(paymentInfo.uuidInPayment, paymentInfo.participantInPayment)
    });
    EventBus.$on("togglePresentation", (info) => {
      this.isNormalMode = info.isNormalMode;
      this.togglePresentation(info.isNormalMode)
      for (const participant of info.participants) {
        const userUUID = participant.getDisplayName().split('_')[0];
        wsCall("sendToUUID", userUUID, {
          type: "bridge-signal",
          action: "change_normal_mode",
          sender: this.state.ownUUID,
          info: {
            callUUID: this.getCallUUID,
            isNormalMode: info.isNormalMode,
          },
        });
      }
    });

  },
  methods: {
    setInfoModal(data){
        return store.setinfoModal(data);
    },
    hasCallEnded(callUUID){
      // eslint-disable-next-line no-prototype-builtins
      if (store.state.remoteBridgeStreams[callUUID].hasOwnProperty('confId')){
        const confId = (store.state.remoteBridgeStreams[callUUID].confId || false);
        if (confId) {
          const extant = this.rawEvents.filter(e => e.confId == confId);
          if (extant[0] && extant[0].color === 'red'){
            // change color
            updateTimelineEntryColor(extant[0].uuid, 'gray');
          }
        }
      }
    },
    setAttach(attachTo){
      setTimeout(() => {
        this.state.user.paymentState.attachTo = attachTo;
      }, 250)
    },
    setUserInPayment(uuidInPayment, participantInPayment){
      this.participantInPayment = participantInPayment;
      this.uuidInPayment = uuidInPayment;

    },
    setRequestPayment(uuid, tileId){
      const callUUID = this.getCallUUID;
      const obj = {
        receiver: uuid,
        tileId: tileId,
        callUUID: callUUID
      }
      setRequestPaymentModal(obj)
    },
    isWaitingRoomUser(uuid){
      return isWaitingRoomUser(uuid);
    },
    isVisitor(uuid, skipDirectCallLink = false){
      // Workaround to report direct call invite anonymous visitors as non-visitors to disable FacePay
      if (skipDirectCallLink && (store.state.group[uuid] || {}).directCallUUID) return false;
      // Default behaviour
      return isVisitor(uuid);
    },
    callUserConference(uuid){
      const isConnected = ((store.state.group[uuid] || {}).connected || false);
      if ( isConnected ){
        callPerson(uuid, this.$router)
      }else{
        this.dispatchMessage(this.$t('generics.userIsOffline', [store.state.group[uuid].user.name]))
      }
    },
    dispatchMessage(message){
      dispatchErrorAlert(message)
    },
    resetMessange(){
      resetNewMessageFromCallEvent()
    },
     fetchWallPaperSrc(uuid){
      return   (((store.state.group[uuid] || {}).user || {}).streamingSettings || {}).wallpaperSrc || 'img/pause.png'
      // return 'https://t.dev03.voffice.pro/userMedia/wallpapers/t.dev03.voffice.pro/28f5236d9df9964a91473a58049255b8/1608630266224.jpeg'
    },
    setNewMessageFromCall(val){
      setNewMessageFromCallEvent(val)
    },
    setSendMessageInCallModal(){
      setSendMessageInCallModalEvent(true)
    },
    hasPrivilege(userUUID){
      return hasPrivilege(userUUID)
    },
    userIsOneCall(userUUID){
      return isOnlyOneCallUser(userUUID);
    },
    redirectToStartView() {
      const startView = '/' + (isGuestOrVisitor() ? 'home' : (store.state.user.userSettings.startView || 'my-favorites'));
      // if (Router.currentRoute.path !== startView) Router.push(startView);
      this.setCurrentContentVisile(startView, true, this.$router);
    },
    setCallDuration(callUUID, callDuration){
      return store.changeCallDurationMsBridgeStream(callUUID, callDuration);
    },
    updateTimeForUser(newTime){
      if(newTime){
        this.currentCallTime = newTime;
      }
    },
    hangUpBridgeCall(){
      this.bridgeCallEnd = true;
    },
    getModerator(value){
      if(value){
        this.amIModerator = true;
      }else {
        this.amIModerator = false
      }
    },
    openCloseBridgeStreamSettings(){
      this.showStreamSettings = !this.showStreamSettings;
    },
    emailSalutation() {
      return encodeURIComponent(
        this.$t("components.userListItem.emailSalutation")
      );
    },
    setShowModalSenMsg(uuid, type = "") {
      // return store.setShowModalSenMsg(uuid, type);
      // setSendMessageModalEvent({show:true, users: [uuid], type: 'regularMsg'})
      setHistoryMessagesModalEvent(uuid)
    },
    togglePresentation(isNormalMode) {
      const callUUID = this.state.user.inBridgeCall;
      return store.changeIsNormalModeBridgeStream(
        callUUID,
        isNormalMode
      );
    },
    hangUpCalling(id) {
      const remoteBridgeStreamsKey = Object.keys(
        this.state.remoteBridgeStreams
      )[0];
      const callInfo = this.state.remoteBridgeStreams[remoteBridgeStreamsKey];
      wsCall("sendToUUID", id, {
        type: "bridge-signal",
        action: "cancel_bridge_call",
        sender: this.state.ownUUID,
        info: {
          callUUID: callInfo.callUUID,
        },
      });
      store.removeCallingUser(id, callInfo);
    },
    rejectCall() {
      const remoteBridgeStreamsKey = Object.keys(
        this.state.remoteBridgeStreams
      )[0];
      const callInfo = this.state.remoteBridgeStreams[remoteBridgeStreamsKey];
      if(callInfo){
        const callingUser = callInfo.calling;
        if (callingUser && callingUser.length > 0) {
          callingUser.forEach((element) => {
            wsCall("sendToUUID", element, {
              type: "bridge-signal",
              action: "cancel_bridge_call",
              sender: this.state.ownUUID,
              info: {
                callUUID: callInfo.callUUID,
              },
            });
          });
        }
        store.removeRemoteBridgeStreams(callInfo.callUUID);
      }
      this.rejectedCall = true;
      // Set the call end time for visitors in their store
      if (this.isVisitor(this.ownUUID) || this.isWaitingRoomUser(this.ownUUID)) {
        store.setEndCallDateVisitor();
      }
    },
    getAvatarForUuid(userUUID) {
      return store.getAvatarForUuid(userUUID);
    },
    getNameForUuid(userUUID) {
      return store.getNameForUuid(userUUID);
    },
    getTitelForUuid(userUUID) {
      return store.getTitelForUuid(userUUID);
    },
    getEmailForUuid(userUUID) {
      return store.getEmailForUuid(userUUID);
    },
    getVisitorFunctionForUuid(userUUID) {
      return store.getVisitorFunctionForUuid(userUUID);
    },
    updateAttendanceList(userUUID, action){
      const callUUID = this.getCallUUID;
      const data = {user:userUUID,status:action}
      const confId = store.state.remoteBridgeStreams[callUUID].confId
      if(confId){
         updateOnlineStatusEvent([confId, callUUID])
      }
     
    },
    bridgeCallScreenShareState(active) {
      return bridgeCallScreenShareState(active);
    }    
  },
};
</script>
<style lang="scss">
.confWrapper:fullscreen .gridWrapperNormalMode {
  height: calc(100vh - 33px) !important;
}
.confWrapper:fullscreen .videBridgeContainer {
  height: calc(100vh - 23px) !important;
}
.confWrapper:fullscreen .gridWrapperNormalMode
.vid1 video, .vid1 .avatar,
.vid2 video, .vid2 .avatar,
.vid3 video, .vid3 .avatar,
.vid3_2 video, .vid3_2 .avatar,
.vid4 video, .vid4 .avatar,
.vid4_2 video, .vid4_2 .avatar,
.vid6 video, .vid6 .avatar,
.vid6_4 video, .vid6_4 .avatar,
.vidR1 video, .vidR1 .avatar,
.vidR2 video, .vidR2 .avatar,
.vidR3 video, .vidR3 .avatar,
.vidR3_2 video, .vidR3_2 .avatar,
.vidR4 video, .vidR4 .avatar,
.vidR4_2 video, .vidR4_2 .avatar,
.vidR6 video, .vidR6 .avatar,
.vidR6_4 video, .vidR6_4 .avatar
 {
  max-height: 100% !important;

}
.theme--light .videBridgeContainer {
  background-color: white;
}
.theme--dark .videBridgeContainer {
  background-color: #131313;
}
.videBridgeContainer {
  position: absolute;
  z-index: 999;
  width: 100%;
  &.withoutTimeLine{
    height: 100%;
  }
  &.withTimeLine{
    height: calc(100vh - 151px);
  }
  // height: 100vh;
  // 
  
}
.inWaitingRoom {
 height: calc(100vh - 99px) !important; 
}
.gridOneCallUser{
  height: calc(100vh - 29px) !important; 
}
.bridgeVideoRemote {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  width: 100%;
}
.bridgeVideoLocal {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  width: 100%;
}
.localContainer {
  position: relative;
  background: black;
  margin: 5px;
}
.remoteContainerChild {
  position: relative;
  background: black;
  margin: 5px;
}
.remoteContainer {
  display: contents;
}
</style>
