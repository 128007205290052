<template>
  <v-dialog v-model="showModal" persistent max-width="560">
    <template>
      <v-card class="mx-auto">
        <v-card-title class="headline" primary-title>
          <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
          {{$t('components.newVersionAvailable.title')}}
        </v-card-title>
        <v-card-text>
          {{$t('components.newVersionAvailable.text')}}
          <div class="mt-8 d-flex justify-center">
            <div class="mx-3 pointer">
              <v-btn
                class="primary"
                @click="reload()"
              >{{$t('components.newVersionAvailable.now')}} ({{ timeLeft }})</v-btn>
              <v-btn
                class="primary"
                @click="later()"
              >{{$t('components.newVersionAvailable.later')}}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { newVersionAvailableModalStore } from "../../effector/modals";
import { snoozeUpdateEvent } from '../../effector/version';

export default {
  data: () => ({
    timeLeft: null,
    reloadPage: false,
  }),
  effector: {
    showModal: newVersionAvailableModalStore,
  },
  methods: {
    reload(){
      if(!this.reloadPage){
        window.location.reload();
        this.reloadPage = true;
      }
    },
    later(){
      snoozeUpdateEvent();
    },
    tick() {
      this.timeLeft--;
    },
  },
  computed: {},
  watch: {
    timeLeft(val) {
      if (val === null) return;
      if (val <= 0) this.reload();
    }
  },
  mounted() {
    // console.log('newversion modal mounted');
    this.timeLeft = 60;
    this._interval = setInterval(this.tick.bind(this), 1000);
  },
  destroyed() {
    // console.log('newversion modal destroy');
    this.timeLeft = null;
    clearInterval(this._interval);
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>