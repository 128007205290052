var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"statsFontSize mb-0 mt-2",class:_vm.inNavbar ? 'displayInlineFlex':'font-weight-bold'},[[(_vm.showNumbers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.showUsersAvailableLength > 0),expression:"showUsersAvailableLength > 0"}],staticClass:"usersAvailable mx-2 cursorPointer",on:{"click":function($event){return _vm.goToStatusPage('available')}}},on),[_vm._v(_vm._s(_vm.showUsersAvailableLength))])]}}],null,false,2986559520)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.showUsersAvailableLength)+" \n        "+_vm._s(1 >= _vm.showUsersAvailableLength
            ? _vm.$t("components.navBarNumbers.usersOnline")
            : _vm.$t("components.navBarNumbers.usersOnlinePlural")))])]):_vm._e(),_vm._v(" "),(_vm.showNumbers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.showUsersCoffeBreakLength > 0),expression:"showUsersCoffeBreakLength > 0"}],staticClass:"usersCoffeBreak mx-2 cursorPointer",on:{"click":function($event){return _vm.goToStatusPage('coffee-break')}}},on),[_vm._v(_vm._s(_vm.showUsersCoffeBreakLength))])]}}],null,false,3686607015)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.navBarNumbers.usersCoffeBreak")))])]):_vm._e(),_vm._v(" "),(_vm.showNumbers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.showUsersNotAvailableLength > 0),expression:"showUsersNotAvailableLength > 0"}],staticClass:"usersNotAvailable mx-2 cursorPointer",on:{"click":function($event){return _vm.goToStatusPage('not-available')}}},on),[_vm._v(_vm._s(_vm.showUsersNotAvailableLength))])]}}],null,false,1684065656)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.navbar.statusMaybeAvailable")))])]):_vm._e(),_vm._v(" "),(_vm.showNumbers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.showUsersBusyLength > 0),expression:"showUsersBusyLength > 0"}],staticClass:"usersBusy mx-2 cursorPointer",on:{"click":function($event){return _vm.goToStatusPage('no-call')}}},on),[_vm._v(_vm._s(_vm.showUsersBusyLength))])]}}],null,false,3343204791)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("generics.noCall")))])]):_vm._e(),_vm._v(" "),(_vm.showNumbers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({staticClass:"usersHolidays mx-2 cursorPointer",on:{"click":function($event){return _vm.goToStatusPage('holidays')}}},on),[_vm._v(_vm._s(_vm.showUsersHolidaysLength))])]}}],null,false,4166845933)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.showUsersHolidaysLength)+" "+_vm._s(_vm.$t("components.navBarNumbers.usersAbsent")))])]):_vm._e(),_vm._v(" "),(_vm.showNumbers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.showUsersOfflineLength > 0),expression:"showUsersOfflineLength > 0"}],staticClass:"usersOffline mx-2 cursorPointer",on:{"click":function($event){return _vm.goToStatusPage('offline')}}},on),[_vm._v(_vm._s(_vm.getAdjustOffline))])]}}],null,false,77637038)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.getAdjustOffline)+" "+_vm._s(_vm.$t("components.navBarNumbers.usersOffline")))])]):_vm._e(),_vm._v(" "),(_vm.showNumbers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.showUsersListLength > 0),expression:"showUsersListLength > 0"}],staticClass:"mx-2  cursorPointer black--text",on:{"click":function($event){return _vm.goToStatusPage('total')}}},on),[_vm._v("\n          "+_vm._s(_vm.showUsersListLength)+"\n        ")])]}}],null,false,2025399873)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.showUsersListLength)+" "+_vm._s(_vm.$t("components.navBarNumbers.usersRegistered")))])]):_vm._e(),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({staticClass:"usersConnections mx-2 cursorPointer",on:{"click":function($event){return _vm.goToStatusPage('in-call')}}},on),[_vm._v(_vm._s(_vm.showTotalCall)+" / "+_vm._s(_vm.showTotalCallToday))])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("generics.inCallTT")))])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }