<template>
  <v-dialog v-model="callEndedInfoModal" persistent max-width="500">
    <template>
      <v-card class="mx-auto">
        <HeaderModal :titleModal="$t('components.beforeEndingCallModal.callEndedInfoTitle')" />
        <v-card-text>
          <div class="mt-2 d-flex justify-center">
            <div class="mx-3 pointer">
              {{$t('components.beforeEndingCallModal.callEndedInfo')}}
            </div>
          </div>
          <div class="mt-2 d-flex justify-center">
            <div class="mx-3 pointer">
              <v-btn class="primary" @click="closeModal">{{
                $t("components.userAgreementModal.ok")
              }}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import { callEndedInfoModalStore, setCallEndedInfoModalEvent } from "../../effector/modals";
export default {
    components: { HeaderModal },
    props: [],
    data: () => ({
        state: store.state,
    }),
    effector: {
        callEndedInfoModal : callEndedInfoModalStore
    },
    methods: {
        closeModal() {
            setCallEndedInfoModalEvent(false);
        }
    },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>