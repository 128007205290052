import {
  setTimelineEventInfoBatchedEvent,
  dispatchFetchTicketInfosEvent,
  setTimelineEventUUIDsEvent,
  newTimelineItemEvent,
  deleteTimelineItemEvent,
  dispatchFetchMyItemsForDateEvent,
  updateTimelineItemEvent,
  dispatchRsvpTimelineEntry,
  dispatchViewedTimelineEntry,
  dispatchColorChangeTimelineEntry,
  dispatchUpdatePdfTimeLineEntry,
  updateTimelineDurationItemEvent,
  setTimelineEventInfoEvent,
  resetAndRefetchDatesEvent,
} from './events';

import {
  fetchEventByUUIDEffect,
  fetchMyEventsEffect,
  createTimelineEntryEffect,
  deleteTimelineEntryEffect,
  updateTimelineItemEffect,
  updateTimelineItemDurationEffect,
  rsvpTimelineEntryEffect,
  viewedTimelineEntryEffect,
  updateTimelineEntryColorEffect,
  pdfTimelineEntryEffect
} from './effects';

import {
  unknownTimelineEvents,
  outdatedTimelineEvents,
} from './state';

import { aDelay, parallel } from '../../lib/asyncUtil';
import { wsConnectedEvent } from '../ws';
import { setEventStateModalEvent, eventModalStateStore } from '../modals';

import { dateToCalendarKey } from './util';

import {
  dispatchSuccessAlert,
  dispatchErrorAlert,
} from '../alerts';

import debounce from 'lodash.debounce';
import Vue from 'vue';

setTimelineEventInfoEvent.watch((payload) => {
  const [entryUUID, entryInfo] = payload;
  const modalInfo = eventModalStateStore.getState();

 
  if (modalInfo && modalInfo.uuid === entryUUID) {
    if ( entryInfo.eventType && entryInfo.eventType == 'directCall'){
      setEventStateModalEvent(entryInfo);
    }else{
      this.$router.push({ path: `/newconference/${this.entry.uuid}` });
    }
  }
});

setTimelineEventInfoBatchedEvent.watch((payload) => {
  const modalInfo = eventModalStateStore.getState();
  if (modalInfo && modalInfo.uuid && payload[modalInfo.uuid]) {
    const entryInfo = payload[modalInfo]
    if ( entryInfo.eventType && entryInfo.eventType == 'directCall'){
      setEventStateModalEvent(entryInfo);
    }else{
      this.$router.push({ path: `/newconference/${this.entry.uuid}` });
    }
  }
});

createTimelineEntryEffect.done.watch(({result, params}) => {
  if(result.users && result.users.length > 1){
    dispatchSuccessAlert(Vue.prototype.$t('components.eventEntryForm.savedEvent'));
  } 
});

createTimelineEntryEffect.fail.watch(({error, params}) => {
  // console.log('createTimelineEntryEffect', params, error);
  dispatchErrorAlert('Failed to created event:' + error.message);
  setEventStateModalEvent(params);
});

updateTimelineItemEffect.done.watch(({result, params}) => {
  if (result && !result.isTemp){
   dispatchSuccessAlert(Vue.prototype.$t('components.eventEntryForm.updatedSuccess')); 
  }
});

updateTimelineItemEffect.fail.watch(({error, params}) => {
  // console.log('updateTimelineItemEffect', params, error);
  dispatchErrorAlert('Failed to update event:' + error.message);
  // setEventStateModalEvent({ ...params[1], uuid: params[0] });
});

updateTimelineItemDurationEffect.done.watch(({params}) => { 
  const payload = [params[0], true];
  rsvpTimelineEntryEffect(payload);
  dispatchSuccessAlert(Vue.prototype.$t('components.eventEntryForm.updatedSuccess'));
});

updateTimelineItemDurationEffect.fail.watch(({error, params}) => { 
  // console.log('updateTimelineItemDurationEffect Fail', error, params);
  dispatchErrorAlert('Failed to update event duration:' + error.message);
});

deleteTimelineEntryEffect.done.watch(({result, params}) => {
 if (result && !result.isTemp){
   dispatchSuccessAlert(Vue.prototype.$t('components.eventEntryForm.deletedEvent'));
 } 
});

deleteTimelineEntryEffect.fail.watch(({error, params}) => {
  // console.log('deleteTimelineEntryEffect', params, error);
  dispatchErrorAlert('Failed to delete event:' + error.message);
});

dispatchViewedTimelineEntry.watch((payload) => {
  // rsvpTimelineEntryEffect(payload);
  viewedTimelineEntryEffect(payload);
});

dispatchColorChangeTimelineEntry.watch((payload) => {
  // rsvpTimelineEntryEffect(payload);
  updateTimelineEntryColorEffect(payload);
});

dispatchRsvpTimelineEntry.watch((payload) => {
  rsvpTimelineEntryEffect(payload);
  if (payload && payload.length){
    if(payload[1] == true){
      dispatchSuccessAlert(Vue.prototype.$t('components.conferenceForm.acceptedConference')); 
    } else {
      dispatchSuccessAlert(Vue.prototype.$t('components.eventEntryForm.declined')); 
    }
  }
  
});
dispatchUpdatePdfTimeLineEntry.watch((payload) => {
  pdfTimelineEntryEffect(payload);
});

/** Dates fetched and being fetched */
const coveredDates = [];

/** Count how many fetch events effects are pending */
let fetchMyEventsEffectsPending = 0;
fetchMyEventsEffect.watch(() => { fetchMyEventsEffectsPending++; });
fetchMyEventsEffect.finally.watch(() => { fetchMyEventsEffectsPending--; });

/** Returns a promise that waits until all current fetch events effects are done */
function fetchMyEventsEffectsDone(wait = 200) {
  let promise = Promise.resolve();
  if (fetchMyEventsEffect.pending.getState()) {
    // If we have events pending, create a new deferred promise
    let done = () => {};
    promise = new Promise((resolve) => (done = resolve));
    // Wait until all operations are done before resolving
    const unwatch = fetchMyEventsEffect.finally.watch(debounce(() => {
      // Debounced to prevent excessive calls in a short period of time
      if (!fetchMyEventsEffect.pending.getState()) {
        unwatch();
        done();
      }
    }, wait));
  }
  return promise;
}

// On connected, clear date cache and refresh the uuid list, both in case we missing something and to let signalling know what we need to get updates on
wsConnectedEvent.watch(() => {
  const copy = [...coveredDates];
  coveredDates.splice(0, coveredDates.length);
  // The first 31 days (the timeline) and the last 31 days (the last calendar viewed)
  // is all we should care about, otherwise it can become too big to handle.
  while (copy.length > 62) {
    const len = (copy.length % 31) || 31;
    copy.splice(31, len);
  }
  // console.log('wsConnectedEvent', copy, coveredDates);
  fetchMyEventsEffectsDone().then(() => copy.forEach(dispatchFetchMyItemsForDateEvent));
});

dispatchFetchMyItemsForDateEvent.watch((date) => {
  const actualDate = dateToCalendarKey(date);
  const needed = coveredDates.indexOf(actualDate) === -1;
  // console.log('dispatchFetchMyItemsForDateEvent', date, actualDate, needed);
  if (needed) {
    const length = coveredDates.push(actualDate);
    const addedAtIdx = length - 1;
    const workFunction = () => {
      // Prevent race-condition by checking our date is at the position it was added
      if (coveredDates.indexOf(actualDate) === addedAtIdx) {
        fetchMyEventsEffect(actualDate);
      }
    };
    // Dynamically limit number of concurrent fetch events effects
    if (fetchMyEventsEffect.pending.getState() && fetchMyEventsEffectsPending > 9) {
      // Apply throttling beginning at 10 or more pending events, with maximum 1.2 seconds additional wait.
      const wait = Math.min(100, (length + fetchMyEventsEffectsPending) * 3);
      const timeout = Math.max(900, wait * 12); // The timeout to prevent dead-lock
      Promise.race([aDelay(timeout), fetchMyEventsEffectsDone(wait)])
        .then(() => workFunction());
      // Note: fetchMyEventsEffectsPending only increases when workFunction/fetchMyEventsEffect is ran.
    } else {
      workFunction();
    }
  }
});

resetAndRefetchDatesEvent.watch(() => {
  const copy = [...coveredDates];
  coveredDates.splice(0, coveredDates.length);
  // The first 31 days (the timeline) and the last 31 days (the last calendar viewed)
  // is all we should care about, otherwise it can become too big to handle.
  while (copy.length > 62) {
    const len = (copy.length % 31) || 31;
    copy.splice(31, len);
  }
  // console.log('resetAndRefetchDatesEvent', copy, coveredDates);
  fetchMyEventsEffectsDone().then(() => copy.forEach(dispatchFetchMyItemsForDateEvent));
});

fetchMyEventsEffect.done.watch(({params, result}) => {
  // console.log('fetchMyEventsEffect done', params, result);
  if (coveredDates.indexOf(params) === -1) coveredDates.push(params);
  setTimelineEventUUIDsEvent(result);
});

let unknownFetchTimeout = undefined;
unknownTimelineEvents.watch(async (uuids) => {
  // console.log('unknownTimelineEvents', uuids);
  if (uuids.length) {
    if (unknownFetchTimeout) clearTimeout(unknownFetchTimeout);
    unknownFetchTimeout = setTimeout(() => dispatchFetchTicketInfosEvent(uuids), 1000);
  }
});

let outdatedFetchTimeout = undefined;
outdatedTimelineEvents.watch(async (uuids) => {
  // console.log('outdatedTimelineEvents', uuids);
  if (uuids.length) {
    if (outdatedFetchTimeout) clearTimeout(outdatedFetchTimeout);
    outdatedFetchTimeout = setTimeout(() => dispatchFetchTicketInfosEvent(uuids), 1000);
  }
});

dispatchFetchTicketInfosEvent.watch(async (uuids) => {
  if (!uuids.length) return; // Nope
  // console.log('dispatchFetchTicketInfosEvent', uuids, fetchEventByUUIDEffect.pending.getState(), fetchEventByUUIDEffect.inFlight.getState());
  if (fetchEventByUUIDEffect.pending.getState()) {
    // No need to fetch while fetching, new fetched things will trigger an update which will then fetch whatever new thing could be missing
    return;
  }
  const acc = {};
  await parallel(10, [...uuids], async (uuid) => {
    const info = await fetchEventByUUIDEffect(uuid);
    if (typeof info !== 'undefined' && info !== null) {
      acc[uuid] = info;
    }
  });
  const notEmpty = Object.keys(acc).length;
  // console.log('dispatchFetchTicketInfosEvent Fetched', notEmpty, acc);
  if (notEmpty) setTimelineEventInfoBatchedEvent(acc);
});

newTimelineItemEvent.watch((newEntryInfo) => {
  createTimelineEntryEffect(newEntryInfo);
});

deleteTimelineItemEvent.watch(uuid => {
  // console.log('deleteTimelineItemEvent', uuid);
  deleteTimelineEntryEffect(uuid);
});

updateTimelineItemEvent.watch(payload => {
  // console.log("updateTimelineItemEvent", payload);
  updateTimelineItemEffect(payload);
});

updateTimelineDurationItemEvent.watch(payload => updateTimelineItemDurationEffect(payload));
