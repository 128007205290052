<template >
  <v-dialog persistent v-model="showModalMessage" :fullscreen="true">
  <!-- <dialog-drag
    ref="modal"
    @move="move"
    v-show="showModalMessage"
    dragCursor="move"
    :options="options"
    :style="{maxWidth:'560px' ,position:'relative',}"
   
   
  > -->
    <v-card >
      <v-card-title
        class="headline"
        v-if="state.showModalSenMsg && state.showModalSenMsg.uuid"
      >
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        {{ modalTitle }}
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="messageForm" v-model="valid" lazy-validation :autocomplete="false">
        <v-row :style="{width: '100%', margin: 0}" class="px-5">
          <v-textarea
            counter
            contenteditable="true"
            :label="$t('components.sendMessageModal.message')"
            :rules="rules"
            v-model="state.tmpMessagetext"
            :no-resize="true"
            maxlength="250"
            autofocus
          ></v-textarea>
        </v-row>
        <!-- <v-row :style="{width: '100%', margin: 0}" class="px-5">
          <p style="font-size: 14px;margin: 0 auto;font-weight: 400;">* {{ $t('components.sendMessageModal.messageSpaceHint')}}</p>
        </v-row>  -->
        <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModalMobile">
          <v-row class="mx-0">
            <v-col lg="3" class="px-0 py-0" align="left">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    color="white"
                    class="btnRed mr-2"
                    @click="seeUserMessages()"
                  >
                    <font-awesome-icon :icon="['fal', 'eye']" :style="{ fontSize: '20px' }" />
                  </v-btn>
                </template>
                <span>{{ $t('components.sendMessageModal.seeYourMessages') }}</span>
              </v-tooltip>
              <v-menu
                v-if="1 == 2"
                :value="shown"
                top
                :offset-y="offset"
                :close-on-click="closeOnClick"
                :close-on-content-click="closeOnContent"
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="white" icon @click="shown = true">
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </template>
                <div style="width:425px; height:120px;background-color:white;">
                  <v-row
                    class="px-2 btns"
                    flex
                    :style="{width: '100%', margin: 0, 'margin-botton':'12px'}"
                  >
                    <v-text-field
                      style="margin: 2px 10px 2px 10px;"
                      hide-details
                      label="Text"
                      v-model="linkText"
                      autofocus
                    ></v-text-field>
                  </v-row>

                  <v-row
                    style="margin-top:12px;"
                    class="px-2 btns"
                    flex
                    :style="{width: '100%', margin: 0}"
                  >
                    <v-text-field
                      style="margin: 2px 10px 2px 10px;"
                      hide-details
                      label="Url"
                      v-model="linkUrl"
                    ></v-text-field>
                  </v-row>
                </div>
                <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
                  <v-col lg="12" class="px-0 py-0" align="right">
                    <v-btn color="primary" @click="linkifyme">
                    <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn @click="closeMenu" color="primary">
                      <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </v-col>
                </v-card>
              </v-menu>
            </v-col>
            <v-col lg="9" class="px-0 py-0" align="right">
              <v-btn
                color="primary"
                @click="closeModalMsg(undefined); resettmpMessagetext('')"
              >{{ $t('generics.cancel') }}</v-btn>
              <v-btn
                color="primary"
                @click="preparedataToSendMsg(state.showModalSenMsg.uuid, state.tmpMessagetext); closeModalMsg(); resettmpMessagetext('');"
                :disabled="(state.tmpMessagetext.length == 0 || !state.tmpMessagetext )"
              >{{ $t('components.sendMessageModal.send') }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
      <v-spacer></v-spacer>
    </v-card>
    </v-dialog>
</template>

<script>
import store, { EventBus } from "../../store";
import { sendOfflineMsg, sendMsg } from "../../lib/wsMsg.js";
import DialogDrag from "vue-dialog-drag";
import { setSendMessageModalEvent, setHistoryMessagesModalEvent } from "../../effector/modals"

export default {
  components: {
    DialogDrag
  },
  data: () => ({
    //dialog: false,
    state: store.state,
    ownUUID: store.state.ownUUID,
    valid: true,
    setCurrentContentVisile: store.setCurrentContentVisile,
    closeOnClick: true,
    closeOnContent: false,
    offset: true,
    shown: false,
    linkText: " Go Here ",
    linkUrl: "https://www.ibm.com",
    content: `
      <h1>Yay Headlines!</h1>
      <p>All these <strong>cool tags</strong> are working now.</p>
    `,
    rules: [v => !!v || "", v => !!v.trim() || "Message can not be blank"],
    // selectSection: store.state.showModalSenMsg.info.selectSection || [],
    // selectDepartments: store.state.showModalSenMsg.info.selectDepartments || [],
    // selectTeams: store.state.showModalSenMsg.info.selectTeams || [],
    // selectUsers: store.state.showModalSenMsg.info.selectUsers || []
    options: {
      width: 560,
      left: 550,
      top: -600,
      zIndex: 99999
    },
    contentWidth: 0,
    contentHeight: 0,
    previousHeight: 0
  }),

  methods: {
    sendFromBus: function(){
      if(store.state.showModalSenMsg){
        this.preparedataToSendMsg(
       store.state.showModalSenMsg.uuid,
       store.state.tmpMessagetext)
      }
      
    },
    seeUserMessages(ownUUID) {
      // store.state.user.subordinateActiveUser = ownUUID;
      this.state.showModalSenMsg = undefined;
      // console.log('see messages of ' , store.state.group[user].user.name);
      this.setCurrentContentVisile("messages", false, this.$router);
    },
    linkifyme() {
      let mssgbody = store.state.tmpMessagetext;
      let link =
        ' <a href="' +
        this.linkUrl +
        '" target="_blank">' +
        this.linkText +
        "</a>";
      store.state.tmpMessagetext += link;
      this.closeMenu();
    },
    handleModalSpace(evt){
        let e = evt || window.event,
        target = e.target || e.srcElement;
       // console.log('from send message modal', target.tagName.toUpperCase())
    },
    closeMenu() {
      this.shown = false;
    },
    move(d) {
      if (this.previousHeight == 0) {
        this.previousHeight = document.getElementsByClassName(
          "dialog-drag"
        )[0].previousElementSibling.previousElementSibling.offsetHeight;
        return;
      }

      if (d.left < 0) {
        this.options.left = 20;
        this.options.top = d.top;

        // console.log("ticketEntryModal::dropEnd - preventing left escape.");
        this.options = Object.assign({}, this.options);
      } else if (d.left > this.contentWidth - d.width - 20 - 320) {
        this.options.left = this.contentWidth - d.width - 20 - 320;
        this.options.top = d.top;

        // console.log("ticketEntryModal::dropEnd - preventing right escape.");
        this.options = Object.assign({}, this.options);
      } else if (d.top < 0 - this.previousHeight) {
        this.options.left = d.left;
        this.options.top = 0 - this.previousHeight;

        // console.log("ticketEntryModal::dropEnd - preventing top escape.");
        this.options = Object.assign({}, this.options);
      } else if (d.top > 0 - d.height - 20) {
        this.options.left = d.left;
        this.options.top = 0 - d.height - 20;

        // console.log("ticketEntryModal::dropEnd - preventing bottom escape.");
        this.options = Object.assign({}, this.options);
      }
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    preparedataToSendMsg(id, text) {
      if(text.trim().length === 0) return;
      const userToSend = [];
      const separate = this.separatedPeople();
      const mssgObject = this.state.showModalSenMsg || this.state.showCompSendMsg;    
     // console.log('mssgObject', mssgObject)

      if (mssgObject.options !== "") {
       // console.log(mssgObject);
        const option = mssgObject.options;
        const recipients = mssgObject.recipients;
       // console.log("Options ", option);
        switch (option) {
          case "teams":
            const teamsSelected = id;
            for (const index in separate.teams[teamsSelected]) {
              const uuid = separate.teams[teamsSelected][index];
              const isConnected = this.userIsConnected(uuid);
              if (
                isConnected &&
                uuid !== this.state.ownUUID &&
                userToSend.indexOf(uuid) == -1
              ) {
                userToSend.push(uuid);
              }
            }
            break;
          case "sections":
            const sectionsSelected = id;
            for (const index in separate.sections[sectionsSelected]) {
              const uuid = separate.sections[sectionsSelected][index];
              const isConnected = this.userIsConnected(uuid);
              if (
                isConnected &&
                uuid !== this.state.ownUUID &&
                userToSend.indexOf(uuid) == -1
              ) {
                userToSend.push(uuid);
              }
            }
            break;
          case "departments":
            const departmentsSelected = id;
            for (const index in separate.departments[departmentsSelected]) {
              const uuid = separate.departments[departmentsSelected][index];
              const isConnected = this.userIsConnected(uuid);
              if (
                isConnected &&
                uuid !== this.state.ownUUID &&
                userToSend.indexOf(uuid) == -1
              ) {
                userToSend.push(uuid);
              }
            }
            break;
          case "multUsers":
            if (recipients.length) {
              recipients.forEach(recipient => {
                userToSend.push(recipient);
              });
            }
            break;

          default:
            break;
        }
       // console.log(userToSend, "usertosend");
        for (const key in userToSend) {
          const uuid = userToSend[key];
          const header = {
            sendTo: {
              name: id,
              category: option
            }
          };
          // console.log(header, "header");
          if (this.userIsConnected(uuid)) {
            this.sendMsg(uuid, this.state.tmpMessagetext, header);
          } else {
            this.sendOfflineMsg(uuid, this.state.tmpMessagetext, header);
          }
        }
      } else {
        if (this.userIsConnected(id)) {
          this.sendMsg(id, text.trim());
        } else {
          this.sendOfflineMsg(id, text.trim());
        }
      }
      this.closeModalMsg();
      this.resettmpMessagetext("");
     
    },
    userIsConnected(uuid) {
      const users = this.state.group || {};
      if (users[uuid].connected) {
        return true;
      } else {
        return false;
      }
    },
    sendOfflineMsg(...arg) {
      return sendOfflineMsg(...arg);
    },
    sendMsg(...arg) {
      return sendMsg(...arg);
    },
    setShowModalSenMsg(v) {
      // return store.setShowModalSenMsg(v);
      // setSendMessageModalEvent({show:true, users: [v], type: 'regularMsg'})
      setHistoryMessagesModalEvent(v)
    },
    closeModalMsg() {
      return store.closeModalMsg();
    },
    resettmpMessagetext(v) {
      return store.resettmpMessagetext(v);
    },
    separatedPeople: function() {
      const result = { sections: {}, departments: {}, teams: {} };

      if (!store.state.namespaceSettings.processedOrganisation) {
        return result;
      }
      const sections =
        store.state.namespaceSettings.processedOrganisation.sections || {};
      const departments =
        store.state.namespaceSettings.processedOrganisation.departments || {};
      const teams =
        store.state.namespaceSettings.processedOrganisation.teams || {};

      if (Object.keys(sections).length > 0) {
        Object.keys(sections).forEach(orgKey => {
          if (!result.sections[orgKey]) result.sections[orgKey] = [];
          for (var i = 0; i < sections[orgKey].supervisors.length; i++) {
            if (
              this.ownUUID !== sections[orgKey].supervisors[i].uuid &&
              !result.sections[orgKey].includes(
                sections[orgKey].supervisors[i].uuid
              )
            ) {
              result.sections[orgKey].push(
                sections[orgKey].supervisors[i].uuid
              );
            }
          }

          for (var i = 0; i < sections[orgKey].departments.length; i++) {
            const departKey = sections[orgKey].departments[i];
            const department = departments[departKey];
            if (!result.departments[departKey])
              result.departments[departKey] = [];
            for (var j = 0; j < department.supervisors.length; j++) {
              if (
                this.ownUUID !== department.supervisors[j].uuid &&
                !result.sections[orgKey].includes(
                  department.supervisors[j].uuid
                )
              ) {
                result.sections[orgKey].push(department.supervisors[j].uuid);
              }
              if (
                this.ownUUID !== department.supervisors[j].uuid &&
                !result.departments[departKey].includes(
                  department.supervisors[j].uuid
                )
              ) {
                result.departments[departKey].push(
                  department.supervisors[j].uuid
                );
              }
            }

            for (
              var teamKey = 0;
              teamKey < department.teams.length;
              teamKey++
            ) {
              const team_key = department.teams[teamKey];
              const team = teams[team_key];
              if (!result.teams[team_key]) result.teams[team_key] = [];
              for (var j = 0; j < team.supervisors.length; j++) {
                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.sections[orgKey].includes(team.supervisors[j].uuid)
                ) {
                  result.sections[orgKey].push(team.supervisors[j].uuid);
                }

                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.departments[departKey].includes(
                    team.supervisors[j].uuid
                  )
                ) {
                  result.departments[departKey].push(team.supervisors[j].uuid);
                }

                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.teams[team_key].includes(team.supervisors[j].uuid)
                ) {
                  result.teams[team_key].push(team.supervisors[j].uuid);
                }
              }

              for (var j = 0; j < team.members.length; j++) {
                if (
                  this.ownUUID !== team.members[j] &&
                  !result.sections[orgKey].includes(team.members[j])
                ) {
                  result.sections[orgKey].push(team.members[j]);
                }

                if (
                  this.ownUUID !== team.members[j] &&
                  !result.departments[departKey].includes(team.members[j])
                ) {
                  result.departments[departKey].push(team.members[j]);
                }

                if (
                  this.ownUUID !== team.members[j] &&
                  !result.teams[team_key].includes(team.members[j])
                ) {
                  result.teams[team_key].push(team.members[j]);
                }
              }
            }
          }
        });
      }
      function sortObject(o) {
        return Object.keys(o)
          .sort((a, b) => {
            const str1 = a.toLowerCase();
            const str2 = b.toLowerCase();
            return (str1 > str2) - (str1 < str2);
          })
          .reduce((r, k) => ((r[k] = o[k]), r), {});
      }
      result.sections = sortObject(result.sections);
      result.departments = sortObject(result.departments);
      result.teams = sortObject(result.teams);
      return result;
    }
  },
  computed: {
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.breakpoint;
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : xl ? [4, 8] : [12, 12];
    },
    modalTitle() {
      let translateKey = "components.sendMessageModal.sendMessageTo";
      switch (this.state.showModalSenMsg.options) {
        case "multUsers":
          if (
            !this.state.showModalSenMsg.isAllMembers &&
            this.state.showModalSenMsg.recipients
          ) {
            translateKey = "components.sendMessageModal.sendMessageToTeamN";
            return this.$t(translateKey, [
              this.state.showModalSenMsg.recipients.length,
              this.state.showModalSenMsg.team ||
                this.getNameForUuid(this.state.showModalSenMsg.uuid)
            ]);
          }
        // fallthru intentional
        case "teams":
          translateKey = "components.sendMessageModal.sendMessageToTeam";
          break;
        case "sections":
          translateKey = "components.sendMessageModal.sendMessageToSection";
          break;
        case "departments":
          translateKey = "components.sendMessageModal.sendMessageToDepartment";
          break;
      }
      return this.$t(translateKey, [
        this.state.showModalSenMsg.team ||
          this.getNameForUuid(this.state.showModalSenMsg.uuid)
      ]);
    },
    showModalMessage() {
      return this.state.showModalSenMsg !== undefined;
    }
  }
};
</script>


<style scoped lang="scss">

.footerModalMobile{
position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    border-radius: 0px !important;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
</style>