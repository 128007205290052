<template>
  <v-dialog
    v-model="showBadQualityMessageModal"
    persistent
    max-width="560"
    @keydown.esc="closeModal"
  >
    <template>
      <v-card>
        <HeaderModal
          :titleModal="$t('components.votingModal.badQualityMessageTitle')"
          :closeModalFunction="closeModal"
        />
        <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
          <v-textarea
            counter
            contenteditable="true"
            :label="$t('components.sendMessageModal.message')"
            v-model="badMessage"
            :no-resize="true"
            maxlength="250"
            autofocus
          ></v-textarea>
        </v-row>
        <FooterModal
          :hideDefaultButtons="true"
          :closeModalFunction="closeModal"
          :showFooter="true"
        >
          <v-btn
            color="primary"
            @click="sendMessage()"
            :disabled="badMessage.trim().length === 0"
            >{{ $t("components.sendMessageModal.send") }}
          </v-btn>
        </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import {
  resetBadQualityMessageModalEvent,
  badQualityMessageModalStore,
} from "../../effector/modals";
import { telemetryLogMessageExported } from "../../lib/telemetry";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
    badMessage: "",
  }),
  effector: {
    showBadQualityMessageModal: badQualityMessageModalStore,
  },
  methods: {
    sendMessage() {
      telemetryLogMessageExported(this.badMessage, "ticket");
      resetBadQualityMessageModalEvent();
    },
    closeModal() {
      resetBadQualityMessageModalEvent();
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>