<template>
  <!-- Incoming call modal  -->
  <div>
    <v-dialog v-model="haveCalls" hide-overlay persistent max-width="550" class="incomingCallModalTest">
      <audio id="audioSource" ref="audioSource" :src="playingRingTone" autoplay loop></audio>
      <IncomingCallSub :key="call.uuid" v-for="call in incommingCallsArray" tabindex="0" :call="call" />
    </v-dialog>
    <v-dialog v-model="haveConferenceCalls" hide-overlay persistent max-width="550" class="incomingCallModalTest">
      <audio id="audioSource" ref="audioSource" :src="playingRingTone" autoplay loop></audio>
      <ConferenceIncomingCallSub :key="call.uuid" v-for="call in incommingConfCallsArray" tabindex="0" :call="call" />
    </v-dialog>
  </div>

  <!-- end incoming call modal -->
</template>

<script>
import store from "../../store";

import IncomingCallSub from "./incomingCallSub.vue";
import ConferenceIncomingCallSub from "./conferenceIncomingCallSub.vue";

export default {
  components: { IncomingCallSub, ConferenceIncomingCallSub },
  data() {
    return {
      state: store.state,
    };
  },
  mounted() {
    
  },
  methods: {

  },
  computed: {
    volumCallTone() {
      let volum = store.state.persisted.mediaDeviceSetup.volumeCallTone;
      if (isNaN(volum)) {
        volum = 100;
      }
      volum = volum / 100;
      return volum;
    },
    haveCalls: function() {
      // this.$refs.test.removeOverlay
      //console.log('incomming calls ' , this.incommingCallsArray)
      return this.incommingCallsArray.length > 0 ;
    },
    haveConferenceCalls: function(){
      return this.incommingConfCallsArray.length > 0 ;
    },
    incommingConfCallsArray: function() {
      const confCalls = [];
      // this.$nextTick(() => this.$refs.divModal.$el.focus())
      for (let uuid in store.state.incomingCallModal.calls) {
        const call = store.state.incomingCallModal.calls[uuid];
        if (!call) continue;
          if (call.isConference){
            confCalls.push({ ...call, uuid });
          }  
      }
      // console.log('incommingConfCallsArray ', confCalls)
      return confCalls.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
    incommingCallsArray: function() {
      const calls = [];
      // this.$nextTick(() => this.$refs.divModal.$el.focus())
      for (let uuid in store.state.incomingCallModal.calls) {
        const call = store.state.incomingCallModal.calls[uuid];
        if (!call) continue;
         if (!call.isConference){
            calls.push({ ...call, uuid });
         }
      }
      // console.log('incommingCallsArray ', calls)
      return calls.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
    playingRingTone() {
      if (!this.incommingCallsArray.length) return "";
      for (let call of this.incommingCallsArray) {
        if (this.$refs.audioSource) {
          this.$refs.audioSource.volume = this.volumCallTone;
        }
        if (
          store
            .getNameForUuid(call.uuid)
            .toLowerCase()
            .indexOf("lazy") !== -1
        ) {
          return "/media/incommingCallLazy.mp3";
        }
      }
      // getNameForUuid(call.uuid).toLowerCase().indexOf('lazy') !== -1 ? '/media/incommingCallLazy.mp3' : '/media/incommingCall1.mp3'
      return "/media/incommingCall1.mp3";
    }
  }
};
</script>
<style scoped lang="scss">
</style>
