import { render, staticRenderFns } from "./documentsMgr.vue?vue&type=template&id=808cf8a6&scoped=true"
import script from "./documentsMgr.vue?vue&type=script&lang=js"
export * from "./documentsMgr.vue?vue&type=script&lang=js"
import style0 from "./documentsMgr.vue?vue&type=style&index=0&id=808cf8a6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "808cf8a6",
  null
  
)

export default component.exports