var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"570"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModal.apply(null, arguments)}},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',[_c('v-row',{staticClass:"w-100 mx-0 my-0 justify-space-between",attrs:{"flex":""}},[_c('v-col',{staticClass:"px-0 py-0",attrs:{"align":"left"}},[_c('v-card-title',{staticClass:"headline"},[_c('v-img',{staticClass:"mr-1",attrs:{"src":"assets/icon.png","max-height":"30","max-width":"30","contain":""}}),_vm._v("\n          "+_vm._s(_vm.$t("components.2faModal.twoFaModalTitle"))+"\n        ")],1)],1)],1),_vm._v(" "),_c('v-card-subtitle',{staticClass:"pt-2"},[(!_vm.enabled)?_c('div',{staticClass:"py-2"},[_c('span',[_vm._v(_vm._s(_vm.$t("components.2faModal.subtitleSentence")))])]):_vm._e(),_vm._v("\n       \n      "),_c('v-row',{staticClass:"py-2 d-flex"},[_c('v-col',[(_vm.enabled)?[_vm._v("\n            "+_vm._s(_vm.$t("components.2faModal.twoFactorActiveAuthentication"))+"\n            "),(_vm.enabled === 'sms')?_c('span',[_vm._v(_vm._s(_vm.$t("components.2faModal.enabledSMS")))]):(_vm.enabled === 'pin')?_c('span',[_vm._v(_vm._s(_vm.$t("components.2faModal.enabledPIN")))]):(_vm.enabled === 'otp')?_c('span',[_vm._v(_vm._s(_vm.$t("components.2faModal.enabledOTP")))]):_vm._e()]:_vm._e(),_vm._v(" "),(!_vm.enabled)?_c('span',[_vm._v(_vm._s(_vm.$t("components.2faModal.twoFactorAuthentication"))+"\n            "),_c('v-tooltip',{attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"cursorPointer"},on),[_c('font-awesome-icon',{style:({
                      fontSize: '17px',
                      position: 'relative',
                      top: '2px',
                    }),attrs:{"color":"#02889d","icon":['fal', 'info-circle']}})],1)]}}],null,false,1517020684)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.2faModal.twoFactorAuthenticationInfo")))])]),_vm._v("\n            "+_vm._s(_vm.$t("components.2faModal.disabled")))],1):_vm._e()],2),_vm._v(" "),_c('v-col',{staticClass:"d-flex justify-end"},[(_vm.enabled === 'sms')?_c('span',[_vm._v(_vm._s(_vm.$t("components.2faSms.phoneNumber"))+" "+_vm._s(_vm.phoneNumber))]):_vm._e(),_vm._v(" "),(_vm.enabled)?_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.$t("components.2faModal.alternativeActivate")))]):_vm._e()])],1)],1),_vm._v(" "),(!_vm.enabled)?_c('v-row',{staticClass:"mx-auto",attrs:{"flex":""}},[_c('v-col',{staticClass:"mx-4 px-0 py-0"},[(_vm.state.namespaceSettings.activateSMS)?_c('v-tooltip',{attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary"},on:{"click":_vm.enableSMS}},on),[_vm._v(_vm._s(_vm.$t("components.2faModal.btnEnableSMS")))])]}}],null,false,2712001417)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.adminSettingsModal.2faActivateSMSTT")))])]):_vm._e(),_vm._v(" "),(_vm.state.namespaceSettings.activatePIN)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","min-width":"150px"},on:{"click":_vm.enablePIN}},on),[_vm._v(_vm._s(_vm.state.namespaceSettings.pinProtectionType === "complex"
                  ? _vm.$t("components.2faModal.btnEnablePINComplex")
                  : _vm.$t("components.2faModal.btnEnablePIN")))])]}}],null,false,3631801594)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.state.namespaceSettings.pinProtectionType === "complex"
              ? _vm.$t("components.2fa.complexTT")
              : _vm.$t("components.2fa.pinTT")))])]):_vm._e(),_vm._v(" "),(_vm.state.namespaceSettings.activatePassword)?_c('v-tooltip',{attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","min-width":"150px"},on:{"click":_vm.enableOTP}},on),[_vm._v(_vm._s(_vm.$t("components.2faModal.btnEnableOTP")))])]}}],null,false,1275541826)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.2fa.authenticatorTT")))])]):_vm._e()],1)],1):_c('v-row',{staticClass:"px-2 btns mx-0 mb-3",attrs:{"flex":""}},[_c('v-col',{staticClass:"px-2 py-2",attrs:{"align":"left"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.disable}},[_vm._v(_vm._s(_vm.$t("components.2faModal.btnDisable")))])],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-col',{staticClass:"px-2 py-2 d-flex"},[_c('v-tooltip',{attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(_vm.enabled !== 'sms' && _vm.state.namespaceSettings.activateSMS)?_c('v-btn',_vm._g({attrs:{"color":"primary"},on:{"click":function($event){return _vm.disable('2faSms')}}},on),[_vm._v(_vm._s(_vm.$t("components.2faModal.btnEnableSMS")))]):_vm._e()]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.adminSettingsModal.2faActivateSMSTT")))])]),_vm._v(" "),(_vm.enabled !== 'pin' && _vm.state.namespaceSettings.activatePIN)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.disable('2faPin')}}},[_vm._v(_vm._s(_vm.$t("components.2faModal.btnEnablePINShort")))]):_vm._e(),_vm._v(" "),_c('v-tooltip',{attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(
                _vm.enabled !== 'otp' && _vm.state.namespaceSettings.activatePassword
              )?_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.disable('2faOtp')}}},on),[_vm._v(_vm._s(_vm.$t("components.2faModal.btnEnableOTP")))]):_vm._e()]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.2fa.authenticatorTT")))])])],1)],1),_vm._v(" "),(_vm.shouldShowSetOwnTime)?_c('v-row',{staticClass:"mx-auto px-2",attrs:{"flex":""}},[_c('v-col',{staticClass:"mx-2 px-0 py-0"},[_c('p',{staticClass:"text-left subtitle-1",attrs:{"align":"left"}},[_vm._v("\n          "+_vm._s(_vm.$t("components.adminSettingsModal.twoFaLifetimeHours"))+"\n        ")]),_vm._v(" "),_c('v-overflow-btn',{staticClass:"my-2",attrs:{"items":_vm.gettwoFaLifetimeHoursByAdmin},on:{"change":_vm.setTwoFaLifetimeHours},model:{value:(_vm.selectedTwoFaLifetimeHours),callback:function ($$v) {_vm.selectedTwoFaLifetimeHours=$$v},expression:"selectedTwoFaLifetimeHours"}})],1)],1):_vm._e(),_vm._v(" "),_c('v-card',{staticClass:"py-2 pl-4 mt-4 pr-2 footerModal",style:(_vm.shouldAllowClose ? {} : { 'z-index': -1 }),attrs:{"color":_vm.shouldAllowClose ? 'primary' : _vm.isDark ? '' : 'white'}},[(_vm.shouldAllowClose)?_c('v-row',{staticClass:"px-2 btns mx-0",attrs:{"flex":""}},[_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"8","align":"right"}},[_c('v-btn',{staticStyle:{"border-radius":"4px","min-width":"45px"},attrs:{"icon":"","tile":"","outlined":"","color":"white"},on:{"click":function($event){return _vm.closeModal()}}},[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'times']}})],1)],1)],1):_vm._e()],1),_vm._v(" "),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }