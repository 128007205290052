<template>
  <div style="padding: 2px">
    <v-tooltip right>
      <template v-slot:activator="{ on }" >
      <v-checkbox
      class="mt-0 pt-0"
        v-model="checkBox"
        hide-details
        v-on="on"
        @click.stop.prevent="addUserToCallQueue"
        :disabled="(multiUserCallQueueStore.length+1)>18"
      ></v-checkbox>
        </template>
      <span> {{ $t('components.organisationGrid.addUserGroupCallTT') }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import store from "../../store";
import { multiUserCallQueueStore, setMultiUserCallQueueEvent, resetMultiUserCallQueueEvent } from "../../effector/multiUserCallQueue"
export default {
  props: ["person"],
  data() {
    return {
      state: store.state,
      checkBox: false
    };
  },
  effector: {
    multiUserCallQueueStore: multiUserCallQueueStore,
  },
  watch:{
    multiUserCallQueueStore: {
      immediate: true,
      handler: function (usersInArray) {
        this.checkUser(usersInArray);
      },
    },
  },
  mounted(){
    this.checkUser(this.multiUserCallQueueStore)
  },
  destroyed(){
    // if(this.multiUserCallQueueStore){
    //   resetMultiUserCallQueueEvent()
    // }
  },
  methods: {
    checkUser(usersInArray){
       if (usersInArray && this.person.user.uuid) {
          if(usersInArray.indexOf(this.person.user.uuid)!==-1){
            this.checkBox = true
          }else{
            this.checkBox = false
          }
        }else{
           this.checkBox = false
        }
    },
    addUserToCallQueue(){
      const userUuid= this.person.user.uuid
      if(!userUuid) return
      let userQueue = this.multiUserCallQueueStore? this.multiUserCallQueueStore : []
      if(userQueue.length>19) return
      const indexUser = userQueue.indexOf(userUuid)
      if(indexUser==-1){
        userQueue.push(userUuid)
      }else{
        userQueue.splice(indexUser, 1);
      }
      if(userQueue.length==0){
        resetMultiUserCallQueueEvent()
      } else{
        setMultiUserCallQueueEvent(userQueue)
      }
    }
  },
  computed: {
  },
};
</script>
<style scoped lang="scss">

</style>