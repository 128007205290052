<template>
  <v-list dense>
    <v-list-item-title class="primary--text flex-column d-flex px-2 pb-3">
      <!-- {{ $t("generics.users") }} -->
      <v-text-field
        class=""

          append-icon="mdi-magnify"
                :label="$t('generics.search')"
                single-line
                hide-details
        dense
        v-model="searchUserList"
      ></v-text-field>
    </v-list-item-title>

    <v-list-item-group
      v-model="selectedUserModel"
      @change="setSelectedUser(selectedUserModel)"
      color="primary"
      class="listWrapper"
    >
      <!-- <v-list-item
        v-if="getUserByUrlParam && !searchUserList"
        :key="`${getUserByUrlParam.uuid}-0`"
        :value="getUserByUrlParam.uuid"
      >
        <v-list-item-content>
          <v-list-item-title
            v-text="
              `${getTitelForUuid(getUserByUrlParam.uuid)}  ${getNameForUuid(
                getUserByUrlParam.uuid
              )}`
            "
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item
        v-for="(uuid, index) in usersList"
        :key="`${uuid}-${index + 1}`"
        :value="uuid"
      >
        <v-list-item-content>
          <v-list-item-title
            v-text="`${getTitelForUuid(uuid)}  ${getNameForUuid(uuid)}`"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
// import debounce from "lodash.debounce";
import store from "../../../store";
import { showDailyReportList } from "../../../utils/basicFunctions";
export default {
  props: ["selectedUser", "setSelectedUser"],
  components: {},
  data() {
    return {
      state: store.state,
      searchUserList: "",
      selectedUserModel: "",
    };
  },
  mounted() {
    // if(!this.hasUrlParam){
    this.setSelectedUser(this.usersList[0]);
    // }
  },
  watch: {
    // getUserByUrlParam: {
    //   handler: debounce(function (user) {
    //     if (user && user.uuid) {
    //       this.setSelectedUser(user.uuid)
    //     }
    //   }, 250),
    // },
    selectedUser: {
      handler: function (uuid) {
        this.selectedUserModel = uuid;
      },
    },
  },
  methods: {
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {
    usersList() {
      const temporalUsers = showDailyReportList();
        let users = temporalUsers.filter((item,index) => {
        return temporalUsers.indexOf(item) === index;
      })
      // let users = [
      //   "28f5236d9df9964a91473a58049255b8",
      //   "e545d7e24a1d9de6ce81900fe3b4dc3f",
      //   "fe2be8c0773c5cc9ad70d405e53a0063",
      //   "43d4a1076a870734b3e7b302e9c420e5",
      //   "7cdd5fee2ccead126960a81aa66d859e",
      //   "c25d0cc618b668f37f7a8f2b7a7ef0f7",
      //   "fda6117776587d55038f3c8992783ec4",
      //   "632ee61849c6ecfcb5ae6cbb942f4107",
      //   "f5b274426e8c05f88df364d6ca85404c",
      //   "dd558428ce466cf91492ef9bd115a760",
      //   "97e6c5c3babfcbe65c61142def46b46b",
      //   "73cdaa47453a65890ba8426ed287f182",
      //   "e2625e3c01f0359117317005ed3fa0cb",
      //   "d1e9324fb2306aeb8466dbc819e6c7de",
      //   "14ac7cded84997881a0233176182c7ae",
      //   "c0f933b84e63c12a43d3b336d0cee283",
      //   "e03a50b54457175740a79f959f916c2e",
      //   "29e46e78b845154ef248085c7678e62f",
      //   "e0ae4e5e60f8c3b170deaeab0edf8d4a",
      //   "06730882f0e6478ad971da1289978283",
      //   "237d42b39ecb346a1943e823bc47004f",
      //   "134a5182d381ef987fce70856841b5a7",
      //   "8ecf2797300da2bc57a0968f539558a3",
      //   "8f4a233bd63f7a2c226db58b9ac580cf",
      //   "227e91d5d20e64e5abf0504b6ee4a1d5",
      //   "fabdb52f0a592fc67d06670a36cb1aca",
      //   "42df21b7eebc98a9b581afdfdebbc624",
      //   "65de48c5c0bfcc24c7a08ff8e1f6c6e0",
      //   "ca0e91c3fda2163973a9a9aec10bb3bb",
      //   "25815eb367e9dbdb715480bd94e4fa8b",
      //   "698efc0f5b6fa8960d807cd402816cab",
      //   "d395fbd6d6ce2db33ff283844223ac83",
      //   "3d87cb892db906efbc0552a763c191b2",
      //   "68a91aafc65622880166b47bb88996f0",
      //   "300acfdb450d158621788fe9b48e5c73",
      //   "2fd6379c7c7de694798f4b6f02bb831a",
      //   "1d704ac76d81b33649c94c92bfde0326",
      //   "91683a0279e670d16d665ddfacc9e89d",
      //   "30decc5b968c6e136b0c8ffeac933117",
      //   "63ce429b0b5ad67cfa10e65515f2ebde",
      //   "41de8aa14f0e2c9cf98e652ccb33bf3c",
      //   "9ac72b6df7058ad4fc6bef1d76297696",
      //   "58a13bcb398ddec57d7c29d8cc15c18e",
      //   "ad224e25422ad880394978acc329d2b1",
      //   "9d27a6d0cecff9bf24af6a927e9b881f",
      //   "f471b65f1c2dad7c93fce15f5f420509",
      //   "7df33ed79ea6beaf8b70b830fa99a3bd",
      //   "2bf8f72f4ae006f5bc58bfa723da08f4",
      //   "5c80c4120f6d3891e8fecc8128abdc2b",
      //   "69667cf4d93e03db2ec72e683d0bb74b",
      //   "a0cbb0e1bd995c6c250a081edc959352",
      //   "2c32ffb577f454f7ffa2e76f0e17f0fa",
      //   "74c5a3e6cd36641e4a32c7df6ee72152",
      //   "0e803fe6970422ccfb02c2ae0e4ecc4d",
      //   "3f5e60383afeb4066ea6c55a415924c9",
      //   "30ff9bba91c51d1a700dc37f30b2415e",
      //   "25b405f7ec5d4520787cbfb344c5ccba",
      //   "c7fad4a2eba266abb1c17dfa1eb5749c",
      //   "3cc8ed6a0ccb8c2bd1214718d590c604",
      //   "625bdd51cb068737f1966866f3924d3a",
      //   "10bc97e1bdf9fd53ea8f9e6019546219",
      //   "8c01a9178acff6a1ff6dc29cee80cf3c",
      //   "1ff8b73756ad6d3943050cf9be2917fa",
      //   "57a11c787540ad8d116dd0910e621ea5",
      //   "b1121e0b7dfa04ba33ec3559a4bc00bf",
      //   "c6ee31abe436809d4c36e86d8ae73392",
      //   "7db4cb2cc298a7f5558e24d010297edc",
      //   "696a7f7798cf9cfaf33c9e62743ddb31",
      //   "daa6cb22e932d8f0c05b773c6bd11977",
      //   "13c3d89d1cfe6d3bd58e43a7250ebc8d",
      //   "8ae105c6995928457de72a607c95be40",
      //   "911ab8f66d6a8d05f204922b9b150940",
      //   "49c376d0e6b868c96b585db9f5af4a5f",
      //   "15d090a0889feef6808cc12752f84a31",
      //   "b65f877f5fcadf1cd71206efc63ed821",
      //   "29c5ebe7bd9de05122f3abae58dd32bf",
      //   "6f9a8957d1db8b2af3c5eb9aae946b87",
      //   "ef7725850bacb701f5efab8925b326a8",
      //   "62dd60192231452023428b0886ab9847",
      //   "2e6a04edd7b474d984db00edf441b9c3",
      //   "1f0005abb23ccf2a026be9d64ada2d40",
      //   "145fd187b775915e85e585ac60e9b7f0",
      //   "cab906240f9394f94237a4a20d31ec57",
      //   "3d243e6ab6a4f74feeeb58df3071820b",
      //   "5fbbc696638689a4cd3c802f84c6c34f",
      //   "f10372f774b2cdf8ab2eb6663c293ff7",
      //   "86f4f0917efc10e56f55c02d57e49db4",
      //   "0a11c7b3d8400ef39c6f80fd8bc25054",
      //   "58214f4e02198b468499fdc36f54b349",
      //   "17e58e280126466ec52aab63768faf67",
      //   "860f94724d6fcae7a4b727a27ffb74df",
      //   "a2710187d75904cd516f8953ce46d144",
      //   "cb2e8f60b8219b5aa5a65c786e3481e7",
      //   "ad8b9ae939821d473feabf3b3653336c",
      //   "9385e8aba059478a304a2b3f08b504c0",
      //   "638c97f573427157bb91569e45ee2ec7",
      //   "d15bbb99fdb63618f1befbaa0c52a52c",
      //   "0f6e0c622f4571491b83d7a08a0a1565",
      //   "96758f918371191238b3978258d852f1",
      //   "444fd2c0d1ea3fa22299b44441f57600",
      //   "0a11c7b3d8400ef39c6f80fd8bc25054",
      // ];
      return users.filter((uuid) => {
        // if (!this.getUserByUrlParam && !this.searchUserList) return uuid;
        if (!this.searchUserList) return uuid;
        if (this.searchUserList) {
          return (
            this.getTitelForUuid(uuid)
              .toLowerCase()
              .includes(this.searchUserList.toLowerCase()) ||
            this.getNameForUuid(uuid)
              .toLowerCase()
              .includes(this.searchUserList.toLowerCase())
          );
        }
        // return this.getUserByUrlParam.uuid !== uuid;
      });
      // .sort((a, b) => {
      //   if (this.getNameForUuid(a) && this.getNameForUuid(b)) {
      //     let fa = `${this.getTitelForUuid(a)} ${this.getNameForUuid(
      //         a
      //       ).toLowerCase()}`,
      //       fb = `${this.getTitelForUuid(b)} ${this.getNameForUuid(
      //         b
      //       ).toLowerCase()}`;

      //     if (fa < fb) {
      //       return -1;
      //     }
      //     if (fa > fb) {
      //       return 1;
      //     }
      //   }
      //   return 0;
      // });
    },
    // hasUrlParam() {
    //   return this.$route.params.uuid;
    // },
    // getUserByUrlParam() {
    //   if (!this.hasUrlParam) return false;
    //   return (
    //     this.state.group[this.hasUrlParam] &&
    //     this.state.group[this.hasUrlParam].user
    //   );
    // },
  },
};
</script>
<style scoped lang="scss">
.listWrapper {
  overflow-y: auto;
  min-height: calc(100vh - 25vh);
  max-height: calc(100vh - 25vh);
  height: calc(100vh - 25vh);
}
</style>