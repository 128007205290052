<template>
  <v-dialog v-model="showModal" persistent max-width="560">
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('components.beforeEndingCallModal.title')"
        />
        <v-card-text>
          {{
            amIOwnerMeeting
              ? $t("components.beforeEndingCallModal.text")
              : $t("components.beforeEndingCallModal.textRegularUser")
          }}
          <div class="mt-8 d-flex justify-center" v-if="amIOwnerMeeting">
            <div class="mx-3 pointer">
              <v-btn class="primary" @click="addMoreTime()">{{
                $t("components.userAgreementModal.ok")
              }}</v-btn>
              <v-btn class="primary" @click="endCall()">{{
                $t("components.beforeEndingCallModal.end")
              }}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import {
  resetBeforeEndingCallModallEvent,
  beforeEndingCallModalStore,
} from "../../effector/modals";
import { callAddDurationEvent } from "../../effector/call";
import { rtcHangup } from "../../lib/rtcConn";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
export default {
  components: { HeaderModal },
  data: () => ({
    state: store.state,
  }),
  watch: {
    "state.durationMeeting": {
      handler() {
        if (this.showModal) {
          this.closeModal();
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.closeModal();
    }, 5000);
  },
  effector: {
    showModal: beforeEndingCallModalStore,
  },
  methods: {
    addMoreTime() {
      callAddDurationEvent(300000);
    },
    endCall() {
      this.hangupUp();
      this.ringAudioFinalCall();
      this.closeModal();
    },
    hangupUp() {
      return rtcHangup();
    },
    closeModal() {
      resetBeforeEndingCallModallEvent();
      // add reset modal
    },
    ringAudioFinalCall() {
      try {
        const audio = new Audio("/media/endCall.mp3");
        audio
          .play()
          .catch((error) =>
            console.warn("Failed to play notification audio", error)
          );
      } catch (error) {
        console.warn("Failed to play notification audio", error);
      }
    },
  },
  computed: {
    amIOwnerMeeting() {
      return this.state.ownerMeeting;
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>