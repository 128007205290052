<style scoped>
/* @import 'splitpanes/dist/splitpanes.css'; */
</style>


<template>
  <div
    style="z-index: 999"
    id="video-container"
    class="absolute"
    v-bind:class="{
      'd-none':
        state.currentContentVisile.showComponent ||
        Object.keys(state.remoteStreams).length === 0,
    }"
  >
  <!-- v-if="state.durationMeeting" -->
    <div class="progressBar" v-if="!infiniteCall">
      <!-- <ProgressLinear
        :click="()=>addMoreTime()"
        :classeProgress="`${(state.currentTS - state.meetingStartAt) >= (state.durationMeeting - 60000) ? 'progressBarBlink': ''}`"
        :value="meetingTime"
        :timerCall="timerCall"
        :elapseTime="elapseTime"
        :originalCallTime="formatTime(state.durationMeeting)"
      /> -->
      <ProgressLinear />
      <!-- <v-progress-linear
        @click="addMoreTime()"
        background-color="#6dbac6"
        height="24"
        color="barGradient"
        :class="`${(state.currentTS - state.meetingStartAt) >= (state.durationMeeting - 60000) ? 'progressBarBlink': ''}`"
        :value="meetingTime"
      >
      <template>-->
      <!-- <strong>{{ $t('components.callsContent.prospected') }} {{(state.durationMeeting/1000)/60}} {{ $t('literals.Minutes') }} / {{ $t('components.callsContent.talkTime') }} {{(((state.currentTS - state.meetingStartAt)/1000)/60).toFixed(2)}} {{ $t('literals.Minutes') }}</strong>-->
      <!-- <strong class="white--text">{{timerCall}}</strong>
            <strong class="white--text" style="position:absolute; left:2px">{{originalCallTime}}</strong>
        </template>
      </v-progress-linear>-->
    </div>
    <splitpanes
      horizontal
      :class="`default-theme ${!this.infiniteCall ? ' mt-6' : ''}`"
      :style="`${!this.infiniteCall ? 'height: 97%' : 'height: 100%'}`"
    >
      <pane>
        <splitpanes @resize="resizePane($event)">
          <pane
            :size="
              Object.keys(state.remoteStreams).length == 1 ? actualSize.me : 50
            "
          >
            <div id="localVideoDiv" ref="localVideoDiv" class="relative localVideo">
              <!-- <div
                class="messageToParticipants messageToParticipantsBlink"
                v-if="state.messageToParticipants.audio || state.messageToParticipants.sound"
              >
                <div v-if="state.messageToParticipants.audio">{{state.messageToParticipants.audio}}</div>
                <div v-if="state.messageToParticipants.sound">{{state.messageToParticipants.sound}}</div>
              </div> -->
              <div
                class="messageToParticipants messageToParticipantsBlink"
                v-if="isLocalAudioMuted || amISoundMuted"
              >
                <div v-if="isLocalAudioMuted">
                  {{ $t("components.callsContent.audioMessage") }}
                </div>
                <div v-if="amISoundMuted">
                  {{ $t("components.callsContent.soundMessage") }}
                </div>
              </div>
              <div class="callerName">
                <span
                  >{{
                    isVisitor(state.ownUUID)
                      ? $t("components.userSettings.guest") + " - "
                      : ""
                  }}
                  {{ getTitelForUuid(state.ownUUID) }}
                  {{ getNameForUuid(state.ownUUID) }}
                  <!-- {{
                    callInfo.ownerUUID === state.ownUUID ? " - Moderator" : ""
                  }} -->
                  </span
                >
                <!-- v-if="ownerUUID === state.ownUUID" -->


                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span
                      v-on="on"
                      class="italicFont"
                      style="float: right; margin-right: 9px"
                    >
                      P2P</span
                    >
                  </template>
                  <span>{{ $t("components.remotePersonCall.p2p") }}</span>
                </v-tooltip>

                <!-- <v-tooltip top v-if="callInfo.ownerUUID === state.ownUUID">
                  <template v-slot:activator="{ on }">
                    <span v-on="on" style="float: left;margin-left:9px" >
                      <font-awesome-icon
                        v-on="on"
                        :icon="['fal', 'users-crown']"
                        :style="{ fontSize: '20px' }"
                      />
                    </span>
                  </template>
                  <span>{{ $t("components.remotePersonCall.moderator") }}</span>
                </v-tooltip> -->
                <span style="float: left;margin-left:9px"  v-if="callInfo.ownerUUID === state.ownUUID" >Moderator</span>
                <v-tooltip top v-if="callInfo.ownerUUID !== state.ownUUID && ( !isWaitingRoomUser(state.ownUUID) && !isVisitor(state.ownUUID) )">
                  <template v-slot:activator="{ on }">
                    <span v-on="on" style="float: left;margin-left:9px" >
                      <font-awesome-icon
                        v-on="on"
                        :icon="['fal', 'user-crown']"
                        :style="{ fontSize: '20px' }"
                      />
                    </span>
                  </template>
                  <span>{{ $t("components.remotePersonCall.user") }}</span>
                </v-tooltip>

                <v-tooltip top v-if="callInfo.ownerUUID !== state.ownUUID && ( isWaitingRoomUser(state.ownUUID) || isVisitor(state.ownUUID) )">
                  <template v-slot:activator="{ on }">
                    <span v-on="on" style="float: left;margin-left:9px" >
                      <font-awesome-icon
                        v-on="on"
                        :icon="['fal', 'user-friends']"
                        :style="{ fontSize: '20px' }"
                      />
                    </span>
                  </template>
                  <span>{{ $t("components.remotePersonCall.guest") }}</span>
                </v-tooltip>

              </div>

              <!-- <div class="microphoneLevel">
                  <font-awesome-icon
                    :icon="['fal', 'microphone']"
                    class="microphonoLevelIcon"
                    v-if="!isLocalAudioMuted"
                  />
                  <font-awesome-icon
                    :icon="['fal', 'microphone-slash']"
                    class="microphonoLevelIcon microphonoLevelIconSlash"
                    v-if="isLocalAudioMuted"
                  />
                  <canvas id="canvasAudioCallsContent" width="50" height="15" :class="{hidden: isLocalAudioMuted}"/>
         
              </div> -->
              <div>
                <AudioStreamMeter
                  :canvasId="'canvasAudioCallsContent'"
                  :stream="state.localStreams.user"
                />
                <AudioStreamMeter
                  :canvasId="'canvasAudioCallsContent'"
                  :stream="state.localStreams.display"
                />
              </div>
              <div class="divSharingScreen" v-if="state.localStreams.display">
                <video
                  id="local-video"
                  v-if="!this.state.persisted.useBlurEffect"
                  playsinline
                  autoplay="autoplay"
                  :src-object.prop.camel="state.localStreams.user"
                  v-bind:class="{ 'd-none': !haveLocalVideo }"
                  muted
                ></video>
                <StreamCanvas
                  id="local-video"
                  v-if="this.state.persisted.useBlurEffect"
                  :stream="state.localStreams.user"
                  v-bind:class="{ 'd-none': !haveLocalVideo }"
                ></StreamCanvas>
              </div>
              <!-- <span v-if="state.ownUUID === ownerUUID" class="italicFont videoIdTag">{{callInfo.callUUID || ''}}</span> -->
              <video
                id="local-video"
                v-if="
                  !state.localStreams.display &&
                  !this.state.persisted.useBlurEffect
                "
                playsinline
                autoplay="autoplay"
                :src-object.prop.camel="state.localStreams.user"
                v-bind:class="{ 'd-none': !haveLocalVideo }"
                muted
              ></video>
              <StreamCanvas
                id="local-video"
                v-if="
                  !state.localStreams.display &&
                  this.state.persisted.useBlurEffect
                "
                v-bind:class="{ 'd-none': !haveLocalVideo }"
                :stream="state.localStreams.user"
              ></StreamCanvas>

              <video
                id="local-screen"
                playsinline
                autoplay="autoplay"
                :src-object.prop.camel="state.localStreams.display"
                v-bind:class="{ 'd-none': !state.localStreams.display }"
                muted
              ></video>
              <div
                class="noCameraStream"
                v-bind:class="{ 'd-none': haveLocalVideo }"
              >
                <div class="noCameraContent">
                  <v-img
                    class="imageNotVideo"
                    contain
                    :src="getAvatarFromUuid(state.ownUUID)"
                    lazy-src="assets/default_profile_picture.png"
                  ></v-img>
                  <div class="emptyDiv"></div>
                </div>
              </div>
              <div
                class="testMeSomehow"
                v-if="showTypingInfo.show"
                :class="{ active: showTypingInfo.show }"
              >
                {{ getNameForUuid(showTypingInfo.from) }}
                {{ $t("components.callsContent.isTyping") }} ...
              </div>
              <div
                v-bind:class="{
                  hidden: Object.keys(state.remoteStreams).length == 0,
                  buttonsIpad: isIpad && isIos,
                }"
                class="buttonsUser"
              >
                <!-- <div class="divButtonsLocal"> -->
                <template>
                  <v-list v-if="openCameraQuality" class="pt-0 cameraQuality">
                    <v-list-item-group color="primary">
                      <div class="d-flex justify-end blueBackground">
                        <v-btn icon @click="openCameraQuality = false">
                          <font-awesome-icon
                            :icon="['fal', 'times']"
                            :style="{ fontSize: '15px' }"
                            v-if="state.persisted.rtcVideo"
                            color="white"
                          />
                        </v-btn>
                      </div>
                      <v-list-item @click="setQuality('high')">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            $t("components.camAndMic.high")
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="setQuality('medium')">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            $t("components.camAndMic.medium")
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="setQuality('low')">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            $t("components.camAndMic.low")
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </template>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="white"
                      icon
                      v-long-press="300"
                      @long-press-start="openMenuQuality"
                      @long-press-stop="onLongPressStop"
                      @click="tryWebcam()"
                      class="mx-3"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'video']"
                        :style="{ fontSize: '20px' }"
                        v-if="state.persisted.rtcVideo"
                      />
                      <font-awesome-icon
                        :icon="['fal', 'video-slash']"
                        :style="{ fontSize: '20px' }"
                        v-if="!state.persisted.rtcVideo"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("generics.camera") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <!-- v-if="state.ownerMeeting || Object.keys(state.remoteStreams).length == 1" -->
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="white"
                      icon
                      @click="toggleMuteLocalAudio()"
                      :disabled="amIMutedByGlobal"
                      class="mx-3"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'microphone']"
                        :style="{ fontSize: '20px' }"
                        v-if="!isLocalAudioMuted"
                      />
                      <font-awesome-icon
                        :icon="['fal', 'microphone-slash']"
                        :style="{ fontSize: '20px' }"
                        v-if="isLocalAudioMuted"
                      />
                      <canvas
                        id="canvasAudioCallsContent"
                        width="50"
                        height="15"
                        :class="{ hidden: isLocalAudioMuted }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.callsContent.mic") }}</span>
                </v-tooltip>
                <v-tooltip
                  top
                  v-if="
                    state.ownerMeeting ||
                    Object.keys(state.remoteStreams).length == 1
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="white"
                      icon
                      @click="toggleMuteSound()"
                      class="mx-3"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'volume-up']"
                        :style="{ fontSize: '20px' }"
                        v-if="!isLocalSoundMuted"
                      />
                      <font-awesome-icon
                        :icon="['fal', 'volume-slash']"
                        :style="{ fontSize: '20px' }"
                        v-if="isLocalSoundMuted"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.callsContent.loudspeaker") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="white"
                      icon
                      class="redBtn mx-3"
                      @click="handleHangUp()"
                      ref="cancelCall"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'phone']"
                        class="faPhoneRotate"
                        :style="{ fontSize: '20px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("generics.hangUp") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="white"
                      icon
                      @click="
                        state.localStreams.display
                          ? closeDisplayMedia()
                          : tryScreenShare()
                      "
                      class="mx-3"
                      :disabled="!callInfo.callActive"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'desktop']"
                        :style="{ fontSize: '20px' }"
                      />
                      <font-awesome-icon
                        :icon="['fal', 'slash']"
                        :style="{
                          fontSize: '20px',
                          position: 'absolute',
                          top: -1,
                          left: 5,
                        }"
                        v-if="!state.localStreams.display"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.callsContent.shareScreen") }}</span>
                </v-tooltip>

                <v-tooltip top v-if="callInfo.ownerUUID === state.ownUUID">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="white"
                      icon
                      @click.stop.prevent="
                        hasOnHold ? unholdCall() : holdCall()
                      "
                      class="mx-3"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'long-arrow-up']"
                        :style="{ fontSize: '20px' }"
                        :class="{ progressBarBlink: hasOnHold }"
                        :color="hasOnHold ? 'red' : 'white'"
                      />
                      <font-awesome-icon
                        :color="hasOnHold ? 'red' : 'white'"
                        :class="{ progressBarBlink: hasOnHold }"
                        :icon="['fal', 'long-arrow-up']"
                        class="rotatedArrow"
                      />
                      <!-- <font-awesome-icon :class="{'progressBarBlink': hasOnHold}" :icon="['fal', 'long-arrow-up']" class="rotatedArrow" :style="{ fontSize: '20px' }" :color="hasOnHold ? 'red' : 'white'"/> -->
                    </v-btn>
                  </template>
                  <span>{{ $t("components.userListItem.forwarding") }}</span>
                  <!-- <span>{{ hasOnHold ? $t('components.remotePersonCall.resume') : $t('components.remotePersonCall.hold') }}</span> -->
                </v-tooltip>

                <!-- <v-tooltip top v-if="state.ownerMeeting && hasOnHold">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="white"
                      icon
                      @click.stop.prevent="unholdCall()"
                      class="mx-3"
                    >
                      <font-awesome-icon :icon="['fal', 'play']" :style="{ fontSize: '20px' }" />
                    </v-btn>
                  </template>
                  <span>Resume call</span>
                </v-tooltip> -->

                <!-- <v-tooltip top v-if="state.ownerMeeting && hasOnHold">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="white"
                      icon
                      @click.stop.prevent="transferCall()"
                      class="mx-3"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'long-arrow-up']"
                        :style="{ fontSize: '20px' }"
                      />
                      <font-awesome-icon :icon="['fal', 'long-arrow-up']" class="rotatedArrow" />
                    </v-btn>
                  </template>
                  <span>{{ $t('components.userListItem.forwarding') }}</span>
                </v-tooltip> -->

                    <!-- Object.keys(state.remoteStreams).length == 1 && -->
                <v-tooltip
                  top
                  v-if="
                    state.ownerMeeting
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="white"
                      icon
                      @click.stop.prevent="setPresentationView()"
                      class="mx-3"
                    >
                      <img
                        class="imgButton"
                        src="/img/icons/voffice_viewB.svg"
                      />
                      <!-- <font-awesome-icon :icon="['fal', 'user-chart']" :style="{ fontSize: '20px' }" /> -->
                    </v-btn>
                  </template>
                  <span>{{
                    $t("components.meetingTime.presentationView")
                  }}</span>
                </v-tooltip>
                <!-- <v-tooltip top v-if="isBlurEffectEnabled">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="white"
                      icon
                      @click.stop.prevent="toggleUseBlurEffect"
                      class="mx-3"
                    >
                      <img
                        v-if="!state.persisted.useBlurEffect"
                        src="/img/vo_blur.svg"
                        style="width: 30px"
                      />
                      <img
                        v-if="state.persisted.useBlurEffect"
                        src="/img/vo_no-blur-2.svg"
                        style="width: 30px"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.callsContent.blurEffect") }}</span>
                </v-tooltip> -->
                <v-tooltip
                  top
                  v-if="
                    state.ownerMeeting &&
                    Object.keys(state.remoteStreams).length > 1
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="white"
                      icon
                      @click="toggleMuteGlobalAudio()"
                      class="mx-3"
                    >
                      <!-- <font-awesome-icon
                          :icon="['fal', 'microphone']"
                          :style="{ fontSize: '20px' }"
                          v-if="!isGlobalAudioMuted"
                        />
                        <font-awesome-icon
                          :icon="['fal', 'microphone-slash']"
                          :style="{ fontSize: '20px' }"
                          v-if="isGlobalAudioMuted"
                        /> -->
                      <font-awesome-icon
                        :icon="['fal', 'user-friends']"
                        :style="{ fontSize: '20px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.remotePersonCall.muteAll") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="white"
                      icon
                      @click="toggleModalSettings"
                      class="mx-3"
                    >
                      <font-awesome-icon :icon="['fal', 'cog']" :style="{ fontSize: '20px' }" />
                    </v-btn>
                  </template>
                  <span>{{$t("components.navbar.camAndMic")}}</span>
                </v-tooltip>
                <v-tooltip top v-if="callInfo.ownerUUID === state.ownUUID">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon color="white" @click.stop.prevent="setSendMessageInCallModal" class="mx-3">
                      <font-awesome-icon :icon="['fal', 'paper-plane']" :style="{ fontSize: '20px' }" />
                      <!-- <font-awesome-icon
                          :icon="['fal', 'lock']"
                          :style="{ fontSize: '14px' }"
                          class="lockMessageIcon"
                        /> -->
                    </v-btn>
                  </template>
                  <span>{{$t("components.bridgeCallTooltips.messageAll")}}</span>
                </v-tooltip>
                <!-- </div> -->
              </div>
            </div>

            <!-- </VueDragResize> -->
          </pane>
          <pane
            v-for="(remote, uuid, index) in state.remoteStreams"
            :key="uuid"
            v-if="index < 1"
            :size="
              Object.keys(state.remoteStreams).length == 1
                ? actualSize.remote
                : 50
            "
          >
            <div @click="changeActualSize()" id="remotePersonCallDiv">
              <RemotePersonCall
                :uuid="uuid"
                :remote="remote"
                :isLocalSoundMuted="isLocalSoundMuted"
                :changeActualSize="changeActualSize"
                :splitViews="splitViews"
                :fullScreen="fullScreen"
              />
            </div>
          </pane>
        </splitpanes>
      </pane>
      <pane v-if="Object.keys(state.remoteStreams).length > 1">
        <splitpanes @resize="resizePane($event)">
          <pane
            v-for="(remote, uuid, index) in state.remoteStreams"
            :key="uuid"
            v-if="index >= 1"
          >
            <RemotePersonCall
              :uuid="uuid"
              :remote="remote"
              :isLocalSoundMuted="isLocalSoundMuted"
            />
          </pane>
        </splitpanes>
      </pane>
    </splitpanes>

    <!-- :minw="240" :minh="160" -->
    <!-- <ErrorInCall
      v-if="errorInCall.show"
      :errorInCall="errorInCall"
      :closeModalError="closeModalError"
    /> -->
    <!-- <ConfirmCloseCallModal v-if="showStopCallModal" :showModal="showStopCallModal" :closeModal="closeStopCallModal" :stopCall="stopCall"/> -->
  </div>

  <!-- <div id="video-container" class="relative" v-bind:class="{'d-none': state.currentContentVisile.showComponent || Object.keys(state.remoteStreams).length === 0}">
      <div class="remotePersonCall" :key="uuid" v-for="(remote, uuid, index) in state.remoteStreams" :class="[`remote-video--${index} total-remote-${Object.keys(state.remoteStreams).length}`]">
       <RemotePersonCall :uuid="uuid" :remote="remote" />
      </div>
      <div class="relative localVideo">
        <video id="local-video" playsinline autoplay="autoplay" :src-object.prop.camel="state.localStreams.user"
          v-bind:class="{ hidden: !haveLocalVideo }" muted></video>
        <video id="local-screen" playsinline autoplay="autoplay" :src-object.prop.camel="state.localStreams.display"
          v-bind:class="{ hidden: !state.localStreams.display }" muted></video>
        <div class="noCameraStream">
           <v-img 
              class="imageNotVideo"
              contain
              :src="getAvatarFromUuid(state.ownUUID)"
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
        </div>
        <div v-bind:class="{ hidden: Object.keys(state.remoteStreams).length == 0}" class="buttonsUser">
          <div>
            <p class="pUserName">{{getNameForUuid(state.ownUUID)}}</p>
          </div>
          <div class="divButtonsLocal">
                  

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="tryWebcam()">
                  <font-awesome-icon :icon="['fal', 'video']" :style="{ fontSize: '20px' }" v-if="state.persisted.rtcVideo"/>
                  <font-awesome-icon :icon="['fal', 'video-slash']" :style="{ fontSize: '20px' }" v-if="!state.persisted.rtcVideo"/>
                </v-btn>
              </template>
              <span>Camera</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="toggleMuteLocalAudio()">
                  <font-awesome-icon :icon="['fal', 'microphone']" :style="{ fontSize: '20px' }" v-if="!isLocalAudioMuted"/>
                  <font-awesome-icon :icon="['fal', 'microphone-slash']" :style="{ fontSize: '20px' }" v-if="isLocalAudioMuted"/>
                </v-btn>
              </template>
              <span>Mic</span>
            </v-tooltip>

           <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon class="redBtn" @click="rtcHangup()">
                 <font-awesome-icon :icon="['fal', 'phone']" :rotation="270" :style="{ fontSize: '20px' }"/>
                </v-btn>
              </template>
              <span>Hang up</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="state.localStreams.display ? closeDisplayMedia() : tryScreenShare()">
                 <font-awesome-icon :icon="['fal', 'desktop']" :style="{ fontSize: '20px' }"/>
                 <font-awesome-icon :icon="['fal', 'slash']" :style="{ fontSize: '20px', position: 'absolute', top: -1, left: 5 }" v-if="!state.localStreams.display"/>
                </v-btn>
              </template>
              <span>Share screen</span>
            </v-tooltip>

          </div>
        </div>
      </div>
      //callcontent
  </div>-->
</template>
<script>
import moment from "../../../sharedsrc/moment";
import store, { EventBus } from "../../store";
// import { tryWebcam, tryScreenShare, rtcHangup, toggleMuteLocalAudio } from '../../lib/rtcCall';
import {
  rtcHangup,
  ensureUserStream,
  // updateCallTime,
  // sendMessageCallparticipants,
  transferCall,
  holdCall,
  unholdCall,
  getWebrtcConnections
  // setPresentationViewScreenShare
} from "../../lib/rtcConn";
import {
  cycleUserMedia,
  tryWebcam,
  tryScreenShare,
  toggleMuteLocalAudio,
  // setPresentationViewForScreenShare
} from "../../lib/mediaDevices";
import { Splitpanes, Pane } from "splitpanes";
import VueDragResize from "vue-drag-resize";
import draggable from "vuedraggable";
import RemotePersonCall from "../rtc/remotePersonCall.vue";
import StreamCanvas from "../rtc/streamCanvas.vue";
import ProgressLinear from "../progressLinear/progressLinear.vue";
// import ErrorInCall from "../modal/errorInCall.vue";
import LongPress from "vue-directive-long-press";
import {
  callAddDurationEvent,
  callLessDurationEvent,
  callSetMessageToParticipantsEvent,
  callSetPresentationViewEvent,
  callSetAllowedToTalkUUIDs,
  callInfoStateStore,
  allowedToTalkMap,
  amMutedByGlobal,
  ownerUUID,
} from "../../effector/call";
import ConfirmCloseCallModal from "../modal/confirmCloseCallModal.vue";
import { setBeforeEndingCallModalEvent, setSendMessageInCallModalEvent, newMessageFromCallStore } from "../../effector/modals";
import { typingInfoStore } from "../../effector/message";
import AudioStreamMeter from "../rtc/audioStreamMeter.vue";
import { isVisitor, isWaitingRoomUser } from "../../utils/privileges.js";
import { getSubjectVisitor } from "../../utils/basicFunctions.js";
import CallInfoManager from "../../lib/callInfoManager";
import { wsCall } from "../../lib/wsConnect";

export default {
  directives: {
    "long-press": LongPress,
  },
  props: ["toggleModalSettings"],
  components: {
    RemotePersonCall,
    StreamCanvas,
    Splitpanes,
    Pane,
    VueDragResize,
    draggable,
    ProgressLinear,
    AudioStreamMeter,
    // ErrorInCall,
    ConfirmCloseCallModal,
  },
  effector: {
    callInfo: callInfoStateStore,
    allowedToTalk: allowedToTalkMap,
    amMutedByGlobal,
    // ownerUUID: ownerUUID,
    showTypingInfo: typingInfoStore,
    newMessageFromCall:newMessageFromCallStore
  },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      pLimitW: 0,
      pLimitH: 0,
      spaceShortcutCallAcceptTimeout: null,
      isIpad: this.$device.ipad,
      isIos: this.$device.ios,
      isLocalSoundMuted: false,
      tenSecondSound: false,
      fiveSecondSound: 0,
      actualSize: {
        remote: 50,
        me: 50,
        position: 0,
      },
      ctrlPressed: false,
      originalCallTime: undefined,
      moment: moment,
      secondsBeep1: 0,
      secondsBeep2: 0,
      secondsBeep3: 0,
      // errorInCall: {
      //   show: false,
      //   messageError: "",
      // },
      uuidOnHold: undefined,
      isLongPress: false,
      openCameraQuality: false,
      connectionsOnHold: [],
      // showStopCallModal: false,
      amIMuted: false,
      amISoundMuted: false,
      infiniteCall: false,
      fullScreen: false,
    };
  },
  beforeDestroy() {
    // console.log('removin lisatener');
    if (!store.state.presentationView.fromScreenshare) {
      let messageToParticipants = store.state.messageToParticipants;
      messageToParticipants.sound = undefined;
      messageToParticipants.audio = undefined;
      // store.setMessageToParticipants(messageToParticipants);
      callSetMessageToParticipantsEvent(messageToParticipants); // TODO: ???
      // this.setdurationMeeting(undefined);
      // this.setOwnerMeeting(undefined);
      // this.setmeetingStartAt(undefined);
      this.notificationEnd = false;
      this.originalCallTime = undefined;
    }
  },
  destroyed() {
    // console.log('removin lisatener on destroy');
    document.removeEventListener("keydown", this.onKeyDown, {
      capture: true,
      passive: true,
    });
    document.removeEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    EventBus.$off('splitViews', this.splitViews);
    EventBus.$off('muteCall', this.audioFunctions);
    EventBus.$off('unMuteCall', this.audioFunctions);
  },
  mounted() {
    //  console.log('IOS IPAD', this.isIpad, this.isIos)
    this.handleResize();
    document.addEventListener("keydown", this.onKeyDown, {
      capture: true,
      passive: true,
    });
    document.addEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    if (!this.infiniteCall){
      let checkDurationMeeting = setInterval(() => {
        if (this.state.durationMeeting) {
          this.originalCallTime = this.state.durationMeeting;
          clearInterval(checkDurationMeeting);
        }
      }, 1000);
    }
    EventBus.$on('splitViews', this.splitViews);
    EventBus.$on('muteCall', this.audioFunctions);
    EventBus.$on('unMuteCall', this.audioFunctions);

    //auto detect quality
    // this.changeAutoQuality();
  },
  watch: {
    "state.localStreams.user": function () {
      ensureUserStream();
    },
    "state.localStreams.userCanvas": function () {
      ensureUserStream();
    },
    "callInfo": function () {
      //console.log('callInfo!', this.callInfo)
      this.infiniteCall = this.callInfo.infiniteCall
    },
    newMessageFromCall:{
       handler:function (newMessageFromCall) {
         if(newMessageFromCall){
            for (let uuid in store.state.remoteStreams) {
              wsCall("sendToUUID", uuid, {
                type: "rtcSendMessageToAll",
                message: newMessageFromCall
            });
          }
         }
       }
    }
  },
  methods: {
    audioFunctions(){
      this.toggleMuteGlobalAudio();
      this.toggleMuteLocalAudio();
    },
    setSendMessageInCallModal(){
      setSendMessageInCallModalEvent(true)
    },
    getSubjectVisitor(uuid) {
      return getSubjectVisitor(uuid);
    },
    isVisitor(uuid) {
      return isVisitor(uuid);
    },
    isWaitingRoomUser(uuid) {
      return isWaitingRoomUser(uuid);
    },
    toggleMuteGlobalAudio() {
      if (this.isGlobalAudioMuted) {
        callSetAllowedToTalkUUIDs([]);
      } else {
        callSetAllowedToTalkUUIDs([this.state.ownUUID]);
      }
    },
    stopCall() {
      if (this.$refs.cancelCall) {
        this.$refs.cancelCall.$el.click();
      }
    },
    // closeStopCallModal(){
    //   this.showStopCallModal = false;
    // },
    async unholdCall() {
      for (let uuid in store.state.remoteStreams) {
        if (store.state.rtc[uuid].onHold) {
          await unholdCall(uuid);
          if (!store.state.rtc[uuid].onHold) {
            // this.rtcHangup();
          }
        }
      }
    },
    holdCall() {
      for (let uuid in store.state.remoteStreams) {
        holdCall(uuid);
      }
    },
    setQuality(value) {
      this.state.persisted.rtcVideoQuality = value;
      cycleUserMedia();
      this.openCameraQuality = false;
    },
    openMenuQuality() {
      // triggers after 300ms of mousedown
      if (this.state.persisted.rtcVideo) {
        this.isLongPress = true;
        // open quality menu
        this.openCameraQuality = true;
      }
    },
    onLongPressStop() {
      setTimeout(() => {
        if (this.isLongPress) {
          this.isLongPress = false;
        }
      }, 100);
    },
    // closeModalError() {
    //   this.errorInCall = {
    //     show: false,
    //     messageError: "",
    //   };
    //   this.rtcHangup();
    // },
    splitViews(){
      this.actualSize.remote = 50;
      this.actualSize.me = 50;
      this.actualSize.position = 0;
    },
    changeActualSize() {
      if ( document.querySelector('.v-dialog__content--active') ) return;
      if (Object.keys(this.state.remoteStreams).length == 1) {
        let Remotesizes = [50,'full', 100];
        let MeSizes = [50,'full', 0.1];
        this.actualSize.position += 1;
        if (this.actualSize.position > 2) {
          this.actualSize.position = 0;
        }
        if( this.actualSize.position >=1 ){
          //call fullScreen
          this.fullSreen()
        } 
        // if( this.actualSize.position == 2 ){
        //   //cancel full screen and 100%
        //   this.fullSreen()
        // }
        if(this.actualSize.position !==1){
          this.actualSize.remote = Remotesizes[this.actualSize.position];
          this.actualSize.me = MeSizes[this.actualSize.position];
        }
      }
    },
    fullSreen(event) {
      if (!this.IsFullScreen()) {
        // const elem = event.target;
        const elem = document.getElementById('remotePersonCallVideosContainer');
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen();
        }
        this.fullScreen = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen().catch(() => {});
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.fullScreen = false;
      }
    },
    IsFullScreen() {
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      );
    },
    addMoreTime() {
      if (this.state.ownerMeeting) {
        callAddDurationEvent();
        // let personRemote = Object.keys(store.state.remoteStreams);
        // let oneMinute = 60000;
        // this.originalCallTime += oneMinute;
        // let durationMeeting = store.state.durationMeeting + oneMinute;
        // store.setdurationMeeting(durationMeeting);
        // for (const key in personRemote) {
        //   let uuid = personRemote[key];
        //   if (store.state.rtc[uuid].ringingState == "connected") {
        //     updateCallTime(uuid, "increase");
        //   }
        // }
      } else {
        const data = {
          show: true,
          header: this.$t("components.modalAddTimeMeeting.header"),
          body: this.$t("components.modalAddTimeMeeting.info"),
        };
        return store.setinfoModal(data);
      }
    },
    lessTime() {
      callLessDurationEvent();
      // if (
      //   this.state.ownerMeeting &&
      //   this.state.currentTS - this.state.meetingStartAt <
      //     this.state.durationMeeting - 60000
      // ) {

      // let personRemote = Object.keys(store.state.remoteStreams);
      // let oneMinute = 60000;
      // let durationMeeting = store.state.durationMeeting - oneMinute;
      // store.setdurationMeeting(durationMeeting);
      // for (const key in personRemote) {
      //   let uuid = personRemote[key];
      //   if (store.state.rtc[uuid].ringingState == "connected") {
      //     updateCallTime(uuid, "decrease");
      //   }
      // }
      // }
    },

    toggleMuteSound() {
      this.isLocalSoundMuted = !this.isLocalSoundMuted;
      const messageToParticipants = store.state.messageToParticipants;
      this.amISoundMuted = !this.amISoundMuted;
      // let personRemote = Object.keys(store.state.remoteStreams);
      if (this.isLocalSoundMuted) {
        messageToParticipants.sound = this.$t(
          "components.callsContent.soundMessage"
        );
        callSetMessageToParticipantsEvent(messageToParticipants);
        // for (const key in personRemote) {
        //   let uuid = personRemote[key];
        //   if (store.state.rtc[uuid].ringingState == "connected") {
        //     sendMessageCallparticipants(uuid, messageToParticipants);
        //   }
        // }
        // store.setMessageToParticipants(messageToParticipants);
      } else {
        if (store.state.messageToParticipants) {
          messageToParticipants.sound = undefined;
          callSetMessageToParticipantsEvent(messageToParticipants);
          // for (const key in personRemote) {
          //   let uuid = personRemote[key];
          //   if (store.state.rtc[uuid].ringingState == "connected") {
          //     sendMessageCallparticipants(uuid, messageToParticipants);
          //   }
          // }
          // store.setMessageToParticipants(messageToParticipants);
        }
      }
    },
    onKeyDown(event) {
      if (event.type === "keydown" && event.keyCode === 17) {
        this.ctrlPressed = true;
      }
    },
    onKeyUp(event) {
      // console.log('keyup', event.keyCode);
      if (
        this.spaceShortcutCallAcceptTimeout &&
        event.type === "keyup" &&
        event.keyCode === 32
      ) {
        clearTimeout(this.spaceShortcutCallAcceptTimeout);
        this.spaceShortcutCallAcceptTimeout = null;
        if (this.$refs.cancelCall) {
          if (
            event.target.nodeName !== "TEXTAREA" &&
            event.target.nodeName !== "INPUT"
          ) {
            // this.showStopCallModal = true;
            // this.$refs.cancelCall.$el.click();
            this.stopCall();
          }
        }
      } else if (event.type === "keyup" && event.keyCode === 32) {
        this.spaceShortcutCallAcceptTimeout = setTimeout(() => {
          this.spaceShortcutCallAcceptTimeout = null;
        }, 300);
      } else if (event.type === "keyup" && event.keyCode === 13) {
        if (this.ctrlPressed) {
          // console.log("click ctrPress");
          if (
            event.target.nodeName !== "TEXTAREA" &&
            event.target.nodeName !== "INPUT"
          ) {
            return this.lessTime();
          }
        } else {
          // console.log('Adding Time call', Object.keys(this.state.remoteStreams).length)
          if (Object.keys(store.state.remoteStreams).length > 0) {
            if (
              event.target.nodeName !== "TEXTAREA" &&
              event.target.nodeName !== "INPUT"
            ) {
              this.addMoreTime();
            }
          }
        }
      } else if (event.type === "keyup" && event.keyCode === 17) {
        this.ctrlPressed = false;
      }
    },
    toggleUseBlurEffect() {
      store.setUseBlurEffect(!this.state.persisted.useBlurEffect);
      // ensureUserStream();
    },
    resizePane(event) {
      // console.log('Resize pan!!!!!!', event)
      this.handleResize();
    },
    handleResize() {
      const el = this.$refs.localVideoDiv;
      if (el) {
        this.pLimitW = el.clientWidth;
        this.pLimitH = el.clientHeight;
      }
    },
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
      this.handleResize();
    },

    tryWebcam() {
      if (!this.isLongPress) {
        return tryWebcam(this.isLocalAudioMuted);
      }
    },
    setPresentationView() {
      callSetPresentationViewEvent({
        value: true,
        owner: store.state.ownUUID,
        fromScreenshare: false,
      });
      // store.setPresentationView(true, store.state.ownUUID);
      // let personRemote = Object.keys(store.state.remoteStreams);
      // for (const key in personRemote) {
      //   let remote_uuid = personRemote[key];
      //   if (store.state.rtc[remote_uuid].ringingState == "connected") {
      //     setPresentationViewScreenShare(remote_uuid);
      //   }
      // }
    },
    tryScreenShare() {
      // setPresentationViewForScreenShare();
      // store.setPresentationView(true, store.state.ownUUID, true);
      callSetPresentationViewEvent({
        value: true,
        owner: store.state.ownUUID,
        fromScreenshare: true,
      });
      return tryScreenShare();
    },
    closeDisplayMedia() {
      return store.closeDisplayMedia();
    },
    // transferCall(hold, unhold) {
    //   return transferCall(hold, unhold);
    // },
    async transferCall() {
      let uuidToTransfer;
      for (let uuid in store.state.remoteStreams) {
        if (!store.state.rtc[uuid].onHold) {
          uuidToTransfer = uuid;
        }
      }
      const connections = store.state.remoteStreams;
      for (let uuid in connections) {
        if (store.state.rtc[uuid].onHold) {
          // console.log('Forwarding ', uuid, 'to', uuidToTransfer);
          await transferCall(uuid, uuidToTransfer);
        }
      }
    },
    setOwnerMeeting(value) {
      store.setOwnerMeeting(value);
    },
    async handleHangUp() {
      // if (Object.keys(store.state.remoteStreams).length == 2) {
      // let hold, unhold;
      // for (let uuid in store.state.remoteStreams) {
      //   if (store.state.rtc[uuid].onHold) {
      //     hold = uuid;
      //   } else {
      //     unhold = uuid;
      //   }
      // }
      if (!this.hasOnHold) {
        if (this.callInfo.ownerUUID === this.state.ownUUID) {
          const remotes = Object.keys(this.state.remoteStreams);
          let usersExist = false;
          for (let i = 0; i < remotes.length; i++) {
            const uuid = remotes[i];
            const person = this.state.group[uuid];
            let test = {};
            // console.log('44444444444', person, remotes)
            if (!person.user.visitor && !person.user.guest) {
              usersExist = true;
              // console.log('1111111111', uuid)
              // this.setOwnerMeeting(uuid)
              test = CallInfoManager.getState();
              test.ownerUUID = uuid;
              CallInfoManager.setState(test, true);
              await CallInfoManager.syncToPeers(true);
              // this.transferModerator(uuid)
              // console.log('TEEEEEST', test, this.callInfo);
              break;
            }
            // CallInfoManager.setStateFromRemote(test, person)
          }
          if (!usersExist) {
            for (const key in this.state.remoteStreams) {
              getWebrtcConnections()[key].die(true);
            }
          }
        }
        this.rtcHangup();
      } else {
        this.transferCall();
      }
      // } else {
      //   this.rtcHangup();
      // }
      // console.log("showing end call");
      // this.setshowModalEndMeeting(true);
    },
    getWebrtcConnections() {
      return getWebrtcConnections();
    },
    transferModerator(uuid) {
      return CallInfoManager.transferOwnership(uuid);
    },
    rtcHangup() {
      // this.setdurationMeeting(undefined);
      // this.setOwnerMeeting(undefined);
      // this.setmeetingStartAt(undefined);
      this.notificationEnd = false;
      this.originalCallTime = undefined;
      return rtcHangup();
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      if (store.getTitelForUuid(uuid) !== "unknown") {
        return store.getTitelForUuid(uuid);
      } else {
        return "";
      }
    },
    getAvatarFromUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    toggleMuteLocalAudio() {
      if (this.isGlobalAudioMuted) return;
      const muted = toggleMuteLocalAudio();
      // let personRemote = Object.keys(store.state.remoteStreams);
      const messageToParticipants = store.state.messageToParticipants;
      this.amIMuted = !this.amIMuted;
      if (this.isLocalAudioMuted) {
        messageToParticipants.audio = this.$t(
          "components.callsContent.audioMessage"
        );
        callSetMessageToParticipantsEvent(messageToParticipants);
        // for (const key in personRemote) {
        //   let uuid = personRemote[key];
        //   if (store.state.rtc[uuid].ringingState == "connected") {
        //     sendMessageCallparticipants(uuid, messageToParticipants);
        //   }
        // }
        // store.setMessageToParticipants(messageToParticipants);
      } else {
        if (store.state.messageToParticipants) {
          messageToParticipants.audio = undefined;
          callSetMessageToParticipantsEvent(messageToParticipants);
          // for (const key in personRemote) {
          //   let uuid = personRemote[key];
          //   if (store.state.rtc[uuid].ringingState == "connected") {
          //     sendMessageCallparticipants(uuid, messageToParticipants);
          //   }
          // }
          // store.setMessageToParticipants(messageToParticipants);
        }
      }
      return muted;
    },
    // setdurationMeeting(time) {
    //   return store.setdurationMeeting(time);
    // },
    // setmeetingStartAt(value) {
    //   return store.setmeetingStartAt(value);
    // },
    // setOwnerMeeting(value) {
    //   store.setOwnerMeeting(value);
    // },
    // setshowModalEndMeeting(value) {
    //   return store.setshowModalEndMeeting(value);
    // },
    // formatTime(time) {
    //   if (time >= 3600000) {
    //     return this.moment.utc(time).format("hh:mm:ss");
    //   } else {
    //     return this.moment.utc(time).format("mm:ss");
    //   }
    // },
    // ringAudio() {
    //   try {
    //     const audio = new Audio("/media/callFinishTone.mp3");
    //     audio
    //       .play()
    //       .catch((error) =>
    //         console.warn("Failed to play notification audio", error)
    //       );
    //   } catch (error) {
    //     console.warn("Failed to play notification audio", error);
    //   }
    // },
    // ringAudioFinalCall() {
    //   try {
    //     const audio = new Audio("/media/endCall.mp3");
    //     audio
    //       .play()
    //       .catch((error) =>
    //         console.warn("Failed to play notification audio", error)
    //       );
    //   } catch (error) {
    //     console.warn("Failed to play notification audio", error);
    //   }
    // },
  },
  computed: {
    // isInfiniteCall(){
    //   const callInfo = CallInfoManager.getState();
    //   if(callInfo.isInfiniteCall){
    //     return true
    //   }
    //   return false;
    // },
    isGlobalAudioMuted() {
      //return this.amMutedByGlobal;
      //  console.log('isGlobalAudioMuted call content', this.allowedToTalk.length !== 0);
      return this.allowedToTalk.length !== 0;
    },
    amIMutedByGlobal() {
      return this.amMutedByGlobal;
    },
    isBlurEffectEnabled() {
      return this.state.namespaceSettings.showBlurEffectOption;
    },
    hasOnHold() {
      let onHold = 0;
      for (let uuid in store.state.remoteStreams) {
        if (store.state.rtc[uuid].onHold) {
          onHold = onHold + 1;
        }
      }
      if (onHold > 0) {
        return true;
      }
      return false;
    },
    // elapseTime() {
    //   let milisegundos = store.state.currentTS - store.state.meetingStartAt;
    //   if (!milisegundos) return '';
    //   let progress = "";
    //   if (milisegundos >= 3600000) {
    //     progress = this.moment
    //       .utc(milisegundos)
    //       .format("hh:mm:ss");
    //   } else {
    //     progress = this.moment
    //       .utc(milisegundos)
    //       .format("mm:ss");
    //   }
    //   return progress;
    // },
    // timerCall() {
    //   let milisegundos =
    //     store.state.durationMeeting -
    //     (store.state.currentTS - store.state.meetingStartAt);
    //   if (!milisegundos) return '';
    //   let progress = "";
    //   if (milisegundos >= 3600000) {
    //     progress = this.moment
    //       .utc(milisegundos)
    //       .format("hh:mm:ss");
    //   } else {
    //     progress = this.moment
    //       .utc(milisegundos)
    //       .format("mm:ss");
    //   }
    //   const audio = new Audio("/media/notificationSound.mp3");
    //   if (milisegundos <= 30000 && milisegundos > 20000) {
    //     if (this.secondsBeep1 < 1) {
    //       this.ringAudio();
    //       this.secondsBeep1 = this.secondsBeep1 + 1;
    //     }
    //   } else if (milisegundos <= 20000 && milisegundos > 10000) {
    //     if (this.secondsBeep2 < 2) {
    //       this.ringAudio();
    //       this.secondsBeep2 = this.secondsBeep2 + 1;
    //     }
    //   } else if (milisegundos <= 10000 && milisegundos > 0) {
    //     if (this.secondsBeep3 < 3) {
    //       this.ringAudio();
    //       this.secondsBeep3 = this.secondsBeep3 + 1;
    //     }
    //   }
    //   return progress;
    // },
    haveLocalVideo() {
      if (!this.state.localStreams.user) return false;
      for (const track of this.state.localStreams.user.getTracks()) {
        if (track.kind === "video") return true;
      }
      return false;
    },
    isLocalAudioMuted() {
      if (!this.state.localStreams.user) return false;
      if (!this.state.localStreams.lastMicChange) return false; // Just for the reference
      for (const track of this.state.localStreams.user.getTracks()) {
        if (track.kind === "audio") {
          return !track.enabled;
        }
      }
      return false;
    },
    // meetingTime() {
    //   let personRemote = Object.keys(store.state.remoteStreams)[0];
    //   if (
    //     store.state.rtc[personRemote] &&
    //     store.state.rtc[personRemote].ringingState === "ringing" &&
    //     store.state.meetingStartAt &&
    //     store.state.meetingDialUPTime
    //   ) {
    //     let timeIncall = store.state.currentTS - store.state.meetingStartAt;
    //     if (timeIncall >= store.state.meetingDialUPTime) {
    //       return rtcHangup();
    //     }
    //   }
    //   if (
    //     store.state.rtc[personRemote] &&
    //     store.state.rtc[personRemote].ringingState == "connected" &&
    //     store.state.meetingStartAt
    //   ) {
    //     let timeIncall = store.state.currentTS - store.state.meetingStartAt;
    //     let limitTimeCall = store.state.durationMeeting;
    //     let porcentaje = ((timeIncall * 100) / limitTimeCall).toFixed(0);
    //     if (porcentaje > 200 || porcentaje < -15) {
    //       this.errorInCall = {
    //         show: true,
    //         messageError:
    //           "Incorrect time, please verify your date/time settings",
    //       };
    //     }
    //     if (porcentaje >= 100) {
    //       this.ringAudioFinalCall();
    // if(this.state.ownerMeeting){
    //   setBeforeEndingCallModalEvent(true)
    // }
    //       return rtcHangup();
    //     }
    //     return porcentaje;
    //   } else {
    //     return 0;
    //   }
    // },
  },
  // beforeDestroy: function() {
  //   this.ringAudioFinalCall();
  // }
};
</script>
<style scoped lang="scss">
.testMeSomehow {
  position: absolute;
  top: 0px;
  opacity: 0;
  background: #2f2f2f;
  color: white;
  width: 100%;
  text-align: center;
  padding: 5px;

  &.active {
    top: 30px;
    opacity: 1;
    transition: all 0.5s ease-in;
  }
}
#canvasAudioCallsContent {
  position: absolute;
  top: -8px;
  height: 3px;
  width: 100%;
}
.microphonoLevelIconSlash {
  margin-bottom: -18px !important;
}
.microphonoLevelIcon {
  font-size: 29px;
  transform: rotate(90deg);
  margin-right: 13px;
  margin-bottom: -3px;
}
.rotatedArrow {
  font-size: 16px;
  transform: rotate(45deg);
  margin-left: -2px;
  margin-top: 0px;
}
.divSharingScreen {
  position: absolute;
  width: 300px;
  height: 150px;
  top: 35px;
  left: 5px;
  z-index: 99999999;
}
.microphoneLevel {
  position: absolute;
  top: 40%;
  left: -25px;
  width: 100px;
  transform: rotate(270deg);
  z-index: 99999;
}
.callerName {
  position: absolute;
  width: 100%;
  color: white;
  // z-index: 2;
  height: 30px;
  padding: 6px;
  background-color: linear-gradient;
  background: rgb(21, 30, 39);
  z-index: 99999999;
  // background: linear-gradient(
  //   0deg,
  //   rgba(21, 30, 39, 0.42) 0%,
  //   rgba(31, 43, 48, 0.43) 100%
  // );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.86) 0%,
    rgba(0, 0, 0, 0.77) 100%
  );
  font-size: 15px;
  text-align: center;
}
.bg {
  background-color: black;
  z-index: 999999 !important;
}

#video-container {
  height: 100%;
  width: 100%;
  min-width: 100%;
  position: absolute;
  // display: grid;
  // grid-template-rows: repeat(2, 1fr);
  // grid-template-columns: repeat(4, 0.5fr);
  overflow-x: hidden !important;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  z-index: 1;
  video {
    display: flex;
    background-color: black;
    border-radius: 3px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
    /* max-width: 800px; */
    /* max-height: 600px; */
    max-width: 100%;
    max-height: 40%;
  }
  .remotePersonCall {
    position: relative;
    min-width: 100px;
    min-height: 100px;
    margin: 5px;
    margin: 0;
    /* 1 person (1 in call plus user) */
    &.total-remote-1.remote-video--0 {
      grid-area: 1 / 1 / span 4 / span 4;
    }

    /* 3 person (2 in call plus user) */
    &.total-remote-2.remote-video--0 {
      grid-area: 1 / 1 / span 2 / span 2;
    }

    &.total-remote-2.remote-video--1 {
      grid-area: 1 / 3 / span 2 / span 2;
    }

    /* 4 person (3 in call plus user) */
    &.total-remote-3.remote-video--0 {
      grid-area: 1 / 1 / span 1 / span 2;
    }

    &.total-remote-3.remote-video--1 {
      grid-area: 2 / 1 / span 1 / span 2;
    }

    &.total-remote-3.remote-video--2 {
      grid-area: 1 / 3 / span 2 / span 2;
    }

    /* 5 person (4 in call plus user) */
    &.total-remote-4.remote-video--0 {
      grid-area: 1 / 1 / span 1 / span 2;
    }

    &.total-remote-4.remote-video--1 {
      grid-area: 2 / 1 / span 1 / span 2;
    }

    &.total-remote-4.remote-video--2 {
      grid-area: 1 / 3 / span 1 / span 2;
    }

    &.total-remote-4.remote-video--3 {
      grid-area: 2 / 3 / span 1 / span 2;
    }
  }
  .localVideo {
    /* grid-area: 2 / 3 / span 1 / span 2; */
    z-index: 999;
    // position: absolute;
    // bottom: 0;
    /*width: 340px;*/
    height: 100%;
    right: 0;
    border: 1px solid #00000066;
    #local-video {
      width: 100%;
      max-height: unset;
      position: relative;
      bottom: 0;
      height: 100%;
    }

    #local-screen {
      width: 100%;
      position: absolute;
      top: 0;
      height: 100%;
      max-height: unset;
    }
    .noCameraStream {
      position: absolute;
      display: table;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      text-align: center;
      z-index: -10;
      font-weight: bold;
      font-size: 30px;
      .noCameraContent {
        display: table-cell;
        vertical-align: middle;
        .emptyDiv {
          height: 125px;
        }
      }
      .imageNotVideo {
        width: 160px;
        height: 160px;
        border-radius: 10px;
        margin: 0 auto;
      }
    }

    .buttonsIpad {
      bottom: 64px !important;
    }
    .buttonsUser {
      // display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      padding-right: 10px;
      min-height: 45px;
      padding-top: 7px !important;
      bottom: 0px;
      position: absolute;
      width: 100%;
      z-index: 9999999999999;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 100%
      );
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        bottom: 64px !important;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        bottom: 64px !important;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        bottom: 64px !important;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
        bottom: 64px !important;
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        bottom: 64px !important;
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
        bottom: 64px !important;
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
        bottom: 64px !important;
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
        bottom: 64px !important;
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
        bottom: 64px !important;
      }
      .pUserName {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 0px;
        margin-top: 5px;
        color: white;
      }
      .divButtonsLocal {
        button {
          margin-right: 5px;
          color: white;
          // background: #02889D;
          // border-radius: 4px;
          &:last-of-type {
            margin-right: 0px;
          }
          &.redBtn {
            // background: red;
          }
        }
      }
    }
  }
}
.splitpanes__pane {
  // background-color: transparent !important;
  z-index: 0;
  position: relative;
}

.theme--dark .splitpanes.default-theme .splitpanes__pane {
  background-color: #272727;
}
.theme--light .splitpanes.default-theme .splitpanes__pane {
  background-color: #f2f2f2;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.progressBar {
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 1;
  background-color: #f44336;
}
.progressBarBlink {
  animation: blink 1s linear infinite;
  //  pointer-events: none;
}
.messageToParticipantsBlink {
  animation: blink 2s linear infinite;
}

.videoIdTag {
  float: left;
  margin-left: 4px;
  color: white;
  position: absolute;
  top: 32px;
  z-index: 9999;
  -webkit-text-stroke: 0.06em black;
  font-size: 1em;
  font-family: verdana;
}
.messageToParticipants {
  // font-size: 12px;
  z-index: 1;
  position: absolute;
  padding: 10px;
  background: red;
  border-radius: 3px;
  right: 5px;
  bottom: 55px;
  color: white;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.cameraQuality {
  bottom: 65px !important;
  position: relative !important;
}
.v-list-item {
  min-height: 35px;
}
.v-list-item .v-list-item__title {
  line-height: 1 !important;
}
.blueBackground {
  background: #02889d;
}
.imgButton {
  width: 33px;
}
</style>