<template>
  <v-dialog v-model="showModal" persistent max-width="580" @keydown.esc="toggleMeetingTimeTitle">
    <v-card>
      <!-- <v-card-title class="headline">
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        {{$t('components.meetingTime.messages')}}
      </v-card-title> -->
       <HeaderModal :titleModal="$t('components.meetingTime.messages')" :closeModalFunction="toggleMeetingTimeTitle" />
      <v-row class="mx-0 px-3">
        <v-col class="col-12 px-1 py-1 pt-2">
          <div class="d-flex">
            <template v-if="!editingText">
              <v-card-title class="text-truncate body-1">{{titleText}}</v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on"  icon class="my-3" @click="editText()">
                    <font-awesome-icon :icon="['fal', 'pen']" :style="{ fontSize: '20px' }" />
                  </v-btn>
                </template>
                <span>{{ $t('components.meetingTime.editText') }}</span>
              </v-tooltip>
            </template>
            <template v-if="editingText">
              <v-text-field v-model="titleText" class="body-2" label></v-text-field>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on"  icon class="my-3" @click="saveText()">
                    <font-awesome-icon :icon="['fal', 'check']" :style="{ fontSize: '20px' }" />
                  </v-btn>
                </template>
                <span>{{ $t('generics.save') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon class="my-3" @click="cancelText()">
                    <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
                  </v-btn>
                </template>
                <span>{{ $t('generics.cancel') }}</span>
              </v-tooltip>
            </template>
          </div>
        </v-col>
      </v-row>
      <!-- /////// Footer area ///////// -->
      <FooterModal :closeModalFunction="toggleMeetingTimeTitle" />
      <!-- <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns w-100 mx-0 my-0">
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  color="white"
                  @click="toggleMeetingTimeTitle"
                  class="mr-3"
                >
                  <font-awesome-icon :icon="['fal', 'check']" :style="{ fontSize: '20px' }" />
                </v-btn>
              </template>
              <span>{{ $t('generics.accept') }}</span>
            </v-tooltip>
          </template>
        </v-row>
      </v-card>
      <v-spacer></v-spacer> -->
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import { setNamespaceSetting } from "../../lib/wsMsg";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  props: ["toggleMeetingTimeTitle", "showModal"],
  components:{
    HeaderModal,
    FooterModal
  },
  data() {
    return {
      state: store.state,
       titleText: store.state.namespaceSettings.companyModalText,
       editingText: false
    };
  },
  mounted: function() {
  },
  methods: {
    editText() {
      this.editingText = true;
    },
        saveText() {
      this.editingText = false;
      store.state.namespaceSettings.companyModalText = this.titleText;
      setNamespaceSetting("companyModalText", this.titleText);
    },
        cancelText() {
      this.editingText = false;
      this.titleText = store.state.namespaceSettings.companyModalText;
    },
  },

  computed: {}
};
</script>
<style scoped lang="scss">
</style>