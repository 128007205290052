<template>
  <v-card>
    <v-row flex class="w-100 mx-0 my-0 justify-space-between">
      <v-col class="px-0 py-0" align="left">
        <v-card-title v-if="isVisitor(call.uuid)" class="headline">
          <v-img
            src="assets/icon.png"
            max-height="30"
            max-width="30"
            contain
          ></v-img>
          <span class="font-weight-medium pl-4 spanModalCall">
            {{ $t("components.incomingCall.incomingOneCall") }}
          </span>
        </v-card-title>
        <v-card-title v-else class="headline">
          <v-img
            src="assets/icon.png"
            max-height="30"
            max-width="30"
            contain
          ></v-img>
          <span class="font-weight-medium pl-4 spanModalCall">
            {{ $t("components.incomingCall.incommingConfCall") }}
          </span>
        </v-card-title>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <!-- branch if pin protection -->
    <v-row flex class="w-100 mx-0 my-0 px-3 pt-3">
      <v-col
        class="my-0 py-0"
        v-for="n in 3"
        :key="n"
        :lg="isMobile ? 12 : cols[n - 1]"
        :md="isMobile ? 12 : cols[n - 1]"
        :sm="isMobile ? 12 : cols[n - 1]"
        :xl="isMobile ? 12 : cols[n - 1]"
      >
        <div v-if="n == 1" align="center">
          <v-list-item-avatar tile size="120" class="borderRadius4">
            <v-img
              class="borderRadius4"
              max-height="120"
              max-width="120"
              contain
              :src="getAvatarForUuid(call.uuid)"
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
          </v-list-item-avatar>
        </div>
        <div
          class="pt-3 pl-3"
          v-if="n == 2"
          v-bind:class="{ 'text-center': isMobile }"
        >
          <p class="font-weight-medium my-0">{{ getNameForUuid(call.uuid) }}</p>
          <p
            class="dataUserCard my-0"
            v-if="state.group[call.uuid].user.position"
          >
            {{ state.group[call.uuid].user.position }}
          </p>
          <p
            class="dataUserCard my-0"
            v-if="
              state.group[call.uuid].user.sections &&
              state.group[call.uuid].user.sections.length > 0 &&
              state.group[call.uuid].user.locations &&
              state.group[call.uuid].user.locations.length > 0
            "
          >
            {{ state.group[call.uuid].user.sections[0] }} |
            {{ state.group[call.uuid].user.locations[0] }}
          </p>
        </div>
        <div
          class="pb-2 pt-3 pl-3 text-right"
          v-if="n == 3"
          v-bind:class="{ 'text-center': isMobile }"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 mb-2"
                v-on="on"
                outlined
                small
                color="primary"
                ref="cancelCall"
                @click="
                  rejectCall(
                    call,
                    $t(
                      'components.incomingCall.declineText1',
                      state.group[call.uuid].user.language
                    )
                  )
                "
                >{{ $t("components.incomingCall.declineText1") }}</v-btn
              >
            </template>
            <span>{{ $t("components.incomingCall.declineText1") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2"
                v-on="on"
                outlined
                small
                color="primary"
                ref="cancelCall"
                @click="
                  rejectCall(
                    call,
                    $t(
                      'components.incomingCall.declineText2',
                      state.group[call.uuid].user.language
                    )
                  )
                "
                >{{ $t("components.incomingCall.declineText2") }}</v-btn
              >
            </template>
            <span>{{ $t("components.incomingCall.declineText2") }}</span>
          </v-tooltip>
        </div>
      </v-col>
      <v-col cols="12" v-if="showMembersContent">
        <p class="dataUserCard my-0 conferenceMembers">
          {{getCallMembersName}}
        </p>
      </v-col>
      <v-col
        cols="12"
        class="px-2 w-100 mx-0 my-0"
        v-if="call && call.dialGroup && call.dialGroup.length"
      >
        <v-divider></v-divider>
        <p class="pt-5">{{ $t("components.inviteVisitor.participants") }}:</p>
        <ul>
          <li v-for="uuid of call.dialGroup" :key="uuid">
            {{ getNameForUuid(uuid) }}
          </li>
        </ul>
      </v-col>
      <template v-if="!isVisitor(ownUUID)">
        <v-col
          cols="12"
          class="px-2 w-100 mx-0 my-0"
          v-bind:class="{ 'text-center': isMobile }"
          v-if="state.persisted.tmpMessages.showInfoIncomingCallModal"
        >
          <v-divider></v-divider>
          <div class="pt-3">
            <p>
              {{ $t("components.incomingCall.answerSpaceB") }}
            </p>
            <v-checkbox
              v-model="checkedMessage"
              @change="setShowInfoIncomingCallModal(false)"
              class="mt-0 white--text"
              :label="$t('components.meetingTime.dontShowAgain')"
            ></v-checkbox>
          </div>
          <v-divider></v-divider>
        </v-col>
      </template>
      <v-col cols="12" v-if="showCameraPreview">
        <CallModalCameraPreview
          :height="'100%'"
          :width="'100%'"
        ></CallModalCameraPreview>
      </v-col>
      <template v-if="isCallProtected">
        <v-col cols="12" class="px-2 w-100 mx-0 my-0" >
          <v-divider></v-divider>
          <div class="d-flex flex-column pt-5">
            <div class="pr-4 font-weight-medium primary--text">
              <p>{{ $t("components.callsContent.protectedCall") }}</p>
            </div>
            <div class="w-50">
              <v-text-field
                :autofocus="true"
                ref="userPinInput"
                class="whiteControl"
                outlined
                dense
                v-model="userPin"
                :style="{ width: 300, color: 'white' }"
                hide-details
                :label="$t('components.callsContent.insertPin')"
                @keyup="checkIt($event)"
                :maxLength="6"
              />
            </div>
          </div>
        </v-col>
      </template>
    </v-row>
    <FooterModal :closeModalFunction="closeModal">
      <template>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="isCallProtected"
              class="ml-3 buttonIconPrimaryFooter callButton px-2"
              v-on="on"
              icon
              tile
              color="primary"
              ref="acceptCall"
              @click="resolveCall(call)"
            >
              {{ $t("generics.accept") }}
            </v-btn>
          </template>
          <span>{{ $t("components.meetingTime.acceptCall") }}</span>
        </v-tooltip>
      </template>
    </FooterModal>
  </v-card>
</template>
<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import {
  dispatchViewedTimelineEntry,
  dispatchRsvpTimelineEntry,
  fetchEventByUUIDEffect,
} from "../../effector/timeline";
import { wsCall } from "../../lib/wsConnect";
import { hasPrivilege } from "../../utils/privileges";
import CallModalCameraPreview from "../camera/callModalCameraPreview.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { amInABridgeCall } from "../../utils/calls";
import { bridgeInfoStore } from "../../effector/users/bridgeInfo";
export default {
  components: { CallModalCameraPreview, FooterModal },
  props: ["call"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      setCurrentContentVisile: store.setCurrentContentVisile,
      spaceShortcutCallAcceptTimeout: null,
      checkedMessage: false,
      isMobile: isMobile(),
      timeLeft: null,
      storeDialUpTime: store.state.user.userSettings.dialUpTime,
      hasConfrimmedPin: false,
      userPin: "",
      audioCallOn: store.state.user.userSettings.audioCallOn,
    };
  },
  effector: {
    bridgeInfoStore: bridgeInfoStore
  },
  mounted() {
    document.addEventListener("keyup", this.onKeyUp, {
      capture: false,
      passive: true,
    });
    this.timeLeft = this.storeDialUpTime / 1000 || 30;
    this._interval = setInterval(this.tick.bind(this), 1000);
  },
  beforeDestroy: function () {
    document.removeEventListener("keyup", this.onKeyUp);
  },
  destroyed() {
    this.timeLeft = null;
    clearInterval(this._interval);
  },
  watch: {
    isCallProtected: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.$nextTick(() => {
            setTimeout(() => {
              if (this.$refs.userPinInput) this.$refs.userPinInput.focus();
            }, 10);
          })
        }
      },
    },
    timeLeft(val) {
      if (val === null) return;
      if (val <= 0) this.rejectCall(this.call);
    },
  },
  methods: {
    closeModal() {
      this.rejectCall(this.call);
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    checkIt(evt) {
      if (this.userPin.length > 6 || this.userPin.length < 6) return false;
      // console.log(this.userPin, this.call.pinCode);
      if (this.userPin === this.call.pinCode) {
        this.hasConfrimmedPin = true;
      } else {
        this.hasConfrimmedPin = false;
      }
    },
    tick() {
      this.timeLeft--;
    },
    isVisitor(uuid) {
      return ((store.state.group[uuid] || {}).user || {}).visitor || false;
    },
    declineDefaultMessage(uuid, message) {
      wsCall("sendToUUID", uuid, {
        type: "rejectCall",
        message: message,
        sender: this.state.ownUUID,
      });
    },
    setShowInfoIncomingCallModal(val) {
      return store.setShowInfoIncomingCallModal(val);
    },
    onKeyUp(event) {
      if (
        this.spaceShortcutCallAcceptTimeout &&
        event.type === "keyup" &&
        event.keyCode === 32
      ) {
        clearTimeout(this.spaceShortcutCallAcceptTimeout);
        this.spaceShortcutCallAcceptTimeout = null;
        // if (this.$refs.cancelCall && this.$refs.cancelCall.length) {
        //   this.$refs.cancelCall[this.$refs.cancelCall.length - 1].$el.click();
        // }
        this.closeModal()
      } else if (event.type === "keyup" && event.keyCode === 32) {
        if (this.$refs.acceptCall) {
          if (
            event.target.nodeName !== "TEXTAREA" &&
            event.target.nodeName !== "INPUT" &&
            !this.state.showModalSenMsg
          ) {
            this.spaceShortcutCallAcceptTimeout = setTimeout(() => {
              this.$refs.acceptCall.$el.click();
              this.spaceShortcutCallAcceptTimeout = null;
            }, 300);
          }
        }
      }
    },
    changeCallSetting() {
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.videoCameraCallOn = false;
      temporalUserSettings.audioCallOn = false;
      store.setUserSettings(temporalUserSettings);
    },
    async resolveCall(call) {
      if (call.confId) {
        //fetchEvent
        const timeLineEvent = await fetchEventByUUIDEffect(call.confId);
        if (
          timeLineEvent &&
          (timeLineEvent.viewed || false) &&
          timeLineEvent.viewed[store.state.ownUUID]
        ) {
          if (timeLineEvent.viewed[store.state.ownUUID].status === "false") {
            dispatchViewedTimelineEntry([timeLineEvent.uuid, true]);
          }
        }
        if (
          timeLineEvent &&
          timeLineEvent.rsvp &&
          timeLineEvent.rsvp[store.state.ownUUID] &&
          timeLineEvent.rsvp[store.state.ownUUID].status === "pending"
        ) {
          dispatchRsvpTimelineEntry([timeLineEvent.uuid, true]);
        }
      }
      store.setCurrentContentVisile("home", false, this.$router);
      let normalMode;
      if (call.isNormalMode != undefined) {
        normalMode = call.isNormalMode;
      } else if (
        call.isNormalMode == undefined &&
        this.state.remoteBridgeStreams[call.callUUID]
      ) {
        normalMode = this.state.remoteBridgeStreams[call.callUUID].isNormalMode;
      } else {
        normalMode = true;
      }
      let callStartTs = call.callStartTs;
      let callDurationMs = call.callDurationMs;
      let infiniteCall = call.infiniteCall;
      if (this.state.remoteBridgeStreams[call.callUUID]) {
        callDurationMs =
          this.state.remoteBridgeStreams[call.callUUID].callDurationMs;
        callStartTs = this.state.remoteBridgeStreams[call.callUUID].callStartTs;
        infiniteCall =
          this.state.remoteBridgeStreams[call.callUUID].infiniteCall;
      }
      store.removeAcceptedCallNotification(call.uuid);
      if (this.getIsConferenceHall && !this.AmIPodium){
        this.changeCallSetting();
        await this.$nextTick();
      }
      const info = {
        callUUID: call.callUUID,
        calling: [],
        isNormalMode: normalMode,
        callStartTs: callStartTs,
        callDurationMs: callDurationMs,
        infiniteCall: infiniteCall,
        conferenceDocuments: call.conferenceDocuments || [],
        userConferencePosition: call.userConferencePosition || [],
        initiator: call.initiator || undefined,
        excludedFromCalling: call.usersToExclude || [],
        conferenceAttendees: call.conferenceAttendees || [],
        conferenceSettings: call.conferenceSettings || {},
        usersToHideName: call.usersToHideName || [],
        confId: call.confId || null,
        isConferenceCall: call.isConferenceCall || undefined,
        isAudioOnly: (this.getIsConferenceHall && !this.AmIPodium) || !this.videoCameraCallOn,
        conferenceHall: call.conferenceHall || undefined,
        liveBroadcastId: call.liveBroadcastId || undefined,
        inBridgeCallListener: (this.getIsConferenceHall && !this.AmIPodium)
      };
      store.addRemoteBridgeStream(call.callUUID, info);
      if (!this.getIsConferenceHall || (this.getIsConferenceHall && this.AmIPodium)) {
        wsCall("sendToUUID", call.uuid, {
          type: "bridge-signal",
          action: "acept_bridge_call",
          sender: this.state.ownUUID,
          info: { ...info, isAudioOnly: true }
        });
      }
      if (call.conferenceHall) {
        const j = JSON.stringify(call.conferenceAttendees);
        for (let i = 0; i < call.conferenceAttendees.length; i++) {
          const member = call.conferenceAttendees[i];
          const isOnline = (this.bridgeInfoStore?.users || {})[member.uuid] === call.callUUID;
          const curr = JSON.stringify(store.state.group[member.uuid]?.user?.bridgeCallInfo?.conferenceAttendees);
          if (isOnline && member.uuid !== this.state.ownUUID && j !== curr) {
            wsCall("sendToUUID", member.uuid, {
              type: "bridge-signal",
              action: "updateConferenceAttendees",
              sender: this.state.ownUUID,
              info: {
                callUUID: call.callUUID,
                confAttendees: call.conferenceAttendees,
              },
            });
          }
        }
      }
      store.appendIncomingCallHistory(call.uuid, call.callUUID, Date.now());
      store.setIncomingBridgeCallFor(call.uuid, undefined);
    },
    rejectCall(call, customMessage) {
      wsCall("sendToUUID", call.uuid, {
        type: "bridge-signal",
        action: "reject_bridge_call",
        sender: this.state.ownUUID,
        info: {
          callUUID: call.callUUID,
        },
      });
      store.setIncomingBridgeCallFor(call.uuid, undefined);
      if (customMessage) {
        setTimeout(() => {
          this.declineDefaultMessage(call.uuid, customMessage);
        }, 5000);
      }
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
  },
  computed: {
    showMembersContent(){
      if (this.call && this.call.conferenceAttendees?.length > 0) return true;
      return false;
    },
    getCallMembersName(){
      return (this.call.conferenceAttendees || []).map((person) => { return person?.name }).join(", ");
    },
    videoCameraCallOn() {
      return store.state.user.userSettings.videoCameraCallOn;
    },
    getIsConferenceHall(){
      return this.call.conferenceHall
    },
    AmIPodium(){
      if(this.call.conferenceHall && this.call && this.call.conferenceAttendees){
        let myData = this.call.conferenceAttendees.find(e=>e.uuid == this.ownUUID)
        if(myData && myData.isPodium){
          return true
        }
      }
      return false
    },
    showCameraPreview(){
      if(!this.amInABridgeCall){
        if(this.call.conferenceHall && !this.AmIPodium){
          return false
        }
        return true
      }
      return false
    },
    amInABridgeCall() {
      return amInABridgeCall(this.ownUUID);
    },
    isCallProtected() {
      if (this.hasConfrimmedPin) {
        return false;
      } else {
        return this.call.isPinProtected && this.call.pinCode.length > 0;
      }
    },
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.breakpoint;
      return lg
        ? [3, 3, 6]
        : sm
        ? [3, 3, 6]
        : md
        ? [3, 3, 6]
        : xl
        ? [3, 3, 6]
        : [12, 12, 12];
    },
  },
};
</script>
<style scoped lang="scss">
.conferenceMembers {
  overflow-y: auto;
  max-height: 75px;
}
.callButton {
  width: auto !important;
}
.theme--dark.v-btn.v-btn--disabled, .theme--dark.v-btn.v-btn--disabled .v-btn__loading, .theme--dark.v-btn.v-btn--disabled .v-icon{
  color: #00000042!important
}
.video-slash {
  width: 25px !important;
  height: 25px !important;
}
.microphone-slash {
  width: 25px !important;
  height: 25px !important;
  color: #fff;
}
.spanModalCall {
  font-size: 18px;
  word-break: break-word;
  max-width: 90%;
  line-height: 2;
}
.dataUserCard {
  margin-bottom: 0px;
}
.v-avatar .v-image {
  border-radius: 10px !important;
}
.borderRadius4,
.v-avatar .v-image {
  border-radius: 4px !important;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: unset !important;
}
</style>
