<template>
  <v-dialog
    v-model="showQrCodeModal"
    persistent
    max-width="400"
    @keydown.esc="closeModal"
  >
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('components.qrModal.qrCode')"
          :closeModalFunction="closeModal"
        >
          <v-spacer></v-spacer>
          <span class="font-weight-bold">{{ timeLeft }}</span>
        </HeaderModal>
        <v-card-text class="py-0 pt-4">
          <div class="d-flex justify-center">
            <v-img
              v-if="inviteQR"
              :src="inviteQR"
              max-height="200"
              max-width="200"
            ></v-img>
            <v-alert v-if="errMsg" type="error" :dismissible="true">{{
              errMsg
            }}</v-alert>
          </div>
        </v-card-text>
        <FooterModal :closeModalFunction="closeModal" />
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import qrcode from "qrcode";
import store from "../../store";
import { resetQrCodeModalEvent, qrCodeModalStore } from "../../effector/modals";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { getUserInviteLink } from "../../lib/wsMsg";

export default {
  components: { HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
    inviteQR: "",
    errMsg: "",
    timeLeft: null,
  }),
  mounted() {
    this.timeLeft = 10;
    this._interval = setInterval(this.tick.bind(this), 1000);
  },
  destroyed() {
    this.timeLeft = null;
    clearInterval(this._interval);
  },
  methods: {
    tick() {
      this.timeLeft--;
    },
    async loadData() {
      this.errMsg = "";
      try {
        let inviteLink = await getUserInviteLink(this.ownUUID);
        inviteLink = inviteLink.replace("https://", "voffice://");
        this.inviteQR = await qrcode.toDataURL(inviteLink);
      } catch (err) {
        this.errMsg = err.message || err;
      }
    },
    closeModal() {
      resetQrCodeModalEvent();
    },
  },
  effector: {
    showQrCodeModal: qrCodeModalStore,
  },
  watch: {
    timeLeft(val) {
      if (val === null) return;
      if (val <= 0) this.closeModal();
    },
    showQrCodeModal: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.loadData();
        } else {
          this.inviteQR = "";
        }
      },
    },
  },
};
</script>