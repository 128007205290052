import Vue from 'vue';
import TwoFaPrompt from './components/2fa/2fa.vue';
import vuetify from './vuetify';

let inited = false;
export const initTwoFaPrompt = () => {
  if (inited) return;
  inited = true;
  try {
    TwoFaPrompt.vuetify = vuetify;
    const root = new Vue({
      render: createElement => createElement(TwoFaPrompt)
    });
    root.$mount('#two_anchor');
  } catch (err) {
    console.warn('Init Vue Error:', err);
  }
};
