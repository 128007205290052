<template>
  <div>
    <v-dialog
      v-model="showModalDirectLink"
      persistent
      max-width="750"
      @keydown.esc="cleanUpOnCancel"
    >
      <v-card>
        <div>
          <v-row flex class="w-100 mx-0 my-0 justify-space-between">
            <v-col class="px-0 py-0" align="left">
              <v-card-title class="headline">
                <v-img
                  class="mr-1"
                  src="assets/icon.png"
                  max-height="30"
                  max-width="30"
                  contain
                ></v-img>
                {{ $t("components.drawerContentCard.directLink") }}
              </v-card-title>
            </v-col>
          </v-row>
          <v-col class="col-6 px-4 py-1 pt-0 mt-3">
            <v-text-field
              v-model="confTitle"
              :label="$t('components.inviteVisitor.conferenceName')"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>

          <directCallMaskMultiUser
            ref="directCallMaskRef"
            :inviteList="inviteList"
          />

          <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
            <v-row class="px-2 btns w-100 mx-0 my-0">
              <template>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mr-2"
                      :disabled="
                        !$refs.directCallMaskRef.userListToSend ||
                        $refs.directCallMaskRef.userListToSend.length === 0 ||
                        confTitle.trim().length < 1
                      "
                      v-on="on"
                      @click="precheckNewVisitor"
                      :loading="$refs.directCallMaskRef.isProcessing"
                      >{{ $t("generics.add") }}</v-btn
                    >
                  </template>
                  <span>{{ $t("generics.add") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mr-2"
                      v-on="on"
                      @click="cleanUpOnCancel()"
                      :disabled="$refs.directCallMaskRef.isProcessing"
                      >{{ $t("generics.cancel") }}</v-btn
                    >
                  </template>
                  <span>{{ $t("generics.cancel") }}</span>
                </v-tooltip>
              </template>
            </v-row>
          </v-card>
          <v-spacer></v-spacer>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "../../store";
import directCallMaskMultiUser from "../timeline/directCallMaskMultiUser.vue";
export default {
  props: [
    "showModalDirectLink",
    "openCloseModalDirectLink",
    "inviteList",
    "forceSingleUse",
    "cancelMultiSelect",
    "title",
  ],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      confTitle: "",
    };
  },
  mounted() {
    if (this.title && this.title.length) {
      this.confTitle = this.title;
    }
  },
  components: { directCallMaskMultiUser },
  methods: {
    cleanUpOnCancel() {
      this.cancelMultiSelect();
    },
    async precheckNewVisitor() {
      this.openCloseModalDirectLink(this.confTitle);
    },
    async addUserToList() {
      this.$refs.directCallMaskRef.addUserToList();
    },
  },
};
</script>
<style scoped lang="scss">
.buttonAddUserToList {
  position: relative;
  top: 13px;
}
.selactive {
  background-color: #c1c1c166;
}
.w-100 {
  width: 100%;
}
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  color: #02889d;
}
.dataUserCard {
  margin-bottom: 0px;
}
.borderRadius10,
.v-avatar .v-image {
  border-radius: 10px !important;
}
.w-200 {
  width: 200px;
}
.w-100-230 {
  width: calc(100% - 230px);
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
