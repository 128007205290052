<template>
  <div class="w-100">
    <v-row
      flex
      class="w-100 mx-0 my-0 justify-space-between"
      v-if="!actualDate"
    >
      <v-col class="px-0 py-0" align="left">
        <v-card-title :class="isMobile ? 'headlineMobile' : 'headline'">
          <v-img
            src="assets/icon.png"
            max-height="30"
            max-width="30"
            contain
          ></v-img>
          <span
            class="font-weight-medium pr-2 pl-4 spanModalCall"
            v-html="titleModal"
          ></span>
          <v-tooltip top v-if="!!showInfoButton" content-class="videoButtonTT">
            <template v-slot:activator="{ on }">
              <font-awesome-icon
                v-on="on"
                color="#02889d"
                :icon="['fal', 'info-circle']"
                :style="{
                  fontSize: '17px',
                  position: 'relative',
                }"
              />
            </template>
            <span>
              {{
                showInfoButton == "holidays"
                  ? $t("components.holidays.infoTT")
                  : ""
              }}
            </span>
          </v-tooltip>
          <slot></slot>
        </v-card-title>
      </v-col>

      <div class="pa-4" v-if="closeModalFunction">
        <template>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="black"
                ref="closeButton"
                @click="closeModalFunction"
              >
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '25px' }"
                  :color="state.persisted.isDark ? 'white' : 'black'"
                />
              </v-btn>
            </template>
            <span>{{ $t("generics.close") }}</span>
          </v-tooltip>
        </template>
      </div>
    </v-row>

    <v-card-title :class="`${isMobile ? 'headlineMobile' : 'headline styleHeader'}`" primary-title v-else>
      <v-row class="mx-0">
        <v-col cols="8" class="d-flex px-1 py-1">
          <v-img
            :max-height="30"
            :max-width="30"
            :src="'assets/icon.png'"
            class="imageLogo"
            contain
          ></v-img>
          <span class="font-weight-medium pl-4 spanModalCall"
            >{{ titleModal }}
          </span>
        </v-col>
        <v-col cols="4" class="px-1 py-1 text-right">
          <span class="font15 pr-2">{{ actualDate }}</span>
          <v-tooltip top v-if="closeModalFunction">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="black"
                ref="closeButton"
                @click="closeModalFunction($event)"
              >
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '25px' }"
                  :color="state.persisted.isDark ? 'white' : 'black'"
                />
              </v-btn>
            </template>
            <span>{{ $t("generics.close") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
  </div>
</template>


<script>
import store from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
export default {
  components: {},
  props: ["titleModal", "closeModalFunction", "actualDate", "showInfoButton"],
  data: () => ({
    state: store.state,
    isMobile: isMobile(),
  }),
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss">
.headlineMobile {
  flex-wrap: unset !important; 
}
.videoButtonTT {
  max-width: 250px;
  background: rgb(97, 97, 97);
  opacity: 1 !important;
}
.spanModalCall {
  font-size: 18px;
  word-break: break-word;
  max-width: 90%;
  line-height: 2;
}
.w-100 {
  width: 100%;
}
.font15 {
  font-size: 15px;
}
/*.styleHeader{
  border-bottom: 1px solid var(--v-primary-base) !important;
  margin-bottom: 15px;
  color: var(--v-primary-base) !important;
}*/
.imageLogo {
  display: inline-block;
  vertical-align: middle;
}
</style>