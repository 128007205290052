<template>
  <div style="position: absolute; width: 100%" id="counter">
    <div
      class="backImage"
      :style="{ backgroundImage: 'url(' + getWaitingRoomBg + ')' }"
    >
      <img
        id="over"
        :class="getClassTemplate"
        v-if="showLogoCompany"
        :src="showCompanyLogo"
        alt="1"
      />
    </div>
  </div>
</template>

<script>
import store from "../../../store";

export default {
  data() {
    return {
      state: store.state,
    };
  },
  computed: {
    getWaitingRoomBg() {
      if (store.state.namespaceSettings.waitingRoomBackground) {
        return (
          store.state.namespaceSettings.waitingRoomBackground ||
          "img/templates/wr_back_01.png"
        );
      } else {
        return (
          store.state.namespaceSettings.waitingRoomSocialMedia.templateBg
            .imagePath || "img/templates/wr_back_01.png"
        );
      }
    },
    showLogoCompany() {
      if (
        !store.state.namespaceSettings.waitingRoomBackground &&
        store.state.namespaceSettings.waitingRoomSocialMedia.templateBg
      ) {
        return true;
      }
      return false;
    },
    getClassTemplate() {
      if (this.showLogoCompany) {
        return (
          store.state.namespaceSettings.waitingRoomSocialMedia.templateBg
            .imageClass || "imageClass1"
        );
      } else {
        return "";
      }
    },
    showCompanyLogo() {
      return this.state.namespaceSettings.companyLogo || "img/icon.png";
    },
  },
};
</script>

<style scoped lang="scss">
.backImage {
  height: calc(100vh - 123px);
  min-width: calc(100vw - 58px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.backImage {
  height: calc(100vh - 50px);
  width: 100vw;
}
#counter {
  position: relative;
  display: inline;
}
#over {
  position: absolute;
  width: 8%;
  z-index: 0;
  background-color: #e4e4e4;
  border-radius: 50%;
  padding: 1%;
  border: 3px solid #dadada;
  box-shadow: 0 5px 15px 0 #3f3f40;
}
.imageClass1 {
  left: 52%;
  top: 28%;
}
.imageClass2{
left: 53%;
    top: 29%;
}
.imageClass3{
left: 71%;
    top: 12%;
}
.imageClass4{
  left: 50%;
  top: 34%;
}
.imageClass5{
  left: 51%;
  top: 16%;

}
.imageClass6{
  left: 43%;
  top: 37%;
}
.imageClass7{
  left: 56%;
  top: 27%;
}
.imageClass8{
  left: 54%;
  top: 36%;
}
.imageClass9{
  left: 63%;
  top: 28%;
}
.imageClass10{
  left: 38%;
  top: 28%;
}
</style>