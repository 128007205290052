<template>
  <v-dialog v-model="showModal" persistent max-width="460" @keydown.esc="closeModal">
    <v-card>
      <HeaderModal :titleModal="$t('generics.stats')" :closeModalFunction="closeModal" />
      <div class="pa-4 pb-0">
        <p
          class=" text-left subtitle-1"
          v-for="item in getCallStatistics"
          v-bind:key="item.ts"
        >
          {{ item.tsDate }}:
          {{ item.rating ? item.rating.toFixed(2) : 5 }}
          <font-awesome-icon
            :icon="['fas', 'star']"
            class="qualityStar"
            :style="{ fontSize: '24px' }"
          />
        </p>
      </div>
      <FooterModal :closeModalFunction="closeModal" :showClose="true" />
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import moment from "../../../sharedsrc/moment";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  props: ["showModal", "closeModal"],
  data() {
    return {
      state: store.state,
    };
  },
  computed: {
    getCallStatistics() {
      const todaysCalls = store.state.namespaceSettings.todaysCalls || {};
      const callStatistics = store.state.namespaceSettings.callStatistics || [];
      const finalStatistics = [...callStatistics];
      if (todaysCalls && todaysCalls.ts && todaysCalls.rating > 0) {
        if (!todaysCalls.tsDate)
          todaysCalls.tsDate = moment(todaysCalls.ts).format("DD.MM.YYYY");
        finalStatistics.push(todaysCalls);
      }
      return finalStatistics.slice(Math.max(finalStatistics.length - 10, 0));
    },
  },
};
</script>
<style scoped lang="scss">
.qualityStar {
  color: #d8c838;
}
</style>
