<template>
  <div class="meter" v-if="state.nerd">
    {{ avg }}
  </div>
</template>

<script>
import store from '../../store';
export default {
  props: ['stream', 'canvasId'],
  data() {
    return {
      state: store.state,
      avg: 0,
      audioContext: undefined,
      canvas: this.canvasId,
    };
  },
  watch: {
    stream: function(newVal, oldVal) {
      // console.log('Audio Meter Watch Stream', newVal, oldVal);
      if (newVal && oldVal && newVal.id === oldVal.id) return;
      this.cleanup();
      if (newVal) {
        this.attachVuMeter(newVal);
      }
    },
  },
  mounted(){
    this.canvas = this.canvasId;
    this.attachVuMeter(this.stream);
  },
  methods: {
    attachVuMeter(stream) {
      try {
        this.audioContext = new window.AudioContext();
        const analyser = this.audioContext.createAnalyser();
        const sourceNode = this.audioContext.createMediaStreamSource(stream);
        const scriptProcessorNode = this.audioContext.createScriptProcessor(2048, 1, 1);

        var max_level_L = 0;
        var old_level_L = 0;
        var cnvs = document.getElementById(this.canvas);
        var cnvs_cntxt = cnvs.getContext("2d");

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        sourceNode.connect(analyser);
        analyser.connect(scriptProcessorNode);
        scriptProcessorNode.connect(this.audioContext.destination);

        scriptProcessorNode.onaudioprocess = (event) => {
          // console.log('AudioStreamMeter =>  on audio process !!!!! ===> ', cnvs_cntxt, cnvs)
          const frequencyArray = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(frequencyArray);
          const sum = frequencyArray.reduce((sum, value) => sum + value, 0);
          const average = sum / frequencyArray.length;
          // console.log('> average', average);
          this.avg = average;

          var inpt_L = event.inputBuffer.getChannelData(0);
          var instant_L = 0.0;

          var sum_L = 0.0;
          for(var i = 0; i < inpt_L.length; ++i) {
            sum_L += inpt_L[i] * inpt_L[i];
          }
          instant_L = Math.sqrt(sum_L / inpt_L.length);
          max_level_L = Math.max(max_level_L, instant_L);				
          instant_L = Math.max( instant_L, old_level_L -0.008 );
          old_level_L = instant_L;
          cnvs_cntxt.clearRect(0, 0, cnvs.width, cnvs.height);
          cnvs_cntxt.fillStyle = '#02889d';
          cnvs_cntxt.fillRect(0, 0,(cnvs.width-20)*(instant_L/max_level_L), cnvs.height); // x,y,w,h


        };
      } catch (err) {
        console.warn('Failed to attach audio for meter:', err.message);
        this.cleanup();
      }
    },
    cleanup() {
      try {
        if (this.audioContext) {
          this.audioContext.close();
          this.audioContext = undefined;
        }
      } catch (err) {
        console.warn('audio meter cleanup:', err.message);
      }
    }
  }
};
</script>

<style scoped lang="scss">

</style>