import {
  faApple as fabApple,
  faFacebook as fabFacebook,
  faGit as fabGit,
  faGooglePlay as fabGooglePlay,
  faLinkedin as fabLinkedin,
  faTwitter as fabTwitter,
  faWhatsapp as fabWhatsapp,
  faYoutube as fabYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
  faCircle as fadCircle,
  faEclipse as fadEclipse,
  faPauseCircle as fadPauseCircle
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faAngleDown as falAngleDown,
  faAngleUp as falAngleUp,
  faArrowAltCircleUp as falArrowAltCircleUp,
  faArrowDown as falArrowDown,
  faArrowUp as falArrowUp,
  faBallotCheck as falBallotCheck,
  faBars as falBars,
  faBell as falBell,
  faBolt as falBolt,
  faBorderAll as falBorderAll,
  faBuilding as falBuilding,
  faCalendar as falCalendar,
  faCalendarAlt as falCalendarAlt,
  faCamera as falCamera,
  faCarBuilding as falCarBuilding,
  faCheck as falCheck,
  faCheckDouble as falCheckDouble,
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faClock as falClock,
  faCog as falCog,
  faComment as falComment,
  faCommentAltLines as falCommentAltLines,
  faCommentSlash as falCommentSlash,
  faCommentTimes as falCommentTimes,
  faComments as falComments,
  faCompressAlt as falCompressAlt,
  faCompressWide as falCompressWide,
  faCopy as falCopy,
  faDesktop as falDesktop,
  faDoorClosed as falDoorClosed,
  faDoorOpen as falDoorOpen,
  faDownload as falDownload,
  faEclipse as falEclipse,
  faEdit as falEdit,
  faEllipsisV as falEllipsisV,
  faEnvelopeSquare as falEnvelopeSquare,
  faEuroSign as falEuroSign,
  faExchange as falExchange,
  faExclamation as falExclamation,
  faExpandAlt as falExpandAlt,
  faExpandWide as falExpandWide,
  faExternalLink as falExternalLink,
  faEye as falEye,
  faFile as falFile,
  faFileExcel as falFileExcel,
  faFilePdf as falFilePdf,
  faFileUpload as falFileUpload,
  faGlobe as falGlobe,
  faHandPointUp as falHandPointUp,
  faHeadset as falHeadset,
  faHistory as falHistory,
  faHome as falHome,
  faHouseUser as falHouseUser,
  faInboxIn as falInboxIn,
  faInboxOut as falInboxOut,
  faInfoCircle as falInfoCircle,
  faLanguage as falLanguage,
  faLayerGroup as falLayerGroup,
  faLink as falLink,
  faLocationArrow as falLocationArrow,
  faLock as falLock,
  faLongArrowUp as falLongArrowUp,
  faLoveseat as falLoveseat,
  faMapMarker as falMapMarker,
  faMicrophone as falMicrophone,
  faMicrophoneSlash as falMicrophoneSlash,
  faMicrophoneStand as falMicrophoneStand,
  faMug as falMug,
  faNotesMedical as falNotesMedical,
  faPalette as falPalette,
  faPaperPlane as falPaperPlane,
  faPause as falPause,
  faPen as falPen,
  faPhone as falPhone,
  faPhonePlus as falPhonePlus,
  faPlay as falPlay,
  faPlus as falPlus,
  faPlusCircle as falPlusCircle,
  faQuestionCircle as falQuestionCircle,
  faRandom as falRandom,
  faRedo as falRedo,
  faReply as falReply,
  faSave as falSave,
  faSearch as falSearch,
  faSignalStream as falSignalStream,
  faSitemap as falSitemap,
  faSlash as falSlash,
  faSpinner as falSpinner,
  faSpinnerThird as falSpinnerThird,
  faStar as falStar,
  faStickyNote as falStickyNote,
  faStop as falStop,
  faTableTennis as falTableTennis,
  faTags as falTags,
  faThLarge as falThLarge,
  faTimes as falTimes,
  faTrash as falTrash,
  faUndo as falUndo,
  faUpload as falUpload,
  faUser as falUser,
  faUserChart as falUserChart,
  faUserClock as falUserClock,
  faUserCog as falUserCog,
  faUserCrown as falUserCrown,
  faUserFriends as falUserFriends,
  faUserHeadset as falUserHeadset,
  faUserPlus as falUserPlus,
  faUserSecret as falUserSecret,
  faUsers as falUsers,
  faUsersClass as falUsersClass,
  faUsersCog as falUsersCog,
  faUsersCrown as falUsersCrown,
  faVideo as falVideo,
  faVideoSlash as falVideoSlash,
  faVolumeSlash as falVolumeSlash,
  faVolumeUp as falVolumeUp
} from '@fortawesome/pro-light-svg-icons';
import {
  faChevronDown as farChevronDown,
  faChevronUp as farChevronUp,
  faClock as farClock,
  faEdit as farEdit,
  faExpandArrows as farExpandArrows,
  faKeyboard as farKeyboard,
  faPoundSign as farPoundSign,
  faQuestionCircle as farQuestionCircle,
  faRedo as farRedo,
  faRepeat as farRepeat,
  faRepeatAlt as farRepeatAlt,
  faSearch as farSearch,
  faShoppingCart as farShoppingCart,
  faSortAlt as farSortAlt,
  faUndo as farUndo
} from '@fortawesome/pro-regular-svg-icons';
import {
  faChevronDown as fasChevronDown,
  faCircle as fasCircle,
  faComment as fasComment,
  faCompress as fasCompress,
  faCrown as fasCrown,
  faEnvelope as fasEnvelope,
  faHandPaper as fasHandPaper,
  faHandPointUp as fasHandPointUp,
  faHeadset as fasHeadset,
  faLayerGroup as fasLayerGroup,
  faMug as fasMug,
  faPen as fasPen,
  faPhone as fasPhone,
  faRectangleWide as fasRectangleWide,
  faRedo as fasRedo,
  faSitemap as fasSitemap,
  faStar as fasStar,
  faStickyNote as fasStickyNote,
  faUser as fasUser,
  faUserFriends as fasUserFriends,
  faUsers as fasUsers,
  faUsersClass as fasUsersClass
} from '@fortawesome/pro-solid-svg-icons';
export default [
  fabApple,
  fabFacebook,
  fabGit,
  fabGooglePlay,
  fabLinkedin,
  fabTwitter,
  fabWhatsapp,
  fabYoutube,
  fadCircle,
  fadEclipse,
  fadPauseCircle,
  falAngleDown,
  falAngleUp,
  falArrowAltCircleUp,
  falArrowDown,
  falArrowUp,
  falBallotCheck,
  falBars,
  falBell,
  falBolt,
  falBorderAll,
  falBuilding,
  falCalendar,
  falCalendarAlt,
  falCamera,
  falCarBuilding,
  falCheck,
  falCheckDouble,
  falChevronLeft,
  falChevronRight,
  falClock,
  falCog,
  falComment,
  falCommentAltLines,
  falCommentSlash,
  falCommentTimes,
  falComments,
  falCompressAlt,
  falCompressWide,
  falCopy,
  falDesktop,
  falDoorClosed,
  falDoorOpen,
  falDownload,
  falEclipse,
  falEdit,
  falEllipsisV,
  falEnvelopeSquare,
  falEuroSign,
  falExchange,
  falExclamation,
  falExpandAlt,
  falExpandWide,
  falExternalLink,
  falEye,
  falFile,
  falFileExcel,
  falFilePdf,
  falFileUpload,
  falGlobe,
  falHandPointUp,
  falHeadset,
  falHistory,
  falHome,
  falHouseUser,
  falInboxIn,
  falInboxOut,
  falInfoCircle,
  falLanguage,
  falLayerGroup,
  falLink,
  falLocationArrow,
  falLock,
  falLongArrowUp,
  falLoveseat,
  falMapMarker,
  falMicrophone,
  falMicrophoneSlash,
  falMicrophoneStand,
  falMug,
  falNotesMedical,
  falPalette,
  falPaperPlane,
  falPause,
  falPen,
  falPhone,
  falPhonePlus,
  falPlay,
  falPlus,
  falPlusCircle,
  falQuestionCircle,
  falRandom,
  falRedo,
  falReply,
  falSave,
  falSearch,
  falSignalStream,
  falSitemap,
  falSlash,
  falSpinner,
  falSpinnerThird,
  falStar,
  falStickyNote,
  falStop,
  falTableTennis,
  falTags,
  falThLarge,
  falTimes,
  falTrash,
  falUndo,
  falUpload,
  falUser,
  falUserChart,
  falUserClock,
  falUserCog,
  falUserCrown,
  falUserFriends,
  falUserHeadset,
  falUserPlus,
  falUserSecret,
  falUsers,
  falUsersClass,
  falUsersCog,
  falUsersCrown,
  falVideo,
  falVideoSlash,
  falVolumeSlash,
  falVolumeUp,
  farChevronDown,
  farChevronUp,
  farClock,
  farEdit,
  farExpandArrows,
  farKeyboard,
  farPoundSign,
  farQuestionCircle,
  farRedo,
  farRepeat,
  farRepeatAlt,
  farSearch,
  farShoppingCart,
  farSortAlt,
  farUndo,
  fasChevronDown,
  fasCircle,
  fasComment,
  fasCompress,
  fasCrown,
  fasEnvelope,
  fasHandPaper,
  fasHandPointUp,
  fasHeadset,
  fasLayerGroup,
  fasMug,
  fasPen,
  fasPhone,
  fasRectangleWide,
  fasRedo,
  fasSitemap,
  fasStar,
  fasStickyNote,
  fasUser,
  fasUserFriends,
  fasUsers,
  fasUsersClass
];
