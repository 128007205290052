<template>
  <v-dialog
    v-model="dailyReportSettingsModal.showModal"
    persistent
    max-width="720"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.adminSettingsModal.dailyReport')"
        :closeModalFunction="closeModal"
      />
      <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
        <template v-if="getTypeModal !== 'members'">
          <v-card-text class="px-0 pb-1 subtitle-2 d-flex align-center">
            {{ getTittle }}
            <v-btn
              class="ml-3"
              icon
              x-small
              @click="
                addEmail = {
                  show: true,
                  type: dailyReportSettingsModal.type,
                  value: dailyReportSettingsModal.value,
                }
              "
              ><font-awesome-icon
                class="primary--text"
                :icon="['fal', 'plus-circle']"
                :style="{
                  fontSize: '20px',
                }"
            /></v-btn>
          </v-card-text>
          <div class="d-flex flex-column">
            <div>
              <v-chip-group column active-class="primary--text">
                <v-chip
                  small
                  close
                  v-for="email in getEmailsTeamsOrSections"
                  :key="email"
                  @click:close="
                    removeEmail(
                      dailyReportSettingsModal.type,
                      dailyReportSettingsModal.value,
                      email
                    )
                  "
                >
                  {{ email }}
                </v-chip>
              </v-chip-group>
            </div>
          </div>
        </template>
        <template v-if="getUsers">
          <v-card-text class="px-0 subtitle-2 pb-0">
            {{ $t("generics.users") }}
          </v-card-text>
          <div class="userContainer">
            <v-list class="pt-0" two-line>
              <v-list-item v-for="userUUID in getUsers" :key="userUUID">
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="d-flex align-center">
                      <div>
                        {{ getTitelForUuid(userUUID) }}
                        {{ getNameForUuid(userUUID) }}
                      </div>
                      <v-btn
                        class="ml-3"
                        icon
                        x-small
                        @click="
                          addEmail = {
                            show: true,
                            type: 'members',
                            value: userUUID,
                          }
                        "
                        ><font-awesome-icon
                          class="primary--text"
                          :icon="['fal', 'plus-circle']"
                          :style="{
                            fontSize: '20px',
                          }"
                      /></v-btn>
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip-group column active-class="primary--text">
                      <v-chip
                        small
                        close
                        v-for="email in getEmailForUser(userUUID)"
                        :key="email"
                        @click:close="removeEmail('members', userUUID, email)"
                      >
                        {{ email }}
                      </v-chip>
                    </v-chip-group>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </template>
      </v-row>

      <v-dialog v-model="addEmail.show" max-width="290">
        <v-card class="px-5 pt-5">
          <v-card-text class="px-0 pb-1 title d-flex align-center">
            Add Email
          </v-card-text>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
          ></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="closeAddEmail">
              cancel
            </v-btn>
            <v-btn color="primary darken-1" text :disabled="disableSaveButton" @click="saveEmail">
              save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <FooterModal :closeModalFunction="closeModal"> </FooterModal>
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import Vue from 'vue';
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import {
  resetDailyReportSettingsModalEvent,
  dailyReportSettingsModalStore,
} from "../../effector/modals";
import { isMobile } from "../../lib/mobileUtil";
import { setNamespaceSetting } from "../../lib/wsMsg";
export default {
  components: { HeaderModal, FooterModal },
  props: [],
  data: () => ({
    isMobile: isMobile(),
    state: store.state,
    email: "",
    addEmail: { show: false, type: undefined, value: undefined },
    emailRules: [
        v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || Vue.prototype.$t('generics.emailMustBeValid') //remove red mesagge ticket #132
      ],
  }),
  effector: {
    dailyReportSettingsModal: dailyReportSettingsModalStore,
  },
  methods: {
    removeEmail(type, value, email) {
      let dataNamespaceSettings = JSON.parse(
        JSON.stringify(store.state.namespaceSettings.dailyReportSettings)
      );
      let sectionsEmails = dataNamespaceSettings.sections;
      let teamsEmails = dataNamespaceSettings.teams;
      let usersEmail = dataNamespaceSettings.members;
      const organisation = this.state.namespaceSettings.processedOrganisation;
      const teams = organisation.teams;
      switch (type) {
        case "sections": {
          if (sectionsEmails[value]) {
            const emailIndex = sectionsEmails[value].indexOf(email);
            if (emailIndex !== -1) {
              sectionsEmails[value].splice(emailIndex, 1);
            }
          }
          const sectionTeams = organisation.sections[value].teams;
          sectionTeams.forEach((team) => {
            teamsEmails = this.prepareRemoveTeamEmail(teamsEmails, team, email);
            const users = teams[team].members;
            users.forEach((uuid) => {
              usersEmail = this.prepareRemoveUserEmail(usersEmail, uuid, email);
            });
          });
          break;
        }
        case "teams": {
          teamsEmails = this.prepareRemoveTeamEmail(teamsEmails, value, email);
          const users = teams[value].members;
          users.forEach((uuid) => {
            usersEmail = this.prepareRemoveUserEmail(usersEmail, uuid, email);
          });
          break;
        }
        case "members":
          usersEmail = this.prepareRemoveUserEmail(usersEmail, value, email);
          break;
        default:
          break;
      }
      dataNamespaceSettings.sections = sectionsEmails;
      dataNamespaceSettings.teams = teamsEmails;
      dataNamespaceSettings.members = usersEmail;
      setNamespaceSetting("dailyReportSettings", dataNamespaceSettings);
    },
    prepareRemoveTeamEmail(teamsEmail, teamName, emailToRemove) {
      let finalArray = teamsEmail;
      const emailIndex = finalArray[teamName].indexOf(emailToRemove);
      if (finalArray[teamName]) {
        if (emailIndex !== -1) {
          finalArray[teamName].splice(emailIndex, 1);
        }
        return finalArray;
      }
    },
    prepareRemoveUserEmail(usersEmail, userUUID, emailToRemove) {
      let finalArray = usersEmail;
      if (finalArray[userUUID]) {
        const emailIndex = finalArray[userUUID].indexOf(emailToRemove);
        if (emailIndex !== -1) {
          finalArray[userUUID].splice(emailIndex, 1);
        }
        return finalArray;
      }
    },
    saveEmail() {
      const { type, value } = this.addEmail;
      let dataNamespaceSettings = JSON.parse(
        JSON.stringify(store.state.namespaceSettings.dailyReportSettings)
      );
      let sectionsEmails = dataNamespaceSettings.sections;
      let teamsEmails = dataNamespaceSettings.teams;
      let usersEmail = dataNamespaceSettings.members;
      const organisation = this.state.namespaceSettings.processedOrganisation;
      const teams = organisation.teams;
      switch (type) {
        case "sections": {
          if (!sectionsEmails[value]) {
            if(this.email && this.email.trim().length) sectionsEmails = { ...sectionsEmails, [value]: [this.email] };
          } else {
            if (this.email && sectionsEmails[value].indexOf(this.email) == -1 && this.email.trim().length) {
              sectionsEmails[value].push(this.email);
            }
          }
          const sectionTeams = organisation.sections[value].teams;
          sectionTeams.forEach((team) => {
            teamsEmails = this.prepareTeamEmailsArray(teamsEmails, team);
            const users = teams[team].members;
            users.forEach((uuid) => {
              usersEmail = this.prepareUserEmailsArray(usersEmail, uuid);
            });
          });
          break;
        }
        case "teams": {
          teamsEmails = this.prepareTeamEmailsArray(teamsEmails, value);
          const users = teams[value].members;
          users.forEach((uuid) => {
            usersEmail = this.prepareUserEmailsArray(usersEmail, uuid);
          });
          break;
        }
        case "members":
          usersEmail = this.prepareUserEmailsArray(usersEmail, value);
          break;
        default:
          break;
      }
      dataNamespaceSettings.sections = sectionsEmails;
      dataNamespaceSettings.teams = teamsEmails;
      dataNamespaceSettings.members = usersEmail;
      setNamespaceSetting("dailyReportSettings", dataNamespaceSettings);
      this.closeAddEmail();
    },
    closeAddEmail() {
      this.email = "";
      this.addEmail = { show: false, type: undefined, value: undefined };
    },
    prepareTeamEmailsArray(teamsEmails, teamName) {
      let finalArray = teamsEmails;
      if (!finalArray[teamName]) {
        if (this.email && this.email.trim().length) finalArray = { ...finalArray, [teamName]: [this.email] };
      } else {
        if (this.email && finalArray[teamName].indexOf(this.email.trim().length) == -1 && this.email.trim().length) {
          finalArray[teamName].push(this.email);
        }
      }
      return finalArray;
    },
    prepareUserEmailsArray(usersEmail, userUUID) {
      let finalArray = usersEmail;
      if (!finalArray[userUUID]) {
        if (this.email && this.email.trim().length) finalArray = { ...finalArray, [userUUID]: [this.email] };
      } else {
        if (this.email && finalArray[userUUID].indexOf(this.email.trim().length) == -1 && this.email.trim().length) {
          finalArray[userUUID].push(this.email);
        }
      }
      return finalArray;
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getEmailForUser(userUUID) {
      if (!userUUID) return false;
      const emails =  ((store.state.namespaceSettings.dailyReportSettings.members || {})[userUUID])||[];
      const finalResult = emails.filter((email)=> email && email.trim().length);
      return finalResult;
    },
    closeModal() {
      resetDailyReportSettingsModalEvent(false);
    },
  },
  computed: {
    disableSaveButton() {
      const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEx.test(this.email)) return true;
      return false;
    },
    getEmailsTeamsOrSections() {
      if (
        !this.dailyReportSettingsModal ||
        !this.dailyReportSettingsModal.value ||
        !this.dailyReportSettingsModal.type
      ) {
        return [];
      }
      if (
        this.dailyReportSettingsModal.type == "teams" ||
        this.dailyReportSettingsModal.type == "sections"
      ) {
        const emails =  (store.state.namespaceSettings.dailyReportSettings[
          this.dailyReportSettingsModal.type
        ][this.dailyReportSettingsModal.value])||[];
        const filterEmails =  emails.filter((email)=> email && email.trim().length)
        return filterEmails;
      }
      return [];
    },
    getUsers() {
      let users = [];
      const organisation = this.state.namespaceSettings.processedOrganisation;
      const teams = organisation.teams;
      switch (this.dailyReportSettingsModal.type) {
        case "teams":
          users =
            organisation.teams[this.dailyReportSettingsModal.value].members;
          break;
        case "sections": {
          const sectionTeams =
            organisation.sections[this.dailyReportSettingsModal.value].teams;
          sectionTeams.forEach((team) => {
            let temporalArray = users || [];
            users = temporalArray.concat(teams[team].members);
          });
          break;
        }
        case "members":
          users = [this.dailyReportSettingsModal.value];
          break;
        default:
          users = [];
          break;
      }
      return users;
    },
    getTypeModal() {
      return (
        this.dailyReportSettingsModal && this.dailyReportSettingsModal.type
      );
    },
    getTittle() {
      switch (this.dailyReportSettingsModal.type) {
        case "teams":
          return `${this.$t("excel.team")}: ${
            this.dailyReportSettingsModal.value
          }`;
        case "sections":
          return `${this.$t("excel.section")}: ${
            this.dailyReportSettingsModal.value
          }`;
        case "members":
          return `${this.$t("generics.user")}: ${this.getTitelForUuid(
            this.dailyReportSettingsModal.value
          )} ${this.getNameForUuid(this.dailyReportSettingsModal.value)}`;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.userContainer {
  max-height: 235px;
  overflow: auto;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
</style>