var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.messagesArray.length !== 0 ||
    Object.keys(_vm.state.FileTransferModal.files).length !== 0
  )?_c('v-list',{class:{'py-0': _vm.isLateralPanel},attrs:{"two-line":"","min-height":310,"height":_vm.isLateralPanel? '100%' :'370px'}},[(_vm.isMobile)?_c('div',{staticClass:"closeButton",on:{"click":function($event){return _vm.closeMenu()}}},[_c('v-btn',{attrs:{"icon":""}},[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'times']}})],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"vueScroll"},[_c('v-list-item-group',{attrs:{"id":"messagesDropDown"}},[(Object.keys(_vm.state.FileTransferModal.files).length !== 0)?_c('div',_vm._l((_vm.state.FileTransferModal.files),function(entry,key){return _c('div',{key:key},[_c('FileItemList',{attrs:{"entry":entry}})],1)}),0):_vm._e(),_vm._v(" "),_vm._l((_vm.messagesArray),function(message,index){return _c('div',{key:index},[(
              (message && message.type !== 'message') ||
              (message.type === 'message' &&
                _vm.state.group[_vm.getDataUserMessage(message)])
            )?_c('v-list-item',{key:index,style:({background: _vm.isLateralPanel ? '#464b4e' : ''}),on:{"click":function($event){_vm.closeMenu();
              _vm.openNotification(message);}},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [(!_vm.isLateralPanel)?_c('v-list-item-avatar',{staticClass:"borderRadius4 cursorPointer",style:(_vm.getBorderByStatusFull(_vm.state.group[_vm.getDataUserMessage(message)], '2px')),attrs:{"tile":"","size":"60"}},[(
                    _vm.getAvatarForUuid(_vm.getDataUserMessage(message)) ==
                    'img/default_profile_picture.png'
                  )?_c('DefaultAvatarGrid',{attrs:{"size":120,"userData":_vm.state.group[_vm.getDataUserMessage(message)],"isLateralPanel":_vm.isLateralPanel}}):_vm._e(),_vm._v(" "),(
                    _vm.getAvatarForUuid(_vm.getDataUserMessage(message)) !=
                    'img/default_profile_picture.png'
                  )?_c('v-img',{staticClass:"mx-2 borderRadius4",attrs:{"max-height":"60","max-width":"60","contain":"","src":_vm.getAvatarForUuid(_vm.getDataUserMessage(message))}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.isLateralPanel)?_c('v-list-item-avatar',{staticClass:"lateralPanelAvatar",style:(_vm.getBorderByStatusFull( _vm.state.group[_vm.getDataUserMessage(message)], '2px'))},[_c('v-img',{staticClass:"white--text align-end",attrs:{"contain":"","height":"80","src":_vm.getAvatarForUuid(_vm.getDataUserMessage(message))}})],1):_vm._e(),_vm._v(" "),_c('v-list-item-content',{class:{'pl-2': !_vm.isLateralPanel, 'messagesContentLaterlPanel': _vm.isLateralPanel}},[_c('v-list-item-title',{class:!_vm.isLateralPanel? _vm.unreadNotification(message): '',style:({ color: _vm.isLateralPanel ? '#fff !important' : '' , height: _vm.isLateralPanel ? '35px' : ''}),domProps:{"textContent":_vm._s(_vm.getNameForUuid(_vm.getDataUserMessage(message)))}}),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"text--primary",class:_vm.isLateralPanel? _vm.unreadNotification(message): '',style:({ color: _vm.isLateralPanel ? '#fff !important' : '' }),domProps:{"textContent":_vm._s(_vm.getTextBody(message))}})],1),_vm._v(" "),_c('v-list-item-action',{staticClass:"ml-0"},[_c('v-list-item-action-text',{class:_vm.isLateralPanel? _vm.unreadNotification(message): '',style:({ color: _vm.isLateralPanel ? '#fff !important' : '' }),domProps:{"textContent":_vm._s(_vm.formatNoticationDate(message.date))}}),_vm._v(" "),_c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[_c('DeleteBasket',{attrs:{"indexData":message,"delFunction":_vm.deleteNotification,"extraClasses":"mr-4","fontSize":"16","prevent":"true"}}),_vm._v(" "),(
                      !_vm.isWaitingRoomUser &&
                      (message.type == 'message' ||
                        message.type == 'plannerEvent')
                    )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('font-awesome-icon',_vm._g({staticClass:"mr-4",style:({
                          color: _vm.isLateralPanel ? '#fff !important' : '',
                        }),attrs:{"icon":['fal', 'paper-plane']},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.closeMenu();
                          _vm.setShowModalSenMsg(
                            _vm.getDataUserMessage(message),
                            'message'
                          );}}},on))]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.userProfile.sendAMessage")))])]):_vm._e(),_vm._v(" "),(
                      message.type === 'call' &&
                      !_vm.isWaitingRoomUser &&
                      !_vm.isSentMessage(message)
                    )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('font-awesome-icon',_vm._g({staticClass:"mr-4",attrs:{"icon":['fal', 'phone'],"color":!_vm.disableCallBtn(message.creatorUUID)
                            ? _vm.setBorderByStatus(
                                _vm.state.group[message.creatorUUID]
                              )
                            : 'grey'},on:{"click":function($event){$event.stopPropagation();_vm.closeMenu();
                          _vm.disableCallBtn(message.creatorUUID)
                            ? false
                            : _vm.callUser(message.creatorUUID);}}},on))]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.disableCallBtn(message.creatorUUID)
                        ? "Dieser Benutzer ist zur Zeit offline"
                        : _vm.$t("components.userProfile.callUser")))])]):_vm._e(),_vm._v(" "),(message.type === 'file')?_c('v-tooltip',{attrs:{"top":""}},[[_c('font-awesome-icon',{staticClass:"mr-4",staticStyle:{"pointer-events":"none"},style:({
                          color: _vm.isLateralPanel ? '#fff !important' : '',
                        }),attrs:{"icon":['fal', 'download'],"color":"grey lighten-1"}})],_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.downloadsDropdown.fileNotAvailable")))])],2):_vm._e()],1)],1)]}}],null,true)}):_vm._e()],1)})],2)],1),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnRed",staticStyle:{"float":"right","margin":"5px 5px 0px 0px"},attrs:{"icon":"","color":"white"}},on),[_c('DeleteBasket',{attrs:{"indexData":"","delFunction":_vm.removeAllListedItemsEffect,"extraClasses":"","fontSize":"20","prevent":"false"}})],1)]}}],null,false,2380448236)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.downloadsDropdown.removeAll")))])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }