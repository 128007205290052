<template >
  <v-dialog
    v-model="showModal"
    persistent
    max-width="550"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('generics.info')"
        :closeModalFunction="closeModal"
      />
      <v-divider></v-divider>
      <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
        <v-card-text>
          <span>
            {{ $t("components.adminSettingsModal.removeICEServer") }}</span
          >
        </v-card-text>
      </v-row>
      <FooterModal :closeModalFunction="closeModal" :hideDefaultButtons="true">
        <v-btn
          class="ml-3 buttonIconPrimaryFooter footerButton px-2"
          icon
          tile
          color="primary"
          @click="remove"
        >
          {{ $t("generics.accept") }}
        </v-btn>
        <v-btn
          class="ml-3 buttonIconPrimaryFooter footerButton px-2"
          icon
          tile
          color="primary"
          @click="closeModal"
        >
          {{ $t("generics.cancel") }}
        </v-btn>
      </FooterModal>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  props: ["showModal", "closeModal", "removeICEServer"],
  components: { HeaderModal, FooterModal },
  data: () => ({}),
  methods: {
    remove(){
      this.removeICEServer()
      this.closeModal()
    }
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.footerButton {
  width: auto !important;
}
</style>