var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"720","fullscreen":_vm.isMobile},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModal.apply(null, arguments)}},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',[_c('v-card',[_c('HeaderModal',{attrs:{"titleModal":_vm.notificationInfo.type == 'message'
            ? _vm.$t('components.notificationsModal.message')
            : 'Notification',"closeModalFunction":_vm.closeModal}}),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-row',{style:({ width: '100%', margin: 0 }),attrs:{"flex":""},on:{"click":function($event){!_vm.isWaitingRoomUser ? _vm.handleClickZone(_vm.notificationInfo) : null}}},_vm._l((1),function(n){return _c('v-col',{key:n,staticClass:"col-12"},[(n == 1)?_c('div',{staticClass:"d-flex"},[_c('v-badge',{attrs:{"bordered":"","overlay":"","height":"60","width":"60","bottom":"","color":_vm.badgetClassObj(_vm.notificationInfo),"dot":"","offset-x":"17","offset-y":"9"}},[(!_vm.isASentMessageNotification)?_c('v-list-item-avatar',{staticClass:"borderRadius10",staticStyle:{"margin":"0px 10px"},attrs:{"tile":"","size":"76"}},[(
                    _vm.getAvatarForUuid(_vm.notificationInfo.creatorUUID) ===
                    'img/default_profile_picture.png'
                  )?_c('DefaultAvatar',{attrs:{"size":76,"userData":_vm.getPerson(_vm.notificationInfo.creatorUUID).user}}):_vm._e(),_vm._v(" "),(
                    _vm.getAvatarForUuid(_vm.notificationInfo.creatorUUID) !==
                    'img/default_profile_picture.png'
                  )?_c('v-img',{staticClass:"borderRadius10",attrs:{"max-height":"76","max-width":"76","contain":"","src":_vm.getAvatarForUuid(_vm.notificationInfo.creatorUUID),"lazy-src":"img/default_profile_picture.png"}}):_vm._e()],1):_c('v-list-item-avatar',{staticClass:"borderRadius10",staticStyle:{"margin":"0px 10px"},attrs:{"tile":"","size":"76"}},[(
                    _vm.getAvatarForUuid(
                      Object.keys(_vm.notificationInfo.users)[0]
                    ) === 'img/default_profile_picture.png'
                  )?_c('DefaultAvatar',{attrs:{"size":76,"userData":_vm.getPerson(Object.keys(_vm.notificationInfo.users)[0]).user}}):_vm._e(),_vm._v(" "),(
                    _vm.getAvatarForUuid(
                      Object.keys(_vm.notificationInfo.users)[0]
                    ) !== 'img/default_profile_picture.png'
                  )?_c('v-img',{staticClass:"borderRadius10",attrs:{"max-height":"76","max-width":"76","contain":"","src":_vm.getAvatarForUuid(Object.keys(_vm.notificationInfo.users)[0]),"lazy-src":"img/default_profile_picture.png"}}):_vm._e()],1)],1),_vm._v(" "),_c('v-row',{style:({ width: '80%', margin: 0 })},[_c('v-col',{staticClass:"pb-0 pt-1 d-flex",attrs:{"cols":"12"}},[_c('p',{staticClass:"userNameCard mb-0 pb-0 mr-auto",attrs:{"color":"primary"}},[_vm._v("\n                  "+_vm._s(_vm.isASentMessageNotification
                      ? _vm.getNameForUuid(Object.keys(_vm.notificationInfo.users)[0])
                      : _vm.getNameForUuid(_vm.notificationInfo.creatorUUID))+"\n                ")]),_vm._v(" "),(
                    (_vm.notificationInfo.type === 'call' ||
                      _vm.notificationInfo.type === 'message') &&
                    !_vm.isWaitingRoomUser &&
                    _vm.notificationInfo.creatorUUID != _vm.ownUUID
                  )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"bR4",style:(_vm.isCall
                          ? 'width:70px !important; height:70px !important;'
                          : 'width:60px !important'),attrs:{"id":"buttonCallDirectly","color":"primary","disabled":_vm.disableCallBtn},on:{"click":function($event){_vm.disableCallBtn
                          ? false
                          : _vm.callUser(_vm.notificationInfo.creatorUUID);
                        _vm.closeModal();}}},on),[(_vm.notificationInfo.type === 'call')?_c('font-awesome-icon',{style:({ fontSize: '50px' }),attrs:{"icon":['fal', 'phone']}}):_vm._e(),_vm._v(" "),(_vm.notificationInfo.type === 'message')?_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'phone']}}):_vm._e()],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.disableCallBtn
                      ? "Dieser Benutzer ist zur Zeit offline"
                      : _vm.$t("components.userProfile.callUser")))])]):_vm._e()],1)],1)],1):_vm._e()])}),1),_vm._v(" "),_c('v-divider'),_vm._v(" "),(
          _vm.notificationInfo.type === 'call' &&
          !_vm.isWaitingRoomUser &&
          _vm.state.persisted.tmpMessages.showMessageInitiateCallSpace
        )?_c('v-row',{staticClass:"px-5",style:({ width: '100%', margin: 0 })},[_c('p',{staticStyle:{"font-size":"14px","margin":"0 auto","font-weight":"400"}},[_vm._v("\n          * "+_vm._s(_vm.$t("components.notificationsModal.callSpaceBar"))+"\n        ")])]):_vm._e(),_vm._v(" "),_c('v-row',{class:{ heightMobileMessages: _vm.isMobile },style:({ width: '100%', margin: 0 }),attrs:{"flex":""}},[_c('v-col',{staticClass:"col-12"},[(
              _vm.notificationInfo.type === 'message' ||
              _vm.notificationInfo.type === 'plannerEvent'
            )?[_c('v-container',{ref:"messageContainer",class:("contentMessagesIsReply " + (_vm.isMobile ? 'contentMessagesIsReplyMobile' : ''))},[_c('MessageHistoryEffector',{attrs:{"messages":_vm.getHistoryMessages}})],1),_vm._v(" "),_c('div',{staticClass:"px-6"},[_c('v-divider')],1)]:_vm._e()],2)],1),_vm._v(" "),_c('v-card',{staticClass:"py-2 pl-4 mt-4 pr-2 footerModal",attrs:{"color":"primary"}},[_c('v-row',{staticClass:"px-2 btns",style:({ width: '100%', margin: 0 }),attrs:{"flex":""}},[_c('v-col',{staticClass:"px-0 py-0 d-flex align-center",attrs:{"lg":"12","align":"right"}},[_c('v-text-field',{staticClass:"inputMessageWaitingRoom",attrs:{"background-color":_vm.isDark ? '#1e1e1e' : '#fff',"autofocus":"","hide-details":"","outlined":"","single-line":"","maxlength":"250","dense":"","label":_vm.$t('components.notificationsModal.reply')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.replyMessage.trim().length > 0
                  ? _vm.sendReply(_vm.notificationInfo)
                  : null}},model:{value:(_vm.replyMessage),callback:function ($$v) {_vm.replyMessage=$$v},expression:"replyMessage"}},[_c('template',{slot:"append"},[_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","disabled":_vm.replyMessage.trim().length === 0},on:{"click":function($event){return _vm.sendReply(_vm.notificationInfo)}}},[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'paper-plane'],"color":"primary"}})],1)],1)],2),_vm._v(" "),_c('v-btn',{staticClass:"ml-3 mr-2",staticStyle:{"border-radius":"4px","min-width":"45px"},attrs:{"icon":"","tile":"","outlined":"","color":"white"},on:{"click":function($event){return _vm.closeModal()}}},[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'times']}})],1)],1)],1)],1),_vm._v(" "),_c('v-spacer')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }