<template>
  <v-list max-width="320" @click.stop.prevent>
    <v-list-group
      active-class="listColor"
      @click="handlerClickWaitingRoom($event)"
      :value="isActive"
      v-if="!mini"
      :appendIcon="'$expand'"
    >
      <template v-slot:activator>
        <v-list-item-icon
          class="primary--text"
          :class="`${getRoute === '/waitingroom' ? ' primary--text' : ''}`"
        >
          <font-awesome-icon
            v-if="currentPage('/waitingroom')"
            :icon="['fas', 'users-class']"
            class="primary--text"
          />
          <font-awesome-icon
            v-else
            :icon="['fal', 'users-class']"
            class="primary--text"
          />
        </v-list-item-icon>
        <v-badge
          @click.native="handleWaitingRoom()"
          v-if="getconnecetedWaitingrooms > 0"
          right
          color="error"
          :content="getconnecetedWaitingrooms"
          :value="getconnecetedWaitingrooms"
          bordered
          overlap
          offset-x="7"
          offset-y="7"
          class="waitingRoomBadge"
        ></v-badge>
        <v-list-item-title
          class="pr-4"
          :class="`${
            getRoute === '/waitingroom' ||
            (!hasPrivilege(state.ownUUID) && `${getRoute === '/home'}`)
              ? 'font-weight-bold'
              : ''
          }`"
          @click.stop.prevent="
            setCurrentContentVisile('', false, $router);
            handleWaitingRoom();
          "
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ getTitleWaitingROOM }}
                {{
                  `${
                    getconnecetedWaitingrooms > 0
                      ? "(" + getconnecetedWaitingrooms + ")"
                      : ""
                  }`
                }}
              </span>
            </template>
            <span>{{ waitingRoomTT }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                class="btnAddGroup"
                @click.stop.prevent="gotoPage('invitationform')"
              >
                <font-awesome-icon
                  class="primary--text"
                  :icon="['fal', 'plus-circle']"
                  :style="{
                    fontSize: '20px',
                  }"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.sidebar.guestTT") }}</span>
          </v-tooltip>
        </v-list-item-title>
        <CustomTT
          v-if="showCustomTT"
          :text="`${$t('components.hotkeys.controlKey')} + R`"
          position="bottom"
          :extraclass="'ctrr'"
        />
      </template>
      <UserList
        :persons="sortedUsersWaitingRoom"
        :section="'guests'"
        typeData="waitingroom"
      />
    </v-list-group>
    <v-tooltip right v-if="!isGuest && mini">
      <template v-slot:activator="{ on }">
        <v-list-item v-on="on" class="cursorPointer">
          <v-list-item-icon @click="handleWaitingRoom($event)">
            <font-awesome-icon
              v-if="currentPage('/waitingroom')"
              :icon="['fas', 'users-class']"
              class="primary--text"
            />
            <font-awesome-icon
              v-if="!currentPage('/waitingroom')"
              :icon="['fal', 'users-class']"
              class="primary--text"
            />
            <v-badge
              @click.native="handleWaitingRoom()"
              v-if="getconnecetedWaitingrooms > 0"
              right
              color="error"
              :content="getconnecetedWaitingrooms"
              :value="getconnecetedWaitingrooms"
              bordered
              overlap
              offset-x="7"
              offset-y="7"
              class="waitingRoomBadge"
            ></v-badge>
          </v-list-item-icon>
        </v-list-item>
      </template>
      <span>{{ waitingRoomTT }}</span>
    </v-tooltip>
  </v-list>
</template>
<script>
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { hasPrivilege } from "../../utils/privileges";
import CustomTT from "../content/customTT.vue";
import UserList from "./userList.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";
import { waitingInfoStore } from "../../effector/users/waitingInfo";

export default {
  components: { CustomTT, UserList },
  props: ["setList", "active", "waitingRoomFor", "mini"],
  data: () => ({
    moment: moment,
    state: store.state,
    setCurrentContentVisile: store.setCurrentContentVisile,
    ownUUID: store.state.ownUUID,
  }),
  effector: {
    showCustomTT: hotkeysInfoStore,
    waitingUsesEffect: waitingInfoStore,
  },
  methods: {
    handlerClickWaitingRoom(event) {
      if (this.mini) {
        event.preventDefault();
        event.stopPropagation();
        this.goToPage("waitingroom");
      } else {
        this.setList("waitingroom");
      }
    },
    gotoPage(route) {
      this.setCurrentContentVisile(route, true, this.$router);
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    handleWaitingRoom() {
      if (hasPrivilege(this.state.ownUUID)) {
        if (this.$route.path !== "/waitingroom") {
          if (this.state.ownUUID === this.waitingRoomFor) {
            store.setCurrentContentVisile("waitingroom", true, this.$router);
          } else {
            store.setCurrentContentVisile(
              `waitingroomfor/${this.waitingRoomFor}`,
              true,
              this.$router
            );
          }
        }
      }
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
  },
  computed: {
    waitingRoomAlias() {
      return store.state.namespaceSettings.waitingRoomAlias;
    },
    isActive() {
      return this.active == "waitingroom";
    },
    getWaitingUsesEffect() {
      return this.waitingUsesEffect;
    },
    waitingRoomTT() {
      return this.waitingRoomAlias
        ? this.$t("components.sidebar.waitingRoomTTAlias", [
            this.waitingRoomAlias,
          ])
        : this.$t("components.sidebar.waitingRoomMiniTT");
    },
    assistantsUser() {
      return (store.state.user.assists || []).length || 0;
    },
    getTitleWaitingROOM() {
      const waitingRoomName = this.$t(
        "components.drawerContentExpansion.guest"
      );
      if (this.waitingRoomFor === this.state.ownUUID) {
        return (
          waitingRoomName +
          ` ${
            this.assistantsUser > 0
              ? this.$t("generics.from") +
                " " +
                this.getTitelForUuid(this.state.ownUUID) +
                " " +
                this.getNameForUuid(this.state.ownUUID)
              : ""
          }`
        );
      } else {
        return (
          waitingRoomName +
          " " +
          this.$t("generics.from") +
          " " +
          this.getTitelForUuid(this.waitingRoomFor) +
          " " +
          this.getNameForUuid(this.waitingRoomFor)
        );
      }
    },
    sortedUsersWaitingRoom: function () {
      let waitingUsers = [];
      let connectedArray = [];
      let disconnectedArray = [];
      const visitors = this.state.persisted.userVisitors || {};
      for (const visitorId in visitors) {
        const visitor = store.state.persisted.userVisitors[visitorId];
        if (visitor && visitor.isWaitingRoom) {
          let person = store.state.group[visitorId];
          if (!person || !person.user || person.user.name == "") continue;
          if (person.connected) {
            connectedArray.push(person);
          } else {
            disconnectedArray.push(person);
          }
          waitingUsers.push({ ...person, uuid: visitorId });
        }
      }
      const connectedPeople = connectedArray.sort(function (a, b) {
        return (
          new Date(b.user.presentFrom).getTime() -
          new Date(a.user.presentFrom).getTime()
        );
      });
      const disconnectedPeople = disconnectedArray.sort(function (a, b) {
        if (a.user.name < b.user.name) {
          return -1;
        }
        if (a.user.name > b.user.name) {
          return 1;
        }
      });
      waitingUsers = connectedPeople.concat(disconnectedPeople);
      return waitingUsers;
    },
    getconnecetedWaitingrooms() {
      const calledVisitors = Object.entries(this.getWaitingUsesEffect.called)
        .filter(([uuid, value]) => !!value)
        .map(([uuid, value]) => uuid);
      const total = store.getWaitingRoomList().length - calledVisitors.length;
      return total;
    },
    getRoute() {
      return this.$route.path;
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
.ctrr {
  top: 58px;
  left: 15px;
}
.waitingRoomBadge {
  font-size: 10px !important;
  height: 16px !important;
  min-width: 16px !important;
  padding: 2px 6px !important;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  border-radius: 50% !important;
}
.btnAddGroup {
  position: absolute !important;
  right: 57px;
  top: 5px;
}
.cursorPointer {
  cursor: pointer;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.theme--dark .listColor {
  color: white !important;
}
.theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-list-group__header__append-icon .v-icon {
  margin-left: 36px !important;
}
</style>
