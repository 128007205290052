<template>
  <v-dialog
    v-model="showFinishCallModal"
    persistent
    max-width="600"
    @keydown.enter="endCall"
    @keydown.space="endCall"
    @keydown.esc="closeModalFunction"
  >
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('generics.info')"
          :closeModalFunction="closeModalFunction"
        >
          <v-spacer></v-spacer>
        </HeaderModal>
        <v-card-text class="py-4 fontSize16">
          <div class="d-flex justify-center">
            {{ finishCallSentence }}
          </div>
        </v-card-text>
        <FooterModal
          :closeModalFunction="closeModalFunction"
          :hideDefaultButtons="true"
        >
          <v-btn
            class="ml-3 mr-2 primary--text buttonIconPrimaryFooter"
            min-width="45px"
            color="white"
            @click="endCall"
          >
            {{ $t("components.conferences.yes") }}
          </v-btn>
          <v-btn
            class="ml-3 primary--text mr-2 buttonIconPrimaryFooter"
            min-width="45px"
            color="white"
            @click="closeModalFunction"
          >
            {{ $t("generics.no") }}
          </v-btn>
        </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  components: { HeaderModal, FooterModal },
  props: ["closeModal", "rejectCall", "showFinishCallModal"],
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
  }),
  computed: {
    finishCallSentence() {
      if (this.showFinishCallModal && typeof this.showFinishCallModal === 'string') {
        const translation = this.$t(this.showFinishCallModal) || this.showFinishCallModal;
        if (translation !== this.showFinishCallModal) return translation;
      }
      return this.$t('components.callsContent.finishCall');
    }
  },
  methods: {
    closeModalFunction() {
      return this.closeModal();
    },
    endCall() {
      this.closeModalFunction();
      return this.rejectCall();
    },
  }
};
</script>

<style scoped lang="scss">
  .fontSize16 {
    font-size:  16px;
  }
</style>