var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"570","fullscreen":_vm.isMobile},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.resetChip();
    _vm.toggleNotificationModal();}},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},_vm._l((_vm.state.showModalNotify),function(key,i){return _c('div',{key:i},[_c('v-card',[_c('v-card-title',{staticClass:"pt-4 pb-2 headline styleHeader"},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"d-flex px-1 py-1",attrs:{"cols":"6"}},[_c('v-img',{staticClass:"imageLogo",attrs:{"src":"assets/icon.png","max-height":"30","max-width":"30","contain":""}}),_vm._v(" "),_c('span',{staticClass:"font-weight-medium pl-4 spanModalCall"},[_vm._v("\n              "+_vm._s(key.type == "message" && _vm.isEventTimeLine(key)
                  ? _vm.$t("components.notificationsModal.meetingChange")
                  : key.type == "message" && !_vm.isEventTimeLine(key)
                  ? _vm.$t("components.notificationsModal.message")
                  : key.type == "call"
                  ? _vm.$t("components.notificationsModal.missedCall")
                  : "Notification")+"\n            ")])],1),_vm._v(" "),_c('v-col',{staticClass:"px-1 py-1 text-right",attrs:{"cols":"6"}},[(key.date)?_c('span',{staticClass:"font15 pr-2"},[[_vm._v("\n                "+_vm._s(key.date ? _vm.moment(key.date).format("DD.MM.YYYY, H:mm") : "")+"\n              ")]],2):_vm._e(),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"black"},on:{"click":function($event){_vm.resetChip();
                    _vm.toggleNotificationModal();}}},on),[_c('font-awesome-icon',{style:({ fontSize: '25px' }),attrs:{"icon":['fal', 'times'],"color":_vm.state.persisted.isDark ? 'white' : 'black'}})],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("generics.close")))])])],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-row',{class:("row py-3 mx-0 px-0 " + (_vm.isMobile ? 'heightModalMobile' : '')),attrs:{"flex":""},on:{"click":function($event){!_vm.isWaitingRoomUser ? _vm.handleClickZone(key.type) : null}}},_vm._l((1),function(n){return _c('v-col',{key:n,staticClass:"col-12"},[(n == 1)?_c('div',{class:("d-flex " + (_vm.isMobile ? 'directionModalMobilie' : ''))},[_c('div',[_c('v-list-item-avatar',{staticClass:"borderRadius4",staticStyle:{"margin":"0px 10px"},attrs:{"tile":"","size":"120"}},[_c('v-img',{staticClass:"borderRadius4",attrs:{"max-height":"120","max-width":"120","contain":"","src":_vm.getAvatarForUuid(Object.keys(_vm.state.showModalNotify)[0]),"lazy-src":"assets/default_profile_picture.png"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"pl-6 pb-0 pt-2",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-medium mb-0 pb-0 mr-auto"},[_vm._v("\n                  "+_vm._s(_vm.getNameForUuid(Object.keys(_vm.state.showModalNotify)[0]))+"\n                ")]),_vm._v(" "),(
                    _vm.state.group[Object.keys(_vm.state.showModalNotify)[0]].user
                  )?_c('p',{staticClass:"mb-0 pb-0 mr-auto"},[_vm._v("\n                  "+_vm._s(_vm.state.group[Object.keys(_vm.state.showModalNotify)[0]].user
                      .position)+"\n                ")]):_vm._e(),_vm._v(" "),(!_vm.isMobile && !_vm.isModalUserGuest)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pt-3",attrs:{"icon":""},on:{"click":function($event){_vm.goToOrganisation(
                          Object.keys(_vm.state.showModalNotify)[0]
                        );
                        _vm.resetChip();
                        _vm.toggleNotificationModal();}}},on),[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'sitemap']}})],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.organisationCarousel.showInOrganigram")))])]):_vm._e()],1)],1)],1):_vm._e()])}),1),_vm._v(" "),(key.type === 'message')?_c('v-row',{style:({ width: '100%', margin: 0 }),attrs:{"flex":""}},[_c('v-col',{staticClass:"col-12"},[_c('vue-scroll',{staticClass:"vueScroll"},[(!_vm.isReply)?_c('div',{directives:[{name:"linkified",rawName:"v-linkified:options",value:({
                attributes: { rel: 'nofollow noopener noreferrer' },
              }),expression:"{\n                attributes: { rel: 'nofollow noopener noreferrer' },\n              }",arg:"options"}],staticClass:"messageTextWrapper px-6",domProps:{"innerHTML":_vm._s(
                _vm.$sanitize(key.notification.text || key.notification.info)
              )}}):_vm._e(),_vm._v(" "),(_vm.isReply)?_c('div',{staticClass:"messageTextWrapper px-6"},[_c('UserMessageComp',{ref:"userMssgComp",refInFor:true,attrs:{"toggleIsReply":_vm.toggleIsReply,"isReply":_vm.isReply,"setIsReply":_vm.setIsReply}})],1):_vm._e()]),_vm._v(" "),(key.type === 'ticket')?_c('vue-scroll',{staticClass:"vueScroll"},[_c('div',{staticClass:"messageTextWrapper"},[_vm._v("\n              "+_vm._s(key.notification.text || key.notification.info)+"\n            ")])]):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('v-card',{staticClass:"py-2 pl-4 pr-2 footerModal",attrs:{"color":"primary"}},[_c('v-row',{staticClass:"px-2 btns",style:({ width: '100%', margin: 0 }),attrs:{"flex":""}},[_c('v-col',{staticClass:"px-0 py-0",attrs:{"lg":"12","align":"right"}},[(
                key.type === 'call' &&
                !_vm.isWaitingRoomUser &&
                _vm.state.persisted.tmpMessages.showMessageInitiateCallSpace
              )?_c('p',{staticClass:"body-2",staticStyle:{"position":"absolute","top":"11px"},attrs:{"align":"left"}},[_vm._v("\n              * "+_vm._s(_vm.$t("components.notificationsModal.callSpaceBar"))+"\n            ")]):_vm._e(),_vm._v(" "),(
                (key.type === 'call' || key.type === 'message') &&
                !_vm.isWaitingRoomUser
              )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({class:("mr-2 buttonIconPrimaryFooter " + (_vm.disableCallBtn ? 'buttonIconPrimaryFooterDisable' : '')),style:((" " + (_vm.disableCallBtn ? 'color: #11889d!important;' : ''))),attrs:{"icon":"","tile":"","id":"buttonCallDirectly","color":"primary","disabled":_vm.disableCallBtn},on:{"click":function($event){_vm.disableCallBtn
                      ? false
                      : _vm.callUser(Object.keys(_vm.state.showModalNotify)[0])}}},on),[(key.type === 'call')?_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'phone']}}):_vm._e(),_vm._v(" "),(key.type === 'message')?_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'phone']}}):_vm._e()],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.disableCallBtn
                  ? "Dieser Benutzer ist zur Zeit offline"
                  : _vm.$t("components.userProfile.callUser")))])]):_vm._e(),_vm._v(" "),(key.type === 'call' && !_vm.isWaitingRoomUser)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"buttonIconPrimaryFooter mr-2",attrs:{"icon":"","tile":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.setShowModalSenMsg(
                      Object.keys(_vm.state.showModalNotify)[0],
                      'regularMsg'
                    )}}},on),[(key.type === 'call')?_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'paper-plane']}}):_vm._e()],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.userProfile.sendAMessage")))])]):_vm._e(),_vm._v(" "),(key.type === 'message' && !_vm.isWaitingRoomUser)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({class:("mr-2 buttonIconPrimaryFooter " + (_vm.isReply ? 'buttonIconPrimaryFooterDisable' : '')),style:((" " + (_vm.isReply ? 'color: #11889d!important;' : ''))),attrs:{"icon":"","tile":"","color":"primary","disabled":_vm.isReply},on:{"click":function($event){_vm.setshowCompSendMsg(
                      Object.keys(_vm.state.showModalNotify)[0],
                      key.notification.header
                    )}}},on),[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'reply']}})],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(!_vm.state.group[Object.keys(_vm.state.showModalNotify)[0]].connected
                  ? "Dieser Benutzer ist zur Zeit offline"
                  : _vm.$t("components.notificationsModal.reply")))])]):_vm._e(),_vm._v(" "),(
                key.type === 'message' &&
                _vm.isEventTimeLine(key) &&
                !_vm.isWaitingRoomUser &&
                !_vm.isMyTimeLineEvent(key)
              )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 buttonIconPrimaryFooter",attrs:{"icon":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.acceptEventTimeline(key.notification.header.eventTimeLine)}}},on),[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'check']}})],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("accept event")])]):_vm._e(),_vm._v(" "),(_vm.isReply)?_c('v-btn',{staticClass:"mr-2 buttonPrimaryFooter",attrs:{"depressed":"","color":"white"},on:{"click":function($event){return _vm.sendReply()}}},[_vm._v(_vm._s(_vm.$t("components.sendMessageModal.send")))]):_vm._e(),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"buttonIconPrimaryFooter",attrs:{"loading":_vm.loading,"icon":"","tile":"","color":"red"},on:{"click":function($event){_vm.removeMessage(
                      Object.keys(_vm.state.showModalNotify)[0],
                      key.id,
                      undefined
                    )}}},on),[_c('DeleteBasket',{attrs:{"indexData":[
                      Object.keys(_vm.state.showModalNotify)[0],
                      key.id,
                      undefined ],"delFunction":_vm.removeMessage,"extraClasses":"colorPrimary","fontSize":"20","prevent":"false"}})],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("generics.delete")))])])],1)],1)],1)],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }