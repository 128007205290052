var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"absolute",class:{
    'd-none':
      _vm.state.currentContentVisile.showComponent ||
      Object.keys(_vm.state.remoteStreams).length === 0,
  },staticStyle:{"z-index":"999"},attrs:{"id":"video-container"}},[(!_vm.infiniteCall)?_c('div',{staticClass:"progressBar"},[_c('ProgressLinear')],1):_vm._e(),_vm._v(" "),_c('splitpanes',{class:("default-theme " + (!this.infiniteCall ? ' mt-6' : '')),style:(("" + (!this.infiniteCall ? 'height: 97%' : 'height: 100%'))),attrs:{"horizontal":""}},[_c('pane',[_c('splitpanes',{on:{"resize":function($event){return _vm.resizePane($event)}}},[_c('pane',{attrs:{"size":Object.keys(_vm.state.remoteStreams).length == 1 ? _vm.actualSize.me : 50}},[_c('div',{ref:"localVideoDiv",staticClass:"relative localVideo",attrs:{"id":"localVideoDiv"}},[(_vm.isLocalAudioMuted || _vm.amISoundMuted)?_c('div',{staticClass:"messageToParticipants messageToParticipantsBlink"},[(_vm.isLocalAudioMuted)?_c('div',[_vm._v("\n                "+_vm._s(_vm.$t("components.callsContent.audioMessage"))+"\n              ")]):_vm._e(),_vm._v(" "),(_vm.amISoundMuted)?_c('div',[_vm._v("\n                "+_vm._s(_vm.$t("components.callsContent.soundMessage"))+"\n              ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"callerName"},[_c('span',[_vm._v(_vm._s(_vm.isVisitor(_vm.state.ownUUID)
                    ? _vm.$t("components.userSettings.guest") + " - "
                    : "")+"\n                "+_vm._s(_vm.getTitelForUuid(_vm.state.ownUUID))+"\n                "+_vm._s(_vm.getNameForUuid(_vm.state.ownUUID))+"\n                ")]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('span',_vm._g({staticClass:"italicFont",staticStyle:{"float":"right","margin-right":"9px"}},on),[_vm._v("\n                    P2P")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.remotePersonCall.p2p")))])]),_vm._v(" "),(_vm.callInfo.ownerUUID === _vm.state.ownUUID)?_c('span',{staticStyle:{"float":"left","margin-left":"9px"}},[_vm._v("Moderator")]):_vm._e(),_vm._v(" "),(_vm.callInfo.ownerUUID !== _vm.state.ownUUID && ( !_vm.isWaitingRoomUser(_vm.state.ownUUID) && !_vm.isVisitor(_vm.state.ownUUID) ))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"float":"left","margin-left":"9px"}},on),[_c('font-awesome-icon',_vm._g({style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'user-crown']}},on))],1)]}}],null,false,477651809)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.remotePersonCall.user")))])]):_vm._e(),_vm._v(" "),(_vm.callInfo.ownerUUID !== _vm.state.ownUUID && ( _vm.isWaitingRoomUser(_vm.state.ownUUID) || _vm.isVisitor(_vm.state.ownUUID) ))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"float":"left","margin-left":"9px"}},on),[_c('font-awesome-icon',_vm._g({style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'user-friends']}},on))],1)]}}],null,false,1591339111)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.remotePersonCall.guest")))])]):_vm._e()],1),_vm._v(" "),_c('div',[_c('AudioStreamMeter',{attrs:{"canvasId":'canvasAudioCallsContent',"stream":_vm.state.localStreams.user}}),_vm._v(" "),_c('AudioStreamMeter',{attrs:{"canvasId":'canvasAudioCallsContent',"stream":_vm.state.localStreams.display}})],1),_vm._v(" "),(_vm.state.localStreams.display)?_c('div',{staticClass:"divSharingScreen"},[(!this.state.persisted.useBlurEffect)?_c('video',{class:{ 'd-none': !_vm.haveLocalVideo },attrs:{"id":"local-video","playsinline":"","autoplay":"autoplay","muted":""},domProps:{"srcObject":_vm.state.localStreams.user,"muted":true}}):_vm._e(),_vm._v(" "),(this.state.persisted.useBlurEffect)?_c('StreamCanvas',{class:{ 'd-none': !_vm.haveLocalVideo },attrs:{"id":"local-video","stream":_vm.state.localStreams.user}}):_vm._e()],1):_vm._e(),_vm._v(" "),(
                !_vm.state.localStreams.display &&
                !this.state.persisted.useBlurEffect
              )?_c('video',{class:{ 'd-none': !_vm.haveLocalVideo },attrs:{"id":"local-video","playsinline":"","autoplay":"autoplay","muted":""},domProps:{"srcObject":_vm.state.localStreams.user,"muted":true}}):_vm._e(),_vm._v(" "),(
                !_vm.state.localStreams.display &&
                this.state.persisted.useBlurEffect
              )?_c('StreamCanvas',{class:{ 'd-none': !_vm.haveLocalVideo },attrs:{"id":"local-video","stream":_vm.state.localStreams.user}}):_vm._e(),_vm._v(" "),_c('video',{class:{ 'd-none': !_vm.state.localStreams.display },attrs:{"id":"local-screen","playsinline":"","autoplay":"autoplay","muted":""},domProps:{"srcObject":_vm.state.localStreams.display,"muted":true}}),_vm._v(" "),_c('div',{staticClass:"noCameraStream",class:{ 'd-none': _vm.haveLocalVideo }},[_c('div',{staticClass:"noCameraContent"},[_c('v-img',{staticClass:"imageNotVideo",attrs:{"contain":"","src":_vm.getAvatarFromUuid(_vm.state.ownUUID),"lazy-src":"assets/default_profile_picture.png"}}),_vm._v(" "),_c('div',{staticClass:"emptyDiv"})],1)]),_vm._v(" "),(_vm.showTypingInfo.show)?_c('div',{staticClass:"testMeSomehow",class:{ active: _vm.showTypingInfo.show }},[_vm._v("\n              "+_vm._s(_vm.getNameForUuid(_vm.showTypingInfo.from))+"\n              "+_vm._s(_vm.$t("components.callsContent.isTyping"))+" ...\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"buttonsUser",class:{
                hidden: Object.keys(_vm.state.remoteStreams).length == 0,
                buttonsIpad: _vm.isIpad && _vm.isIos,
              }},[[(_vm.openCameraQuality)?_c('v-list',{staticClass:"pt-0 cameraQuality"},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('div',{staticClass:"d-flex justify-end blueBackground"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.openCameraQuality = false}}},[(_vm.state.persisted.rtcVideo)?_c('font-awesome-icon',{style:({ fontSize: '15px' }),attrs:{"icon":['fal', 'times'],"color":"white"}}):_vm._e()],1)],1),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.setQuality('high')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("components.camAndMic.high")))])],1)],1),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.setQuality('medium')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("components.camAndMic.medium")))])],1)],1),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.setQuality('low')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("components.camAndMic.low")))])],1)],1)],1)],1):_vm._e()],_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"long-press",rawName:"v-long-press",value:(300),expression:"300"}],staticClass:"mx-3",attrs:{"color":"white","icon":""},on:{"long-press-start":_vm.openMenuQuality,"long-press-stop":_vm.onLongPressStop,"click":function($event){return _vm.tryWebcam()}}},on),[(_vm.state.persisted.rtcVideo)?_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'video']}}):_vm._e(),_vm._v(" "),(!_vm.state.persisted.rtcVideo)?_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'video-slash']}}):_vm._e()],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("generics.camera")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-3",attrs:{"color":"white","icon":"","disabled":_vm.amIMutedByGlobal},on:{"click":function($event){return _vm.toggleMuteLocalAudio()}}},on),[(!_vm.isLocalAudioMuted)?_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'microphone']}}):_vm._e(),_vm._v(" "),(_vm.isLocalAudioMuted)?_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'microphone-slash']}}):_vm._e(),_vm._v(" "),_c('canvas',{class:{ hidden: _vm.isLocalAudioMuted },attrs:{"id":"canvasAudioCallsContent","width":"50","height":"15"}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.callsContent.mic")))])]),_vm._v(" "),(
                  _vm.state.ownerMeeting ||
                  Object.keys(_vm.state.remoteStreams).length == 1
                )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-3",attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.toggleMuteSound()}}},on),[(!_vm.isLocalSoundMuted)?_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'volume-up']}}):_vm._e(),_vm._v(" "),(_vm.isLocalSoundMuted)?_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'volume-slash']}}):_vm._e()],1)]}}],null,false,1344696976)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.callsContent.loudspeaker")))])]):_vm._e(),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({ref:"cancelCall",staticClass:"redBtn mx-3",attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.handleHangUp()}}},on),[_c('font-awesome-icon',{staticClass:"faPhoneRotate",style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'phone']}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("generics.hangUp")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-3",attrs:{"color":"white","icon":"","disabled":!_vm.callInfo.callActive},on:{"click":function($event){_vm.state.localStreams.display
                        ? _vm.closeDisplayMedia()
                        : _vm.tryScreenShare()}}},on),[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'desktop']}}),_vm._v(" "),(!_vm.state.localStreams.display)?_c('font-awesome-icon',{style:({
                        fontSize: '20px',
                        position: 'absolute',
                        top: -1,
                        left: 5,
                      }),attrs:{"icon":['fal', 'slash']}}):_vm._e()],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.callsContent.shareScreen")))])]),_vm._v(" "),(_vm.callInfo.ownerUUID === _vm.state.ownUUID)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-3",attrs:{"color":"white","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.hasOnHold ? _vm.unholdCall() : _vm.holdCall()}}},on),[_c('font-awesome-icon',{class:{ progressBarBlink: _vm.hasOnHold },style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'long-arrow-up'],"color":_vm.hasOnHold ? 'red' : 'white'}}),_vm._v(" "),_c('font-awesome-icon',{staticClass:"rotatedArrow",class:{ progressBarBlink: _vm.hasOnHold },attrs:{"color":_vm.hasOnHold ? 'red' : 'white',"icon":['fal', 'long-arrow-up']}})],1)]}}],null,false,4113165815)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.userListItem.forwarding")))])]):_vm._e(),_vm._v(" "),(
                  _vm.state.ownerMeeting
                )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-3",attrs:{"color":"white","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.setPresentationView()}}},on),[_c('img',{staticClass:"imgButton",attrs:{"src":"/img/icons/voffice_viewB.svg"}})])]}}],null,false,492771333)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.meetingTime.presentationView")))])]):_vm._e(),_vm._v(" "),(
                  _vm.state.ownerMeeting &&
                  Object.keys(_vm.state.remoteStreams).length > 1
                )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-3",attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.toggleMuteGlobalAudio()}}},on),[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'user-friends']}})],1)]}}],null,false,1261905822)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.remotePersonCall.muteAll")))])]):_vm._e(),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-3",attrs:{"color":"white","icon":""},on:{"click":_vm.toggleModalSettings}},on),[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'cog']}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.navbar.camAndMic")))])]),_vm._v(" "),(_vm.callInfo.ownerUUID === _vm.state.ownUUID)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-3",attrs:{"icon":"","color":"white"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.setSendMessageInCallModal.apply(null, arguments)}}},on),[_c('font-awesome-icon',{style:({ fontSize: '20px' }),attrs:{"icon":['fal', 'paper-plane']}})],1)]}}],null,false,3362231525)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("components.bridgeCallTooltips.messageAll")))])]):_vm._e()],2)],1)]),_vm._v(" "),_vm._l((_vm.state.remoteStreams),function(remote,uuid,index){return (index < 1)?_c('pane',{key:uuid,attrs:{"size":Object.keys(_vm.state.remoteStreams).length == 1
              ? _vm.actualSize.remote
              : 50}},[_c('div',{attrs:{"id":"remotePersonCallDiv"},on:{"click":function($event){return _vm.changeActualSize()}}},[_c('RemotePersonCall',{attrs:{"uuid":uuid,"remote":remote,"isLocalSoundMuted":_vm.isLocalSoundMuted,"changeActualSize":_vm.changeActualSize,"splitViews":_vm.splitViews,"fullScreen":_vm.fullScreen}})],1)]):_vm._e()})],2)],1),_vm._v(" "),(Object.keys(_vm.state.remoteStreams).length > 1)?_c('pane',[_c('splitpanes',{on:{"resize":function($event){return _vm.resizePane($event)}}},_vm._l((_vm.state.remoteStreams),function(remote,uuid,index){return (index >= 1)?_c('pane',{key:uuid},[_c('RemotePersonCall',{attrs:{"uuid":uuid,"remote":remote,"isLocalSoundMuted":_vm.isLocalSoundMuted}})],1):_vm._e()}),1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }