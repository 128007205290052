<template>
  <v-dialog v-model="showLastChance" persistent max-width="595" @keydown.esc="closeModal">
    <v-card>
      <v-card-title class="headline">
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        {{ $t('components.2faActivation.lastChanceTitle') }}
      </v-card-title>
       <v-card-subtitle class="pt-2">
        <div class="py-2">
           <span>{{ $t('components.2faActivation.checkBoxText') }}</span>
        </div>
      </v-card-subtitle>
        <v-card color="primary" class="py-2 pl-4 mt-0 pr-2 footerModal">
          <v-row class="px-2 btns mx-0" >
            <v-col cols="12" class="px-0 py-0" align="right">
              <v-btn
              color="primary"
              @click="closeModal"             
              >{{ $t('generics.cancel') }}</v-btn>
              <v-btn
               color="primary"
               @click="saveEntry()"
              >{{ $t('generics.save') }}</v-btn>
            </v-col>
          </v-row>
        </v-card>  
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../../../store";
export default {
  props: ["toggle2faLastChangeModal", "showLastChance", "saveEntryNoTel"],
  data() {
    return {
      state: store.state,
    };
  },
  methods: {
    closeModal(){
      this.toggle2faLastChangeModal(); 
    },
    saveEntry(){
      this.saveEntryNoTel();
    }
  },
  watch: {},
  computed: {},
};
</script>
<style scoped lang="scss">

</style>