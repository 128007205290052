<template>
  <v-alert
    :value="showAlert"
    :type="alertType"
    style="border-color: #fff !important"
    @click.native="dismiss"
    transition="scale-transition"
    mode="in-out"
    class="whiteBorder"
    color="primary"
  >
    {{ alertText }}
  </v-alert>
</template>

<script>
import { delAlertEvent } from "../../effector/alerts";

export default {
  props: ["item"],
  data() {
    return {
      showAlert: false,
      mounted: false,
    };
  },
  methods: {
    show() {
      this.showAlert = true;
      this._showTimeout = undefined;
    },
    dismiss() {
      if (!this.mounted) return;
      this.showAlert = false;
      setTimeout(() => {
        delAlertEvent(this.item);
      }, 100);
      this._dismissTimeout = undefined;
    },
  },
  computed: {
    alertType() {
      return this.item.type || "warning";
    },
    alertText() {
      return this.item.text;
    },
    dismissTimeout() {
      return this.item.timeout || 5000;
    },
  },
  mounted() {
    if(this.show){
      this.mounted = true;
      this._showTimeout = setTimeout(this.show.bind(this), 10);
      this._dismissTimeout = setTimeout(
      this.dismiss.bind(this),
      this.dismissTimeout
    );
    }
 
  },
  beforeDestroy() {
    this.mounted = false;
    if (this._showTimeout) {
      clearTimeout(this._showTimeout);
    }
    if (this._dismissTimeout) {
      clearTimeout(this._dismissTimeout);
    }
  },
};
</script>

<style lang="scss">
.v-application {
  .whiteBorder {
    border-color: #fff !important;
    border: 2px solid #fff !important;
  }
}
</style>
