<template>
  <v-dialog v-model="showModal" persistent max-width="560">
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('components.adminSettingsModal.mailFooter')"
          :closeModalFunction="closeModal"
        />
        <v-card-text>
          <!-- :titleModal="$t('components.beforeEndingCallModal.title')" -->
          <v-textarea
            name="footerText"
            :label="$t('components.adminSettingsModal.mailFooter')"
            v-model="footerText"
            no-resize
          ></v-textarea>
        </v-card-text>
        <FooterModal :closeModalFunction="closeModal" :showCancel="true">
          <v-btn class="ml-2 white--text" color="primary" @click="saveFooterText()">
            {{ $t("generics.save") }}</v-btn
          >
        </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { setNamespaceSetting } from '../../lib/wsMsg';
import { resetMailFooterEvent, mailFooterStore } from "../../effector/modals";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
      footerText: store.state.namespaceSettings.legalMailFooter || this.$t('components.adminSettingsModal.legalMailFooter').join("\n"),
    };
  },
  watch: {},
  mounted() {},
  effector: {
    showModal: mailFooterStore,
  },
  methods: {
    saveFooterText() {
      this.saveData();
      this.closeModal();
    },
    saveData() {
      setNamespaceSetting('legalMailFooter', this.footerText);
      return store.setLegalMailFooter(this.footerText);
    },
    closeModal() {
      resetMailFooterEvent();
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>