<template>
  <div style="padding:2px;">
    <template>
      <v-btn width="20" height="20" icon class="locationIcon" :color="additionalStatusColor">
        <font-awesome-icon
          v-if="additionalStatusColor === 'grey'"
          :icon="['fal', 'sticky-note']"
        />
        <font-awesome-icon
          v-else
          :icon="['fas', 'sticky-note']"
        />
      </v-btn>
    </template>
  </div>
</template>
<script>
import store from "../../store";
export default {
  props: ["person"],
  data() {
    return {
      state: store.state,
    };
  },
  methods: {},
  computed: {
    additionalStatusColor() {
      if (this.person.user){
        return this.person.user.additionalStatus.trim().length > 0
          ? "#d8c838"
          : "grey";
      }
      return "grey"
    },
  },
};
</script>
<style scoped lang="scss">
.locationIcon {
  pointer-events: none;
  background-color: #ffffff;
}
</style>