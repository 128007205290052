import Vue from 'vue';
import DecryptError from './components/firstSetup/decryptError.vue';
import vuetify from './vuetify';

let inited = false;
export const initDecryptError = () => {
  if (inited) return;
  inited = true;
  try {
    DecryptError.vuetify = vuetify;
    const root = new Vue({
      render: createElement => createElement(DecryptError)
    });
    root.$mount('#decrypt_anchor');
  } catch (err) {
    console.warn('Init Vue Error:', err);
  }
};
