import { webLicensedBaseFeatures } from "../../sharedsrc/licensedFeatures";
import store from '../store';
import { getStatusColorByUser } from './status';
import { personIsInBridgeCall } from "./calls";
import { isVisitor, hasPrivilege, isAdmin, userSupervisorSection, userSupervisorDepartment, userSupervisorTeam } from './privileges';
import Vue from 'vue';
import moment from "../../sharedsrc/moment";

const hasOwn = Object.prototype.hasOwnProperty;

/**
 * Returns TZ offset in ISO 8601 compatible mode (Ex: +01:00)
 * @param {string=} date Date in a reasonable format
 * @param {string=} time Time in a reasonable format
 * @returns {string} The guessed timezone string for current environment
 */
export function getTimezone(date = '', time = '') {
  let d = new Date();
  if (date) {
    const dateTime = ('' + (date || '').trim() + ' ' + (time || '').trim() + '').trim();
    if (dateTime) {
      const dateSplit = dateTime.split(/[ .:T-]/).map(v => parseInt(v, 10));
      const [year, month, day, hour, minute, second] = dateSplit;
      const dateObject = new Date(year, month - 1, day, (hour || 0), (minute || 0), (second || 0));
      if (dateObject.getTime()) {
        d = dateObject;
      } else {
        console.warn('getTimezone: guessed invalid date object', date, time);
      }
    } else {
      console.warn('getTimezone: got emptyish parameters', date, time);
    }
  } else if (time) {
    console.warn('getTimezone: got time without date', date, time);
  }
  // Extract timezone from date string
  const tzMatch = d.toString().match(/([+-][\d]{2})([\d]{2})\s/);
  if (tzMatch && tzMatch.length) return tzMatch[1] + ':' + tzMatch[2];
  // Extract timezone from offset
  const tzOffset = d.getTimezoneOffset() * -1;
  const hours = Math.floor(tzOffset / 60).toString()
    .replace(/^\d/, '+$&')
    .replace(/^([+-])(\d)$/, '$1\x30$2') // "0".charCodeAt(0) === 0x30
  const minutes = Math.abs(tzOffset % 60).toString().padStart(2, '0');
  return hours + ":" + minutes;
}

export function sortByName(array) {
  if (array.length > 0) {
    return array.sort((a, b) => {
      return ((a.user || {}).name || a.uuid || '').toString().localeCompare(
        (b.user || {}).name || b.uuid || ''
      );
    });
  } else {
    return [];
  }
}

export function sortByLastName(array) {
  if (array.length > 0) {
    return array.sort((a, b) => {
      const au = a.user || {};
      const bu = b.user || {};
      const ak = `${(au.lastName || '') + ' ' + (au.firstName || '') + ' ' + (au.name || '') + ' ' + (au.uuid || '')}`.replace(/\s{2,}/, ' ').trim();
      const bk = `${(bu.lastName || '') + ' ' + (bu.firstName || '') + ' ' + (bu.name || '') + ' ' + (bu.uuid || '')}`.replace(/\s{2,}/, ' ').trim();
      return ak.toString().localeCompare(bk);
    });
  } else {
    return [];
  }
}

export function getFinalPersonsSortedByColor(persons) {
  const connectedArray = [];
  const coffeeBreakArray = [];
  const disconnectedArray = [];
  const busyArray = [];
  const redArray = [];
  const holidaysArray = [];
  const noStatusArray = [];
  for (const i in persons) {
    if (hasOwn.call(persons, i)) {
      const person = persons[i];
      const result = setBorderByStatus(person);
      if (result === '#008000') {
        // green status
        connectedArray.push(person);
      } else if (result === '#795548') {
        // brown status
        coffeeBreakArray.push(person);
      } else if (result === '#808080') {
        // grey status
        disconnectedArray.push(person);
      } else if (result === '#ffa500') {
        // yellow status
        busyArray.push(person);
      } else if (result === '#ff0000') {
        // red status
        redArray.push(person);
      } else if (result === '#7208ff') {
        // purple status
        holidaysArray.push(person);
    }  else if (result === '#000000') {
        // purple status
        noStatusArray.push(person);
      }
    }
  }
  let finalResult = [];
  finalResult = finalResult.concat(
    sortByLastName(connectedArray),
    sortByLastName(coffeeBreakArray),
    sortByLastName(busyArray),
    sortByLastName(redArray),
    sortByLastName(noStatusArray),
    sortByLastName(disconnectedArray),
    sortByLastName(holidaysArray)
  );
  return finalResult;
}

export function checkListSort(persons, apart) {
  const setting = apart;
  if (setting) {
    switch (setting) {
      case 'alphabetical':
        return sortByName(persons);
      case 'statusColor':
        return getFinalPersonsSortedByColor(persons);
      case 'lastName':
        return sortByLastName(persons);
      default:
        return getFinalPersonsSortedByColor(persons);
    }
  } else {
    return getFinalPersonsSortedByColor(persons);
  }
}
export function setBorderByStatus(person) {
  if (!person || !person.user) return;
  return getStatusColorByUser(person.user, person.connected);
}

export function getBorderByStatusFull(person, customPx) {
  if (!person || !person.user) return;
  const userInBridgeCall = personIsInBridgeCall(person.uuid || person.user.uuid);
  if (userInBridgeCall && person.user.activity !== 'No status') {
    if (customPx) {
      return `border: ${customPx} dashed ${setBorderByStatus(person)}`;
    }
    return `border: 3px dashed ${setBorderByStatus(person)}`;
  } else {
    const isNoStatus = person.user.activity === 'No status' ? `outline: 1px solid white` : '';
    if (customPx) {
      return `border:${customPx} solid ${setBorderByStatus(person)}; ${isNoStatus}`;
    }
    return `border:4px solid ${setBorderByStatus(person)}; ${isNoStatus}`;
  }
}

export function getCustomBorderByStatusFull(person, position) {
  if (!person || !person.user) return;
  const userInBridgeCall = personIsInBridgeCall(person.uuid || person.user.uuid);
  let defaultCss;
  if (userInBridgeCall && person.user.activity !== 'No status') {
    defaultCss = `border: 3px dotted ${setBorderByStatus(person)};`;
  } else {
    const isNoStatus = person.user.activity === 'No status' ? `outline: 1px solid white;` : '';
    defaultCss = `border: 4px solid ${setBorderByStatus(person)}; ${isNoStatus}`;
  }
  if (position) {
    let customCss;
    const boxShadowCss = `box-shadow: 0 3px 1px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);`
    if (position == 'right') {
      customCss = `${defaultCss} border-right: 0; border-radius: 5px 0px 0px 5px; ${boxShadowCss}`;
    }else if (position == 'left') {
      customCss = `${defaultCss} border-left: 0; border-radius: 0px 5px 5px 0px; ${boxShadowCss}`;
    }
    return customCss;
  }
  return defaultCss;
}

export function getUserOrgaData(userUUID) {
 const organisation = store.state.namespaceSettings.processedOrganisation;
 const teams = organisation.teams;
 const userTeams = Object.entries(teams).filter(([teamName, value]) => {
  if (value.members.indexOf(userUUID) !== -1) return true;
 }).map(([teamName, value]) => teamName)
 const sectionTeams = Object.entries(teams).filter(([teamName, value]) => {
  if (value.members.indexOf(userUUID) !== -1) return true;
 }).map(([teamName, value]) => value.section)


//  console.log('------', userTeams, sectionTeams)
 return { 'teams':userTeams, 'sections':sectionTeams}
}


export function showDailyReportList() {
  const organisation = store.state.namespaceSettings.organisation;
  if (store.state.namespaceSettings.showDailyReport && organisation) {
    const showDailyReportValue = store.state.namespaceSettings.dailyReportValue;
    if (showDailyReportValue == 'team') {
      const teams = organisation.filter((e) => e.team);
      let teamUsers = [];
      for (const key in teams) {
        if (Object.hasOwnProperty.call(teams, key)) {
          const team = teams[key];
          if (team.teamMembers.indexOf(store.state.ownUUID) !== -1 || team.supervisor.indexOf(store.state.ownUUID) !== -1) {
            const temporalArray = teamUsers;
            teamUsers = temporalArray.concat(team.teamMembers);
          }
        }
      }
      // return all the user uuids list
      return teamUsers;
    } else if (showDailyReportValue == 'department') {
      let imDeptSupervisor = [];
      let teamUsers = [];
      // step 1 check if I am a department supervisor,
      // if true, get all the team supervisor uuids and team member uuids of the department
      organisation.filter((e) => e.departmentRights).map((e) => {
        if (e.supervisor.indexOf(store.state.ownUUID) !== -1) {
          const temporalArray = imDeptSupervisor;
          imDeptSupervisor = temporalArray.concat(e.department)
        }
      });
      const teams = organisation.filter((e) => e.team);
      teams.filter((e) => {
        if (imDeptSupervisor.indexOf(e.department) !== -1) {
          const temporalArray = teamUsers;
          teamUsers = temporalArray.concat(e.teamMembers).concat(e.supervisor);
        }
      });
      // step 2
      // if I part of a team, get all the team member uuids
      let imPartOfDepts = [];
      for (const key in teams) {
        if (Object.hasOwnProperty.call(teams, key)) {
          const team = teams[key];
          if (team.teamMembers.indexOf(store.state.ownUUID) !== -1 || team.supervisor.indexOf(store.state.ownUUID) !== -1) {
            if (imPartOfDepts.indexOf(team.department) == -1) imPartOfDepts.push(team.department);
          }
        }
      }
      imPartOfDepts.forEach(dept => {
        teams.filter((e) => e.department === dept).map((e) => {
          const temporalArray = teamUsers;
          teamUsers = temporalArray.concat(e.teamMembers);
        });
      });
      // return all the user uuids list
      return teamUsers;
    } else if (showDailyReportValue == 'section') {
      const sections = organisation.filter((e) => e.sectionRights);
      const mySections = sections.filter((e) => e.supervisor.indexOf(store.state.ownUUID) !== -1).map((e) => {
        return e.section;
      });
      const departments = organisation.filter((e) => e.departmentRights);
      const teams = organisation.filter((e) => e.team);
      let departmentSupervisorsOfMySections = []
      departments.filter((e) => mySections.indexOf(e.section) !== -1).map((e) => {
        let temporalArray = departmentSupervisorsOfMySections;
        departmentSupervisorsOfMySections = temporalArray.concat(e.supervisor);
      });
      let teamSupervisorsAndMembersOfMySections = []
      teams.filter((e) => mySections.indexOf(e.section) !== -1).map((e) => {
        let temporalArray = teamSupervisorsAndMembersOfMySections;
        teamSupervisorsAndMembersOfMySections = temporalArray.concat(e.supervisor).concat(e.teamMembers);
      });
      return teamSupervisorsAndMembersOfMySections;
    }
  }
}

export function isDailyReportEnabled() {
  return store.state.namespaceSettings.showDailyReport
}

export function getFillColorByStatus(person) {
  return setBorderByStatus(person);
}

export function getSubjectVisitor(uuid) {
  if (isVisitor(uuid)) {
    return store.state.group[uuid].user.visitorData.subjectDisplay;
  }
}

export function canIseeSpeechliveIcon() {
  return ((store.state.user || {}).speechlive || {}).activated && canShowSpeechliveByDomain();
}
export function userHasSpeechLiveActivated(person) {
  return ((person.user || {}).speechlive || {}).activated && canShowSpeechliveByDomain();
}
export function canShowSpeechliveByDomain() {
  return webLicensedBaseFeatures.isSpeechliveAvailable;
}

export function locationTranslated(person) {
  if (!person || !person.userLocation) return;
  const key = `components.locations.${person.userLocation}`;
  const translation = Vue.prototype.$t(key) || key;
  return key === translation ? `${person.userLocation}` : translation;
}

export function getPictureSize() {
  const size = store.state.namespaceSettings.pictureSize || 'square';
  switch (size) {
    case 'square':
      return true;
    case 'rectangle':
      return false;
    default:
      return true;
  }
}

export function getAvatarByType(uuid) {
  const type = store.state.namespaceSettings.pictureSize || 'square';
  if (type === 'square') {
    return store.getAvatarForUuid(uuid);
  } else if (type === 'rectangle') {
    return store.getAvatarRectangleForUuid(uuid);
  }
}

export function hasUserImage(uuid) {
  const image = getAvatarByType(uuid);
  return image === 'img/default_profile_picture.png' ? false : true;
}

export function canIShowOrganigram(uuid) {
  if (store.state.namespaceSettings.featureOrganization && !store.state.namespaceSettings.basicView) { //&& Object.keys(store.state.namespaceSettings.organisation).length > 0
    const userUUID = uuid || store.state.ownUUID
    const data = store.state.namespaceSettings.showOrganigrammFor
    let canIShow = false
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      switch (element) {
        case 'all':
          if (hasPrivilege(userUUID)) canIShow = true
          break;
        case 'admin':
          if (isAdmin(userUUID)) canIShow = true
          break;
        case 'sectionManager':
          if (userSupervisorSection(userUUID)) canIShow = true;
          break;
        case 'departmentManager':
          if (userSupervisorDepartment(userUUID)) canIShow = true;
          break;
        case 'teamManager':
          if (userSupervisorTeam(userUUID)) canIShow = true;
          break;
        default:
          break;
      }
    }
    return canIShow
  } else {
    return false
  }
}

function getDepartmentSupervisorUUID(departmentName) {
  const departmentsList = store.state.namespaceSettings.processedOrganisation.departments;
  if (departmentsList[departmentName] && departmentsList[departmentName].supervisors[0]) {
    return departmentsList[departmentName].supervisors[0].uuid;
  }
  return undefined;
}

export function getAllUsersWithPrivileges() {
  let userList = [];
  const allUsers = Object.keys(store.state.group);
  allUsers.forEach(userUUID => {
    if (hasPrivilege(userUUID) && userList.indexOf(userUUID) == -1) {
      userList.push(userUUID);
    }
  });
  return userList;
}

export function getUsersFromOrganigramByName(type, typeName) {
  const sectionsList = store.state.namespaceSettings.processedOrganisation.sections;
  const departmentsList = store.state.namespaceSettings.processedOrganisation.departments;
  const teamsList = store.state.namespaceSettings.processedOrganisation.teams;
  let usersList = [];
  let sectionSupervisor = ''
  let departmentSupervisor = ''
  switch (type) {
    case 'section':
      for (const teamName in teamsList) {
        if (Object.hasOwnProperty.call(teamsList, teamName)) {
          const team = teamsList[teamName];
          if (team.section === typeName) {
            if (team.supervisors[0] && usersList.indexOf(team.supervisors[0].uuid) == -1) usersList.push(team.supervisors[0].uuid);
            team.members.forEach(userUUID => {
              if (usersList.indexOf(userUUID) == -1) usersList.push(userUUID);
            });
            if (sectionsList[team.section].supervisors[0]) {
              sectionSupervisor = sectionsList[team.section].supervisors[0].uuid;
              if (usersList.indexOf(sectionSupervisor) == -1) usersList.push(sectionSupervisor);
            }
            for (let i = 0; i < sectionsList[team.section].departments.length; i++) {
              const department = sectionsList[team.section].departments[i];
              const getDepartmentSupervisor = getDepartmentSupervisorUUID(department);
              if (usersList.indexOf(getDepartmentSupervisor) == -1) usersList.push(getDepartmentSupervisor);
            }
          }
        }
      }
      break;
    case 'department':
      for (const teamName in teamsList) {
        if (Object.hasOwnProperty.call(teamsList, teamName)) {
          const team = teamsList[teamName];
          if (team.department === typeName) {
            if (team.supervisors[0] && usersList.indexOf(team.supervisors[0].uuid) == -1) usersList.push(team.supervisors[0].uuid);
            team.members.forEach(userUUID => {
              if (usersList.indexOf(userUUID) == -1) usersList.push(userUUID);
            });
            if (departmentsList[team.department].supervisors[0]) {
              departmentSupervisor = departmentsList[team.department].supervisors[0].uuid;
              if (usersList.indexOf(departmentSupervisor) == -1) usersList.push(departmentSupervisor);
            }
          }
        }
      }
      break;
    case 'team':
      if (teamsList[typeName]) {
        usersList = teamsList[typeName].members;
        if (teamsList[typeName].supervisors[0] && usersList.indexOf(teamsList[typeName].supervisors[0].uuid) == -1) usersList.push(teamsList[typeName].supervisors[0].uuid)
      }
      break;
    default:
      break;
  }
  return usersList
}

export function hasAdditionalStatus(person) {
  if (
    person.user &&
    person.user.additionalStatus &&
    person.user.additionalStatus.length > 0
  ) {
    return true;
  } else {
    return false;
  }
}

export function checkIsDateInRange(startDate, endDate, date) {
  let range = moment().range(startDate, endDate);
  const sameDay =
    startDate.getTime() === endDate.getTime() &&
    endDate.getTime() === date.getTime() &&
    startDate.getTime() === date.getTime();
  if (range.contains(date) || sameDay) {
    return true;
  }
  return false;
}

export function userHasHolidaysSetted(uuid) {
  if (!uuid) return false
  const person = (store.state.group[uuid] || {}).user
  if (
    person &&
    person.startHolidays &&
    person.startHolidays.length > 0
  ) {
    let startDate = new Date(person.startHolidays);
    let endDate = new Date(person.endHolidays);
    let nowDate = new Date(
      Date.UTC(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    );
    if (checkIsDateInRange(startDate, endDate, nowDate)) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export function checkForUrls(message, newline = true) {
  if (!message) return "";
  const linkExpBlacklist = /["'>](((https?|ftp|file):\/\/)?[-A-Z0-9+&@'#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])(["']|<\/a>)/gmi,
    linkExp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@'#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig,
    wwwExp = /(^|[^\/]\b)(www\.[^\s<]+(\b|$))/gim,
    hyperlinkBlacklist = [];
  // Search hyperlinks with <a> tag
  message.replace(linkExpBlacklist, (...args) => { if (hyperlinkBlacklist.indexOf(args[1]) === -1) hyperlinkBlacklist.push(args[1]); });
  // Replace hyperlinks ignoring hyperlink blacklist
  return message
    .replace(linkExp, (...args) => {
      if (hyperlinkBlacklist.indexOf(args[1]) === -1) {
        return `${newline ? '<br>' : ''}<a style="text-decoration: none;" rel="nofollow noopener noreferrer" target="_blank" href="${args[1]}">${args[1]}</a>${newline ? '<br>' : ''}`;
      }
      return args[0];
    })
    .replace(wwwExp, (...args) => {
      if (hyperlinkBlacklist.indexOf(args[2]) === -1) {
        return `${args[1]}${newline ? '<br>' : ''}<a style="text-decoration: none;" rel="nofollow noopener noreferrer" target="_blank" href="http://${args[2]}">${args[2]}</a>${newline ? '<br>' : ''}`;
      }
      return args[0];
    });
}
export function checkForEmojis(message) {
  if (!message) return "";
  const regexExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
  let outPutMessage = message
  if (outPutMessage.match(regexExp) && (outPutMessage.match(regexExp).length > 1 || (outPutMessage.match(regexExp).length == 1 && outPutMessage.trim().length > 2))) {
    outPutMessage = outPutMessage.replace(regexExp, "<span class='emojisInText'>$1</span>");
  }
  if (outPutMessage.match(regexExp) && outPutMessage.match(regexExp).length == 1 && outPutMessage.trim().length == 2) {
    outPutMessage = outPutMessage.replace(regexExp, "<span class='singleEmojiInText'>$1</span>");
  }
  return outPutMessage;
}

export function userInOrganigram(uuid) {
  if (uuid && store.getProcessedUserList()[uuid] !== undefined) {
    return true;
  }
  return false;
}

export function getUserActivityFunction(person) {
  const redStatus = (
    person.user.activity == "Out of Office" ||
    person.user.activity == "No Calls" ||
    person.user.activity == "Break" ||
    person.user.activity == "No Calls" ||
    person.user.activity == "Only phone") ? true : false;
  if (
    (!person.connected && person.user.activity !== "Holidays" && !redStatus && person.user.activity !== "No status")
  ) {
    return Vue.prototype.$t("status.Offline");
  } else {
    const key = `status.${person.user.activity}`;
    const translation = Vue.prototype.$t(key) || key;
    return key === translation ? `${person.user.activity}` : translation;
  }
}

export function validURL(message) {
  if (!message) return;
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(message);
}
