<template>
  <v-dialog v-model="showActiveUserModal" persistent max-width="560">
    <template>
      <v-card class="mx-auto">
        <v-card-title class="headline" primary-title>
          <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
          {{ $t('components.activeUserModal.title')}}
        </v-card-title>
        <v-card-text>
          {{ $t('components.activeUserModal.text')}}
          <div class="mt-8 d-flex justify-center">
            <div class="mx-3 pointer">
              <v-btn class="primary" v-on:click="reConnectUser()">Ok</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";

export default {
  data: () => ({
    state: store.state,
  }),
  methods: {
    reConnectUser() {
      const date = new Date(store.state.currentTS).toISOString();
      this.setPresentFrom(date);
      this.closeModal();
    },
    closeModal() {
      store.setShowModalActiveUser(false);
    },
    setPresentFrom(value) {
      return store.setPresentFrom(value);
    },
  },
  computed: {
    showActiveUserModal() {
      return store.state.showModalActiveUser;
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>