var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',{staticClass:"px-0 py-0 contentGridWrapper"},[(!_vm.showUsersGrid)?_c('v-row',{staticClass:"mx-auto"},_vm._l((_vm.gridData),function(section,index){return _c('v-col',{key:index,staticClass:"custom6cols pb-1 px-2",attrs:{"cols":"6"}},[_c('v-card',{staticClass:"cardWidth",style:(_vm.getBorderByStatusFull(_vm.getSupervisorPerson(section))),on:{"click":function($event){_vm.handleClicks($event, _vm.getSupervisorCardUserPerson(section), section)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.getSupervisorAvatar(section, 'sections'),"height":"180px"}},[_c('div',{staticClass:"gradientBg"},[_c('v-card-subtitle',{staticClass:"titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",staticStyle:{"color":"#fff !important"}},[_vm._v("\n                    "+_vm._s(section.team)+"\n                  ")]),_vm._v(" "),_c('v-card-subtitle',{staticClass:"titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate subTitleCard",staticStyle:{"color":"#fff !important"}},[_vm._v("\n                    "+_vm._s(_vm.getSupervisorTitel(section) +
                      " " +
                      _vm.getSupervisorName(section))+"\n                  ")]),_vm._v(" "),_c('ButtonsFrontCard',{attrs:{"person":_vm.getSupervisorByUuid(section),"uuid":_vm.getSupervisorUuid(section)}})],1),_vm._v(" "),_c('ButtonsCardWrapper',{attrs:{"person":_vm.getSupervisorPerson(section),"isRegularGrid":true}})],1)],1)]}}],null,true)},[_vm._v(" "),_c('UserInfoTooltip',{attrs:{"person":_vm.getSupervisorPerson(section)}})],1)],1)],1)}),1):_vm._e(),_vm._v(" "),(_vm.showUsersGrid)?_c('v-row',{staticClass:"mx-auto"},_vm._l((_vm.usersArray),function(user,index){return _c('v-col',{key:index,staticClass:"custom6cols pb-1 px-2",attrs:{"cols":"6"}},[_c('v-card',{staticClass:"cardWidth",style:(_vm.getBorderByStatusFull(user)),on:{"click":function($event){return _vm.handleClicks($event, user, user.user.uuid)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.getAvatarForUuid(user.user.uuid),"height":"180px"}},[_c('div',{staticClass:"gradientBg"},[_c('v-card-subtitle',{staticClass:"titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate subTitleCard",staticStyle:{"color":"#fff !important"}},[_vm._v("\n                    "+_vm._s(_vm.getTitelByUuid(user.user.uuid) +
                      " " +
                      _vm.getNameByUuid(user.user.uuid))+"\n                  ")]),_vm._v(" "),_c('ButtonsFrontCard',{attrs:{"person":user,"uuid":user.user.uuid}})],1),_vm._v(" "),_c('ButtonsCardWrapper',{attrs:{"person":user,"isRegularGrid":true}})],1)],1)]}}],null,true)},[_vm._v(" "),_c('UserInfoTooltip',{attrs:{"person":user}})],1)],1)],1)}),1):_vm._e(),_vm._v(" "),_c('infinite-loading',{attrs:{"distance":5},on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"no-results"},slot:"no-results"}),_vm._v(" "),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }