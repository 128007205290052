<template>
<!-- UNUSED MODAL -->
  <v-dialog v-model="showErrorRemoteStorage" persistent max-width="460" @keydown.esc="closeModal">
    <v-card>
      <v-card-title class="headline">
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        {{$t('components.adminSettingsModal.remoteStorage')}}
      </v-card-title>
      <v-card-text>{{$t('components.adminSettingsModal.errorRemoteStorage')}}</v-card-text>

      <v-row class="mx-0">
        <v-col cols="12">
          <v-checkbox
            v-model="enableRemoteStorage"
            @change="enableDisabledRemoteStorage"
            class="mencheckbox chkList sel-check-item py-0 my-0"
           :label="$t('components.adminSettingsModal.enableRemoteStorageftp')" 
          ></v-checkbox>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field outlined dense v-model="url" :disabled="!enableRemoteStorage"
          label="URL"/>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field outlined dense v-model="username" :disabled="!enableRemoteStorage"
          label="Username"/>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field outlined dense v-model="password" :disabled="!enableRemoteStorage"
          label="Password"/>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field outlined dense v-model="port" type="number" :disabled="!enableRemoteStorage"
          label="Port"/>
        </v-col>

          <v-col cols="12" class="py-0">
            <v-subheader class="pl-0 mb-5">Sync intervale</v-subheader>
            <v-slider v-model="syncIntervale" min="1" max="60" thumb-label="always" :disabled="!enableRemoteStorage"></v-slider>
          </v-col>

        <v-col cols="12" class="py-0">
          <v-btn
            :disabled="disabledSaveRemoteStorage"
            @click="saveRemoteStorageData()"
            depressed
            class="ml-2"
            color="primary"
          >{{ $t('generics.save') }}</v-btn>
        </v-col>
      </v-row>
      <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns mx-0" flex>
          <v-col cols="8" class="px-0 py-0" align="right">
            <v-btn color="primary" @click="closeModal();">{{ $t('generics.close') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { setNamespaceSetting } from "../../lib/wsMsg";

export default {
  props: ["showErrorRemoteStorage", "closeModalErrorRemoteStorage"],
  data() {
    return {
      state: store.state,
      enableRemoteStorage: store.state.namespaceSettings.enableRemoteStorage,
      url: store.state.namespaceSettings.remoteStorageData.url,
      username: store.state.namespaceSettings.remoteStorageData.username,
      password: store.state.namespaceSettings.remoteStorageData.password,
      port: store.state.namespaceSettings.remoteStorageData.port,
      syncIntervale: store.state.namespaceSettings.remoteStorageData.syncIntervale,
      qualityLogging: store.state.namespaceSettings.qualityLogging,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    enableDisabledRemoteStorage(value){
      setNamespaceSetting("enableRemoteStorage", this.enableRemoteStorage);
      if (!value ){
        const tmpData = {
          url: '',
          username: '',
          password: '',
          port: null,
          syncIntervale: 1,
        }
        setNamespaceSetting("remoteStorageData", tmpData);
      }
    },
    closeModal(){
      this.closeModalErrorRemoteStorage();
    },
    saveRemoteStorageData(){
      if ( !isNaN(this.port) && this.isPortInRange){
        const tmpData = {
          url: this.url,
          username: this.username,
          password: this.password,
          port: this.port,
          syncIntervale: this.syncIntervale,
        }
        setNamespaceSetting("enableRemoteStorage", this.enableRemoteStorage);
        setNamespaceSetting("remoteStorageData", tmpData);
      }else{
        // console.log('port is not in range')
      }
    }
  },
  computed:{
    isPortInRange(){
      if ( this.port >= 0 && this.port <= 65535){
        return true;
      }
      return false;
    },
    disabledSaveRemoteStorage(){
      if( !this.enableRemoteStorage && this.url == '' && this.username === '' && this.password === '' ){
        return true;
      }
      return false;
    },
  }

};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
