<template>
 <v-list>
      <v-list-group :color="isDarkMode? 'white':'black'" :value="isActive" @click="setList('stats')"
      >
        <template v-slot:activator>
          <v-list-item-icon>
            <font-awesome-icon class="primary--text" :icon="['fab', 'git']"/>
          </v-list-item-icon>
          <v-list-item-title  @click="setCurrentContentVisile('', false, $router)">{{ $t('generics.gitStats') }}</v-list-item-title>
        </template>

        <ul>
           <li>
            <span id="statusUUID">UUID: {{ state.ownUUID }}</span>
          </li>
          <li>
            <span id="statusTS">{{ currentTSText }}</span>
          </li>
          <li v-if="showStatusGit">
            <span id="statusGit">
              "{{ state.gitCommit.subject }}" ({{ state.gitCommit.shortHash }})<br>
              <div>~ {{ state.gitCommit.author }}</div>
              {{ moment(parseInt(state.gitCommit.committedOn+'000')).from(state.currentTS) }}<br>
              <span class="gitDirty" v-if="state.gitCommit.dirty">You might need to reload the page to be up to date!</span>
            </span>
          </li>
        </ul>
      </v-list-group>
    </v-list>
</template>

<script>
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import moment from 'moment';
import store from '../../store';
export default {
  props:['active', 'setList'],
  components: {
  },
  data() {
    return {
      state: store.state,
    };
  },
  methods: {
    setCurrentContentVisile(...args) { return store.setCurrentContentVisile(...args); },
    moment(...args) { return moment(...args); },
  },
  computed: {
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    currentTSText() {
      return (new Date(this.state.currentTS)).toISOString();
    },
    isActive() {
      return this.active == 'stats';
    },
    showStatusGit() {
      return !webLicensedBaseFeatures.isPkg;
    },
  }
};
</script>


<style scoped lang="scss">
.titleGroup{
  .v-icon{
    font-size: 14px !important;
  }
}

.v-list {
  padding: 0;
}

.v-list-item__icon{
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped){
    border-radius: 0;
}


</style>
